import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSortList from '@components/utility-components/modal/UrstammModalSortList';
import { i18n } from '@i18n/i18n';
import { TreeDTO, TreeDTORecordingTypeEnum } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
  getColorByPileState,
  getIconByPileState,
  getTextByPileState,
  getTextByTreeOrTrunkSpecies,
  getTextByTreeType
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { getCardTitleByRecordingType } from '../../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyle, UrstammStyleList } from '../../../utils/styles/UrstammStyle';

export default function TreeListView(props: {
  navigation: any;
  treeList: TreeDTO[];
  moreItems?: () => void;
  modalVisible: boolean;
  treeSelected?: (tree: TreeDTO) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
}) {
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.tree.sortBy);
  const [treeSelected, setTreeSelected] = useState<TreeDTO>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  let keys: ListSubData[] = [
    {
      title: i18n.t('generics.species') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'species',
      keyId: '1species',
      isEnum: true
    },
    {
      title: i18n.t('generics.recording_type') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'recordingType',
      keyId: '2recordingType',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' },
      isEnum: true
    }
  ];

  useEffect(() => {
    if (props.modalVisible) {
      setModalVisible(props.modalVisible);
    }
    return () => {};
  }, [props.modalVisible]);

  return (
    <View style={[UrstammStyle.flex1]}>
      <View style={UrstammStyle.flex1}>
        <UrstammList
          elementSelected={(data: any) => {
            setTreeSelected(data.item), props.treeSelected ? props.treeSelected(data.item) : null;
          }}
          showHash={true}
          testID={'tree_list'}
          list={props.treeList}
          titleByFunction={data => getCardTitleByRecordingType(data)}
          textForEmptyList={i18n.t('generics.trees_not_found')}
          listStyle={{
            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
            bottomContainerViewStyle: UrstammStyleList.cardSubData,
            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
          }}
          moreItems={props.moreItems}
          listSubData={keys}
          currentState={{
            icon: state => getIconByPileState(state),
            color: state => getColorByPileState(state),
            text: state => getTextByPileState(state)
          }}
          getTextForData={(data, key) =>
            key == 'recordingType' ? getTextByTreeType(data) : getTextByTreeOrTrunkSpecies(data)
          }
        />

        {props.showSortBy && (
          <UrstammModalSortList
            testID={'modal_sort'}
            nameSortKey={'photoNumber'}
            showSortBy={props.showSortBy}
            closeShowSortBy={props.closeShowSortBy}
            applyOrder={(orderSelected: ButtonSelect[]) => props.applyOrder(orderSelected)}
            sortBySelected={rdxSortBy}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
