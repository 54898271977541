/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeliverySheet,
} from '../models';
import {
    DeliverySheetFromJSON,
    DeliverySheetToJSON,
} from '../models';

export interface CountDeliverySheetsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    sentToSawmillEquals?: boolean;
    sentToSawmillNotEquals?: boolean;
    sentToSawmillSpecified?: boolean;
    sentToSawmillIn?: Array<boolean>;
    sentToSawmillNotIn?: Array<boolean>;
    currentStateEquals?: CountDeliverySheetsCurrentStateEqualsEnum;
    currentStateNotEquals?: CountDeliverySheetsCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<CountDeliverySheetsCurrentStateInEnum>;
    currentStateNotIn?: Array<CountDeliverySheetsCurrentStateNotInEnum>;
    currentUrstammStateEquals?: CountDeliverySheetsCurrentUrstammStateEqualsEnum;
    currentUrstammStateNotEquals?: CountDeliverySheetsCurrentUrstammStateNotEqualsEnum;
    currentUrstammStateSpecified?: boolean;
    currentUrstammStateIn?: Array<CountDeliverySheetsCurrentUrstammStateInEnum>;
    currentUrstammStateNotIn?: Array<CountDeliverySheetsCurrentUrstammStateNotInEnum>;
    recordingTypeEquals?: CountDeliverySheetsRecordingTypeEqualsEnum;
    recordingTypeNotEquals?: CountDeliverySheetsRecordingTypeNotEqualsEnum;
    recordingTypeSpecified?: boolean;
    recordingTypeIn?: Array<CountDeliverySheetsRecordingTypeInEnum>;
    recordingTypeNotIn?: Array<CountDeliverySheetsRecordingTypeNotInEnum>;
    automaticallyCreatedEquals?: boolean;
    automaticallyCreatedNotEquals?: boolean;
    automaticallyCreatedSpecified?: boolean;
    automaticallyCreatedIn?: Array<boolean>;
    automaticallyCreatedNotIn?: Array<boolean>;
    amountOfTruckJourneysGreaterThan?: number;
    amountOfTruckJourneysLessThan?: number;
    amountOfTruckJourneysGreaterThanOrEqual?: number;
    amountOfTruckJourneysLessThanOrEqual?: number;
    amountOfTruckJourneysEquals?: number;
    amountOfTruckJourneysNotEquals?: number;
    amountOfTruckJourneysSpecified?: boolean;
    amountOfTruckJourneysIn?: Array<number>;
    amountOfTruckJourneysNotIn?: Array<number>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    pileIdGreaterThan?: number;
    pileIdLessThan?: number;
    pileIdGreaterThanOrEqual?: number;
    pileIdLessThanOrEqual?: number;
    pileIdEquals?: number;
    pileIdNotEquals?: number;
    pileIdSpecified?: boolean;
    pileIdIn?: Array<number>;
    pileIdNotIn?: Array<number>;
    forestryIdGreaterThan?: number;
    forestryIdLessThan?: number;
    forestryIdGreaterThanOrEqual?: number;
    forestryIdLessThanOrEqual?: number;
    forestryIdEquals?: number;
    forestryIdNotEquals?: number;
    forestryIdSpecified?: boolean;
    forestryIdIn?: Array<number>;
    forestryIdNotIn?: Array<number>;
    sawmillIdGreaterThan?: number;
    sawmillIdLessThan?: number;
    sawmillIdGreaterThanOrEqual?: number;
    sawmillIdLessThanOrEqual?: number;
    sawmillIdEquals?: number;
    sawmillIdNotEquals?: number;
    sawmillIdSpecified?: boolean;
    sawmillIdIn?: Array<number>;
    sawmillIdNotIn?: Array<number>;
    deliverySheetErpImportationIdGreaterThan?: number;
    deliverySheetErpImportationIdLessThan?: number;
    deliverySheetErpImportationIdGreaterThanOrEqual?: number;
    deliverySheetErpImportationIdLessThanOrEqual?: number;
    deliverySheetErpImportationIdEquals?: number;
    deliverySheetErpImportationIdNotEquals?: number;
    deliverySheetErpImportationIdSpecified?: boolean;
    deliverySheetErpImportationIdIn?: Array<number>;
    deliverySheetErpImportationIdNotIn?: Array<number>;
    deliverySheetPhotoIdGreaterThan?: number;
    deliverySheetPhotoIdLessThan?: number;
    deliverySheetPhotoIdGreaterThanOrEqual?: number;
    deliverySheetPhotoIdLessThanOrEqual?: number;
    deliverySheetPhotoIdEquals?: number;
    deliverySheetPhotoIdNotEquals?: number;
    deliverySheetPhotoIdSpecified?: boolean;
    deliverySheetPhotoIdIn?: Array<number>;
    deliverySheetPhotoIdNotIn?: Array<number>;
    deliverySheetStateIdGreaterThan?: number;
    deliverySheetStateIdLessThan?: number;
    deliverySheetStateIdGreaterThanOrEqual?: number;
    deliverySheetStateIdLessThanOrEqual?: number;
    deliverySheetStateIdEquals?: number;
    deliverySheetStateIdNotEquals?: number;
    deliverySheetStateIdSpecified?: boolean;
    deliverySheetStateIdIn?: Array<number>;
    deliverySheetStateIdNotIn?: Array<number>;
    trunkIdGreaterThan?: number;
    trunkIdLessThan?: number;
    trunkIdGreaterThanOrEqual?: number;
    trunkIdLessThanOrEqual?: number;
    trunkIdEquals?: number;
    trunkIdNotEquals?: number;
    trunkIdSpecified?: boolean;
    trunkIdIn?: Array<number>;
    trunkIdNotIn?: Array<number>;
    batchIdGreaterThan?: number;
    batchIdLessThan?: number;
    batchIdGreaterThanOrEqual?: number;
    batchIdLessThanOrEqual?: number;
    batchIdEquals?: number;
    batchIdNotEquals?: number;
    batchIdSpecified?: boolean;
    batchIdIn?: Array<number>;
    batchIdNotIn?: Array<number>;
    packetIdGreaterThan?: number;
    packetIdLessThan?: number;
    packetIdGreaterThanOrEqual?: number;
    packetIdLessThanOrEqual?: number;
    packetIdEquals?: number;
    packetIdNotEquals?: number;
    packetIdSpecified?: boolean;
    packetIdIn?: Array<number>;
    packetIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateDeliverySheetRequest {
    deliverySheet: DeliverySheet;
}

export interface DeleteDeliverySheetRequest {
    id: number;
}

export interface GetAllDeliverySheetsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    sentToSawmillEquals?: boolean;
    sentToSawmillNotEquals?: boolean;
    sentToSawmillSpecified?: boolean;
    sentToSawmillIn?: Array<boolean>;
    sentToSawmillNotIn?: Array<boolean>;
    currentStateEquals?: GetAllDeliverySheetsCurrentStateEqualsEnum;
    currentStateNotEquals?: GetAllDeliverySheetsCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<GetAllDeliverySheetsCurrentStateInEnum>;
    currentStateNotIn?: Array<GetAllDeliverySheetsCurrentStateNotInEnum>;
    currentUrstammStateEquals?: GetAllDeliverySheetsCurrentUrstammStateEqualsEnum;
    currentUrstammStateNotEquals?: GetAllDeliverySheetsCurrentUrstammStateNotEqualsEnum;
    currentUrstammStateSpecified?: boolean;
    currentUrstammStateIn?: Array<GetAllDeliverySheetsCurrentUrstammStateInEnum>;
    currentUrstammStateNotIn?: Array<GetAllDeliverySheetsCurrentUrstammStateNotInEnum>;
    recordingTypeEquals?: GetAllDeliverySheetsRecordingTypeEqualsEnum;
    recordingTypeNotEquals?: GetAllDeliverySheetsRecordingTypeNotEqualsEnum;
    recordingTypeSpecified?: boolean;
    recordingTypeIn?: Array<GetAllDeliverySheetsRecordingTypeInEnum>;
    recordingTypeNotIn?: Array<GetAllDeliverySheetsRecordingTypeNotInEnum>;
    automaticallyCreatedEquals?: boolean;
    automaticallyCreatedNotEquals?: boolean;
    automaticallyCreatedSpecified?: boolean;
    automaticallyCreatedIn?: Array<boolean>;
    automaticallyCreatedNotIn?: Array<boolean>;
    amountOfTruckJourneysGreaterThan?: number;
    amountOfTruckJourneysLessThan?: number;
    amountOfTruckJourneysGreaterThanOrEqual?: number;
    amountOfTruckJourneysLessThanOrEqual?: number;
    amountOfTruckJourneysEquals?: number;
    amountOfTruckJourneysNotEquals?: number;
    amountOfTruckJourneysSpecified?: boolean;
    amountOfTruckJourneysIn?: Array<number>;
    amountOfTruckJourneysNotIn?: Array<number>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    pileIdGreaterThan?: number;
    pileIdLessThan?: number;
    pileIdGreaterThanOrEqual?: number;
    pileIdLessThanOrEqual?: number;
    pileIdEquals?: number;
    pileIdNotEquals?: number;
    pileIdSpecified?: boolean;
    pileIdIn?: Array<number>;
    pileIdNotIn?: Array<number>;
    forestryIdGreaterThan?: number;
    forestryIdLessThan?: number;
    forestryIdGreaterThanOrEqual?: number;
    forestryIdLessThanOrEqual?: number;
    forestryIdEquals?: number;
    forestryIdNotEquals?: number;
    forestryIdSpecified?: boolean;
    forestryIdIn?: Array<number>;
    forestryIdNotIn?: Array<number>;
    sawmillIdGreaterThan?: number;
    sawmillIdLessThan?: number;
    sawmillIdGreaterThanOrEqual?: number;
    sawmillIdLessThanOrEqual?: number;
    sawmillIdEquals?: number;
    sawmillIdNotEquals?: number;
    sawmillIdSpecified?: boolean;
    sawmillIdIn?: Array<number>;
    sawmillIdNotIn?: Array<number>;
    deliverySheetErpImportationIdGreaterThan?: number;
    deliverySheetErpImportationIdLessThan?: number;
    deliverySheetErpImportationIdGreaterThanOrEqual?: number;
    deliverySheetErpImportationIdLessThanOrEqual?: number;
    deliverySheetErpImportationIdEquals?: number;
    deliverySheetErpImportationIdNotEquals?: number;
    deliverySheetErpImportationIdSpecified?: boolean;
    deliverySheetErpImportationIdIn?: Array<number>;
    deliverySheetErpImportationIdNotIn?: Array<number>;
    deliverySheetPhotoIdGreaterThan?: number;
    deliverySheetPhotoIdLessThan?: number;
    deliverySheetPhotoIdGreaterThanOrEqual?: number;
    deliverySheetPhotoIdLessThanOrEqual?: number;
    deliverySheetPhotoIdEquals?: number;
    deliverySheetPhotoIdNotEquals?: number;
    deliverySheetPhotoIdSpecified?: boolean;
    deliverySheetPhotoIdIn?: Array<number>;
    deliverySheetPhotoIdNotIn?: Array<number>;
    deliverySheetStateIdGreaterThan?: number;
    deliverySheetStateIdLessThan?: number;
    deliverySheetStateIdGreaterThanOrEqual?: number;
    deliverySheetStateIdLessThanOrEqual?: number;
    deliverySheetStateIdEquals?: number;
    deliverySheetStateIdNotEquals?: number;
    deliverySheetStateIdSpecified?: boolean;
    deliverySheetStateIdIn?: Array<number>;
    deliverySheetStateIdNotIn?: Array<number>;
    trunkIdGreaterThan?: number;
    trunkIdLessThan?: number;
    trunkIdGreaterThanOrEqual?: number;
    trunkIdLessThanOrEqual?: number;
    trunkIdEquals?: number;
    trunkIdNotEquals?: number;
    trunkIdSpecified?: boolean;
    trunkIdIn?: Array<number>;
    trunkIdNotIn?: Array<number>;
    batchIdGreaterThan?: number;
    batchIdLessThan?: number;
    batchIdGreaterThanOrEqual?: number;
    batchIdLessThanOrEqual?: number;
    batchIdEquals?: number;
    batchIdNotEquals?: number;
    batchIdSpecified?: boolean;
    batchIdIn?: Array<number>;
    batchIdNotIn?: Array<number>;
    packetIdGreaterThan?: number;
    packetIdLessThan?: number;
    packetIdGreaterThanOrEqual?: number;
    packetIdLessThanOrEqual?: number;
    packetIdEquals?: number;
    packetIdNotEquals?: number;
    packetIdSpecified?: boolean;
    packetIdIn?: Array<number>;
    packetIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetDeliverySheetRequest {
    id: number;
}

export interface PartialUpdateDeliverySheetRequest {
    id: number;
    deliverySheet: DeliverySheet;
}

export interface UpdateDeliverySheetRequest {
    id: number;
    deliverySheet: DeliverySheet;
}

/**
 * 
 */
export class DeliverySheetResourceApi extends runtime.BaseAPI {

    /**
     */
    async countDeliverySheetsRaw(requestParameters: CountDeliverySheetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.sentToSawmillEquals !== undefined) {
            queryParameters['sentToSawmill.equals'] = requestParameters.sentToSawmillEquals;
        }

        if (requestParameters.sentToSawmillNotEquals !== undefined) {
            queryParameters['sentToSawmill.notEquals'] = requestParameters.sentToSawmillNotEquals;
        }

        if (requestParameters.sentToSawmillSpecified !== undefined) {
            queryParameters['sentToSawmill.specified'] = requestParameters.sentToSawmillSpecified;
        }

        if (requestParameters.sentToSawmillIn) {
            queryParameters['sentToSawmill.in'] = requestParameters.sentToSawmillIn;
        }

        if (requestParameters.sentToSawmillNotIn) {
            queryParameters['sentToSawmill.notIn'] = requestParameters.sentToSawmillNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.currentUrstammStateEquals !== undefined) {
            queryParameters['currentUrstammState.equals'] = requestParameters.currentUrstammStateEquals;
        }

        if (requestParameters.currentUrstammStateNotEquals !== undefined) {
            queryParameters['currentUrstammState.notEquals'] = requestParameters.currentUrstammStateNotEquals;
        }

        if (requestParameters.currentUrstammStateSpecified !== undefined) {
            queryParameters['currentUrstammState.specified'] = requestParameters.currentUrstammStateSpecified;
        }

        if (requestParameters.currentUrstammStateIn) {
            queryParameters['currentUrstammState.in'] = requestParameters.currentUrstammStateIn;
        }

        if (requestParameters.currentUrstammStateNotIn) {
            queryParameters['currentUrstammState.notIn'] = requestParameters.currentUrstammStateNotIn;
        }

        if (requestParameters.recordingTypeEquals !== undefined) {
            queryParameters['recordingType.equals'] = requestParameters.recordingTypeEquals;
        }

        if (requestParameters.recordingTypeNotEquals !== undefined) {
            queryParameters['recordingType.notEquals'] = requestParameters.recordingTypeNotEquals;
        }

        if (requestParameters.recordingTypeSpecified !== undefined) {
            queryParameters['recordingType.specified'] = requestParameters.recordingTypeSpecified;
        }

        if (requestParameters.recordingTypeIn) {
            queryParameters['recordingType.in'] = requestParameters.recordingTypeIn;
        }

        if (requestParameters.recordingTypeNotIn) {
            queryParameters['recordingType.notIn'] = requestParameters.recordingTypeNotIn;
        }

        if (requestParameters.automaticallyCreatedEquals !== undefined) {
            queryParameters['automaticallyCreated.equals'] = requestParameters.automaticallyCreatedEquals;
        }

        if (requestParameters.automaticallyCreatedNotEquals !== undefined) {
            queryParameters['automaticallyCreated.notEquals'] = requestParameters.automaticallyCreatedNotEquals;
        }

        if (requestParameters.automaticallyCreatedSpecified !== undefined) {
            queryParameters['automaticallyCreated.specified'] = requestParameters.automaticallyCreatedSpecified;
        }

        if (requestParameters.automaticallyCreatedIn) {
            queryParameters['automaticallyCreated.in'] = requestParameters.automaticallyCreatedIn;
        }

        if (requestParameters.automaticallyCreatedNotIn) {
            queryParameters['automaticallyCreated.notIn'] = requestParameters.automaticallyCreatedNotIn;
        }

        if (requestParameters.amountOfTruckJourneysGreaterThan !== undefined) {
            queryParameters['amountOfTruckJourneys.greaterThan'] = requestParameters.amountOfTruckJourneysGreaterThan;
        }

        if (requestParameters.amountOfTruckJourneysLessThan !== undefined) {
            queryParameters['amountOfTruckJourneys.lessThan'] = requestParameters.amountOfTruckJourneysLessThan;
        }

        if (requestParameters.amountOfTruckJourneysGreaterThanOrEqual !== undefined) {
            queryParameters['amountOfTruckJourneys.greaterThanOrEqual'] = requestParameters.amountOfTruckJourneysGreaterThanOrEqual;
        }

        if (requestParameters.amountOfTruckJourneysLessThanOrEqual !== undefined) {
            queryParameters['amountOfTruckJourneys.lessThanOrEqual'] = requestParameters.amountOfTruckJourneysLessThanOrEqual;
        }

        if (requestParameters.amountOfTruckJourneysEquals !== undefined) {
            queryParameters['amountOfTruckJourneys.equals'] = requestParameters.amountOfTruckJourneysEquals;
        }

        if (requestParameters.amountOfTruckJourneysNotEquals !== undefined) {
            queryParameters['amountOfTruckJourneys.notEquals'] = requestParameters.amountOfTruckJourneysNotEquals;
        }

        if (requestParameters.amountOfTruckJourneysSpecified !== undefined) {
            queryParameters['amountOfTruckJourneys.specified'] = requestParameters.amountOfTruckJourneysSpecified;
        }

        if (requestParameters.amountOfTruckJourneysIn) {
            queryParameters['amountOfTruckJourneys.in'] = requestParameters.amountOfTruckJourneysIn;
        }

        if (requestParameters.amountOfTruckJourneysNotIn) {
            queryParameters['amountOfTruckJourneys.notIn'] = requestParameters.amountOfTruckJourneysNotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.pileIdGreaterThan !== undefined) {
            queryParameters['pileId.greaterThan'] = requestParameters.pileIdGreaterThan;
        }

        if (requestParameters.pileIdLessThan !== undefined) {
            queryParameters['pileId.lessThan'] = requestParameters.pileIdLessThan;
        }

        if (requestParameters.pileIdGreaterThanOrEqual !== undefined) {
            queryParameters['pileId.greaterThanOrEqual'] = requestParameters.pileIdGreaterThanOrEqual;
        }

        if (requestParameters.pileIdLessThanOrEqual !== undefined) {
            queryParameters['pileId.lessThanOrEqual'] = requestParameters.pileIdLessThanOrEqual;
        }

        if (requestParameters.pileIdEquals !== undefined) {
            queryParameters['pileId.equals'] = requestParameters.pileIdEquals;
        }

        if (requestParameters.pileIdNotEquals !== undefined) {
            queryParameters['pileId.notEquals'] = requestParameters.pileIdNotEquals;
        }

        if (requestParameters.pileIdSpecified !== undefined) {
            queryParameters['pileId.specified'] = requestParameters.pileIdSpecified;
        }

        if (requestParameters.pileIdIn) {
            queryParameters['pileId.in'] = requestParameters.pileIdIn;
        }

        if (requestParameters.pileIdNotIn) {
            queryParameters['pileId.notIn'] = requestParameters.pileIdNotIn;
        }

        if (requestParameters.forestryIdGreaterThan !== undefined) {
            queryParameters['forestryId.greaterThan'] = requestParameters.forestryIdGreaterThan;
        }

        if (requestParameters.forestryIdLessThan !== undefined) {
            queryParameters['forestryId.lessThan'] = requestParameters.forestryIdLessThan;
        }

        if (requestParameters.forestryIdGreaterThanOrEqual !== undefined) {
            queryParameters['forestryId.greaterThanOrEqual'] = requestParameters.forestryIdGreaterThanOrEqual;
        }

        if (requestParameters.forestryIdLessThanOrEqual !== undefined) {
            queryParameters['forestryId.lessThanOrEqual'] = requestParameters.forestryIdLessThanOrEqual;
        }

        if (requestParameters.forestryIdEquals !== undefined) {
            queryParameters['forestryId.equals'] = requestParameters.forestryIdEquals;
        }

        if (requestParameters.forestryIdNotEquals !== undefined) {
            queryParameters['forestryId.notEquals'] = requestParameters.forestryIdNotEquals;
        }

        if (requestParameters.forestryIdSpecified !== undefined) {
            queryParameters['forestryId.specified'] = requestParameters.forestryIdSpecified;
        }

        if (requestParameters.forestryIdIn) {
            queryParameters['forestryId.in'] = requestParameters.forestryIdIn;
        }

        if (requestParameters.forestryIdNotIn) {
            queryParameters['forestryId.notIn'] = requestParameters.forestryIdNotIn;
        }

        if (requestParameters.sawmillIdGreaterThan !== undefined) {
            queryParameters['sawmillId.greaterThan'] = requestParameters.sawmillIdGreaterThan;
        }

        if (requestParameters.sawmillIdLessThan !== undefined) {
            queryParameters['sawmillId.lessThan'] = requestParameters.sawmillIdLessThan;
        }

        if (requestParameters.sawmillIdGreaterThanOrEqual !== undefined) {
            queryParameters['sawmillId.greaterThanOrEqual'] = requestParameters.sawmillIdGreaterThanOrEqual;
        }

        if (requestParameters.sawmillIdLessThanOrEqual !== undefined) {
            queryParameters['sawmillId.lessThanOrEqual'] = requestParameters.sawmillIdLessThanOrEqual;
        }

        if (requestParameters.sawmillIdEquals !== undefined) {
            queryParameters['sawmillId.equals'] = requestParameters.sawmillIdEquals;
        }

        if (requestParameters.sawmillIdNotEquals !== undefined) {
            queryParameters['sawmillId.notEquals'] = requestParameters.sawmillIdNotEquals;
        }

        if (requestParameters.sawmillIdSpecified !== undefined) {
            queryParameters['sawmillId.specified'] = requestParameters.sawmillIdSpecified;
        }

        if (requestParameters.sawmillIdIn) {
            queryParameters['sawmillId.in'] = requestParameters.sawmillIdIn;
        }

        if (requestParameters.sawmillIdNotIn) {
            queryParameters['sawmillId.notIn'] = requestParameters.sawmillIdNotIn;
        }

        if (requestParameters.deliverySheetErpImportationIdGreaterThan !== undefined) {
            queryParameters['deliverySheetErpImportationId.greaterThan'] = requestParameters.deliverySheetErpImportationIdGreaterThan;
        }

        if (requestParameters.deliverySheetErpImportationIdLessThan !== undefined) {
            queryParameters['deliverySheetErpImportationId.lessThan'] = requestParameters.deliverySheetErpImportationIdLessThan;
        }

        if (requestParameters.deliverySheetErpImportationIdGreaterThanOrEqual !== undefined) {
            queryParameters['deliverySheetErpImportationId.greaterThanOrEqual'] = requestParameters.deliverySheetErpImportationIdGreaterThanOrEqual;
        }

        if (requestParameters.deliverySheetErpImportationIdLessThanOrEqual !== undefined) {
            queryParameters['deliverySheetErpImportationId.lessThanOrEqual'] = requestParameters.deliverySheetErpImportationIdLessThanOrEqual;
        }

        if (requestParameters.deliverySheetErpImportationIdEquals !== undefined) {
            queryParameters['deliverySheetErpImportationId.equals'] = requestParameters.deliverySheetErpImportationIdEquals;
        }

        if (requestParameters.deliverySheetErpImportationIdNotEquals !== undefined) {
            queryParameters['deliverySheetErpImportationId.notEquals'] = requestParameters.deliverySheetErpImportationIdNotEquals;
        }

        if (requestParameters.deliverySheetErpImportationIdSpecified !== undefined) {
            queryParameters['deliverySheetErpImportationId.specified'] = requestParameters.deliverySheetErpImportationIdSpecified;
        }

        if (requestParameters.deliverySheetErpImportationIdIn) {
            queryParameters['deliverySheetErpImportationId.in'] = requestParameters.deliverySheetErpImportationIdIn;
        }

        if (requestParameters.deliverySheetErpImportationIdNotIn) {
            queryParameters['deliverySheetErpImportationId.notIn'] = requestParameters.deliverySheetErpImportationIdNotIn;
        }

        if (requestParameters.deliverySheetPhotoIdGreaterThan !== undefined) {
            queryParameters['deliverySheetPhotoId.greaterThan'] = requestParameters.deliverySheetPhotoIdGreaterThan;
        }

        if (requestParameters.deliverySheetPhotoIdLessThan !== undefined) {
            queryParameters['deliverySheetPhotoId.lessThan'] = requestParameters.deliverySheetPhotoIdLessThan;
        }

        if (requestParameters.deliverySheetPhotoIdGreaterThanOrEqual !== undefined) {
            queryParameters['deliverySheetPhotoId.greaterThanOrEqual'] = requestParameters.deliverySheetPhotoIdGreaterThanOrEqual;
        }

        if (requestParameters.deliverySheetPhotoIdLessThanOrEqual !== undefined) {
            queryParameters['deliverySheetPhotoId.lessThanOrEqual'] = requestParameters.deliverySheetPhotoIdLessThanOrEqual;
        }

        if (requestParameters.deliverySheetPhotoIdEquals !== undefined) {
            queryParameters['deliverySheetPhotoId.equals'] = requestParameters.deliverySheetPhotoIdEquals;
        }

        if (requestParameters.deliverySheetPhotoIdNotEquals !== undefined) {
            queryParameters['deliverySheetPhotoId.notEquals'] = requestParameters.deliverySheetPhotoIdNotEquals;
        }

        if (requestParameters.deliverySheetPhotoIdSpecified !== undefined) {
            queryParameters['deliverySheetPhotoId.specified'] = requestParameters.deliverySheetPhotoIdSpecified;
        }

        if (requestParameters.deliverySheetPhotoIdIn) {
            queryParameters['deliverySheetPhotoId.in'] = requestParameters.deliverySheetPhotoIdIn;
        }

        if (requestParameters.deliverySheetPhotoIdNotIn) {
            queryParameters['deliverySheetPhotoId.notIn'] = requestParameters.deliverySheetPhotoIdNotIn;
        }

        if (requestParameters.deliverySheetStateIdGreaterThan !== undefined) {
            queryParameters['deliverySheetStateId.greaterThan'] = requestParameters.deliverySheetStateIdGreaterThan;
        }

        if (requestParameters.deliverySheetStateIdLessThan !== undefined) {
            queryParameters['deliverySheetStateId.lessThan'] = requestParameters.deliverySheetStateIdLessThan;
        }

        if (requestParameters.deliverySheetStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['deliverySheetStateId.greaterThanOrEqual'] = requestParameters.deliverySheetStateIdGreaterThanOrEqual;
        }

        if (requestParameters.deliverySheetStateIdLessThanOrEqual !== undefined) {
            queryParameters['deliverySheetStateId.lessThanOrEqual'] = requestParameters.deliverySheetStateIdLessThanOrEqual;
        }

        if (requestParameters.deliverySheetStateIdEquals !== undefined) {
            queryParameters['deliverySheetStateId.equals'] = requestParameters.deliverySheetStateIdEquals;
        }

        if (requestParameters.deliverySheetStateIdNotEquals !== undefined) {
            queryParameters['deliverySheetStateId.notEquals'] = requestParameters.deliverySheetStateIdNotEquals;
        }

        if (requestParameters.deliverySheetStateIdSpecified !== undefined) {
            queryParameters['deliverySheetStateId.specified'] = requestParameters.deliverySheetStateIdSpecified;
        }

        if (requestParameters.deliverySheetStateIdIn) {
            queryParameters['deliverySheetStateId.in'] = requestParameters.deliverySheetStateIdIn;
        }

        if (requestParameters.deliverySheetStateIdNotIn) {
            queryParameters['deliverySheetStateId.notIn'] = requestParameters.deliverySheetStateIdNotIn;
        }

        if (requestParameters.trunkIdGreaterThan !== undefined) {
            queryParameters['trunkId.greaterThan'] = requestParameters.trunkIdGreaterThan;
        }

        if (requestParameters.trunkIdLessThan !== undefined) {
            queryParameters['trunkId.lessThan'] = requestParameters.trunkIdLessThan;
        }

        if (requestParameters.trunkIdGreaterThanOrEqual !== undefined) {
            queryParameters['trunkId.greaterThanOrEqual'] = requestParameters.trunkIdGreaterThanOrEqual;
        }

        if (requestParameters.trunkIdLessThanOrEqual !== undefined) {
            queryParameters['trunkId.lessThanOrEqual'] = requestParameters.trunkIdLessThanOrEqual;
        }

        if (requestParameters.trunkIdEquals !== undefined) {
            queryParameters['trunkId.equals'] = requestParameters.trunkIdEquals;
        }

        if (requestParameters.trunkIdNotEquals !== undefined) {
            queryParameters['trunkId.notEquals'] = requestParameters.trunkIdNotEquals;
        }

        if (requestParameters.trunkIdSpecified !== undefined) {
            queryParameters['trunkId.specified'] = requestParameters.trunkIdSpecified;
        }

        if (requestParameters.trunkIdIn) {
            queryParameters['trunkId.in'] = requestParameters.trunkIdIn;
        }

        if (requestParameters.trunkIdNotIn) {
            queryParameters['trunkId.notIn'] = requestParameters.trunkIdNotIn;
        }

        if (requestParameters.batchIdGreaterThan !== undefined) {
            queryParameters['batchId.greaterThan'] = requestParameters.batchIdGreaterThan;
        }

        if (requestParameters.batchIdLessThan !== undefined) {
            queryParameters['batchId.lessThan'] = requestParameters.batchIdLessThan;
        }

        if (requestParameters.batchIdGreaterThanOrEqual !== undefined) {
            queryParameters['batchId.greaterThanOrEqual'] = requestParameters.batchIdGreaterThanOrEqual;
        }

        if (requestParameters.batchIdLessThanOrEqual !== undefined) {
            queryParameters['batchId.lessThanOrEqual'] = requestParameters.batchIdLessThanOrEqual;
        }

        if (requestParameters.batchIdEquals !== undefined) {
            queryParameters['batchId.equals'] = requestParameters.batchIdEquals;
        }

        if (requestParameters.batchIdNotEquals !== undefined) {
            queryParameters['batchId.notEquals'] = requestParameters.batchIdNotEquals;
        }

        if (requestParameters.batchIdSpecified !== undefined) {
            queryParameters['batchId.specified'] = requestParameters.batchIdSpecified;
        }

        if (requestParameters.batchIdIn) {
            queryParameters['batchId.in'] = requestParameters.batchIdIn;
        }

        if (requestParameters.batchIdNotIn) {
            queryParameters['batchId.notIn'] = requestParameters.batchIdNotIn;
        }

        if (requestParameters.packetIdGreaterThan !== undefined) {
            queryParameters['packetId.greaterThan'] = requestParameters.packetIdGreaterThan;
        }

        if (requestParameters.packetIdLessThan !== undefined) {
            queryParameters['packetId.lessThan'] = requestParameters.packetIdLessThan;
        }

        if (requestParameters.packetIdGreaterThanOrEqual !== undefined) {
            queryParameters['packetId.greaterThanOrEqual'] = requestParameters.packetIdGreaterThanOrEqual;
        }

        if (requestParameters.packetIdLessThanOrEqual !== undefined) {
            queryParameters['packetId.lessThanOrEqual'] = requestParameters.packetIdLessThanOrEqual;
        }

        if (requestParameters.packetIdEquals !== undefined) {
            queryParameters['packetId.equals'] = requestParameters.packetIdEquals;
        }

        if (requestParameters.packetIdNotEquals !== undefined) {
            queryParameters['packetId.notEquals'] = requestParameters.packetIdNotEquals;
        }

        if (requestParameters.packetIdSpecified !== undefined) {
            queryParameters['packetId.specified'] = requestParameters.packetIdSpecified;
        }

        if (requestParameters.packetIdIn) {
            queryParameters['packetId.in'] = requestParameters.packetIdIn;
        }

        if (requestParameters.packetIdNotIn) {
            queryParameters['packetId.notIn'] = requestParameters.packetIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheets/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countDeliverySheets(requestParameters: CountDeliverySheetsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countDeliverySheetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createDeliverySheetRaw(requestParameters: CreateDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheet>> {
        if (requestParameters.deliverySheet === null || requestParameters.deliverySheet === undefined) {
            throw new runtime.RequiredError('deliverySheet','Required parameter requestParameters.deliverySheet was null or undefined when calling createDeliverySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetToJSON(requestParameters.deliverySheet),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetFromJSON(jsonValue));
    }

    /**
     */
    async createDeliverySheet(requestParameters: CreateDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheet> {
        const response = await this.createDeliverySheetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteDeliverySheetRaw(requestParameters: DeleteDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDeliverySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDeliverySheet(requestParameters: DeleteDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDeliverySheetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllDeliverySheetsRaw(requestParameters: GetAllDeliverySheetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeliverySheet>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.sentToSawmillEquals !== undefined) {
            queryParameters['sentToSawmill.equals'] = requestParameters.sentToSawmillEquals;
        }

        if (requestParameters.sentToSawmillNotEquals !== undefined) {
            queryParameters['sentToSawmill.notEquals'] = requestParameters.sentToSawmillNotEquals;
        }

        if (requestParameters.sentToSawmillSpecified !== undefined) {
            queryParameters['sentToSawmill.specified'] = requestParameters.sentToSawmillSpecified;
        }

        if (requestParameters.sentToSawmillIn) {
            queryParameters['sentToSawmill.in'] = requestParameters.sentToSawmillIn;
        }

        if (requestParameters.sentToSawmillNotIn) {
            queryParameters['sentToSawmill.notIn'] = requestParameters.sentToSawmillNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.currentUrstammStateEquals !== undefined) {
            queryParameters['currentUrstammState.equals'] = requestParameters.currentUrstammStateEquals;
        }

        if (requestParameters.currentUrstammStateNotEquals !== undefined) {
            queryParameters['currentUrstammState.notEquals'] = requestParameters.currentUrstammStateNotEquals;
        }

        if (requestParameters.currentUrstammStateSpecified !== undefined) {
            queryParameters['currentUrstammState.specified'] = requestParameters.currentUrstammStateSpecified;
        }

        if (requestParameters.currentUrstammStateIn) {
            queryParameters['currentUrstammState.in'] = requestParameters.currentUrstammStateIn;
        }

        if (requestParameters.currentUrstammStateNotIn) {
            queryParameters['currentUrstammState.notIn'] = requestParameters.currentUrstammStateNotIn;
        }

        if (requestParameters.recordingTypeEquals !== undefined) {
            queryParameters['recordingType.equals'] = requestParameters.recordingTypeEquals;
        }

        if (requestParameters.recordingTypeNotEquals !== undefined) {
            queryParameters['recordingType.notEquals'] = requestParameters.recordingTypeNotEquals;
        }

        if (requestParameters.recordingTypeSpecified !== undefined) {
            queryParameters['recordingType.specified'] = requestParameters.recordingTypeSpecified;
        }

        if (requestParameters.recordingTypeIn) {
            queryParameters['recordingType.in'] = requestParameters.recordingTypeIn;
        }

        if (requestParameters.recordingTypeNotIn) {
            queryParameters['recordingType.notIn'] = requestParameters.recordingTypeNotIn;
        }

        if (requestParameters.automaticallyCreatedEquals !== undefined) {
            queryParameters['automaticallyCreated.equals'] = requestParameters.automaticallyCreatedEquals;
        }

        if (requestParameters.automaticallyCreatedNotEquals !== undefined) {
            queryParameters['automaticallyCreated.notEquals'] = requestParameters.automaticallyCreatedNotEquals;
        }

        if (requestParameters.automaticallyCreatedSpecified !== undefined) {
            queryParameters['automaticallyCreated.specified'] = requestParameters.automaticallyCreatedSpecified;
        }

        if (requestParameters.automaticallyCreatedIn) {
            queryParameters['automaticallyCreated.in'] = requestParameters.automaticallyCreatedIn;
        }

        if (requestParameters.automaticallyCreatedNotIn) {
            queryParameters['automaticallyCreated.notIn'] = requestParameters.automaticallyCreatedNotIn;
        }

        if (requestParameters.amountOfTruckJourneysGreaterThan !== undefined) {
            queryParameters['amountOfTruckJourneys.greaterThan'] = requestParameters.amountOfTruckJourneysGreaterThan;
        }

        if (requestParameters.amountOfTruckJourneysLessThan !== undefined) {
            queryParameters['amountOfTruckJourneys.lessThan'] = requestParameters.amountOfTruckJourneysLessThan;
        }

        if (requestParameters.amountOfTruckJourneysGreaterThanOrEqual !== undefined) {
            queryParameters['amountOfTruckJourneys.greaterThanOrEqual'] = requestParameters.amountOfTruckJourneysGreaterThanOrEqual;
        }

        if (requestParameters.amountOfTruckJourneysLessThanOrEqual !== undefined) {
            queryParameters['amountOfTruckJourneys.lessThanOrEqual'] = requestParameters.amountOfTruckJourneysLessThanOrEqual;
        }

        if (requestParameters.amountOfTruckJourneysEquals !== undefined) {
            queryParameters['amountOfTruckJourneys.equals'] = requestParameters.amountOfTruckJourneysEquals;
        }

        if (requestParameters.amountOfTruckJourneysNotEquals !== undefined) {
            queryParameters['amountOfTruckJourneys.notEquals'] = requestParameters.amountOfTruckJourneysNotEquals;
        }

        if (requestParameters.amountOfTruckJourneysSpecified !== undefined) {
            queryParameters['amountOfTruckJourneys.specified'] = requestParameters.amountOfTruckJourneysSpecified;
        }

        if (requestParameters.amountOfTruckJourneysIn) {
            queryParameters['amountOfTruckJourneys.in'] = requestParameters.amountOfTruckJourneysIn;
        }

        if (requestParameters.amountOfTruckJourneysNotIn) {
            queryParameters['amountOfTruckJourneys.notIn'] = requestParameters.amountOfTruckJourneysNotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.pileIdGreaterThan !== undefined) {
            queryParameters['pileId.greaterThan'] = requestParameters.pileIdGreaterThan;
        }

        if (requestParameters.pileIdLessThan !== undefined) {
            queryParameters['pileId.lessThan'] = requestParameters.pileIdLessThan;
        }

        if (requestParameters.pileIdGreaterThanOrEqual !== undefined) {
            queryParameters['pileId.greaterThanOrEqual'] = requestParameters.pileIdGreaterThanOrEqual;
        }

        if (requestParameters.pileIdLessThanOrEqual !== undefined) {
            queryParameters['pileId.lessThanOrEqual'] = requestParameters.pileIdLessThanOrEqual;
        }

        if (requestParameters.pileIdEquals !== undefined) {
            queryParameters['pileId.equals'] = requestParameters.pileIdEquals;
        }

        if (requestParameters.pileIdNotEquals !== undefined) {
            queryParameters['pileId.notEquals'] = requestParameters.pileIdNotEquals;
        }

        if (requestParameters.pileIdSpecified !== undefined) {
            queryParameters['pileId.specified'] = requestParameters.pileIdSpecified;
        }

        if (requestParameters.pileIdIn) {
            queryParameters['pileId.in'] = requestParameters.pileIdIn;
        }

        if (requestParameters.pileIdNotIn) {
            queryParameters['pileId.notIn'] = requestParameters.pileIdNotIn;
        }

        if (requestParameters.forestryIdGreaterThan !== undefined) {
            queryParameters['forestryId.greaterThan'] = requestParameters.forestryIdGreaterThan;
        }

        if (requestParameters.forestryIdLessThan !== undefined) {
            queryParameters['forestryId.lessThan'] = requestParameters.forestryIdLessThan;
        }

        if (requestParameters.forestryIdGreaterThanOrEqual !== undefined) {
            queryParameters['forestryId.greaterThanOrEqual'] = requestParameters.forestryIdGreaterThanOrEqual;
        }

        if (requestParameters.forestryIdLessThanOrEqual !== undefined) {
            queryParameters['forestryId.lessThanOrEqual'] = requestParameters.forestryIdLessThanOrEqual;
        }

        if (requestParameters.forestryIdEquals !== undefined) {
            queryParameters['forestryId.equals'] = requestParameters.forestryIdEquals;
        }

        if (requestParameters.forestryIdNotEquals !== undefined) {
            queryParameters['forestryId.notEquals'] = requestParameters.forestryIdNotEquals;
        }

        if (requestParameters.forestryIdSpecified !== undefined) {
            queryParameters['forestryId.specified'] = requestParameters.forestryIdSpecified;
        }

        if (requestParameters.forestryIdIn) {
            queryParameters['forestryId.in'] = requestParameters.forestryIdIn;
        }

        if (requestParameters.forestryIdNotIn) {
            queryParameters['forestryId.notIn'] = requestParameters.forestryIdNotIn;
        }

        if (requestParameters.sawmillIdGreaterThan !== undefined) {
            queryParameters['sawmillId.greaterThan'] = requestParameters.sawmillIdGreaterThan;
        }

        if (requestParameters.sawmillIdLessThan !== undefined) {
            queryParameters['sawmillId.lessThan'] = requestParameters.sawmillIdLessThan;
        }

        if (requestParameters.sawmillIdGreaterThanOrEqual !== undefined) {
            queryParameters['sawmillId.greaterThanOrEqual'] = requestParameters.sawmillIdGreaterThanOrEqual;
        }

        if (requestParameters.sawmillIdLessThanOrEqual !== undefined) {
            queryParameters['sawmillId.lessThanOrEqual'] = requestParameters.sawmillIdLessThanOrEqual;
        }

        if (requestParameters.sawmillIdEquals !== undefined) {
            queryParameters['sawmillId.equals'] = requestParameters.sawmillIdEquals;
        }

        if (requestParameters.sawmillIdNotEquals !== undefined) {
            queryParameters['sawmillId.notEquals'] = requestParameters.sawmillIdNotEquals;
        }

        if (requestParameters.sawmillIdSpecified !== undefined) {
            queryParameters['sawmillId.specified'] = requestParameters.sawmillIdSpecified;
        }

        if (requestParameters.sawmillIdIn) {
            queryParameters['sawmillId.in'] = requestParameters.sawmillIdIn;
        }

        if (requestParameters.sawmillIdNotIn) {
            queryParameters['sawmillId.notIn'] = requestParameters.sawmillIdNotIn;
        }

        if (requestParameters.deliverySheetErpImportationIdGreaterThan !== undefined) {
            queryParameters['deliverySheetErpImportationId.greaterThan'] = requestParameters.deliverySheetErpImportationIdGreaterThan;
        }

        if (requestParameters.deliverySheetErpImportationIdLessThan !== undefined) {
            queryParameters['deliverySheetErpImportationId.lessThan'] = requestParameters.deliverySheetErpImportationIdLessThan;
        }

        if (requestParameters.deliverySheetErpImportationIdGreaterThanOrEqual !== undefined) {
            queryParameters['deliverySheetErpImportationId.greaterThanOrEqual'] = requestParameters.deliverySheetErpImportationIdGreaterThanOrEqual;
        }

        if (requestParameters.deliverySheetErpImportationIdLessThanOrEqual !== undefined) {
            queryParameters['deliverySheetErpImportationId.lessThanOrEqual'] = requestParameters.deliverySheetErpImportationIdLessThanOrEqual;
        }

        if (requestParameters.deliverySheetErpImportationIdEquals !== undefined) {
            queryParameters['deliverySheetErpImportationId.equals'] = requestParameters.deliverySheetErpImportationIdEquals;
        }

        if (requestParameters.deliverySheetErpImportationIdNotEquals !== undefined) {
            queryParameters['deliverySheetErpImportationId.notEquals'] = requestParameters.deliverySheetErpImportationIdNotEquals;
        }

        if (requestParameters.deliverySheetErpImportationIdSpecified !== undefined) {
            queryParameters['deliverySheetErpImportationId.specified'] = requestParameters.deliverySheetErpImportationIdSpecified;
        }

        if (requestParameters.deliverySheetErpImportationIdIn) {
            queryParameters['deliverySheetErpImportationId.in'] = requestParameters.deliverySheetErpImportationIdIn;
        }

        if (requestParameters.deliverySheetErpImportationIdNotIn) {
            queryParameters['deliverySheetErpImportationId.notIn'] = requestParameters.deliverySheetErpImportationIdNotIn;
        }

        if (requestParameters.deliverySheetPhotoIdGreaterThan !== undefined) {
            queryParameters['deliverySheetPhotoId.greaterThan'] = requestParameters.deliverySheetPhotoIdGreaterThan;
        }

        if (requestParameters.deliverySheetPhotoIdLessThan !== undefined) {
            queryParameters['deliverySheetPhotoId.lessThan'] = requestParameters.deliverySheetPhotoIdLessThan;
        }

        if (requestParameters.deliverySheetPhotoIdGreaterThanOrEqual !== undefined) {
            queryParameters['deliverySheetPhotoId.greaterThanOrEqual'] = requestParameters.deliverySheetPhotoIdGreaterThanOrEqual;
        }

        if (requestParameters.deliverySheetPhotoIdLessThanOrEqual !== undefined) {
            queryParameters['deliverySheetPhotoId.lessThanOrEqual'] = requestParameters.deliverySheetPhotoIdLessThanOrEqual;
        }

        if (requestParameters.deliverySheetPhotoIdEquals !== undefined) {
            queryParameters['deliverySheetPhotoId.equals'] = requestParameters.deliverySheetPhotoIdEquals;
        }

        if (requestParameters.deliverySheetPhotoIdNotEquals !== undefined) {
            queryParameters['deliverySheetPhotoId.notEquals'] = requestParameters.deliverySheetPhotoIdNotEquals;
        }

        if (requestParameters.deliverySheetPhotoIdSpecified !== undefined) {
            queryParameters['deliverySheetPhotoId.specified'] = requestParameters.deliverySheetPhotoIdSpecified;
        }

        if (requestParameters.deliverySheetPhotoIdIn) {
            queryParameters['deliverySheetPhotoId.in'] = requestParameters.deliverySheetPhotoIdIn;
        }

        if (requestParameters.deliverySheetPhotoIdNotIn) {
            queryParameters['deliverySheetPhotoId.notIn'] = requestParameters.deliverySheetPhotoIdNotIn;
        }

        if (requestParameters.deliverySheetStateIdGreaterThan !== undefined) {
            queryParameters['deliverySheetStateId.greaterThan'] = requestParameters.deliverySheetStateIdGreaterThan;
        }

        if (requestParameters.deliverySheetStateIdLessThan !== undefined) {
            queryParameters['deliverySheetStateId.lessThan'] = requestParameters.deliverySheetStateIdLessThan;
        }

        if (requestParameters.deliverySheetStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['deliverySheetStateId.greaterThanOrEqual'] = requestParameters.deliverySheetStateIdGreaterThanOrEqual;
        }

        if (requestParameters.deliverySheetStateIdLessThanOrEqual !== undefined) {
            queryParameters['deliverySheetStateId.lessThanOrEqual'] = requestParameters.deliverySheetStateIdLessThanOrEqual;
        }

        if (requestParameters.deliverySheetStateIdEquals !== undefined) {
            queryParameters['deliverySheetStateId.equals'] = requestParameters.deliverySheetStateIdEquals;
        }

        if (requestParameters.deliverySheetStateIdNotEquals !== undefined) {
            queryParameters['deliverySheetStateId.notEquals'] = requestParameters.deliverySheetStateIdNotEquals;
        }

        if (requestParameters.deliverySheetStateIdSpecified !== undefined) {
            queryParameters['deliverySheetStateId.specified'] = requestParameters.deliverySheetStateIdSpecified;
        }

        if (requestParameters.deliverySheetStateIdIn) {
            queryParameters['deliverySheetStateId.in'] = requestParameters.deliverySheetStateIdIn;
        }

        if (requestParameters.deliverySheetStateIdNotIn) {
            queryParameters['deliverySheetStateId.notIn'] = requestParameters.deliverySheetStateIdNotIn;
        }

        if (requestParameters.trunkIdGreaterThan !== undefined) {
            queryParameters['trunkId.greaterThan'] = requestParameters.trunkIdGreaterThan;
        }

        if (requestParameters.trunkIdLessThan !== undefined) {
            queryParameters['trunkId.lessThan'] = requestParameters.trunkIdLessThan;
        }

        if (requestParameters.trunkIdGreaterThanOrEqual !== undefined) {
            queryParameters['trunkId.greaterThanOrEqual'] = requestParameters.trunkIdGreaterThanOrEqual;
        }

        if (requestParameters.trunkIdLessThanOrEqual !== undefined) {
            queryParameters['trunkId.lessThanOrEqual'] = requestParameters.trunkIdLessThanOrEqual;
        }

        if (requestParameters.trunkIdEquals !== undefined) {
            queryParameters['trunkId.equals'] = requestParameters.trunkIdEquals;
        }

        if (requestParameters.trunkIdNotEquals !== undefined) {
            queryParameters['trunkId.notEquals'] = requestParameters.trunkIdNotEquals;
        }

        if (requestParameters.trunkIdSpecified !== undefined) {
            queryParameters['trunkId.specified'] = requestParameters.trunkIdSpecified;
        }

        if (requestParameters.trunkIdIn) {
            queryParameters['trunkId.in'] = requestParameters.trunkIdIn;
        }

        if (requestParameters.trunkIdNotIn) {
            queryParameters['trunkId.notIn'] = requestParameters.trunkIdNotIn;
        }

        if (requestParameters.batchIdGreaterThan !== undefined) {
            queryParameters['batchId.greaterThan'] = requestParameters.batchIdGreaterThan;
        }

        if (requestParameters.batchIdLessThan !== undefined) {
            queryParameters['batchId.lessThan'] = requestParameters.batchIdLessThan;
        }

        if (requestParameters.batchIdGreaterThanOrEqual !== undefined) {
            queryParameters['batchId.greaterThanOrEqual'] = requestParameters.batchIdGreaterThanOrEqual;
        }

        if (requestParameters.batchIdLessThanOrEqual !== undefined) {
            queryParameters['batchId.lessThanOrEqual'] = requestParameters.batchIdLessThanOrEqual;
        }

        if (requestParameters.batchIdEquals !== undefined) {
            queryParameters['batchId.equals'] = requestParameters.batchIdEquals;
        }

        if (requestParameters.batchIdNotEquals !== undefined) {
            queryParameters['batchId.notEquals'] = requestParameters.batchIdNotEquals;
        }

        if (requestParameters.batchIdSpecified !== undefined) {
            queryParameters['batchId.specified'] = requestParameters.batchIdSpecified;
        }

        if (requestParameters.batchIdIn) {
            queryParameters['batchId.in'] = requestParameters.batchIdIn;
        }

        if (requestParameters.batchIdNotIn) {
            queryParameters['batchId.notIn'] = requestParameters.batchIdNotIn;
        }

        if (requestParameters.packetIdGreaterThan !== undefined) {
            queryParameters['packetId.greaterThan'] = requestParameters.packetIdGreaterThan;
        }

        if (requestParameters.packetIdLessThan !== undefined) {
            queryParameters['packetId.lessThan'] = requestParameters.packetIdLessThan;
        }

        if (requestParameters.packetIdGreaterThanOrEqual !== undefined) {
            queryParameters['packetId.greaterThanOrEqual'] = requestParameters.packetIdGreaterThanOrEqual;
        }

        if (requestParameters.packetIdLessThanOrEqual !== undefined) {
            queryParameters['packetId.lessThanOrEqual'] = requestParameters.packetIdLessThanOrEqual;
        }

        if (requestParameters.packetIdEquals !== undefined) {
            queryParameters['packetId.equals'] = requestParameters.packetIdEquals;
        }

        if (requestParameters.packetIdNotEquals !== undefined) {
            queryParameters['packetId.notEquals'] = requestParameters.packetIdNotEquals;
        }

        if (requestParameters.packetIdSpecified !== undefined) {
            queryParameters['packetId.specified'] = requestParameters.packetIdSpecified;
        }

        if (requestParameters.packetIdIn) {
            queryParameters['packetId.in'] = requestParameters.packetIdIn;
        }

        if (requestParameters.packetIdNotIn) {
            queryParameters['packetId.notIn'] = requestParameters.packetIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliverySheetFromJSON));
    }

    /**
     */
    async getAllDeliverySheets(requestParameters: GetAllDeliverySheetsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeliverySheet>> {
        const response = await this.getAllDeliverySheetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDeliverySheetRaw(requestParameters: GetDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDeliverySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetFromJSON(jsonValue));
    }

    /**
     */
    async getDeliverySheet(requestParameters: GetDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheet> {
        const response = await this.getDeliverySheetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateDeliverySheetRaw(requestParameters: PartialUpdateDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateDeliverySheet.');
        }

        if (requestParameters.deliverySheet === null || requestParameters.deliverySheet === undefined) {
            throw new runtime.RequiredError('deliverySheet','Required parameter requestParameters.deliverySheet was null or undefined when calling partialUpdateDeliverySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetToJSON(requestParameters.deliverySheet),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateDeliverySheet(requestParameters: PartialUpdateDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheet> {
        const response = await this.partialUpdateDeliverySheetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateDeliverySheetRaw(requestParameters: UpdateDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDeliverySheet.');
        }

        if (requestParameters.deliverySheet === null || requestParameters.deliverySheet === undefined) {
            throw new runtime.RequiredError('deliverySheet','Required parameter requestParameters.deliverySheet was null or undefined when calling updateDeliverySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetToJSON(requestParameters.deliverySheet),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetFromJSON(jsonValue));
    }

    /**
     */
    async updateDeliverySheet(requestParameters: UpdateDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheet> {
        const response = await this.updateDeliverySheetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CountDeliverySheetsCurrentStateEqualsEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type CountDeliverySheetsCurrentStateEqualsEnum = typeof CountDeliverySheetsCurrentStateEqualsEnum[keyof typeof CountDeliverySheetsCurrentStateEqualsEnum];
/**
 * @export
 */
export const CountDeliverySheetsCurrentStateNotEqualsEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type CountDeliverySheetsCurrentStateNotEqualsEnum = typeof CountDeliverySheetsCurrentStateNotEqualsEnum[keyof typeof CountDeliverySheetsCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const CountDeliverySheetsCurrentStateInEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type CountDeliverySheetsCurrentStateInEnum = typeof CountDeliverySheetsCurrentStateInEnum[keyof typeof CountDeliverySheetsCurrentStateInEnum];
/**
 * @export
 */
export const CountDeliverySheetsCurrentStateNotInEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type CountDeliverySheetsCurrentStateNotInEnum = typeof CountDeliverySheetsCurrentStateNotInEnum[keyof typeof CountDeliverySheetsCurrentStateNotInEnum];
/**
 * @export
 */
export const CountDeliverySheetsCurrentUrstammStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type CountDeliverySheetsCurrentUrstammStateEqualsEnum = typeof CountDeliverySheetsCurrentUrstammStateEqualsEnum[keyof typeof CountDeliverySheetsCurrentUrstammStateEqualsEnum];
/**
 * @export
 */
export const CountDeliverySheetsCurrentUrstammStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type CountDeliverySheetsCurrentUrstammStateNotEqualsEnum = typeof CountDeliverySheetsCurrentUrstammStateNotEqualsEnum[keyof typeof CountDeliverySheetsCurrentUrstammStateNotEqualsEnum];
/**
 * @export
 */
export const CountDeliverySheetsCurrentUrstammStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type CountDeliverySheetsCurrentUrstammStateInEnum = typeof CountDeliverySheetsCurrentUrstammStateInEnum[keyof typeof CountDeliverySheetsCurrentUrstammStateInEnum];
/**
 * @export
 */
export const CountDeliverySheetsCurrentUrstammStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type CountDeliverySheetsCurrentUrstammStateNotInEnum = typeof CountDeliverySheetsCurrentUrstammStateNotInEnum[keyof typeof CountDeliverySheetsCurrentUrstammStateNotInEnum];
/**
 * @export
 */
export const CountDeliverySheetsRecordingTypeEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountDeliverySheetsRecordingTypeEqualsEnum = typeof CountDeliverySheetsRecordingTypeEqualsEnum[keyof typeof CountDeliverySheetsRecordingTypeEqualsEnum];
/**
 * @export
 */
export const CountDeliverySheetsRecordingTypeNotEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountDeliverySheetsRecordingTypeNotEqualsEnum = typeof CountDeliverySheetsRecordingTypeNotEqualsEnum[keyof typeof CountDeliverySheetsRecordingTypeNotEqualsEnum];
/**
 * @export
 */
export const CountDeliverySheetsRecordingTypeInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountDeliverySheetsRecordingTypeInEnum = typeof CountDeliverySheetsRecordingTypeInEnum[keyof typeof CountDeliverySheetsRecordingTypeInEnum];
/**
 * @export
 */
export const CountDeliverySheetsRecordingTypeNotInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountDeliverySheetsRecordingTypeNotInEnum = typeof CountDeliverySheetsRecordingTypeNotInEnum[keyof typeof CountDeliverySheetsRecordingTypeNotInEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsCurrentStateEqualsEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type GetAllDeliverySheetsCurrentStateEqualsEnum = typeof GetAllDeliverySheetsCurrentStateEqualsEnum[keyof typeof GetAllDeliverySheetsCurrentStateEqualsEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsCurrentStateNotEqualsEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type GetAllDeliverySheetsCurrentStateNotEqualsEnum = typeof GetAllDeliverySheetsCurrentStateNotEqualsEnum[keyof typeof GetAllDeliverySheetsCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsCurrentStateInEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type GetAllDeliverySheetsCurrentStateInEnum = typeof GetAllDeliverySheetsCurrentStateInEnum[keyof typeof GetAllDeliverySheetsCurrentStateInEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsCurrentStateNotInEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type GetAllDeliverySheetsCurrentStateNotInEnum = typeof GetAllDeliverySheetsCurrentStateNotInEnum[keyof typeof GetAllDeliverySheetsCurrentStateNotInEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsCurrentUrstammStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type GetAllDeliverySheetsCurrentUrstammStateEqualsEnum = typeof GetAllDeliverySheetsCurrentUrstammStateEqualsEnum[keyof typeof GetAllDeliverySheetsCurrentUrstammStateEqualsEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsCurrentUrstammStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type GetAllDeliverySheetsCurrentUrstammStateNotEqualsEnum = typeof GetAllDeliverySheetsCurrentUrstammStateNotEqualsEnum[keyof typeof GetAllDeliverySheetsCurrentUrstammStateNotEqualsEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsCurrentUrstammStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type GetAllDeliverySheetsCurrentUrstammStateInEnum = typeof GetAllDeliverySheetsCurrentUrstammStateInEnum[keyof typeof GetAllDeliverySheetsCurrentUrstammStateInEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsCurrentUrstammStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type GetAllDeliverySheetsCurrentUrstammStateNotInEnum = typeof GetAllDeliverySheetsCurrentUrstammStateNotInEnum[keyof typeof GetAllDeliverySheetsCurrentUrstammStateNotInEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsRecordingTypeEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllDeliverySheetsRecordingTypeEqualsEnum = typeof GetAllDeliverySheetsRecordingTypeEqualsEnum[keyof typeof GetAllDeliverySheetsRecordingTypeEqualsEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsRecordingTypeNotEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllDeliverySheetsRecordingTypeNotEqualsEnum = typeof GetAllDeliverySheetsRecordingTypeNotEqualsEnum[keyof typeof GetAllDeliverySheetsRecordingTypeNotEqualsEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsRecordingTypeInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllDeliverySheetsRecordingTypeInEnum = typeof GetAllDeliverySheetsRecordingTypeInEnum[keyof typeof GetAllDeliverySheetsRecordingTypeInEnum];
/**
 * @export
 */
export const GetAllDeliverySheetsRecordingTypeNotInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllDeliverySheetsRecordingTypeNotInEnum = typeof GetAllDeliverySheetsRecordingTypeNotInEnum[keyof typeof GetAllDeliverySheetsRecordingTypeNotInEnum];
