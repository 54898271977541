import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import PileDetailsView, { PileForm } from '@components/views/pile/PileDetailsView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import {
  setDeliverySheetList,
  updatePileDataInDeliverySheets
} from '@redux/features/delivery-sheet/deliverySheetSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { setLoggingList } from '@redux/features/logging/loggingSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { RootState } from '@redux/store';
import { FullAvailableLoggingResourceApi } from '@services/apis/FullAvailableLoggingResourceApi';
import { FullAvailableOtherAssortmentResourceApi } from '@services/apis/FullAvailableOtherAssortmentResourceApi';
import { FullAvailablePileResourceApi } from '@services/apis/FullAvailablePileResourceApi';
import { LoggingDTO, OtherAssortment, Pile, PileWoodTypeEnum } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { resetPileList } from '../../redux/features/pile/pileSlice';
import { clearElementForRedux } from '../../utils/classes/UrstammClassMapper';
import { sleep, truncate, urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function PileDetailsContainer({ navigation, route }: RootStackScreenProps<'PileDetails'>) {
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const rdxOtherAssortmentCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.otherAssortment.completeList
  );
  const rdxLoggingCompleteList = useSelector((state: RootState) => state.persistedReducer.logging.completeList);
  const rdxMyCompany = useSelector((state: RootState) => state.persistedReducer.user.myCompany);

  const [pileSelected, setPileSelected] = useState<Pile>({});
  const [otherAssortment, setOtherAssortment] = useState<OtherAssortment>({});
  const dispatch = useDispatch();

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    if (route.params) {
      setPileSelected(route.params.pileSelected);
      if (route.params?.pileSelected && route.params.pileSelected?.woodType == PileWoodTypeEnum.OtherAssortment)
        getOtherAssortmentByPileSelected(route.params?.pileSelected);
    }
  }, [route.params]);

  const updatePile = (pileForm: PileForm): void => {
    dispatch(changeLoaderStatus(true));

    const pileCopy: Pile = {};
    for (let key in pileForm.pile) {
      if (key === 'otherAssortmentType' || key === 'species') continue;
      pileCopy[key] = pileForm.pile[key];
    }
    pileCopy.logging = {
      id: route.params?.loggingSelected.id,
      name: route.params?.loggingSelected.name,
      currentState: route.params?.currentState,
      company: { id: rdxMyCompany?.id, name: rdxMyCompany?.name }
    };

    const isNewTimber = pileCopy.woodType == PileWoodTypeEnum.Timber && !pileSelected.woodType;

    FullAvailablePileResourceApi.updatePileCustom(
      rdxOfflineSyncList,
      dispatch,
      addOfflineObjectSync,
      setDeliverySheetList,
      isNewTimber,
      {
        id: pileSelected.id!,
        entityWithFileDTOPile: {
          entity: pileCopy,
          base64File: pileForm.base64File
        }
      }
    )
      .then(res => {
        dispatch(changeLoaderStatus(false));

        if (res) {
          dispatch(resetPileList());
          dispatch(updatePileDataInDeliverySheets(res));
          if (res.woodType == PileWoodTypeEnum.OtherAssortment) {
            // Updates logging in redux because volume has changed
            FullAvailableLoggingResourceApi.getLoggingCustom(rdxLoggingCompleteList, {
              id: res.logging?.id!
            })
              .then((logging: LoggingDTO) => {
                if (logging) {
                  logging = clearElementForRedux(logging);
                  dispatch(setLoggingList([logging]));
                }
              })
              .catch(async error => {
                let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                AlertHelper.showSimpleAlert('Error', errorJson);
              });

            if (otherAssortment.id) {
              let otherAssortmentObj = {
                id: otherAssortment.id,
                otherAssortmentType: pileForm.pile?.otherAssortmentType,
                species: pileForm.pile?.species,
                pile: res
              };
              FullAvailableOtherAssortmentResourceApi.updateOtherAssortmentCustom(
                rdxOfflineSyncList,
                dispatch,
                addOfflineObjectSync,
                {
                  id: otherAssortment.id!,
                  otherAssortment: otherAssortmentObj
                }
              )
                .then(res => {
                  UrstammNavigationHelper.navigateToPileList(navigation, route.params?.loggingSelected, true);
                })
                .catch(async error => {
                  dispatch(changeLoaderStatus(false));
                  let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                  let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                  AlertHelper.showSimpleAlert('Error', errorJson);
                });
            } else {
              let otherAssortment = {
                otherAssortmentType: pileForm.pile?.otherAssortmentType,
                species: pileForm.pile?.species,
                pile: res
              };
              FullAvailableOtherAssortmentResourceApi.createOtherAssortmentCustom(
                rdxOfflineSyncList,
                dispatch,
                addOfflineObjectSync,
                { otherAssortment: otherAssortment }
              )
                .then(res => {
                  UrstammNavigationHelper.navigateToPileList(navigation, route.params?.loggingSelected!, true);
                })
                .catch(async error => {
                  dispatch(changeLoaderStatus(false));
                  let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                  let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                  AlertHelper.showSimpleAlert('Error', errorJson);
                });
            }
          } else {
            UrstammNavigationHelper.navigateToPileList(navigation, route.params?.loggingSelected, true);
          }
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getOtherAssortmentByPileSelected = (pile: Pile) => {
    if (pile) {
      FullAvailableOtherAssortmentResourceApi.getOtherAssortmentsByPile(rdxOtherAssortmentCompleteList, {
        pileId: pile.id!
      })
        .then(oa => {
          if (oa) {
            //TODO: Manage list
            setOtherAssortment(oa[0]);
          }
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      title={
        <UrstammTitle
          testID={'pile_details_title'}
          text={i18n.t('generics.pile')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'pile_details_subtitle'}
          text={truncate(route.params?.pileSelected.name!, 30)}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={[
        {
          title: i18n.t('generics.logging'),
          value: route.params?.loggingSelected.name!
        }
      ]}
      view={
        <PileDetailsView
          navigation={navigation}
          pileSelected={pileSelected}
          otherAssortment={otherAssortment}
          modalVisible={false}
          loggingSelected={route.params?.loggingSelected}
          updatePile={(pileForm: PileForm) => updatePile(pileForm)}
        />
      }
    />
  );
}
