import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import {
  applySavingToReference,
  deepCopyAndAddSelectedParameter,
  restoreListToReference,
  selectElementFormList,
  selectSlideElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ProjectDetailsView from '@components/views/project/ProjectDetailsView';
import { ProjectForm, ProjectImageForm } from '@components/views/project/ProjectRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { resetProjectList } from '@redux/features/project/projectSlice';
import { RootState } from '@redux/store';
import {
  customProjectResourceApi,
  listSize
} from '@services/apis/ApiConfiguration';
import {
  Product,
  Project, ProjectCurrentStateEnum, ProjectDTO,
} from '@services/apis/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { getTextByTreeOrTrunkSpecies } from '../../utils/classes/UrstammUtilityCurrentState';
import { generateSet, generateSetForCertification, generateSetForProductSpecies, getCardTitleByProject, sleep } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout, UrstammStyleList } from '../../utils/styles/UrstammStyle';
import { resetCompanyAppearanceList, setCompanyAppearanceList } from '@redux/features/appearance/companyAppearanceSlice';
import {
  RouteProcessorBatchDetails,
} from '../../navigation/UrstammNavigationRoutes';
import { ListSubData } from '@components/utility-components/list/UrstammList';

export default function ProjectDetailsContainer({ route, navigation }: RootStackScreenProps<'ProjectDetails'>) {
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('generics.project'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };
  
  //Redux

  const [sourceProductList, setSourceProductList] = useState<any>();
  const [sourceProductListReference, setSourceProductListReference] = useState<any>();

  const dispatch = useDispatch();

  const [projectSelected, setProjectSelected] = useState<ProjectDTO>();
  
  const readOnly = useMemo(() => {
    return !!route.params?.readOnly;
  }, [!!route.params?.readOnly])

  useEffect(() => {
    if (route.params?.projectSelected) {
      getProjectCustom(route.params.projectSelected);
    }
  }, [route.params]);

  const goBack = async (): Promise<void> => {
    dispatch(resetProjectList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToProjectList(navigation, true);    
  };

  const getProjectCustom = (projectSelected: Project) => {
    dispatch(changeLoaderStatus(true));

    customProjectResourceApi
      .getProjectCustom({ id: projectSelected.id! })
      .then((project: ProjectDTO) => {
        dispatch(changeLoaderStatus(false));
        if (project) {
          setProjectSelected(project);
          getSourceProduct(project);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getSourceProduct = (projectSelected: ProjectDTO) => {
    customProjectResourceApi
      .getDSProductsByProject({ id: projectSelected.id! })
      .then(async (products: Set<Product>) => {
        dispatch(changeLoaderStatus(false));

        if (products) {
          const list = [...products.values()]
          const selected = [...(projectSelected.products?.values() || [])];
          deepCopyAndAddSelectedParameter(list, setSourceProductList, setSourceProductListReference, selected);
        } else {
          setSourceProductList([]);
          setSourceProductListReference([]);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  }

  const submitProject = (projectForm: ProjectForm, projectImageForm?: ProjectImageForm) => {
    dispatch(changeLoaderStatus(true));

    let productsSet: Set<Product> = generateSet(sourceProductList);

    let projectObj: Project = {
      id: projectSelected?.id,
      currentState: ProjectCurrentStateEnum.InProgress,
      uniqueNumber: projectForm.project.uniqueNumber!,
      name: projectForm.project.name!,
      creationDate: projectForm.project.creationDate,
      comment: projectForm.project.comment,
      products: productsSet,
      longitude: isNaN(Number(projectImageForm?.longitude)) ? undefined : Number(projectImageForm?.longitude),
      latitude: isNaN(Number(projectImageForm?.latitude)) ? undefined : Number(projectImageForm?.latitude),
      photoCreationDate: projectImageForm?.photoCreationDate,
    };

    customProjectResourceApi
      .updateProjectCustom({
        id: projectSelected?.id!,
        entityWithFileDTOProject: { entity: projectObj, base64File: projectImageForm?.base64File, deletePrevious: projectImageForm?.deletePrevious }
      })
      .then(async (project: Project) => {
        dispatch(changeLoaderStatus(false));

        if (project) {
          dispatch(resetProjectList());
          Keyboard.dismiss();
          await sleep(PlatformHelper.isIos() ? 300 : 100);
          UrstammNavigationHelper.navigateToProjectList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const closeProject = (project: Project | ProjectDTO) => {
    dispatch(changeLoaderStatus(true));

    let productsSet: Set<Product> = generateSet(sourceProductList);

    let projectObj: Project = {
      id: projectSelected?.id,
      currentState: ProjectCurrentStateEnum.Closed,
      uniqueNumber: project.uniqueNumber!,
      name: project.name!,
      creationDate: project.creationDate,
      comment: project.comment,
      products: productsSet,
      longitude: isNaN(Number(project?.longitude)) ? undefined : Number(project?.longitude),
      latitude: isNaN(Number(project?.latitude)) ? undefined : Number(project?.latitude),
      photoCreationDate: project?.photoCreationDate,
    };

    customProjectResourceApi
      .updateProjectCustom({
        id: projectSelected?.id!,
        entityWithFileDTOProject: { entity: projectObj, base64File: undefined, deletePrevious: false }
      })
      .then(async (project: Project) => {
        dispatch(changeLoaderStatus(false));

        if (project) {
          dispatch(resetProjectList());
          Keyboard.dismiss();
          await sleep(PlatformHelper.isIos() ? 300 : 100);
          UrstammNavigationHelper.navigateToProjectList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const showProductDetails = (product?: Product) => {
    UrstammNavigationHelper.navigateToProductDetails(
      navigation,
      product?.processorBatch,
      product,
      {
        readOnly: true,
        returnTo: 'back'
      },
    )
  }

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'product_title'}
          text={i18n.t('generics.project')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'product_details_subtitle'}
          text={route.params?.projectSelected?.uniqueNumber}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={[{ title: i18n.t('generics.name'), value: projectSelected?.name! }]}
      view={
        <ProjectDetailsView
          navigation={navigation}
          projectSelected={projectSelected!}
          submitProjectForm={submitProject}
          closingProject={(project) => closeProject(projectSelected!)}
          productsPros={{
            list: sourceProductList,
            selected: selected => selectElementFormList(selected, setSourceProductList, sourceProductList),
            moreItems: () => { },
            resetSelected: () => restoreListToReference(setSourceProductList, sourceProductListReference),
            confirmSelected: () => applySavingToReference(setSourceProductListReference, sourceProductList),
          }}
          productListProps={{
            productSelected: showProductDetails,
          }}
          readOnly={readOnly}
        />
      }
    />
  );
}
