/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EntityWithFileDTOPacket,
  Packet,
  PacketDTO,
} from '../models';
import {
    EntityWithFileDTOPacketFromJSON,
    EntityWithFileDTOPacketToJSON,
    PacketFromJSON,
    PacketToJSON,
    PacketDTOFromJSON,
    PacketDTOToJSON,
} from '../models';
import { CountBatchesCurrentStateEqualsEnum, CountBatchesCurrentStateInEnum, CountBatchesCurrentStateNotEqualsEnum, CountBatchesCurrentStateNotInEnum } from './BatchResourceApi';

export interface CreatePacketCustomRequest {
    entityWithFileDTOPacket: EntityWithFileDTOPacket;
}

export interface DownloadPacketPhotoRequest {
    id: number;
}

export interface GetPacketCustomRequest {
    id: number;
}

export interface GetPacketsByBatchRequest {
    batchId: number;
    sortBy?: string;
    direction?: GetPacketsByBatchDirectionEnum;
    currentStateEquals?: CountBatchesCurrentStateEqualsEnum;
    currentStateNotEquals?: CountBatchesCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<CountBatchesCurrentStateInEnum>;
    currentStateNotIn?: Array<CountBatchesCurrentStateNotInEnum>;
    batchIdGreaterThan?: number;
    batchIdLessThan?: number;
    batchIdGreaterThanOrEqual?: number;
    batchIdLessThanOrEqual?: number;
    batchIdEquals?: number;
    batchIdNotEquals?: number;
    batchIdSpecified?: boolean;
    batchIdIn?: Array<number>;
    batchIdNotIn?: Array<number>;
    shippingNoteIdEquals?: number;
    distinct?: boolean;
}

export interface UpdatePacketCustomRequest {
    id: number;
    entityWithFileDTOPacket: EntityWithFileDTOPacket;
}

/**
 * 
 */
export class CustomPacketResourceApi extends runtime.BaseAPI {

    /**
     * Create a new Packet connecting to Company of logged user.
     * Create a Packet
     */
    async createPacketCustomRaw(requestParameters: CreatePacketCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Packet>> {
        if (requestParameters.entityWithFileDTOPacket === null || requestParameters.entityWithFileDTOPacket === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOPacket','Required parameter requestParameters.entityWithFileDTOPacket was null or undefined when calling createPacketCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/packets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOPacketToJSON(requestParameters.entityWithFileDTOPacket),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketFromJSON(jsonValue));
    }

    /**
     * Create a new Packet connecting to Company of logged user.
     * Create a Packet
     */
    async createPacketCustom(requestParameters: CreatePacketCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Packet> {
        const response = await this.createPacketCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download photo corresponding to Packet identified by given id.
     * Download photo of Packet
     */
    async downloadPacketPhotoRaw(requestParameters: DownloadPacketPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadPacketPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/packets/{id}/download/photo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download photo corresponding to Packet identified by given id.
     * Download photo of Packet
     */
    async downloadPacketPhoto(requestParameters: DownloadPacketPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadPacketPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Packet identified by given id (must belong to Company of logged user).
     * Get a specific Packet
     */
    async getPacketCustomRaw(requestParameters: GetPacketCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPacketCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/packets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketDTOFromJSON(jsonValue));
    }

    /**
     * Get Packet identified by given id (must belong to Company of logged user).
     * Get a specific Packet
     */
    async getPacketCustom(requestParameters: GetPacketCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketDTO> {
        const response = await this.getPacketCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a page of Packet belonging to Company of logged User.
     * Get packets for a Batch
     */
    async getPacketsByBatchRaw(requestParameters: GetPacketsByBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Packet>>> {
        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling getPacketsByBatch.');
        }
 
        const queryParameters: any = {};
 
        if (requestParameters.batchIdGreaterThan !== undefined) {
            queryParameters['batchId.greaterThan'] = requestParameters.batchIdGreaterThan;
        }
 
        if (requestParameters.batchIdLessThan !== undefined) {
            queryParameters['batchId.lessThan'] = requestParameters.batchIdLessThan;
        }
 
        if (requestParameters.batchIdGreaterThanOrEqual !== undefined) {
            queryParameters['batchId.greaterThanOrEqual'] = requestParameters.batchIdGreaterThanOrEqual;
        }
 
        if (requestParameters.batchIdLessThanOrEqual !== undefined) {
            queryParameters['batchId.lessThanOrEqual'] = requestParameters.batchIdLessThanOrEqual;
        }
 
        if (requestParameters.batchIdEquals !== undefined) {
            queryParameters['batchId.equals'] = requestParameters.batchIdEquals;
        }
 
        if (requestParameters.batchIdNotEquals !== undefined) {
            queryParameters['batchId.notEquals'] = requestParameters.batchIdNotEquals;
        }
 
        if (requestParameters.batchIdSpecified !== undefined) {
            queryParameters['batchId.specified'] = requestParameters.batchIdSpecified;
        }
 
        if (requestParameters.batchIdIn) {
            queryParameters['batchId.in'] = requestParameters.batchIdIn;
        }
 
        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }
 
        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }
 
        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals
        }
 
        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals
        }
 
        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified
        }
 
        if (requestParameters.currentStateIn !== undefined) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn
        }
 
        if (requestParameters.currentStateNotIn !== undefined) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn
        }

        if (requestParameters.shippingNoteIdEquals !== undefined) {
            queryParameters['shippingNoteId.equals'] = requestParameters.shippingNoteIdEquals
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct
        }
 
        const headerParameters: runtime.HTTPHeaders = {};
 
 
        const response = await this.request({
            path: `/api/custom/packets/batch`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
 
 
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PacketFromJSON));
    }
 

    /**
     * Get a page of Packet belonging to Company of logged User.
     * Get packets for a Batch
     */
    async getPacketsByBatch(requestParameters: GetPacketsByBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Packet>> {
        const response = await this.getPacketsByBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update Packet identified by given id (must belong to Company of logged user).
     * Partial update a Packet
     */
    async updatePacketCustomRaw(requestParameters: UpdatePacketCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Packet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePacketCustom.');
        }

        if (requestParameters.entityWithFileDTOPacket === null || requestParameters.entityWithFileDTOPacket === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOPacket','Required parameter requestParameters.entityWithFileDTOPacket was null or undefined when calling updatePacketCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/packets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOPacketToJSON(requestParameters.entityWithFileDTOPacket),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketFromJSON(jsonValue));
    }

    /**
     * Partial update Packet identified by given id (must belong to Company of logged user).
     * Partial update a Packet
     */
    async updatePacketCustom(requestParameters: UpdatePacketCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Packet> {
        const response = await this.updatePacketCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetPacketsByBatchDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetPacketsByBatchDirectionEnum = typeof GetPacketsByBatchDirectionEnum[keyof typeof GetPacketsByBatchDirectionEnum];
