  import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { ProcessorShippingNoteDTO } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface IncomingProcessorShippingNoteState {
  list: ProcessorShippingNoteDTO[];
  completeList: ProcessorShippingNoteDTO[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: IncomingProcessorShippingNoteState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const incomingProcessorShippingNote = createSlice({
  name: 'incomingProcessorShippingNoteList',
  initialState,
  reducers: {
    setIncomingProcessorShippingNoteList: (state, action) => {
      let incomingProcessorShippingNoteList = { ...state }.list;
      let incomingProcessorShippingNoteCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(incomingProcessorShippingNoteList, element);
        pushElementOrReplaceById(incomingProcessorShippingNoteCompleteList, element);
      });

      state.list = incomingProcessorShippingNoteList;
      state.completeList = incomingProcessorShippingNoteCompleteList;
    },
    setIncomingProcessorShippingNoteTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setIncomingProcessorShippingNoteListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setIncomingProcessorShippingNotePage: (state, action) => {
      state.page = action.payload;
    },
    resetIncomingProcessorShippingNoteList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearIncomingProcessorShippingNoteList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setIncomingProcessorShippingNoteList,
  setIncomingProcessorShippingNoteListSort,
  resetIncomingProcessorShippingNoteList,
  setIncomingProcessorShippingNoteTotalPages,
  setIncomingProcessorShippingNotePage,
  clearIncomingProcessorShippingNoteList
} = incomingProcessorShippingNote.actions;

export default incomingProcessorShippingNote.reducer;
