import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native';
import UrstammButtonBase, { UrstammButtonStyleProps } from '../button/UrstammButtonBase';
import UrstammIcon, { UrstammIconProps } from '../icon/UrstammIcon';

export default function UrstammIndicator(props: {
  fontStyle?: StyleProp<TextStyle>;
  testID: string;
  text: string;
  counter: number;
  icon: UrstammIconProps;
  enableButton: boolean;
  buttonText?: string;
  buttonStyle?: UrstammButtonStyleProps;
  submit?: () => void;
}) {
  const getCounterValue = (value: number): number => {
    if (!value) return 0;

    return value;
  };

  return (
    <View style={[styles.indicatorContainer]}>
      <View style={[styles.iconCounterContainer]}>
        {props.icon.showIcon && <UrstammIcon name={props.icon.iconName} size={24} color={props.icon.iconColor!} />}
        <Text testID={props.testID} style={[props.fontStyle, { marginHorizontal: 8 }]}>
          {props.text + ': ' + getCounterValue(props.counter)}
        </Text>
      </View>

      {props.enableButton && (
        <View style={[styles.buttonContainer]}>
          <UrstammButtonBase
            text={props.buttonText}
            style={props.buttonStyle!}
            testID={'save_logging'}
            onSubmit={props.submit}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  indicatorContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconCounterContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  buttonContainer: {}
});
