/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShippingNote } from './ShippingNote';
import {
    ShippingNoteFromJSON,
    ShippingNoteFromJSONTyped,
    ShippingNoteToJSON,
} from './ShippingNote';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ShippingNoteState
 */
export interface ShippingNoteState {
    /**
     * 
     * @type {number}
     * @memberof ShippingNoteState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof ShippingNoteState
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteState
     */
    state: ShippingNoteStateStateEnum;
    /**
     * 
     * @type {ShippingNote}
     * @memberof ShippingNoteState
     */
    shippingNote?: ShippingNote;
    /**
     * 
     * @type {User}
     * @memberof ShippingNoteState
     */
    author?: User;
}


/**
 * @export
 */
export const ShippingNoteStateStateEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type ShippingNoteStateStateEnum = typeof ShippingNoteStateStateEnum[keyof typeof ShippingNoteStateStateEnum];


/**
 * Check if a given object implements the ShippingNoteState interface.
 */
export function instanceOfShippingNoteState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function ShippingNoteStateFromJSON(json: any): ShippingNoteState {
    return ShippingNoteStateFromJSONTyped(json, false);
}

export function ShippingNoteStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingNoteState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'state': json['state'],
        'shippingNote': !exists(json, 'shippingNote') ? undefined : ShippingNoteFromJSON(json['shippingNote']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
    };
}

export function ShippingNoteStateToJSON(value?: ShippingNoteState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'state': value.state,
        'shippingNote': ShippingNoteToJSON(value.shippingNote),
        'author': UserToJSON(value.author),
    };
}

