import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { CompanyProductType } from '@services/apis/generated';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface CompanyProductTypeState {
  list: CompanyProductType[];
  completeList: CompanyProductType[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: CompanyProductTypeState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const companyProductType = createSlice({
  name: 'companyProductTypeList',
  initialState,
  reducers: {
    setCompanyProductTypeList: (state, action) => {
      let companyProductType = { ...state }.list;
      let companyProductTypeCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        pushElementOrReplaceById(companyProductType, element);
        pushElementOrReplaceById(companyProductTypeCompleteList, element);
      });

      state.list = companyProductType;
      state.completeList = companyProductTypeCompleteList;
    },
    setCompanyProductTypeTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setCompanyProductTypeListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setCompanyProductTypePage: (state, action) => {
      state.page = action.payload;
    },
    resetCompanyProductTypeList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearCompanyProductTypeList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setCompanyProductTypeList,
  setCompanyProductTypeListSort,
  resetCompanyProductTypeList,
  setCompanyProductTypeTotalPages,
  setCompanyProductTypePage,
  clearCompanyProductTypeList
} = companyProductType.actions;

export default companyProductType.reducer;
