/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyToJSON
} from './Company';
import type { ProcessorBatch } from './ProcessorBatch';
import {
    ProcessorBatchFromJSON,
    ProcessorBatchToJSON
} from './ProcessorBatch';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductToJSON
} from './Product';
import type { ShippingNote } from './ShippingNote';
import {
    ShippingNoteFromJSON,
    ShippingNoteToJSON
} from './ShippingNote';

/**
 * 
 * @export
 * @interface ProcessorShippingNoteDTO
 */
export interface ProcessorShippingNoteDTO {
    totalProducts?: number;
    /**
     * 
     * @type {number}
     * @memberof ProcessorShippingNoteDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteDTO
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProcessorShippingNoteDTO
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProcessorShippingNoteDTO
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteDTO
     */
    currentState?: ProcessorShippingNoteDTOCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteDTO
     */
    uuid?: string;
    /**
     * 
     * @type {Company}
     * @memberof ProcessorShippingNoteDTO
     */
    processor?: Company;
    /**
     * 
     * @type {Company}
     * @memberof ProcessorShippingNoteDTO
     */
    creator?: Company;
    /**
     * 
     * @type {ShippingNote}
     * @memberof ProcessorShippingNoteDTO
     */
    referenceShippingNote?: ShippingNote;
    /**
     * 
     * @type {ProcessorBatch}
     * @memberof ProcessorShippingNoteDTO
     */
    outgoingBatch?: ProcessorBatch;
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProcessorShippingNoteDTO
     */
    incomingProducts?: Array<Product>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProcessorShippingNoteDTO
     */
    outgoingProducts?: Array<Product>;
}


/**
 * @export
 */
export const ProcessorShippingNoteDTOCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type ProcessorShippingNoteDTOCurrentStateEnum = typeof ProcessorShippingNoteDTOCurrentStateEnum[keyof typeof ProcessorShippingNoteDTOCurrentStateEnum];


/**
 * Check if a given object implements the ProcessorShippingNoteDTO interface.
 */
export function instanceOfProcessorShippingNoteDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProcessorShippingNoteDTOFromJSON(json: any): ProcessorShippingNoteDTO {
    return ProcessorShippingNoteDTOFromJSONTyped(json, false);
}

export function ProcessorShippingNoteDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessorShippingNoteDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalProducts': !exists(json, 'totalProducts') ? undefined : json['totalProducts'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'processor': !exists(json, 'processor') ? undefined : CompanyFromJSON(json['processor']),
        'creator': !exists(json, 'creator') ? undefined : CompanyFromJSON(json['creator']),
        'referenceShippingNote': !exists(json, 'referenceShippingNote') ? undefined : ShippingNoteFromJSON(json['referenceShippingNote']),
        'outgoingBatch': !exists(json, 'outgoingBatch') ? undefined : ProcessorBatchFromJSON(json['outgoingBatch']),
        'incomingProducts': !exists(json, 'incomingProducts') ? undefined : ((json['incomingProducts'] as Array<any>).map(ProductFromJSON)),
        'outgoingProducts': !exists(json, 'outgoingProducts') ? undefined : ((json['outgoingProducts'] as Array<any>).map(ProductFromJSON)),
    };
}

export function ProcessorShippingNoteDTOToJSON(value?: ProcessorShippingNoteDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'sumCubage': value.sumCubage,
        'currentState': value.currentState,
        'uuid': value.uuid,
        'processor': CompanyToJSON(value.processor),
        'creator': CompanyToJSON(value.creator),
        'referenceShippingNote': ShippingNoteToJSON(value.referenceShippingNote),
        'outgoingBatch': ProcessorBatchToJSON(value.outgoingBatch),
        'incomingProducts': value.incomingProducts === undefined ? undefined : ((value.incomingProducts as Array<any>).map(ProductToJSON)),
        'outgoingProducts': value.outgoingProducts === undefined ? undefined : ((value.outgoingProducts as Array<any>).map(ProductToJSON)),
    };
}

