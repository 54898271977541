/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Company,
} from '../models';
import {
    CompanyFromJSON,
    CompanyToJSON,
} from '../models';

export interface CountCompaniesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    prefixIdentifierContains?: string;
    prefixIdentifierDoesNotContain?: string;
    prefixIdentifierEquals?: string;
    prefixIdentifierNotEquals?: string;
    prefixIdentifierSpecified?: boolean;
    prefixIdentifierIn?: Array<string>;
    prefixIdentifierNotIn?: Array<string>;
    idiNumberContains?: string;
    idiNumberDoesNotContain?: string;
    idiNumberEquals?: string;
    idiNumberNotEquals?: string;
    idiNumberSpecified?: boolean;
    idiNumberIn?: Array<string>;
    idiNumberNotIn?: Array<string>;
    streetNameContains?: string;
    streetNameDoesNotContain?: string;
    streetNameEquals?: string;
    streetNameNotEquals?: string;
    streetNameSpecified?: boolean;
    streetNameIn?: Array<string>;
    streetNameNotIn?: Array<string>;
    streetNumberContains?: string;
    streetNumberDoesNotContain?: string;
    streetNumberEquals?: string;
    streetNumberNotEquals?: string;
    streetNumberSpecified?: boolean;
    streetNumberIn?: Array<string>;
    streetNumberNotIn?: Array<string>;
    phoneNumberContains?: string;
    phoneNumberDoesNotContain?: string;
    phoneNumberEquals?: string;
    phoneNumberNotEquals?: string;
    phoneNumberSpecified?: boolean;
    phoneNumberIn?: Array<string>;
    phoneNumberNotIn?: Array<string>;
    emailContains?: string;
    emailDoesNotContain?: string;
    emailEquals?: string;
    emailNotEquals?: string;
    emailSpecified?: boolean;
    emailIn?: Array<string>;
    emailNotIn?: Array<string>;
    cityContains?: string;
    cityDoesNotContain?: string;
    cityEquals?: string;
    cityNotEquals?: string;
    citySpecified?: boolean;
    cityIn?: Array<string>;
    cityNotIn?: Array<string>;
    zipCodeContains?: string;
    zipCodeDoesNotContain?: string;
    zipCodeEquals?: string;
    zipCodeNotEquals?: string;
    zipCodeSpecified?: boolean;
    zipCodeIn?: Array<string>;
    zipCodeNotIn?: Array<string>;
    logoFilenameContains?: string;
    logoFilenameDoesNotContain?: string;
    logoFilenameEquals?: string;
    logoFilenameNotEquals?: string;
    logoFilenameSpecified?: boolean;
    logoFilenameIn?: Array<string>;
    logoFilenameNotIn?: Array<string>;
    logoFilepathContains?: string;
    logoFilepathDoesNotContain?: string;
    logoFilepathEquals?: string;
    logoFilepathNotEquals?: string;
    logoFilepathSpecified?: boolean;
    logoFilepathIn?: Array<string>;
    logoFilepathNotIn?: Array<string>;
    logoUrlContains?: string;
    logoUrlDoesNotContain?: string;
    logoUrlEquals?: string;
    logoUrlNotEquals?: string;
    logoUrlSpecified?: boolean;
    logoUrlIn?: Array<string>;
    logoUrlNotIn?: Array<string>;
    sawmillIdGreaterThan?: number;
    sawmillIdLessThan?: number;
    sawmillIdGreaterThanOrEqual?: number;
    sawmillIdLessThanOrEqual?: number;
    sawmillIdEquals?: number;
    sawmillIdNotEquals?: number;
    sawmillIdSpecified?: boolean;
    sawmillIdIn?: Array<number>;
    sawmillIdNotIn?: Array<number>;
    processorIdGreaterThan?: number;
    processorIdLessThan?: number;
    processorIdGreaterThanOrEqual?: number;
    processorIdLessThanOrEqual?: number;
    processorIdEquals?: number;
    processorIdNotEquals?: number;
    processorIdSpecified?: boolean;
    processorIdIn?: Array<number>;
    processorIdNotIn?: Array<number>;
    xmlExportErpIdGreaterThan?: number;
    xmlExportErpIdLessThan?: number;
    xmlExportErpIdGreaterThanOrEqual?: number;
    xmlExportErpIdLessThanOrEqual?: number;
    xmlExportErpIdEquals?: number;
    xmlExportErpIdNotEquals?: number;
    xmlExportErpIdSpecified?: boolean;
    xmlExportErpIdIn?: Array<number>;
    xmlExportErpIdNotIn?: Array<number>;
    companyAppearanceIdGreaterThan?: number;
    companyAppearanceIdLessThan?: number;
    companyAppearanceIdGreaterThanOrEqual?: number;
    companyAppearanceIdLessThanOrEqual?: number;
    companyAppearanceIdEquals?: number;
    companyAppearanceIdNotEquals?: number;
    companyAppearanceIdSpecified?: boolean;
    companyAppearanceIdIn?: Array<number>;
    companyAppearanceIdNotIn?: Array<number>;
    companyProductTypeIdGreaterThan?: number;
    companyProductTypeIdLessThan?: number;
    companyProductTypeIdGreaterThanOrEqual?: number;
    companyProductTypeIdLessThanOrEqual?: number;
    companyProductTypeIdEquals?: number;
    companyProductTypeIdNotEquals?: number;
    companyProductTypeIdSpecified?: boolean;
    companyProductTypeIdIn?: Array<number>;
    companyProductTypeIdNotIn?: Array<number>;
    companyPacketWoodQualityIdGreaterThan?: number;
    companyPacketWoodQualityIdLessThan?: number;
    companyPacketWoodQualityIdGreaterThanOrEqual?: number;
    companyPacketWoodQualityIdLessThanOrEqual?: number;
    companyPacketWoodQualityIdEquals?: number;
    companyPacketWoodQualityIdNotEquals?: number;
    companyPacketWoodQualityIdSpecified?: boolean;
    companyPacketWoodQualityIdIn?: Array<number>;
    companyPacketWoodQualityIdNotIn?: Array<number>;
    companySurfaceIdGreaterThan?: number;
    companySurfaceIdLessThan?: number;
    companySurfaceIdGreaterThanOrEqual?: number;
    companySurfaceIdLessThanOrEqual?: number;
    companySurfaceIdEquals?: number;
    companySurfaceIdNotEquals?: number;
    companySurfaceIdSpecified?: boolean;
    companySurfaceIdIn?: Array<number>;
    companySurfaceIdNotIn?: Array<number>;
    loggingIdGreaterThan?: number;
    loggingIdLessThan?: number;
    loggingIdGreaterThanOrEqual?: number;
    loggingIdLessThanOrEqual?: number;
    loggingIdEquals?: number;
    loggingIdNotEquals?: number;
    loggingIdSpecified?: boolean;
    loggingIdIn?: Array<number>;
    loggingIdNotIn?: Array<number>;
    userCompanyIdGreaterThan?: number;
    userCompanyIdLessThan?: number;
    userCompanyIdGreaterThanOrEqual?: number;
    userCompanyIdLessThanOrEqual?: number;
    userCompanyIdEquals?: number;
    userCompanyIdNotEquals?: number;
    userCompanyIdSpecified?: boolean;
    userCompanyIdIn?: Array<number>;
    userCompanyIdNotIn?: Array<number>;
    userCompanyInviteIdGreaterThan?: number;
    userCompanyInviteIdLessThan?: number;
    userCompanyInviteIdGreaterThanOrEqual?: number;
    userCompanyInviteIdLessThanOrEqual?: number;
    userCompanyInviteIdEquals?: number;
    userCompanyInviteIdNotEquals?: number;
    userCompanyInviteIdSpecified?: boolean;
    userCompanyInviteIdIn?: Array<number>;
    userCompanyInviteIdNotIn?: Array<number>;
    forestryIdGreaterThan?: number;
    forestryIdLessThan?: number;
    forestryIdGreaterThanOrEqual?: number;
    forestryIdLessThanOrEqual?: number;
    forestryIdEquals?: number;
    forestryIdNotEquals?: number;
    forestryIdSpecified?: boolean;
    forestryIdIn?: Array<number>;
    forestryIdNotIn?: Array<number>;
    sawmillSupplierIdGreaterThan?: number;
    sawmillSupplierIdLessThan?: number;
    sawmillSupplierIdGreaterThanOrEqual?: number;
    sawmillSupplierIdLessThanOrEqual?: number;
    sawmillSupplierIdEquals?: number;
    sawmillSupplierIdNotEquals?: number;
    sawmillSupplierIdSpecified?: boolean;
    sawmillSupplierIdIn?: Array<number>;
    sawmillSupplierIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateCompanyRequest {
    company: Company;
}

export interface DeleteCompanyRequest {
    id: number;
}

export interface GetAllCompaniesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    prefixIdentifierContains?: string;
    prefixIdentifierDoesNotContain?: string;
    prefixIdentifierEquals?: string;
    prefixIdentifierNotEquals?: string;
    prefixIdentifierSpecified?: boolean;
    prefixIdentifierIn?: Array<string>;
    prefixIdentifierNotIn?: Array<string>;
    idiNumberContains?: string;
    idiNumberDoesNotContain?: string;
    idiNumberEquals?: string;
    idiNumberNotEquals?: string;
    idiNumberSpecified?: boolean;
    idiNumberIn?: Array<string>;
    idiNumberNotIn?: Array<string>;
    streetNameContains?: string;
    streetNameDoesNotContain?: string;
    streetNameEquals?: string;
    streetNameNotEquals?: string;
    streetNameSpecified?: boolean;
    streetNameIn?: Array<string>;
    streetNameNotIn?: Array<string>;
    streetNumberContains?: string;
    streetNumberDoesNotContain?: string;
    streetNumberEquals?: string;
    streetNumberNotEquals?: string;
    streetNumberSpecified?: boolean;
    streetNumberIn?: Array<string>;
    streetNumberNotIn?: Array<string>;
    phoneNumberContains?: string;
    phoneNumberDoesNotContain?: string;
    phoneNumberEquals?: string;
    phoneNumberNotEquals?: string;
    phoneNumberSpecified?: boolean;
    phoneNumberIn?: Array<string>;
    phoneNumberNotIn?: Array<string>;
    emailContains?: string;
    emailDoesNotContain?: string;
    emailEquals?: string;
    emailNotEquals?: string;
    emailSpecified?: boolean;
    emailIn?: Array<string>;
    emailNotIn?: Array<string>;
    cityContains?: string;
    cityDoesNotContain?: string;
    cityEquals?: string;
    cityNotEquals?: string;
    citySpecified?: boolean;
    cityIn?: Array<string>;
    cityNotIn?: Array<string>;
    zipCodeContains?: string;
    zipCodeDoesNotContain?: string;
    zipCodeEquals?: string;
    zipCodeNotEquals?: string;
    zipCodeSpecified?: boolean;
    zipCodeIn?: Array<string>;
    zipCodeNotIn?: Array<string>;
    logoFilenameContains?: string;
    logoFilenameDoesNotContain?: string;
    logoFilenameEquals?: string;
    logoFilenameNotEquals?: string;
    logoFilenameSpecified?: boolean;
    logoFilenameIn?: Array<string>;
    logoFilenameNotIn?: Array<string>;
    logoFilepathContains?: string;
    logoFilepathDoesNotContain?: string;
    logoFilepathEquals?: string;
    logoFilepathNotEquals?: string;
    logoFilepathSpecified?: boolean;
    logoFilepathIn?: Array<string>;
    logoFilepathNotIn?: Array<string>;
    logoUrlContains?: string;
    logoUrlDoesNotContain?: string;
    logoUrlEquals?: string;
    logoUrlNotEquals?: string;
    logoUrlSpecified?: boolean;
    logoUrlIn?: Array<string>;
    logoUrlNotIn?: Array<string>;
    sawmillIdGreaterThan?: number;
    sawmillIdLessThan?: number;
    sawmillIdGreaterThanOrEqual?: number;
    sawmillIdLessThanOrEqual?: number;
    sawmillIdEquals?: number;
    sawmillIdNotEquals?: number;
    sawmillIdSpecified?: boolean;
    sawmillIdIn?: Array<number>;
    sawmillIdNotIn?: Array<number>;
    processorIdGreaterThan?: number;
    processorIdLessThan?: number;
    processorIdGreaterThanOrEqual?: number;
    processorIdLessThanOrEqual?: number;
    processorIdEquals?: number;
    processorIdNotEquals?: number;
    processorIdSpecified?: boolean;
    processorIdIn?: Array<number>;
    processorIdNotIn?: Array<number>;
    xmlExportErpIdGreaterThan?: number;
    xmlExportErpIdLessThan?: number;
    xmlExportErpIdGreaterThanOrEqual?: number;
    xmlExportErpIdLessThanOrEqual?: number;
    xmlExportErpIdEquals?: number;
    xmlExportErpIdNotEquals?: number;
    xmlExportErpIdSpecified?: boolean;
    xmlExportErpIdIn?: Array<number>;
    xmlExportErpIdNotIn?: Array<number>;
    companyAppearanceIdGreaterThan?: number;
    companyAppearanceIdLessThan?: number;
    companyAppearanceIdGreaterThanOrEqual?: number;
    companyAppearanceIdLessThanOrEqual?: number;
    companyAppearanceIdEquals?: number;
    companyAppearanceIdNotEquals?: number;
    companyAppearanceIdSpecified?: boolean;
    companyAppearanceIdIn?: Array<number>;
    companyAppearanceIdNotIn?: Array<number>;
    companyProductTypeIdGreaterThan?: number;
    companyProductTypeIdLessThan?: number;
    companyProductTypeIdGreaterThanOrEqual?: number;
    companyProductTypeIdLessThanOrEqual?: number;
    companyProductTypeIdEquals?: number;
    companyProductTypeIdNotEquals?: number;
    companyProductTypeIdSpecified?: boolean;
    companyProductTypeIdIn?: Array<number>;
    companyProductTypeIdNotIn?: Array<number>;
    companyPacketWoodQualityIdGreaterThan?: number;
    companyPacketWoodQualityIdLessThan?: number;
    companyPacketWoodQualityIdGreaterThanOrEqual?: number;
    companyPacketWoodQualityIdLessThanOrEqual?: number;
    companyPacketWoodQualityIdEquals?: number;
    companyPacketWoodQualityIdNotEquals?: number;
    companyPacketWoodQualityIdSpecified?: boolean;
    companyPacketWoodQualityIdIn?: Array<number>;
    companyPacketWoodQualityIdNotIn?: Array<number>;
    companySurfaceIdGreaterThan?: number;
    companySurfaceIdLessThan?: number;
    companySurfaceIdGreaterThanOrEqual?: number;
    companySurfaceIdLessThanOrEqual?: number;
    companySurfaceIdEquals?: number;
    companySurfaceIdNotEquals?: number;
    companySurfaceIdSpecified?: boolean;
    companySurfaceIdIn?: Array<number>;
    companySurfaceIdNotIn?: Array<number>;
    loggingIdGreaterThan?: number;
    loggingIdLessThan?: number;
    loggingIdGreaterThanOrEqual?: number;
    loggingIdLessThanOrEqual?: number;
    loggingIdEquals?: number;
    loggingIdNotEquals?: number;
    loggingIdSpecified?: boolean;
    loggingIdIn?: Array<number>;
    loggingIdNotIn?: Array<number>;
    userCompanyIdGreaterThan?: number;
    userCompanyIdLessThan?: number;
    userCompanyIdGreaterThanOrEqual?: number;
    userCompanyIdLessThanOrEqual?: number;
    userCompanyIdEquals?: number;
    userCompanyIdNotEquals?: number;
    userCompanyIdSpecified?: boolean;
    userCompanyIdIn?: Array<number>;
    userCompanyIdNotIn?: Array<number>;
    userCompanyInviteIdGreaterThan?: number;
    userCompanyInviteIdLessThan?: number;
    userCompanyInviteIdGreaterThanOrEqual?: number;
    userCompanyInviteIdLessThanOrEqual?: number;
    userCompanyInviteIdEquals?: number;
    userCompanyInviteIdNotEquals?: number;
    userCompanyInviteIdSpecified?: boolean;
    userCompanyInviteIdIn?: Array<number>;
    userCompanyInviteIdNotIn?: Array<number>;
    forestryIdGreaterThan?: number;
    forestryIdLessThan?: number;
    forestryIdGreaterThanOrEqual?: number;
    forestryIdLessThanOrEqual?: number;
    forestryIdEquals?: number;
    forestryIdNotEquals?: number;
    forestryIdSpecified?: boolean;
    forestryIdIn?: Array<number>;
    forestryIdNotIn?: Array<number>;
    sawmillSupplierIdGreaterThan?: number;
    sawmillSupplierIdLessThan?: number;
    sawmillSupplierIdGreaterThanOrEqual?: number;
    sawmillSupplierIdLessThanOrEqual?: number;
    sawmillSupplierIdEquals?: number;
    sawmillSupplierIdNotEquals?: number;
    sawmillSupplierIdSpecified?: boolean;
    sawmillSupplierIdIn?: Array<number>;
    sawmillSupplierIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetCompanyRequest {
    id: number;
}

export interface PartialUpdateCompanyRequest {
    id: number;
    company: Company;
}

export interface UpdateCompanyRequest {
    id: number;
    company: Company;
}

/**
 * 
 */
export class CompanyResourceApi extends runtime.BaseAPI {

    /**
     */
    async countCompaniesRaw(requestParameters: CountCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.prefixIdentifierContains !== undefined) {
            queryParameters['prefixIdentifier.contains'] = requestParameters.prefixIdentifierContains;
        }

        if (requestParameters.prefixIdentifierDoesNotContain !== undefined) {
            queryParameters['prefixIdentifier.doesNotContain'] = requestParameters.prefixIdentifierDoesNotContain;
        }

        if (requestParameters.prefixIdentifierEquals !== undefined) {
            queryParameters['prefixIdentifier.equals'] = requestParameters.prefixIdentifierEquals;
        }

        if (requestParameters.prefixIdentifierNotEquals !== undefined) {
            queryParameters['prefixIdentifier.notEquals'] = requestParameters.prefixIdentifierNotEquals;
        }

        if (requestParameters.prefixIdentifierSpecified !== undefined) {
            queryParameters['prefixIdentifier.specified'] = requestParameters.prefixIdentifierSpecified;
        }

        if (requestParameters.prefixIdentifierIn) {
            queryParameters['prefixIdentifier.in'] = requestParameters.prefixIdentifierIn;
        }

        if (requestParameters.prefixIdentifierNotIn) {
            queryParameters['prefixIdentifier.notIn'] = requestParameters.prefixIdentifierNotIn;
        }

        if (requestParameters.idiNumberContains !== undefined) {
            queryParameters['idiNumber.contains'] = requestParameters.idiNumberContains;
        }

        if (requestParameters.idiNumberDoesNotContain !== undefined) {
            queryParameters['idiNumber.doesNotContain'] = requestParameters.idiNumberDoesNotContain;
        }

        if (requestParameters.idiNumberEquals !== undefined) {
            queryParameters['idiNumber.equals'] = requestParameters.idiNumberEquals;
        }

        if (requestParameters.idiNumberNotEquals !== undefined) {
            queryParameters['idiNumber.notEquals'] = requestParameters.idiNumberNotEquals;
        }

        if (requestParameters.idiNumberSpecified !== undefined) {
            queryParameters['idiNumber.specified'] = requestParameters.idiNumberSpecified;
        }

        if (requestParameters.idiNumberIn) {
            queryParameters['idiNumber.in'] = requestParameters.idiNumberIn;
        }

        if (requestParameters.idiNumberNotIn) {
            queryParameters['idiNumber.notIn'] = requestParameters.idiNumberNotIn;
        }

        if (requestParameters.streetNameContains !== undefined) {
            queryParameters['streetName.contains'] = requestParameters.streetNameContains;
        }

        if (requestParameters.streetNameDoesNotContain !== undefined) {
            queryParameters['streetName.doesNotContain'] = requestParameters.streetNameDoesNotContain;
        }

        if (requestParameters.streetNameEquals !== undefined) {
            queryParameters['streetName.equals'] = requestParameters.streetNameEquals;
        }

        if (requestParameters.streetNameNotEquals !== undefined) {
            queryParameters['streetName.notEquals'] = requestParameters.streetNameNotEquals;
        }

        if (requestParameters.streetNameSpecified !== undefined) {
            queryParameters['streetName.specified'] = requestParameters.streetNameSpecified;
        }

        if (requestParameters.streetNameIn) {
            queryParameters['streetName.in'] = requestParameters.streetNameIn;
        }

        if (requestParameters.streetNameNotIn) {
            queryParameters['streetName.notIn'] = requestParameters.streetNameNotIn;
        }

        if (requestParameters.streetNumberContains !== undefined) {
            queryParameters['streetNumber.contains'] = requestParameters.streetNumberContains;
        }

        if (requestParameters.streetNumberDoesNotContain !== undefined) {
            queryParameters['streetNumber.doesNotContain'] = requestParameters.streetNumberDoesNotContain;
        }

        if (requestParameters.streetNumberEquals !== undefined) {
            queryParameters['streetNumber.equals'] = requestParameters.streetNumberEquals;
        }

        if (requestParameters.streetNumberNotEquals !== undefined) {
            queryParameters['streetNumber.notEquals'] = requestParameters.streetNumberNotEquals;
        }

        if (requestParameters.streetNumberSpecified !== undefined) {
            queryParameters['streetNumber.specified'] = requestParameters.streetNumberSpecified;
        }

        if (requestParameters.streetNumberIn) {
            queryParameters['streetNumber.in'] = requestParameters.streetNumberIn;
        }

        if (requestParameters.streetNumberNotIn) {
            queryParameters['streetNumber.notIn'] = requestParameters.streetNumberNotIn;
        }

        if (requestParameters.phoneNumberContains !== undefined) {
            queryParameters['phoneNumber.contains'] = requestParameters.phoneNumberContains;
        }

        if (requestParameters.phoneNumberDoesNotContain !== undefined) {
            queryParameters['phoneNumber.doesNotContain'] = requestParameters.phoneNumberDoesNotContain;
        }

        if (requestParameters.phoneNumberEquals !== undefined) {
            queryParameters['phoneNumber.equals'] = requestParameters.phoneNumberEquals;
        }

        if (requestParameters.phoneNumberNotEquals !== undefined) {
            queryParameters['phoneNumber.notEquals'] = requestParameters.phoneNumberNotEquals;
        }

        if (requestParameters.phoneNumberSpecified !== undefined) {
            queryParameters['phoneNumber.specified'] = requestParameters.phoneNumberSpecified;
        }

        if (requestParameters.phoneNumberIn) {
            queryParameters['phoneNumber.in'] = requestParameters.phoneNumberIn;
        }

        if (requestParameters.phoneNumberNotIn) {
            queryParameters['phoneNumber.notIn'] = requestParameters.phoneNumberNotIn;
        }

        if (requestParameters.emailContains !== undefined) {
            queryParameters['email.contains'] = requestParameters.emailContains;
        }

        if (requestParameters.emailDoesNotContain !== undefined) {
            queryParameters['email.doesNotContain'] = requestParameters.emailDoesNotContain;
        }

        if (requestParameters.emailEquals !== undefined) {
            queryParameters['email.equals'] = requestParameters.emailEquals;
        }

        if (requestParameters.emailNotEquals !== undefined) {
            queryParameters['email.notEquals'] = requestParameters.emailNotEquals;
        }

        if (requestParameters.emailSpecified !== undefined) {
            queryParameters['email.specified'] = requestParameters.emailSpecified;
        }

        if (requestParameters.emailIn) {
            queryParameters['email.in'] = requestParameters.emailIn;
        }

        if (requestParameters.emailNotIn) {
            queryParameters['email.notIn'] = requestParameters.emailNotIn;
        }

        if (requestParameters.cityContains !== undefined) {
            queryParameters['city.contains'] = requestParameters.cityContains;
        }

        if (requestParameters.cityDoesNotContain !== undefined) {
            queryParameters['city.doesNotContain'] = requestParameters.cityDoesNotContain;
        }

        if (requestParameters.cityEquals !== undefined) {
            queryParameters['city.equals'] = requestParameters.cityEquals;
        }

        if (requestParameters.cityNotEquals !== undefined) {
            queryParameters['city.notEquals'] = requestParameters.cityNotEquals;
        }

        if (requestParameters.citySpecified !== undefined) {
            queryParameters['city.specified'] = requestParameters.citySpecified;
        }

        if (requestParameters.cityIn) {
            queryParameters['city.in'] = requestParameters.cityIn;
        }

        if (requestParameters.cityNotIn) {
            queryParameters['city.notIn'] = requestParameters.cityNotIn;
        }

        if (requestParameters.zipCodeContains !== undefined) {
            queryParameters['zipCode.contains'] = requestParameters.zipCodeContains;
        }

        if (requestParameters.zipCodeDoesNotContain !== undefined) {
            queryParameters['zipCode.doesNotContain'] = requestParameters.zipCodeDoesNotContain;
        }

        if (requestParameters.zipCodeEquals !== undefined) {
            queryParameters['zipCode.equals'] = requestParameters.zipCodeEquals;
        }

        if (requestParameters.zipCodeNotEquals !== undefined) {
            queryParameters['zipCode.notEquals'] = requestParameters.zipCodeNotEquals;
        }

        if (requestParameters.zipCodeSpecified !== undefined) {
            queryParameters['zipCode.specified'] = requestParameters.zipCodeSpecified;
        }

        if (requestParameters.zipCodeIn) {
            queryParameters['zipCode.in'] = requestParameters.zipCodeIn;
        }

        if (requestParameters.zipCodeNotIn) {
            queryParameters['zipCode.notIn'] = requestParameters.zipCodeNotIn;
        }

        if (requestParameters.logoFilenameContains !== undefined) {
            queryParameters['logoFilename.contains'] = requestParameters.logoFilenameContains;
        }

        if (requestParameters.logoFilenameDoesNotContain !== undefined) {
            queryParameters['logoFilename.doesNotContain'] = requestParameters.logoFilenameDoesNotContain;
        }

        if (requestParameters.logoFilenameEquals !== undefined) {
            queryParameters['logoFilename.equals'] = requestParameters.logoFilenameEquals;
        }

        if (requestParameters.logoFilenameNotEquals !== undefined) {
            queryParameters['logoFilename.notEquals'] = requestParameters.logoFilenameNotEquals;
        }

        if (requestParameters.logoFilenameSpecified !== undefined) {
            queryParameters['logoFilename.specified'] = requestParameters.logoFilenameSpecified;
        }

        if (requestParameters.logoFilenameIn) {
            queryParameters['logoFilename.in'] = requestParameters.logoFilenameIn;
        }

        if (requestParameters.logoFilenameNotIn) {
            queryParameters['logoFilename.notIn'] = requestParameters.logoFilenameNotIn;
        }

        if (requestParameters.logoFilepathContains !== undefined) {
            queryParameters['logoFilepath.contains'] = requestParameters.logoFilepathContains;
        }

        if (requestParameters.logoFilepathDoesNotContain !== undefined) {
            queryParameters['logoFilepath.doesNotContain'] = requestParameters.logoFilepathDoesNotContain;
        }

        if (requestParameters.logoFilepathEquals !== undefined) {
            queryParameters['logoFilepath.equals'] = requestParameters.logoFilepathEquals;
        }

        if (requestParameters.logoFilepathNotEquals !== undefined) {
            queryParameters['logoFilepath.notEquals'] = requestParameters.logoFilepathNotEquals;
        }

        if (requestParameters.logoFilepathSpecified !== undefined) {
            queryParameters['logoFilepath.specified'] = requestParameters.logoFilepathSpecified;
        }

        if (requestParameters.logoFilepathIn) {
            queryParameters['logoFilepath.in'] = requestParameters.logoFilepathIn;
        }

        if (requestParameters.logoFilepathNotIn) {
            queryParameters['logoFilepath.notIn'] = requestParameters.logoFilepathNotIn;
        }

        if (requestParameters.logoUrlContains !== undefined) {
            queryParameters['logoUrl.contains'] = requestParameters.logoUrlContains;
        }

        if (requestParameters.logoUrlDoesNotContain !== undefined) {
            queryParameters['logoUrl.doesNotContain'] = requestParameters.logoUrlDoesNotContain;
        }

        if (requestParameters.logoUrlEquals !== undefined) {
            queryParameters['logoUrl.equals'] = requestParameters.logoUrlEquals;
        }

        if (requestParameters.logoUrlNotEquals !== undefined) {
            queryParameters['logoUrl.notEquals'] = requestParameters.logoUrlNotEquals;
        }

        if (requestParameters.logoUrlSpecified !== undefined) {
            queryParameters['logoUrl.specified'] = requestParameters.logoUrlSpecified;
        }

        if (requestParameters.logoUrlIn) {
            queryParameters['logoUrl.in'] = requestParameters.logoUrlIn;
        }

        if (requestParameters.logoUrlNotIn) {
            queryParameters['logoUrl.notIn'] = requestParameters.logoUrlNotIn;
        }

        if (requestParameters.sawmillIdGreaterThan !== undefined) {
            queryParameters['sawmillId.greaterThan'] = requestParameters.sawmillIdGreaterThan;
        }

        if (requestParameters.sawmillIdLessThan !== undefined) {
            queryParameters['sawmillId.lessThan'] = requestParameters.sawmillIdLessThan;
        }

        if (requestParameters.sawmillIdGreaterThanOrEqual !== undefined) {
            queryParameters['sawmillId.greaterThanOrEqual'] = requestParameters.sawmillIdGreaterThanOrEqual;
        }

        if (requestParameters.sawmillIdLessThanOrEqual !== undefined) {
            queryParameters['sawmillId.lessThanOrEqual'] = requestParameters.sawmillIdLessThanOrEqual;
        }

        if (requestParameters.sawmillIdEquals !== undefined) {
            queryParameters['sawmillId.equals'] = requestParameters.sawmillIdEquals;
        }

        if (requestParameters.sawmillIdNotEquals !== undefined) {
            queryParameters['sawmillId.notEquals'] = requestParameters.sawmillIdNotEquals;
        }

        if (requestParameters.sawmillIdSpecified !== undefined) {
            queryParameters['sawmillId.specified'] = requestParameters.sawmillIdSpecified;
        }

        if (requestParameters.sawmillIdIn) {
            queryParameters['sawmillId.in'] = requestParameters.sawmillIdIn;
        }

        if (requestParameters.sawmillIdNotIn) {
            queryParameters['sawmillId.notIn'] = requestParameters.sawmillIdNotIn;
        }

        if (requestParameters.processorIdGreaterThan !== undefined) {
            queryParameters['processorId.greaterThan'] = requestParameters.processorIdGreaterThan;
        }

        if (requestParameters.processorIdLessThan !== undefined) {
            queryParameters['processorId.lessThan'] = requestParameters.processorIdLessThan;
        }

        if (requestParameters.processorIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorId.greaterThanOrEqual'] = requestParameters.processorIdGreaterThanOrEqual;
        }

        if (requestParameters.processorIdLessThanOrEqual !== undefined) {
            queryParameters['processorId.lessThanOrEqual'] = requestParameters.processorIdLessThanOrEqual;
        }

        if (requestParameters.processorIdEquals !== undefined) {
            queryParameters['processorId.equals'] = requestParameters.processorIdEquals;
        }

        if (requestParameters.processorIdNotEquals !== undefined) {
            queryParameters['processorId.notEquals'] = requestParameters.processorIdNotEquals;
        }

        if (requestParameters.processorIdSpecified !== undefined) {
            queryParameters['processorId.specified'] = requestParameters.processorIdSpecified;
        }

        if (requestParameters.processorIdIn) {
            queryParameters['processorId.in'] = requestParameters.processorIdIn;
        }

        if (requestParameters.processorIdNotIn) {
            queryParameters['processorId.notIn'] = requestParameters.processorIdNotIn;
        }

        if (requestParameters.xmlExportErpIdGreaterThan !== undefined) {
            queryParameters['xmlExportErpId.greaterThan'] = requestParameters.xmlExportErpIdGreaterThan;
        }

        if (requestParameters.xmlExportErpIdLessThan !== undefined) {
            queryParameters['xmlExportErpId.lessThan'] = requestParameters.xmlExportErpIdLessThan;
        }

        if (requestParameters.xmlExportErpIdGreaterThanOrEqual !== undefined) {
            queryParameters['xmlExportErpId.greaterThanOrEqual'] = requestParameters.xmlExportErpIdGreaterThanOrEqual;
        }

        if (requestParameters.xmlExportErpIdLessThanOrEqual !== undefined) {
            queryParameters['xmlExportErpId.lessThanOrEqual'] = requestParameters.xmlExportErpIdLessThanOrEqual;
        }

        if (requestParameters.xmlExportErpIdEquals !== undefined) {
            queryParameters['xmlExportErpId.equals'] = requestParameters.xmlExportErpIdEquals;
        }

        if (requestParameters.xmlExportErpIdNotEquals !== undefined) {
            queryParameters['xmlExportErpId.notEquals'] = requestParameters.xmlExportErpIdNotEquals;
        }

        if (requestParameters.xmlExportErpIdSpecified !== undefined) {
            queryParameters['xmlExportErpId.specified'] = requestParameters.xmlExportErpIdSpecified;
        }

        if (requestParameters.xmlExportErpIdIn) {
            queryParameters['xmlExportErpId.in'] = requestParameters.xmlExportErpIdIn;
        }

        if (requestParameters.xmlExportErpIdNotIn) {
            queryParameters['xmlExportErpId.notIn'] = requestParameters.xmlExportErpIdNotIn;
        }

        if (requestParameters.companyAppearanceIdGreaterThan !== undefined) {
            queryParameters['companyAppearanceId.greaterThan'] = requestParameters.companyAppearanceIdGreaterThan;
        }

        if (requestParameters.companyAppearanceIdLessThan !== undefined) {
            queryParameters['companyAppearanceId.lessThan'] = requestParameters.companyAppearanceIdLessThan;
        }

        if (requestParameters.companyAppearanceIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyAppearanceId.greaterThanOrEqual'] = requestParameters.companyAppearanceIdGreaterThanOrEqual;
        }

        if (requestParameters.companyAppearanceIdLessThanOrEqual !== undefined) {
            queryParameters['companyAppearanceId.lessThanOrEqual'] = requestParameters.companyAppearanceIdLessThanOrEqual;
        }

        if (requestParameters.companyAppearanceIdEquals !== undefined) {
            queryParameters['companyAppearanceId.equals'] = requestParameters.companyAppearanceIdEquals;
        }

        if (requestParameters.companyAppearanceIdNotEquals !== undefined) {
            queryParameters['companyAppearanceId.notEquals'] = requestParameters.companyAppearanceIdNotEquals;
        }

        if (requestParameters.companyAppearanceIdSpecified !== undefined) {
            queryParameters['companyAppearanceId.specified'] = requestParameters.companyAppearanceIdSpecified;
        }

        if (requestParameters.companyAppearanceIdIn) {
            queryParameters['companyAppearanceId.in'] = requestParameters.companyAppearanceIdIn;
        }

        if (requestParameters.companyAppearanceIdNotIn) {
            queryParameters['companyAppearanceId.notIn'] = requestParameters.companyAppearanceIdNotIn;
        }

        if (requestParameters.companyProductTypeIdGreaterThan !== undefined) {
            queryParameters['companyProductTypeId.greaterThan'] = requestParameters.companyProductTypeIdGreaterThan;
        }

        if (requestParameters.companyProductTypeIdLessThan !== undefined) {
            queryParameters['companyProductTypeId.lessThan'] = requestParameters.companyProductTypeIdLessThan;
        }

        if (requestParameters.companyProductTypeIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyProductTypeId.greaterThanOrEqual'] = requestParameters.companyProductTypeIdGreaterThanOrEqual;
        }

        if (requestParameters.companyProductTypeIdLessThanOrEqual !== undefined) {
            queryParameters['companyProductTypeId.lessThanOrEqual'] = requestParameters.companyProductTypeIdLessThanOrEqual;
        }

        if (requestParameters.companyProductTypeIdEquals !== undefined) {
            queryParameters['companyProductTypeId.equals'] = requestParameters.companyProductTypeIdEquals;
        }

        if (requestParameters.companyProductTypeIdNotEquals !== undefined) {
            queryParameters['companyProductTypeId.notEquals'] = requestParameters.companyProductTypeIdNotEquals;
        }

        if (requestParameters.companyProductTypeIdSpecified !== undefined) {
            queryParameters['companyProductTypeId.specified'] = requestParameters.companyProductTypeIdSpecified;
        }

        if (requestParameters.companyProductTypeIdIn) {
            queryParameters['companyProductTypeId.in'] = requestParameters.companyProductTypeIdIn;
        }

        if (requestParameters.companyProductTypeIdNotIn) {
            queryParameters['companyProductTypeId.notIn'] = requestParameters.companyProductTypeIdNotIn;
        }

        if (requestParameters.companyPacketWoodQualityIdGreaterThan !== undefined) {
            queryParameters['companyPacketWoodQualityId.greaterThan'] = requestParameters.companyPacketWoodQualityIdGreaterThan;
        }

        if (requestParameters.companyPacketWoodQualityIdLessThan !== undefined) {
            queryParameters['companyPacketWoodQualityId.lessThan'] = requestParameters.companyPacketWoodQualityIdLessThan;
        }

        if (requestParameters.companyPacketWoodQualityIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyPacketWoodQualityId.greaterThanOrEqual'] = requestParameters.companyPacketWoodQualityIdGreaterThanOrEqual;
        }

        if (requestParameters.companyPacketWoodQualityIdLessThanOrEqual !== undefined) {
            queryParameters['companyPacketWoodQualityId.lessThanOrEqual'] = requestParameters.companyPacketWoodQualityIdLessThanOrEqual;
        }

        if (requestParameters.companyPacketWoodQualityIdEquals !== undefined) {
            queryParameters['companyPacketWoodQualityId.equals'] = requestParameters.companyPacketWoodQualityIdEquals;
        }

        if (requestParameters.companyPacketWoodQualityIdNotEquals !== undefined) {
            queryParameters['companyPacketWoodQualityId.notEquals'] = requestParameters.companyPacketWoodQualityIdNotEquals;
        }

        if (requestParameters.companyPacketWoodQualityIdSpecified !== undefined) {
            queryParameters['companyPacketWoodQualityId.specified'] = requestParameters.companyPacketWoodQualityIdSpecified;
        }

        if (requestParameters.companyPacketWoodQualityIdIn) {
            queryParameters['companyPacketWoodQualityId.in'] = requestParameters.companyPacketWoodQualityIdIn;
        }

        if (requestParameters.companyPacketWoodQualityIdNotIn) {
            queryParameters['companyPacketWoodQualityId.notIn'] = requestParameters.companyPacketWoodQualityIdNotIn;
        }

        if (requestParameters.companySurfaceIdGreaterThan !== undefined) {
            queryParameters['companySurfaceId.greaterThan'] = requestParameters.companySurfaceIdGreaterThan;
        }

        if (requestParameters.companySurfaceIdLessThan !== undefined) {
            queryParameters['companySurfaceId.lessThan'] = requestParameters.companySurfaceIdLessThan;
        }

        if (requestParameters.companySurfaceIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurfaceId.greaterThanOrEqual'] = requestParameters.companySurfaceIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurfaceIdLessThanOrEqual !== undefined) {
            queryParameters['companySurfaceId.lessThanOrEqual'] = requestParameters.companySurfaceIdLessThanOrEqual;
        }

        if (requestParameters.companySurfaceIdEquals !== undefined) {
            queryParameters['companySurfaceId.equals'] = requestParameters.companySurfaceIdEquals;
        }

        if (requestParameters.companySurfaceIdNotEquals !== undefined) {
            queryParameters['companySurfaceId.notEquals'] = requestParameters.companySurfaceIdNotEquals;
        }

        if (requestParameters.companySurfaceIdSpecified !== undefined) {
            queryParameters['companySurfaceId.specified'] = requestParameters.companySurfaceIdSpecified;
        }

        if (requestParameters.companySurfaceIdIn) {
            queryParameters['companySurfaceId.in'] = requestParameters.companySurfaceIdIn;
        }

        if (requestParameters.companySurfaceIdNotIn) {
            queryParameters['companySurfaceId.notIn'] = requestParameters.companySurfaceIdNotIn;
        }

        if (requestParameters.loggingIdGreaterThan !== undefined) {
            queryParameters['loggingId.greaterThan'] = requestParameters.loggingIdGreaterThan;
        }

        if (requestParameters.loggingIdLessThan !== undefined) {
            queryParameters['loggingId.lessThan'] = requestParameters.loggingIdLessThan;
        }

        if (requestParameters.loggingIdGreaterThanOrEqual !== undefined) {
            queryParameters['loggingId.greaterThanOrEqual'] = requestParameters.loggingIdGreaterThanOrEqual;
        }

        if (requestParameters.loggingIdLessThanOrEqual !== undefined) {
            queryParameters['loggingId.lessThanOrEqual'] = requestParameters.loggingIdLessThanOrEqual;
        }

        if (requestParameters.loggingIdEquals !== undefined) {
            queryParameters['loggingId.equals'] = requestParameters.loggingIdEquals;
        }

        if (requestParameters.loggingIdNotEquals !== undefined) {
            queryParameters['loggingId.notEquals'] = requestParameters.loggingIdNotEquals;
        }

        if (requestParameters.loggingIdSpecified !== undefined) {
            queryParameters['loggingId.specified'] = requestParameters.loggingIdSpecified;
        }

        if (requestParameters.loggingIdIn) {
            queryParameters['loggingId.in'] = requestParameters.loggingIdIn;
        }

        if (requestParameters.loggingIdNotIn) {
            queryParameters['loggingId.notIn'] = requestParameters.loggingIdNotIn;
        }

        if (requestParameters.userCompanyIdGreaterThan !== undefined) {
            queryParameters['userCompanyId.greaterThan'] = requestParameters.userCompanyIdGreaterThan;
        }

        if (requestParameters.userCompanyIdLessThan !== undefined) {
            queryParameters['userCompanyId.lessThan'] = requestParameters.userCompanyIdLessThan;
        }

        if (requestParameters.userCompanyIdGreaterThanOrEqual !== undefined) {
            queryParameters['userCompanyId.greaterThanOrEqual'] = requestParameters.userCompanyIdGreaterThanOrEqual;
        }

        if (requestParameters.userCompanyIdLessThanOrEqual !== undefined) {
            queryParameters['userCompanyId.lessThanOrEqual'] = requestParameters.userCompanyIdLessThanOrEqual;
        }

        if (requestParameters.userCompanyIdEquals !== undefined) {
            queryParameters['userCompanyId.equals'] = requestParameters.userCompanyIdEquals;
        }

        if (requestParameters.userCompanyIdNotEquals !== undefined) {
            queryParameters['userCompanyId.notEquals'] = requestParameters.userCompanyIdNotEquals;
        }

        if (requestParameters.userCompanyIdSpecified !== undefined) {
            queryParameters['userCompanyId.specified'] = requestParameters.userCompanyIdSpecified;
        }

        if (requestParameters.userCompanyIdIn) {
            queryParameters['userCompanyId.in'] = requestParameters.userCompanyIdIn;
        }

        if (requestParameters.userCompanyIdNotIn) {
            queryParameters['userCompanyId.notIn'] = requestParameters.userCompanyIdNotIn;
        }

        if (requestParameters.userCompanyInviteIdGreaterThan !== undefined) {
            queryParameters['userCompanyInviteId.greaterThan'] = requestParameters.userCompanyInviteIdGreaterThan;
        }

        if (requestParameters.userCompanyInviteIdLessThan !== undefined) {
            queryParameters['userCompanyInviteId.lessThan'] = requestParameters.userCompanyInviteIdLessThan;
        }

        if (requestParameters.userCompanyInviteIdGreaterThanOrEqual !== undefined) {
            queryParameters['userCompanyInviteId.greaterThanOrEqual'] = requestParameters.userCompanyInviteIdGreaterThanOrEqual;
        }

        if (requestParameters.userCompanyInviteIdLessThanOrEqual !== undefined) {
            queryParameters['userCompanyInviteId.lessThanOrEqual'] = requestParameters.userCompanyInviteIdLessThanOrEqual;
        }

        if (requestParameters.userCompanyInviteIdEquals !== undefined) {
            queryParameters['userCompanyInviteId.equals'] = requestParameters.userCompanyInviteIdEquals;
        }

        if (requestParameters.userCompanyInviteIdNotEquals !== undefined) {
            queryParameters['userCompanyInviteId.notEquals'] = requestParameters.userCompanyInviteIdNotEquals;
        }

        if (requestParameters.userCompanyInviteIdSpecified !== undefined) {
            queryParameters['userCompanyInviteId.specified'] = requestParameters.userCompanyInviteIdSpecified;
        }

        if (requestParameters.userCompanyInviteIdIn) {
            queryParameters['userCompanyInviteId.in'] = requestParameters.userCompanyInviteIdIn;
        }

        if (requestParameters.userCompanyInviteIdNotIn) {
            queryParameters['userCompanyInviteId.notIn'] = requestParameters.userCompanyInviteIdNotIn;
        }

        if (requestParameters.forestryIdGreaterThan !== undefined) {
            queryParameters['forestryId.greaterThan'] = requestParameters.forestryIdGreaterThan;
        }

        if (requestParameters.forestryIdLessThan !== undefined) {
            queryParameters['forestryId.lessThan'] = requestParameters.forestryIdLessThan;
        }

        if (requestParameters.forestryIdGreaterThanOrEqual !== undefined) {
            queryParameters['forestryId.greaterThanOrEqual'] = requestParameters.forestryIdGreaterThanOrEqual;
        }

        if (requestParameters.forestryIdLessThanOrEqual !== undefined) {
            queryParameters['forestryId.lessThanOrEqual'] = requestParameters.forestryIdLessThanOrEqual;
        }

        if (requestParameters.forestryIdEquals !== undefined) {
            queryParameters['forestryId.equals'] = requestParameters.forestryIdEquals;
        }

        if (requestParameters.forestryIdNotEquals !== undefined) {
            queryParameters['forestryId.notEquals'] = requestParameters.forestryIdNotEquals;
        }

        if (requestParameters.forestryIdSpecified !== undefined) {
            queryParameters['forestryId.specified'] = requestParameters.forestryIdSpecified;
        }

        if (requestParameters.forestryIdIn) {
            queryParameters['forestryId.in'] = requestParameters.forestryIdIn;
        }

        if (requestParameters.forestryIdNotIn) {
            queryParameters['forestryId.notIn'] = requestParameters.forestryIdNotIn;
        }

        if (requestParameters.sawmillSupplierIdGreaterThan !== undefined) {
            queryParameters['sawmillSupplierId.greaterThan'] = requestParameters.sawmillSupplierIdGreaterThan;
        }

        if (requestParameters.sawmillSupplierIdLessThan !== undefined) {
            queryParameters['sawmillSupplierId.lessThan'] = requestParameters.sawmillSupplierIdLessThan;
        }

        if (requestParameters.sawmillSupplierIdGreaterThanOrEqual !== undefined) {
            queryParameters['sawmillSupplierId.greaterThanOrEqual'] = requestParameters.sawmillSupplierIdGreaterThanOrEqual;
        }

        if (requestParameters.sawmillSupplierIdLessThanOrEqual !== undefined) {
            queryParameters['sawmillSupplierId.lessThanOrEqual'] = requestParameters.sawmillSupplierIdLessThanOrEqual;
        }

        if (requestParameters.sawmillSupplierIdEquals !== undefined) {
            queryParameters['sawmillSupplierId.equals'] = requestParameters.sawmillSupplierIdEquals;
        }

        if (requestParameters.sawmillSupplierIdNotEquals !== undefined) {
            queryParameters['sawmillSupplierId.notEquals'] = requestParameters.sawmillSupplierIdNotEquals;
        }

        if (requestParameters.sawmillSupplierIdSpecified !== undefined) {
            queryParameters['sawmillSupplierId.specified'] = requestParameters.sawmillSupplierIdSpecified;
        }

        if (requestParameters.sawmillSupplierIdIn) {
            queryParameters['sawmillSupplierId.in'] = requestParameters.sawmillSupplierIdIn;
        }

        if (requestParameters.sawmillSupplierIdNotIn) {
            queryParameters['sawmillSupplierId.notIn'] = requestParameters.sawmillSupplierIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countCompanies(requestParameters: CountCompaniesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling createCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async createCompany(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.createCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCompanyRaw(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompany(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllCompaniesRaw(requestParameters: GetAllCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Company>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.prefixIdentifierContains !== undefined) {
            queryParameters['prefixIdentifier.contains'] = requestParameters.prefixIdentifierContains;
        }

        if (requestParameters.prefixIdentifierDoesNotContain !== undefined) {
            queryParameters['prefixIdentifier.doesNotContain'] = requestParameters.prefixIdentifierDoesNotContain;
        }

        if (requestParameters.prefixIdentifierEquals !== undefined) {
            queryParameters['prefixIdentifier.equals'] = requestParameters.prefixIdentifierEquals;
        }

        if (requestParameters.prefixIdentifierNotEquals !== undefined) {
            queryParameters['prefixIdentifier.notEquals'] = requestParameters.prefixIdentifierNotEquals;
        }

        if (requestParameters.prefixIdentifierSpecified !== undefined) {
            queryParameters['prefixIdentifier.specified'] = requestParameters.prefixIdentifierSpecified;
        }

        if (requestParameters.prefixIdentifierIn) {
            queryParameters['prefixIdentifier.in'] = requestParameters.prefixIdentifierIn;
        }

        if (requestParameters.prefixIdentifierNotIn) {
            queryParameters['prefixIdentifier.notIn'] = requestParameters.prefixIdentifierNotIn;
        }

        if (requestParameters.idiNumberContains !== undefined) {
            queryParameters['idiNumber.contains'] = requestParameters.idiNumberContains;
        }

        if (requestParameters.idiNumberDoesNotContain !== undefined) {
            queryParameters['idiNumber.doesNotContain'] = requestParameters.idiNumberDoesNotContain;
        }

        if (requestParameters.idiNumberEquals !== undefined) {
            queryParameters['idiNumber.equals'] = requestParameters.idiNumberEquals;
        }

        if (requestParameters.idiNumberNotEquals !== undefined) {
            queryParameters['idiNumber.notEquals'] = requestParameters.idiNumberNotEquals;
        }

        if (requestParameters.idiNumberSpecified !== undefined) {
            queryParameters['idiNumber.specified'] = requestParameters.idiNumberSpecified;
        }

        if (requestParameters.idiNumberIn) {
            queryParameters['idiNumber.in'] = requestParameters.idiNumberIn;
        }

        if (requestParameters.idiNumberNotIn) {
            queryParameters['idiNumber.notIn'] = requestParameters.idiNumberNotIn;
        }

        if (requestParameters.streetNameContains !== undefined) {
            queryParameters['streetName.contains'] = requestParameters.streetNameContains;
        }

        if (requestParameters.streetNameDoesNotContain !== undefined) {
            queryParameters['streetName.doesNotContain'] = requestParameters.streetNameDoesNotContain;
        }

        if (requestParameters.streetNameEquals !== undefined) {
            queryParameters['streetName.equals'] = requestParameters.streetNameEquals;
        }

        if (requestParameters.streetNameNotEquals !== undefined) {
            queryParameters['streetName.notEquals'] = requestParameters.streetNameNotEquals;
        }

        if (requestParameters.streetNameSpecified !== undefined) {
            queryParameters['streetName.specified'] = requestParameters.streetNameSpecified;
        }

        if (requestParameters.streetNameIn) {
            queryParameters['streetName.in'] = requestParameters.streetNameIn;
        }

        if (requestParameters.streetNameNotIn) {
            queryParameters['streetName.notIn'] = requestParameters.streetNameNotIn;
        }

        if (requestParameters.streetNumberContains !== undefined) {
            queryParameters['streetNumber.contains'] = requestParameters.streetNumberContains;
        }

        if (requestParameters.streetNumberDoesNotContain !== undefined) {
            queryParameters['streetNumber.doesNotContain'] = requestParameters.streetNumberDoesNotContain;
        }

        if (requestParameters.streetNumberEquals !== undefined) {
            queryParameters['streetNumber.equals'] = requestParameters.streetNumberEquals;
        }

        if (requestParameters.streetNumberNotEquals !== undefined) {
            queryParameters['streetNumber.notEquals'] = requestParameters.streetNumberNotEquals;
        }

        if (requestParameters.streetNumberSpecified !== undefined) {
            queryParameters['streetNumber.specified'] = requestParameters.streetNumberSpecified;
        }

        if (requestParameters.streetNumberIn) {
            queryParameters['streetNumber.in'] = requestParameters.streetNumberIn;
        }

        if (requestParameters.streetNumberNotIn) {
            queryParameters['streetNumber.notIn'] = requestParameters.streetNumberNotIn;
        }

        if (requestParameters.phoneNumberContains !== undefined) {
            queryParameters['phoneNumber.contains'] = requestParameters.phoneNumberContains;
        }

        if (requestParameters.phoneNumberDoesNotContain !== undefined) {
            queryParameters['phoneNumber.doesNotContain'] = requestParameters.phoneNumberDoesNotContain;
        }

        if (requestParameters.phoneNumberEquals !== undefined) {
            queryParameters['phoneNumber.equals'] = requestParameters.phoneNumberEquals;
        }

        if (requestParameters.phoneNumberNotEquals !== undefined) {
            queryParameters['phoneNumber.notEquals'] = requestParameters.phoneNumberNotEquals;
        }

        if (requestParameters.phoneNumberSpecified !== undefined) {
            queryParameters['phoneNumber.specified'] = requestParameters.phoneNumberSpecified;
        }

        if (requestParameters.phoneNumberIn) {
            queryParameters['phoneNumber.in'] = requestParameters.phoneNumberIn;
        }

        if (requestParameters.phoneNumberNotIn) {
            queryParameters['phoneNumber.notIn'] = requestParameters.phoneNumberNotIn;
        }

        if (requestParameters.emailContains !== undefined) {
            queryParameters['email.contains'] = requestParameters.emailContains;
        }

        if (requestParameters.emailDoesNotContain !== undefined) {
            queryParameters['email.doesNotContain'] = requestParameters.emailDoesNotContain;
        }

        if (requestParameters.emailEquals !== undefined) {
            queryParameters['email.equals'] = requestParameters.emailEquals;
        }

        if (requestParameters.emailNotEquals !== undefined) {
            queryParameters['email.notEquals'] = requestParameters.emailNotEquals;
        }

        if (requestParameters.emailSpecified !== undefined) {
            queryParameters['email.specified'] = requestParameters.emailSpecified;
        }

        if (requestParameters.emailIn) {
            queryParameters['email.in'] = requestParameters.emailIn;
        }

        if (requestParameters.emailNotIn) {
            queryParameters['email.notIn'] = requestParameters.emailNotIn;
        }

        if (requestParameters.cityContains !== undefined) {
            queryParameters['city.contains'] = requestParameters.cityContains;
        }

        if (requestParameters.cityDoesNotContain !== undefined) {
            queryParameters['city.doesNotContain'] = requestParameters.cityDoesNotContain;
        }

        if (requestParameters.cityEquals !== undefined) {
            queryParameters['city.equals'] = requestParameters.cityEquals;
        }

        if (requestParameters.cityNotEquals !== undefined) {
            queryParameters['city.notEquals'] = requestParameters.cityNotEquals;
        }

        if (requestParameters.citySpecified !== undefined) {
            queryParameters['city.specified'] = requestParameters.citySpecified;
        }

        if (requestParameters.cityIn) {
            queryParameters['city.in'] = requestParameters.cityIn;
        }

        if (requestParameters.cityNotIn) {
            queryParameters['city.notIn'] = requestParameters.cityNotIn;
        }

        if (requestParameters.zipCodeContains !== undefined) {
            queryParameters['zipCode.contains'] = requestParameters.zipCodeContains;
        }

        if (requestParameters.zipCodeDoesNotContain !== undefined) {
            queryParameters['zipCode.doesNotContain'] = requestParameters.zipCodeDoesNotContain;
        }

        if (requestParameters.zipCodeEquals !== undefined) {
            queryParameters['zipCode.equals'] = requestParameters.zipCodeEquals;
        }

        if (requestParameters.zipCodeNotEquals !== undefined) {
            queryParameters['zipCode.notEquals'] = requestParameters.zipCodeNotEquals;
        }

        if (requestParameters.zipCodeSpecified !== undefined) {
            queryParameters['zipCode.specified'] = requestParameters.zipCodeSpecified;
        }

        if (requestParameters.zipCodeIn) {
            queryParameters['zipCode.in'] = requestParameters.zipCodeIn;
        }

        if (requestParameters.zipCodeNotIn) {
            queryParameters['zipCode.notIn'] = requestParameters.zipCodeNotIn;
        }

        if (requestParameters.logoFilenameContains !== undefined) {
            queryParameters['logoFilename.contains'] = requestParameters.logoFilenameContains;
        }

        if (requestParameters.logoFilenameDoesNotContain !== undefined) {
            queryParameters['logoFilename.doesNotContain'] = requestParameters.logoFilenameDoesNotContain;
        }

        if (requestParameters.logoFilenameEquals !== undefined) {
            queryParameters['logoFilename.equals'] = requestParameters.logoFilenameEquals;
        }

        if (requestParameters.logoFilenameNotEquals !== undefined) {
            queryParameters['logoFilename.notEquals'] = requestParameters.logoFilenameNotEquals;
        }

        if (requestParameters.logoFilenameSpecified !== undefined) {
            queryParameters['logoFilename.specified'] = requestParameters.logoFilenameSpecified;
        }

        if (requestParameters.logoFilenameIn) {
            queryParameters['logoFilename.in'] = requestParameters.logoFilenameIn;
        }

        if (requestParameters.logoFilenameNotIn) {
            queryParameters['logoFilename.notIn'] = requestParameters.logoFilenameNotIn;
        }

        if (requestParameters.logoFilepathContains !== undefined) {
            queryParameters['logoFilepath.contains'] = requestParameters.logoFilepathContains;
        }

        if (requestParameters.logoFilepathDoesNotContain !== undefined) {
            queryParameters['logoFilepath.doesNotContain'] = requestParameters.logoFilepathDoesNotContain;
        }

        if (requestParameters.logoFilepathEquals !== undefined) {
            queryParameters['logoFilepath.equals'] = requestParameters.logoFilepathEquals;
        }

        if (requestParameters.logoFilepathNotEquals !== undefined) {
            queryParameters['logoFilepath.notEquals'] = requestParameters.logoFilepathNotEquals;
        }

        if (requestParameters.logoFilepathSpecified !== undefined) {
            queryParameters['logoFilepath.specified'] = requestParameters.logoFilepathSpecified;
        }

        if (requestParameters.logoFilepathIn) {
            queryParameters['logoFilepath.in'] = requestParameters.logoFilepathIn;
        }

        if (requestParameters.logoFilepathNotIn) {
            queryParameters['logoFilepath.notIn'] = requestParameters.logoFilepathNotIn;
        }

        if (requestParameters.logoUrlContains !== undefined) {
            queryParameters['logoUrl.contains'] = requestParameters.logoUrlContains;
        }

        if (requestParameters.logoUrlDoesNotContain !== undefined) {
            queryParameters['logoUrl.doesNotContain'] = requestParameters.logoUrlDoesNotContain;
        }

        if (requestParameters.logoUrlEquals !== undefined) {
            queryParameters['logoUrl.equals'] = requestParameters.logoUrlEquals;
        }

        if (requestParameters.logoUrlNotEquals !== undefined) {
            queryParameters['logoUrl.notEquals'] = requestParameters.logoUrlNotEquals;
        }

        if (requestParameters.logoUrlSpecified !== undefined) {
            queryParameters['logoUrl.specified'] = requestParameters.logoUrlSpecified;
        }

        if (requestParameters.logoUrlIn) {
            queryParameters['logoUrl.in'] = requestParameters.logoUrlIn;
        }

        if (requestParameters.logoUrlNotIn) {
            queryParameters['logoUrl.notIn'] = requestParameters.logoUrlNotIn;
        }

        if (requestParameters.sawmillIdGreaterThan !== undefined) {
            queryParameters['sawmillId.greaterThan'] = requestParameters.sawmillIdGreaterThan;
        }

        if (requestParameters.sawmillIdLessThan !== undefined) {
            queryParameters['sawmillId.lessThan'] = requestParameters.sawmillIdLessThan;
        }

        if (requestParameters.sawmillIdGreaterThanOrEqual !== undefined) {
            queryParameters['sawmillId.greaterThanOrEqual'] = requestParameters.sawmillIdGreaterThanOrEqual;
        }

        if (requestParameters.sawmillIdLessThanOrEqual !== undefined) {
            queryParameters['sawmillId.lessThanOrEqual'] = requestParameters.sawmillIdLessThanOrEqual;
        }

        if (requestParameters.sawmillIdEquals !== undefined) {
            queryParameters['sawmillId.equals'] = requestParameters.sawmillIdEquals;
        }

        if (requestParameters.sawmillIdNotEquals !== undefined) {
            queryParameters['sawmillId.notEquals'] = requestParameters.sawmillIdNotEquals;
        }

        if (requestParameters.sawmillIdSpecified !== undefined) {
            queryParameters['sawmillId.specified'] = requestParameters.sawmillIdSpecified;
        }

        if (requestParameters.sawmillIdIn) {
            queryParameters['sawmillId.in'] = requestParameters.sawmillIdIn;
        }

        if (requestParameters.sawmillIdNotIn) {
            queryParameters['sawmillId.notIn'] = requestParameters.sawmillIdNotIn;
        }

        if (requestParameters.processorIdGreaterThan !== undefined) {
            queryParameters['processorId.greaterThan'] = requestParameters.processorIdGreaterThan;
        }

        if (requestParameters.processorIdLessThan !== undefined) {
            queryParameters['processorId.lessThan'] = requestParameters.processorIdLessThan;
        }

        if (requestParameters.processorIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorId.greaterThanOrEqual'] = requestParameters.processorIdGreaterThanOrEqual;
        }

        if (requestParameters.processorIdLessThanOrEqual !== undefined) {
            queryParameters['processorId.lessThanOrEqual'] = requestParameters.processorIdLessThanOrEqual;
        }

        if (requestParameters.processorIdEquals !== undefined) {
            queryParameters['processorId.equals'] = requestParameters.processorIdEquals;
        }

        if (requestParameters.processorIdNotEquals !== undefined) {
            queryParameters['processorId.notEquals'] = requestParameters.processorIdNotEquals;
        }

        if (requestParameters.processorIdSpecified !== undefined) {
            queryParameters['processorId.specified'] = requestParameters.processorIdSpecified;
        }

        if (requestParameters.processorIdIn) {
            queryParameters['processorId.in'] = requestParameters.processorIdIn;
        }

        if (requestParameters.processorIdNotIn) {
            queryParameters['processorId.notIn'] = requestParameters.processorIdNotIn;
        }

        if (requestParameters.xmlExportErpIdGreaterThan !== undefined) {
            queryParameters['xmlExportErpId.greaterThan'] = requestParameters.xmlExportErpIdGreaterThan;
        }

        if (requestParameters.xmlExportErpIdLessThan !== undefined) {
            queryParameters['xmlExportErpId.lessThan'] = requestParameters.xmlExportErpIdLessThan;
        }

        if (requestParameters.xmlExportErpIdGreaterThanOrEqual !== undefined) {
            queryParameters['xmlExportErpId.greaterThanOrEqual'] = requestParameters.xmlExportErpIdGreaterThanOrEqual;
        }

        if (requestParameters.xmlExportErpIdLessThanOrEqual !== undefined) {
            queryParameters['xmlExportErpId.lessThanOrEqual'] = requestParameters.xmlExportErpIdLessThanOrEqual;
        }

        if (requestParameters.xmlExportErpIdEquals !== undefined) {
            queryParameters['xmlExportErpId.equals'] = requestParameters.xmlExportErpIdEquals;
        }

        if (requestParameters.xmlExportErpIdNotEquals !== undefined) {
            queryParameters['xmlExportErpId.notEquals'] = requestParameters.xmlExportErpIdNotEquals;
        }

        if (requestParameters.xmlExportErpIdSpecified !== undefined) {
            queryParameters['xmlExportErpId.specified'] = requestParameters.xmlExportErpIdSpecified;
        }

        if (requestParameters.xmlExportErpIdIn) {
            queryParameters['xmlExportErpId.in'] = requestParameters.xmlExportErpIdIn;
        }

        if (requestParameters.xmlExportErpIdNotIn) {
            queryParameters['xmlExportErpId.notIn'] = requestParameters.xmlExportErpIdNotIn;
        }

        if (requestParameters.companyAppearanceIdGreaterThan !== undefined) {
            queryParameters['companyAppearanceId.greaterThan'] = requestParameters.companyAppearanceIdGreaterThan;
        }

        if (requestParameters.companyAppearanceIdLessThan !== undefined) {
            queryParameters['companyAppearanceId.lessThan'] = requestParameters.companyAppearanceIdLessThan;
        }

        if (requestParameters.companyAppearanceIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyAppearanceId.greaterThanOrEqual'] = requestParameters.companyAppearanceIdGreaterThanOrEqual;
        }

        if (requestParameters.companyAppearanceIdLessThanOrEqual !== undefined) {
            queryParameters['companyAppearanceId.lessThanOrEqual'] = requestParameters.companyAppearanceIdLessThanOrEqual;
        }

        if (requestParameters.companyAppearanceIdEquals !== undefined) {
            queryParameters['companyAppearanceId.equals'] = requestParameters.companyAppearanceIdEquals;
        }

        if (requestParameters.companyAppearanceIdNotEquals !== undefined) {
            queryParameters['companyAppearanceId.notEquals'] = requestParameters.companyAppearanceIdNotEquals;
        }

        if (requestParameters.companyAppearanceIdSpecified !== undefined) {
            queryParameters['companyAppearanceId.specified'] = requestParameters.companyAppearanceIdSpecified;
        }

        if (requestParameters.companyAppearanceIdIn) {
            queryParameters['companyAppearanceId.in'] = requestParameters.companyAppearanceIdIn;
        }

        if (requestParameters.companyAppearanceIdNotIn) {
            queryParameters['companyAppearanceId.notIn'] = requestParameters.companyAppearanceIdNotIn;
        }

        if (requestParameters.companyProductTypeIdGreaterThan !== undefined) {
            queryParameters['companyProductTypeId.greaterThan'] = requestParameters.companyProductTypeIdGreaterThan;
        }

        if (requestParameters.companyProductTypeIdLessThan !== undefined) {
            queryParameters['companyProductTypeId.lessThan'] = requestParameters.companyProductTypeIdLessThan;
        }

        if (requestParameters.companyProductTypeIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyProductTypeId.greaterThanOrEqual'] = requestParameters.companyProductTypeIdGreaterThanOrEqual;
        }

        if (requestParameters.companyProductTypeIdLessThanOrEqual !== undefined) {
            queryParameters['companyProductTypeId.lessThanOrEqual'] = requestParameters.companyProductTypeIdLessThanOrEqual;
        }

        if (requestParameters.companyProductTypeIdEquals !== undefined) {
            queryParameters['companyProductTypeId.equals'] = requestParameters.companyProductTypeIdEquals;
        }

        if (requestParameters.companyProductTypeIdNotEquals !== undefined) {
            queryParameters['companyProductTypeId.notEquals'] = requestParameters.companyProductTypeIdNotEquals;
        }

        if (requestParameters.companyProductTypeIdSpecified !== undefined) {
            queryParameters['companyProductTypeId.specified'] = requestParameters.companyProductTypeIdSpecified;
        }

        if (requestParameters.companyProductTypeIdIn) {
            queryParameters['companyProductTypeId.in'] = requestParameters.companyProductTypeIdIn;
        }

        if (requestParameters.companyProductTypeIdNotIn) {
            queryParameters['companyProductTypeId.notIn'] = requestParameters.companyProductTypeIdNotIn;
        }

        if (requestParameters.companyPacketWoodQualityIdGreaterThan !== undefined) {
            queryParameters['companyPacketWoodQualityId.greaterThan'] = requestParameters.companyPacketWoodQualityIdGreaterThan;
        }

        if (requestParameters.companyPacketWoodQualityIdLessThan !== undefined) {
            queryParameters['companyPacketWoodQualityId.lessThan'] = requestParameters.companyPacketWoodQualityIdLessThan;
        }

        if (requestParameters.companyPacketWoodQualityIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyPacketWoodQualityId.greaterThanOrEqual'] = requestParameters.companyPacketWoodQualityIdGreaterThanOrEqual;
        }

        if (requestParameters.companyPacketWoodQualityIdLessThanOrEqual !== undefined) {
            queryParameters['companyPacketWoodQualityId.lessThanOrEqual'] = requestParameters.companyPacketWoodQualityIdLessThanOrEqual;
        }

        if (requestParameters.companyPacketWoodQualityIdEquals !== undefined) {
            queryParameters['companyPacketWoodQualityId.equals'] = requestParameters.companyPacketWoodQualityIdEquals;
        }

        if (requestParameters.companyPacketWoodQualityIdNotEquals !== undefined) {
            queryParameters['companyPacketWoodQualityId.notEquals'] = requestParameters.companyPacketWoodQualityIdNotEquals;
        }

        if (requestParameters.companyPacketWoodQualityIdSpecified !== undefined) {
            queryParameters['companyPacketWoodQualityId.specified'] = requestParameters.companyPacketWoodQualityIdSpecified;
        }

        if (requestParameters.companyPacketWoodQualityIdIn) {
            queryParameters['companyPacketWoodQualityId.in'] = requestParameters.companyPacketWoodQualityIdIn;
        }

        if (requestParameters.companyPacketWoodQualityIdNotIn) {
            queryParameters['companyPacketWoodQualityId.notIn'] = requestParameters.companyPacketWoodQualityIdNotIn;
        }

        if (requestParameters.companySurfaceIdGreaterThan !== undefined) {
            queryParameters['companySurfaceId.greaterThan'] = requestParameters.companySurfaceIdGreaterThan;
        }

        if (requestParameters.companySurfaceIdLessThan !== undefined) {
            queryParameters['companySurfaceId.lessThan'] = requestParameters.companySurfaceIdLessThan;
        }

        if (requestParameters.companySurfaceIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurfaceId.greaterThanOrEqual'] = requestParameters.companySurfaceIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurfaceIdLessThanOrEqual !== undefined) {
            queryParameters['companySurfaceId.lessThanOrEqual'] = requestParameters.companySurfaceIdLessThanOrEqual;
        }

        if (requestParameters.companySurfaceIdEquals !== undefined) {
            queryParameters['companySurfaceId.equals'] = requestParameters.companySurfaceIdEquals;
        }

        if (requestParameters.companySurfaceIdNotEquals !== undefined) {
            queryParameters['companySurfaceId.notEquals'] = requestParameters.companySurfaceIdNotEquals;
        }

        if (requestParameters.companySurfaceIdSpecified !== undefined) {
            queryParameters['companySurfaceId.specified'] = requestParameters.companySurfaceIdSpecified;
        }

        if (requestParameters.companySurfaceIdIn) {
            queryParameters['companySurfaceId.in'] = requestParameters.companySurfaceIdIn;
        }

        if (requestParameters.companySurfaceIdNotIn) {
            queryParameters['companySurfaceId.notIn'] = requestParameters.companySurfaceIdNotIn;
        }

        if (requestParameters.loggingIdGreaterThan !== undefined) {
            queryParameters['loggingId.greaterThan'] = requestParameters.loggingIdGreaterThan;
        }

        if (requestParameters.loggingIdLessThan !== undefined) {
            queryParameters['loggingId.lessThan'] = requestParameters.loggingIdLessThan;
        }

        if (requestParameters.loggingIdGreaterThanOrEqual !== undefined) {
            queryParameters['loggingId.greaterThanOrEqual'] = requestParameters.loggingIdGreaterThanOrEqual;
        }

        if (requestParameters.loggingIdLessThanOrEqual !== undefined) {
            queryParameters['loggingId.lessThanOrEqual'] = requestParameters.loggingIdLessThanOrEqual;
        }

        if (requestParameters.loggingIdEquals !== undefined) {
            queryParameters['loggingId.equals'] = requestParameters.loggingIdEquals;
        }

        if (requestParameters.loggingIdNotEquals !== undefined) {
            queryParameters['loggingId.notEquals'] = requestParameters.loggingIdNotEquals;
        }

        if (requestParameters.loggingIdSpecified !== undefined) {
            queryParameters['loggingId.specified'] = requestParameters.loggingIdSpecified;
        }

        if (requestParameters.loggingIdIn) {
            queryParameters['loggingId.in'] = requestParameters.loggingIdIn;
        }

        if (requestParameters.loggingIdNotIn) {
            queryParameters['loggingId.notIn'] = requestParameters.loggingIdNotIn;
        }

        if (requestParameters.userCompanyIdGreaterThan !== undefined) {
            queryParameters['userCompanyId.greaterThan'] = requestParameters.userCompanyIdGreaterThan;
        }

        if (requestParameters.userCompanyIdLessThan !== undefined) {
            queryParameters['userCompanyId.lessThan'] = requestParameters.userCompanyIdLessThan;
        }

        if (requestParameters.userCompanyIdGreaterThanOrEqual !== undefined) {
            queryParameters['userCompanyId.greaterThanOrEqual'] = requestParameters.userCompanyIdGreaterThanOrEqual;
        }

        if (requestParameters.userCompanyIdLessThanOrEqual !== undefined) {
            queryParameters['userCompanyId.lessThanOrEqual'] = requestParameters.userCompanyIdLessThanOrEqual;
        }

        if (requestParameters.userCompanyIdEquals !== undefined) {
            queryParameters['userCompanyId.equals'] = requestParameters.userCompanyIdEquals;
        }

        if (requestParameters.userCompanyIdNotEquals !== undefined) {
            queryParameters['userCompanyId.notEquals'] = requestParameters.userCompanyIdNotEquals;
        }

        if (requestParameters.userCompanyIdSpecified !== undefined) {
            queryParameters['userCompanyId.specified'] = requestParameters.userCompanyIdSpecified;
        }

        if (requestParameters.userCompanyIdIn) {
            queryParameters['userCompanyId.in'] = requestParameters.userCompanyIdIn;
        }

        if (requestParameters.userCompanyIdNotIn) {
            queryParameters['userCompanyId.notIn'] = requestParameters.userCompanyIdNotIn;
        }

        if (requestParameters.userCompanyInviteIdGreaterThan !== undefined) {
            queryParameters['userCompanyInviteId.greaterThan'] = requestParameters.userCompanyInviteIdGreaterThan;
        }

        if (requestParameters.userCompanyInviteIdLessThan !== undefined) {
            queryParameters['userCompanyInviteId.lessThan'] = requestParameters.userCompanyInviteIdLessThan;
        }

        if (requestParameters.userCompanyInviteIdGreaterThanOrEqual !== undefined) {
            queryParameters['userCompanyInviteId.greaterThanOrEqual'] = requestParameters.userCompanyInviteIdGreaterThanOrEqual;
        }

        if (requestParameters.userCompanyInviteIdLessThanOrEqual !== undefined) {
            queryParameters['userCompanyInviteId.lessThanOrEqual'] = requestParameters.userCompanyInviteIdLessThanOrEqual;
        }

        if (requestParameters.userCompanyInviteIdEquals !== undefined) {
            queryParameters['userCompanyInviteId.equals'] = requestParameters.userCompanyInviteIdEquals;
        }

        if (requestParameters.userCompanyInviteIdNotEquals !== undefined) {
            queryParameters['userCompanyInviteId.notEquals'] = requestParameters.userCompanyInviteIdNotEquals;
        }

        if (requestParameters.userCompanyInviteIdSpecified !== undefined) {
            queryParameters['userCompanyInviteId.specified'] = requestParameters.userCompanyInviteIdSpecified;
        }

        if (requestParameters.userCompanyInviteIdIn) {
            queryParameters['userCompanyInviteId.in'] = requestParameters.userCompanyInviteIdIn;
        }

        if (requestParameters.userCompanyInviteIdNotIn) {
            queryParameters['userCompanyInviteId.notIn'] = requestParameters.userCompanyInviteIdNotIn;
        }

        if (requestParameters.forestryIdGreaterThan !== undefined) {
            queryParameters['forestryId.greaterThan'] = requestParameters.forestryIdGreaterThan;
        }

        if (requestParameters.forestryIdLessThan !== undefined) {
            queryParameters['forestryId.lessThan'] = requestParameters.forestryIdLessThan;
        }

        if (requestParameters.forestryIdGreaterThanOrEqual !== undefined) {
            queryParameters['forestryId.greaterThanOrEqual'] = requestParameters.forestryIdGreaterThanOrEqual;
        }

        if (requestParameters.forestryIdLessThanOrEqual !== undefined) {
            queryParameters['forestryId.lessThanOrEqual'] = requestParameters.forestryIdLessThanOrEqual;
        }

        if (requestParameters.forestryIdEquals !== undefined) {
            queryParameters['forestryId.equals'] = requestParameters.forestryIdEquals;
        }

        if (requestParameters.forestryIdNotEquals !== undefined) {
            queryParameters['forestryId.notEquals'] = requestParameters.forestryIdNotEquals;
        }

        if (requestParameters.forestryIdSpecified !== undefined) {
            queryParameters['forestryId.specified'] = requestParameters.forestryIdSpecified;
        }

        if (requestParameters.forestryIdIn) {
            queryParameters['forestryId.in'] = requestParameters.forestryIdIn;
        }

        if (requestParameters.forestryIdNotIn) {
            queryParameters['forestryId.notIn'] = requestParameters.forestryIdNotIn;
        }

        if (requestParameters.sawmillSupplierIdGreaterThan !== undefined) {
            queryParameters['sawmillSupplierId.greaterThan'] = requestParameters.sawmillSupplierIdGreaterThan;
        }

        if (requestParameters.sawmillSupplierIdLessThan !== undefined) {
            queryParameters['sawmillSupplierId.lessThan'] = requestParameters.sawmillSupplierIdLessThan;
        }

        if (requestParameters.sawmillSupplierIdGreaterThanOrEqual !== undefined) {
            queryParameters['sawmillSupplierId.greaterThanOrEqual'] = requestParameters.sawmillSupplierIdGreaterThanOrEqual;
        }

        if (requestParameters.sawmillSupplierIdLessThanOrEqual !== undefined) {
            queryParameters['sawmillSupplierId.lessThanOrEqual'] = requestParameters.sawmillSupplierIdLessThanOrEqual;
        }

        if (requestParameters.sawmillSupplierIdEquals !== undefined) {
            queryParameters['sawmillSupplierId.equals'] = requestParameters.sawmillSupplierIdEquals;
        }

        if (requestParameters.sawmillSupplierIdNotEquals !== undefined) {
            queryParameters['sawmillSupplierId.notEquals'] = requestParameters.sawmillSupplierIdNotEquals;
        }

        if (requestParameters.sawmillSupplierIdSpecified !== undefined) {
            queryParameters['sawmillSupplierId.specified'] = requestParameters.sawmillSupplierIdSpecified;
        }

        if (requestParameters.sawmillSupplierIdIn) {
            queryParameters['sawmillSupplierId.in'] = requestParameters.sawmillSupplierIdIn;
        }

        if (requestParameters.sawmillSupplierIdNotIn) {
            queryParameters['sawmillSupplierId.notIn'] = requestParameters.sawmillSupplierIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFromJSON));
    }

    /**
     */
    async getAllCompanies(requestParameters: GetAllCompaniesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Company>> {
        const response = await this.getAllCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCompanyRaw(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async getCompany(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.getCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanyRaw(requestParameters: PartialUpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompany.');
        }

        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling partialUpdateCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompany(requestParameters: PartialUpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.partialUpdateCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompany.');
        }

        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling updateCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async updateCompany(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.updateCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
