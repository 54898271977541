import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { CompanyPacketWoodQuality } from '@services/apis/generated';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface companyPacketWoodQualityState {
  list: CompanyPacketWoodQuality[];
  completeList: CompanyPacketWoodQuality[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: companyPacketWoodQualityState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const companyPacketWoodQuality = createSlice({
  name: 'companyPacketWoodQualityList',
  initialState,
  reducers: {
    setCompanyPacketWoodQualityList: (state, action) => {
      let companyPacketWoodQuality = { ...state }.list;
      let companyPacketWoodQualityCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        pushElementOrReplaceById(companyPacketWoodQuality, element);
        pushElementOrReplaceById(companyPacketWoodQualityCompleteList, element);
      });

      state.list = companyPacketWoodQuality;
      state.completeList = companyPacketWoodQualityCompleteList;
    },
    setCompanyPacketWoodQualityTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setCompanyPacketWoodQualityListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setCompanyPacketWoodQualityPage: (state, action) => {
      state.page = action.payload;
    },
    resetCompanyPacketWoodQualityList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearCompanyPacketWoodQualityList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
    setCompanyPacketWoodQualityList,
    setCompanyPacketWoodQualityListSort,
    resetCompanyPacketWoodQualityList,
    setCompanyPacketWoodQualityTotalPages,
    setCompanyPacketWoodQualityPage,
    clearCompanyPacketWoodQualityList
} = companyPacketWoodQuality.actions;

export default companyPacketWoodQuality.reducer;