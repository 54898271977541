/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TreeDTO } from './TreeDTO';
import {
    TreeDTOFromJSON,
    TreeDTOFromJSONTyped,
    TreeDTOToJSON,
} from './TreeDTO';

/**
 * 
 * @export
 * @interface EntityWithFileDTOTreeDTO
 */
export interface EntityWithFileDTOTreeDTO {
    /**
     * 
     * @type {TreeDTO}
     * @memberof EntityWithFileDTOTreeDTO
     */
    entity?: TreeDTO;
    /**
     * 
     * @type {string}
     * @memberof EntityWithFileDTOTreeDTO
     */
    base64File?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityWithFileDTOTreeDTO
     */
    deletePrevious?: boolean;
}

/**
 * Check if a given object implements the EntityWithFileDTOTreeDTO interface.
 */
export function instanceOfEntityWithFileDTOTreeDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EntityWithFileDTOTreeDTOFromJSON(json: any): EntityWithFileDTOTreeDTO {
    return EntityWithFileDTOTreeDTOFromJSONTyped(json, false);
}

export function EntityWithFileDTOTreeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityWithFileDTOTreeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': !exists(json, 'entity') ? undefined : TreeDTOFromJSON(json['entity']),
        'base64File': !exists(json, 'base64File') ? undefined : json['base64File'],
        'deletePrevious': !exists(json, 'deletePrevious') ? undefined : json['deletePrevious'],
    };
}

export function EntityWithFileDTOTreeDTOToJSON(value?: EntityWithFileDTOTreeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': TreeDTOToJSON(value.entity),
        'base64File': value.base64File,
        'deletePrevious': value.deletePrevious,
    };
}

