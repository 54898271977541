import { PlatformHelper } from '@helpers/PlatformHelper';
import React, { useEffect } from 'react';
import { ScrollView, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { getMandatorySymbol } from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { UrstammFontFamily, UrstammFontsize } from '../../../utils/styles/UrstammFont';
import { UrstammStyle, UrstammStyleButton } from '../../../utils/styles/UrstammStyle';

export type SlideButtonOption = {
  id: any;
  text: string;
  key?: string;
  active: boolean;
};

export default function UrstammSlideButton(props: {
  textStyle?: StyleProp<TextStyle>;
  testID: string;
  slideButtonList?: SlideButtonOption[];
  buttonPressed?: (element: SlideButtonOption) => void;
  slideButtonRotatedTitle?: string;
  slideButtonTitle?: string;
  mandatory?: boolean;
  disabled?: boolean;
}) {
  const [filterList, setFilterList] = React.useState(props.slideButtonList);

  useEffect(() => {
    setFilterList(props.slideButtonList);
  }, [props.slideButtonList]);

  const selectFilter = (element: SlideButtonOption) => {
    props.buttonPressed ? props.buttonPressed(element) : null;
  };

  const getActiveContainerColor = (filter: SlideButtonOption): StyleProp<ViewStyle> => {
    if (!filter.active) return UrstammStyleButton.buttonContainerFilterInactive;

    return UrstammStyleButton.buttonContainerFilterActive;
  };

  const getActiveTextColor = (filter: SlideButtonOption): StyleProp<TextStyle> => {
    if (!filter.active) return styles.buttonTextFilterInactive;

    return styles.buttonTextFilterActive;
  };

  return (
    <View style={[UrstammStyle.flex1]}>
      {/**Filter title */}
      {props.slideButtonTitle && (
        <View style={UrstammStyle.textTitleContainerFlex}>
          <Text style={[UrstammStyle.textTitle, props.textStyle]}>
            {' '}
            {props.slideButtonTitle + getMandatorySymbol(props.mandatory!)}{' '}
          </Text>
        </View>
      )}

      {props.slideButtonRotatedTitle && <Text style={styles.filterName}> {props.slideButtonRotatedTitle} </Text>}
      <ScrollView
        keyboardShouldPersistTaps="always"
        horizontal={true}
        contentContainerStyle={styles.scrollViewContainer}>
        {filterList &&
          filterList.map((filter, idx) => {
            return (
              <View style={UrstammStyleButton.mainButtonContainer} key={idx}>
                <TouchableOpacity
                  disabled={props.disabled}
                  style={getActiveContainerColor(filter)}
                  onPress={() => selectFilter(filter)}>
                  <Text style={[getActiveTextColor(filter), { alignItems: 'center' }]}>{filter.text}</Text>
                </TouchableOpacity>
              </View>
            );
          })}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  scrollViewContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    minHeight: 16,
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterName: {
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignSelf: 'center',
    // transform: [{ rotate: '90deg' }],
    position: 'relative',
    marginBottom: 3,
    textTransform: 'capitalize',
    fontFamily: UrstammFontFamily.lightFont,
    color: ColorTheme.darkGrey,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  filterTitle: {
    textTransform: 'capitalize',
    fontFamily: UrstammFontFamily.lightFont,
    color: ColorTheme.darkGrey,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  buttonTextFilterActive: {
    color: 'white',
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
    justifyContent: 'center',
    alignSelf: 'center'
  },
  buttonTextFilterInactive: {
    color: ColorTheme.darkGrey,
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
    justifyContent: 'center',
    alignSelf: 'center'
  }
});
