/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeliverySheetPhoto,
} from '../models';
import {
    DeliverySheetPhotoFromJSON,
    DeliverySheetPhotoToJSON,
} from '../models';

export interface CreateDeliverySheetPhotoRequest {
    deliverySheetPhoto: DeliverySheetPhoto;
}

export interface DeleteDeliverySheetPhotoRequest {
    id: number;
}

export interface GetDeliverySheetPhotoRequest {
    id: number;
}

export interface PartialUpdateDeliverySheetPhotoRequest {
    id: number;
    deliverySheetPhoto: DeliverySheetPhoto;
}

export interface UpdateDeliverySheetPhotoRequest {
    id: number;
    deliverySheetPhoto: DeliverySheetPhoto;
}

/**
 * 
 */
export class DeliverySheetPhotoResourceApi extends runtime.BaseAPI {

    /**
     */
    async createDeliverySheetPhotoRaw(requestParameters: CreateDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetPhoto>> {
        if (requestParameters.deliverySheetPhoto === null || requestParameters.deliverySheetPhoto === undefined) {
            throw new runtime.RequiredError('deliverySheetPhoto','Required parameter requestParameters.deliverySheetPhoto was null or undefined when calling createDeliverySheetPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheet-photos`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetPhotoToJSON(requestParameters.deliverySheetPhoto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetPhotoFromJSON(jsonValue));
    }

    /**
     */
    async createDeliverySheetPhoto(requestParameters: CreateDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetPhoto> {
        const response = await this.createDeliverySheetPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteDeliverySheetPhotoRaw(requestParameters: DeleteDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDeliverySheetPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheet-photos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDeliverySheetPhoto(requestParameters: DeleteDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDeliverySheetPhotoRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllDeliverySheetPhotosRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeliverySheetPhoto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheet-photos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliverySheetPhotoFromJSON));
    }

    /**
     */
    async getAllDeliverySheetPhotos(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeliverySheetPhoto>> {
        const response = await this.getAllDeliverySheetPhotosRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getDeliverySheetPhotoRaw(requestParameters: GetDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetPhoto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDeliverySheetPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheet-photos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetPhotoFromJSON(jsonValue));
    }

    /**
     */
    async getDeliverySheetPhoto(requestParameters: GetDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetPhoto> {
        const response = await this.getDeliverySheetPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateDeliverySheetPhotoRaw(requestParameters: PartialUpdateDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetPhoto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateDeliverySheetPhoto.');
        }

        if (requestParameters.deliverySheetPhoto === null || requestParameters.deliverySheetPhoto === undefined) {
            throw new runtime.RequiredError('deliverySheetPhoto','Required parameter requestParameters.deliverySheetPhoto was null or undefined when calling partialUpdateDeliverySheetPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheet-photos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetPhotoToJSON(requestParameters.deliverySheetPhoto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetPhotoFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateDeliverySheetPhoto(requestParameters: PartialUpdateDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetPhoto> {
        const response = await this.partialUpdateDeliverySheetPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateDeliverySheetPhotoRaw(requestParameters: UpdateDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetPhoto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDeliverySheetPhoto.');
        }

        if (requestParameters.deliverySheetPhoto === null || requestParameters.deliverySheetPhoto === undefined) {
            throw new runtime.RequiredError('deliverySheetPhoto','Required parameter requestParameters.deliverySheetPhoto was null or undefined when calling updateDeliverySheetPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheet-photos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetPhotoToJSON(requestParameters.deliverySheetPhoto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetPhotoFromJSON(jsonValue));
    }

    /**
     */
    async updateDeliverySheetPhoto(requestParameters: UpdateDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetPhoto> {
        const response = await this.updateDeliverySheetPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
