/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliverySheet } from './DeliverySheet';
import {
    DeliverySheetFromJSON,
    DeliverySheetFromJSONTyped,
    DeliverySheetToJSON,
} from './DeliverySheet';
import type { Packet } from './Packet';
import {
    PacketFromJSON,
    PacketFromJSONTyped,
    PacketToJSON,
} from './Packet';
import type { Tree } from './Tree';
import {
    TreeFromJSON,
    TreeFromJSONTyped,
    TreeToJSON,
} from './Tree';
import type { TrunkChangeHistory } from './TrunkChangeHistory';
import {
    TrunkChangeHistoryFromJSON,
    TrunkChangeHistoryFromJSONTyped,
    TrunkChangeHistoryToJSON,
} from './TrunkChangeHistory';
import type { TrunkState } from './TrunkState';
import {
    TrunkStateFromJSON,
    TrunkStateFromJSONTyped,
    TrunkStateToJSON,
} from './TrunkState';

/**
 * 
 * @export
 * @interface Trunk
 */
export interface Trunk {
    /**
     * 
     * @type {number}
     * @memberof Trunk
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof Trunk
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Trunk
     */
    trunkNumberFAS?: number;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    quality?: TrunkQualityEnum;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    qualityImported?: string;
    /**
     * 
     * @type {number}
     * @memberof Trunk
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof Trunk
     */
    lengthImported?: number;
    /**
     * 
     * @type {number}
     * @memberof Trunk
     */
    diameter?: number;
    /**
     * 
     * @type {number}
     * @memberof Trunk
     */
    diameterImported?: number;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    species?: TrunkSpeciesEnum;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    speciesImported?: string;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    treePhotoNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    diameterClass?: TrunkDiameterClassEnum;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    diameterClassImported?: string;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    lengthRange?: TrunkLengthRangeEnum;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    lengthRangeImported?: string;
    /**
     * 
     * @type {number}
     * @memberof Trunk
     */
    volume?: number;
    /**
     * 
     * @type {number}
     * @memberof Trunk
     */
    volumeImported?: number;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    dataSource?: TrunkDataSourceEnum;
    /**
     * 
     * @type {number}
     * @memberof Trunk
     */
    barkDiscount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Trunk
     */
    qualityChanged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Trunk
     */
    lengthChanged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Trunk
     */
    diameterChanged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Trunk
     */
    speciesChanged?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    currentState: TrunkCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Trunk
     */
    uuid?: string;
    /**
     * 
     * @type {DeliverySheet}
     * @memberof Trunk
     */
    deliverySheet?: DeliverySheet;
    /**
     * 
     * @type {Tree}
     * @memberof Trunk
     */
    tree?: Tree;
    /**
     * 
     * @type {Set<Packet>}
     * @memberof Trunk
     */
    specificPackets?: Set<Packet>;
    /**
     * 
     * @type {Set<TrunkChangeHistory>}
     * @memberof Trunk
     */
    trunkChangeHistories?: Set<TrunkChangeHistory>;
    /**
     * 
     * @type {Set<TrunkState>}
     * @memberof Trunk
     */
    trunkStates?: Set<TrunkState>;
    /**
     * 
     * @type {Set<Packet>}
     * @memberof Trunk
     */
    packets?: Set<Packet>;
}


/**
 * @export
 */
export const TrunkQualityEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D'
} as const;
export type TrunkQualityEnum = typeof TrunkQualityEnum[keyof typeof TrunkQualityEnum];

/**
 * @export
 */
export const TrunkSpeciesEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type TrunkSpeciesEnum = typeof TrunkSpeciesEnum[keyof typeof TrunkSpeciesEnum];

/**
 * @export
 */
export const TrunkDiameterClassEnum = {
    _0: 'K_0',
    _1A: 'K_1A',
    _1B: 'K_1B',
    _2A: 'K_2A',
    _2B: 'K_2B',
    _3A: 'K_3A',
    _3B: 'K_3B',
    _4A: 'K_4A',
    _4B: 'K_4B',
    _5A: 'K_5A',
    _5B: 'K_5B',
    _6: 'K_6'
} as const;
export type TrunkDiameterClassEnum = typeof TrunkDiameterClassEnum[keyof typeof TrunkDiameterClassEnum];

/**
 * @export
 */
export const TrunkLengthRangeEnum = {
    L0: 'L0',
    L1: 'L1',
    L2: 'L2',
    L3: 'L3'
} as const;
export type TrunkLengthRangeEnum = typeof TrunkLengthRangeEnum[keyof typeof TrunkLengthRangeEnum];

/**
 * @export
 */
export const TrunkDataSourceEnum = {
    Manual: 'MANUAL',
    ErpLehmann: 'ERP_LEHMANN'
} as const;
export type TrunkDataSourceEnum = typeof TrunkDataSourceEnum[keyof typeof TrunkDataSourceEnum];

/**
 * @export
 */
export const TrunkCurrentStateEnum = {
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID'
} as const;
export type TrunkCurrentStateEnum = typeof TrunkCurrentStateEnum[keyof typeof TrunkCurrentStateEnum];


/**
 * Check if a given object implements the Trunk interface.
 */
export function instanceOfTrunk(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "currentState" in value;

    return isInstance;
}

export function TrunkFromJSON(json: any): Trunk {
    return TrunkFromJSONTyped(json, false);
}

export function TrunkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Trunk {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'trunkNumberFAS': !exists(json, 'trunkNumberFAS') ? undefined : json['trunkNumberFAS'],
        'quality': !exists(json, 'quality') ? undefined : json['quality'],
        'qualityImported': !exists(json, 'qualityImported') ? undefined : json['qualityImported'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'lengthImported': !exists(json, 'lengthImported') ? undefined : json['lengthImported'],
        'diameter': !exists(json, 'diameter') ? undefined : json['diameter'],
        'diameterImported': !exists(json, 'diameterImported') ? undefined : json['diameterImported'],
        'species': !exists(json, 'species') ? undefined : json['species'],
        'speciesImported': !exists(json, 'speciesImported') ? undefined : json['speciesImported'],
        'treePhotoNumber': !exists(json, 'treePhotoNumber') ? undefined : json['treePhotoNumber'],
        'diameterClass': !exists(json, 'diameterClass') ? undefined : json['diameterClass'],
        'diameterClassImported': !exists(json, 'diameterClassImported') ? undefined : json['diameterClassImported'],
        'lengthRange': !exists(json, 'lengthRange') ? undefined : json['lengthRange'],
        'lengthRangeImported': !exists(json, 'lengthRangeImported') ? undefined : json['lengthRangeImported'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'volumeImported': !exists(json, 'volumeImported') ? undefined : json['volumeImported'],
        'dataSource': !exists(json, 'dataSource') ? undefined : json['dataSource'],
        'barkDiscount': !exists(json, 'barkDiscount') ? undefined : json['barkDiscount'],
        'qualityChanged': !exists(json, 'qualityChanged') ? undefined : json['qualityChanged'],
        'lengthChanged': !exists(json, 'lengthChanged') ? undefined : json['lengthChanged'],
        'diameterChanged': !exists(json, 'diameterChanged') ? undefined : json['diameterChanged'],
        'speciesChanged': !exists(json, 'speciesChanged') ? undefined : json['speciesChanged'],
        'currentState': json['currentState'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'deliverySheet': !exists(json, 'deliverySheet') ? undefined : DeliverySheetFromJSON(json['deliverySheet']),
        'tree': !exists(json, 'tree') ? undefined : TreeFromJSON(json['tree']),
        'specificPackets': !exists(json, 'specificPackets') ? undefined : (new Set((json['specificPackets'] as Array<any>).map(PacketFromJSON))),
        'trunkChangeHistories': !exists(json, 'trunkChangeHistories') ? undefined : (new Set((json['trunkChangeHistories'] as Array<any>).map(TrunkChangeHistoryFromJSON))),
        'trunkStates': !exists(json, 'trunkStates') ? undefined : (new Set((json['trunkStates'] as Array<any>).map(TrunkStateFromJSON))),
        'packets': !exists(json, 'packets') ? undefined : (new Set((json['packets'] as Array<any>).map(PacketFromJSON))),
    };
}

export function TrunkToJSON(value?: Trunk | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'trunkNumberFAS': value.trunkNumberFAS,
        'quality': value.quality,
        'qualityImported': value.qualityImported,
        'length': value.length,
        'lengthImported': value.lengthImported,
        'diameter': value.diameter,
        'diameterImported': value.diameterImported,
        'species': value.species,
        'speciesImported': value.speciesImported,
        'treePhotoNumber': value.treePhotoNumber,
        'diameterClass': value.diameterClass,
        'diameterClassImported': value.diameterClassImported,
        'lengthRange': value.lengthRange,
        'lengthRangeImported': value.lengthRangeImported,
        'volume': value.volume,
        'volumeImported': value.volumeImported,
        'dataSource': value.dataSource,
        'barkDiscount': value.barkDiscount,
        'qualityChanged': value.qualityChanged,
        'lengthChanged': value.lengthChanged,
        'diameterChanged': value.diameterChanged,
        'speciesChanged': value.speciesChanged,
        'currentState': value.currentState,
        'uuid': value.uuid,
        'deliverySheet': DeliverySheetToJSON(value.deliverySheet),
        'tree': TreeToJSON(value.tree),
        'specificPackets': value.specificPackets === undefined ? undefined : (Array.from(value.specificPackets as Set<any>).map(PacketToJSON)),
        'trunkChangeHistories': value.trunkChangeHistories === undefined ? undefined : (Array.from(value.trunkChangeHistories as Set<any>).map(TrunkChangeHistoryToJSON)),
        'trunkStates': value.trunkStates === undefined ? undefined : (Array.from(value.trunkStates as Set<any>).map(TrunkStateToJSON)),
        'packets': value.packets === undefined ? undefined : (Array.from(value.packets as Set<any>).map(PacketToJSON)),
    };
}

