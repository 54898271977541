/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DoubleFilter } from './DoubleFilter';
import {
    DoubleFilterFromJSON,
    DoubleFilterFromJSONTyped,
    DoubleFilterToJSON,
} from './DoubleFilter';
import type { InstantFilter } from './InstantFilter';
import {
    InstantFilterFromJSON,
    InstantFilterFromJSONTyped,
    InstantFilterToJSON,
} from './InstantFilter';
import type { LongFilter } from './LongFilter';
import {
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
} from './LongFilter';
import type { ProcessorBatchStateEnumFilter } from './ProcessorBatchStateEnumFilter';
import {
    ProcessorBatchStateEnumFilterFromJSON,
    ProcessorBatchStateEnumFilterFromJSONTyped,
    ProcessorBatchStateEnumFilterToJSON,
} from './ProcessorBatchStateEnumFilter';
import type { RecordingTypeEnumFilter } from './RecordingTypeEnumFilter';
import {
    RecordingTypeEnumFilterFromJSON,
    RecordingTypeEnumFilterFromJSONTyped,
    RecordingTypeEnumFilterToJSON,
} from './RecordingTypeEnumFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface CustomProcessorBatchCriteria
 */
export interface CustomProcessorBatchCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    uniqueIdentifier?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    comment?: StringFilter;
    /**
     * 
     * @type {InstantFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    creationDate?: InstantFilter;
    /**
     * 
     * @type {RecordingTypeEnumFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    recordingType?: RecordingTypeEnumFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    sumCubage?: DoubleFilter;
    /**
     * 
     * @type {ProcessorBatchStateEnumFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    currentState?: ProcessorBatchStateEnumFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    uuid?: StringFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    processorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    referenceBatchId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    sourceProductId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    processorBatchStateId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    ownerShippingNoteId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorBatchCriteria
     */
    productId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomProcessorBatchCriteria
     */
    distinct?: boolean;
}

/**
 * Check if a given object implements the CustomProcessorBatchCriteria interface.
 */
export function instanceOfCustomProcessorBatchCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomProcessorBatchCriteriaFromJSON(json: any): CustomProcessorBatchCriteria {
    return CustomProcessorBatchCriteriaFromJSONTyped(json, false);
}

export function CustomProcessorBatchCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomProcessorBatchCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : StringFilterFromJSON(json['name']),
        'uniqueIdentifier': !exists(json, 'uniqueIdentifier') ? undefined : StringFilterFromJSON(json['uniqueIdentifier']),
        'comment': !exists(json, 'comment') ? undefined : StringFilterFromJSON(json['comment']),
        'creationDate': !exists(json, 'creationDate') ? undefined : InstantFilterFromJSON(json['creationDate']),
        'recordingType': !exists(json, 'recordingType') ? undefined : RecordingTypeEnumFilterFromJSON(json['recordingType']),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : DoubleFilterFromJSON(json['sumCubage']),
        'currentState': !exists(json, 'currentState') ? undefined : ProcessorBatchStateEnumFilterFromJSON(json['currentState']),
        'uuid': !exists(json, 'uuid') ? undefined : StringFilterFromJSON(json['uuid']),
        'processorId': !exists(json, 'processorId') ? undefined : LongFilterFromJSON(json['processorId']),
        'referenceBatchId': !exists(json, 'referenceBatchId') ? undefined : LongFilterFromJSON(json['referenceBatchId']),
        'sourceProductId': !exists(json, 'sourceProductId') ? undefined : LongFilterFromJSON(json['sourceProductId']),
        'processorBatchStateId': !exists(json, 'processorBatchStateId') ? undefined : LongFilterFromJSON(json['processorBatchStateId']),
        'ownerShippingNoteId': !exists(json, 'ownerShippingNoteId') ? undefined : LongFilterFromJSON(json['ownerShippingNoteId']),
        'productId': !exists(json, 'productId') ? undefined : LongFilterFromJSON(json['productId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CustomProcessorBatchCriteriaToJSON(value?: CustomProcessorBatchCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'name': StringFilterToJSON(value.name),
        'uniqueIdentifier': StringFilterToJSON(value.uniqueIdentifier),
        'comment': StringFilterToJSON(value.comment),
        'creationDate': InstantFilterToJSON(value.creationDate),
        'recordingType': RecordingTypeEnumFilterToJSON(value.recordingType),
        'sumCubage': DoubleFilterToJSON(value.sumCubage),
        'currentState': ProcessorBatchStateEnumFilterToJSON(value.currentState),
        'uuid': StringFilterToJSON(value.uuid),
        'processorId': LongFilterToJSON(value.processorId),
        'referenceBatchId': LongFilterToJSON(value.referenceBatchId),
        'sourceProductId': LongFilterToJSON(value.sourceProductId),
        'processorBatchStateId': LongFilterToJSON(value.processorBatchStateId),
        'ownerShippingNoteId': LongFilterToJSON(value.ownerShippingNoteId),
        'productId': LongFilterToJSON(value.productId),
        'distinct': value.distinct,
    };
}

