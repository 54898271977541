/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BatchStateEnumFilter } from './BatchStateEnumFilter';
import {
    BatchStateEnumFilterFromJSON,
    BatchStateEnumFilterFromJSONTyped,
    BatchStateEnumFilterToJSON,
} from './BatchStateEnumFilter';
import type { DoubleFilter } from './DoubleFilter';
import {
    DoubleFilterFromJSON,
    DoubleFilterFromJSONTyped,
    DoubleFilterToJSON,
} from './DoubleFilter';
import type { InstantFilter } from './InstantFilter';
import {
    InstantFilterFromJSON,
    InstantFilterFromJSONTyped,
    InstantFilterToJSON,
} from './InstantFilter';
import type { LongFilter } from './LongFilter';
import {
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
} from './LongFilter';
import type { RecordingTypeEnumFilter } from './RecordingTypeEnumFilter';
import {
    RecordingTypeEnumFilterFromJSON,
    RecordingTypeEnumFilterFromJSONTyped,
    RecordingTypeEnumFilterToJSON,
} from './RecordingTypeEnumFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface CustomBatchCriteria
 */
export interface CustomBatchCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomBatchCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomBatchCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomBatchCriteria
     */
    uniqueIdentifier?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomBatchCriteria
     */
    comment?: StringFilter;
    /**
     * 
     * @type {InstantFilter}
     * @memberof CustomBatchCriteria
     */
    creationDate?: InstantFilter;
    /**
     * 
     * @type {RecordingTypeEnumFilter}
     * @memberof CustomBatchCriteria
     */
    recordingType?: RecordingTypeEnumFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomBatchCriteria
     */
    sumCubage?: DoubleFilter;
    /**
     * 
     * @type {BatchStateEnumFilter}
     * @memberof CustomBatchCriteria
     */
    currentState?: BatchStateEnumFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomBatchCriteria
     */
    uuid?: StringFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomBatchCriteria
     */
    sawmillId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomBatchCriteria
     */
    deliverySheetId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomBatchCriteria
     */
    batchStateId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomBatchCriteria
     */
    packetId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomBatchCriteria
     */
    shippingNoteId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomBatchCriteria
     */
    distinct?: boolean;
}

/**
 * Check if a given object implements the CustomBatchCriteria interface.
 */
export function instanceOfCustomBatchCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomBatchCriteriaFromJSON(json: any): CustomBatchCriteria {
    return CustomBatchCriteriaFromJSONTyped(json, false);
}

export function CustomBatchCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomBatchCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : StringFilterFromJSON(json['name']),
        'uniqueIdentifier': !exists(json, 'uniqueIdentifier') ? undefined : StringFilterFromJSON(json['uniqueIdentifier']),
        'comment': !exists(json, 'comment') ? undefined : StringFilterFromJSON(json['comment']),
        'creationDate': !exists(json, 'creationDate') ? undefined : InstantFilterFromJSON(json['creationDate']),
        'recordingType': !exists(json, 'recordingType') ? undefined : RecordingTypeEnumFilterFromJSON(json['recordingType']),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : DoubleFilterFromJSON(json['sumCubage']),
        'currentState': !exists(json, 'currentState') ? undefined : BatchStateEnumFilterFromJSON(json['currentState']),
        'uuid': !exists(json, 'uuid') ? undefined : StringFilterFromJSON(json['uuid']),
        'sawmillId': !exists(json, 'sawmillId') ? undefined : LongFilterFromJSON(json['sawmillId']),
        'deliverySheetId': !exists(json, 'deliverySheetId') ? undefined : LongFilterFromJSON(json['deliverySheetId']),
        'batchStateId': !exists(json, 'batchStateId') ? undefined : LongFilterFromJSON(json['batchStateId']),
        'packetId': !exists(json, 'packetId') ? undefined : LongFilterFromJSON(json['packetId']),
        'shippingNoteId': !exists(json, 'shippingNoteId') ? undefined : LongFilterFromJSON(json['shippingNoteId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CustomBatchCriteriaToJSON(value?: CustomBatchCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'name': StringFilterToJSON(value.name),
        'uniqueIdentifier': StringFilterToJSON(value.uniqueIdentifier),
        'comment': StringFilterToJSON(value.comment),
        'creationDate': InstantFilterToJSON(value.creationDate),
        'recordingType': RecordingTypeEnumFilterToJSON(value.recordingType),
        'sumCubage': DoubleFilterToJSON(value.sumCubage),
        'currentState': BatchStateEnumFilterToJSON(value.currentState),
        'uuid': StringFilterToJSON(value.uuid),
        'sawmillId': LongFilterToJSON(value.sawmillId),
        'deliverySheetId': LongFilterToJSON(value.deliverySheetId),
        'batchStateId': LongFilterToJSON(value.batchStateId),
        'packetId': LongFilterToJSON(value.packetId),
        'shippingNoteId': LongFilterToJSON(value.shippingNoteId),
        'distinct': value.distinct,
    };
}

