/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliverySheetErpImportation } from './DeliverySheetErpImportation';
import {
    DeliverySheetErpImportationFromJSON,
    DeliverySheetErpImportationFromJSONTyped,
    DeliverySheetErpImportationToJSON,
} from './DeliverySheetErpImportation';

/**
 * 
 * @export
 * @interface EntityWithFileDTODeliverySheetErpImportation
 */
export interface EntityWithFileDTODeliverySheetErpImportation {
    /**
     * 
     * @type {DeliverySheetErpImportation}
     * @memberof EntityWithFileDTODeliverySheetErpImportation
     */
    entity?: DeliverySheetErpImportation;
    /**
     * 
     * @type {string}
     * @memberof EntityWithFileDTODeliverySheetErpImportation
     */
    base64File?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityWithFileDTODeliverySheetErpImportation
     */
    deletePrevious?: boolean;
}

/**
 * Check if a given object implements the EntityWithFileDTODeliverySheetErpImportation interface.
 */
export function instanceOfEntityWithFileDTODeliverySheetErpImportation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EntityWithFileDTODeliverySheetErpImportationFromJSON(json: any): EntityWithFileDTODeliverySheetErpImportation {
    return EntityWithFileDTODeliverySheetErpImportationFromJSONTyped(json, false);
}

export function EntityWithFileDTODeliverySheetErpImportationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityWithFileDTODeliverySheetErpImportation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': !exists(json, 'entity') ? undefined : DeliverySheetErpImportationFromJSON(json['entity']),
        'base64File': !exists(json, 'base64File') ? undefined : json['base64File'],
        'deletePrevious': !exists(json, 'deletePrevious') ? undefined : json['deletePrevious'],
    };
}

export function EntityWithFileDTODeliverySheetErpImportationToJSON(value?: EntityWithFileDTODeliverySheetErpImportation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': DeliverySheetErpImportationToJSON(value.entity),
        'base64File': value.base64File,
        'deletePrevious': value.deletePrevious,
    };
}

