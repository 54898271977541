import { DateHelper } from '@helpers/DateHelper';
import { NetworkHelper } from '@helpers/NetworkHelper';
import { setOtherAssortmentList } from '@redux/features/otherAssortment/otherAssortmentSlice';
import { OfflineSyncClassEnum, OfflineSyncTypeEnum } from '@redux/features/offlineSync/offlineSyncSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Dispatch, AnyAction } from 'redux';
import { customOtherAssortmentResourceApi } from './ApiConfiguration';
import {
  CreateOtherAssortmentCustomRequest,
  GetOtherAssortmentsByPileRequest,
  OtherAssortment,
  UpdateOtherAssortmentCustomRequest
} from './generated';
import { sortOfflineList } from '../../utils/classes/UrstammUtilityFunctions';

export class FullAvailableOtherAssortmentResourceApi {
  /**
   * Get all otherAssortment list for pile
   * @param rdxOtherAssortmentCompleteList Redux list of Other Assortment
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns Updated list of otherAssortments by pile or redux list if network is not reachable
   */
  static async getOtherAssortmentsByPile(
    rdxOtherAssortmentCompleteList: OtherAssortment[],
    requestParameters: GetOtherAssortmentsByPileRequest,
    initOverrides?: RequestInit
  ): Promise<Array<OtherAssortment>> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk) return customOtherAssortmentResourceApi.getOtherAssortmentsByPile(requestParameters, initOverrides);
    let otherAssortmentList =  rdxOtherAssortmentCompleteList.filter(
      otherAssortment => otherAssortment.pile?.id == requestParameters.pileId
    );
    return sortOfflineList(otherAssortmentList, requestParameters)
  }

  /**
   * Create a OtherAssortment
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async createOtherAssortmentCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: CreateOtherAssortmentCustomRequest,
    initOverrides?: RequestInit
  ): Promise<OtherAssortment> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customOtherAssortmentResourceApi.createOtherAssortmentCustom(requestParameters, initOverrides);
    requestParameters.otherAssortment.id = DateHelper.nowTimestamp();
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.otherAssortment,
        type: OfflineSyncTypeEnum.INSERT,
        class: OfflineSyncClassEnum.OTHERASSORTMENT,
        name: requestParameters.otherAssortment.pile?.name
      })
    );
    dispatch(setOtherAssortmentList([requestParameters.otherAssortment]));
    return requestParameters.otherAssortment;
  }

  /**
   * Update a OtherAssortment
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async updateOtherAssortmentCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: UpdateOtherAssortmentCustomRequest,
    initOverrides?: RequestInit
  ): Promise<OtherAssortment> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customOtherAssortmentResourceApi.updateOtherAssortmentCustom(requestParameters, initOverrides);
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.otherAssortment,
        type: OfflineSyncTypeEnum.UPDATE,
        class: OfflineSyncClassEnum.OTHERASSORTMENT,
        name: requestParameters.otherAssortment.pile?.name
      })
    );
    dispatch(setOtherAssortmentList([requestParameters.otherAssortment]));
    return requestParameters.otherAssortment;
  }
}
