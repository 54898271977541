/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadMyAvatarRequest
 */
export interface UploadMyAvatarRequest {
    /**
     * 
     * @type {Blob}
     * @memberof UploadMyAvatarRequest
     */
    avatar: Blob;
}

/**
 * Check if a given object implements the UploadMyAvatarRequest interface.
 */
export function instanceOfUploadMyAvatarRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "avatar" in value;

    return isInstance;
}

export function UploadMyAvatarRequestFromJSON(json: any): UploadMyAvatarRequest {
    return UploadMyAvatarRequestFromJSONTyped(json, false);
}

export function UploadMyAvatarRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadMyAvatarRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatar': json['avatar'],
    };
}

export function UploadMyAvatarRequestToJSON(value?: UploadMyAvatarRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar': value.avatar,
    };
}

