import { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { createSlice } from '@reduxjs/toolkit';

export interface ProjectFilterState {
    value: SlideButtonOption[];
}

const initialState: ProjectFilterState = {
    value: []
};

export const projectFilter = createSlice({
    name: 'projectFilter',
    initialState,
    reducers: {
        setProjectFilter: (state, action) => {
            state.value = action.payload;
        },
        setProjectFilterList: (state, action) => {
            state.value = action.payload;
        },
        selectProjectFilter: (state, action) => {
            let selected = action.payload;
            let filterList = { ...state }.value;
            if (filterList) {
                for (let elem of filterList) {
                    elem.active = elem.id == selected.id;
                }
                state.value = filterList;
            }
        },
        reset: state => {
            initialState;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setProjectFilter, setProjectFilterList, selectProjectFilter, reset } = projectFilter.actions;

export default projectFilter.reducer;
