/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyToJSON
} from './Company';
import type { LoggingState } from './LoggingState';
import {
    LoggingStateFromJSON,
    LoggingStateToJSON
} from './LoggingState';
import type { Pile } from './Pile';
import {
    PileFromJSON,
    PileToJSON
} from './Pile';
import type { Plot } from './Plot';
import {
    PlotFromJSON,
    PlotToJSON
} from './Plot';
import type { Tree } from './Tree';
import {
    TreeFromJSON,
    TreeToJSON
} from './Tree';
import type { User } from './User';
import {
    UserFromJSON,
    UserToJSON
} from './User';

/**
 * @export
 * @interface LoggingPhoto
 */
export interface LoggingPhoto {
    /**
     * 
     * @type {number}
     * @memberof LoggingPhoto
     */
    id?: number;
    /** 
     * 
     * @type {number}
     * @memberof LoggingPhoto
     */
    latitude?: number,
    /**
     * 
     * @type {number}
     * @memberof LoggingPhoto
     */
    longitude?: number,
    /**
     * 
     * @type {string}
     * @memberof LoggingPhoto
     */
    photoFilename?: string,
    /**
     * 
     * @type {string}
     * @memberof LoggingPhoto
     */
    photoFilepath?: string,
    /**
     * 
     * @type {string}
     * @memberof LoggingPhoto
     */
    photoUrl: string,
    /**
     * 
     * @type {string}
     * @memberof LoggingPhoto
     */
    photoMd5?: string,
    /**
     * 
     * @type {string}
     * @memberof LoggingPhoto
     */
    photoCreationDate?: string,
    /**
     * @type null
     * @memberof LoggingPhoto
     */
    logging?: null
}

/**
 * 
 * @export
 * @interface Logging
 */
export interface Logging {
    /**
     * 
     * @type {number}
     * @memberof Logging
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Logging
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Logging
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof Logging
     */
    creationDate?: number;
    /**
     * 
     * @type {number}
     * @memberof Logging
     */
    sumCubage?: number;
    /**
     * 
     * @type {number}
     * @memberof Logging
     */
    sumWoodOtherAssortment?: number;
    /**
     * 
     * @type {number}
     * @memberof Logging
     */
    sumWoodTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof Logging
     */
    estimatedVolume?: number;
    /**
     * 
     * @type {string}
     * @memberof Logging
     */
    currentState?: LoggingCurrentStateEnum;
    /**
     * 
     * @type { LoggingPhoto }
     * @memberof LoggingPhoto
     */
    loggingPhoto?: LoggingPhoto
    /**
     * 
     * @type {string}
     * @memberof Logging
     */
    permitMediaType?: LoggingPermitMediaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Logging
     */
    permitFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof Logging
     */
    permitFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof Logging
     */
    permitUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Logging
     */
    permitMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof Logging
     */
    uuid?: string;
    /**
     * 
     * @type {Company}
     * @memberof Logging
     */
    company?: Company;
    /**
     * 
     * @type {User}
     * @memberof Logging
     */
    permitAuthor?: User;
    /**
     * 
     * @type {Set<LoggingState>}
     * @memberof Logging
     */
    loggingStates?: Set<LoggingState>;
    /**
     * 
     * @type {Set<Pile>}
     * @memberof Logging
     */
    piles?: Set<Pile>;
    /**
     * 
     * @type {Set<Plot>}
     * @memberof Logging
     */
    plots?: Set<Plot>;
    /**
     * 
     * @type {Set<Tree>}
     * @memberof Logging
     */
    trees?: Set<Tree>;
    /**
     *
     * @type {Date}
     * @memberof Logging
     */
    photoCreationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Logging
     */
    numberOfTrees?: number;
    /**
     * 
     * @type {number}
     * @memberof Logging
     */
    numberOfPiles?: number;
    /**
     * 
     * @type {number}
     * @memberof Logging
     */
    numberOfTrunks?: number;
    /**
     * 
     * @type {number}
     * @memberof Logging
     */
    numberOfDeliverySheets?: number;
}


/**
 * @export
 */
export const LoggingCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type LoggingCurrentStateEnum = typeof LoggingCurrentStateEnum[keyof typeof LoggingCurrentStateEnum];

/**
 * @export
 */
export const LoggingPermitMediaTypeEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type LoggingPermitMediaTypeEnum = typeof LoggingPermitMediaTypeEnum[keyof typeof LoggingPermitMediaTypeEnum];


/**
 * Check if a given object implements the Logging interface.
 */
export function instanceOfLogging(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "currentState" in value;

    return isInstance;
}

export function LoggingFromJSON(json: any): Logging {
    return LoggingFromJSONTyped(json, false);
}

export function LoggingPhotoFromJSON(json: any): any {
    return LoggingPhotoFromJSONTyped(json, false);
}

export function LoggingPhotoFromJSONTyped(json: any, ignoreDiscriminator: boolean): any {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'photoFilename': !exists(json, 'photoFilename') ? undefined : json['photoFilename'],
        'photoFilepath': !exists(json, 'photoFilePath') ? undefined : json['photoFilePath'],
        'photoUrl': !exists(json, 'photoUrl') ? undefined : json['photoUrl'],
        'photoMd5': !exists(json, 'photoMd5') ? undefined : json['photoMd5'],
        'photoCreationDate': !exists(json, 'photoCreationDate') ? undefined : json['photoCreationDate'],
    };
}

export function LoggingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Logging {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'sumWoodOtherAssortment': !exists(json, 'sumWoodOtherAssortment') ? undefined : json['sumWoodOtherAssortment'],
        'sumWoodTotal': !exists(json, 'sumWoodTotal') ? undefined : json['sumWoodTotal'],
        'estimatedVolume': !exists(json, 'estimatedVolume') ? undefined : json['estimatedVolume'],
        'currentState': json['currentState'],
        'permitMediaType': !exists(json, 'permitMediaType') ? undefined : json['permitMediaType'],
        'permitFilename': !exists(json, 'permitFilename') ? undefined : json['permitFilename'],
        'permitFilepath': !exists(json, 'permitFilepath') ? undefined : json['permitFilepath'],
        'permitUrl': !exists(json, 'permitUrl') ? undefined : json['permitUrl'],
        'permitMd5': !exists(json, 'permitMd5') ? undefined : json['permitMd5'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'permitAuthor': !exists(json, 'permitAuthor') ? undefined : UserFromJSON(json['permitAuthor']),
        'loggingStates': !exists(json, 'loggingStates') ? undefined : (new Set((json['loggingStates'] as Array<any>).map(LoggingStateFromJSON))),
        'loggingPhoto': !exists(json, 'loggingPhoto') ? undefined : LoggingPhotoFromJSON(json['loggingPhoto']),
        'piles': !exists(json, 'piles') ? undefined : (new Set((json['piles'] as Array<any>).map(PileFromJSON))),
        'plots': !exists(json, 'plots') ? undefined : (new Set((json['plots'] as Array<any>).map(PlotFromJSON))),
        'trees': !exists(json, 'trees') ? undefined : (new Set((json['trees'] as Array<any>).map(TreeFromJSON))),
        'numberOfPiles': !exists(json, 'numberOfPiles') ? undefined : json['numberOfPiles'],
        'numberOfDeliverySheets': !exists(json, 'numberOfDeliverySheets') ? undefined : json['numberOfDeliverySheets'],
        'numberOfTrees': !exists(json, 'numberOfTrees') ? undefined : json['numberOfTrees'],
    };
}

export function LoggingToJSON(value?: Logging | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'sumCubage': value.sumCubage,
        'sumWoodOtherAssortment': value.sumWoodOtherAssortment,
        'sumWoodTotal': value.sumWoodTotal,
        'estimatedVolume': value.estimatedVolume,
        'currentState': value.currentState,
        'permitMediaType': value.permitMediaType,
        'permitFilename': value.permitFilename,
        'permitFilepath': value.permitFilepath,
        'permitUrl': value.permitUrl,
        'permitMd5': value.permitMd5,
        'loggingPhoto': {
            'latitude': value.loggingPhoto?.latitude,
            'longitude': value.loggingPhoto?.longitude,
            'photoCreationDate': value.loggingPhoto?.photoCreationDate,
            'id': value.loggingPhoto?.id,
            'photoFilename': value.loggingPhoto?.photoFilename,
            'photoFilepath': value.loggingPhoto?.photoFilepath,
            'photoUrl': value.loggingPhoto?.photoUrl,
            'photoMd5': value.loggingPhoto?.photoMd5
        },
        'uuid': value.uuid,
        'company': CompanyToJSON(value.company),
        'permitAuthor': UserToJSON(value.permitAuthor),
        'loggingStates': value.loggingStates === undefined ? undefined : (Array.from(value.loggingStates as Set<any>).map(LoggingStateToJSON)),
        'piles': value.piles === undefined ? undefined : (Array.from(value.piles as Set<any>).map(PileToJSON)),
        'plots': value.plots === undefined ? undefined : (Array.from(value.plots as Set<any>).map(PlotToJSON)),
        'trees': value.trees === undefined ? undefined : (Array.from(value.trees as Set<any>).map(TreeToJSON)),
        'photoCreationDate': value.photoCreationDate === undefined ? undefined : value.photoCreationDate,
    };
}

