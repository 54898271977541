import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSortList from '@components/utility-components/modal/UrstammModalSortList';
import { i18n } from '@i18n/i18n';
import { selectProcessorBatchFilter, setProcessorBatchFilterList } from '@redux/features/filter/processorBatchFilterSlice';
import { ProcessorBatch, ProcessorBatchCurrentStateEnum } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
  getColorByProcessorBatchState,
  getIconByProcessorBatchState,
  getTextByProcessorBatchState,
  getTextByProcessorBatchType
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterProcessorBatchByNameOrUniqueIdentifier } from '../../../utils/classes/UrstammUtilityFilter';
import { getCardTitleByProcessorBatchOrProduct } from '../../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyle, UrstammStyleList } from '../../../utils/styles/UrstammStyle';

export default function ProcessorBatchListView(props: {
  navigation: any;
  processorBatchList: ProcessorBatch[];
  moreItems?: () => void;
  modalVisible: boolean;
  processorBatchSelected?: (processorBatch: ProcessorBatch) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
}) {
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const processorBatchFilter = useSelector((state: RootState) => state.persistedReducer.processorBatchFilter.value);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.processorBatch.sortBy);
  const dispatch = useDispatch();

  const [processorBatchSelected, setProcessorBatchSelected] = useState<ProcessorBatch>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  let keys: ListSubData[] = [
    {
      title: undefined,
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'currentState',
      keyId: '1currentState',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.recording_type') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'recordingType',
      keyId: '1recordingType',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' },
      isEnum: true
    },
    {
      title: i18n.t('views.processor_batch.sum_volume_products') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      dataContainerViewStyle: { height: 24, flexBasis: '100%' }
    }
    //{ title: i18n.t('generics.delivery_sheet') + ':', titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'deliverySheets', keyId: '1deliverySheets', dataContainerViewStyle: { height: 24, flexBasis: '50%' }, isEnum: true},
  ];

  useEffect(() => {
    if (props.modalVisible) {
      setModalVisible(props.modalVisible);
    }
    return () => {};
  }, [props.modalVisible]);

  useEffect(() => {
    getSlideButtonOptionsProcessorBatchCurrentStates();
  }, []);

  /**
   * Function used for generating filter buttons
   */
  const getSlideButtonOptionsProcessorBatchCurrentStates = (): SlideButtonOption[] => {
    let filterList: SlideButtonOption[] = [];
    let type: SlideButtonOption = {
      id: 'ALL',
      text: i18n.t('utility_components.filter_button.all'),
      key: 'currentUrstammState',
      active: true
    };
    filterList.push(type);
    Object.values(ProcessorBatchCurrentStateEnum).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByProcessorBatchState(state)!,
        key: 'currentState',
        active: false
      };
      filterList.push(type);
    });
    dispatch(setProcessorBatchFilterList(filterList));

    return filterList;
  };

  const setProcessorBatchCurrentState = (selected: SlideButtonOption): void => {
    dispatch(selectProcessorBatchFilter(selected));
  };

  const getActiveProcessorBatchCurrentState = (): SlideButtonOption[] => {
    return processorBatchFilter.filter(val => val.active);
  };

  return (
    <View style={[UrstammStyle.flex1]}>
      {props.processorBatchList && props.processorBatchList.length > 0 ? (
        <View style={[{ flex: 0.15 }]}>
          <UrstammSlideButton
            testID={'button_status_filter'}
            slideButtonList={processorBatchFilter}
            slideButtonRotatedTitle={i18n.t('utility_components.filter_button.status')}
            buttonPressed={(selected: SlideButtonOption) => setProcessorBatchCurrentState(selected)}
          />
        </View>
      ) : null}

      <View style={[{ flex: 0.85 }]}>
        <UrstammList
          elementSelected={(data: any) => {
            setProcessorBatchSelected(data.item), props.processorBatchSelected ? props.processorBatchSelected(data.item) : null;
          }}
          showHash={false}
          testID={'processorBatch_list'}
          list={props.processorBatchList}
          textForEmptyList={i18n.t('views.processor_batch.processor_batch_not_found')}
          titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
          filterBy={getActiveProcessorBatchCurrentState()}
          filterList={true}
          filterListFunction={(text, data) => filterProcessorBatchByNameOrUniqueIdentifier(text, data, rdxUserExtendedMe)}
          listStyle={{
            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
            bottomContainerViewStyle: UrstammStyleList.cardSubData,
            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
          }}
          moreItems={props.moreItems}
          listSubData={keys}
          currentState={{
            icon: state => getIconByProcessorBatchState(state),
            color: state => getColorByProcessorBatchState(state),
            text: state => getTextByProcessorBatchState(state)!
          }}
          getTextForData={(data, key) =>
            key == 'recordingType' ? getTextByProcessorBatchType(data) : key == 'deliverySheets' ? console.log(data) : null
          }
        />
        {props.showSortBy ? (
          <UrstammModalSortList
            testID={'modal_sort'}
            showSortBy={props.showSortBy}
            closeShowSortBy={props.closeShowSortBy}
            applyOrder={(orderSelected: ButtonSelect[]) => props.applyOrder(orderSelected)}
            sortBySelected={rdxSortBy}
          />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
