/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { PlatformHelper } from '@helpers/PlatformHelper';
import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { RootStackParamList } from './UrstammNavigationHelper';

const linking: LinkingOptions<RootStackParamList> | undefined = PlatformHelper.isWeb()
  ? {
      prefixes: [Linking.createURL('/')],
      config: {
        screens: {
          Login: 'Login',
          ChangePassword: 'ChangePassword',
          ResetPassword: 'ResetPassword'
        }
      }
    }
  : undefined;

export default linking;
