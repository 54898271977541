/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OtherAssortment,
} from '../models';
import {
    OtherAssortmentFromJSON,
    OtherAssortmentToJSON,
} from '../models';

export interface CreateOtherAssortmentCustomRequest {
    otherAssortment: OtherAssortment;
}

export interface GetOtherAssortmentCustomRequest {
    id: number;
}

export interface GetOtherAssortmentsByPileRequest {
    pileId: number;
}

export interface UpdateOtherAssortmentCustomRequest {
    id: number;
    otherAssortment: OtherAssortment;
}

/**
 * 
 */
export class CustomOtherAssortmentResourceApi extends runtime.BaseAPI {

    /**
     * Create a new OtherAssortment connecting to given Pile.
     * Create a OtherAssortment
     */
    async createOtherAssortmentCustomRaw(requestParameters: CreateOtherAssortmentCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OtherAssortment>> {
        if (requestParameters.otherAssortment === null || requestParameters.otherAssortment === undefined) {
            throw new runtime.RequiredError('otherAssortment','Required parameter requestParameters.otherAssortment was null or undefined when calling createOtherAssortmentCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/other-assortments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OtherAssortmentToJSON(requestParameters.otherAssortment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OtherAssortmentFromJSON(jsonValue));
    }

    /**
     * Create a new OtherAssortment connecting to given Pile.
     * Create a OtherAssortment
     */
    async createOtherAssortmentCustom(requestParameters: CreateOtherAssortmentCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OtherAssortment> {
        const response = await this.createOtherAssortmentCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all OtherAssortments for Company.
     * Get a list of all OtherAssortments for Company
     */
    async getAllMyOtherAssortmentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OtherAssortment>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/other-assortments/my/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OtherAssortmentFromJSON));
    }

    /**
     * Get a list of all OtherAssortments for Company.
     * Get a list of all OtherAssortments for Company
     */
    async getAllMyOtherAssortments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OtherAssortment>> {
        const response = await this.getAllMyOtherAssortmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get OtherAssortment identified by given id (must belong to Company of logged user).
     * Get a specific OtherAssortment
     */
    async getOtherAssortmentCustomRaw(requestParameters: GetOtherAssortmentCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OtherAssortment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOtherAssortmentCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/other-assortments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OtherAssortmentFromJSON(jsonValue));
    }

    /**
     * Get OtherAssortment identified by given id (must belong to Company of logged user).
     * Get a specific OtherAssortment
     */
    async getOtherAssortmentCustom(requestParameters: GetOtherAssortmentCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OtherAssortment> {
        const response = await this.getOtherAssortmentCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of OtherAssortments for Pile with given id (must belong to Company of logged user).
     * Get a list of OtherAssortments for Pile
     */
    async getOtherAssortmentsByPileRaw(requestParameters: GetOtherAssortmentsByPileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OtherAssortment>>> {
        if (requestParameters.pileId === null || requestParameters.pileId === undefined) {
            throw new runtime.RequiredError('pileId','Required parameter requestParameters.pileId was null or undefined when calling getOtherAssortmentsByPile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/other-assortments/pile/{pileId}`.replace(`{${"pileId"}}`, encodeURIComponent(String(requestParameters.pileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OtherAssortmentFromJSON));
    }

    /**
     * Get a list of OtherAssortments for Pile with given id (must belong to Company of logged user).
     * Get a list of OtherAssortments for Pile
     */
    async getOtherAssortmentsByPile(requestParameters: GetOtherAssortmentsByPileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OtherAssortment>> {
        const response = await this.getOtherAssortmentsByPileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update OtherAssortment identified by given id (must belong to Pile of logged user).
     * Partial update a OtherAssortment
     */
    async updateOtherAssortmentCustomRaw(requestParameters: UpdateOtherAssortmentCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OtherAssortment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOtherAssortmentCustom.');
        }

        if (requestParameters.otherAssortment === null || requestParameters.otherAssortment === undefined) {
            throw new runtime.RequiredError('otherAssortment','Required parameter requestParameters.otherAssortment was null or undefined when calling updateOtherAssortmentCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/other-assortments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OtherAssortmentToJSON(requestParameters.otherAssortment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OtherAssortmentFromJSON(jsonValue));
    }

    /**
     * Partial update OtherAssortment identified by given id (must belong to Pile of logged user).
     * Partial update a OtherAssortment
     */
    async updateOtherAssortmentCustom(requestParameters: UpdateOtherAssortmentCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OtherAssortment> {
        const response = await this.updateOtherAssortmentCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
