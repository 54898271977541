import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSortList from '@components/utility-components/modal/UrstammModalSortList';
import { i18n } from '@i18n/i18n';
import { selectOutgoingProcessorShippingNoteFilter, setOutgoingProcessorShippingNoteFilterList } from '@redux/features/filter/outgoingProcessorShippingNoteFilterSlice';
import { ProcessorShippingNote, ProcessorShippingNoteCurrentStateEnum } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
  getColorByProcessorShippingNoteState,
  getIconByProcessorShippingNoteState,
  getTextByProcessorShippingNoteState,
  getTextByBatchType
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterProcessorShippingNote } from '../../../utils/classes/UrstammUtilityFilter';
import { getCardTitleByProcessorBatchOrProduct } from '../../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyle, UrstammStyleList } from '../../../utils/styles/UrstammStyle';

export default function OutgoingProcessorShippingNoteListView(props: {
  navigation: any;
  processorShippingNoteList: ProcessorShippingNote[];
  moreItems?: () => void;
  modalVisible: boolean;
  processorShippingNoteSelected?: (processorShippingNote: ProcessorShippingNote) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
}) {
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const processorShippingNoteFilter = useSelector((state: RootState) => state.persistedReducer.outgoingProcessorShippingNoteFilter.value);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.outgoingProcessorShippingNote.sortBy);
  const dispatch = useDispatch();

  const [processorShippingNoteSelected, setProcessorShippingNoteSelected] = useState<ProcessorShippingNote>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  let keys: ListSubData[] = [
    {
      title: undefined,
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'currentState',
      keyId: '1currentState',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.recording_type') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'outgoingBatch',
      keyId: '1recordingType',
      dataContainerViewStyle: { height: 24, flexBasis: '100%' },
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' },
      isEnum: true
    },
    {
      title: i18n.t('views.processor_batch.sum_volume_products') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      dataContainerViewStyle: { height: 24, flexBasis: '100%' },
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.amount_of_product') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'totalProducts',
      keyId: '1totalProducts',
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' },
      isEnum: true
    },
    {
      title: i18n.t('generics.processor_batch') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'outgoingBatchData',
      keyId: '1batch',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' },
      truncateMaxLength: 20,
      isEnum: true
    },
    {
      title: i18n.t('generics.id') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'id',
      keyId: '1id',
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' },
      isEnum: true
    },
    {
      title: i18n.t('generics.sender') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creator',
      secondKey: 'name',
      keyId: '1creator',
      // dataContainerViewStyle: { height: 24, flexBasis: '100%' }
    },
    {
      title: i18n.t('generics.receiver') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      secondKey: 'name',
      keyId: '1processor',
      // dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' },
      isEnum: true
    }
  ];

  useEffect(() => {
    if (props.modalVisible) {
      setModalVisible(props.modalVisible);
    }
    return () => {};
  }, [props.modalVisible]);

  useEffect(() => {
    getSlideButtonOptionsProcessorShippingNoteCurrentStates();
  }, []);

  /**
   * Function used for generating filter buttons
   */
  const getSlideButtonOptionsProcessorShippingNoteCurrentStates = (): SlideButtonOption[] => {
    let filterList: SlideButtonOption[] = [];
    let type: SlideButtonOption = {
      id: 'ALL',
      text: i18n.t('utility_components.filter_button.all'),
      key: 'currentUrstammState',
      active: true
    };
    filterList.push(type);
    Object.values(ProcessorShippingNoteCurrentStateEnum).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByProcessorShippingNoteState(state)!,
        key: 'currentState',
        active: false
      };
      filterList.push(type);
    });
    dispatch(setOutgoingProcessorShippingNoteFilterList(filterList));

    return filterList;
  };

  const setProcessorShippingNoteCurrentState = (selected: SlideButtonOption): void => {
    dispatch(selectOutgoingProcessorShippingNoteFilter(selected));
  };

  const getActiveProcessorShippingNoteCurrentState = (): SlideButtonOption[] => {
    return processorShippingNoteFilter.filter(val => val.active);
  };

  return (
    <View style={[UrstammStyle.flex1]}>
      {props.processorShippingNoteList && props.processorShippingNoteList.length > 0 ? (
        <View style={[{ flex: 0.15 }]}>
          <UrstammSlideButton
            testID={'button_status_filter'}
            slideButtonList={processorShippingNoteFilter}
            slideButtonRotatedTitle={i18n.t('utility_components.filter_button.status')}
            buttonPressed={(selected: SlideButtonOption) => setProcessorShippingNoteCurrentState(selected)}
          />
        </View>
      ) : null}

      <View style={[{ flex: 0.85 }]}>
        <UrstammList
          elementSelected={(data: any) => {
            setProcessorShippingNoteSelected(data.item),
              props.processorShippingNoteSelected ? props.processorShippingNoteSelected(data.item) : null;
          }}
          showHash={false}
          testID={'shipping_note_list'}
          list={props.processorShippingNoteList}
          textForEmptyList={i18n.t('views.processor_shipping_note.shipping_note_not_found')}
          filterBy={getActiveProcessorShippingNoteCurrentState()}
          filterList={true}
          filterListFunction={(text, data) => filterProcessorShippingNote(text, data, rdxUserExtendedMe)}
          listStyle={{
            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
            bottomContainerViewStyle: UrstammStyleList.cardSubData,
            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
          }}
          moreItems={props.moreItems}
          listSubData={keys}
          currentState={{
            icon: state => getIconByProcessorShippingNoteState(state),
            color: state => getColorByProcessorShippingNoteState(state),
            text: state => getTextByProcessorShippingNoteState(state)!
          }}
          getTextForData={(data, key) => {
            if (key == 'outgoingBatch' && data) return getTextByBatchType(data.recordingType);
            else if (key == 'incomingProducts' && data) return `${data.length}`;
            return null;
          }}
          getTextForFullData={(data, key) => {
            if (key == 'id' && data) return data.referenceShippingNote ? data.referenceShippingNote.id : data.id;
            return null;
          }}
        />
        {props.showSortBy ? (
          <UrstammModalSortList
            testID={'modal_sort'}
            nameSortKey={'id'}
            nameSortKeyAscText={i18n.t('utility_components.modal.sortBy.id_asc')}
            nameSortKeyDescText={i18n.t('utility_components.modal.sortBy.id_desc')}
            showSortBy={props.showSortBy}
            closeShowSortBy={props.closeShowSortBy}
            applyOrder={(orderSelected: ButtonSelect[]) => props.applyOrder(orderSelected)}
            sortBySelected={rdxSortBy}
          />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
