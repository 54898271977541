/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliverySheet } from './DeliverySheet';
import {
    DeliverySheetFromJSON,
    DeliverySheetFromJSONTyped,
    DeliverySheetToJSON,
} from './DeliverySheet';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface DeliverySheetState
 */
export interface DeliverySheetState {
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof DeliverySheetState
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetState
     */
    state: DeliverySheetStateStateEnum;
    /**
     * 
     * @type {DeliverySheet}
     * @memberof DeliverySheetState
     */
    deliverySheet?: DeliverySheet;
    /**
     * 
     * @type {User}
     * @memberof DeliverySheetState
     */
    author?: User;
}


/**
 * @export
 */
export const DeliverySheetStateStateEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetStateStateEnum = typeof DeliverySheetStateStateEnum[keyof typeof DeliverySheetStateStateEnum];


/**
 * Check if a given object implements the DeliverySheetState interface.
 */
export function instanceOfDeliverySheetState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function DeliverySheetStateFromJSON(json: any): DeliverySheetState {
    return DeliverySheetStateFromJSONTyped(json, false);
}

export function DeliverySheetStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliverySheetState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'state': json['state'],
        'deliverySheet': !exists(json, 'deliverySheet') ? undefined : DeliverySheetFromJSON(json['deliverySheet']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
    };
}

export function DeliverySheetStateToJSON(value?: DeliverySheetState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'state': value.state,
        'deliverySheet': DeliverySheetToJSON(value.deliverySheet),
        'author': UserToJSON(value.author),
    };
}

