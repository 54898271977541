/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Trunk,
} from '../models';
import {
    TrunkFromJSON,
    TrunkToJSON,
} from '../models';

export interface CreateTrunkRequest {
    trunk: Trunk;
}

export interface DeleteTrunkRequest {
    id: number;
}

export interface GetTrunkRequest {
    id: number;
}

export interface PartialUpdateTrunkRequest {
    id: number;
    trunk: Trunk;
}

export interface UpdateTrunkRequest {
    id: number;
    trunk: Trunk;
}

/**
 * 
 */
export class TrunkResourceApi extends runtime.BaseAPI {

    /**
     */
    async createTrunkRaw(requestParameters: CreateTrunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Trunk>> {
        if (requestParameters.trunk === null || requestParameters.trunk === undefined) {
            throw new runtime.RequiredError('trunk','Required parameter requestParameters.trunk was null or undefined when calling createTrunk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkToJSON(requestParameters.trunk),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkFromJSON(jsonValue));
    }

    /**
     */
    async createTrunk(requestParameters: CreateTrunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Trunk> {
        const response = await this.createTrunkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTrunkRaw(requestParameters: DeleteTrunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTrunk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTrunk(requestParameters: DeleteTrunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTrunkRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllTrunksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Trunk>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrunkFromJSON));
    }

    /**
     */
    async getAllTrunks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Trunk>> {
        const response = await this.getAllTrunksRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTrunkRaw(requestParameters: GetTrunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Trunk>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTrunk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkFromJSON(jsonValue));
    }

    /**
     */
    async getTrunk(requestParameters: GetTrunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Trunk> {
        const response = await this.getTrunkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateTrunkRaw(requestParameters: PartialUpdateTrunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Trunk>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateTrunk.');
        }

        if (requestParameters.trunk === null || requestParameters.trunk === undefined) {
            throw new runtime.RequiredError('trunk','Required parameter requestParameters.trunk was null or undefined when calling partialUpdateTrunk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkToJSON(requestParameters.trunk),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateTrunk(requestParameters: PartialUpdateTrunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Trunk> {
        const response = await this.partialUpdateTrunkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTrunkRaw(requestParameters: UpdateTrunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Trunk>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTrunk.');
        }

        if (requestParameters.trunk === null || requestParameters.trunk === undefined) {
            throw new runtime.RequiredError('trunk','Required parameter requestParameters.trunk was null or undefined when calling updateTrunk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkToJSON(requestParameters.trunk),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkFromJSON(jsonValue));
    }

    /**
     */
    async updateTrunk(requestParameters: UpdateTrunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Trunk> {
        const response = await this.updateTrunkRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
