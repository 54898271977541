import React from 'react';

export type AppContextType = {
  loading: boolean | undefined;
  setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

const AppContext = React.createContext<AppContextType>({
  loading: undefined,
  setLoading(value: ((prevState: boolean) => boolean | undefined) | boolean | undefined): void {}
});

export default AppContext;
