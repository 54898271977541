import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import TrunkListView from '@components/views/trunk/TrunkListView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { customDeliverySheetResourceApi } from '@services/apis/ApiConfiguration';
import { FullAvailableTrunkResourceApi } from '@services/apis/FullAvailableTrunkResourceApi';
import { DeliverySheetDTO, Trunk } from '@services/apis/generated';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { resetTrunkList, setTrunkList, setTrunkListSort, TrunkListItem } from '../../redux/features/trunk/trunkSlice';
import { RootState, store } from '../../redux/store';
import {
  canAddTrunksByDSCurrentState,
  dsCurrentStatusIsInProgressOrValidOrClosed,
  sleep,
  trunkChangedProperties,
  urstammUtilGoBack
} from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function TrunkListContainer({ navigation, route }: RootStackScreenProps<'TrunkList'>) {
  //REDUX
  const rdxTrunkList = useSelector((state: RootState) => state.persistedReducer.trunk.list);
  const rdxTrunkCompleteList = useSelector((state: RootState) => state.persistedReducer.trunk.completeList);
  const rdxTrunkSortBy = useSelector((state: RootState) => state.persistedReducer.trunk.sortBy);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const [latestTrunk, setLatestTrunk] = useState<Trunk | undefined>(undefined);
  const dispatch = useDispatch();

  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  /**
   * Settings for corner buttons
   */
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText:
        rdxTrunkSortBy.length > 0 && rdxTrunkSortBy[0]
          ? rdxTrunkSortBy[0].text
          : i18n.t('utility_components.modal.sortBy.date_desc')
    },
    cornerBottomRight: {
      showCorner: canAddTrunksByDSCurrentState(
        route.params?.deliverySheetSelected?.currentUrstammState,
        route.params?.deliverySheetSelected?.logging?.currentState,
        rdxUserExtendedMe.type,
        route.params?.deliverySheet.recordingType
      ),
      text: i18n.t('generics.trunk'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useFocusEffect(
    useCallback(() => {
      if (dsCurrentStatusIsInProgressOrValidOrClosed(route.params?.deliverySheetSelected.currentState))
        getTrunkListByDeliverySheet(route.params?.deliverySheetSelected, rdxTrunkSortBy);
    }, [route.params, rdxTrunkSortBy])
  );

  useEffect(() => {
    getTrunkListByDeliverySheet(route.params?.deliverySheetSelected, rdxTrunkSortBy);
    return () => {
      dispatch(resetTrunkList());
    };
  }, []);

  const getTrunkListByDeliverySheet = (deliverySheetSelected?: DeliverySheetDTO, order?: ButtonSelect[]): void => {
    dispatch(changeLoaderStatus(true));
    let rdxUpdated = store.getState().persistedReducer.trunk.completeList;
    FullAvailableTrunkResourceApi.getTrunksByDeliverySheet(
      rdxUpdated.map(_ => _.trunk),
      { deliverySheetId: deliverySheetSelected?.id! }
    )
      .then((list: Trunk[]) => {
        if (list) {
          const newList: TrunkListItem[] = list.map(_ => {
            return {
              id: _.id,
              trunk: _,
              edited: {
                status: trunkChangedProperties(_, rdxUserExtendedMe.type),
                data: _
              }
            };
          });
          dispatch(changeLoaderStatus(false));
          dispatch(setTrunkList(newList));
          if (newList.length > 0) setLatestTrunk(getLastTrunkByCreationDate(newList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  /**
   * Creating reference trunk
   */
  const getLastTrunkByCreationDate = (trunkList: TrunkListItem[]) => {
    const latestTrunk = trunkList
      .sort((a, b) => new Date(b.trunk.creationDate!).getTime() - new Date(a.trunk.creationDate!).getTime())
      .shift();
    return latestTrunk?.trunk;
  };

  const navigateToTrunkRegistration = (): void => {
    UrstammNavigationHelper.navigateToTrunkRegistration(navigation, route.params?.deliverySheetSelected, latestTrunk);
  };

  const navigateToTrunkDetails = (trunkSelected: Trunk): void => {
    UrstammNavigationHelper.navigateToTrunkDetails(navigation, route.params?.deliverySheetSelected, trunkSelected);
  };

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setTrunkListSort(orderSelected));
    setShowSortBy(false);
  };

  const validateTrunkList = (validateAllAndIgnoreChanges: boolean) => {
    dispatch(changeLoaderStatus(true));
    let trunks: Set<Trunk> = validateAllAndIgnoreChanges
      ? new Set(rdxTrunkList.map(_ => _.trunk))
      : new Set(rdxTrunkList.map(_ => (_.edited.status ? _.trunk : _.edited.data)));

    customDeliverySheetResourceApi
      .validateDeliverySheet({
        id: route.params?.deliverySheetSelected.id,
        deliverySheetValidationDTO: {
          id: route.params?.deliverySheetSelected.id,
          trunks: trunks
        }
      })
      .then(res => {
        if (res) {
          dispatch(changeLoaderStatus(false));
          dispatch(resetTrunkList());
          UrstammNavigationHelper.navigateToDeliverySheetList(
            navigation,
            route.params?.deliverySheetSelected?.logging,
            true
          );
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const validateTrunkSwitched = (data: TrunkListItem) => {
    //Reset the trunk edited
    const indexComplete = rdxTrunkList.findIndex(object => object.trunk.id === data.trunk.id);
    const list = [...rdxTrunkList];
    const item = list[indexComplete];
    const itemEdited = {
      id: item.id,
      trunk: item.trunk,
      edited: {
        status: data.edited.status,
        data: item.edited.data
      }
    };
    list[indexComplete] = itemEdited;
    dispatch(setTrunkList(list));
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomRight={navigateToTrunkRegistration}
      submitBottomLeft={() => setShowSortBy(true)}
      title={
        <UrstammTitle
          testID={'trunk_title'}
          text={i18n.t('generics.trunk')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      headerDetails={
        route.params?.deliverySheetSelected
          ? [
              {
                title: i18n.t('generics.delivery_sheet'),
                value: route.params?.deliverySheetSelected.name!
              }
            ]
          : undefined
      }
      view={
        <TrunkListView
          navigation={navigation}
          deliverySheetSelected={route.params?.deliverySheetSelected}
          trunkList={rdxTrunkList}
          trunkSelected={(trunkSelected: any) => navigateToTrunkDetails(trunkSelected.item)}
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
          validate={validateTrunkList}
          validateTrunkSwitched={(trunk: TrunkListItem) => validateTrunkSwitched(trunk)}
        />
      }
    />
  );
}
