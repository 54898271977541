import moment from 'moment';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { StyleProp, StyleSheet, Text, TextInput, TextStyle, View, ViewStyle } from 'react-native';
import { getMandatorySymbol } from '../../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyle } from '../../../utils/styles/UrstammStyle';
import UrstammIcon, { UrstammIconProps } from '../icon/UrstammIcon';

const DatePickerInput = forwardRef((props: any, ref: React.ForwardedRef<any>) => {
  const { datePickerInputProps = {}, ...otherProps } = props;
  const { containerStyle, icon, fontStyle } = datePickerInputProps;

  return (
    <View style={[containerStyle, UrstammStyle.baseShadow]}>
      {icon.showIcon && (
        <View style={styles.iconContainer}>
          <UrstammIcon name={icon.iconName} size={24} color={icon.iconColor!} />
        </View>
      )}
      <TextInput ref={ref} style={[UrstammStyle.flex1, fontStyle]} autoFocus={false} {...otherProps} />
    </View>
  );
});

export default function UrstammDatePicker(props: {
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  fontStyle?: StyleProp<TextStyle>;
  placeholder?: string;
  testID: string;
  icon: UrstammIconProps;
  onChange: (dateTime: any) => void;
  disabled?: boolean;
  defaultValue?: any;
  textInputTitle?: string;
  mandatory?: boolean;
  mode: 'date' | 'time' | 'datetime'; //IOS only: | 'datetime' | 'countdown'
}) {
  const [date, setDate] = useState<Date>(new Date());

  useEffect(() => {
    if (props.defaultValue instanceof Date) {
      setDate(props.defaultValue);
    } else if (typeof props.defaultValue === 'string') {
      const momentDate = moment(props.defaultValue);
      if (momentDate.isValid()) {
        setDate(momentDate.toDate());
      }
    } else if (typeof props.defaultValue === 'number') {
      const momentDate = moment(props.defaultValue, 'X');
      if (momentDate.isValid()) {
        setDate(momentDate.toDate());
      }
    }
  }, [props.defaultValue]);

  const handleConfirm = (date: any) => {
    let time: Date = new Date();
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());

    setDate(date);
    props.onChange(date);
  };

  //Ref needed for custom input
  const refCustomInput = useRef();

  return (
    <View style={[UrstammStyle.flex1]}>
      {/**Title */}
      {props.textInputTitle && (
        <View style={UrstammStyle.textTitleContainerFlex}>
          <Text style={UrstammStyle.textTitle}>{props.textInputTitle + getMandatorySymbol(props.mandatory!)}</Text>
        </View>
      )}

      {/**Date picker */}
      <View style={{ justifyContent: 'flex-start' }}>
        <DatePicker
          customInput={
            <DatePickerInput
              ref={refCustomInput}
              datePickerInputProps={{
                containerStyle: props.containerStyle,
                icon: props.icon,
                fontStyle: props.fontStyle
              }}
            />
          }
          placeholderText={props.placeholder}
          dateFormat="dd/MM/yyyy"
          disabled={props.disabled}
          selected={date}
          popperPlacement="auto"
          showTimeSelectOnly={props.mode === 'time'}
          showTimeInput={props.mode == 'datetime' || props.mode == 'time'}
          showTimeSelect={props.mode == 'datetime' || props.mode == 'time'}
          onChange={handleConfirm}
          portalId={'root-portal'}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  iconContainer: {
    paddingRight: 14
  },
  datePicker: {
    backgroundColor: 'red',
    zIndex: 9999
  }
});
