import { DateHelper } from '@helpers/DateHelper';
import { NetworkHelper } from '@helpers/NetworkHelper';
import { OfflineSyncClassEnum, OfflineSyncTypeEnum } from '@redux/features/offlineSync/offlineSyncSlice';
import { setTreeList } from '@redux/features/tree/treeSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Dispatch, AnyAction } from 'redux';
import { storeTreePhoto } from '../../utils/classes/UrstammUtilityFile';
import { sortOfflineList } from '../../utils/classes/UrstammUtilityFunctions';
import { customTreeResourceApi } from './ApiConfiguration';
import {
  CreateTreeCustomRequest,
  GetTreesByLoggingRequest,
  Tree,
  TreeCurrentStateEnum,
  TreeDTO,
  TreeRecordingTypeEnum,
  TreeSpeciesEnum,
  UpdateTreeCustomRequest
} from './generated';

export class FullAvailableTreeResourceApi {
  /**
   * Get all trunk list for delivery sheet
   * @param rdxTreeCompleteList Redux list of Tree
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns Updated list of trunks by logging or redux list if network is not reachable
   */
  static async getTreesByLogging(
    rdxTreeCompleteList: TreeDTO[],
    requestParameters: GetTreesByLoggingRequest,
    initOverrides?: RequestInit
  ): Promise<Array<TreeDTO>> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk) return customTreeResourceApi.getTreesByLogging(requestParameters, initOverrides);
    let treeList = rdxTreeCompleteList.filter(trunk => trunk.logging?.id == requestParameters.loggingId);
    return sortOfflineList(treeList ,requestParameters)

  }

  /**
   * Create a Tree
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async createTreeCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: CreateTreeCustomRequest,
    initOverrides?: RequestInit
  ): Promise<Tree> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customTreeResourceApi.createTreeCustom(requestParameters, initOverrides);
    requestParameters.entityWithFileDTOTreeDTO.entity!.id = DateHelper.nowTimestamp();
    const base64 = requestParameters.entityWithFileDTOTreeDTO.base64File!;
    if (base64) {
      storeTreePhoto(requestParameters.entityWithFileDTOTreeDTO.entity!.id, base64);
      requestParameters.entityWithFileDTOTreeDTO.base64File = undefined;
    }
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.entityWithFileDTOTreeDTO,
        type: OfflineSyncTypeEnum.INSERT,
        class: OfflineSyncClassEnum.TREE,
        name: requestParameters.entityWithFileDTOTreeDTO.entity?.photoNumber
      })
    );
    dispatch(setTreeList([requestParameters.entityWithFileDTOTreeDTO.entity]));
    return {
      ...requestParameters.entityWithFileDTOTreeDTO.entity,
      species:
        requestParameters.entityWithFileDTOTreeDTO.entity!.species != null
          ? TreeSpeciesEnum[requestParameters.entityWithFileDTOTreeDTO.entity!.species!.toString()]
          : undefined,
      recordingType:
        requestParameters.entityWithFileDTOTreeDTO.entity!.recordingType != null
          ? TreeRecordingTypeEnum[requestParameters.entityWithFileDTOTreeDTO.entity!.recordingType!.toString()]
          : undefined,
      currentState:
        requestParameters.entityWithFileDTOTreeDTO.entity!.currentState != null
          ? TreeCurrentStateEnum[requestParameters.entityWithFileDTOTreeDTO.entity!.currentState!.toString()]
          : undefined
    };
  }

  /**
   * Update a Tree
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async updateTreeCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: UpdateTreeCustomRequest,
    initOverrides?: RequestInit
  ): Promise<Tree> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customTreeResourceApi.updateTreeCustom(requestParameters, initOverrides);
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.entityWithFileDTOTreeDTO,
        type: OfflineSyncTypeEnum.UPDATE,
        class: OfflineSyncClassEnum.TREE,
        name: requestParameters.entityWithFileDTOTreeDTO.entity?.photoNumber
      })
    );
    dispatch(setTreeList([requestParameters.entityWithFileDTOTreeDTO.entity]));
    return {
      ...requestParameters.entityWithFileDTOTreeDTO.entity,
      species:
        requestParameters.entityWithFileDTOTreeDTO.entity!.species != null
          ? TreeSpeciesEnum[requestParameters.entityWithFileDTOTreeDTO.entity!.species!.toString()]
          : undefined,
      recordingType:
        requestParameters.entityWithFileDTOTreeDTO.entity!.recordingType != null
          ? TreeRecordingTypeEnum[requestParameters.entityWithFileDTOTreeDTO.entity!.recordingType!.toString()]
          : undefined,
      currentState:
        requestParameters.entityWithFileDTOTreeDTO.entity!.currentState != null
          ? TreeCurrentStateEnum[requestParameters.entityWithFileDTOTreeDTO.entity!.currentState!.toString()]
          : undefined
    };
  }
}
