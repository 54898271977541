/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DoubleFilter } from './DoubleFilter';
import {
    DoubleFilterFromJSON,
    DoubleFilterFromJSONTyped,
    DoubleFilterToJSON,
} from './DoubleFilter';
import type { InstantFilter } from './InstantFilter';
import {
    InstantFilterFromJSON,
    InstantFilterFromJSONTyped,
    InstantFilterToJSON,
} from './InstantFilter';
import type { LoggingStateEnumFilter } from './LoggingStateEnumFilter';
import {
    LoggingStateEnumFilterFromJSON,
    LoggingStateEnumFilterFromJSONTyped,
    LoggingStateEnumFilterToJSON,
} from './LoggingStateEnumFilter';
import type { LongFilter } from './LongFilter';
import {
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
} from './LongFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';
import type { UrstammMediaTypeFilter } from './UrstammMediaTypeFilter';
import {
    UrstammMediaTypeFilterFromJSON,
    UrstammMediaTypeFilterFromJSONTyped,
    UrstammMediaTypeFilterToJSON,
} from './UrstammMediaTypeFilter';

/**
 * 
 * @export
 * @interface CustomLoggingCriteria
 */
export interface CustomLoggingCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomLoggingCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomLoggingCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomLoggingCriteria
     */
    comment?: StringFilter;
    /**
     * 
     * @type {InstantFilter}
     * @memberof CustomLoggingCriteria
     */
    creationDate?: InstantFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomLoggingCriteria
     */
    sumCubage?: DoubleFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomLoggingCriteria
     */
    sumWoodOtherAssortment?: DoubleFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomLoggingCriteria
     */
    sumWoodTotal?: DoubleFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomLoggingCriteria
     */
    estimatedVolume?: DoubleFilter;
    /**
     * 
     * @type {LoggingStateEnumFilter}
     * @memberof CustomLoggingCriteria
     */
    currentState?: LoggingStateEnumFilter;
    /**
     * 
     * @type {UrstammMediaTypeFilter}
     * @memberof CustomLoggingCriteria
     */
    permitMediaType?: UrstammMediaTypeFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomLoggingCriteria
     */
    permitFilename?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomLoggingCriteria
     */
    permitFilepath?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomLoggingCriteria
     */
    permitUrl?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomLoggingCriteria
     */
    permitMd5?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomLoggingCriteria
     */
    uuid?: StringFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomLoggingCriteria
     */
    companyId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomLoggingCriteria
     */
    permitAuthorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomLoggingCriteria
     */
    loggingStateId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomLoggingCriteria
     */
    pileId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomLoggingCriteria
     */
    plotId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomLoggingCriteria
     */
    treeId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomLoggingCriteria
     */
    distinct?: boolean;
}

/**
 * Check if a given object implements the CustomLoggingCriteria interface.
 */
export function instanceOfCustomLoggingCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomLoggingCriteriaFromJSON(json: any): CustomLoggingCriteria {
    return CustomLoggingCriteriaFromJSONTyped(json, false);
}

export function CustomLoggingCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomLoggingCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : StringFilterFromJSON(json['name']),
        'comment': !exists(json, 'comment') ? undefined : StringFilterFromJSON(json['comment']),
        'creationDate': !exists(json, 'creationDate') ? undefined : InstantFilterFromJSON(json['creationDate']),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : DoubleFilterFromJSON(json['sumCubage']),
        'sumWoodOtherAssortment': !exists(json, 'sumWoodOtherAssortment') ? undefined : DoubleFilterFromJSON(json['sumWoodOtherAssortment']),
        'sumWoodTotal': !exists(json, 'sumWoodTotal') ? undefined : DoubleFilterFromJSON(json['sumWoodTotal']),
        'estimatedVolume': !exists(json, 'estimatedVolume') ? undefined : DoubleFilterFromJSON(json['estimatedVolume']),
        'currentState': !exists(json, 'currentState') ? undefined : LoggingStateEnumFilterFromJSON(json['currentState']),
        'permitMediaType': !exists(json, 'permitMediaType') ? undefined : UrstammMediaTypeFilterFromJSON(json['permitMediaType']),
        'permitFilename': !exists(json, 'permitFilename') ? undefined : StringFilterFromJSON(json['permitFilename']),
        'permitFilepath': !exists(json, 'permitFilepath') ? undefined : StringFilterFromJSON(json['permitFilepath']),
        'permitUrl': !exists(json, 'permitUrl') ? undefined : StringFilterFromJSON(json['permitUrl']),
        'permitMd5': !exists(json, 'permitMd5') ? undefined : StringFilterFromJSON(json['permitMd5']),
        'uuid': !exists(json, 'uuid') ? undefined : StringFilterFromJSON(json['uuid']),
        'companyId': !exists(json, 'companyId') ? undefined : LongFilterFromJSON(json['companyId']),
        'permitAuthorId': !exists(json, 'permitAuthorId') ? undefined : LongFilterFromJSON(json['permitAuthorId']),
        'loggingStateId': !exists(json, 'loggingStateId') ? undefined : LongFilterFromJSON(json['loggingStateId']),
        'pileId': !exists(json, 'pileId') ? undefined : LongFilterFromJSON(json['pileId']),
        'plotId': !exists(json, 'plotId') ? undefined : LongFilterFromJSON(json['plotId']),
        'treeId': !exists(json, 'treeId') ? undefined : LongFilterFromJSON(json['treeId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CustomLoggingCriteriaToJSON(value?: CustomLoggingCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'name': StringFilterToJSON(value.name),
        'comment': StringFilterToJSON(value.comment),
        'creationDate': InstantFilterToJSON(value.creationDate),
        'sumCubage': DoubleFilterToJSON(value.sumCubage),
        'sumWoodOtherAssortment': DoubleFilterToJSON(value.sumWoodOtherAssortment),
        'sumWoodTotal': DoubleFilterToJSON(value.sumWoodTotal),
        'estimatedVolume': DoubleFilterToJSON(value.estimatedVolume),
        'currentState': LoggingStateEnumFilterToJSON(value.currentState),
        'permitMediaType': UrstammMediaTypeFilterToJSON(value.permitMediaType),
        'permitFilename': StringFilterToJSON(value.permitFilename),
        'permitFilepath': StringFilterToJSON(value.permitFilepath),
        'permitUrl': StringFilterToJSON(value.permitUrl),
        'permitMd5': StringFilterToJSON(value.permitMd5),
        'uuid': StringFilterToJSON(value.uuid),
        'companyId': LongFilterToJSON(value.companyId),
        'permitAuthorId': LongFilterToJSON(value.permitAuthorId),
        'loggingStateId': LongFilterToJSON(value.loggingStateId),
        'pileId': LongFilterToJSON(value.pileId),
        'plotId': LongFilterToJSON(value.plotId),
        'treeId': LongFilterToJSON(value.treeId),
        'distinct': value.distinct,
    };
}

