import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImageNewTree from '@components/images/corner/UrstammImageNewTree';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import {
  applySavingToReference,
  deepCopyAndAddSelectedParameter,
  restoreListToReference,
  selectElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import BatchRegistrationView from '@components/views/batch/BatchRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { resetBatchList } from '@redux/features/batch/batchSlice';
import { setDeliverySheetPage } from '@redux/features/delivery-sheet/deliverySheetSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { RootState } from '@redux/store';
import { customBatchResourceApi } from '@services/apis/ApiConfiguration';
import {
  Batch,
  BatchCurrentStateEnum,
  BatchDTO,
  BatchRecordingTypeEnum,
  DeliverySheet,
  DeliverySheetDTO,
  DeliverySheetDTOCurrentUrstammStateEnum
} from '@services/apis/generated';
import React, { useCallback, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { formatPhotoNumber, generateSet, sleep } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function BatchRegistrationContainer({ navigation, route }: RootStackScreenProps<'BatchRegistration'>) {
  const rdxDSList = useSelector((state: RootState) => state.persistedReducer.deliverySheet.list);
  const rdxDSCompleteList = useSelector((state: RootState) => state.persistedReducer.deliverySheet.completeList);
  const rdxDSPage = useSelector((state: RootState) => state.persistedReducer.deliverySheet.page);
  const rdxDSTotalPages = useSelector((state: RootState) => state.persistedReducer.deliverySheet.totalPages);
  const rdxDSSortBy = useSelector((state: RootState) => state.persistedReducer.deliverySheet.sortBy);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const rdxMyCompany = useSelector((state: RootState) => state.persistedReducer.user.myCompany);
  const dispatch = useDispatch();
  const [batchList, setBatchList] = useStateWithCallbackLazy<Batch[]>([]);
  const [batchUniqueIdentifier, setBatchUniqueIdentifier] = useState<string>('');
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [dsList, setDsList] = useState<any>();
  const [dsListReference, setDsListReference] = useState<any>();
  const [singleTreeRecord, setSingleTreeRecord] = useState<boolean>(false);
  const [dateRecord, setDateRecord] = useState<boolean>(false);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: undefined,
      icon: (
        <UrstammImageNewTree
          width={PlatformHelper.normalizeByDevice(34)}
          height={PlatformHelper.normalizeByDevice(24)}
        />
      )
    }
  };

  useFocusEffect(
    useCallback(() => {
      setResetForm(false);

      if (route.params?.batchList && route.params.batchList.length > 0) {
        //TODO: Test
        let list: DeliverySheetDTO[] = JSON.parse(JSON.stringify(rdxDSCompleteList));
        list = list.filter(ds => ds.currentUrstammState != DeliverySheetDTOCurrentUrstammStateEnum.InProgress);

        setBatchList(route.params?.batchList, updatedBatchList => {
          deepCopyAndAddSelectedParameter(list, setDsList, setDsListReference);
        });
      }

      generateBatchUniqueIdentifier(route.params?.batchList ? route.params?.batchList : []);
      return () => {
        setResetForm(true);
      };
    }, [route.params])
  );

  const saveBatch = (batch: BatchDTO) => {
    let dsSet: Set<DeliverySheet> = new Set(generateSet(dsList));

    dispatch(changeLoaderStatus(true));

    let batchObj: Batch = {
      name: batch.name!,
      uniqueIdentifier: batch.uniqueIdentifier!,
      creationDate: batch.creationDate,
      currentState: BatchCurrentStateEnum.InProgress,
      recordingType: batch.recordingType! as any,
      comment: batch.comment!,
      deliverySheets: dsSet
    };

    customBatchResourceApi
      .createBatchCustom({ batch: batchObj })
      .then((batch: Batch) => {
        dispatch(changeLoaderStatus(false));
        if (batch) {
          setResetForm(true);
          dispatch(resetBatchList());
          UrstammNavigationHelper.navigateToBatchList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const filterDSListByBatchRecordingType = (batchRT: BatchRecordingTypeEnum) => {
    let list = [...rdxDSCompleteList];
    list = list
      .filter(
        elem =>
          elem.recordingType === batchRT &&
          // enable the selection of empty delivery sheets (no trunks)
          // && elem.trunks?.length
          // && elem.trunks?.length > 0
          ((!(elem.trunks?.length && elem.trunks?.length > 0) &&
            elem.currentUrstammState == DeliverySheetDTOCurrentUrstammStateEnum.Delivered) ||
            elem.currentUrstammState == DeliverySheetDTOCurrentUrstammStateEnum.Validated ||
            elem.currentUrstammState == DeliverySheetDTOCurrentUrstammStateEnum.Closed)
      )
      .map(val => ({
        ...val,
        selected: false
      }));
    setDsList(list);
    setDsListReference(list);
  };

  const goBack = async (): Promise<void> => {
    dispatch(resetBatchList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToBatchList(navigation, true);
  };

  /**
   * Prefix ex: U_BRU_001
   * U = Urstamm
   * BRU = prefixIdentifier
   * 001 = counter
   */
  const generateBatchUniqueIdentifier = (updatedBatchList: Batch[]) => {
    let companyIdentifier = 'U';
    let prefixIdentifier = rdxMyCompany?.prefixIdentifier;
    if (updatedBatchList.length > 0) {
      let maxId: string = formatPhotoNumber((Number(updatedBatchList.length) + 1).toString());

      //Finally combining the two variables (string);
      return setBatchUniqueIdentifier(companyIdentifier + '_' + prefixIdentifier + '_' + formatPhotoNumber((route?.params?.totalBatch+1).toString()));
    }
    //Default value
    return setBatchUniqueIdentifier(companyIdentifier + '_' + prefixIdentifier + '_' + '001');
  };

  const getMoreDsItems = (): void => {
    if (rdxDSPage < rdxDSTotalPages - 1) {
      dispatch(setDeliverySheetPage(rdxDSPage + 1));
    }
  };

  const addSingleTreeToIdentifier = (add: string) => {
    if (add == BatchRecordingTypeEnum.Single) {
      if (!singleTreeRecord && !dateRecord) {
        setBatchUniqueIdentifier(batchUniqueIdentifier + 'E');
      } else if (!singleTreeRecord && dateRecord) {
        setBatchUniqueIdentifier(batchUniqueIdentifier.slice(0, -1) + 'E');
      }
      setSingleTreeRecord(true);
    } else if (add == BatchRecordingTypeEnum.Multiple) {
      if (singleTreeRecord || dateRecord) {
        // Remove last letter
        setBatchUniqueIdentifier(batchUniqueIdentifier.slice(0, -1));
        setSingleTreeRecord(false);
        setDateRecord(false);
      }
    } else {
      if (singleTreeRecord) {
        setBatchUniqueIdentifier(batchUniqueIdentifier.slice(0, -1) + 'D');
        setSingleTreeRecord(false);
        setDateRecord(true);
      } else {
        if (!dateRecord) {
          setBatchUniqueIdentifier(batchUniqueIdentifier + 'D');
        }
        setSingleTreeRecord(false);
        setDateRecord(true);
      }
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'batch_title'}
          text={i18n.t('views.batch.batch_registration.new_batch')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <BatchRegistrationView
          navigation={navigation}
          uniqueIdentifier={batchUniqueIdentifier}
          submitBatchForm={(data: BatchDTO) => saveBatch(data)}
          resetBatchForm={resetForm}
          dsProps={{
            deliverySheetList: dsList,
            deliverySheetSelected: dsSelected => selectElementFormList(dsSelected, setDsList, dsList),
            moreItems: getMoreDsItems,
            resetDSSelected: () => restoreListToReference(setDsList, dsListReference),
            confirmDSSelected: () => applySavingToReference(setDsListReference, dsList)
          }}
          userExtendedMe={rdxUserExtendedMe}
          recordingTypeSelected={filterDSListByBatchRecordingType}
          addSingleTreeToIdentifier={addSingleTreeToIdentifier}
          // addDateRecordingIdentifier={addDateRecordingIdentifier}
        />
      }
    />
  );
}
