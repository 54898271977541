import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammButtonSelect, { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammFilePicker, {
  PickerMediaType,
  UploadType
} from '@components/utility-components/file-picker/UrstammFilePicker';
import { i18n } from '@i18n/i18n';
import {
  Logging,
  OtherAssortmentOtherAssortmentTypeEnum,
  OtherAssortmentSpeciesEnum,
  PileWoodTypeEnum
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  urstammUtilDisplayError,
  urstammUtilUpdateForm,
  urstammUtilUpdatePhoto,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { mainGreenButton, mainGreenButtonSelectActive } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammStyle, UrstammStyleContainer, UrstammStyleLayout } from '../../../utils/styles/UrstammStyle';
import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { getTextByOtherAssortmentSpecies } from '../../../utils/classes/UrstammUtilityCurrentState';
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import { getTreeDTOSpeciesList } from '../../../utils/classes/UrstammUtilityFunctions';
import { AlertHelper } from '@helpers/AlertHelper';

export interface PileRegistration {
  id?: number;
  name?: string;
  creationDate?: Date;
  comment?: string;
  woodType?: PileWoodTypeEnum;
  estimatedVolume?: number;
  latitude?: number;
  longitude?: number;
  logging?: Logging;
  otherAssortmentType?: OtherAssortmentOtherAssortmentTypeEnum;
  species?: OtherAssortmentSpeciesEnum;
}

export interface PileForm {
  pile: PileRegistration;
  base64File: string;
}

export default function PileRegistrationView(props: {
  navigation: any;
  submitPileForm: (form: any) => void;
  loggingSelected: Logging;
}) {
  useEffect(() => {
    getSlideButtonOptionsTreeSpecies();
  }, []);

  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);

  const [otherAssortmentActive, setOtherAssortmentActive] = useState<boolean>(false);
  const [treeSpeciesList, setTreeSpeciesList] = useState<SlideButtonOption[]>([]);

  const [woodType, setWoodType] = useState<ButtonSelect[]>([
    {
      text: i18n.t('generics.timber'),
      enum: PileWoodTypeEnum.Timber,
      active: false
    },
    {
      text: i18n.t('generics.other_assortment'),
      enum: PileWoodTypeEnum.OtherAssortment,
      active: false
    }
  ]);

  const [otherAssortmentType, setOtherAssortmentType] = useState<ButtonSelect[]>([
    {
      text: i18n.t('generics.energy'),
      enum: OtherAssortmentOtherAssortmentTypeEnum.EnergyWood,
      active: false
    },
    {
      text: i18n.t('generics.industrial_wood'),
      enum: OtherAssortmentOtherAssortmentTypeEnum.IndustrialWood,
      active: false
    },
    {
      text: i18n.t('generics.fire_wood'),
      enum: OtherAssortmentOtherAssortmentTypeEnum.FireWood,
      active: false
    }
  ]);

  const [pileForm, setPileForm] = useStateWithCallbackLazy<PileForm>({
    pile: {
      name: '',
      longitude: undefined,
      latitude: undefined,
      creationDate: new Date(),
      comment: '',
      logging: props.loggingSelected
    },
    base64File: ''
  });

  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  //Validators
  const [validators, setValidators] = useState<any>({
    name: { required: true },
    woodType: { required: true },
    estimatedVolume: {},
    species: { required: true },
    otherAssortmentType: { required: true }
  });

  useEffect(() => {
    let isTimberOrEstimatedIsSet =
      pileForm.pile.woodType == PileWoodTypeEnum.Timber ||
      (pileForm.pile.woodType == PileWoodTypeEnum.OtherAssortment && pileForm.pile.estimatedVolume);
    if (isTimberOrEstimatedIsSet) validate(pileForm.pile.estimatedVolume as any, 'estimatedVolume', pileForm);
  }, [validators]);

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    woodType: [],
    estimatedVolume: [],
    species: [],
    otherAssortmentType: []
  });

  const validate = (value: string, key: string, updatedPileForm: any) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedPileForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedPileForm: PileForm) => {
    let disabled: boolean = false;
    if (
      urstammUtilValidatorInError(errorsUpdated) ||
      updatedPileForm.pile.name?.length == 0 ||
      !updatedPileForm.pile.woodType ||
      (updatedPileForm.pile.woodType == PileWoodTypeEnum.OtherAssortment && !updatedPileForm.pile.estimatedVolume) ||
      (updatedPileForm.pile.woodType == PileWoodTypeEnum.OtherAssortment &&
        (!updatedPileForm.pile.species || !updatedPileForm.pile.otherAssortmentType))
    ) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  /**
   * FORM UPDATE
   */
  const updatePileForm = (key: string, value: any, mustValidate?: boolean): void => {
    let form = urstammUtilUpdateForm(key, value, pileForm, 'pile');
    setPileForm(
      { pile: form['pile'], base64File: form['base64File'] },
      mustValidate ? updatedPileForm => validate(value, key, updatedPileForm) : () => {}
    );
  };

  const updatePhoto = (base64: string, location: any, mustValidate?: boolean): void => {
    const photoCreationDate = new Date();
    let form = urstammUtilUpdatePhoto(base64, location, photoCreationDate, pileForm, 'pile');
    setPileForm(
      { pile: form['pile'], base64File: form['base64File'] },
      mustValidate ? updatedPileForm => validate(base64, 'base64File', updatedPileForm) : () => {}
    );
  };

  const activeWoodType = (woodTypeEnum: PileWoodTypeEnum): void => {
    woodType.forEach(type => {
      type.active = type.enum == woodTypeEnum ? true : false;
    });
    setWoodType([...woodType]);
    getActiveWoodType();
    if (woodTypeEnum == PileWoodTypeEnum.OtherAssortment) {
      setValidators(prev => ({
        ...prev,
        estimatedVolume: { required: true, numeric: true }
      }));
    } else {
      setValidators(prev => ({ ...prev, estimatedVolume: {} }));
    }
  };

  const getActiveWoodType = (): void => {
    woodType.forEach(type => {
      if (type.enum == PileWoodTypeEnum.OtherAssortment) {
        if (type.active) {
          setOtherAssortmentActive(true);
        } else {
          setOtherAssortmentActive(false);
        }
      }
    });
  };

  const activeOtherAssortmentType = (assortmentType: OtherAssortmentOtherAssortmentTypeEnum): void => {
    otherAssortmentType.forEach(type => {
      type.active = type.enum == assortmentType ? true : false;
    });
    setOtherAssortmentType([...otherAssortmentType]);
  };

  const setPileTreeSpecie = (selected: SlideButtonOption): void => {
    treeSpeciesList.forEach(type => {
      type.active = type.id == selected.id ? true : false;
    });
    setTreeSpeciesList([...treeSpeciesList]);
  };

  const getSlideButtonOptionsTreeSpecies = (): void => {
    let filterList: SlideButtonOption[] = [];
    (getTreeDTOSpeciesList() as OtherAssortmentSpeciesEnum[]).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByOtherAssortmentSpecies(state),
        active: false
      };
      filterList.push(type);
    });
    setTreeSpeciesList(filterList);
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** Name */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammInput
            testID="pile_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => {
              updatePileForm('name', text, true);
            }}
            placeholder={i18n.t('views.pile.pile_registration.insert_pile_name_here')}
            textInputTitle={i18n.t('generics.name')}
            mandatory={true}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Date Picker */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammDatePicker
            testID={'pile_date'}
            containerStyle={UrstammStyleContainer.inputContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            textInputTitle={i18n.t('generics.date')}
            onChange={(dateTime: string) => updatePileForm('creationDate', dateTime, false)}
            icon={{
              showIcon: true,
              iconName: 'calendar',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.pile.pile_registration.insert_pile_date_here')}
            mode={'date'}
          />
        </View>

        {/** Wood type (Other Assortment/ Timber) */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonSelect
            testID={'wood_type_button_select'}
            buttonSelectTitle={i18n.t('views.pile.choose_type_of_pile')}
            options={woodType}
            style={mainGreenButtonSelectActive}
            onSubmit={(data: ButtonSelect) => {
              activeWoodType(data.enum), updatePileForm('woodType', data.enum, true);
            }}
            mandatory={true}
          />

          {/**Validator */}
          {errors['woodType'] && errors['woodType'].length > 0
            ? errors['woodType'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/**Type of other assortment */}
        {otherAssortmentActive && (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammButtonSelect
              testID={'wood_type_button_select'}
              options={otherAssortmentType}
              buttonSelectTitle={i18n.t('generics.type_of_other_assortment')}
              style={mainGreenButtonSelectActive}
              mandatory={true}
              onSubmit={(data: ButtonSelect) => {
                activeOtherAssortmentType(data.enum), updatePileForm('otherAssortmentType', data.enum, true);
              }}
            />
            {/**Validator */}
            {errors['otherAssortmentType'] && errors['otherAssortmentType'].length > 0
              ? errors['otherAssortmentType'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        )}

        {/**Tree species */}
        {otherAssortmentActive && (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammSlideButton
              testID={'button_tree_species'}
              slideButtonList={treeSpeciesList}
              slideButtonTitle={i18n.t('generics.tree_species')}
              slideButtonRotatedTitle={i18n.t('generics.species')}
              mandatory={true}
              buttonPressed={(selected: SlideButtonOption) => {
                setPileTreeSpecie(selected), updatePileForm('species', selected.id, true);
              }}
            />
            {/**Validator */}
            {errors['species'] && errors['species'].length > 0
              ? errors['species'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        )}

        {/** base64File files */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammFilePicker
            testID="pile_photo_picker"
            inputStyle={UrstammStyleContainer.cameraContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            icon={{
              showIcon: true,
              iconName: 'camera',
              iconColor: ColorTheme.lightGreen
            }}
            mediaTypeUploaded={PickerMediaType.Image}
            placeholder={i18n.t('generics.upload_photo_file')}
            textInputTitle={i18n.t('views.pile.pile_registration.pile_photo')}
            uploadType={UploadType.camera}
            mandatory={false}
            deleteBase64={() => updatePileForm('pile', 'base64File', undefined)}
            saveBase64={(base64: string, location: any) => {
              if (!location) {
                AlertHelper.showSimpleAlert('Error', 'cannot_take_gps_coordinates');
                updatePileForm('pile', 'base64File', undefined);
                return;
              }
              updatePhoto(base64, location, false);
            }}
          />
        </View>

        {/** Estimated volume */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="estimated_volume_pile"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: true }}
            keyboardType={'numeric'}
            placeholder={i18n.t('generics.insert_estimated_volume_here')}
            textInputTitle={i18n.t('generics.estimated_volume')}
            onChangeText={(text: string) => updatePileForm('estimatedVolume', text, true)}
            mandatory={pileForm.pile.woodType == PileWoodTypeEnum.OtherAssortment}
          />
          {/**Validator */}
          {pileForm.pile.woodType == PileWoodTypeEnum.OtherAssortment &&
          errors['estimatedVolume'] &&
          errors['estimatedVolume'].length > 0
            ? errors['estimatedVolume'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="pile_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => updatePileForm('comment', text, false)}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/**Create pile */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_pile'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() => props.submitPileForm(pileForm)}
            disabled={saveDisabled || rdxLoaderStatus}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
