import { DateHelper } from '@helpers/DateHelper';
import { NetworkHelper } from '@helpers/NetworkHelper';
import { setDeliverySheetList } from '@redux/features/delivery-sheet/deliverySheetSlice';
import { OfflineSyncClassEnum, OfflineSyncTypeEnum } from '@redux/features/offlineSync/offlineSyncSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AnyAction, Dispatch } from 'redux';
import { sortOfflineList } from '../../utils/classes/UrstammUtilityFunctions';
import { customDeliverySheetResourceApi } from './ApiConfiguration';
import {
  CreateDeliverySheetCustomRequest,
  DeliverySheet,
  DeliverySheetCurrentStateEnum,
  DeliverySheetCurrentUrstammStateEnum,
  DeliverySheetDTO,
  DeliverySheetPhoto,
  DeliverySheetRecordingTypeEnum,
  GetDeliverySheetCustomRequest,
  GetDeliverySheetsByLoggingRequest,
  GetMyDeliverySheetsPageRequest,
  PageDeliverySheetDTO,
  UpdateDeliverySheetCustomRequest,
  UploadDeliverySheetPhotoRequest
} from './generated';

export class FullAvailableDeliverySheetResourceApi {
  /**
   * Get all delivery sheet list for company of logged user
   * @param rdxDSCompleteList Redux list of DeliverySheet
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns Updated list of Delivery sheet or redux list if network is not reachable
   */
  static async getMyDeliverySheetsPage(
    rdxDSCompleteList: DeliverySheetDTO[],
    requestParameters: GetMyDeliverySheetsPageRequest,
    initOverrides?: RequestInit
  ): Promise<PageDeliverySheetDTO> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk) return customDeliverySheetResourceApi.getMyDeliverySheetsPage(requestParameters, initOverrides);
    let pageLogging: PageDeliverySheetDTO = {
      totalElements: rdxDSCompleteList.length,
      content: rdxDSCompleteList,
      totalPages: 1
    };
    return pageLogging;
  }

  /**
   * Get delivery sheet list for given logging
   * @param rdxDSCompleteList Redux list of DeliverySheet
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns Updated list of Delivery sheet or redux list if network is not reachable
   */
  static async getDeliverySheetsByLogging(
    rdxDSCompleteList: DeliverySheetDTO[],
    requestParameters: GetDeliverySheetsByLoggingRequest,
    initOverrides?: RequestInit
  ): Promise<Array<DeliverySheetDTO>> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk) return customDeliverySheetResourceApi.getDeliverySheetsByLogging(requestParameters, initOverrides);
    let deliverySheetList = rdxDSCompleteList.filter(ds => ds.logging?.id == requestParameters.loggingId);
    return sortOfflineList(deliverySheetList, requestParameters)
  }

  /**
   * Get a delivery sheet by id
   * @param rdxDSCompleteList Redux list of DeliverySheet
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides paremeters for call
   * @returns Found DeliverySheetDTO by id
   */
  static async getDeliverySheetCustom(
    rdxDSCompleteList: DeliverySheetDTO[],
    requestParameters: GetDeliverySheetCustomRequest,
    initOverrides?: RequestInit
  ): Promise<DeliverySheetDTO> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk) return customDeliverySheetResourceApi.getDeliverySheetCustom(requestParameters, initOverrides);
    return rdxDSCompleteList.find(ds => ds.id == requestParameters.id) || {};
  }

  /**
   * Create a delivery sheet
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async createDeliverySheetCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: CreateDeliverySheetCustomRequest,
    initOverrides?: RequestInit
  ): Promise<DeliverySheet> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customDeliverySheetResourceApi.createDeliverySheetCustom(requestParameters, initOverrides);
    requestParameters.deliverySheetDTO!.id = DateHelper.nowTimestamp();
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.deliverySheetDTO,
        type: OfflineSyncTypeEnum.INSERT,
        class: OfflineSyncClassEnum.DELIVERYSHEET,
        name: requestParameters.deliverySheetDTO.name
      })
    );
    dispatch(setDeliverySheetList([requestParameters.deliverySheetDTO]));
    return requestParameters.deliverySheetDTO as DeliverySheet;
  }

  /**
   * Update a delivery sheet
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async updateDeliverySheetCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: UpdateDeliverySheetCustomRequest,
    initOverrides?: RequestInit
  ): Promise<DeliverySheet> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customDeliverySheetResourceApi.updateDeliverySheetCustom(requestParameters, initOverrides);
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.deliverySheetDTO,
        type: OfflineSyncTypeEnum.UPDATE,
        class: OfflineSyncClassEnum.DELIVERYSHEET,
        name: requestParameters.deliverySheetDTO.name,
        updateOnly: requestParameters.updateOnly,
      })
    );
    dispatch(setDeliverySheetList([requestParameters.deliverySheetDTO]));
    return {
      ...requestParameters.deliverySheetDTO,
      currentState:
        requestParameters.deliverySheetDTO.currentState != null
          ? DeliverySheetCurrentStateEnum[requestParameters.deliverySheetDTO.currentState!.toString()]
          : undefined,
      currentUrstammState:
        requestParameters.deliverySheetDTO.currentUrstammState != null
          ? DeliverySheetCurrentUrstammStateEnum[requestParameters.deliverySheetDTO.currentUrstammState!.toString()]
          : undefined,
      recordingType:
        requestParameters.deliverySheetDTO.recordingType != null
          ? DeliverySheetRecordingTypeEnum[requestParameters.deliverySheetDTO.recordingType!.toString()!]
          : undefined
    } as DeliverySheet;
  }

  /**
   * Create a delivery sheet photo
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async uploadDeliverySheetPhoto(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: UploadDeliverySheetPhotoRequest,
    initOverrides?: RequestInit
  ): Promise<DeliverySheetPhoto> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customDeliverySheetResourceApi.uploadDeliverySheetPhoto(requestParameters, initOverrides);
    requestParameters.entityWithFileDTODeliverySheetPhoto.entity!.id = DateHelper.nowTimestamp();
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.entityWithFileDTODeliverySheetPhoto,
        type: OfflineSyncTypeEnum.INSERT,
        class: OfflineSyncClassEnum.DELIVERYSHEETPHOTO,
        name: requestParameters.entityWithFileDTODeliverySheetPhoto.entity?.deliverySheet?.name
      })
    );
    // TODO: Add to photo list
    return requestParameters.entityWithFileDTODeliverySheetPhoto.entity!;
  }
}
