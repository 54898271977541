/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TrunkBarkDiscountTable,
} from '../models';
import {
    TrunkBarkDiscountTableFromJSON,
    TrunkBarkDiscountTableToJSON,
} from '../models';

export interface CreateTrunkBarkDiscountTableRequest {
    trunkBarkDiscountTable: TrunkBarkDiscountTable;
}

export interface DeleteTrunkBarkDiscountTableRequest {
    id: number;
}

export interface GetTrunkBarkDiscountTableRequest {
    id: number;
}

export interface PartialUpdateTrunkBarkDiscountTableRequest {
    id: number;
    trunkBarkDiscountTable: TrunkBarkDiscountTable;
}

export interface UpdateTrunkBarkDiscountTableRequest {
    id: number;
    trunkBarkDiscountTable: TrunkBarkDiscountTable;
}

/**
 * 
 */
export class TrunkBarkDiscountTableResourceApi extends runtime.BaseAPI {

    /**
     */
    async createTrunkBarkDiscountTableRaw(requestParameters: CreateTrunkBarkDiscountTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkBarkDiscountTable>> {
        if (requestParameters.trunkBarkDiscountTable === null || requestParameters.trunkBarkDiscountTable === undefined) {
            throw new runtime.RequiredError('trunkBarkDiscountTable','Required parameter requestParameters.trunkBarkDiscountTable was null or undefined when calling createTrunkBarkDiscountTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-bark-discount-tables`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkBarkDiscountTableToJSON(requestParameters.trunkBarkDiscountTable),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkBarkDiscountTableFromJSON(jsonValue));
    }

    /**
     */
    async createTrunkBarkDiscountTable(requestParameters: CreateTrunkBarkDiscountTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkBarkDiscountTable> {
        const response = await this.createTrunkBarkDiscountTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTrunkBarkDiscountTableRaw(requestParameters: DeleteTrunkBarkDiscountTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTrunkBarkDiscountTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-bark-discount-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTrunkBarkDiscountTable(requestParameters: DeleteTrunkBarkDiscountTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTrunkBarkDiscountTableRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllTrunkBarkDiscountTablesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TrunkBarkDiscountTable>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-bark-discount-tables`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrunkBarkDiscountTableFromJSON));
    }

    /**
     */
    async getAllTrunkBarkDiscountTables(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TrunkBarkDiscountTable>> {
        const response = await this.getAllTrunkBarkDiscountTablesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTrunkBarkDiscountTableRaw(requestParameters: GetTrunkBarkDiscountTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkBarkDiscountTable>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTrunkBarkDiscountTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-bark-discount-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkBarkDiscountTableFromJSON(jsonValue));
    }

    /**
     */
    async getTrunkBarkDiscountTable(requestParameters: GetTrunkBarkDiscountTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkBarkDiscountTable> {
        const response = await this.getTrunkBarkDiscountTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateTrunkBarkDiscountTableRaw(requestParameters: PartialUpdateTrunkBarkDiscountTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkBarkDiscountTable>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateTrunkBarkDiscountTable.');
        }

        if (requestParameters.trunkBarkDiscountTable === null || requestParameters.trunkBarkDiscountTable === undefined) {
            throw new runtime.RequiredError('trunkBarkDiscountTable','Required parameter requestParameters.trunkBarkDiscountTable was null or undefined when calling partialUpdateTrunkBarkDiscountTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-bark-discount-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkBarkDiscountTableToJSON(requestParameters.trunkBarkDiscountTable),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkBarkDiscountTableFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateTrunkBarkDiscountTable(requestParameters: PartialUpdateTrunkBarkDiscountTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkBarkDiscountTable> {
        const response = await this.partialUpdateTrunkBarkDiscountTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTrunkBarkDiscountTableRaw(requestParameters: UpdateTrunkBarkDiscountTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkBarkDiscountTable>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTrunkBarkDiscountTable.');
        }

        if (requestParameters.trunkBarkDiscountTable === null || requestParameters.trunkBarkDiscountTable === undefined) {
            throw new runtime.RequiredError('trunkBarkDiscountTable','Required parameter requestParameters.trunkBarkDiscountTable was null or undefined when calling updateTrunkBarkDiscountTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-bark-discount-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkBarkDiscountTableToJSON(requestParameters.trunkBarkDiscountTable),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkBarkDiscountTableFromJSON(jsonValue));
    }

    /**
     */
    async updateTrunkBarkDiscountTable(requestParameters: UpdateTrunkBarkDiscountTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkBarkDiscountTable> {
        const response = await this.updateTrunkBarkDiscountTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
