import * as React from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome5, Fontisto } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
MaterialIcons;
export interface UrstammIconProps {
  showIcon?: boolean;
  iconName?: string;
  iconColor?: string;
  dropIconColor?: string;
}

export default function UrstammIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'] | any;
  color: string;
  size?: number;
  type?: string;
}) {
  if (props.type == 'FontAwesome5') {
    return (
      <FontAwesome5
        size={props.size ? props.size : 16}
        style={{ justifyContent: 'flex-start' }}
        {...props}></FontAwesome5>
    );
  } else {
    return (
      <FontAwesome
        size={props.size ? props.size : 16}
        style={{ justifyContent: 'flex-start' }}
        {...props}></FontAwesome>
    );
  }
}
