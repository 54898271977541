import React, { useEffect, useState } from 'react';
import { Switch } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

export interface UrstammSwitchStyleProps {
  trackColor: { false: string; true: string; indeterminate: string };
  thumbColor: { enabled: string; disabled: string };
  ios_backGroundColor: string;
}

export default function UrstammSwitch(props: {
  style: UrstammSwitchStyleProps;
  valueChanged: (status: boolean) => void;
  pressed: boolean;
  value: boolean;
  disabled: boolean;
  indeterminate: boolean;
  switchConfirm?: boolean;
}) {
  const [isEnabled, setIsEnabled] = useStateWithCallbackLazy(false);

  useEffect(() => {
    setIsEnabled(props.value, () => {});
  }, [props.value]);

  return (
    <Switch
      trackColor={{
        false: props.indeterminate ? props.style.trackColor.indeterminate : props.style.trackColor.false,
        true: props.style.trackColor.true
      }}
      ios_backgroundColor={props.style.ios_backGroundColor}
      onValueChange={() => {
        setIsEnabled(!isEnabled, stateUpdated => {
          props.valueChanged(stateUpdated);
        });
      }}
      thumbColor={isEnabled ? props.style.thumbColor.enabled : props.style.thumbColor.disabled}
      value={
        props.switchConfirm == null
          ? props.indeterminate
            ? false
            : isEnabled
          : props.switchConfirm
          ? props.indeterminate
            ? false
            : isEnabled
          : false
      }
      //value={props.indeterminate ? false : isEnabled}
      disabled={props.disabled}
    />
  );
}
