/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProcessorShippingNoteState,
} from '../models';
import {
    ProcessorShippingNoteStateFromJSON,
    ProcessorShippingNoteStateToJSON,
} from '../models';

export interface CreateProcessorShippingNoteStateRequest {
    processorShippingNoteState: ProcessorShippingNoteState;
}

export interface DeleteProcessorShippingNoteStateRequest {
    id: number;
}

export interface GetProcessorShippingNoteStateRequest {
    id: number;
}

export interface PartialUpdateProcessorShippingNoteStateRequest {
    id: number;
    processorShippingNoteState: ProcessorShippingNoteState;
}

export interface UpdateProcessorShippingNoteStateRequest {
    id: number;
    processorShippingNoteState: ProcessorShippingNoteState;
}

/**
 * 
 */
export class ProcessorShippingNoteStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createProcessorShippingNoteStateRaw(requestParameters: CreateProcessorShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNoteState>> {
        if (requestParameters.processorShippingNoteState === null || requestParameters.processorShippingNoteState === undefined) {
            throw new runtime.RequiredError('processorShippingNoteState','Required parameter requestParameters.processorShippingNoteState was null or undefined when calling createProcessorShippingNoteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-shipping-note-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorShippingNoteStateToJSON(requestParameters.processorShippingNoteState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteStateFromJSON(jsonValue));
    }

    /**
     */
    async createProcessorShippingNoteState(requestParameters: CreateProcessorShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNoteState> {
        const response = await this.createProcessorShippingNoteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProcessorShippingNoteStateRaw(requestParameters: DeleteProcessorShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProcessorShippingNoteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-shipping-note-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProcessorShippingNoteState(requestParameters: DeleteProcessorShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProcessorShippingNoteStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProcessorShippingNoteStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProcessorShippingNoteState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-shipping-note-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProcessorShippingNoteStateFromJSON));
    }

    /**
     */
    async getAllProcessorShippingNoteStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProcessorShippingNoteState>> {
        const response = await this.getAllProcessorShippingNoteStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProcessorShippingNoteStateRaw(requestParameters: GetProcessorShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNoteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProcessorShippingNoteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-shipping-note-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteStateFromJSON(jsonValue));
    }

    /**
     */
    async getProcessorShippingNoteState(requestParameters: GetProcessorShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNoteState> {
        const response = await this.getProcessorShippingNoteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateProcessorShippingNoteStateRaw(requestParameters: PartialUpdateProcessorShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNoteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateProcessorShippingNoteState.');
        }

        if (requestParameters.processorShippingNoteState === null || requestParameters.processorShippingNoteState === undefined) {
            throw new runtime.RequiredError('processorShippingNoteState','Required parameter requestParameters.processorShippingNoteState was null or undefined when calling partialUpdateProcessorShippingNoteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-shipping-note-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorShippingNoteStateToJSON(requestParameters.processorShippingNoteState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateProcessorShippingNoteState(requestParameters: PartialUpdateProcessorShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNoteState> {
        const response = await this.partialUpdateProcessorShippingNoteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProcessorShippingNoteStateRaw(requestParameters: UpdateProcessorShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNoteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProcessorShippingNoteState.');
        }

        if (requestParameters.processorShippingNoteState === null || requestParameters.processorShippingNoteState === undefined) {
            throw new runtime.RequiredError('processorShippingNoteState','Required parameter requestParameters.processorShippingNoteState was null or undefined when calling updateProcessorShippingNoteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-shipping-note-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorShippingNoteStateToJSON(requestParameters.processorShippingNoteState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteStateFromJSON(jsonValue));
    }

    /**
     */
    async updateProcessorShippingNoteState(requestParameters: UpdateProcessorShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNoteState> {
        const response = await this.updateProcessorShippingNoteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
