/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Batch } from './Batch';
import {
    BatchFromJSON,
    BatchFromJSONTyped,
    BatchToJSON,
} from './Batch';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { CompanyPacketWoodQuality } from './CompanyPacketWoodQuality';
import {
    CompanyPacketWoodQualityFromJSON,
    CompanyPacketWoodQualityFromJSONTyped,
    CompanyPacketWoodQualityToJSON,
} from './CompanyPacketWoodQuality';
import type { CompanyProductType } from './CompanyProductType';
import {
    CompanyProductTypeFromJSON,
    CompanyProductTypeFromJSONTyped,
    CompanyProductTypeToJSON,
} from './CompanyProductType';
import type { CompanySurface } from './CompanySurface';
import {
    CompanySurfaceFromJSON,
    CompanySurfaceFromJSONTyped,
    CompanySurfaceToJSON,
} from './CompanySurface';
import type { DeliverySheet } from './DeliverySheet';
import {
    DeliverySheetFromJSON,
    DeliverySheetFromJSONTyped,
    DeliverySheetToJSON,
} from './DeliverySheet';
import type { PacketCertification } from './PacketCertification';
import {
    PacketCertificationFromJSON,
    PacketCertificationFromJSONTyped,
    PacketCertificationToJSON,
} from './PacketCertification';
import type { PacketSpecies } from './PacketSpecies';
import {
    PacketSpeciesFromJSON,
    PacketSpeciesFromJSONTyped,
    PacketSpeciesToJSON,
} from './PacketSpecies';
import type { PacketState } from './PacketState';
import {
    PacketStateFromJSON,
    PacketStateFromJSONTyped,
    PacketStateToJSON,
} from './PacketState';
import type { Trunk } from './Trunk';
import {
    TrunkFromJSON,
    TrunkFromJSONTyped,
    TrunkToJSON,
} from './Trunk';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface PacketDTO
 */
export interface PacketDTO {
    /**
     * 
     * @type {number}
     * @memberof PacketDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    uniqueIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof PacketDTO
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof PacketDTO
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof PacketDTO
     */
    width?: number;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    discount?: string;
    /**
     * 
     * @type {number}
     * @memberof PacketDTO
     */
    thickness?: number;
    /**
     * 
     * @type {number}
     * @memberof PacketDTO
     */
    amountOfProducts?: number;
    /**
     * 
     * @type {number}
     * @memberof PacketDTO
     */
    sumCubage?: number;
    /**
     * 
     * @type {number}
     * @memberof PacketDTO
     */
    dryness?: number;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    planningDimension?: string;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    processorCustomName?: string;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    currentState?: PacketDTOCurrentStateEnum;
    /**
     * 
     * @type {number}
     * @memberof PacketDTO
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof PacketDTO
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    photoFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    photoFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    photoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    photoMd5?: string;
    /**
     * 
     * @type {User}
     * @memberof PacketDTO
     */
    photoAuthor?: User;
    /**
     * 
     * @type {string}
     * @memberof PacketDTO
     */
    uuid?: string;
    /**
     * 
     * @type {Batch}
     * @memberof PacketDTO
     */
    batch?: Batch;
    /**
     * 
     * @type {Trunk}
     * @memberof PacketDTO
     */
    specificTrunk?: Trunk;
    /**
     * 
     * @type {Company}
     * @memberof PacketDTO
     */
    processor?: Company;
    /**
     * 
     * @type {CompanySurface}
     * @memberof PacketDTO
     */
    surface?: CompanySurface;
    /**
     * 
     * @type {CompanyPacketWoodQuality}
     * @memberof PacketDTO
     */
    woodQuality?: CompanyPacketWoodQuality;
    /**
     * 
     * @type {CompanyProductType}
     * @memberof PacketDTO
     */
    productType?: CompanyProductType;
    /**
     * 
     * @type {Set<DeliverySheet>}
     * @memberof PacketDTO
     */
    deliverySheets?: Set<DeliverySheet>;
    /**
     * 
     * @type {Set<Trunk>}
     * @memberof PacketDTO
     */
    trunks?: Set<Trunk>;
    /**
     * 
     * @type {Set<PacketCertification>}
     * @memberof PacketDTO
     */
    packetCertifications?: Set<PacketCertification>;
    /**
     * 
     * @type {Set<PacketState>}
     * @memberof PacketDTO
     */
    packetStates?: Set<PacketState>;
    /**
     * 
     * @type {Set<PacketSpecies>}
     * @memberof PacketDTO
     */
    packetSpecies?: Set<PacketSpecies>;
    /**
     * 
     * @type {Date}
     * @memberof PacketDTO
     */
    photoCreationDate?: Date;
}


/**
 * @export
 */
export const PacketDTOCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED',
    SentInShippingNote: 'SENT_IN_SHIPPING_NOTE'
} as const;
export type PacketDTOCurrentStateEnum = typeof PacketDTOCurrentStateEnum[keyof typeof PacketDTOCurrentStateEnum];


/**
 * Check if a given object implements the PacketDTO interface.
 */
export function instanceOfPacketDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PacketDTOFromJSON(json: any): PacketDTO {
    return PacketDTOFromJSONTyped(json, false);
}

export function PacketDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PacketDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'uniqueIdentifier': !exists(json, 'uniqueIdentifier') ? undefined : json['uniqueIdentifier'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'length': !exists(json, 'length') ? undefined : json['length'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'thickness': !exists(json, 'thickness') ? undefined : json['thickness'],
        'amountOfProducts': !exists(json, 'amountOfProducts') ? undefined : json['amountOfProducts'],
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'dryness': !exists(json, 'dryness') ? undefined : json['dryness'],
        'planningDimension': !exists(json, 'planningDimension') ? undefined : json['planningDimension'],
        'processorCustomName': !exists(json, 'processorCustomName') ? undefined : json['processorCustomName'],
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'photoFilename': !exists(json, 'photoFilename') ? undefined : json['photoFilename'],
        'photoFilepath': !exists(json, 'photoFilepath') ? undefined : json['photoFilepath'],
        'photoUrl': !exists(json, 'photoUrl') ? undefined : json['photoUrl'],
        'photoMd5': !exists(json, 'photoMd5') ? undefined : json['photoMd5'],
        'photoAuthor': !exists(json, 'photoAuthor') ? undefined : UserFromJSON(json['photoAuthor']),
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'batch': !exists(json, 'batch') ? undefined : BatchFromJSON(json['batch']),
        'specificTrunk': !exists(json, 'specificTrunk') ? undefined : TrunkFromJSON(json['specificTrunk']),
        'processor': !exists(json, 'processor') ? undefined : CompanyFromJSON(json['processor']),
        'surface': !exists(json, 'surface') ? undefined : CompanySurfaceFromJSON(json['surface']),
        'woodQuality': !exists(json, 'woodQuality') ? undefined : CompanyPacketWoodQualityFromJSON(json['woodQuality']),
        'productType': !exists(json, 'productType') ? undefined : CompanyProductTypeFromJSON(json['productType']),
        'deliverySheets': !exists(json, 'deliverySheets') ? undefined : (new Set((json['deliverySheets'] as Array<any>).map(DeliverySheetFromJSON))),
        'trunks': !exists(json, 'trunks') ? undefined : (new Set((json['trunks'] as Array<any>).map(TrunkFromJSON))),
        'packetCertifications': !exists(json, 'packetCertifications') ? undefined : (new Set((json['packetCertifications'] as Array<any>).map(PacketCertificationFromJSON))),
        'packetStates': !exists(json, 'packetStates') ? undefined : (new Set((json['packetStates'] as Array<any>).map(PacketStateFromJSON))),
        'packetSpecies': !exists(json, 'packetSpecies') ? undefined : (new Set((json['packetSpecies'] as Array<any>).map(PacketSpeciesFromJSON))),
    };
}

export function PacketDTOToJSON(value?: PacketDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'uniqueIdentifier': value.uniqueIdentifier,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'length': value.length,
        'width': value.width,
        'discount': value.discount,
        'thickness': value.thickness,
        'amountOfProducts': value.amountOfProducts,
        'sumCubage': value.sumCubage,
        'dryness': value.dryness,
        'planningDimension': value.planningDimension,
        'processorCustomName': value.processorCustomName,
        'currentState': value.currentState,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'photoFilename': value.photoFilename,
        'photoFilepath': value.photoFilepath,
        'photoUrl': value.photoUrl,
        'photoMd5': value.photoMd5,
        'photoAuthor': UserToJSON(value.photoAuthor),
        'uuid': value.uuid,
        'batch': BatchToJSON(value.batch),
        'specificTrunk': TrunkToJSON(value.specificTrunk),
        'processor': CompanyToJSON(value.processor),
        'surface': CompanySurfaceToJSON(value.surface),
        'woodQuality': CompanyPacketWoodQualityToJSON(value.woodQuality),
        'productType': CompanyProductTypeToJSON(value.productType),
        'deliverySheets': value.deliverySheets === undefined ? undefined : (Array.from(value.deliverySheets as Set<any>).map(DeliverySheetToJSON)),
        'trunks': value.trunks === undefined ? undefined : (Array.from(value.trunks as Set<any>).map(TrunkToJSON)),
        'packetCertifications': value.packetCertifications === undefined ? undefined : (Array.from(value.packetCertifications as Set<any>).map(PacketCertificationToJSON)),
        'packetStates': value.packetStates === undefined ? undefined : (Array.from(value.packetStates as Set<any>).map(PacketStateToJSON)),
        'packetSpecies': value.packetSpecies === undefined ? undefined : (Array.from(value.packetSpecies as Set<any>).map(PacketSpeciesToJSON)),
        'photoCreationDate': value.photoCreationDate === undefined ? undefined : value.photoCreationDate,
    };
}

