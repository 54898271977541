import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { OtherAssortment } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface OtherAssortmentState {
  list: OtherAssortment[];
  completeList: OtherAssortment[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: OtherAssortmentState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const otherAssortment = createSlice({
  name: 'otherAssortmentList',
  initialState,
  reducers: {
    setOtherAssortmentList: (state, action) => {
      let otherAssortmentList = { ...state }.list;
      let otherAssortmentCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(otherAssortmentList, element);
        pushElementOrReplaceById(otherAssortmentCompleteList, element);
      });

      state.list = otherAssortmentList;
      state.completeList = otherAssortmentCompleteList;
    },
    setCompleteOtherAssortmentList: (state, action) => {
      // Repetition
      let otherAssortmentCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(otherAssortmentCompleteList, element);
      });

      state.completeList = otherAssortmentCompleteList;
    },
    removeOtherAssortmentElement: (state, action) => {
      let otherAssortmentList = { ...state }.list;
      let otherAssortmentCompleteList = { ...state }.completeList;

      let element: OtherAssortment = action.payload;
      otherAssortmentList = otherAssortmentList.filter(e => e.id != element.id);
      otherAssortmentCompleteList = otherAssortmentCompleteList.filter(e => e.id != element.id);

      state.list = otherAssortmentList;
      state.completeList = otherAssortmentCompleteList;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setOtherAssortmentListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setOtherAssortmentPage: (state, action) => {
      state.page = action.payload;
    },
    resetOtherAssortmentList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearOtherAssortmentList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setOtherAssortmentList,
  setCompleteOtherAssortmentList,
  removeOtherAssortmentElement,
  resetOtherAssortmentList,
  setOtherAssortmentPage,
  setTotalPages,
  setOtherAssortmentListSort,
  clearOtherAssortmentList
} = otherAssortment.actions;

export default otherAssortment.reducer;
