import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  ShippingNote,
  Batch,
  BatchDTO,
  DeliverySheetDTO,
  Logging,
  Packet,
  Pile,
  Plot,
  Product,
  Project,
  TreeDTO,
  Trunk,
  ProcessorShippingNote,
  ProcessorBatch,
  ProcessorBatchDTO,
  PacketDTO,
  ProductDTO,
} from '@services/apis/generated';
import {
  RouteBatchDetails,
  RouteBatchList,
  RouteBatchRegistration,
  RouteShippingNoteDetails,
  RouteShippingNoteList,
  RouteShippingNoteRegistration,
  RouteChangePassword,
  RouteDeliverySheetDetails,
  RouteDeliverySheetList,
  RouteDeliverySheetRegistration,
  RouteLoggingDetails,
  RouteLoggingList,
  RouteLoggingRegistration,
  RouteLogin,
  RoutePacketDetails,
  RoutePacketRegistration,
  RoutePileDetails,
  RoutePileList,
  RoutePileRegistration,
  RoutePlotDetails,
  RoutePlotList,
  RoutePlotRegistration,
  RouteResetPassword,
  RouteSignup,
  RouteTreeDetails,
  RouteTreeList,
  RouteTrunkDetails,
  RouteTrunkList,
  RouteTrunkRegistration,
  RoutTreeRegistration,
  RouteProcessorShippingNoteRegistration,
  RouteProcessorShippingNoteDetails,
  RouteProcessorBatchDetails,
  RouteProcessorBatchList,
  RouteProcessorBatchRegistration,
  RouteProductDetails,
  RouteProductRegistration,
  RouteOutgoingProcessorShippingNoteList,
  RouteIncomingProcessorShippingNoteList,
  RouteProjectRegistration,
  RouteProjectList,
  RouteProjectDetails,
} from './UrstammNavigationRoutes';

export type RootStackParamList = { // TODO - commentare sto codice e rimuovere sto scempio di any!
  BatchDetails: any;
  BatchList: any;
  BatchRegistration: any;
  ShippingNoteDetails: any;
  ShippingNoteList: any;
  ProcessorBatchDetails: any;
  ProcessorBatchList: any;
  ProcessorBatchRegistration: any;
  IncomingProcessorShippingNoteList: any;
  OutgoingProcessorShippingNoteList: any;
  ProcessorShippingNoteDetails: any;
  ProcessorShippingNoteRegistration: any;
  ShippingNoteRegistration: any;
  RouteShippingNoteDetails: any;
  RouteShippingNoteList: any;
  RouteShippingNoteRegistration: any;
  ChangePassword: any;
  DeliverySheetDetails: any;
  DeliverySheetList: any;
  DeliverySheetRegistration: any;
  LoggingDetails: any;
  LoggingList: any;
  LoggingRegistration: any;
  Login: any;
  NotFound: any;
  OfflineSyncList: any;
  PacketDetails: any;
  PacketRegistration: any;
  PacketList: any;
  ProjectDetails: any;
  ProjectRegistration: any;
  ProjectList: any;
  ProductDetails: any;
  ProductRegistration: any;
  ProductList: any;
  PileDetails: any;
  PileList: any;
  PileRegistration: any;
  PlotDetails: any;
  PlotList: any;
  PlotRegistration: any;
  ResetPassword: any;
  Signup: any;
  TreeDetails: any;
  TreeList: any;
  TreeRegistration: any;
  TrunkDetails: any;
  TrunkList: any;
  TrunkRegistration: any;
};
export type RootStackScreenProps<Screen extends keyof RootStackParamList> = NativeStackScreenProps<
  RootStackParamList,
  Screen
>;
export class UrstammNavigationHelper {
  /**
   * Navigate to Signup view
   * @param navigation
   * @param type
   */
  static navigateToSignup(navigation: NativeStackNavigationProp<RootStackParamList>) {
    navigation.navigate(RouteSignup);
  }

  /**
   * Navigate to ResetPassword view
   * @param navigation
   * @param type
   */
  static navigateToResetPassword(navigation: NativeStackNavigationProp<RootStackParamList>) {
    navigation.navigate(RouteResetPassword);
  }

  /**
   * Navigate to ResetPassword view
   * @param navigation
   * @param type
   */
  static navigateToChangePassword(navigation: NativeStackNavigationProp<RootStackParamList>) {
    navigation.navigate(RouteChangePassword);
  }

  /**
   * Navigate to Login view
   * @param navigation
   * @param type
   */
  static navigateToLogin(navigation: NativeStackNavigationProp<RootStackParamList>) {
    navigation.navigate(RouteLogin);
  }

  /**
   * Navigate to logging registration view
   * @param navigation
   * @param type
   */
  static navigateToLoggingRegistration(navigation: NativeStackNavigationProp<RootStackParamList>) {
    navigation.navigate(RouteLoggingRegistration);
  }

  /**
   * Navigate to logging list view
   * @param navigation
   * @param type
   */
  static navigateToLoggingList(navigation: NativeStackNavigationProp<RootStackParamList>, refresh?: boolean) {
    navigation.navigate(RouteLoggingList, { refreshList: refresh });
  }

  /**
   * Navigate to logging details view
   * @param navigation
   * @param type
   */
  static navigateToLoggingDetails(navigation: NativeStackNavigationProp<RootStackParamList>, loggingSelected: Logging) {
    navigation.navigate(RouteLoggingDetails, { logging: loggingSelected });
  }

  /**
   * Navigate to Plot list view
   * @param navigation
   * @param type
   */
  static navigateToPlotList(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    loggingSelected?: Logging,
    refresh?: boolean
  ) {
    navigation.navigate(RoutePlotList, {
      loggingSelected: loggingSelected,
      refreshList: refresh
    });
  }

  /**
   * Navigate to Plot details view
   * @param navigation
   * @param type
   */
  static navigateToPlotDetails(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    plot: Plot,
    logging?: Logging
  ) {
    navigation.navigate(RoutePlotDetails, {
      plotSelected: plot,
      loggingSelected: logging
    });
  }

  /**
   * Navigate to Plot registration view
   * @param navigation
   * @param type
   */
  static navigateToPlotRegistration(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    loggingSelected: Logging
  ) {
    navigation.navigate(RoutePlotRegistration, {
      loggingSelected: loggingSelected
    });
  }

  /**
   * Navigate to Pile list view
   * @param navigation
   * @param type
   */
  static navigateToPileList(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    loggingSelected?: Logging,
    refresh?: boolean
  ) {
    navigation.navigate(RoutePileList, {
      loggingSelected: loggingSelected,
      refreshList: refresh
    });
  }

  /**
   * Navigate to Pile details view
   * @param navigation
   * @param type
   */
  static navigateToPileDetails(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    pile: Pile,
    logging?: Logging
  ) {
    navigation.navigate(RoutePileDetails, {
      pileSelected: pile,
      loggingSelected: logging
    });
  }

  /**
   * Navigate to Pile registration view
   * @param navigation
   * @param type
   */
  static navigateToPileRegistration(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    loggingSelected: Logging
  ) {
    navigation.navigate(RoutePileRegistration, {
      loggingSelected: loggingSelected
    });
  }
  /**
   * Navigate to Tree list view
   * @param navigation
   * @param type
   */
  static navigateToTreeList(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    loggingSelected: Logging,
    refresh?: boolean
  ) {
    navigation.navigate(RouteTreeList, {
      loggingSelected: loggingSelected,
      refreshList: refresh
    });
  }

  /**
   * Navigate to Tree registration view
   * @param navigation
   * @param type
   */
  static navigateToTreeRegistration(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    loggingSelected: Logging
  ) {
    navigation.navigate(RoutTreeRegistration, {
      loggingSelected: loggingSelected
    });
  }

  /**
   * Navigate to Tree details view
   * @param navigation
   * @param type
   */
  static navigateToTreeDetails(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    tree: TreeDTO,
    logging?: Logging
  ) {
    navigation.navigate(RouteTreeDetails, {
      treeSelected: tree,
      loggingSelected: logging
    });
  }

  /**
   * Navigate to DeliverySheet list view
   * @param navigation
   * @param type
   */
  static navigateToDeliverySheetList(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    loggingSelected?: Logging,
    refresh?: boolean
  ) {
    navigation.navigate(RouteDeliverySheetList, {
      loggingSelected: loggingSelected,
      refreshList: refresh
    });
  }

  /**
   * Navigate to DeliverySheet registration view
   * @param navigation
   * @param type
   */
  static navigateToDeliverySheetRegistration(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    loggingSelected?: Logging
  ) {
    navigation.navigate(RouteDeliverySheetRegistration, {
      loggingSelected: loggingSelected
    });
  }

  /**
   * Navigate to DeliverySheet details view
   * @param navigation
   * @param type
   */
  static navigateToDeliverySheetDetails(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    deliverySheetSelected?: DeliverySheetDTO,
    refresh?: boolean
  ) {
    navigation.navigate(RouteDeliverySheetDetails, {
      deliverySheet: deliverySheetSelected,
      refreshList: refresh
    });
  }

  /**UNUSED!!! (trunk list is in the DS details)
   * Navigate to Trunk list view
   * @param navigation
   * @param type
   */
  static navigateToTrunkList(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    deliverySheetSelected?: DeliverySheetDTO,
    refresh?: boolean,
    editedTrunks?: Trunk[]
  ) {
    navigation.navigate(RouteTrunkList, {
      deliverySheetSelected: deliverySheetSelected,
      refreshList: refresh,
      editedTrunks: editedTrunks
    });
  }

  /**
   * Navigate to Trunk registration view
   * @param navigation
   * @param type
   */
  static navigateToTrunkRegistration(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    deliverySheetSelected?: DeliverySheetDTO,
    latestTrunk?: Trunk
  ) {
    navigation.navigate(RouteTrunkRegistration, {
      deliverySheetSelected: deliverySheetSelected,
      latestTrunk: latestTrunk
    });
  }

  /**
   * Navigate to Trunk details view
   * @param navigation
   * @param type
   */
  static navigateToTrunkDetails(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    deliverySheetSelected: DeliverySheetDTO,
    trunkSelected: Trunk
  ) {
    navigation.navigate(RouteTrunkDetails, {
      deliverySheetSelected: deliverySheetSelected,
      trunkSelected: trunkSelected
    });
  }


  /**
   * Navigate to Batch list view
   * @param navigation
   * @param type
   */
  static navigateToBatchList(navigation: NativeStackNavigationProp<RootStackParamList>, refresh?: boolean) {
    navigation.navigate(RouteBatchList, { refreshList: refresh });
  }

  /**
   * Navigate to Batch registration view
   * @param navigation
   * @param type
   */
  static navigateToBatchRegistration(navigation: NativeStackNavigationProp<RootStackParamList>, batchList: Batch[], totalBatch: number) {
    navigation.navigate(RouteBatchRegistration, { batchList: batchList, totalBatch: totalBatch });
  }

  /**
   * Navigate to Batch details view
   * @param navigation
   * @param type
   */
  static navigateToBatchDetails(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    batchSelected?: Batch | BatchDTO,
    refresh?: boolean
  ) {
    navigation.navigate(RouteBatchDetails, {
      batchSelected: batchSelected,
      refreshList: refresh
    });
  }


  /**
   * Navigate to ShippingNote list view
   * @param navigation
   * @param type
   */
   static navigateToShippingNoteList(navigation: NativeStackNavigationProp<RootStackParamList>, refresh?: boolean) {
    navigation.navigate(RouteShippingNoteList, { refreshList: refresh });
  }

  /**
   * Navigate to ShippingNote registration view
   * @param navigation
   * @param type
   */
  static navigateToShippingNoteRegistration(navigation: NativeStackNavigationProp<RootStackParamList>, shippingNoteList: ShippingNote[]) {
    navigation.navigate(RouteShippingNoteRegistration, { shippingNoteList: shippingNoteList });
  }

  /**
   * Navigate to ProcessorShippingNote registration view
   * @param navigation
   * @param type
   */
   static navigateToProcessorShippingNoteRegistration(navigation: NativeStackNavigationProp<RootStackParamList>, processorShippingNoteList: ProcessorShippingNote[]) {
    navigation.navigate(RouteProcessorShippingNoteRegistration, { processorShippingNoteList: processorShippingNoteList });
  }

  /**
   * Navigate to ProcessorShippingNote list view
   * @param navigation
   * @param type
   */
   static navigateToOutgoingProcessorShippingNoteList(navigation: NativeStackNavigationProp<RootStackParamList>, refresh?: boolean) {
    navigation.navigate(RouteOutgoingProcessorShippingNoteList, { refreshList: refresh });
  }

  /**
   * Navigate to ProcessorShippingNote list view
   * @param navigation
   * @param type
   */
   static navigateToIncomingProcessorShippingNoteList(navigation: NativeStackNavigationProp<RootStackParamList>, refresh?: boolean) {
    navigation.navigate(RouteIncomingProcessorShippingNoteList, { refreshList: refresh });
  }

  /**
   * Navigate to ShippingNote details view
   * @param navigation
   * @param type
   */
  static navigateToShippingNoteDetails(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    shippingNoteSelected?: ShippingNote, //| BatchDTO,
    refresh?: boolean
  ) {
    navigation.navigate(RouteShippingNoteDetails, {
      shippingNoteSelected: shippingNoteSelected,
      refreshList: refresh
    });
  }

  /**
   * Navigate to ProcessorShippingNote details view
   * @param navigation
   * @param type
   */
   static navigateToProcessorShippingNoteDetails(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    processorShippingNoteSelected?: ProcessorShippingNote, //| BatchDTO,
    refresh?: boolean
  ) {
    navigation.navigate(RouteProcessorShippingNoteDetails, {
      processorShippingNoteSelected: processorShippingNoteSelected,
      refreshList: refresh
    });
  }

  /**
   * Navigate to Packet registration view
   * @param navigation
   * @param type
   */
  static navigateToPacketRegistration(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    batchSelected?: BatchDTO,
    cloneFromPacket?: Packet | PacketDTO
  ) {
    navigation.navigate(RoutePacketRegistration, {
      batchSelected: batchSelected,
      cloneFromPacket
    });
  }

  /**
   * Navigate to Packet details view
   * @param navigation
   * @param type
   */
  static navigateToPacketDetails(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    batchSelected?: BatchDTO,
    packetSelected?: Packet | PacketDTO,
    params?: any
  ) {
    navigation.navigate(RoutePacketDetails, {
      ...(params || {}),
      batchSelected: batchSelected,
      packetSelected: packetSelected
    });
  }

  /**
   * Navigate to ProcessorBatch list view
   * @param navigation
   * @param type
   */
  static navigateToProcessorBatchList(navigation: NativeStackNavigationProp<RootStackParamList>, refresh?: boolean) {
    navigation.navigate(RouteProcessorBatchList, { refreshList: refresh });
  }

  /**
   * Navigate to ProcessorBatch registration view
   * @param navigation
   * @param type
   */
  static navigateToProcessorBatchRegistration(navigation: NativeStackNavigationProp<RootStackParamList>, processorBatchList: ProcessorBatch[], totalBatch: number) {
    navigation.navigate(RouteProcessorBatchRegistration, { processorBatchList: processorBatchList, totalBatch: totalBatch });
  }

  /**
   * Navigate to ProcessorBatch details view
   * @param navigation
   * @param type
   */
  static navigateToProcessorBatchDetails(
    navigation: NativeStackNavigationProp<RootStackParamList>,
    processorBatchSelected?: ProcessorBatch | ProcessorBatchDTO,
    refresh?: boolean
  ) {
    navigation.navigate(RouteProcessorBatchDetails, {
      processorBatchSelected: processorBatchSelected,
      refreshList: refresh
    });
  }

    /**
   * Navigate to Product registration view
   * @param navigation
   * @param type
   */
     static navigateToProductRegistration(
      navigation: NativeStackNavigationProp<RootStackParamList>,
      processorBatchSelected?: ProcessorBatchDTO,
      cloneFromProduct?: Product | ProductDTO
    ) {
      navigation.navigate(RouteProductRegistration, {
        processorBatchSelected: processorBatchSelected,
        cloneFromProduct
      });
    }

  /**
   * Navigate to Product details view
   * @param navigation
   * @param type
   */
    static navigateToProductDetails(
      navigation: NativeStackNavigationProp<RootStackParamList>,
      processorBatchSelected?: ProcessorBatchDTO,
      productSelected?: Product,
      params?: any
    ) {
      navigation.navigate(RouteProductDetails, {
        ...(params || {}),
        processorBatchSelected: processorBatchSelected,
        productSelected: productSelected,
      });
    }

  /**
   * Navigate to ProcessorBatch list view
   * @param navigation
   * @param type
   */
  static navigateToProjectList(navigation: NativeStackNavigationProp<RootStackParamList>, refresh?: boolean) {
    navigation.navigate(RouteProjectList, { refreshList: refresh });
  }

  /**
   * Navigate to Project details view
   * @param navigation
   * @param project
   */
    static navigateToProjectDetails(
        navigation: NativeStackNavigationProp<RootStackParamList>,
        projectSelected: Project
    ){
      navigation.navigate(RouteProjectDetails, { projectSelected });
    }

  /**
   * Navigate to Project details view
   * @param navigation
   * @param project
   */
  static navigateToProjectRegistration(navigation: NativeStackNavigationProp<RootStackParamList>, refresh?: boolean){
    navigation.navigate(RouteProjectRegistration, { refreshList: refresh });
  }
}
