import { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { createSlice } from '@reduxjs/toolkit';

export interface DeliverySheetFilterState {
  value: SlideButtonOption[];
}

const initialState: DeliverySheetFilterState = {
  value: []
};

export const deliverySheetFilter = createSlice({
  name: 'deliverySheetFilter',
  initialState,
  reducers: {
    setDsFilter: (state, action) => {
      state.value = action.payload;
    },
    setDsFilterList: (state, action) => {
      state.value = action.payload;
    },
    selectDsFilter: (state, action) => {
      let selected = action.payload;
      let filterList = { ...state }.value;
      if (filterList) {
        for (let elem of filterList) {
          elem.active = elem.id == selected.id;
        }
        state.value = filterList;
      }
    },
    reset: state => {
      initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setDsFilter, setDsFilterList, selectDsFilter, reset } = deliverySheetFilter.actions;

export default deliverySheetFilter.reducer;
