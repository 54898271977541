/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TrunkChangeHistory,
} from '../models';
import {
    TrunkChangeHistoryFromJSON,
    TrunkChangeHistoryToJSON,
} from '../models';

export interface CreateTrunkChangeHistoryRequest {
    trunkChangeHistory: TrunkChangeHistory;
}

export interface DeleteTrunkChangeHistoryRequest {
    id: number;
}

export interface GetTrunkChangeHistoryRequest {
    id: number;
}

export interface PartialUpdateTrunkChangeHistoryRequest {
    id: number;
    trunkChangeHistory: TrunkChangeHistory;
}

export interface UpdateTrunkChangeHistoryRequest {
    id: number;
    trunkChangeHistory: TrunkChangeHistory;
}

/**
 * 
 */
export class TrunkChangeHistoryResourceApi extends runtime.BaseAPI {

    /**
     */
    async createTrunkChangeHistoryRaw(requestParameters: CreateTrunkChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkChangeHistory>> {
        if (requestParameters.trunkChangeHistory === null || requestParameters.trunkChangeHistory === undefined) {
            throw new runtime.RequiredError('trunkChangeHistory','Required parameter requestParameters.trunkChangeHistory was null or undefined when calling createTrunkChangeHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-change-histories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkChangeHistoryToJSON(requestParameters.trunkChangeHistory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkChangeHistoryFromJSON(jsonValue));
    }

    /**
     */
    async createTrunkChangeHistory(requestParameters: CreateTrunkChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkChangeHistory> {
        const response = await this.createTrunkChangeHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTrunkChangeHistoryRaw(requestParameters: DeleteTrunkChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTrunkChangeHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-change-histories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTrunkChangeHistory(requestParameters: DeleteTrunkChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTrunkChangeHistoryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllTrunkChangeHistoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TrunkChangeHistory>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-change-histories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrunkChangeHistoryFromJSON));
    }

    /**
     */
    async getAllTrunkChangeHistories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TrunkChangeHistory>> {
        const response = await this.getAllTrunkChangeHistoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTrunkChangeHistoryRaw(requestParameters: GetTrunkChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkChangeHistory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTrunkChangeHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-change-histories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkChangeHistoryFromJSON(jsonValue));
    }

    /**
     */
    async getTrunkChangeHistory(requestParameters: GetTrunkChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkChangeHistory> {
        const response = await this.getTrunkChangeHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateTrunkChangeHistoryRaw(requestParameters: PartialUpdateTrunkChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkChangeHistory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateTrunkChangeHistory.');
        }

        if (requestParameters.trunkChangeHistory === null || requestParameters.trunkChangeHistory === undefined) {
            throw new runtime.RequiredError('trunkChangeHistory','Required parameter requestParameters.trunkChangeHistory was null or undefined when calling partialUpdateTrunkChangeHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-change-histories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkChangeHistoryToJSON(requestParameters.trunkChangeHistory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkChangeHistoryFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateTrunkChangeHistory(requestParameters: PartialUpdateTrunkChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkChangeHistory> {
        const response = await this.partialUpdateTrunkChangeHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTrunkChangeHistoryRaw(requestParameters: UpdateTrunkChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkChangeHistory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTrunkChangeHistory.');
        }

        if (requestParameters.trunkChangeHistory === null || requestParameters.trunkChangeHistory === undefined) {
            throw new runtime.RequiredError('trunkChangeHistory','Required parameter requestParameters.trunkChangeHistory was null or undefined when calling updateTrunkChangeHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-change-histories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkChangeHistoryToJSON(requestParameters.trunkChangeHistory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkChangeHistoryFromJSON(jsonValue));
    }

    /**
     */
    async updateTrunkChangeHistory(requestParameters: UpdateTrunkChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkChangeHistory> {
        const response = await this.updateTrunkChangeHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
