import { customPushTokenResourceApi } from '@services/apis/ApiConfiguration';
import { PushToken, PushTokenOperatingSystemEnum } from '@services/apis/generated';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import { Logger } from './Logger';
import { PlatformHelper } from './PlatformHelper';

export class NotificationHelper {
  protected static readonly GRANTED = 'granted';

  static registerForPushNotificationsAsync = async () => {
    if (Device.isDevice && !PlatformHelper.isWeb()) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;

      // Request permissions if not already granted
      if (existingStatus !== this.GRANTED) {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }

      // Check if permissions were granted
      if (finalStatus !== this.GRANTED) {
        Logger.warn(NotificationHelper.name, 'Failed to get push token for push notifications!');
        return;
      }

      // Get the push token
      const token = (await Notifications.getExpoPushTokenAsync()).data;
      const platform: PushTokenOperatingSystemEnum = PlatformHelper.isIos()
        ? PushTokenOperatingSystemEnum.Ios
        : PushTokenOperatingSystemEnum.Android;

      const pushToken: PushToken = {
        token: token,
        operatingSystem: platform,
      };

      // Send the push token to the server
      try {
        const res = await customPushTokenResourceApi.createMyPushToken({ pushToken: pushToken });
        Logger.log(NotificationHelper.name, res);
      } catch (error) {
        Logger.warn(NotificationHelper.name, 'Cannot create push token: ' + error.message);
      }
    } else {
      Logger.warn(NotificationHelper.name, 'Must use a physical device for Push Notifications');
    }

    // Set up notification channel for Android
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  };

  static showDownloadNotification = async () => {
    const notificationId = await Notifications.scheduleNotificationAsync({
      content: {
        title: "Downloading...",
        body: "Your file is being downloaded.",
        data: { status: "downloading" },
      },
      trigger: null,
    });
  
    return notificationId;
  };
  
  static updateNotification = async (notificationId, progress) => {
    await Notifications.scheduleNotificationAsync({
      identifier: notificationId,
      content: {
        title: `Downloading... ${Math.round(progress * 100)}%`,
        body: "Your file is being downloaded.",
      },
      trigger: null,
    });
  };
  
  static completeNotification = async (notificationId) => {
    await Notifications.scheduleNotificationAsync({
      identifier: notificationId,
      content: {
        title: "Download Complete",
        body: "Your file has been downloaded successfully.",
      },
      trigger: null,
    });
  };

}