/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrunkDiameterClassTable
 */
export interface TrunkDiameterClassTable {
    /**
     * 
     * @type {number}
     * @memberof TrunkDiameterClassTable
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TrunkDiameterClassTable
     */
    minDiameter?: number;
    /**
     * 
     * @type {number}
     * @memberof TrunkDiameterClassTable
     */
    maxDiameter?: number;
    /**
     * 
     * @type {string}
     * @memberof TrunkDiameterClassTable
     */
    diameterClass?: TrunkDiameterClassTableDiameterClassEnum;
}


/**
 * @export
 */
export const TrunkDiameterClassTableDiameterClassEnum = {
    _0: 'K_0',
    _1A: 'K_1A',
    _1B: 'K_1B',
    _2A: 'K_2A',
    _2B: 'K_2B',
    _3A: 'K_3A',
    _3B: 'K_3B',
    _4A: 'K_4A',
    _4B: 'K_4B',
    _5A: 'K_5A',
    _5B: 'K_5B',
    _6: 'K_6'
} as const;
export type TrunkDiameterClassTableDiameterClassEnum = typeof TrunkDiameterClassTableDiameterClassEnum[keyof typeof TrunkDiameterClassTableDiameterClassEnum];


/**
 * Check if a given object implements the TrunkDiameterClassTable interface.
 */
export function instanceOfTrunkDiameterClassTable(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TrunkDiameterClassTableFromJSON(json: any): TrunkDiameterClassTable {
    return TrunkDiameterClassTableFromJSONTyped(json, false);
}

export function TrunkDiameterClassTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrunkDiameterClassTable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'minDiameter': !exists(json, 'minDiameter') ? undefined : json['minDiameter'],
        'maxDiameter': !exists(json, 'maxDiameter') ? undefined : json['maxDiameter'],
        'diameterClass': !exists(json, 'diameterClass') ? undefined : json['diameterClass'],
    };
}

export function TrunkDiameterClassTableToJSON(value?: TrunkDiameterClassTable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'minDiameter': value.minDiameter,
        'maxDiameter': value.maxDiameter,
        'diameterClass': value.diameterClass,
    };
}

