import LoginLayout from '@components/layouts/login/LoginLayout';
import ChangePasswordView, { PasswordForm } from '@components/views/login/ChangePasswordView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { accountResourceApi, customAccountResourceApi } from '@services/apis/ApiConfiguration';
import { LoginVM } from '@services/apis/generated';
import React from 'react';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { useQuery } from '../../utils/classes/UrstammUtilityFunctions';

export default function ChangePasswordContainer({ navigation, route }: RootStackScreenProps<'ChangePassword'>) {
  const changePassword = (passwordForm: PasswordForm): void => {
    let token = useQuery().get('token');
    accountResourceApi
      .finishPasswordReset({
        keyAndPasswordVM: {
          key: token!,
          newPassword: passwordForm.password
        }
      })
      .then(() => {
        UrstammNavigationHelper.navigateToLogin(navigation);
      })
      .catch(async error => {
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <LoginLayout
      navigation={navigation}
      view={
        <ChangePasswordView submit={(password: PasswordForm) => changePassword(password)} navigation={navigation} />
      }
    />
  );
}

function resetPassword(authData: LoginVM) {
  throw new Error('Function not implemented.');
}
