import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface UrstammImageInProgress {
  width?: number;
  height?: number;
  showText?: boolean;
}

const UrstammImageInProgress = (props: UrstammImageInProgress) => {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 17 18" fill="none">
      <Path
        d="M8.75014 4.60728V9.00014L11.6787 10.4644M16.0716 9.00014C16.0716 13.0437 12.7937 16.3216 8.75014 16.3216C4.70663 16.3216 1.42871 13.0437 1.42871 9.00014C1.42871 4.95663 4.70663 1.67871 8.75014 1.67871C12.7937 1.67871 16.0716 4.95663 16.0716 9.00014Z"
        stroke="#E7C61B"
        strokeWidth="1.78261"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default UrstammImageInProgress;
