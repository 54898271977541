/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserCompanyInvite } from './UserCompanyInvite';
import {
    UserCompanyInviteFromJSON,
    UserCompanyInviteFromJSONTyped,
    UserCompanyInviteToJSON,
} from './UserCompanyInvite';

/**
 * 
 * @export
 * @interface UserCompanyInviteState
 */
export interface UserCompanyInviteState {
    /**
     * 
     * @type {number}
     * @memberof UserCompanyInviteState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserCompanyInviteState
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserCompanyInviteState
     */
    state: UserCompanyInviteStateStateEnum;
    /**
     * 
     * @type {UserCompanyInvite}
     * @memberof UserCompanyInviteState
     */
    userCompanyInvite?: UserCompanyInvite;
}


/**
 * @export
 */
export const UserCompanyInviteStateStateEnum = {
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type UserCompanyInviteStateStateEnum = typeof UserCompanyInviteStateStateEnum[keyof typeof UserCompanyInviteStateStateEnum];


/**
 * Check if a given object implements the UserCompanyInviteState interface.
 */
export function instanceOfUserCompanyInviteState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function UserCompanyInviteStateFromJSON(json: any): UserCompanyInviteState {
    return UserCompanyInviteStateFromJSONTyped(json, false);
}

export function UserCompanyInviteStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCompanyInviteState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'state': json['state'],
        'userCompanyInvite': !exists(json, 'userCompanyInvite') ? undefined : UserCompanyInviteFromJSON(json['userCompanyInvite']),
    };
}

export function UserCompanyInviteStateToJSON(value?: UserCompanyInviteState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'state': value.state,
        'userCompanyInvite': UserCompanyInviteToJSON(value.userCompanyInvite),
    };
}

