import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import { ProjectListView } from '@components/views/project/ProjectListView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import {
  setProjectList,
  setProjectListSort,
  setProjectPage,
  setProjectTotalPages
} from '@redux/features/project/projectSlice';
import { RootState } from '@redux/store';
import { customProjectResourceApi, listSize } from '@services/apis/ApiConfiguration';
import { PageProject } from '@services/apis/generated';
import { Project } from '@services/apis/generated/models/Project';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function ProjectListContainer({ navigation, route }: RootStackScreenProps<'ProjectList'>) {
  //REDUX
  const rdxProjectList = useSelector((state: RootState) => state.persistedReducer.project.list);
  const rdxProjectCompleteList = useSelector((state: RootState) => state.persistedReducer.project.completeList);
  const rdxProjectPage = useSelector((state: RootState) => state.persistedReducer.project.page);
  const rdxProjectTotalPages = useSelector((state: RootState) => state.persistedReducer.project.totalPages);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.project.sortBy);
  const dispatch = useDispatch();
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  /**
   * Settings for corner buttons
   */
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText:
        rdxSortBy.length > 0 && rdxSortBy[0] ? rdxSortBy[0].text : i18n.t('utility_components.modal.sortBy.date_desc')
    },
    cornerBottomRight: {
      showCorner: true,
      text: i18n.t('generics.project'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    dispatch(changeLoaderStatus(false));
    getProjectList(rdxSortBy);
  }, [route.params, rdxSortBy]);

  useFocusEffect(
    useCallback(() => {
      getProjectList(rdxSortBy);
    }, [route.params, rdxProjectPage, rdxSortBy])
  );

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setProjectListSort(orderSelected));
    setShowSortBy(false);
  };

  const navigateToProjectDetails = (project: Project) => {
    UrstammNavigationHelper.navigateToProjectDetails(navigation, project);
  };

  const getProjectList = (order?: ButtonSelect[]) => {
    let sortBy =
      order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['creationDate,desc'];
    dispatch(changeLoaderStatus(true));

    customProjectResourceApi
      .getPageProjectsForMyCompany({
        sort: sortBy,
        customProjectCriteria: { currentState: {} },
        page: rdxProjectPage,
        size: listSize
      })
      .then((pageProject: PageProject) => {
        dispatch(changeLoaderStatus(false));
        if (pageProject) {
          dispatch(setProjectTotalPages(pageProject.totalPages));
          dispatch(setProjectList(pageProject.content));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
        dispatch(setProjectTotalPages(1));
        dispatch(setProjectList([]));
      });
  };

  const navigateToProjectRegistration = () => {
    UrstammNavigationHelper.navigateToProjectRegistration(navigation);
  };

  const getMoreProjectItems = (): void => {
    if (rdxProjectPage < rdxProjectTotalPages - 1) {
      dispatch(setProjectPage(rdxProjectPage + 1));
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerLogoTitleStart,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitBottomRight={navigateToProjectRegistration}
      submitTopLeft={() => console.log('Tap on Back!')}
      submitBottomLeft={() => setShowSortBy(true)}
      title={
        <UrstammTitle
          testID={'projectsList_title'}
          text={i18n.t('generics.projects')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <ProjectListView
          navigation={navigation}
          projectList={rdxProjectList}
          modalVisible={false}
          moreItems={getMoreProjectItems}
          refresh={() => getProjectList(rdxSortBy)}
          projectSelected={(project: Project) => navigateToProjectDetails(project)}
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
        />
      }
    />
  );
}
