import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import OfflineSyncManager from '@components/utility-components/offlineSync/OfflineSyncManager';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import LoggingListView from '@components/views/logging/LoggingListVIew';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { listSize } from '@services/apis/ApiConfiguration';
import { FullAvailableLoggingResourceApi } from '@services/apis/FullAvailableLoggingResourceApi';
import { Logging, PageLogging } from '@services/apis/generated';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import {
  setLoggingList,
  setLoggingListSort,
  setLoggingPage,
  setTotalPages
} from '../../redux/features/logging/loggingSlice';
import { RootState } from '../../redux/store';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function LoggingListContainer({ navigation, route }: RootStackScreenProps<'LoggingList'>) {
  //REDUX
  const rdxLoggingList = useSelector((state: RootState) => state.persistedReducer.logging.list);
  const rdxOfflineSyncLastSync = useSelector((state: RootState) => state.persistedReducer.offlineSync.lastSync);
  const rdxLoggingCompleteList = useSelector((state: RootState) => state.persistedReducer.logging.completeList);
  const rdxPage = useSelector((state: RootState) => state.persistedReducer.logging.page);
  const rdxTotalPages = useSelector((state: RootState) => state.persistedReducer.logging.totalPages);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.logging.sortBy);
  const dispatch = useDispatch();

  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  const offlineSyncManagerRef = useRef<any>();

  /**
   * Settings for corner buttons
   */
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText:
        rdxSortBy.length > 0 && rdxSortBy[0] ? rdxSortBy[0].text : i18n.t('utility_components.modal.sortBy.date_desc')
    },
    cornerBottomRight: {
      showCorner: true,
      text: i18n.t('generics.logging'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useFocusEffect(
    useCallback(() => {
      dispatch(changeLoaderStatus(false));
      getLoggingList(rdxSortBy);
    }, [route.params, rdxPage, rdxSortBy])
  );

  const getLoggingList = (order?: ButtonSelect[]): void => {
    let sortBy =
      order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['creationDate,desc'];
    dispatch(changeLoaderStatus(true));

    FullAvailableLoggingResourceApi.getPageLoggingsForMyCompany(rdxLoggingCompleteList, {
      sort: sortBy,
      customLoggingCriteria: {},
      page: rdxPage,
      size: listSize
    })
      .then((loggingList: PageLogging) => {
        dispatch(changeLoaderStatus(false));

        if (loggingList) {
          dispatch(setTotalPages(loggingList.totalPages!));
          dispatch(setLoggingList(loggingList.content));
        }
      })
      .catch(async error => {
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
        dispatch(changeLoaderStatus(false));
      });
  };

  const getMoreLoggingItems = (): void => {
    if (rdxPage < rdxTotalPages - 1) {
      dispatch(setLoggingPage(rdxPage + 1));
    }
  };

  const navigateToLoggingRegistration = (): void => {
    UrstammNavigationHelper.navigateToLoggingRegistration(navigation);
  };

  const navigateToLoggingDetails = (logging: Logging): void => {
    UrstammNavigationHelper.navigateToLoggingDetails(navigation, logging);
  };

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setLoggingListSort(orderSelected));
    setShowSortBy(false);
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerLogoTitleStart,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitBottomRight={navigateToLoggingRegistration}
      submitBottomLeft={() => setShowSortBy(true)}
      title={
        <UrstammTitle
          testID={'logging_title'}
          text={i18n.t('generics.logging')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <>
          <OfflineSyncManager ref={offlineSyncManagerRef} />
          <LoggingListView
            navigation={navigation}
            loggingList={rdxLoggingList}
            refresh={() => getLoggingList(rdxSortBy)}
            moreItems={getMoreLoggingItems}
            showSortBy={showSortBy}
            closeShowSortBy={() => setShowSortBy(false)}
            applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
            navigateToLoggingDetails={(loggingSelected: Logging) => navigateToLoggingDetails(loggingSelected)}
          />
        </>
      }
    />
  );
}
