/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectUniqueNumber
 */
export interface ProjectUniqueNumber {
    /**
     * 
     * @type {number}
     * @memberof ProjectUniqueNumber
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectUniqueNumber
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectUniqueNumber
     */
    number: number;
}

/**
 * Check if a given object implements the ProjectUniqueNumber interface.
 */
export function instanceOfProjectUniqueNumber(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "number" in value;

    return isInstance;
}

export function ProjectUniqueNumberFromJSON(json: any): ProjectUniqueNumber {
    return ProjectUniqueNumberFromJSONTyped(json, false);
}

export function ProjectUniqueNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectUniqueNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'year': json['year'],
        'number': json['number'],
    };
}

export function ProjectUniqueNumberToJSON(value?: ProjectUniqueNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'year': value.year,
        'number': value.number,
    };
}

