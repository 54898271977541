/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserExtended,
} from '../models';
import {
    UserExtendedFromJSON,
    UserExtendedToJSON,
} from '../models';

export interface DownloadFileRequest {
    id: number;
}

export interface PartialUpdateUserExtendedMeRequest {
    userExtended: UserExtended;
}

export interface UploadMyAvatarRequest {
    id: number;
    avatar: Blob;
}

/**
 * 
 */
export class CustomUserExtendedResourceApi extends runtime.BaseAPI {

    /**
     * Download avatar image corresponding to UserExtended identified by given id.
     * Download avatar of UserExtended
     */
    async downloadFileRaw(requestParameters: DownloadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/user-extendeds/{id}/download/avatar`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download avatar image corresponding to UserExtended identified by given id.
     * Download avatar of UserExtended
     */
    async downloadFile(requestParameters: DownloadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get UserExtended using token of caller.
     * Get UserExtended object of logged user
     */
    async getUserExtendedMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserExtended>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/user-extendeds/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     * Get UserExtended using token of caller.
     * Get UserExtended object of logged user
     */
    async getUserExtendedMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserExtended> {
        const response = await this.getUserExtendedMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Partial update UserExtended of logged user.
     * Partial update my UserExtended
     */
    async partialUpdateUserExtendedMeRaw(requestParameters: PartialUpdateUserExtendedMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserExtended>> {
        if (requestParameters.userExtended === null || requestParameters.userExtended === undefined) {
            throw new runtime.RequiredError('userExtended','Required parameter requestParameters.userExtended was null or undefined when calling partialUpdateUserExtendedMe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/user-extendeds/partial/me`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserExtendedToJSON(requestParameters.userExtended),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     * Partial update UserExtended of logged user.
     * Partial update my UserExtended
     */
    async partialUpdateUserExtendedMe(requestParameters: PartialUpdateUserExtendedMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserExtended> {
        const response = await this.partialUpdateUserExtendedMeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload an avatar image corresponding to UserExtended identified by given id.
     * Upload avatar for UserExtended
     */
    async uploadMyAvatarRaw(requestParameters: UploadMyAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserExtended>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadMyAvatar.');
        }

        if (requestParameters.avatar === null || requestParameters.avatar === undefined) {
            throw new runtime.RequiredError('avatar','Required parameter requestParameters.avatar was null or undefined when calling uploadMyAvatar.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/octet-stream' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.avatar !== undefined) {
            formParams.append('avatar', requestParameters.avatar as any);
        }

        const response = await this.request({
            path: `/api/custom/user-extendeds/{id}/avatar`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     * Upload an avatar image corresponding to UserExtended identified by given id.
     * Upload avatar for UserExtended
     */
    async uploadMyAvatar(requestParameters: UploadMyAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserExtended> {
        const response = await this.uploadMyAvatarRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
