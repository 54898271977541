import UrstammAlertModal from '@components/utility-components/modal/UrstammAlertModal';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { UrstammNavigationHelper } from '../../../navigation/UrstammNavigationHelper';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { mainGreenButton, mediumGreyButton } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammFontFamily, UrstammFontsize } from '../../../utils/styles/UrstammFont';
import { UrstammStyle, UrstammStyleContainer, UrstammStyleModal } from '../../../utils/styles/UrstammStyle';
import UrstammButtonBase from '../../utility-components/button/UrstammButtonBase';
import UrstammInput from '../../utility-components/input/UrstammInput';
import UrstammTitle from '../../utility-components/title/UrstammTitle';

export default function ResetPasswordView(props: { submit: (email: object) => void; navigation: any }) {
  const [email, setEmail] = useStateWithCallbackLazy<any>({ email: '' });
  const [openCloseValidateModal, setOpenCloseValidateModal] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  const backToLogin = (): void => {
    UrstammNavigationHelper.navigateToLogin(props.navigation);
  };

  //Validators
  const validators = useState<any>({
    email: { required: true, email: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    email: []
  });

  const validate = (value: string, key: string, updateEmail: object) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updateEmail)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updateEmail: any) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated) || updateEmail.email?.length == 0) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn]}>
      <ScrollView keyboardShouldPersistTaps="always" contentContainerStyle={[UrstammStyle.flexGrow1]}>
        {/** Title */}
        <View style={styles.titleTextContainer}>
          <UrstammTitle
            testID={'reset_password_title'}
            text={i18n.t('views.login.reset_password')}
            fontStyle={UrstammStyle.sectionTitleXBDarkGrey}
          />
        </View>
        {/** Email */}
        <View style={styles.inputContainer}>
          <UrstammInput
            testID="reset_email"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{
              showIcon: true,
              iconName: 'user',
              iconColor: ColorTheme.lightGreen
            }}
            onChangeText={(text: string) => {
              setEmail({ ...email, email: text }, updatedEmail => validate(text, 'email', updatedEmail));
            }}
            mandatory={true}
            placeholder={i18n.t('views.login.insert_your_email_here')}
          />
          {/**Validator */}
          {errors['email'] && errors['email'].length > 0
            ? errors['email'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Footer */}
        <View style={[UrstammStyle.flex4, { justifyContent: 'center' }]}>
          {/** Send password button */}
          <View style={styles.buttonLogin}>
            <UrstammButtonBase
              testID={'reset_password_button'}
              text={i18n.t('views.login.send_email')}
              style={mainGreenButton}
              onSubmit={() => setOpenCloseValidateModal(true)}
              disabled={saveDisabled}
            />
          </View>

          {/** Go back to Login button */}
          <View style={styles.buttonLogin}>
            <UrstammButtonBase
              testID={'back_to_login_button'}
              text={i18n.t('views.login.login')}
              style={mediumGreyButton}
              onSubmit={backToLogin}
            />
          </View>

          {openCloseValidateModal && (
            <UrstammAlertModal
              testID={'mail_sent_modal'}
              text={i18n.t('views.login.check_email_for_resetting_password')}
              textStyle={UrstammStyleModal.formBtextModalStyle}
              visible={openCloseValidateModal}
              confirm={(confirmed: boolean) => {
                props.submit(email.email);
                backToLogin();
                setOpenCloseValidateModal(false);
              }}
              buttonText={{ center: i18n.t('views.login.login') }}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  titleTextContainer: {
    //flex: 1,
    marginBottom: 16
  },
  inputContainer: {
    //flex: 1,
    paddingVertical: 8
  },
  forgotPasswordContainer: {
    //flex: 0.2,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  buttonLogin: {
    marginTop: 8
  },

  forgotPasswordText: {
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
    color: ColorTheme.mediumGrey,
    textDecorationLine: 'underline'
  }
});
