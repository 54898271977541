import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ShippingNoteListView from '@components/views/shipping-note/ShippingNoteListView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import {
  setShippingNoteList,
  setShippingNoteListSort,
  setShippingNotePage,
  setShippingNoteTotalPages,
  shippingNote
} from '@redux/features/shipping-note/shippingNoteSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { RootState } from '@redux/store';

import { customShippingNoteResourceApi, listSize } from '@services/apis/ApiConfiguration';
import { ShippingNote, PageShippingNoteDTO } from '@services/apis/generated';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';
import { useFocusEffect } from '@react-navigation/native';

export default function ShippingNoteListContainer({ navigation, route }: RootStackScreenProps<'ShippingNoteList'>) {
  //REDUX
  const rdxShippingNoteList = useSelector((state: RootState) => state.persistedReducer.shippingNote.list);
  const rdxShippingNoteCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.shippingNote.completeList
  );
  const rdxShippingNotePage = useSelector((state: RootState) => state.persistedReducer.shippingNote.page);
  const rdxShippingNoteTotalPages = useSelector((state: RootState) => state.persistedReducer.shippingNote.totalPages);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.shippingNote.sortBy);
  const dispatch = useDispatch();
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  /**
   * Settings for corner buttons
   */
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText:
        rdxSortBy.length > 0 && rdxSortBy[0] ? rdxSortBy[0].text : i18n.t('utility_components.modal.sortBy.date_desc')
    },
    cornerBottomRight: {
      showCorner: true,
      text: i18n.t('generics.shipping_note'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    dispatch(changeLoaderStatus(false));
    getShippingNoteList(rdxSortBy);
  }, [route.params, rdxSortBy]);

  useFocusEffect(
    useCallback(() => {
      getShippingNoteList(rdxSortBy);
    }, [route.params, rdxShippingNotePage, rdxSortBy])
  );

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setShippingNoteListSort(orderSelected));
    setShowSortBy(false);
  };

  const navigateToShippingNoteDetails = (shippingNote: ShippingNote) => {
    UrstammNavigationHelper.navigateToShippingNoteDetails(navigation, shippingNote);
  };

  const getShippingNoteList = (order?: ButtonSelect[]) => {
    let sortBy =
      order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['creationDate,desc'];
    dispatch(changeLoaderStatus(true));

    customShippingNoteResourceApi
      .getPageShippingNotesForMyCompany({
        sort: sortBy,
        customShippingNoteCriteria: { currentState: {} },
        page: rdxShippingNotePage,
        size: listSize
      })
      .then((shippingNoteList: PageShippingNoteDTO) => {
        dispatch(changeLoaderStatus(false));
        if (shippingNoteList) {
          dispatch(setShippingNoteTotalPages(shippingNoteList.totalPages));
          dispatch(setShippingNoteList(shippingNoteList.content));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const navigateToShippingNoteRegistration = () => {
    UrstammNavigationHelper.navigateToShippingNoteRegistration(navigation, rdxShippingNoteList);
  };

  const getMoreShippingNoteItems = (): void => {
    if (rdxShippingNotePage < (rdxShippingNoteTotalPages - 1)) {
      dispatch(setShippingNotePage(rdxShippingNotePage + 1));
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerLogoTitleStart,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitBottomRight={navigateToShippingNoteRegistration}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomLeft={() => setShowSortBy(true)}
      title={
        <UrstammTitle
          testID={'shipping_note_title'}
          text={i18n.t('generics.shipping_note')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <ShippingNoteListView
          navigation={navigation}
          shippingNoteList={rdxShippingNoteList}
          modalVisible={false}
          moreItems={getMoreShippingNoteItems}
          shippingNoteSelected={(shippingNote: ShippingNote) => navigateToShippingNoteDetails(shippingNote)}
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
        />
      }
    />
  );
}
