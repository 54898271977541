/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminUserFullDTO,
  Authority,
  PageUser,
  Pageable,
  User,
} from '../models';
import {
    AdminUserFullDTOFromJSON,
    AdminUserFullDTOToJSON,
    AuthorityFromJSON,
    AuthorityToJSON,
    PageUserFromJSON,
    PageUserToJSON,
    PageableFromJSON,
    PageableToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface ActivateUserRequest {
    login: string;
}

export interface CreateUserIncludingExtendedRequest {
    adminUserFullDTO: AdminUserFullDTO;
}

export interface DeleteUserCascadeRequest {
    login: string;
}

export interface GetAllUsersPageRequest {
    searchText: string;
    pageable: Pageable;
}

export interface PartialUpdateUserRequest {
    id: number;
    user: User;
}

/**
 * 
 */
export class CustomUserResourceApi extends runtime.BaseAPI {

    /**
     * Activate User identified by given \"login\".
     * Activate a User
     */
    async activateUserRaw(requestParameters: ActivateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.login === null || requestParameters.login === undefined) {
            throw new runtime.RequiredError('login','Required parameter requestParameters.login was null or undefined when calling activateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/admin/users/{login}/activate`.replace(`{${"login"}}`, encodeURIComponent(String(requestParameters.login))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Activate User identified by given \"login\".
     * Activate a User
     */
    async activateUser(requestParameters: ActivateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.activateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Count all the users
     */
    async countUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/admin/users/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Count all the users
     */
    async countUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create a new User and a new UserExtended, then they are connected each other.
     * Create a User and relative UserExtended
     */
    async createUserIncludingExtendedRaw(requestParameters: CreateUserIncludingExtendedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.adminUserFullDTO === null || requestParameters.adminUserFullDTO === undefined) {
            throw new runtime.RequiredError('adminUserFullDTO','Required parameter requestParameters.adminUserFullDTO was null or undefined when calling createUserIncludingExtended.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/admin/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUserFullDTOToJSON(requestParameters.adminUserFullDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Create a new User and a new UserExtended, then they are connected each other.
     * Create a User and relative UserExtended
     */
    async createUserIncludingExtended(requestParameters: CreateUserIncludingExtendedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.createUserIncludingExtendedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete User identified by given \"login\".
     * Delete a User
     */
    async deleteUserCascadeRaw(requestParameters: DeleteUserCascadeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.login === null || requestParameters.login === undefined) {
            throw new runtime.RequiredError('login','Required parameter requestParameters.login was null or undefined when calling deleteUserCascade.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/admin/users/{login}`.replace(`{${"login"}}`, encodeURIComponent(String(requestParameters.login))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete User identified by given \"login\".
     * Delete a User
     */
    async deleteUserCascade(requestParameters: DeleteUserCascadeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserCascadeRaw(requestParameters, initOverrides);
    }

    /**
     * Get all users with all the details - calling this are only allowed for the administrators.
     * Get all users (pageable)
     */
    async getAllUsersPageRaw(requestParameters: GetAllUsersPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageUser>> {
        if (requestParameters.searchText === null || requestParameters.searchText === undefined) {
            throw new runtime.RequiredError('searchText','Required parameter requestParameters.searchText was null or undefined when calling getAllUsersPage.');
        }

        if (requestParameters.pageable === null || requestParameters.pageable === undefined) {
            throw new runtime.RequiredError('pageable','Required parameter requestParameters.pageable was null or undefined when calling getAllUsersPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['searchText'] = requestParameters.searchText;
        }

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageUserFromJSON(jsonValue));
    }

    /**
     * Get all users with all the details - calling this are only allowed for the administrators.
     * Get all users (pageable)
     */
    async getAllUsersPage(requestParameters: GetAllUsersPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageUser> {
        const response = await this.getAllUsersPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get User (including UserExtended) using token of caller.
     * Get User object of logged user
     */
    async getMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Get User (including UserExtended) using token of caller.
     * Get User object of logged user
     */
    async getMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get authorities of User using token of caller.
     * Get authorities of logged user
     */
    async getMyAuthoritiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Set<Authority>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/users/me/authorities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => new Set(jsonValue.map(AuthorityFromJSON)));
    }

    /**
     * Get authorities of User using token of caller.
     * Get authorities of logged user
     */
    async getMyAuthorities(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Set<Authority>> {
        const response = await this.getMyAuthoritiesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Partial update User identified by given id.
     * Partial update a User
     */
    async partialUpdateUserRaw(requestParameters: PartialUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateUser.');
        }

        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling partialUpdateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Partial update User identified by given id.
     * Partial update a User
     */
    async partialUpdateUser(requestParameters: PartialUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.partialUpdateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
