import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { Company } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface SawmillState {
  list: Company[];
  completeList: Company[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: SawmillState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const sawmill = createSlice({
  name: 'sawmillList',
  initialState,
  reducers: {
    setSawmillList: (state, action) => {
      let sawmillList = { ...state }.list;
      let sawmillCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(sawmillList, element);
        pushElementOrReplaceById(sawmillCompleteList, element);
      });

      state.list = sawmillList;
      state.completeList = sawmillCompleteList;
    },
    setCompleteSawmillList: (state, action) => {
      // Repetition
      let sawmillCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(sawmillCompleteList, element);
      });

      state.completeList = sawmillCompleteList;
    },
    setSawmillTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setSawmillListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setSawmillPage: (state, action) => {
      state.page = action.payload;
    },
    resetSawmillList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setSawmillList,
  setCompleteSawmillList,
  setSawmillTotalPages,
  setSawmillListSort,
  setSawmillPage,
  resetSawmillList
} = sawmill.actions;

export default sawmill.reducer;
