import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface UrstammImageBack {
  width?: number;
  height?: number;
  showText?: boolean;
}

const UrstammImageBack = (props: UrstammImageBack) => {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 23 22" fill="none">
      <Path
        d="M11.5 7L7.5 11M7.5 11L11.5 15M7.5 11H15.5M21.5 11C21.5 16.5228 17.0228 21 11.5 21C5.97715 21 1.5 16.5228 1.5 11C1.5 5.47715 5.97715 1 11.5 1C17.0228 1 21.5 5.47715 21.5 11Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default UrstammImageBack;
