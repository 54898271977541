/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProcessorBatchState,
} from '../models';
import {
    ProcessorBatchStateFromJSON,
    ProcessorBatchStateToJSON,
} from '../models';

export interface CreateProcessorBatchStateRequest {
    processorBatchState: ProcessorBatchState;
}

export interface DeleteProcessorBatchStateRequest {
    id: number;
}

export interface GetProcessorBatchStateRequest {
    id: number;
}

export interface PartialUpdateProcessorBatchStateRequest {
    id: number;
    processorBatchState: ProcessorBatchState;
}

export interface UpdateProcessorBatchStateRequest {
    id: number;
    processorBatchState: ProcessorBatchState;
}

/**
 * 
 */
export class ProcessorBatchStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createProcessorBatchStateRaw(requestParameters: CreateProcessorBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorBatchState>> {
        if (requestParameters.processorBatchState === null || requestParameters.processorBatchState === undefined) {
            throw new runtime.RequiredError('processorBatchState','Required parameter requestParameters.processorBatchState was null or undefined when calling createProcessorBatchState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-batch-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorBatchStateToJSON(requestParameters.processorBatchState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorBatchStateFromJSON(jsonValue));
    }

    /**
     */
    async createProcessorBatchState(requestParameters: CreateProcessorBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorBatchState> {
        const response = await this.createProcessorBatchStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProcessorBatchStateRaw(requestParameters: DeleteProcessorBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProcessorBatchState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-batch-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProcessorBatchState(requestParameters: DeleteProcessorBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProcessorBatchStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProcessorBatchStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProcessorBatchState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-batch-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProcessorBatchStateFromJSON));
    }

    /**
     */
    async getAllProcessorBatchStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProcessorBatchState>> {
        const response = await this.getAllProcessorBatchStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProcessorBatchStateRaw(requestParameters: GetProcessorBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorBatchState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProcessorBatchState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-batch-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorBatchStateFromJSON(jsonValue));
    }

    /**
     */
    async getProcessorBatchState(requestParameters: GetProcessorBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorBatchState> {
        const response = await this.getProcessorBatchStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateProcessorBatchStateRaw(requestParameters: PartialUpdateProcessorBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorBatchState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateProcessorBatchState.');
        }

        if (requestParameters.processorBatchState === null || requestParameters.processorBatchState === undefined) {
            throw new runtime.RequiredError('processorBatchState','Required parameter requestParameters.processorBatchState was null or undefined when calling partialUpdateProcessorBatchState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-batch-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorBatchStateToJSON(requestParameters.processorBatchState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorBatchStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateProcessorBatchState(requestParameters: PartialUpdateProcessorBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorBatchState> {
        const response = await this.partialUpdateProcessorBatchStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProcessorBatchStateRaw(requestParameters: UpdateProcessorBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorBatchState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProcessorBatchState.');
        }

        if (requestParameters.processorBatchState === null || requestParameters.processorBatchState === undefined) {
            throw new runtime.RequiredError('processorBatchState','Required parameter requestParameters.processorBatchState was null or undefined when calling updateProcessorBatchState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-batch-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorBatchStateToJSON(requestParameters.processorBatchState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorBatchStateFromJSON(jsonValue));
    }

    /**
     */
    async updateProcessorBatchState(requestParameters: UpdateProcessorBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorBatchState> {
        const response = await this.updateProcessorBatchStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
