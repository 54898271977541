import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { CompanyAppearance } from '@services/apis/generated';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface CompanyAppearanceState {
  list: CompanyAppearance[];
  completeList: CompanyAppearance[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: CompanyAppearanceState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const companyAppearance = createSlice({
  name: 'companyAppearanceList',
  initialState,
  reducers: {
    setCompanyAppearanceList: (state, action) => {
      let companyAppearance = { ...state }.list;
      let companyAppearanceCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        pushElementOrReplaceById(companyAppearance, element);
        pushElementOrReplaceById(companyAppearanceCompleteList, element);
      });

      state.list = companyAppearance;
      state.completeList = companyAppearanceCompleteList;
    },
    setCompanyAppearanceTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setCompanyAppearanceListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setCompanyAppearancePage: (state, action) => {
      state.page = action.payload;
    },
    resetCompanyAppearanceList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearCompanyAppearanceList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setCompanyAppearanceList,
  setCompanyAppearanceListSort,
  resetCompanyAppearanceList,
  setCompanyAppearanceTotalPages,
  setCompanyAppearancePage,
  clearCompanyAppearanceList
} = companyAppearance.actions;

export default companyAppearance.reducer;
