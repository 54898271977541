import { RootState } from '@redux/store';
import * as React from 'react';
import { Text, View } from 'react-native';
import { useSelector } from 'react-redux';

export default function UrstammWarningIcon() {
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);

  return rdxOfflineSyncList && rdxOfflineSyncList.length > 0 ? (
    <View
      style={{
        justifyContent: 'center',
        backgroundColor: 'red',
        width: 22,
        height: 22,
        borderRadius: 22 / 2,
        marginRight: -32
      }}>
      <Text style={{ margin: 'auto', textAlign: 'center', color: 'white' }}>!</Text>
    </View>
  ) : null;
}
