/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomShippingNoteCriteria,
  PageShippingNoteDTO,
  ShippingNote,
  ShippingNoteDTO,
  ShippingNoteStateUpdateDTO,
} from '../models';
import {
    CustomShippingNoteCriteriaFromJSON,
    CustomShippingNoteCriteriaToJSON,
    PageShippingNoteDTOFromJSON,
    PageShippingNoteDTOToJSON,
    ShippingNoteFromJSON,
    ShippingNoteToJSON,
    ShippingNoteDTOFromJSON,
    ShippingNoteDTOToJSON,
    ShippingNoteStateUpdateDTOFromJSON,
    ShippingNoteStateUpdateDTOToJSON,
} from '../models';

export interface CreateShippingNoteCustomRequest {
    shippingNote: ShippingNote;
}

export interface DownloadShippingNotePdfRequest {
    id: number;
}

export interface GetPageShippingNotesForMyCompanyRequest {
    customShippingNoteCriteria: CustomShippingNoteCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface GetShippingNoteCustomRequest {
    id: number;
}

export interface UpdateShippingNoteCustomRequest {
    id: number;
    shippingNote: ShippingNote;
}

export interface UpdateShippingNoteStateCustomRequest {
    id: number;
    shippingNoteStateUpdateDTO: ShippingNoteStateUpdateDTO;
}

/**
 * 
 */
export class CustomShippingNoteResourceApi extends runtime.BaseAPI {

    /**
     * Create a new ShippingNote connecting to Company of logged user.
     * Create a ShippingNote
     */
    async createShippingNoteCustomRaw(requestParameters: CreateShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNote>> {
        if (requestParameters.shippingNote === null || requestParameters.shippingNote === undefined) {
            throw new runtime.RequiredError('shippingNote','Required parameter requestParameters.shippingNote was null or undefined when calling createShippingNoteCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/shipping-notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShippingNoteToJSON(requestParameters.shippingNote),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteFromJSON(jsonValue));
    }

    /**
     * Create a new ShippingNote connecting to Company of logged user.
     * Create a ShippingNote
     */
    async createShippingNoteCustom(requestParameters: CreateShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNote> {
        const response = await this.createShippingNoteCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download pdf corresponding to ShippingNote identified by given id.
     * Download pdf of ShippingNote
     */
    async downloadShippingNotePdfRaw(requestParameters: DownloadShippingNotePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadShippingNotePdf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/shipping-notes/{id}/download/pdf`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download pdf corresponding to ShippingNote identified by given id.
     * Download pdf of ShippingNote
     */
    async downloadShippingNotePdf(requestParameters: DownloadShippingNotePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadShippingNotePdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a page of ShippingNote belonging to Company of logged User.
     * Get a page of shipping-notes for my Company
     */
    async getPageShippingNotesForMyCompanyRaw(requestParameters: GetPageShippingNotesForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageShippingNoteDTO>> {
        if (requestParameters.customShippingNoteCriteria === null || requestParameters.customShippingNoteCriteria === undefined) {
            throw new runtime.RequiredError('customShippingNoteCriteria','Required parameter requestParameters.customShippingNoteCriteria was null or undefined when calling getPageShippingNotesForMyCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/shipping-notes/company/my`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomShippingNoteCriteriaToJSON(requestParameters.customShippingNoteCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageShippingNoteDTOFromJSON(jsonValue));
    }

    /**
     * Get a page of ShippingNote belonging to Company of logged User.
     * Get a page of shipping-notes for my Company
     */
    async getPageShippingNotesForMyCompany(requestParameters: GetPageShippingNotesForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageShippingNoteDTO> {
        const response = await this.getPageShippingNotesForMyCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get ShippingNote identified by given id (must belong to Company of logged user).
     * Get a specific ShippingNote
     */
    async getShippingNoteCustomRaw(requestParameters: GetShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNoteDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getShippingNoteCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteDTOFromJSON(jsonValue));
    }

    /**
     * Get ShippingNote identified by given id (must belong to Company of logged user).
     * Get a specific ShippingNote
     */
    async getShippingNoteCustom(requestParameters: GetShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNoteDTO> {
        const response = await this.getShippingNoteCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update ShippingNote identified by given id (must belong to Company of logged user).
     * Partial update a ShippingNote
     */
    async updateShippingNoteCustomRaw(requestParameters: UpdateShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateShippingNoteCustom.');
        }

        if (requestParameters.shippingNote === null || requestParameters.shippingNote === undefined) {
            throw new runtime.RequiredError('shippingNote','Required parameter requestParameters.shippingNote was null or undefined when calling updateShippingNoteCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShippingNoteToJSON(requestParameters.shippingNote),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteFromJSON(jsonValue));
    }

    /**
     * Partial update ShippingNote identified by given id (must belong to Company of logged user).
     * Partial update a ShippingNote
     */
    async updateShippingNoteCustom(requestParameters: UpdateShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNote> {
        const response = await this.updateShippingNoteCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ShippingNote state identified by given id (must belong to Company of logged user).
     * Update a ShippingNote state
     */
    async updateShippingNoteStateCustomRaw(requestParameters: UpdateShippingNoteStateCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateShippingNoteStateCustom.');
        }

        if (requestParameters.shippingNoteStateUpdateDTO === null || requestParameters.shippingNoteStateUpdateDTO === undefined) {
            throw new runtime.RequiredError('shippingNoteStateUpdateDTO','Required parameter requestParameters.shippingNoteStateUpdateDTO was null or undefined when calling updateShippingNoteStateCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/shipping-notes/{id}/state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShippingNoteStateUpdateDTOToJSON(requestParameters.shippingNoteStateUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteFromJSON(jsonValue));
    }

    /**
     * Update ShippingNote state identified by given id (must belong to Company of logged user).
     * Update a ShippingNote state
     */
    async updateShippingNoteStateCustom(requestParameters: UpdateShippingNoteStateCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNote> {
        const response = await this.updateShippingNoteStateCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
