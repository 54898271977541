/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Company,
  CustomCompanyCriteria,
  PageCompany,
  UserCompany,
} from '../models';
import {
    CompanyFromJSON,
    CompanyToJSON,
    CustomCompanyCriteriaFromJSON,
    CustomCompanyCriteriaToJSON,
    PageCompanyFromJSON,
    PageCompanyToJSON,
    UserCompanyFromJSON,
    UserCompanyToJSON,
} from '../models';

export interface DownloadCompanyLogoRequest {
    id: number;
}

export interface GetAllCompaniesConnectedToMeRequest {
    customCompanyCriteria: CustomCompanyCriteria;
}

export interface GetCompaniesConnectedToMeRequest {
    customCompanyCriteria: CustomCompanyCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface PartialUpdateMyCompanyCustomRequest {
    company: Company;
}

export interface UploadMyCompanyLogoRequest {
    body: string;
}

/**
 * 
 */
export class CustomCompanyResourceApi extends runtime.BaseAPI {

    /**
     * Download logo corresponding to Company identified by given id.
     * Download logo of Company
     */
    async downloadCompanyLogoRaw(requestParameters: DownloadCompanyLogoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadCompanyLogo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/companies/{id}/download/logo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download logo corresponding to Company identified by given id.
     * Download logo of Company
     */
    async downloadCompanyLogo(requestParameters: DownloadCompanyLogoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadCompanyLogoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all UserCompany connected to my Company, then map them to Company list
     * Get all companies connected to my Company
     */
    async getAllCompaniesConnectedToMeRaw(requestParameters: GetAllCompaniesConnectedToMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Company>>> {
        if (requestParameters.customCompanyCriteria === null || requestParameters.customCompanyCriteria === undefined) {
            throw new runtime.RequiredError('customCompanyCriteria','Required parameter requestParameters.customCompanyCriteria was null or undefined when calling getAllCompaniesConnectedToMe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/companies/connected/me/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomCompanyCriteriaToJSON(requestParameters.customCompanyCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFromJSON));
    }

    /**
     * Get all UserCompany connected to my Company, then map them to Company list
     * Get all companies connected to my Company
     */
    async getAllCompaniesConnectedToMe(requestParameters: GetAllCompaniesConnectedToMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Company>> {
        const response = await this.getAllCompaniesConnectedToMeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a page of UserCompany connected to my Company, then map them to Company list
     * Get a page of companies connected to my Company
     */
    async getCompaniesConnectedToMeRaw(requestParameters: GetCompaniesConnectedToMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCompany>> {
        if (requestParameters.customCompanyCriteria === null || requestParameters.customCompanyCriteria === undefined) {
            throw new runtime.RequiredError('customCompanyCriteria','Required parameter requestParameters.customCompanyCriteria was null or undefined when calling getCompaniesConnectedToMe.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/companies/connected/me`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomCompanyCriteriaToJSON(requestParameters.customCompanyCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCompanyFromJSON(jsonValue));
    }

    /**
     * Get a page of UserCompany connected to my Company, then map them to Company list
     * Get a page of companies connected to my Company
     */
    async getCompaniesConnectedToMe(requestParameters: GetCompaniesConnectedToMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCompany> {
        const response = await this.getCompaniesConnectedToMeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get my UserCompany and get Company
     * Get my company
     */
    async getMyCompanyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/companies/mine`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     * Get my UserCompany and get Company
     * Get my company
     */
    async getMyCompany(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.getMyCompanyRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get my UserCompany in my own company
     * Get my relationship in my company
     */
    async getMyCompanyUserCompanyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompany>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/companies/mine/relationship`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyFromJSON(jsonValue));
    }

    /**
     * Get my UserCompany in my own company
     * Get my relationship in my company
     */
    async getMyCompanyUserCompany(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompany> {
        const response = await this.getMyCompanyUserCompanyRaw(initOverrides);
        return await response.value();
    }

    /**
     * Partial update data of my company. It does not update unique identifier and logo (ADMINISTRATOR role required)
     * Partial update my company (ADMINISTRATOR role required)
     */
    async partialUpdateMyCompanyCustomRaw(requestParameters: PartialUpdateMyCompanyCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling partialUpdateMyCompanyCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/companies/mine`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     * Partial update data of my company. It does not update unique identifier and logo (ADMINISTRATOR role required)
     * Partial update my company (ADMINISTRATOR role required)
     */
    async partialUpdateMyCompanyCustom(requestParameters: PartialUpdateMyCompanyCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.partialUpdateMyCompanyCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload an logo image corresponding to my Company.
     * Upload logo for my Company
     */
    async uploadMyCompanyLogoRaw(requestParameters: UploadMyCompanyLogoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling uploadMyCompanyLogo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/companies/mine/upload/logo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     * Upload an logo image corresponding to my Company.
     * Upload logo for my Company
     */
    async uploadMyCompanyLogo(requestParameters: UploadMyCompanyLogoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.uploadMyCompanyLogoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
