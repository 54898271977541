import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImageSync from '@components/images/corner/UrstammImageSync';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import OfflineSyncManager from '@components/utility-components/offlineSync/OfflineSyncManager';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import OfflineSyncListView from '@components/views/offlineSync/OfflineSyncListView';
import { i18n } from '@i18n/i18n';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps } from '../../navigation/UrstammNavigationHelper';
import { setLoggingListSort } from '../../redux/features/logging/loggingSlice';
import { RootState } from '../../redux/store';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function OfflineSyncListContainer({ navigation, route }: RootStackScreenProps<'OfflineSyncList'>) {
  //REDUX
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const dispatch = useDispatch();

  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  const offlineSyncManagerRef = useRef<any>();

  /**
   * Settings for corner buttons
   */
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.back'),
      icon: <UrstammImageBack width={34} height={24} />
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: <UrstammImageMenu width={34} height={24} />
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText: ''
    },
    cornerBottomRight: {
      showCorner: rdxOfflineSyncList.length > 0,
      text: i18n.t('views.offlineSync.offlineSyncs_button'),
      icon: <UrstammImageSync width={34} height={34} />
    }
  };

  useEffect(() => {
    if (route.params?.refreshList) {
    }
  }, [route.params, rdxOfflineSyncList]);

  const getMoreItems = (): void => {};

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setLoggingListSort(orderSelected));
    setShowSortBy(false);
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerLogoTitleStart,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitBottomRight={() => offlineSyncManagerRef.current!.startUploadSync()}
      submitBottomLeft={() => setShowSortBy(true)}
      title={
        <UrstammTitle
          testID={'logging_title'}
          text={i18n.t('views.offlineSync.offlineSyncs_title')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <>
          <OfflineSyncManager ref={offlineSyncManagerRef} />
          <OfflineSyncListView
            navigation={navigation}
            offlineSyncList={rdxOfflineSyncList}
            moreItems={getMoreItems}
            showSortBy={showSortBy}
            closeShowSortBy={() => setShowSortBy(false)}
            applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
            navigateToLoggingDetails={() => null}
          />
        </>
      }
    />
  );
}
