/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface EntityWithFileDTOProduct
 */
export interface EntityWithFileDTOProduct {
    /**
     * 
     * @type {Product}
     * @memberof EntityWithFileDTOProduct
     */
    entity?: Product;
    /**
     * 
     * @type {string}
     * @memberof EntityWithFileDTOProduct
     */
    base64File?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityWithFileDTOProduct
     */
    deletePrevious?: boolean;
}

/**
 * Check if a given object implements the EntityWithFileDTOProduct interface.
 */
export function instanceOfEntityWithFileDTOProduct(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EntityWithFileDTOProductFromJSON(json: any): EntityWithFileDTOProduct {
    return EntityWithFileDTOProductFromJSONTyped(json, false);
}

export function EntityWithFileDTOProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityWithFileDTOProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': !exists(json, 'entity') ? undefined : ProductFromJSON(json['entity']),
        'base64File': !exists(json, 'base64File') ? undefined : json['base64File'],
        'deletePrevious': !exists(json, 'deletePrevious') ? undefined : json['deletePrevious'],
    };
}

export function EntityWithFileDTOProductToJSON(value?: EntityWithFileDTOProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': ProductToJSON(value.entity),
        'base64File': value.base64File,
        'deletePrevious': value.deletePrevious,
    };
}

