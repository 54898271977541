/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ValidatePdfDeliverySheetRequest } from '../apis';
import { exists } from '../runtime';
import type { Trunk } from './Trunk';
import {
    TrunkFromJSON,
    TrunkToJSON
} from './Trunk';

/**
 * 
 * @export
 * @interface DeliverySheetValidationDTO
 */
export interface DeliverySheetValidationDTO {
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetValidationDTO
     */
    id?: number;
    /**
     * 
     * @type {Set<Trunk>}
     * @memberof DeliverySheetValidationDTO
     */
    trunks?: Set<Trunk>;
}

export interface DeliverySheetPdfValidationDTO {
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetPdfValidationDTO
     */
    volume?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetPdfValidationDTO
     */
    amountOfTrunks?: number;
    /**
     * 
     * @type {any[]}
     * @memberof DeliverySheetPdfValidationDTO
     */
    species?: any[];
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetPdfValidationDTO
     */
    deliverySheetFileUploads?: any[];
}

/**
 * Check if a given object implements the DeliverySheetValidationDTO interface.
 */
export function instanceOfDeliverySheetValidationDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeliverySheetValidationDTOFromJSON(json: any): DeliverySheetValidationDTO {
    return DeliverySheetValidationDTOFromJSONTyped(json, false);
}

export function DeliverySheetValidationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliverySheetValidationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'trunks': !exists(json, 'trunks') ? undefined : (new Set((json['trunks'] as Array<any>).map(TrunkFromJSON))),
    };
}

export function DeliverySheetValidationDTOToJSON(value?: DeliverySheetValidationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'trunks': value.trunks === undefined ? undefined : (Array.from(value.trunks as Set<any>).map(TrunkToJSON)),
    };
}

export function DeliverySheetPdfValidationDTOToJSON(value?: ValidatePdfDeliverySheetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'volume': value.volume,
        'amountOfTrunks': value.amountOfTrunks,
        'species': value.species,
        'deliverySheetFileUploads': value.deliverySheetFileUploads,
        'comment': value.comment
    };
}

