/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Packet } from './Packet';
import {
    PacketFromJSON,
    PacketFromJSONTyped,
    PacketToJSON,
} from './Packet';

/**
 * 
 * @export
 * @interface PacketSpecies
 */
export interface PacketSpecies {
    /**
     * 
     * @type {number}
     * @memberof PacketSpecies
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PacketSpecies
     */
    species?: PacketSpeciesSpeciesEnum;
    /**
     * 
     * @type {Packet}
     * @memberof PacketSpecies
     */
    packet?: Packet;
}


/**
 * @export
 */
export const PacketSpeciesSpeciesEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type PacketSpeciesSpeciesEnum = typeof PacketSpeciesSpeciesEnum[keyof typeof PacketSpeciesSpeciesEnum];


/**
 * Check if a given object implements the PacketSpecies interface.
 */
export function instanceOfPacketSpecies(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PacketSpeciesFromJSON(json: any): PacketSpecies {
    return PacketSpeciesFromJSONTyped(json, false);
}

export function PacketSpeciesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PacketSpecies {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'species': !exists(json, 'species') ? undefined : json['species'],
        'packet': !exists(json, 'packet') ? undefined : PacketFromJSON(json['packet']),
    };
}

export function PacketSpeciesToJSON(value?: PacketSpecies | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'species': value.species,
        'packet': PacketToJSON(value.packet),
    };
}

