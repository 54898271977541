/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstantFilter
 */
export interface InstantFilter {
    /**
     * 
     * @type {Date}
     * @memberof InstantFilter
     */
    equals?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InstantFilter
     */
    notEquals?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof InstantFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<Date>}
     * @memberof InstantFilter
     */
    _in?: Array<Date>;
    /**
     * 
     * @type {Array<Date>}
     * @memberof InstantFilter
     */
    notIn?: Array<Date>;
    /**
     * 
     * @type {Date}
     * @memberof InstantFilter
     */
    greaterThan?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InstantFilter
     */
    lessThan?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InstantFilter
     */
    greaterThanOrEqual?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InstantFilter
     */
    lessThanOrEqual?: Date;
}

/**
 * Check if a given object implements the InstantFilter interface.
 */
export function instanceOfInstantFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InstantFilterFromJSON(json: any): InstantFilter {
    return InstantFilterFromJSONTyped(json, false);
}

export function InstantFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstantFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : (new Date(json['equals'])),
        'notEquals': !exists(json, 'notEquals') ? undefined : (new Date(json['notEquals'])),
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
        'greaterThan': !exists(json, 'greaterThan') ? undefined : (new Date(json['greaterThan'])),
        'lessThan': !exists(json, 'lessThan') ? undefined : (new Date(json['lessThan'])),
        'greaterThanOrEqual': !exists(json, 'greaterThanOrEqual') ? undefined : (new Date(json['greaterThanOrEqual'])),
        'lessThanOrEqual': !exists(json, 'lessThanOrEqual') ? undefined : (new Date(json['lessThanOrEqual'])),
    };
}

export function InstantFilterToJSON(value?: InstantFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals === undefined ? undefined : (value.equals.toISOString()),
        'notEquals': value.notEquals === undefined ? undefined : (value.notEquals.toISOString()),
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
        'greaterThan': value.greaterThan === undefined ? undefined : (value.greaterThan.toISOString()),
        'lessThan': value.lessThan === undefined ? undefined : (value.lessThan.toISOString()),
        'greaterThanOrEqual': value.greaterThanOrEqual === undefined ? undefined : (value.greaterThanOrEqual.toISOString()),
        'lessThanOrEqual': value.lessThanOrEqual === undefined ? undefined : (value.lessThanOrEqual.toISOString()),
    };
}

