/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BatchStateEnumFilter
 */
export interface BatchStateEnumFilter {
    /**
     * 
     * @type {string}
     * @memberof BatchStateEnumFilter
     */
    equals?: BatchStateEnumFilterEqualsEnum;
    /**
     * 
     * @type {string}
     * @memberof BatchStateEnumFilter
     */
    notEquals?: BatchStateEnumFilterNotEqualsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BatchStateEnumFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchStateEnumFilter
     */
    _in?: Array<BatchStateEnumFilterInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchStateEnumFilter
     */
    notIn?: Array<BatchStateEnumFilterNotInEnum>;
}


/**
 * @export
 */
export const BatchStateEnumFilterEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type BatchStateEnumFilterEqualsEnum = typeof BatchStateEnumFilterEqualsEnum[keyof typeof BatchStateEnumFilterEqualsEnum];

/**
 * @export
 */
export const BatchStateEnumFilterNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type BatchStateEnumFilterNotEqualsEnum = typeof BatchStateEnumFilterNotEqualsEnum[keyof typeof BatchStateEnumFilterNotEqualsEnum];

/**
 * @export
 */
export const BatchStateEnumFilterInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type BatchStateEnumFilterInEnum = typeof BatchStateEnumFilterInEnum[keyof typeof BatchStateEnumFilterInEnum];

/**
 * @export
 */
export const BatchStateEnumFilterNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type BatchStateEnumFilterNotInEnum = typeof BatchStateEnumFilterNotInEnum[keyof typeof BatchStateEnumFilterNotInEnum];


/**
 * Check if a given object implements the BatchStateEnumFilter interface.
 */
export function instanceOfBatchStateEnumFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BatchStateEnumFilterFromJSON(json: any): BatchStateEnumFilter {
    return BatchStateEnumFilterFromJSONTyped(json, false);
}

export function BatchStateEnumFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchStateEnumFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
        'notEquals': !exists(json, 'notEquals') ? undefined : json['notEquals'],
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
    };
}

export function BatchStateEnumFilterToJSON(value?: BatchStateEnumFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals,
        'notEquals': value.notEquals,
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
    };
}

