import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { TrunkBarkDiscountTable, TrunkDiameterClassTable, TrunkLengthRangeTable } from '@services/apis/generated';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface CalculationState {
  lengthRangeList: TrunkLengthRangeTable[];
  diameterClassList: TrunkDiameterClassTable[];
  barkDiscountList: TrunkBarkDiscountTable[];
}

const initialState: CalculationState = {
  lengthRangeList: [],
  diameterClassList: [],
  barkDiscountList: []
};

export const calculation = createSlice({
  name: 'calculationList',
  initialState,
  reducers: {
    setLengthRangeList: (state, action) => {
      let calculationList = { ...state }.lengthRangeList;

      action.payload.forEach((element: any) => {
        pushElementOrReplaceById(calculationList, element);
      });

      state.lengthRangeList = calculationList;
    },
    setDiameterClassList: (state, action) => {
      let calculationList = { ...state }.diameterClassList;

      action.payload.forEach((element: any) => {
        pushElementOrReplaceById(calculationList, element);
      });

      state.diameterClassList = calculationList;
    },
    setBarkDiscountList: (state, action) => {
      let calculationList = { ...state }.barkDiscountList;

      action.payload.forEach((element: any) => {
        pushElementOrReplaceById(calculationList, element);
      });

      state.barkDiscountList = calculationList;
    },
    clearCalculationList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const { setLengthRangeList, setDiameterClassList, setBarkDiscountList, clearCalculationList } =
  calculation.actions;

export default calculation.reducer;
