/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ProjectDTO
 */
export interface ProjectDTO {
    /**
     * 
     * @type {number}
     * @memberof ProjectDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    uniqueNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDTO
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    currentState?: ProjectDTOCurrentStateEnum;
    /**
     * 
     * @type {number}
     * @memberof ProjectDTO
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDTO
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDTO
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    photoFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    photoFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    photoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    photoMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    uuid?: string;
    /**
     * 
     * @type {Company}
     * @memberof ProjectDTO
     */
    processor?: Company;
    /**
     * 
     * @type {User}
     * @memberof ProjectDTO
     */
    author?: User;
    /**
     * 
     * @type {User}
     * @memberof ProjectDTO
     */
    photoAuthor?: User;
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProjectDTO
     */
    products?: Array<Product>;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDTO
     */
    photoCreationDate?: Date;
}


/**
 * @export
 */
export const ProjectDTOCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProjectDTOCurrentStateEnum = typeof ProjectDTOCurrentStateEnum[keyof typeof ProjectDTOCurrentStateEnum];


/**
 * Check if a given object implements the ProjectDTO interface.
 */
export function instanceOfProjectDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectDTOFromJSON(json: any): ProjectDTO {
    return ProjectDTOFromJSONTyped(json, false);
}

export function ProjectDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'uniqueNumber': !exists(json, 'uniqueNumber') ? undefined : json['uniqueNumber'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'photoFilename': !exists(json, 'photoFilename') ? undefined : json['photoFilename'],
        'photoFilepath': !exists(json, 'photoFilepath') ? undefined : json['photoFilepath'],
        'photoUrl': !exists(json, 'photoUrl') ? undefined : json['photoUrl'],
        'photoMd5': !exists(json, 'photoMd5') ? undefined : json['photoMd5'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'processor': !exists(json, 'processor') ? undefined : CompanyFromJSON(json['processor']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
        'photoAuthor': !exists(json, 'photoAuthor') ? undefined : UserFromJSON(json['photoAuthor']),
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ProductFromJSON)),
    };
}

export function ProjectDTOToJSON(value?: ProjectDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'uniqueNumber': value.uniqueNumber,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'currentState': value.currentState,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'sumCubage': value.sumCubage,
        'photoFilename': value.photoFilename,
        'photoFilepath': value.photoFilepath,
        'photoUrl': value.photoUrl,
        'photoMd5': value.photoMd5,
        'uuid': value.uuid,
        'processor': CompanyToJSON(value.processor),
        'author': UserToJSON(value.author),
        'photoAuthor': UserToJSON(value.photoAuthor),
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ProductToJSON)),
    };
}

