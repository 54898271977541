/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyAppearance } from './CompanyAppearance';
import {
    CompanyAppearanceFromJSON,
    CompanyAppearanceFromJSONTyped,
    CompanyAppearanceToJSON,
} from './CompanyAppearance';
import type { CompanyPacketWoodQuality } from './CompanyPacketWoodQuality';
import {
    CompanyPacketWoodQualityFromJSON,
    CompanyPacketWoodQualityFromJSONTyped,
    CompanyPacketWoodQualityToJSON,
} from './CompanyPacketWoodQuality';
import type { CompanyProductType } from './CompanyProductType';
import {
    CompanyProductTypeFromJSON,
    CompanyProductTypeFromJSONTyped,
    CompanyProductTypeToJSON,
} from './CompanyProductType';
import type { CompanySurface } from './CompanySurface';
import {
    CompanySurfaceFromJSON,
    CompanySurfaceFromJSONTyped,
    CompanySurfaceToJSON,
} from './CompanySurface';
import type { Logging } from './Logging';
import {
    LoggingFromJSON,
    LoggingFromJSONTyped,
    LoggingToJSON,
} from './Logging';
import type { UserCompany } from './UserCompany';
import {
    UserCompanyFromJSON,
    UserCompanyFromJSONTyped,
    UserCompanyToJSON,
} from './UserCompany';
import type { UserCompanyInvite } from './UserCompanyInvite';
import {
    UserCompanyInviteFromJSON,
    UserCompanyInviteFromJSONTyped,
    UserCompanyInviteToJSON,
} from './UserCompanyInvite';
import type { XmlExportErp } from './XmlExportErp';
import {
    XmlExportErpFromJSON,
    XmlExportErpFromJSONTyped,
    XmlExportErpToJSON,
} from './XmlExportErp';

/**
 * Entities
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    prefixIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    idiNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    logoFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    logoFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    logoUrl?: string;
    /**
     * 
     * @type {Set<Company>}
     * @memberof Company
     */
    sawmills?: Set<Company>;
    /**
     * 
     * @type {Set<Company>}
     * @memberof Company
     */
    processors?: Set<Company>;
    /**
     * 
     * @type {Set<XmlExportErp>}
     * @memberof Company
     */
    xmlExportErps?: Set<XmlExportErp>;
    /**
     * 
     * @type {Set<CompanyAppearance>}
     * @memberof Company
     */
    companyAppearances?: Set<CompanyAppearance>;
    /**
     * 
     * @type {Set<CompanyProductType>}
     * @memberof Company
     */
    companyProductTypes?: Set<CompanyProductType>;
    /**
     * 
     * @type {Set<CompanyPacketWoodQuality>}
     * @memberof Company
     */
    companyPacketWoodQualities?: Set<CompanyPacketWoodQuality>;
    /**
     * 
     * @type {Set<CompanySurface>}
     * @memberof Company
     */
    companySurfaces?: Set<CompanySurface>;
    /**
     * 
     * @type {Set<Logging>}
     * @memberof Company
     */
    loggings?: Set<Logging>;
    /**
     * 
     * @type {Set<UserCompany>}
     * @memberof Company
     */
    userCompanies?: Set<UserCompany>;
    /**
     * 
     * @type {Set<UserCompanyInvite>}
     * @memberof Company
     */
    userCompanyInvites?: Set<UserCompanyInvite>;
    /**
     * 
     * @type {Set<Company>}
     * @memberof Company
     */
    forestries?: Set<Company>;
    /**
     * 
     * @type {Set<Company>}
     * @memberof Company
     */
    sawmillSuppliers?: Set<Company>;
}

/**
 * Check if a given object implements the Company interface.
 */
export function instanceOfCompany(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'prefixIdentifier': !exists(json, 'prefixIdentifier') ? undefined : json['prefixIdentifier'],
        'idiNumber': !exists(json, 'idiNumber') ? undefined : json['idiNumber'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'logoFilename': !exists(json, 'logoFilename') ? undefined : json['logoFilename'],
        'logoFilepath': !exists(json, 'logoFilepath') ? undefined : json['logoFilepath'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'sawmills': !exists(json, 'sawmills') ? undefined : (new Set((json['sawmills'] as Array<any>).map(CompanyFromJSON))),
        'processors': !exists(json, 'processors') ? undefined : (new Set((json['processors'] as Array<any>).map(CompanyFromJSON))),
        'xmlExportErps': !exists(json, 'xmlExportErps') ? undefined : (new Set((json['xmlExportErps'] as Array<any>).map(XmlExportErpFromJSON))),
        'companyAppearances': !exists(json, 'companyAppearances') ? undefined : (new Set((json['companyAppearances'] as Array<any>).map(CompanyAppearanceFromJSON))),
        'companyProductTypes': !exists(json, 'companyProductTypes') ? undefined : (new Set((json['companyProductTypes'] as Array<any>).map(CompanyProductTypeFromJSON))),
        'companyPacketWoodQualities': !exists(json, 'companyPacketWoodQualities') ? undefined : (new Set((json['companyPacketWoodQualities'] as Array<any>).map(CompanyPacketWoodQualityFromJSON))),
        'companySurfaces': !exists(json, 'companySurfaces') ? undefined : (new Set((json['companySurfaces'] as Array<any>).map(CompanySurfaceFromJSON))),
        'loggings': !exists(json, 'loggings') ? undefined : (new Set((json['loggings'] as Array<any>).map(LoggingFromJSON))),
        'userCompanies': !exists(json, 'userCompanies') ? undefined : (new Set((json['userCompanies'] as Array<any>).map(UserCompanyFromJSON))),
        'userCompanyInvites': !exists(json, 'userCompanyInvites') ? undefined : (new Set((json['userCompanyInvites'] as Array<any>).map(UserCompanyInviteFromJSON))),
        'forestries': !exists(json, 'forestries') ? undefined : (new Set((json['forestries'] as Array<any>).map(CompanyFromJSON))),
        'sawmillSuppliers': !exists(json, 'sawmillSuppliers') ? undefined : (new Set((json['sawmillSuppliers'] as Array<any>).map(CompanyFromJSON))),
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'prefixIdentifier': value.prefixIdentifier,
        'idiNumber': value.idiNumber,
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
        'city': value.city,
        'zipCode': value.zipCode,
        'logoFilename': value.logoFilename,
        'logoFilepath': value.logoFilepath,
        'logoUrl': value.logoUrl,
        'sawmills': value.sawmills === undefined ? undefined : (Array.from(value.sawmills as Set<any>).map(CompanyToJSON)),
        'processors': value.processors === undefined ? undefined : (Array.from(value.processors as Set<any>).map(CompanyToJSON)),
        'xmlExportErps': value.xmlExportErps === undefined ? undefined : (Array.from(value.xmlExportErps as Set<any>).map(XmlExportErpToJSON)),
        'companyAppearances': value.companyAppearances === undefined ? undefined : (Array.from(value.companyAppearances as Set<any>).map(CompanyAppearanceToJSON)),
        'companyProductTypes': value.companyProductTypes === undefined ? undefined : (Array.from(value.companyProductTypes as Set<any>).map(CompanyProductTypeToJSON)),
        'companyPacketWoodQualities': value.companyPacketWoodQualities === undefined ? undefined : (Array.from(value.companyPacketWoodQualities as Set<any>).map(CompanyPacketWoodQualityToJSON)),
        'companySurfaces': value.companySurfaces === undefined ? undefined : (Array.from(value.companySurfaces as Set<any>).map(CompanySurfaceToJSON)),
        'loggings': value.loggings === undefined ? undefined : (Array.from(value.loggings as Set<any>).map(LoggingToJSON)),
        'userCompanies': value.userCompanies === undefined ? undefined : (Array.from(value.userCompanies as Set<any>).map(UserCompanyToJSON)),
        'userCompanyInvites': value.userCompanyInvites === undefined ? undefined : (Array.from(value.userCompanyInvites as Set<any>).map(UserCompanyInviteToJSON)),
        'forestries': value.forestries === undefined ? undefined : (Array.from(value.forestries as Set<any>).map(CompanyToJSON)),
        'sawmillSuppliers': value.sawmillSuppliers === undefined ? undefined : (Array.from(value.sawmillSuppliers as Set<any>).map(CompanyToJSON)),
    };
}

