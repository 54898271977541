/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TrunkDiameterClassTable,
} from '../models';
import {
    TrunkDiameterClassTableFromJSON,
    TrunkDiameterClassTableToJSON,
} from '../models';

export interface CreateTrunkDiameterClassTableRequest {
    trunkDiameterClassTable: TrunkDiameterClassTable;
}

export interface DeleteTrunkDiameterClassTableRequest {
    id: number;
}

export interface GetTrunkDiameterClassTableRequest {
    id: number;
}

export interface PartialUpdateTrunkDiameterClassTableRequest {
    id: number;
    trunkDiameterClassTable: TrunkDiameterClassTable;
}

export interface UpdateTrunkDiameterClassTableRequest {
    id: number;
    trunkDiameterClassTable: TrunkDiameterClassTable;
}

/**
 * 
 */
export class TrunkDiameterClassTableResourceApi extends runtime.BaseAPI {

    /**
     */
    async createTrunkDiameterClassTableRaw(requestParameters: CreateTrunkDiameterClassTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkDiameterClassTable>> {
        if (requestParameters.trunkDiameterClassTable === null || requestParameters.trunkDiameterClassTable === undefined) {
            throw new runtime.RequiredError('trunkDiameterClassTable','Required parameter requestParameters.trunkDiameterClassTable was null or undefined when calling createTrunkDiameterClassTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-diameter-class-tables`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkDiameterClassTableToJSON(requestParameters.trunkDiameterClassTable),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkDiameterClassTableFromJSON(jsonValue));
    }

    /**
     */
    async createTrunkDiameterClassTable(requestParameters: CreateTrunkDiameterClassTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkDiameterClassTable> {
        const response = await this.createTrunkDiameterClassTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTrunkDiameterClassTableRaw(requestParameters: DeleteTrunkDiameterClassTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTrunkDiameterClassTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-diameter-class-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTrunkDiameterClassTable(requestParameters: DeleteTrunkDiameterClassTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTrunkDiameterClassTableRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllTrunkDiameterClassTablesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TrunkDiameterClassTable>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-diameter-class-tables`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrunkDiameterClassTableFromJSON));
    }

    /**
     */
    async getAllTrunkDiameterClassTables(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TrunkDiameterClassTable>> {
        const response = await this.getAllTrunkDiameterClassTablesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTrunkDiameterClassTableRaw(requestParameters: GetTrunkDiameterClassTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkDiameterClassTable>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTrunkDiameterClassTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-diameter-class-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkDiameterClassTableFromJSON(jsonValue));
    }

    /**
     */
    async getTrunkDiameterClassTable(requestParameters: GetTrunkDiameterClassTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkDiameterClassTable> {
        const response = await this.getTrunkDiameterClassTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateTrunkDiameterClassTableRaw(requestParameters: PartialUpdateTrunkDiameterClassTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkDiameterClassTable>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateTrunkDiameterClassTable.');
        }

        if (requestParameters.trunkDiameterClassTable === null || requestParameters.trunkDiameterClassTable === undefined) {
            throw new runtime.RequiredError('trunkDiameterClassTable','Required parameter requestParameters.trunkDiameterClassTable was null or undefined when calling partialUpdateTrunkDiameterClassTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-diameter-class-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkDiameterClassTableToJSON(requestParameters.trunkDiameterClassTable),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkDiameterClassTableFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateTrunkDiameterClassTable(requestParameters: PartialUpdateTrunkDiameterClassTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkDiameterClassTable> {
        const response = await this.partialUpdateTrunkDiameterClassTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTrunkDiameterClassTableRaw(requestParameters: UpdateTrunkDiameterClassTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkDiameterClassTable>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTrunkDiameterClassTable.');
        }

        if (requestParameters.trunkDiameterClassTable === null || requestParameters.trunkDiameterClassTable === undefined) {
            throw new runtime.RequiredError('trunkDiameterClassTable','Required parameter requestParameters.trunkDiameterClassTable was null or undefined when calling updateTrunkDiameterClassTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-diameter-class-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkDiameterClassTableToJSON(requestParameters.trunkDiameterClassTable),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkDiameterClassTableFromJSON(jsonValue));
    }

    /**
     */
    async updateTrunkDiameterClassTable(requestParameters: UpdateTrunkDiameterClassTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkDiameterClassTable> {
        const response = await this.updateTrunkDiameterClassTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
