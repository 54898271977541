/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OtherAssortment,
} from '../models';
import {
    OtherAssortmentFromJSON,
    OtherAssortmentToJSON,
} from '../models';

export interface CreateOtherAssortmentRequest {
    otherAssortment: OtherAssortment;
}

export interface DeleteOtherAssortmentRequest {
    id: number;
}

export interface GetOtherAssortmentRequest {
    id: number;
}

export interface PartialUpdateOtherAssortmentRequest {
    id: number;
    otherAssortment: OtherAssortment;
}

export interface UpdateOtherAssortmentRequest {
    id: number;
    otherAssortment: OtherAssortment;
}

/**
 * 
 */
export class OtherAssortmentResourceApi extends runtime.BaseAPI {

    /**
     */
    async createOtherAssortmentRaw(requestParameters: CreateOtherAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OtherAssortment>> {
        if (requestParameters.otherAssortment === null || requestParameters.otherAssortment === undefined) {
            throw new runtime.RequiredError('otherAssortment','Required parameter requestParameters.otherAssortment was null or undefined when calling createOtherAssortment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/other-assortments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OtherAssortmentToJSON(requestParameters.otherAssortment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OtherAssortmentFromJSON(jsonValue));
    }

    /**
     */
    async createOtherAssortment(requestParameters: CreateOtherAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OtherAssortment> {
        const response = await this.createOtherAssortmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOtherAssortmentRaw(requestParameters: DeleteOtherAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOtherAssortment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/other-assortments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteOtherAssortment(requestParameters: DeleteOtherAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOtherAssortmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllOtherAssortmentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OtherAssortment>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/other-assortments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OtherAssortmentFromJSON));
    }

    /**
     */
    async getAllOtherAssortments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OtherAssortment>> {
        const response = await this.getAllOtherAssortmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getOtherAssortmentRaw(requestParameters: GetOtherAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OtherAssortment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOtherAssortment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/other-assortments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OtherAssortmentFromJSON(jsonValue));
    }

    /**
     */
    async getOtherAssortment(requestParameters: GetOtherAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OtherAssortment> {
        const response = await this.getOtherAssortmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateOtherAssortmentRaw(requestParameters: PartialUpdateOtherAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OtherAssortment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateOtherAssortment.');
        }

        if (requestParameters.otherAssortment === null || requestParameters.otherAssortment === undefined) {
            throw new runtime.RequiredError('otherAssortment','Required parameter requestParameters.otherAssortment was null or undefined when calling partialUpdateOtherAssortment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/other-assortments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OtherAssortmentToJSON(requestParameters.otherAssortment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OtherAssortmentFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateOtherAssortment(requestParameters: PartialUpdateOtherAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OtherAssortment> {
        const response = await this.partialUpdateOtherAssortmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOtherAssortmentRaw(requestParameters: UpdateOtherAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OtherAssortment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOtherAssortment.');
        }

        if (requestParameters.otherAssortment === null || requestParameters.otherAssortment === undefined) {
            throw new runtime.RequiredError('otherAssortment','Required parameter requestParameters.otherAssortment was null or undefined when calling updateOtherAssortment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/other-assortments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OtherAssortmentToJSON(requestParameters.otherAssortment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OtherAssortmentFromJSON(jsonValue));
    }

    /**
     */
    async updateOtherAssortment(requestParameters: UpdateOtherAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OtherAssortment> {
        const response = await this.updateOtherAssortmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
