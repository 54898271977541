import { PlatformHelper } from '@helpers/PlatformHelper';
import { resetInputFocus } from '@redux/features/utility/inputSlice';
import React from 'react';
import { Keyboard, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useDispatch } from 'react-redux';
import { sleep, truncate } from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { UrstammStyle } from '../../../utils/styles/UrstammStyle';
import UrstammIcon, { UrstammIconProps } from '../icon/UrstammIcon';

export interface UrstammButtonStyleProps {
  containerViewStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  buttonBackground?: string;
  buttonBorderStyle?: StyleProp<ViewStyle>;
}

export default function UrstammButtonBase(props: {
  testID: string;
  text?: string;
  style: UrstammButtonStyleProps;
  icon?: UrstammIconProps;
  onSubmit?: any;
  disabled?: any;
  counter?: string;
}) {
  const dispatch = useDispatch();

  const submit = async () => {
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    props.onSubmit();
    dispatch(resetInputFocus());
  };

  return (
    <TouchableOpacity
      disabled={props.disabled}
      testID={props.testID}
      onPress={submit}
      style={[
        props.style.containerViewStyle,
        props.style.buttonBorderStyle,
        UrstammStyle.baseShadow,
        {
          backgroundColor: props.style.buttonBackground ? props.style.buttonBackground : ColorTheme.mainGreen
        },
        { opacity: props.disabled ? 0.5 : 1 },
        { minWidth: 100 }
      ]}>
      {props.icon?.showIcon && (
        <View style={[styles.iconContainer]}>
          <UrstammIcon name={props.icon.iconName} size={24} color={props.icon.iconColor!} />
        </View>
      )}
      <Text
        style={[
          props.style.textStyle,
          props.counter ? [{ flex: 1, alignItems: 'flex-end', textAlign: 'center' }] : null
        ]}>
        {props.counter ? truncate(props.text, 20) : props.text}
      </Text>

      {props.counter ? (
        <Text
          style={[
            props.style.textStyle,
            props.counter ? [{ textAlign: 'right', right: 20, position: 'absolute' }] : null
          ]}>
          {props.counter}
        </Text>
      ) : null}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  disabled: {
    opacity: 0.6
  },
  iconContainer: {
    paddingRight: 14,
    alignItems: 'center'
  }
});
