/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalCompany
 */
export interface ExternalCompany {
    /**
     * 
     * @type {number}
     * @memberof ExternalCompany
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    prefixIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    idiNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    logoFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    logoFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalCompany
     */
    externalCompany?: ExternalCompanyExternalCompanyEnum;
}


/**
 * @export
 */
export const ExternalCompanyExternalCompanyEnum = {
    Forestry: 'FORESTRY',
    Sawmill: 'SAWMILL',
    Processor: 'PROCESSOR',
    Projectmanager: 'PROJECTMANAGER'
} as const;
export type ExternalCompanyExternalCompanyEnum = typeof ExternalCompanyExternalCompanyEnum[keyof typeof ExternalCompanyExternalCompanyEnum];


/**
 * Check if a given object implements the ExternalCompany interface.
 */
export function instanceOfExternalCompany(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExternalCompanyFromJSON(json: any): ExternalCompany {
    return ExternalCompanyFromJSONTyped(json, false);
}

export function ExternalCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalCompany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'prefixIdentifier': !exists(json, 'prefixIdentifier') ? undefined : json['prefixIdentifier'],
        'idiNumber': !exists(json, 'idiNumber') ? undefined : json['idiNumber'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'logoFilename': !exists(json, 'logoFilename') ? undefined : json['logoFilename'],
        'logoFilepath': !exists(json, 'logoFilepath') ? undefined : json['logoFilepath'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'externalCompany': !exists(json, 'externalCompany') ? undefined : json['externalCompany'],
    };
}

export function ExternalCompanyToJSON(value?: ExternalCompany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'prefixIdentifier': value.prefixIdentifier,
        'idiNumber': value.idiNumber,
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
        'city': value.city,
        'zipCode': value.zipCode,
        'logoFilename': value.logoFilename,
        'logoFilepath': value.logoFilepath,
        'logoUrl': value.logoUrl,
        'externalCompany': value.externalCompany,
    };
}

