import { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { createSlice } from '@reduxjs/toolkit';

export interface OutgoingProcessorShippingNoteFilterState {
  value: SlideButtonOption[];
}

const initialState: OutgoingProcessorShippingNoteFilterState = {
  value: []
};

export const outgoingProcessorShippingNoteFilter = createSlice({
  name: 'outgoingProcessorShippingNoteFilter',
  initialState,
  reducers: {
    setOutgoingProcessorShippingNoteFilter: (state, action) => {
      state.value = action.payload;
    },
    setOutgoingProcessorShippingNoteFilterList: (state, action) => {
      state.value = action.payload;
    },
    selectOutgoingProcessorShippingNoteFilter: (state, action) => {
      let selected = action.payload;
      let filterList = { ...state }.value;
      if (filterList) {
        for (let elem of filterList) {
          elem.active = elem.id == selected.id;
        }
        state.value = filterList;
      }
    },
    reset: state => {
      initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setOutgoingProcessorShippingNoteFilter, setOutgoingProcessorShippingNoteFilterList, selectOutgoingProcessorShippingNoteFilter, reset } =
  outgoingProcessorShippingNoteFilter.actions;

export default outgoingProcessorShippingNoteFilter.reducer;
