/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { ExternalCompany } from './ExternalCompany';
import {
    ExternalCompanyFromJSON,
    ExternalCompanyFromJSONTyped,
    ExternalCompanyToJSON,
} from './ExternalCompany';
import type { ProcessorBatch } from './ProcessorBatch';
import {
    ProcessorBatchFromJSON,
    ProcessorBatchFromJSONTyped,
    ProcessorBatchToJSON,
} from './ProcessorBatch';
import type { ProcessorShippingNoteState } from './ProcessorShippingNoteState';
import {
    ProcessorShippingNoteStateFromJSON,
    ProcessorShippingNoteStateFromJSONTyped,
    ProcessorShippingNoteStateToJSON,
} from './ProcessorShippingNoteState';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { ShippingNote } from './ShippingNote';
import {
    ShippingNoteFromJSON,
    ShippingNoteFromJSONTyped,
    ShippingNoteToJSON,
} from './ShippingNote';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ProcessorShippingNote
 */
export interface ProcessorShippingNote {
    /**
     * 
     * @type {number}
     * @memberof ProcessorShippingNote
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNote
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNote
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProcessorShippingNote
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProcessorShippingNote
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNote
     */
    currentState: ProcessorShippingNoteCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNote
     */
    pdfFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNote
     */
    pdfFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNote
     */
    pdfMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNote
     */
    uuid?: string;
    /**
     * 
     * @type {ExternalCompany}
     * @memberof ProcessorShippingNote
     */
    mockProcessor?: ExternalCompany;
    /**
     * 
     * @type {Company}
     * @memberof ProcessorShippingNote
     */
    processor?: Company;
    /**
     * 
     * @type {Company}
     * @memberof ProcessorShippingNote
     */
    creator?: Company;
    /**
     * 
     * @type {User}
     * @memberof ProcessorShippingNote
     */
    pdfAuthor?: User;
    /**
     * 
     * @type {ProcessorBatch}
     * @memberof ProcessorShippingNote
     */
    outgoingBatch?: ProcessorBatch;
    /**
     * 
     * @type {ShippingNote}
     * @memberof ProcessorShippingNote
     */
    referenceShippingNote?: ShippingNote;
    /**
     * 
     * @type {Set<Product>}
     * @memberof ProcessorShippingNote
     */
    incomingProducts?: Set<Product>;
    /**
     * 
     * @type {Set<ProcessorShippingNoteState>}
     * @memberof ProcessorShippingNote
     */
    processorShippingNoteStates?: Set<ProcessorShippingNoteState>;
    /**
     * 
     * @type {Set<Product>}
     * @memberof ProcessorShippingNote
     */
    outgoingProducts?: Set<Product>;
}


/**
 * @export
 */
export const ProcessorShippingNoteCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type ProcessorShippingNoteCurrentStateEnum = typeof ProcessorShippingNoteCurrentStateEnum[keyof typeof ProcessorShippingNoteCurrentStateEnum];


/**
 * Check if a given object implements the ProcessorShippingNote interface.
 */
export function instanceOfProcessorShippingNote(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "currentState" in value;

    return isInstance;
}

export function ProcessorShippingNoteFromJSON(json: any): ProcessorShippingNote {
    return ProcessorShippingNoteFromJSONTyped(json, false);
}

export function ProcessorShippingNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessorShippingNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'currentState': json['currentState'],
        'pdfFilename': !exists(json, 'pdfFilename') ? undefined : json['pdfFilename'],
        'pdfFilepath': !exists(json, 'pdfFilepath') ? undefined : json['pdfFilepath'],
        'pdfMd5': !exists(json, 'pdfMd5') ? undefined : json['pdfMd5'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'mockProcessor': !exists(json, 'mockProcessor') ? undefined : ExternalCompanyFromJSON(json['mockProcessor']),
        'processor': !exists(json, 'processor') ? undefined : CompanyFromJSON(json['processor']),
        'creator': !exists(json, 'creator') ? undefined : CompanyFromJSON(json['creator']),
        'pdfAuthor': !exists(json, 'pdfAuthor') ? undefined : UserFromJSON(json['pdfAuthor']),
        'outgoingBatch': !exists(json, 'outgoingBatch') ? undefined : ProcessorBatchFromJSON(json['outgoingBatch']),
        'referenceShippingNote': !exists(json, 'referenceShippingNote') ? undefined : ShippingNoteFromJSON(json['referenceShippingNote']),
        'incomingProducts': !exists(json, 'incomingProducts') ? undefined : (new Set((json['incomingProducts'] as Array<any>).map(ProductFromJSON))),
        'processorShippingNoteStates': !exists(json, 'processorShippingNoteStates') ? undefined : (new Set((json['processorShippingNoteStates'] as Array<any>).map(ProcessorShippingNoteStateFromJSON))),
        'outgoingProducts': !exists(json, 'outgoingProducts') ? undefined : (new Set((json['outgoingProducts'] as Array<any>).map(ProductFromJSON))),
    };
}

export function ProcessorShippingNoteToJSON(value?: ProcessorShippingNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'sumCubage': value.sumCubage,
        'currentState': value.currentState,
        'pdfFilename': value.pdfFilename,
        'pdfFilepath': value.pdfFilepath,
        'pdfMd5': value.pdfMd5,
        'uuid': value.uuid,
        'mockProcessor': ExternalCompanyToJSON(value.mockProcessor),
        'processor': CompanyToJSON(value.processor),
        'creator': CompanyToJSON(value.creator),
        'pdfAuthor': UserToJSON(value.pdfAuthor),
        'outgoingBatch': ProcessorBatchToJSON(value.outgoingBatch),
        'referenceShippingNote': ShippingNoteToJSON(value.referenceShippingNote),
        'incomingProducts': value.incomingProducts === undefined ? undefined : (Array.from(value.incomingProducts as Set<any>).map(ProductToJSON)),
        'processorShippingNoteStates': value.processorShippingNoteStates === undefined ? undefined : (Array.from(value.processorShippingNoteStates as Set<any>).map(ProcessorShippingNoteStateToJSON)),
        'outgoingProducts': value.outgoingProducts === undefined ? undefined : (Array.from(value.outgoingProducts as Set<any>).map(ProductToJSON)),
    };
}

