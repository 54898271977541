/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProcessorShippingNote,
} from '../models';
import {
    ProcessorShippingNoteFromJSON,
    ProcessorShippingNoteToJSON,
} from '../models';

export interface CountProcessorShippingNotesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    currentStateEquals?: CountProcessorShippingNotesCurrentStateEqualsEnum;
    currentStateNotEquals?: CountProcessorShippingNotesCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<CountProcessorShippingNotesCurrentStateInEnum>;
    currentStateNotIn?: Array<CountProcessorShippingNotesCurrentStateNotInEnum>;
    pdfFilenameContains?: string;
    pdfFilenameDoesNotContain?: string;
    pdfFilenameEquals?: string;
    pdfFilenameNotEquals?: string;
    pdfFilenameSpecified?: boolean;
    pdfFilenameIn?: Array<string>;
    pdfFilenameNotIn?: Array<string>;
    pdfFilepathContains?: string;
    pdfFilepathDoesNotContain?: string;
    pdfFilepathEquals?: string;
    pdfFilepathNotEquals?: string;
    pdfFilepathSpecified?: boolean;
    pdfFilepathIn?: Array<string>;
    pdfFilepathNotIn?: Array<string>;
    pdfMd5Contains?: string;
    pdfMd5DoesNotContain?: string;
    pdfMd5Equals?: string;
    pdfMd5NotEquals?: string;
    pdfMd5Specified?: boolean;
    pdfMd5In?: Array<string>;
    pdfMd5NotIn?: Array<string>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    mockProcessorIdGreaterThan?: number;
    mockProcessorIdLessThan?: number;
    mockProcessorIdGreaterThanOrEqual?: number;
    mockProcessorIdLessThanOrEqual?: number;
    mockProcessorIdEquals?: number;
    mockProcessorIdNotEquals?: number;
    mockProcessorIdSpecified?: boolean;
    mockProcessorIdIn?: Array<number>;
    mockProcessorIdNotIn?: Array<number>;
    processorIdGreaterThan?: number;
    processorIdLessThan?: number;
    processorIdGreaterThanOrEqual?: number;
    processorIdLessThanOrEqual?: number;
    processorIdEquals?: number;
    processorIdNotEquals?: number;
    processorIdSpecified?: boolean;
    processorIdIn?: Array<number>;
    processorIdNotIn?: Array<number>;
    creatorIdGreaterThan?: number;
    creatorIdLessThan?: number;
    creatorIdGreaterThanOrEqual?: number;
    creatorIdLessThanOrEqual?: number;
    creatorIdEquals?: number;
    creatorIdNotEquals?: number;
    creatorIdSpecified?: boolean;
    creatorIdIn?: Array<number>;
    creatorIdNotIn?: Array<number>;
    pdfAuthorIdGreaterThan?: number;
    pdfAuthorIdLessThan?: number;
    pdfAuthorIdGreaterThanOrEqual?: number;
    pdfAuthorIdLessThanOrEqual?: number;
    pdfAuthorIdEquals?: number;
    pdfAuthorIdNotEquals?: number;
    pdfAuthorIdSpecified?: boolean;
    pdfAuthorIdIn?: Array<number>;
    pdfAuthorIdNotIn?: Array<number>;
    outgoingBatchIdGreaterThan?: number;
    outgoingBatchIdLessThan?: number;
    outgoingBatchIdGreaterThanOrEqual?: number;
    outgoingBatchIdLessThanOrEqual?: number;
    outgoingBatchIdEquals?: number;
    outgoingBatchIdNotEquals?: number;
    outgoingBatchIdSpecified?: boolean;
    outgoingBatchIdIn?: Array<number>;
    outgoingBatchIdNotIn?: Array<number>;
    referenceShippingNoteIdGreaterThan?: number;
    referenceShippingNoteIdLessThan?: number;
    referenceShippingNoteIdGreaterThanOrEqual?: number;
    referenceShippingNoteIdLessThanOrEqual?: number;
    referenceShippingNoteIdEquals?: number;
    referenceShippingNoteIdNotEquals?: number;
    referenceShippingNoteIdSpecified?: boolean;
    referenceShippingNoteIdIn?: Array<number>;
    referenceShippingNoteIdNotIn?: Array<number>;
    incomingProductIdGreaterThan?: number;
    incomingProductIdLessThan?: number;
    incomingProductIdGreaterThanOrEqual?: number;
    incomingProductIdLessThanOrEqual?: number;
    incomingProductIdEquals?: number;
    incomingProductIdNotEquals?: number;
    incomingProductIdSpecified?: boolean;
    incomingProductIdIn?: Array<number>;
    incomingProductIdNotIn?: Array<number>;
    processorShippingNoteStateIdGreaterThan?: number;
    processorShippingNoteStateIdLessThan?: number;
    processorShippingNoteStateIdGreaterThanOrEqual?: number;
    processorShippingNoteStateIdLessThanOrEqual?: number;
    processorShippingNoteStateIdEquals?: number;
    processorShippingNoteStateIdNotEquals?: number;
    processorShippingNoteStateIdSpecified?: boolean;
    processorShippingNoteStateIdIn?: Array<number>;
    processorShippingNoteStateIdNotIn?: Array<number>;
    outgoingProductIdGreaterThan?: number;
    outgoingProductIdLessThan?: number;
    outgoingProductIdGreaterThanOrEqual?: number;
    outgoingProductIdLessThanOrEqual?: number;
    outgoingProductIdEquals?: number;
    outgoingProductIdNotEquals?: number;
    outgoingProductIdSpecified?: boolean;
    outgoingProductIdIn?: Array<number>;
    outgoingProductIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateProcessorShippingNoteRequest {
    processorShippingNote: ProcessorShippingNote;
}

export interface DeleteProcessorShippingNoteRequest {
    id: number;
}

export interface GetAllProcessorShippingNotesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    currentStateEquals?: GetAllProcessorShippingNotesCurrentStateEqualsEnum;
    currentStateNotEquals?: GetAllProcessorShippingNotesCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<GetAllProcessorShippingNotesCurrentStateInEnum>;
    currentStateNotIn?: Array<GetAllProcessorShippingNotesCurrentStateNotInEnum>;
    pdfFilenameContains?: string;
    pdfFilenameDoesNotContain?: string;
    pdfFilenameEquals?: string;
    pdfFilenameNotEquals?: string;
    pdfFilenameSpecified?: boolean;
    pdfFilenameIn?: Array<string>;
    pdfFilenameNotIn?: Array<string>;
    pdfFilepathContains?: string;
    pdfFilepathDoesNotContain?: string;
    pdfFilepathEquals?: string;
    pdfFilepathNotEquals?: string;
    pdfFilepathSpecified?: boolean;
    pdfFilepathIn?: Array<string>;
    pdfFilepathNotIn?: Array<string>;
    pdfMd5Contains?: string;
    pdfMd5DoesNotContain?: string;
    pdfMd5Equals?: string;
    pdfMd5NotEquals?: string;
    pdfMd5Specified?: boolean;
    pdfMd5In?: Array<string>;
    pdfMd5NotIn?: Array<string>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    mockProcessorIdGreaterThan?: number;
    mockProcessorIdLessThan?: number;
    mockProcessorIdGreaterThanOrEqual?: number;
    mockProcessorIdLessThanOrEqual?: number;
    mockProcessorIdEquals?: number;
    mockProcessorIdNotEquals?: number;
    mockProcessorIdSpecified?: boolean;
    mockProcessorIdIn?: Array<number>;
    mockProcessorIdNotIn?: Array<number>;
    processorIdGreaterThan?: number;
    processorIdLessThan?: number;
    processorIdGreaterThanOrEqual?: number;
    processorIdLessThanOrEqual?: number;
    processorIdEquals?: number;
    processorIdNotEquals?: number;
    processorIdSpecified?: boolean;
    processorIdIn?: Array<number>;
    processorIdNotIn?: Array<number>;
    creatorIdGreaterThan?: number;
    creatorIdLessThan?: number;
    creatorIdGreaterThanOrEqual?: number;
    creatorIdLessThanOrEqual?: number;
    creatorIdEquals?: number;
    creatorIdNotEquals?: number;
    creatorIdSpecified?: boolean;
    creatorIdIn?: Array<number>;
    creatorIdNotIn?: Array<number>;
    pdfAuthorIdGreaterThan?: number;
    pdfAuthorIdLessThan?: number;
    pdfAuthorIdGreaterThanOrEqual?: number;
    pdfAuthorIdLessThanOrEqual?: number;
    pdfAuthorIdEquals?: number;
    pdfAuthorIdNotEquals?: number;
    pdfAuthorIdSpecified?: boolean;
    pdfAuthorIdIn?: Array<number>;
    pdfAuthorIdNotIn?: Array<number>;
    outgoingBatchIdGreaterThan?: number;
    outgoingBatchIdLessThan?: number;
    outgoingBatchIdGreaterThanOrEqual?: number;
    outgoingBatchIdLessThanOrEqual?: number;
    outgoingBatchIdEquals?: number;
    outgoingBatchIdNotEquals?: number;
    outgoingBatchIdSpecified?: boolean;
    outgoingBatchIdIn?: Array<number>;
    outgoingBatchIdNotIn?: Array<number>;
    referenceShippingNoteIdGreaterThan?: number;
    referenceShippingNoteIdLessThan?: number;
    referenceShippingNoteIdGreaterThanOrEqual?: number;
    referenceShippingNoteIdLessThanOrEqual?: number;
    referenceShippingNoteIdEquals?: number;
    referenceShippingNoteIdNotEquals?: number;
    referenceShippingNoteIdSpecified?: boolean;
    referenceShippingNoteIdIn?: Array<number>;
    referenceShippingNoteIdNotIn?: Array<number>;
    incomingProductIdGreaterThan?: number;
    incomingProductIdLessThan?: number;
    incomingProductIdGreaterThanOrEqual?: number;
    incomingProductIdLessThanOrEqual?: number;
    incomingProductIdEquals?: number;
    incomingProductIdNotEquals?: number;
    incomingProductIdSpecified?: boolean;
    incomingProductIdIn?: Array<number>;
    incomingProductIdNotIn?: Array<number>;
    processorShippingNoteStateIdGreaterThan?: number;
    processorShippingNoteStateIdLessThan?: number;
    processorShippingNoteStateIdGreaterThanOrEqual?: number;
    processorShippingNoteStateIdLessThanOrEqual?: number;
    processorShippingNoteStateIdEquals?: number;
    processorShippingNoteStateIdNotEquals?: number;
    processorShippingNoteStateIdSpecified?: boolean;
    processorShippingNoteStateIdIn?: Array<number>;
    processorShippingNoteStateIdNotIn?: Array<number>;
    outgoingProductIdGreaterThan?: number;
    outgoingProductIdLessThan?: number;
    outgoingProductIdGreaterThanOrEqual?: number;
    outgoingProductIdLessThanOrEqual?: number;
    outgoingProductIdEquals?: number;
    outgoingProductIdNotEquals?: number;
    outgoingProductIdSpecified?: boolean;
    outgoingProductIdIn?: Array<number>;
    outgoingProductIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetProcessorShippingNoteRequest {
    id: number;
}

export interface PartialUpdateProcessorShippingNoteRequest {
    id: number;
    processorShippingNote: ProcessorShippingNote;
}

export interface UpdateProcessorShippingNoteRequest {
    id: number;
    processorShippingNote: ProcessorShippingNote;
}

/**
 * 
 */
export class ProcessorShippingNoteResourceApi extends runtime.BaseAPI {

    /**
     */
    async countProcessorShippingNotesRaw(requestParameters: CountProcessorShippingNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.pdfFilenameContains !== undefined) {
            queryParameters['pdfFilename.contains'] = requestParameters.pdfFilenameContains;
        }

        if (requestParameters.pdfFilenameDoesNotContain !== undefined) {
            queryParameters['pdfFilename.doesNotContain'] = requestParameters.pdfFilenameDoesNotContain;
        }

        if (requestParameters.pdfFilenameEquals !== undefined) {
            queryParameters['pdfFilename.equals'] = requestParameters.pdfFilenameEquals;
        }

        if (requestParameters.pdfFilenameNotEquals !== undefined) {
            queryParameters['pdfFilename.notEquals'] = requestParameters.pdfFilenameNotEquals;
        }

        if (requestParameters.pdfFilenameSpecified !== undefined) {
            queryParameters['pdfFilename.specified'] = requestParameters.pdfFilenameSpecified;
        }

        if (requestParameters.pdfFilenameIn) {
            queryParameters['pdfFilename.in'] = requestParameters.pdfFilenameIn;
        }

        if (requestParameters.pdfFilenameNotIn) {
            queryParameters['pdfFilename.notIn'] = requestParameters.pdfFilenameNotIn;
        }

        if (requestParameters.pdfFilepathContains !== undefined) {
            queryParameters['pdfFilepath.contains'] = requestParameters.pdfFilepathContains;
        }

        if (requestParameters.pdfFilepathDoesNotContain !== undefined) {
            queryParameters['pdfFilepath.doesNotContain'] = requestParameters.pdfFilepathDoesNotContain;
        }

        if (requestParameters.pdfFilepathEquals !== undefined) {
            queryParameters['pdfFilepath.equals'] = requestParameters.pdfFilepathEquals;
        }

        if (requestParameters.pdfFilepathNotEquals !== undefined) {
            queryParameters['pdfFilepath.notEquals'] = requestParameters.pdfFilepathNotEquals;
        }

        if (requestParameters.pdfFilepathSpecified !== undefined) {
            queryParameters['pdfFilepath.specified'] = requestParameters.pdfFilepathSpecified;
        }

        if (requestParameters.pdfFilepathIn) {
            queryParameters['pdfFilepath.in'] = requestParameters.pdfFilepathIn;
        }

        if (requestParameters.pdfFilepathNotIn) {
            queryParameters['pdfFilepath.notIn'] = requestParameters.pdfFilepathNotIn;
        }

        if (requestParameters.pdfMd5Contains !== undefined) {
            queryParameters['pdfMd5.contains'] = requestParameters.pdfMd5Contains;
        }

        if (requestParameters.pdfMd5DoesNotContain !== undefined) {
            queryParameters['pdfMd5.doesNotContain'] = requestParameters.pdfMd5DoesNotContain;
        }

        if (requestParameters.pdfMd5Equals !== undefined) {
            queryParameters['pdfMd5.equals'] = requestParameters.pdfMd5Equals;
        }

        if (requestParameters.pdfMd5NotEquals !== undefined) {
            queryParameters['pdfMd5.notEquals'] = requestParameters.pdfMd5NotEquals;
        }

        if (requestParameters.pdfMd5Specified !== undefined) {
            queryParameters['pdfMd5.specified'] = requestParameters.pdfMd5Specified;
        }

        if (requestParameters.pdfMd5In) {
            queryParameters['pdfMd5.in'] = requestParameters.pdfMd5In;
        }

        if (requestParameters.pdfMd5NotIn) {
            queryParameters['pdfMd5.notIn'] = requestParameters.pdfMd5NotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.mockProcessorIdGreaterThan !== undefined) {
            queryParameters['mockProcessorId.greaterThan'] = requestParameters.mockProcessorIdGreaterThan;
        }

        if (requestParameters.mockProcessorIdLessThan !== undefined) {
            queryParameters['mockProcessorId.lessThan'] = requestParameters.mockProcessorIdLessThan;
        }

        if (requestParameters.mockProcessorIdGreaterThanOrEqual !== undefined) {
            queryParameters['mockProcessorId.greaterThanOrEqual'] = requestParameters.mockProcessorIdGreaterThanOrEqual;
        }

        if (requestParameters.mockProcessorIdLessThanOrEqual !== undefined) {
            queryParameters['mockProcessorId.lessThanOrEqual'] = requestParameters.mockProcessorIdLessThanOrEqual;
        }

        if (requestParameters.mockProcessorIdEquals !== undefined) {
            queryParameters['mockProcessorId.equals'] = requestParameters.mockProcessorIdEquals;
        }

        if (requestParameters.mockProcessorIdNotEquals !== undefined) {
            queryParameters['mockProcessorId.notEquals'] = requestParameters.mockProcessorIdNotEquals;
        }

        if (requestParameters.mockProcessorIdSpecified !== undefined) {
            queryParameters['mockProcessorId.specified'] = requestParameters.mockProcessorIdSpecified;
        }

        if (requestParameters.mockProcessorIdIn) {
            queryParameters['mockProcessorId.in'] = requestParameters.mockProcessorIdIn;
        }

        if (requestParameters.mockProcessorIdNotIn) {
            queryParameters['mockProcessorId.notIn'] = requestParameters.mockProcessorIdNotIn;
        }

        if (requestParameters.processorIdGreaterThan !== undefined) {
            queryParameters['processorId.greaterThan'] = requestParameters.processorIdGreaterThan;
        }

        if (requestParameters.processorIdLessThan !== undefined) {
            queryParameters['processorId.lessThan'] = requestParameters.processorIdLessThan;
        }

        if (requestParameters.processorIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorId.greaterThanOrEqual'] = requestParameters.processorIdGreaterThanOrEqual;
        }

        if (requestParameters.processorIdLessThanOrEqual !== undefined) {
            queryParameters['processorId.lessThanOrEqual'] = requestParameters.processorIdLessThanOrEqual;
        }

        if (requestParameters.processorIdEquals !== undefined) {
            queryParameters['processorId.equals'] = requestParameters.processorIdEquals;
        }

        if (requestParameters.processorIdNotEquals !== undefined) {
            queryParameters['processorId.notEquals'] = requestParameters.processorIdNotEquals;
        }

        if (requestParameters.processorIdSpecified !== undefined) {
            queryParameters['processorId.specified'] = requestParameters.processorIdSpecified;
        }

        if (requestParameters.processorIdIn) {
            queryParameters['processorId.in'] = requestParameters.processorIdIn;
        }

        if (requestParameters.processorIdNotIn) {
            queryParameters['processorId.notIn'] = requestParameters.processorIdNotIn;
        }

        if (requestParameters.creatorIdGreaterThan !== undefined) {
            queryParameters['creatorId.greaterThan'] = requestParameters.creatorIdGreaterThan;
        }

        if (requestParameters.creatorIdLessThan !== undefined) {
            queryParameters['creatorId.lessThan'] = requestParameters.creatorIdLessThan;
        }

        if (requestParameters.creatorIdGreaterThanOrEqual !== undefined) {
            queryParameters['creatorId.greaterThanOrEqual'] = requestParameters.creatorIdGreaterThanOrEqual;
        }

        if (requestParameters.creatorIdLessThanOrEqual !== undefined) {
            queryParameters['creatorId.lessThanOrEqual'] = requestParameters.creatorIdLessThanOrEqual;
        }

        if (requestParameters.creatorIdEquals !== undefined) {
            queryParameters['creatorId.equals'] = requestParameters.creatorIdEquals;
        }

        if (requestParameters.creatorIdNotEquals !== undefined) {
            queryParameters['creatorId.notEquals'] = requestParameters.creatorIdNotEquals;
        }

        if (requestParameters.creatorIdSpecified !== undefined) {
            queryParameters['creatorId.specified'] = requestParameters.creatorIdSpecified;
        }

        if (requestParameters.creatorIdIn) {
            queryParameters['creatorId.in'] = requestParameters.creatorIdIn;
        }

        if (requestParameters.creatorIdNotIn) {
            queryParameters['creatorId.notIn'] = requestParameters.creatorIdNotIn;
        }

        if (requestParameters.pdfAuthorIdGreaterThan !== undefined) {
            queryParameters['pdfAuthorId.greaterThan'] = requestParameters.pdfAuthorIdGreaterThan;
        }

        if (requestParameters.pdfAuthorIdLessThan !== undefined) {
            queryParameters['pdfAuthorId.lessThan'] = requestParameters.pdfAuthorIdLessThan;
        }

        if (requestParameters.pdfAuthorIdGreaterThanOrEqual !== undefined) {
            queryParameters['pdfAuthorId.greaterThanOrEqual'] = requestParameters.pdfAuthorIdGreaterThanOrEqual;
        }

        if (requestParameters.pdfAuthorIdLessThanOrEqual !== undefined) {
            queryParameters['pdfAuthorId.lessThanOrEqual'] = requestParameters.pdfAuthorIdLessThanOrEqual;
        }

        if (requestParameters.pdfAuthorIdEquals !== undefined) {
            queryParameters['pdfAuthorId.equals'] = requestParameters.pdfAuthorIdEquals;
        }

        if (requestParameters.pdfAuthorIdNotEquals !== undefined) {
            queryParameters['pdfAuthorId.notEquals'] = requestParameters.pdfAuthorIdNotEquals;
        }

        if (requestParameters.pdfAuthorIdSpecified !== undefined) {
            queryParameters['pdfAuthorId.specified'] = requestParameters.pdfAuthorIdSpecified;
        }

        if (requestParameters.pdfAuthorIdIn) {
            queryParameters['pdfAuthorId.in'] = requestParameters.pdfAuthorIdIn;
        }

        if (requestParameters.pdfAuthorIdNotIn) {
            queryParameters['pdfAuthorId.notIn'] = requestParameters.pdfAuthorIdNotIn;
        }

        if (requestParameters.outgoingBatchIdGreaterThan !== undefined) {
            queryParameters['outgoingBatchId.greaterThan'] = requestParameters.outgoingBatchIdGreaterThan;
        }

        if (requestParameters.outgoingBatchIdLessThan !== undefined) {
            queryParameters['outgoingBatchId.lessThan'] = requestParameters.outgoingBatchIdLessThan;
        }

        if (requestParameters.outgoingBatchIdGreaterThanOrEqual !== undefined) {
            queryParameters['outgoingBatchId.greaterThanOrEqual'] = requestParameters.outgoingBatchIdGreaterThanOrEqual;
        }

        if (requestParameters.outgoingBatchIdLessThanOrEqual !== undefined) {
            queryParameters['outgoingBatchId.lessThanOrEqual'] = requestParameters.outgoingBatchIdLessThanOrEqual;
        }

        if (requestParameters.outgoingBatchIdEquals !== undefined) {
            queryParameters['outgoingBatchId.equals'] = requestParameters.outgoingBatchIdEquals;
        }

        if (requestParameters.outgoingBatchIdNotEquals !== undefined) {
            queryParameters['outgoingBatchId.notEquals'] = requestParameters.outgoingBatchIdNotEquals;
        }

        if (requestParameters.outgoingBatchIdSpecified !== undefined) {
            queryParameters['outgoingBatchId.specified'] = requestParameters.outgoingBatchIdSpecified;
        }

        if (requestParameters.outgoingBatchIdIn) {
            queryParameters['outgoingBatchId.in'] = requestParameters.outgoingBatchIdIn;
        }

        if (requestParameters.outgoingBatchIdNotIn) {
            queryParameters['outgoingBatchId.notIn'] = requestParameters.outgoingBatchIdNotIn;
        }

        if (requestParameters.referenceShippingNoteIdGreaterThan !== undefined) {
            queryParameters['referenceShippingNoteId.greaterThan'] = requestParameters.referenceShippingNoteIdGreaterThan;
        }

        if (requestParameters.referenceShippingNoteIdLessThan !== undefined) {
            queryParameters['referenceShippingNoteId.lessThan'] = requestParameters.referenceShippingNoteIdLessThan;
        }

        if (requestParameters.referenceShippingNoteIdGreaterThanOrEqual !== undefined) {
            queryParameters['referenceShippingNoteId.greaterThanOrEqual'] = requestParameters.referenceShippingNoteIdGreaterThanOrEqual;
        }

        if (requestParameters.referenceShippingNoteIdLessThanOrEqual !== undefined) {
            queryParameters['referenceShippingNoteId.lessThanOrEqual'] = requestParameters.referenceShippingNoteIdLessThanOrEqual;
        }

        if (requestParameters.referenceShippingNoteIdEquals !== undefined) {
            queryParameters['referenceShippingNoteId.equals'] = requestParameters.referenceShippingNoteIdEquals;
        }

        if (requestParameters.referenceShippingNoteIdNotEquals !== undefined) {
            queryParameters['referenceShippingNoteId.notEquals'] = requestParameters.referenceShippingNoteIdNotEquals;
        }

        if (requestParameters.referenceShippingNoteIdSpecified !== undefined) {
            queryParameters['referenceShippingNoteId.specified'] = requestParameters.referenceShippingNoteIdSpecified;
        }

        if (requestParameters.referenceShippingNoteIdIn) {
            queryParameters['referenceShippingNoteId.in'] = requestParameters.referenceShippingNoteIdIn;
        }

        if (requestParameters.referenceShippingNoteIdNotIn) {
            queryParameters['referenceShippingNoteId.notIn'] = requestParameters.referenceShippingNoteIdNotIn;
        }

        if (requestParameters.incomingProductIdGreaterThan !== undefined) {
            queryParameters['incomingProductId.greaterThan'] = requestParameters.incomingProductIdGreaterThan;
        }

        if (requestParameters.incomingProductIdLessThan !== undefined) {
            queryParameters['incomingProductId.lessThan'] = requestParameters.incomingProductIdLessThan;
        }

        if (requestParameters.incomingProductIdGreaterThanOrEqual !== undefined) {
            queryParameters['incomingProductId.greaterThanOrEqual'] = requestParameters.incomingProductIdGreaterThanOrEqual;
        }

        if (requestParameters.incomingProductIdLessThanOrEqual !== undefined) {
            queryParameters['incomingProductId.lessThanOrEqual'] = requestParameters.incomingProductIdLessThanOrEqual;
        }

        if (requestParameters.incomingProductIdEquals !== undefined) {
            queryParameters['incomingProductId.equals'] = requestParameters.incomingProductIdEquals;
        }

        if (requestParameters.incomingProductIdNotEquals !== undefined) {
            queryParameters['incomingProductId.notEquals'] = requestParameters.incomingProductIdNotEquals;
        }

        if (requestParameters.incomingProductIdSpecified !== undefined) {
            queryParameters['incomingProductId.specified'] = requestParameters.incomingProductIdSpecified;
        }

        if (requestParameters.incomingProductIdIn) {
            queryParameters['incomingProductId.in'] = requestParameters.incomingProductIdIn;
        }

        if (requestParameters.incomingProductIdNotIn) {
            queryParameters['incomingProductId.notIn'] = requestParameters.incomingProductIdNotIn;
        }

        if (requestParameters.processorShippingNoteStateIdGreaterThan !== undefined) {
            queryParameters['processorShippingNoteStateId.greaterThan'] = requestParameters.processorShippingNoteStateIdGreaterThan;
        }

        if (requestParameters.processorShippingNoteStateIdLessThan !== undefined) {
            queryParameters['processorShippingNoteStateId.lessThan'] = requestParameters.processorShippingNoteStateIdLessThan;
        }

        if (requestParameters.processorShippingNoteStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorShippingNoteStateId.greaterThanOrEqual'] = requestParameters.processorShippingNoteStateIdGreaterThanOrEqual;
        }

        if (requestParameters.processorShippingNoteStateIdLessThanOrEqual !== undefined) {
            queryParameters['processorShippingNoteStateId.lessThanOrEqual'] = requestParameters.processorShippingNoteStateIdLessThanOrEqual;
        }

        if (requestParameters.processorShippingNoteStateIdEquals !== undefined) {
            queryParameters['processorShippingNoteStateId.equals'] = requestParameters.processorShippingNoteStateIdEquals;
        }

        if (requestParameters.processorShippingNoteStateIdNotEquals !== undefined) {
            queryParameters['processorShippingNoteStateId.notEquals'] = requestParameters.processorShippingNoteStateIdNotEquals;
        }

        if (requestParameters.processorShippingNoteStateIdSpecified !== undefined) {
            queryParameters['processorShippingNoteStateId.specified'] = requestParameters.processorShippingNoteStateIdSpecified;
        }

        if (requestParameters.processorShippingNoteStateIdIn) {
            queryParameters['processorShippingNoteStateId.in'] = requestParameters.processorShippingNoteStateIdIn;
        }

        if (requestParameters.processorShippingNoteStateIdNotIn) {
            queryParameters['processorShippingNoteStateId.notIn'] = requestParameters.processorShippingNoteStateIdNotIn;
        }

        if (requestParameters.outgoingProductIdGreaterThan !== undefined) {
            queryParameters['outgoingProductId.greaterThan'] = requestParameters.outgoingProductIdGreaterThan;
        }

        if (requestParameters.outgoingProductIdLessThan !== undefined) {
            queryParameters['outgoingProductId.lessThan'] = requestParameters.outgoingProductIdLessThan;
        }

        if (requestParameters.outgoingProductIdGreaterThanOrEqual !== undefined) {
            queryParameters['outgoingProductId.greaterThanOrEqual'] = requestParameters.outgoingProductIdGreaterThanOrEqual;
        }

        if (requestParameters.outgoingProductIdLessThanOrEqual !== undefined) {
            queryParameters['outgoingProductId.lessThanOrEqual'] = requestParameters.outgoingProductIdLessThanOrEqual;
        }

        if (requestParameters.outgoingProductIdEquals !== undefined) {
            queryParameters['outgoingProductId.equals'] = requestParameters.outgoingProductIdEquals;
        }

        if (requestParameters.outgoingProductIdNotEquals !== undefined) {
            queryParameters['outgoingProductId.notEquals'] = requestParameters.outgoingProductIdNotEquals;
        }

        if (requestParameters.outgoingProductIdSpecified !== undefined) {
            queryParameters['outgoingProductId.specified'] = requestParameters.outgoingProductIdSpecified;
        }

        if (requestParameters.outgoingProductIdIn) {
            queryParameters['outgoingProductId.in'] = requestParameters.outgoingProductIdIn;
        }

        if (requestParameters.outgoingProductIdNotIn) {
            queryParameters['outgoingProductId.notIn'] = requestParameters.outgoingProductIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-shipping-notes/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countProcessorShippingNotes(requestParameters: CountProcessorShippingNotesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countProcessorShippingNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProcessorShippingNoteRaw(requestParameters: CreateProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNote>> {
        if (requestParameters.processorShippingNote === null || requestParameters.processorShippingNote === undefined) {
            throw new runtime.RequiredError('processorShippingNote','Required parameter requestParameters.processorShippingNote was null or undefined when calling createProcessorShippingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-shipping-notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorShippingNoteToJSON(requestParameters.processorShippingNote),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteFromJSON(jsonValue));
    }

    /**
     */
    async createProcessorShippingNote(requestParameters: CreateProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNote> {
        const response = await this.createProcessorShippingNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProcessorShippingNoteRaw(requestParameters: DeleteProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProcessorShippingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProcessorShippingNote(requestParameters: DeleteProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProcessorShippingNoteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProcessorShippingNotesRaw(requestParameters: GetAllProcessorShippingNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProcessorShippingNote>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.pdfFilenameContains !== undefined) {
            queryParameters['pdfFilename.contains'] = requestParameters.pdfFilenameContains;
        }

        if (requestParameters.pdfFilenameDoesNotContain !== undefined) {
            queryParameters['pdfFilename.doesNotContain'] = requestParameters.pdfFilenameDoesNotContain;
        }

        if (requestParameters.pdfFilenameEquals !== undefined) {
            queryParameters['pdfFilename.equals'] = requestParameters.pdfFilenameEquals;
        }

        if (requestParameters.pdfFilenameNotEquals !== undefined) {
            queryParameters['pdfFilename.notEquals'] = requestParameters.pdfFilenameNotEquals;
        }

        if (requestParameters.pdfFilenameSpecified !== undefined) {
            queryParameters['pdfFilename.specified'] = requestParameters.pdfFilenameSpecified;
        }

        if (requestParameters.pdfFilenameIn) {
            queryParameters['pdfFilename.in'] = requestParameters.pdfFilenameIn;
        }

        if (requestParameters.pdfFilenameNotIn) {
            queryParameters['pdfFilename.notIn'] = requestParameters.pdfFilenameNotIn;
        }

        if (requestParameters.pdfFilepathContains !== undefined) {
            queryParameters['pdfFilepath.contains'] = requestParameters.pdfFilepathContains;
        }

        if (requestParameters.pdfFilepathDoesNotContain !== undefined) {
            queryParameters['pdfFilepath.doesNotContain'] = requestParameters.pdfFilepathDoesNotContain;
        }

        if (requestParameters.pdfFilepathEquals !== undefined) {
            queryParameters['pdfFilepath.equals'] = requestParameters.pdfFilepathEquals;
        }

        if (requestParameters.pdfFilepathNotEquals !== undefined) {
            queryParameters['pdfFilepath.notEquals'] = requestParameters.pdfFilepathNotEquals;
        }

        if (requestParameters.pdfFilepathSpecified !== undefined) {
            queryParameters['pdfFilepath.specified'] = requestParameters.pdfFilepathSpecified;
        }

        if (requestParameters.pdfFilepathIn) {
            queryParameters['pdfFilepath.in'] = requestParameters.pdfFilepathIn;
        }

        if (requestParameters.pdfFilepathNotIn) {
            queryParameters['pdfFilepath.notIn'] = requestParameters.pdfFilepathNotIn;
        }

        if (requestParameters.pdfMd5Contains !== undefined) {
            queryParameters['pdfMd5.contains'] = requestParameters.pdfMd5Contains;
        }

        if (requestParameters.pdfMd5DoesNotContain !== undefined) {
            queryParameters['pdfMd5.doesNotContain'] = requestParameters.pdfMd5DoesNotContain;
        }

        if (requestParameters.pdfMd5Equals !== undefined) {
            queryParameters['pdfMd5.equals'] = requestParameters.pdfMd5Equals;
        }

        if (requestParameters.pdfMd5NotEquals !== undefined) {
            queryParameters['pdfMd5.notEquals'] = requestParameters.pdfMd5NotEquals;
        }

        if (requestParameters.pdfMd5Specified !== undefined) {
            queryParameters['pdfMd5.specified'] = requestParameters.pdfMd5Specified;
        }

        if (requestParameters.pdfMd5In) {
            queryParameters['pdfMd5.in'] = requestParameters.pdfMd5In;
        }

        if (requestParameters.pdfMd5NotIn) {
            queryParameters['pdfMd5.notIn'] = requestParameters.pdfMd5NotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.mockProcessorIdGreaterThan !== undefined) {
            queryParameters['mockProcessorId.greaterThan'] = requestParameters.mockProcessorIdGreaterThan;
        }

        if (requestParameters.mockProcessorIdLessThan !== undefined) {
            queryParameters['mockProcessorId.lessThan'] = requestParameters.mockProcessorIdLessThan;
        }

        if (requestParameters.mockProcessorIdGreaterThanOrEqual !== undefined) {
            queryParameters['mockProcessorId.greaterThanOrEqual'] = requestParameters.mockProcessorIdGreaterThanOrEqual;
        }

        if (requestParameters.mockProcessorIdLessThanOrEqual !== undefined) {
            queryParameters['mockProcessorId.lessThanOrEqual'] = requestParameters.mockProcessorIdLessThanOrEqual;
        }

        if (requestParameters.mockProcessorIdEquals !== undefined) {
            queryParameters['mockProcessorId.equals'] = requestParameters.mockProcessorIdEquals;
        }

        if (requestParameters.mockProcessorIdNotEquals !== undefined) {
            queryParameters['mockProcessorId.notEquals'] = requestParameters.mockProcessorIdNotEquals;
        }

        if (requestParameters.mockProcessorIdSpecified !== undefined) {
            queryParameters['mockProcessorId.specified'] = requestParameters.mockProcessorIdSpecified;
        }

        if (requestParameters.mockProcessorIdIn) {
            queryParameters['mockProcessorId.in'] = requestParameters.mockProcessorIdIn;
        }

        if (requestParameters.mockProcessorIdNotIn) {
            queryParameters['mockProcessorId.notIn'] = requestParameters.mockProcessorIdNotIn;
        }

        if (requestParameters.processorIdGreaterThan !== undefined) {
            queryParameters['processorId.greaterThan'] = requestParameters.processorIdGreaterThan;
        }

        if (requestParameters.processorIdLessThan !== undefined) {
            queryParameters['processorId.lessThan'] = requestParameters.processorIdLessThan;
        }

        if (requestParameters.processorIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorId.greaterThanOrEqual'] = requestParameters.processorIdGreaterThanOrEqual;
        }

        if (requestParameters.processorIdLessThanOrEqual !== undefined) {
            queryParameters['processorId.lessThanOrEqual'] = requestParameters.processorIdLessThanOrEqual;
        }

        if (requestParameters.processorIdEquals !== undefined) {
            queryParameters['processorId.equals'] = requestParameters.processorIdEquals;
        }

        if (requestParameters.processorIdNotEquals !== undefined) {
            queryParameters['processorId.notEquals'] = requestParameters.processorIdNotEquals;
        }

        if (requestParameters.processorIdSpecified !== undefined) {
            queryParameters['processorId.specified'] = requestParameters.processorIdSpecified;
        }

        if (requestParameters.processorIdIn) {
            queryParameters['processorId.in'] = requestParameters.processorIdIn;
        }

        if (requestParameters.processorIdNotIn) {
            queryParameters['processorId.notIn'] = requestParameters.processorIdNotIn;
        }

        if (requestParameters.creatorIdGreaterThan !== undefined) {
            queryParameters['creatorId.greaterThan'] = requestParameters.creatorIdGreaterThan;
        }

        if (requestParameters.creatorIdLessThan !== undefined) {
            queryParameters['creatorId.lessThan'] = requestParameters.creatorIdLessThan;
        }

        if (requestParameters.creatorIdGreaterThanOrEqual !== undefined) {
            queryParameters['creatorId.greaterThanOrEqual'] = requestParameters.creatorIdGreaterThanOrEqual;
        }

        if (requestParameters.creatorIdLessThanOrEqual !== undefined) {
            queryParameters['creatorId.lessThanOrEqual'] = requestParameters.creatorIdLessThanOrEqual;
        }

        if (requestParameters.creatorIdEquals !== undefined) {
            queryParameters['creatorId.equals'] = requestParameters.creatorIdEquals;
        }

        if (requestParameters.creatorIdNotEquals !== undefined) {
            queryParameters['creatorId.notEquals'] = requestParameters.creatorIdNotEquals;
        }

        if (requestParameters.creatorIdSpecified !== undefined) {
            queryParameters['creatorId.specified'] = requestParameters.creatorIdSpecified;
        }

        if (requestParameters.creatorIdIn) {
            queryParameters['creatorId.in'] = requestParameters.creatorIdIn;
        }

        if (requestParameters.creatorIdNotIn) {
            queryParameters['creatorId.notIn'] = requestParameters.creatorIdNotIn;
        }

        if (requestParameters.pdfAuthorIdGreaterThan !== undefined) {
            queryParameters['pdfAuthorId.greaterThan'] = requestParameters.pdfAuthorIdGreaterThan;
        }

        if (requestParameters.pdfAuthorIdLessThan !== undefined) {
            queryParameters['pdfAuthorId.lessThan'] = requestParameters.pdfAuthorIdLessThan;
        }

        if (requestParameters.pdfAuthorIdGreaterThanOrEqual !== undefined) {
            queryParameters['pdfAuthorId.greaterThanOrEqual'] = requestParameters.pdfAuthorIdGreaterThanOrEqual;
        }

        if (requestParameters.pdfAuthorIdLessThanOrEqual !== undefined) {
            queryParameters['pdfAuthorId.lessThanOrEqual'] = requestParameters.pdfAuthorIdLessThanOrEqual;
        }

        if (requestParameters.pdfAuthorIdEquals !== undefined) {
            queryParameters['pdfAuthorId.equals'] = requestParameters.pdfAuthorIdEquals;
        }

        if (requestParameters.pdfAuthorIdNotEquals !== undefined) {
            queryParameters['pdfAuthorId.notEquals'] = requestParameters.pdfAuthorIdNotEquals;
        }

        if (requestParameters.pdfAuthorIdSpecified !== undefined) {
            queryParameters['pdfAuthorId.specified'] = requestParameters.pdfAuthorIdSpecified;
        }

        if (requestParameters.pdfAuthorIdIn) {
            queryParameters['pdfAuthorId.in'] = requestParameters.pdfAuthorIdIn;
        }

        if (requestParameters.pdfAuthorIdNotIn) {
            queryParameters['pdfAuthorId.notIn'] = requestParameters.pdfAuthorIdNotIn;
        }

        if (requestParameters.outgoingBatchIdGreaterThan !== undefined) {
            queryParameters['outgoingBatchId.greaterThan'] = requestParameters.outgoingBatchIdGreaterThan;
        }

        if (requestParameters.outgoingBatchIdLessThan !== undefined) {
            queryParameters['outgoingBatchId.lessThan'] = requestParameters.outgoingBatchIdLessThan;
        }

        if (requestParameters.outgoingBatchIdGreaterThanOrEqual !== undefined) {
            queryParameters['outgoingBatchId.greaterThanOrEqual'] = requestParameters.outgoingBatchIdGreaterThanOrEqual;
        }

        if (requestParameters.outgoingBatchIdLessThanOrEqual !== undefined) {
            queryParameters['outgoingBatchId.lessThanOrEqual'] = requestParameters.outgoingBatchIdLessThanOrEqual;
        }

        if (requestParameters.outgoingBatchIdEquals !== undefined) {
            queryParameters['outgoingBatchId.equals'] = requestParameters.outgoingBatchIdEquals;
        }

        if (requestParameters.outgoingBatchIdNotEquals !== undefined) {
            queryParameters['outgoingBatchId.notEquals'] = requestParameters.outgoingBatchIdNotEquals;
        }

        if (requestParameters.outgoingBatchIdSpecified !== undefined) {
            queryParameters['outgoingBatchId.specified'] = requestParameters.outgoingBatchIdSpecified;
        }

        if (requestParameters.outgoingBatchIdIn) {
            queryParameters['outgoingBatchId.in'] = requestParameters.outgoingBatchIdIn;
        }

        if (requestParameters.outgoingBatchIdNotIn) {
            queryParameters['outgoingBatchId.notIn'] = requestParameters.outgoingBatchIdNotIn;
        }

        if (requestParameters.referenceShippingNoteIdGreaterThan !== undefined) {
            queryParameters['referenceShippingNoteId.greaterThan'] = requestParameters.referenceShippingNoteIdGreaterThan;
        }

        if (requestParameters.referenceShippingNoteIdLessThan !== undefined) {
            queryParameters['referenceShippingNoteId.lessThan'] = requestParameters.referenceShippingNoteIdLessThan;
        }

        if (requestParameters.referenceShippingNoteIdGreaterThanOrEqual !== undefined) {
            queryParameters['referenceShippingNoteId.greaterThanOrEqual'] = requestParameters.referenceShippingNoteIdGreaterThanOrEqual;
        }

        if (requestParameters.referenceShippingNoteIdLessThanOrEqual !== undefined) {
            queryParameters['referenceShippingNoteId.lessThanOrEqual'] = requestParameters.referenceShippingNoteIdLessThanOrEqual;
        }

        if (requestParameters.referenceShippingNoteIdEquals !== undefined) {
            queryParameters['referenceShippingNoteId.equals'] = requestParameters.referenceShippingNoteIdEquals;
        }

        if (requestParameters.referenceShippingNoteIdNotEquals !== undefined) {
            queryParameters['referenceShippingNoteId.notEquals'] = requestParameters.referenceShippingNoteIdNotEquals;
        }

        if (requestParameters.referenceShippingNoteIdSpecified !== undefined) {
            queryParameters['referenceShippingNoteId.specified'] = requestParameters.referenceShippingNoteIdSpecified;
        }

        if (requestParameters.referenceShippingNoteIdIn) {
            queryParameters['referenceShippingNoteId.in'] = requestParameters.referenceShippingNoteIdIn;
        }

        if (requestParameters.referenceShippingNoteIdNotIn) {
            queryParameters['referenceShippingNoteId.notIn'] = requestParameters.referenceShippingNoteIdNotIn;
        }

        if (requestParameters.incomingProductIdGreaterThan !== undefined) {
            queryParameters['incomingProductId.greaterThan'] = requestParameters.incomingProductIdGreaterThan;
        }

        if (requestParameters.incomingProductIdLessThan !== undefined) {
            queryParameters['incomingProductId.lessThan'] = requestParameters.incomingProductIdLessThan;
        }

        if (requestParameters.incomingProductIdGreaterThanOrEqual !== undefined) {
            queryParameters['incomingProductId.greaterThanOrEqual'] = requestParameters.incomingProductIdGreaterThanOrEqual;
        }

        if (requestParameters.incomingProductIdLessThanOrEqual !== undefined) {
            queryParameters['incomingProductId.lessThanOrEqual'] = requestParameters.incomingProductIdLessThanOrEqual;
        }

        if (requestParameters.incomingProductIdEquals !== undefined) {
            queryParameters['incomingProductId.equals'] = requestParameters.incomingProductIdEquals;
        }

        if (requestParameters.incomingProductIdNotEquals !== undefined) {
            queryParameters['incomingProductId.notEquals'] = requestParameters.incomingProductIdNotEquals;
        }

        if (requestParameters.incomingProductIdSpecified !== undefined) {
            queryParameters['incomingProductId.specified'] = requestParameters.incomingProductIdSpecified;
        }

        if (requestParameters.incomingProductIdIn) {
            queryParameters['incomingProductId.in'] = requestParameters.incomingProductIdIn;
        }

        if (requestParameters.incomingProductIdNotIn) {
            queryParameters['incomingProductId.notIn'] = requestParameters.incomingProductIdNotIn;
        }

        if (requestParameters.processorShippingNoteStateIdGreaterThan !== undefined) {
            queryParameters['processorShippingNoteStateId.greaterThan'] = requestParameters.processorShippingNoteStateIdGreaterThan;
        }

        if (requestParameters.processorShippingNoteStateIdLessThan !== undefined) {
            queryParameters['processorShippingNoteStateId.lessThan'] = requestParameters.processorShippingNoteStateIdLessThan;
        }

        if (requestParameters.processorShippingNoteStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorShippingNoteStateId.greaterThanOrEqual'] = requestParameters.processorShippingNoteStateIdGreaterThanOrEqual;
        }

        if (requestParameters.processorShippingNoteStateIdLessThanOrEqual !== undefined) {
            queryParameters['processorShippingNoteStateId.lessThanOrEqual'] = requestParameters.processorShippingNoteStateIdLessThanOrEqual;
        }

        if (requestParameters.processorShippingNoteStateIdEquals !== undefined) {
            queryParameters['processorShippingNoteStateId.equals'] = requestParameters.processorShippingNoteStateIdEquals;
        }

        if (requestParameters.processorShippingNoteStateIdNotEquals !== undefined) {
            queryParameters['processorShippingNoteStateId.notEquals'] = requestParameters.processorShippingNoteStateIdNotEquals;
        }

        if (requestParameters.processorShippingNoteStateIdSpecified !== undefined) {
            queryParameters['processorShippingNoteStateId.specified'] = requestParameters.processorShippingNoteStateIdSpecified;
        }

        if (requestParameters.processorShippingNoteStateIdIn) {
            queryParameters['processorShippingNoteStateId.in'] = requestParameters.processorShippingNoteStateIdIn;
        }

        if (requestParameters.processorShippingNoteStateIdNotIn) {
            queryParameters['processorShippingNoteStateId.notIn'] = requestParameters.processorShippingNoteStateIdNotIn;
        }

        if (requestParameters.outgoingProductIdGreaterThan !== undefined) {
            queryParameters['outgoingProductId.greaterThan'] = requestParameters.outgoingProductIdGreaterThan;
        }

        if (requestParameters.outgoingProductIdLessThan !== undefined) {
            queryParameters['outgoingProductId.lessThan'] = requestParameters.outgoingProductIdLessThan;
        }

        if (requestParameters.outgoingProductIdGreaterThanOrEqual !== undefined) {
            queryParameters['outgoingProductId.greaterThanOrEqual'] = requestParameters.outgoingProductIdGreaterThanOrEqual;
        }

        if (requestParameters.outgoingProductIdLessThanOrEqual !== undefined) {
            queryParameters['outgoingProductId.lessThanOrEqual'] = requestParameters.outgoingProductIdLessThanOrEqual;
        }

        if (requestParameters.outgoingProductIdEquals !== undefined) {
            queryParameters['outgoingProductId.equals'] = requestParameters.outgoingProductIdEquals;
        }

        if (requestParameters.outgoingProductIdNotEquals !== undefined) {
            queryParameters['outgoingProductId.notEquals'] = requestParameters.outgoingProductIdNotEquals;
        }

        if (requestParameters.outgoingProductIdSpecified !== undefined) {
            queryParameters['outgoingProductId.specified'] = requestParameters.outgoingProductIdSpecified;
        }

        if (requestParameters.outgoingProductIdIn) {
            queryParameters['outgoingProductId.in'] = requestParameters.outgoingProductIdIn;
        }

        if (requestParameters.outgoingProductIdNotIn) {
            queryParameters['outgoingProductId.notIn'] = requestParameters.outgoingProductIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-shipping-notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProcessorShippingNoteFromJSON));
    }

    /**
     */
    async getAllProcessorShippingNotes(requestParameters: GetAllProcessorShippingNotesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProcessorShippingNote>> {
        const response = await this.getAllProcessorShippingNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProcessorShippingNoteRaw(requestParameters: GetProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProcessorShippingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteFromJSON(jsonValue));
    }

    /**
     */
    async getProcessorShippingNote(requestParameters: GetProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNote> {
        const response = await this.getProcessorShippingNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateProcessorShippingNoteRaw(requestParameters: PartialUpdateProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateProcessorShippingNote.');
        }

        if (requestParameters.processorShippingNote === null || requestParameters.processorShippingNote === undefined) {
            throw new runtime.RequiredError('processorShippingNote','Required parameter requestParameters.processorShippingNote was null or undefined when calling partialUpdateProcessorShippingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorShippingNoteToJSON(requestParameters.processorShippingNote),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateProcessorShippingNote(requestParameters: PartialUpdateProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNote> {
        const response = await this.partialUpdateProcessorShippingNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProcessorShippingNoteRaw(requestParameters: UpdateProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProcessorShippingNote.');
        }

        if (requestParameters.processorShippingNote === null || requestParameters.processorShippingNote === undefined) {
            throw new runtime.RequiredError('processorShippingNote','Required parameter requestParameters.processorShippingNote was null or undefined when calling updateProcessorShippingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorShippingNoteToJSON(requestParameters.processorShippingNote),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteFromJSON(jsonValue));
    }

    /**
     */
    async updateProcessorShippingNote(requestParameters: UpdateProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNote> {
        const response = await this.updateProcessorShippingNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CountProcessorShippingNotesCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type CountProcessorShippingNotesCurrentStateEqualsEnum = typeof CountProcessorShippingNotesCurrentStateEqualsEnum[keyof typeof CountProcessorShippingNotesCurrentStateEqualsEnum];
/**
 * @export
 */
export const CountProcessorShippingNotesCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type CountProcessorShippingNotesCurrentStateNotEqualsEnum = typeof CountProcessorShippingNotesCurrentStateNotEqualsEnum[keyof typeof CountProcessorShippingNotesCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const CountProcessorShippingNotesCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type CountProcessorShippingNotesCurrentStateInEnum = typeof CountProcessorShippingNotesCurrentStateInEnum[keyof typeof CountProcessorShippingNotesCurrentStateInEnum];
/**
 * @export
 */
export const CountProcessorShippingNotesCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type CountProcessorShippingNotesCurrentStateNotInEnum = typeof CountProcessorShippingNotesCurrentStateNotInEnum[keyof typeof CountProcessorShippingNotesCurrentStateNotInEnum];
/**
 * @export
 */
export const GetAllProcessorShippingNotesCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type GetAllProcessorShippingNotesCurrentStateEqualsEnum = typeof GetAllProcessorShippingNotesCurrentStateEqualsEnum[keyof typeof GetAllProcessorShippingNotesCurrentStateEqualsEnum];
/**
 * @export
 */
export const GetAllProcessorShippingNotesCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type GetAllProcessorShippingNotesCurrentStateNotEqualsEnum = typeof GetAllProcessorShippingNotesCurrentStateNotEqualsEnum[keyof typeof GetAllProcessorShippingNotesCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const GetAllProcessorShippingNotesCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type GetAllProcessorShippingNotesCurrentStateInEnum = typeof GetAllProcessorShippingNotesCurrentStateInEnum[keyof typeof GetAllProcessorShippingNotesCurrentStateInEnum];
/**
 * @export
 */
export const GetAllProcessorShippingNotesCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type GetAllProcessorShippingNotesCurrentStateNotInEnum = typeof GetAllProcessorShippingNotesCurrentStateNotInEnum[keyof typeof GetAllProcessorShippingNotesCurrentStateNotInEnum];
