/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import type { DeliverySheet, DeliverySheetMeasurements } from './DeliverySheet';
import {
    DeliverySheetFromJSON,
    DeliverySheetToJSON
} from './DeliverySheet';
import type { User } from './User';
import {
    UserFromJSON,
    UserToJSON
} from './User';

/**
 * 
 * @export
 * @interface DeliverySheetPhoto
 */
export interface DeliverySheetPhoto {
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetPhoto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetPhoto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetPhoto
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetPhoto
     */
    photoFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetPhoto
     */
    photoFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetPhoto
     */
    photoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetPhoto
     */
    photoMd5?: string;
    /**
     * 
     * @type {DeliverySheet}
     * @memberof DeliverySheetPhoto
     */
    deliverySheet?: DeliverySheet;
    /**
     * 
     * @type {User}
     * @memberof DeliverySheetPhoto
     */
    author?: User;
    /**
     * 
     * @type {Date}
     * @memberof DeliverySheetPhoto
     */
    photoCreationDate?: Date;
}

/**
 * Check if a given object implements the DeliverySheetPhoto interface.
 */
export function instanceOfDeliverySheetPhoto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeliverySheetPhotoFromJSON(json: any): DeliverySheetPhoto {
    return DeliverySheetPhotoFromJSONTyped(json, false);
}

export function DeliverySheetPhotoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliverySheetPhoto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'photoFilename': !exists(json, 'photoFilename') ? undefined : json['photoFilename'],
        'photoFilepath': !exists(json, 'photoFilepath') ? undefined : json['photoFilepath'],
        'photoUrl': !exists(json, 'photoUrl') ? undefined : json['photoUrl'],
        'photoMd5': !exists(json, 'photoMd5') ? undefined : json['photoMd5'],
        'deliverySheet': !exists(json, 'deliverySheet') ? undefined : DeliverySheetFromJSON(json['deliverySheet']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
    };
}

export function DeliverySheetFactoryMeasurementFromJSON(json: any): DeliverySheetMeasurements {
    return DeliverySheetFactoryMeasurementFromJSONTyped(json, false);
}

export function DeliverySheetFactoryMeasurementFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliverySheetMeasurements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'amountOfTrunks': !exists(json, 'amountOfTrunks') ? undefined : json['amountOfTrunks'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'deliverySheetFileUploads': json['deliverySheetFileUploads'],
       'species': json['species'],
    };
}

export function DeliverySheetPhotoToJSON(value?: DeliverySheetPhoto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'photoFilename': value.photoFilename,
        'photoFilepath': value.photoFilepath,
        'photoUrl': value.photoUrl,
        'photoMd5': value.photoMd5,
        'deliverySheet': DeliverySheetToJSON(value.deliverySheet),
        'author': UserToJSON(value.author),
        'photoCreationDate': value.photoCreationDate,
    };
}

