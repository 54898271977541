import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { DeliverySheetDTO } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface DeliverySheetState {
  list: DeliverySheetDTO[];
  completeList: DeliverySheetDTO[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: DeliverySheetState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const deliverySheet = createSlice({
  name: 'deliverySheetList',
  initialState,
  reducers: {
    setDeliverySheetList: (state, action) => {
      let deliverySheetList = { ...state }.list;
      let deliverySheetCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(deliverySheetList, element);
        pushElementOrReplaceById(deliverySheetCompleteList, element);
      });

      state.list = deliverySheetList;
      state.completeList = deliverySheetCompleteList;
    },
    updatePileDataInDeliverySheets: (state, action) => {
      let deliverySheetCompleteList = { ...state }.completeList;

      let updatedPile = action.payload;
      deliverySheetCompleteList.forEach(ds => {
        if (ds.pile && ds.pile.id == updatedPile.id) {
          ds.pile.name = updatedPile.name;
        }
      });

      state.completeList = deliverySheetCompleteList;
    },
    setCompleteDeliverySheetList: (state, action) => {
      // Repetition
      let deliverySheetCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(deliverySheetCompleteList, element);
      });

      state.completeList = deliverySheetCompleteList;
    },
    removeDeliverySheetElement: (state, action) => {
      let deliverySheetList = { ...state }.list;
      let deliverySheetCompleteList = { ...state }.completeList;

      let element: DeliverySheetDTO = action.payload;
      deliverySheetList = deliverySheetList.filter(e => e.id != element.id);
      deliverySheetCompleteList = deliverySheetCompleteList.filter(e => e.id != element.id);

      state.list = deliverySheetList;
      state.completeList = deliverySheetCompleteList;
    },
    setDeliverySheetTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setDeliverySheetListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setDeliverySheetPage: (state, action) => {
      state.page = action.payload;
    },
    resetDeliverySheetList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearDeliverySheetList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setDeliverySheetList,
  updatePileDataInDeliverySheets,
  setCompleteDeliverySheetList,
  removeDeliverySheetElement,
  setDeliverySheetTotalPages,
  setDeliverySheetListSort,
  setDeliverySheetPage,
  resetDeliverySheetList,
  clearDeliverySheetList
} = deliverySheet.actions;

export default deliverySheet.reducer;
