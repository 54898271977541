import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface UrstammImagePlus {
  width?: number;
  height?: number;
  showText?: boolean;
}

const UrstammImagePlus = (props: UrstammImagePlus) => {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 60 60" fill="none">
      <Path
        d="M30.3001 40.357V19.0427M19.643 29.6999H40.9573M56.943 29.6999C56.943 14.9854 45.0146 3.05699 30.3001 3.05699C15.5857 3.05699 3.65723 14.9854 3.65723 29.6999C3.65723 44.4143 15.5857 56.3428 30.3001 56.3428C45.0146 56.3428 56.943 44.4143 56.943 29.6999Z"
        stroke="white"
        strokeWidth="5.32858"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default UrstammImagePlus;
