/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Pile } from './Pile';
import {
    PileFromJSON,
    PileFromJSONTyped,
    PileToJSON,
} from './Pile';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface PileState
 */
export interface PileState {
    /**
     * 
     * @type {number}
     * @memberof PileState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PileState
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof PileState
     */
    state: PileStateStateEnum;
    /**
     * 
     * @type {Pile}
     * @memberof PileState
     */
    pile?: Pile;
    /**
     * 
     * @type {User}
     * @memberof PileState
     */
    author?: User;
}


/**
 * @export
 */
export const PileStateStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type PileStateStateEnum = typeof PileStateStateEnum[keyof typeof PileStateStateEnum];


/**
 * Check if a given object implements the PileState interface.
 */
export function instanceOfPileState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function PileStateFromJSON(json: any): PileState {
    return PileStateFromJSONTyped(json, false);
}

export function PileStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PileState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'state': json['state'],
        'pile': !exists(json, 'pile') ? undefined : PileFromJSON(json['pile']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
    };
}

export function PileStateToJSON(value?: PileState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'state': value.state,
        'pile': PileToJSON(value.pile),
        'author': UserToJSON(value.author),
    };
}

