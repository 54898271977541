import { i18n } from '@i18n/i18n';
import { Text } from 'react-native';
import { UrstammStyle } from '../styles/UrstammStyle';

/**
 *
 * @param key = attribute
 * @param value = new value
 * @param formState = form
 * @param stateKey = form section
 */
export const urstammUtilUpdateForm = (key: string, value: any, formState: any, stateKey: string): object => {
  let form = { ...formState[stateKey] };
  let base64File = formState.base64File;

  const formKey = key;
  form[formKey] = value;
  return { [stateKey]: form, base64File: base64File };
};

/**
 *
 * @param key = attribute
 * @param value = new value
 * @param formState = form
 * @param stateKey = form section
 */
export const urstammUtilUpdatePhoto = (
  base64: string,
  location: any,
  photoCreationDate: Date,
  formState: any,
  stateKey: string
): object => {
  let form = { ...formState[stateKey] };
  let base64File = formState.base64File;

  if (base64) {
    base64File = base64;
  }
  if (location) {
    if (stateKey === 'logging') {
      form.loggingPhoto = {
        longitude: location.coords.longitude,
        latitude: location.coords.latitude,
        photoCreationDate: new Date(),
      };
    } else {
      form.longitude = location.coords.longitude;
      form.latitude = location.coords.latitude;
    }
  }
  if (photoCreationDate) {
    if (stateKey !== 'logging') form.photoCreationDate = photoCreationDate;
  }
  return { [stateKey]: form, base64File: base64File };
};

export const urstammUtilValidator = (text, objKey, validators) => {
  let errors: object[] = [];
  let reference: object[] = [];
  let validatorObject = Array.isArray(validators) ? validators[0] : validators;
  let numberOfValidators = Object.keys(validatorObject[objKey]).length;
  Object.keys(validatorObject[objKey]).forEach((key, idx) => {
    if (key == 'required') {
      if (!text || text.length == 0) {
        errors.push({
          [key]: i18n.t('utility_components.input.validators.required')
        });
      } else {
        delete errors['required'];
      }
    }
    if (key == 'email') {
      if (!text.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        errors.push({
          [key]: i18n.t('utility_components.input.validators.email')
        });
      } else {
        delete errors['email'];
      }
    }
    if (key == 'maxlength') {
      if (text.length > validatorObject[objKey][key]) {
        errors.push({
          [key]: i18n.t('utility_components.input.validators.max_length', {
            char: validatorObject[objKey][key]
          })
        });
      } else {
        delete errors['maxlength'];
      }
    }
    if (key == 'minlength') {
      if (text.length < validatorObject[objKey][key]) {
        errors.push({
          [key]: i18n.t('utility_components.input.validators.min_length', {
            char: validatorObject[objKey][key]
          })
        });
      } else {
        delete errors['minlength'];
      }
    }
    if (key == 'max2Digits') {
      if (!text) {
        delete errors['max2Digits'];
      } else {
        if (text.match(/^[0-9]{1,2}([,.][0-9]{1,2})?$/) == null) {
          errors.push({
            [key]: i18n.t('utility_components.input.validators.maxDigits')
          });
        } else {
          delete errors['max2Digits'];
        }
      }
    }
    if (key == 'max3Digits') {
      if (!text) {
        delete errors['max3Digits'];
      } else {
        if (text.match(/^[0-9]{1,2}([,.][0-9]{1,3})?$/) == null) {
          errors.push({
            [key]: i18n.t('utility_components.input.validators.maxDigits')
          });
        } else {
          delete errors['max3Digits'];
        }
      }
    }
    if (key == 'firstCharacterIsLetter') {
      if (text.length == 1) {
        if (text.match(/^[A-Z|?]+$/) == null) {
          errors.push({
            [key]: i18n.t('utility_components.input.validators.first_letter_character')
          });
        } else {
          delete errors['firstCharacterIsLetter'];
        }
      }
    }
    if (key == 'password') {
      if (
        typeof text === 'string' &&
        text.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-+!%*?&"£/()='^°\\])[A-Za-z\d@$\-+!%*?&"£/()='^°\\]{8,}$/
        ) == null
      ) {
        errors.push({
          [key]: i18n.t('utility_components.input.validators.password')
        });
      } else {
        delete errors['password'];
      }
    }
    if (key == 'numeric') {
      if (typeof text === 'string' && text.match(/^\d*\,?\d*$|^\d*\.?\d*$/) == null) {
        errors.push({
          [key]: i18n.t('utility_components.input.validators.numeric')
        });
      } else {
        delete errors['numeric'];
      }
    }
    if (key == 'numeric_no_digits') {
      if (!text) {
        delete errors['numeric_no_digits'];
      } else {
        if (typeof text === 'string' && text.match(/^(0|[1-9][0-9]*)$/) == null) {
          errors.push({
            [key]: i18n.t('utility_components.input.validators.numeric_no_digits')
          });
        } else {
          delete errors['numeric_no_digits'];
        }
      }
    }
    if (key == 'maxValue') {
      if (Number(text) > Number(validatorObject[objKey][key])) {
        errors.push({
          [key]: i18n.t('utility_components.input.validators.maxValue', {
            char: validatorObject[objKey][key]
          })
        });
      } else {
        delete errors['maxValue'];
      }
    }
    if (key == 'minValue') {
      if (Number(text) < Number(validatorObject[objKey][key])) {
        errors.push({
          [key]: i18n.t('utility_components.input.validators.minValue', {
            char: validatorObject[objKey][key]
          })
        });
      } else {
        delete errors['minValue'];
      }
    }
    if (key == 'percentage') {
      if (!text) {
        delete errors['percentage'];
      } else {
        if (typeof text === 'string' && text.match(/^[0-9][0-9]?$|^100$/) == null) {
          errors.push({
            [key]: i18n.t('utility_components.input.validators.percentage')
          });
        } else {
          delete errors['percentage'];
        }
      }
    }
    if (idx == numberOfValidators - 1) {
      reference = errors;
    }
  });
  return reference;
};

export const urstammUtilDisplayError = (errors: object[], idx: number) => {
  if (errors) {
    return (
      <Text key={idx} style={UrstammStyle.formValidators}>
        {'- ' + Object.values(errors)}
      </Text>
    );
  }
};

export const urstammUtilValidatorInError = (errors: any): boolean => {
  let err: boolean = false;
  for (let val in errors) {
    if (errors[val].length > 0) {
      err = true;
    }
  }
  return err;
};
