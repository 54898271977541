/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Product,
} from '../models';
import {
    ProductFromJSON,
    ProductToJSON,
} from '../models';

export interface CreateProductRequest {
    product: Product;
}

export interface DeleteProductRequest {
    id: number;
}

export interface GetAllProductsRequest {
    eagerload?: boolean;
}

export interface GetProductRequest {
    id: number;
}

export interface PartialUpdateProductRequest {
    id: number;
    product: Product;
}

export interface UpdateProductRequest {
    id: number;
    product: Product;
}

/**
 * 
 */
export class ProductResourceApi extends runtime.BaseAPI {

    /**
     */
    async createProductRaw(requestParameters: CreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.product === null || requestParameters.product === undefined) {
            throw new runtime.RequiredError('product','Required parameter requestParameters.product was null or undefined when calling createProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductToJSON(requestParameters.product),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     */
    async createProduct(requestParameters: CreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.createProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProductRaw(requestParameters: DeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProduct(requestParameters: DeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProductRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProductsRaw(requestParameters: GetAllProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Product>>> {
        const queryParameters: any = {};

        if (requestParameters.eagerload !== undefined) {
            queryParameters['eagerload'] = requestParameters.eagerload;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductFromJSON));
    }

    /**
     */
    async getAllProducts(requestParameters: GetAllProductsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Product>> {
        const response = await this.getAllProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductRaw(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     */
    async getProduct(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.getProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateProductRaw(requestParameters: PartialUpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateProduct.');
        }

        if (requestParameters.product === null || requestParameters.product === undefined) {
            throw new runtime.RequiredError('product','Required parameter requestParameters.product was null or undefined when calling partialUpdateProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProductToJSON(requestParameters.product),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateProduct(requestParameters: PartialUpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.partialUpdateProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProductRaw(requestParameters: UpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProduct.');
        }

        if (requestParameters.product === null || requestParameters.product === undefined) {
            throw new runtime.RequiredError('product','Required parameter requestParameters.product was null or undefined when calling updateProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductToJSON(requestParameters.product),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     */
    async updateProduct(requestParameters: UpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.updateProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
