import Moment from 'moment';

export class DateHelper {
  private static DATE_FORMAT = 'DD/MM/YYYY';
  private static TIME_FORMAT = 'HH:mm';
  private static DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';

  /**
   * Format a date with custom format
   * @param {Date} date
   * @returns {string}
   */
  public static formatDate(date: Date): string {
    return Moment(date).format(DateHelper.DATE_FORMAT);
  }

  /**
   * Format a time with custom format
   * @param {Date} time
   * @returns {string}
   */
  public static formatTime(time: Date): string {
    return Moment(time).format(DateHelper.TIME_FORMAT);
  }

  /**
   * Format a datetime with custom format
   * @param {Date} datetime
   * @returns {string}
   */
  public static formatDatetime(datetime: Date): string {
    return Moment(datetime).format(DateHelper.DATETIME_FORMAT);
  }

  /**
   * Creates the current time millis
   * @returns {number}
   */
  public static nowTimestamp(): number {
    return +new Date();
  }
}
