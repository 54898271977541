/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrunkLengthRangeTable
 */
export interface TrunkLengthRangeTable {
    /**
     * 
     * @type {number}
     * @memberof TrunkLengthRangeTable
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TrunkLengthRangeTable
     */
    minLength?: number;
    /**
     * 
     * @type {number}
     * @memberof TrunkLengthRangeTable
     */
    maxLength?: number;
    /**
     * 
     * @type {string}
     * @memberof TrunkLengthRangeTable
     */
    lengthRange?: TrunkLengthRangeTableLengthRangeEnum;
}


/**
 * @export
 */
export const TrunkLengthRangeTableLengthRangeEnum = {
    L0: 'L0',
    L1: 'L1',
    L2: 'L2',
    L3: 'L3'
} as const;
export type TrunkLengthRangeTableLengthRangeEnum = typeof TrunkLengthRangeTableLengthRangeEnum[keyof typeof TrunkLengthRangeTableLengthRangeEnum];


/**
 * Check if a given object implements the TrunkLengthRangeTable interface.
 */
export function instanceOfTrunkLengthRangeTable(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TrunkLengthRangeTableFromJSON(json: any): TrunkLengthRangeTable {
    return TrunkLengthRangeTableFromJSONTyped(json, false);
}

export function TrunkLengthRangeTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrunkLengthRangeTable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'minLength': !exists(json, 'minLength') ? undefined : json['minLength'],
        'maxLength': !exists(json, 'maxLength') ? undefined : json['maxLength'],
        'lengthRange': !exists(json, 'lengthRange') ? undefined : json['lengthRange'],
    };
}

export function TrunkLengthRangeTableToJSON(value?: TrunkLengthRangeTable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'minLength': value.minLength,
        'maxLength': value.maxLength,
        'lengthRange': value.lengthRange,
    };
}

