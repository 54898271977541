import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { ShippingNote } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface ShippingNoteState {
  list: ShippingNote[];
  completeList: ShippingNote[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: ShippingNoteState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const shippingNote = createSlice({
  name: 'shippingNoteList',
  initialState,
  reducers: {
    setShippingNoteList: (state, action) => {
      let shippingNoteList = { ...state }.list;
      let shippingNoteCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(shippingNoteList, element);
        pushElementOrReplaceById(shippingNoteCompleteList, element);
      });

      state.list = shippingNoteList;
      state.completeList = shippingNoteCompleteList;
    },
    setShippingNoteTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setShippingNoteListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setShippingNotePage: (state, action) => {
      state.page = action.payload;
    },
    resetShippingNoteList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearShippingNoteList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setShippingNoteList,
  setShippingNoteListSort,
  resetShippingNoteList,
  setShippingNoteTotalPages,
  setShippingNotePage,
  clearShippingNoteList
} = shippingNote.actions;

export default shippingNote.reducer;
