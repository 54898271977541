import React, { ReactElement } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { ColorTheme } from '../../../utils/styles/ColorTheme';

export default function UrstammButtonCorner(props: {
  cornerStyle?: StyleProp<ViewStyle>;
  textIconStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  subTextStyle?: StyleProp<TextStyle>;
  text?: string;
  subText?: string;
  iconSvg?: ReactElement;
  testID: string;
  onSubmit?: any;
}) {
  return (
    <TouchableOpacity testID={props.testID} style={props.cornerStyle} onPress={props.onSubmit}>
      <View style={props.textIconStyle}>
        {props.iconSvg && props.iconSvg}
        {props.text && <Text style={[props.textStyle]}>{props.text}</Text>}
        {props.subText && <Text style={[props.subTextStyle]}>{props.subText}</Text>}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({});
