import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import TreeDetailsView from '@components/views/tree/TreeDetailsView';
import { TreeForm } from '@components/views/tree/TreeRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { RootState } from '@redux/store';
import { FullAvailableTreeResourceApi } from '@services/apis/FullAvailableTreeResourceApi';
import { TreeDTO } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { resetTreeList } from '../../redux/features/tree/treeSlice';
import { createOnlyIdLogging } from '../../utils/classes/UrstammClassMapper';
import { getTreeTitle, sleep, urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function TreeDetailsContainer({ navigation, route }: RootStackScreenProps<'TreeDetails'>) {
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const [treeSelected, setTreeSelected] = useState<TreeDTO>();
  const dispatch = useDispatch();

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('generics.tree'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    if (route.params) {
      setTreeSelected(route.params.treeSelected);
    }
  }, [route.params]);

  const updateTree = (treeForm: any): void => {
    dispatch(changeLoaderStatus(true));
    let treeObj: TreeDTO = {
      id: treeSelected?.id,
      photoNumber: treeSelected?.photoNumber,
      creationDate: treeSelected?.creationDate,
      recordingType: treeSelected?.recordingType,
      latitude: treeSelected?.latitude,
      longitude: treeSelected?.longitude,
      comment: treeForm.comment,
      species: treeForm.species,
      logging: createOnlyIdLogging(treeSelected?.logging)
    };
    FullAvailableTreeResourceApi.updateTreeCustom(rdxOfflineSyncList, dispatch, addOfflineObjectSync, {
      id: treeSelected?.id!,
      entityWithFileDTOTreeDTO: {
        entity: treeObj,
        base64File: treeForm.base64File
      }
    })
      .then(res => {
        dispatch(changeLoaderStatus(false));
        if (res) {
          dispatch(resetTreeList());
          UrstammNavigationHelper.navigateToTreeList(navigation, route.params?.loggingSelected, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      title={
        <UrstammTitle
          testID={'tree_details_title'}
          text={i18n.t('generics.tree') + ' '}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'tree_details_subtitle'}
          text={getTreeTitle(route.params?.treeSelected)!}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={[
        {
          title: i18n.t('generics.logging'),
          value: route.params?.loggingSelected.name
        }
      ]}
      titleSubViewStyle={{ flexDirection: 'row', alignItems: 'center' }}
      view={
        <TreeDetailsView
          navigation={navigation}
          treeSelected={treeSelected!}
          modalVisible={false}
          loggingSelected={route.params?.loggingSelected}
          updateTree={(treeForm: any) => updateTree(treeForm)}
        />
      }
    />
  );
}
