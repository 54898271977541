/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserCompanyInviteState,
} from '../models';
import {
    UserCompanyInviteStateFromJSON,
    UserCompanyInviteStateToJSON,
} from '../models';

export interface CreateUserCompanyInviteStateRequest {
    userCompanyInviteState: UserCompanyInviteState;
}

export interface DeleteUserCompanyInviteStateRequest {
    id: number;
}

export interface GetUserCompanyInviteStateRequest {
    id: number;
}

export interface PartialUpdateUserCompanyInviteStateRequest {
    id: number;
    userCompanyInviteState: UserCompanyInviteState;
}

export interface UpdateUserCompanyInviteStateRequest {
    id: number;
    userCompanyInviteState: UserCompanyInviteState;
}

/**
 * 
 */
export class UserCompanyInviteStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createUserCompanyInviteStateRaw(requestParameters: CreateUserCompanyInviteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompanyInviteState>> {
        if (requestParameters.userCompanyInviteState === null || requestParameters.userCompanyInviteState === undefined) {
            throw new runtime.RequiredError('userCompanyInviteState','Required parameter requestParameters.userCompanyInviteState was null or undefined when calling createUserCompanyInviteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-company-invite-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCompanyInviteStateToJSON(requestParameters.userCompanyInviteState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyInviteStateFromJSON(jsonValue));
    }

    /**
     */
    async createUserCompanyInviteState(requestParameters: CreateUserCompanyInviteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompanyInviteState> {
        const response = await this.createUserCompanyInviteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteUserCompanyInviteStateRaw(requestParameters: DeleteUserCompanyInviteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserCompanyInviteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-company-invite-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserCompanyInviteState(requestParameters: DeleteUserCompanyInviteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserCompanyInviteStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllUserCompanyInviteStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserCompanyInviteState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-company-invite-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserCompanyInviteStateFromJSON));
    }

    /**
     */
    async getAllUserCompanyInviteStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserCompanyInviteState>> {
        const response = await this.getAllUserCompanyInviteStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserCompanyInviteStateRaw(requestParameters: GetUserCompanyInviteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompanyInviteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserCompanyInviteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-company-invite-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyInviteStateFromJSON(jsonValue));
    }

    /**
     */
    async getUserCompanyInviteState(requestParameters: GetUserCompanyInviteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompanyInviteState> {
        const response = await this.getUserCompanyInviteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateUserCompanyInviteStateRaw(requestParameters: PartialUpdateUserCompanyInviteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompanyInviteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateUserCompanyInviteState.');
        }

        if (requestParameters.userCompanyInviteState === null || requestParameters.userCompanyInviteState === undefined) {
            throw new runtime.RequiredError('userCompanyInviteState','Required parameter requestParameters.userCompanyInviteState was null or undefined when calling partialUpdateUserCompanyInviteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-company-invite-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserCompanyInviteStateToJSON(requestParameters.userCompanyInviteState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyInviteStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateUserCompanyInviteState(requestParameters: PartialUpdateUserCompanyInviteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompanyInviteState> {
        const response = await this.partialUpdateUserCompanyInviteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserCompanyInviteStateRaw(requestParameters: UpdateUserCompanyInviteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompanyInviteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserCompanyInviteState.');
        }

        if (requestParameters.userCompanyInviteState === null || requestParameters.userCompanyInviteState === undefined) {
            throw new runtime.RequiredError('userCompanyInviteState','Required parameter requestParameters.userCompanyInviteState was null or undefined when calling updateUserCompanyInviteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-company-invite-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserCompanyInviteStateToJSON(requestParameters.userCompanyInviteState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyInviteStateFromJSON(jsonValue));
    }

    /**
     */
    async updateUserCompanyInviteState(requestParameters: UpdateUserCompanyInviteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompanyInviteState> {
        const response = await this.updateUserCompanyInviteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
