/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UrstammMediaTypeFilter
 */
export interface UrstammMediaTypeFilter {
    /**
     * 
     * @type {string}
     * @memberof UrstammMediaTypeFilter
     */
    equals?: UrstammMediaTypeFilterEqualsEnum;
    /**
     * 
     * @type {string}
     * @memberof UrstammMediaTypeFilter
     */
    notEquals?: UrstammMediaTypeFilterNotEqualsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UrstammMediaTypeFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UrstammMediaTypeFilter
     */
    _in?: Array<UrstammMediaTypeFilterInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UrstammMediaTypeFilter
     */
    notIn?: Array<UrstammMediaTypeFilterNotInEnum>;
}


/**
 * @export
 */
export const UrstammMediaTypeFilterEqualsEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type UrstammMediaTypeFilterEqualsEnum = typeof UrstammMediaTypeFilterEqualsEnum[keyof typeof UrstammMediaTypeFilterEqualsEnum];

/**
 * @export
 */
export const UrstammMediaTypeFilterNotEqualsEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type UrstammMediaTypeFilterNotEqualsEnum = typeof UrstammMediaTypeFilterNotEqualsEnum[keyof typeof UrstammMediaTypeFilterNotEqualsEnum];

/**
 * @export
 */
export const UrstammMediaTypeFilterInEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type UrstammMediaTypeFilterInEnum = typeof UrstammMediaTypeFilterInEnum[keyof typeof UrstammMediaTypeFilterInEnum];

/**
 * @export
 */
export const UrstammMediaTypeFilterNotInEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type UrstammMediaTypeFilterNotInEnum = typeof UrstammMediaTypeFilterNotInEnum[keyof typeof UrstammMediaTypeFilterNotInEnum];


/**
 * Check if a given object implements the UrstammMediaTypeFilter interface.
 */
export function instanceOfUrstammMediaTypeFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UrstammMediaTypeFilterFromJSON(json: any): UrstammMediaTypeFilter {
    return UrstammMediaTypeFilterFromJSONTyped(json, false);
}

export function UrstammMediaTypeFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrstammMediaTypeFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
        'notEquals': !exists(json, 'notEquals') ? undefined : json['notEquals'],
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
    };
}

export function UrstammMediaTypeFilterToJSON(value?: UrstammMediaTypeFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals,
        'notEquals': value.notEquals,
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
    };
}

