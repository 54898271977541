/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';

/**
 * 
 * @export
 * @interface XmlExportErp
 */
export interface XmlExportErp {
    /**
     * 
     * @type {number}
     * @memberof XmlExportErp
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof XmlExportErp
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof XmlExportErp
     */
    code?: XmlExportErpCodeEnum;
    /**
     * 
     * @type {Set<Company>}
     * @memberof XmlExportErp
     */
    companies?: Set<Company>;
}


/**
 * @export
 */
export const XmlExportErpCodeEnum = {
    Sharpforst: 'SHARPFORST'
} as const;
export type XmlExportErpCodeEnum = typeof XmlExportErpCodeEnum[keyof typeof XmlExportErpCodeEnum];


/**
 * Check if a given object implements the XmlExportErp interface.
 */
export function instanceOfXmlExportErp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function XmlExportErpFromJSON(json: any): XmlExportErp {
    return XmlExportErpFromJSONTyped(json, false);
}

export function XmlExportErpFromJSONTyped(json: any, ignoreDiscriminator: boolean): XmlExportErp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'companies': !exists(json, 'companies') ? undefined : (new Set((json['companies'] as Array<any>).map(CompanyFromJSON))),
    };
}

export function XmlExportErpToJSON(value?: XmlExportErp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'companies': value.companies === undefined ? undefined : (Array.from(value.companies as Set<any>).map(CompanyToJSON)),
    };
}

