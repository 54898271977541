import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { Batch } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface BatchState {
  list: Batch[];
  completeList: Batch[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: BatchState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const batch = createSlice({
  name: 'batchList',
  initialState,
  reducers: {
    setBatchList: (state, action) => {
      let batchList = { ...state }.list;
      let batchCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(batchList, element);
        pushElementOrReplaceById(batchCompleteList, element);
      });

      state.list = batchList;
      state.completeList = batchCompleteList;
    },
    setBatchTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setBatchListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setBatchPage: (state, action) => {
      state.page = action.payload;
    },
    resetBatchList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearBatchList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const { setBatchList, setBatchListSort, resetBatchList, setBatchTotalPages, setBatchPage, clearBatchList } =
  batch.actions;

export default batch.reducer;
