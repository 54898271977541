import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import { NumberHelper } from '@helpers/NumberHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import moment from 'moment';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  FlatList,
  RefreshControl,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle
} from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { isCompanyMock, truncate } from '../../../utils/classes/UrstammUtilityFunctions';
import { buildPilePhotoUrl } from '../../../utils/classes/UrstammUtilityImage';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { UrstammFontFamily, UrstammFontsize } from '../../../utils/styles/UrstammFont';
import { UrstammStyle, UrstammStyleContainer, UrstammStyleLayout } from '../../../utils/styles/UrstammStyle';
import { SlideButtonOption } from '../button/UrstammButtonFilter';
import UrstammIcon, { UrstammIconProps } from '../icon/UrstammIcon';
import UrstammInput from '../input/UrstammInput';
import UrstammSwitch from '../switch/UrstammSwitch';

export interface ListSubData {
  title?: string;
  titleTextStyle?: StyleProp<TextStyle>;
  key: string;
  isEnum?: boolean;
  changedKey?: string;
  secondKey?: string;
  keyId?: string;
  dataTextStyle?: StyleProp<TextStyle>;
  dataViewStyle?: StyleProp<ViewStyle>;
  truncateMaxLength?: number;
  dataContainerViewStyle?: StyleProp<ViewStyle>;
}

export interface UrstammListCurrentState {
  icon?: (state: any) => ReactElement;
  color?: (state: any) => StyleProp<TextStyle>;
  text?: (state: any) => string;
}

export interface UrstammListStyle {
  mainTopContainerViewStyle?: StyleProp<ViewStyle>;
  mainBottomContainerViewStyle?: StyleProp<ViewStyle>;
  mainTitleTextStyle?: StyleProp<TextStyle>;
  bottomContainerViewStyle?: StyleProp<ViewStyle>;
  bottomDataTextStyle?: StyleProp<TextStyle>;
  paddingBottom?: number;
}

export interface UrstammListSwitchProperty {
  enabled: boolean;
  statusByFn?: any;
  colorByFn?: any;
  textByFn?: any;
  textStyle?: StyleProp<TextStyle>;
  disabledByFn?: any;
  indeterminateByFn?: any;
  switchConfirm?: any;
}

export type UrstammListItemActionPosition =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-right'
  | 'bottom-center'
  | 'bottom-left';

export interface UrstammListItemActionProps {
  action: (item: any, index?: number) => void | Promise<void>;
  title?: string;
  icon?: React.ReactNode | string;
  iconProps?: Omit<UrstammIconProps, 'iconName'> & { iconSize?: number };
  preAction?: (item: any, index?: number) => void | Promise<void>;
  postAction?: (item: any, index?: number) => void | Promise<void>;
  titleStyle?: StyleProp<TextStyle>;
  enabled?: boolean | ((item: any, index?: number) => boolean);
}

export interface UrstammListItemProps {
  actions?: { [position in UrstammListItemActionPosition]?: UrstammListItemActionProps };
}

export interface UrstammListAdditionalFnProps {
  enabled: boolean;
  enableCreate: boolean;
  update: (data: any) => void;
  delete: (data: any) => void;
  iconUpdateName: string;
  iconUpdateColor: string;
  iconDeleteName: string;
  iconDeleteColor: string;
}

export default function UrstammList(props: {
  listStyle?: UrstammListStyle;
  testID: string;
  list: any;
  filterBy?: SlideButtonOption[];
  listSubData?: ListSubData[];
  textForEmptyList: string;
  uri?: string;
  elementSelected?: (data: any) => void;
  multipleSelection?: boolean;
  selectedElement?: any; // TODO
  refresh?: () => void;
  moreItems?: () => void;
  titleKey?: string;
  titleTruncateMaxLength?: number;
  titleByFunction?: (data?: any) => string;
  currentState?: UrstammListCurrentState;
  switchOptions?: UrstammListSwitchProperty;
  elementSwitched?: (data: any) => void;
  // TODO: to be removed - use top-right corner as def position
  switchPressed?: (data: any, pressed: boolean) => void;
  filterList?: boolean;
  filterListFunction?: (text: string, data?: any) => boolean;
  getTextForData?: (data: any, key?: any) => string;
  getTextForFullData?: (data: any, key?: any) => string;
  isFieldHiddenFunction?: (data: any, key?: any) => boolean;
  touchSwitch?: (data: any) => void;
  additionalFnProps?: UrstammListAdditionalFnProps;
  listItemProps?: UrstammListItemProps;
  showHash: boolean;
}) {
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [listState, setListState] = useState<any[]>([]);
  const [completeListState, setCompleteListState] = useState<any[]>([]);
  const [image, setImage] = useState<string>('');
  const [switchPressed, setSwitchPressed] = useState<boolean>(false);
  const [filterText, setFilterText] = useStateWithCallbackLazy<string>('');

  useEffect(() => {
    setListState(props.list);
    setCompleteListState(props.list);
    if (props.filterBy && props.filterBy.length > 0) {
      filterList();
    }
    return () => {};
  }, [props.filterBy, props.list]);

  const filterData = (text: any) => {
    if (text && text.length > 0) {
      let listUpdated = props.list.filter((data: any) =>
        props.filterListFunction
          ? props.filterListFunction(text, data)
          : data?.name?.toLowerCase().includes(text.toLowerCase())
      );
      return setListState(listUpdated);
    }
    return setListState(props.list);
  };

  const buildPilePhoto = (data: any): any => {
    return buildPilePhotoUrl(data).then(url => url);
  };

  const filterList = () => {
    if (props.filterBy && props.filterBy.length > 0) {
      let filterBy = props.filterBy[0];
      let filter = props.filterBy[0].id!;
      if (filter !== 'ALL') {
        let listUpdated = props.list.filter((data: any) =>
          filterBy.key ? data[filterBy.key] == filter : data.currentState == filter
        );
        setListState(listUpdated);
      } else {
        setListState(props.list);
      }
    }
  };

  const navigateToDetails = (data: any) => {
    props.elementSelected ? props.elementSelected(data) : null;
  };

  const onScrollHandler = () => {
    props.moreItems ? props.moreItems() : null;
  };

  /**
   * Pressing toggle
   * @param data
   */
  const onPressSwitch = (data?: any) => {
    setSwitchPressed(!switchPressed);
    if (props.switchOptions?.disabledByFn && props.switchOptions?.statusByFn) {
      //If properties are not undefined
      if (!props.switchOptions?.statusByFn(data.item) && props.switchOptions?.disabledByFn(data.item)) {
        props.switchPressed ? props.switchPressed(data.item, true) : null;
      }
    }
  };

  const getProcessorName = (elem: ListSubData, data: any) => {
    let value: string | null = null;
    if (elem.key == 'processor') {
      value = '--';
      if (data.item?.processor && !isCompanyMock(data.item?.processor)) {
        value = data.item.processor.name;
      } else if (data.item?.processorCustomName) {
        value = data.item.processorCustomName;
      } else if (data.item?.processor && isCompanyMock(data.item?.processor)) {
        value = i18n.t('views.shipping_note.no_urstamm_processor');
      }
      return truncate(value, elem.truncateMaxLength || 20);
    }
    return null;
  };

  const getOutgoingBatchData = (elem: ListSubData, data: any) => {
    if (elem.key == 'outgoingBatchData') {
      if (data.item?.outgoingBatch) {
        const value = `${data.item.outgoingBatch.name} (${data.item.outgoingBatch.uniqueIdentifier})`;
        if ((elem.truncateMaxLength || elem.truncateMaxLength == 0) && elem.truncateMaxLength > -1) {
          return truncate(value, elem.truncateMaxLength);
        }
        return value;
      } else {
        return '--';
      }
    }
    return null;
  };

  const renderListItemAction = (position: UrstammListItemActionPosition, data: any, index: number): React.ReactNode => {
    const actionProps = props.listItemProps?.actions?.[position];
    const isStringIcon = typeof actionProps?.icon === 'string';
    const isNodeIcon = !isStringIcon && typeof actionProps?.icon !== 'undefined';

    const _action = async () => {
      await actionProps?.preAction?.(data.item, index);
      await actionProps?.action?.(data.item, index);
      await actionProps?.postAction?.(data.item, index);
    };

    const _styleContainer = [
      ...(position === 'top-left' ? [UrstammStyle.flexStartX] : []),
      ...(position === 'top-center' ? [UrstammStyle.flexCenterX] : []),
      ...(position === 'top-right' ? [UrstammStyle.flexEndX] : []),
      ...(position === 'bottom-right' ? [UrstammStyle.flexEndX] : []),
      ...(position === 'bottom-center' ? [UrstammStyle.flexCenterX] : []),
      ...(position === 'bottom-left' ? [UrstammStyle.flexStartX] : []),
      UrstammStyle.flexRow,
      {
        width: '33.3333333%'
      }
    ];

    const _style = [
      ...(position === 'top-left' ? [UrstammStyle.flexStartX] : []),
      ...(position === 'top-center' ? [UrstammStyle.flexCenterX] : []),
      ...(position === 'top-right' ? [UrstammStyle.flexEndX] : []),
      ...(position === 'bottom-right' ? [UrstammStyle.flexEndX] : []),
      ...(position === 'bottom-center' ? [UrstammStyle.flexCenterX] : []),
      ...(position === 'bottom-left' ? [UrstammStyle.flexStartX] : []),
      {
        ...styles.iconCorner,
        padding: 4,
        borderRadius: 3
      }
    ];

    return (
      <View style={[..._styleContainer]}>
        {actionProps && (
          <TouchableOpacity
            disabled={actionProps.enabled === false ? true : false}
            onPress={_action}
            style={[..._style]}>
            {isStringIcon && (
              <UrstammIcon
                name={actionProps.icon}
                size={actionProps?.iconProps?.iconSize || UrstammFontsize.mbaseFontSize}
                color={actionProps?.iconProps?.iconColor || ColorTheme.mainGreen}
              />
            )}
            {isNodeIcon && <View style={[..._style]}>{actionProps?.icon}</View>}
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const hasTopActions = useMemo(
    () =>
      props.listItemProps?.actions?.['top-left'] ||
      props.listItemProps?.actions?.['top-center'] ||
      props.listItemProps?.actions?.['top-right'],
    [
      props.listItemProps?.actions?.['top-left'],
      props.listItemProps?.actions?.['top-center'],
      props.listItemProps?.actions?.['top-right']
    ]
  );

  const hasBottomActions = useMemo(
    () =>
      props.listItemProps?.actions?.['bottom-left'] ||
      props.listItemProps?.actions?.['bottom-center'] ||
      props.listItemProps?.actions?.['bottom-right'],
    [
      props.listItemProps?.actions?.['bottom-left'],
      props.listItemProps?.actions?.['bottom-center'],
      props.listItemProps?.actions?.['bottom-right']
    ]
  );

  const renderItem = (data: any, index: number): ReactElement => {
    return (
      <TouchableOpacity
        key={index}
        style={[
          props.listStyle?.mainTopContainerViewStyle,
          UrstammStyle.baseShadowList,
          props.multipleSelection && data.item.selected ? { borderColor: ColorTheme.mainGreen, borderWidth: 2 } : null
        ]}
        onPress={() => navigateToDetails(data)}
        disabled={data.item.disabled}>
        {hasTopActions && (
          <View style={[styles.actionsTop]}>
            {renderListItemAction('top-left', data, index)}
            {renderListItemAction('top-center', data, index)}
            {renderListItemAction('top-right', data, index)}
          </View>
        )}
        {/**Switch component */}
        {props.switchOptions?.enabled ? (
          <View style={[UrstammStyle.flexRow, UrstammStyle.spaceBetween, UrstammStyle.paddingBottom4]}>
            <View style={[UrstammStyle.flex1]}>
              <Text style={[props.switchOptions.textStyle, props.switchOptions.colorByFn(data.item)]}>
                {props.switchOptions.textByFn(data.item)}
              </Text>
            </View>

            <TouchableOpacity onPress={() => onPressSwitch(data)}>
              <UrstammSwitch
                pressed={switchPressed}
                style={{
                  trackColor: {
                    false: ColorTheme.yellow,
                    true: ColorTheme.lightGreen,
                    indeterminate: ColorTheme.fineGrey
                  },
                  thumbColor: {
                    enabled: ColorTheme.fineGrey,
                    disabled: ColorTheme.fineGrey
                  },
                  ios_backGroundColor: 'white'
                }}
                key={index}
                //TODO: trunk---> value
                valueChanged={(switchValue: boolean) => {
                  props.elementSwitched
                    ? props.elementSwitched({
                        id: data.item?.id,
                        trunk: data.item,
                        edited: {
                          status: switchValue,
                          data: data.item
                        }
                      })
                    : null;
                }}
                value={props.switchOptions.statusByFn(data.item)}
                disabled={props.switchOptions.disabledByFn ? props.switchOptions.disabledByFn(data.item) : null}
                indeterminate={
                  props.switchOptions?.indeterminateByFn ? props.switchOptions?.indeterminateByFn(data.item) : false
                }
                switchConfirm={props.switchOptions?.switchConfirm ? props.switchOptions.switchConfirm(data.item) : null}
              />
            </TouchableOpacity>
          </View>
        ) : null}

        {/**Additional functions */}
        {props.additionalFnProps?.enabled ? (
          <View style={[UrstammStyle.flexRow, UrstammStyle.flexEndX, UrstammStyle.paddingBottom4]}>
            <TouchableOpacity
              style={[
                styles.iconContainerStyle,
                UrstammStyle.baseShadow,
                { borderColor: ColorTheme.fineGrey, marginRight: 8 }
              ]}
              onPress={() => props.additionalFnProps?.update(data.item)}>
              <UrstammIcon
                name={props.additionalFnProps?.iconUpdateName}
                size={PlatformHelper.normalizeByDevice(24)}
                color={props.additionalFnProps.iconUpdateColor!}
                type="FontAwesome5"
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.iconContainerStyle, UrstammStyle.baseShadow, { borderColor: ColorTheme.fineGrey }]}
              onPress={() => props.additionalFnProps?.delete(data.item)}>
              <UrstammIcon
                name={props.additionalFnProps?.iconDeleteName}
                size={PlatformHelper.normalizeByDevice(24)}
                color={props.additionalFnProps.iconDeleteColor!}
                type="FontAwesome5"
              />
            </TouchableOpacity>
          </View>
        ) : null}

        <View
          style={[
            UrstammStyle.flexRow,
            UrstammStyle.spaceBetween,
            UrstammStyle.wrap
            // props.listSubData && props.listSubData?.length <= 1 ? { flexBasis: '100%' } : null
          ]}>
          {/**Title */}
          {data.item.name && !props.titleKey && !props.titleByFunction ? (
            <View style={[UrstammStyle.flex1]}>
              <Text style={props.listStyle?.mainTitleTextStyle}>
                {/* {truncate(data.item.name, props.titleTruncateMaxLength ? props.titleTruncateMaxLength : 20)} */}
                {data.item.name}
              </Text>
            </View>
          ) : props.titleKey && props.titleKey.length > 0 && !props.titleByFunction ? (
            <View style={[UrstammStyle.flex1]}>
              <Text style={props.listStyle?.mainTitleTextStyle}>
                {data.item[props.titleKey!] ? data.item[props.titleKey!] : '--'}
              </Text>
            </View>
          ) : (
            <View style={[UrstammStyle.flex1]}>
              <Text style={props.listStyle?.mainTitleTextStyle}>
                {data.item.recordingType == 'DATE' && props.showHash ? '#' : ''}
                {props.titleByFunction ? props.titleByFunction(data.item) : ''}
              </Text>
            </View>
          )}
        </View>
        {/* {data.item.photoFilepath && <Image source={{ uri: buildPilePhoto(data.item) }} style={{ width: 48, height: 48 }} />} */}

        {/**Data */}
        <View style={[props.listStyle?.mainBottomContainerViewStyle]}>
          {/**Here we render data from listState using a key defined in listKey */}
          {props.listSubData && props.listSubData.length > 0
            ? props.listSubData.map((elem, idx) => {
                if (props.isFieldHiddenFunction && props.isFieldHiddenFunction(data.item, elem.key)) return <></>;
                return (
                  <View key={elem.keyId} style={elem.dataContainerViewStyle}>
                    <View
                      style={[
                        props.listStyle?.bottomContainerViewStyle,
                        UrstammStyle.wrap,
                        elem.dataViewStyle ? elem.dataViewStyle : null
                      ]}>
                      {/**TITLE */}
                      {elem.title ? <Text style={[elem.titleTextStyle]}>{elem.title + ' '}</Text> : null}

                      {/**DATA  */}
                      {elem.key == 'currentState' ? (
                        <View style={[UrstammStyle.flexRow]}>
                          {data.item.currentState && props.currentState?.icon
                            ? props.currentState?.icon(data.item.currentState)
                            : null}
                          <Text
                            style={[
                              props.listStyle?.bottomDataTextStyle,
                              props.currentState?.color ? props.currentState?.color(data.item.currentState) : null,
                              { alignContent: 'center' },
                              props.currentState?.icon ? { paddingLeft: 8 } : null
                            ]}>
                            {props.currentState?.text ? props.currentState?.text(data.item.currentState) : null}
                          </Text>
                        </View>
                      ) : elem.key == 'totalProducts' ? (
                        <View style={[UrstammStyle.flexRow]}>
                          <Text style={[props.listStyle?.bottomDataTextStyle]}>{data.item.totalProducts}</Text>
                          {/* <Text style={[UrstammStyle.flex1, props.listStyle?.bottomDataTextStyle]}>
                          {data.item.trunks && data.item.trunks.length > 0
                           ? '(' + data.item.trunks.reduce((sum, trunk) => sum + trunk.cubage, 0) + ')'
                            : '--'}
                        </Text> */}
                        </View>
                      ) : elem.isEnum ? (
                        <Text
                          style={[
                            elem.changedKey && data.item[elem.changedKey]
                              ? [props.listStyle?.bottomDataTextStyle, { color: ColorTheme.orange }]
                              : props.listStyle?.bottomDataTextStyle
                          ]}>
                          {props.getTextForData
                            ? truncate(props.getTextForData(data.item[elem.key], elem.key), 10)
                            : undefined}
                          {props.getTextForFullData ? props.getTextForFullData(data.item, elem.key) : undefined}
                          {elem.key == 'qualityWood' ? (data.item['qualityWood'] ? null : '--') : null}
                          {elem.key == 'batchData'
                            ? data.item?.batch
                              ? data.item.batch.name + ' ' + '(' + data.item.batch.uniqueIdentifier + ')'
                              : '--'
                            : null}
                          {getOutgoingBatchData(elem, data)}
                          {getProcessorName(elem, data)}
                        </Text>
                      ) : (
                        <Text
                          style={[
                            elem.changedKey && data.item[elem.changedKey]
                              ? [props.listStyle?.bottomDataTextStyle, { color: ColorTheme.orange }]
                              : props.listStyle?.bottomDataTextStyle
                          ]}>
                          {elem.key == 'creationDate'
                            ? moment(data.item[elem.key]).format('DD/MM/yyyy')
                            : elem.key == 'numberOfTrunks'
                            ? data.item.deliverySheetFactoryMeasurement
                              ? data.item.deliverySheetFactoryMeasurement.amountOfTrunks
                              : data.item[elem.key]
                            : elem.key == 'sumCubage'
                            ? data.item.deliverySheetFactoryMeasurement
                              ? NumberHelper.roundWithThreeDecimals(data.item.deliverySheetFactoryMeasurement.volume)
                              : NumberHelper.roundWithThreeDecimals(data.item[elem.key])
                            : elem.key == 'sumWoodTotal'
                            ? NumberHelper.roundWithThreeDecimals(data.item[elem.key])
                            : elem.key == 'volume'
                            ? NumberHelper.roundWithThreeDecimals(data.item[elem.key])
                            : elem.key == 'estimatedVolume'
                            ? NumberHelper.roundWithThreeDecimals(data.item[elem.key])
                            : data.item[elem.key]
                            ? truncate(
                                elem?.secondKey ? data.item[elem.key][elem?.secondKey] : data.item[elem.key],
                                elem.truncateMaxLength ? elem.truncateMaxLength : 20
                              )
                            : '--'}
                        </Text>
                      )}
                    </View>
                  </View>
                );
              })
            : null}
        </View>
        {hasBottomActions && (
          <View style={[styles.actionsBottom]}>
            {renderListItemAction('bottom-left', data, index)}
            {renderListItemAction('bottom-center', data, index)}
            {renderListItemAction('bottom-right', data, index)}
          </View>
        )}
      </TouchableOpacity>
    );
  };

  return (
    <>
      {/** Filter */}
      {props.list && props.list.length > 0 && props.filterList ? (
        <View style={[UrstammStyleLayout.formElementContainerGrw1, { paddingHorizontal: 4 }]}>
          <UrstammInput
            testID="delivery_sheet_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => setFilterText(text, updatedText => filterData(updatedText))}
            placeholder={i18n.t('generics.search')}
            textInputTitle={undefined}
            mandatory={false}
            multiline={false}
          />
        </View>
      ) : null}
      {listState && listState.length > 0 ? (
        <FlatList
          contentContainerStyle={{
            paddingBottom: props.listStyle?.paddingBottom ? props.listStyle?.paddingBottom : 160,
            paddingHorizontal: 4
          }}
          data={listState}
          //keyExtractor={(item, index) => {item.id}}
          listKey={(item: any) => item.id}
          renderItem={({ item, index }) => renderItem({ item: item }, index)}
          refreshControl={props.refresh ? <RefreshControl refreshing={refreshing} onRefresh={props.refresh} /> : <></>}
          onEndReached={onScrollHandler}
          onEndReachedThreshold={0.5}></FlatList>
      ) : (
        <Text style={styles.emptyList}>{props.textForEmptyList}</Text>
      )}
      {props.additionalFnProps?.enableCreate ? (
        <TouchableOpacity style={[styles.iconCreate, UrstammStyle.baseShadow]}>
          <UrstammImagePlus
            width={PlatformHelper.normalizeByDevice(44)}
            height={PlatformHelper.normalizeByDevice(44)}
          />
        </TouchableOpacity>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  emptyList: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center',
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
    marginBottom: 150
  },
  iconContainerStyle: {
    minWidth: 40,
    padding: 4,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: ColorTheme.lightGreen,
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconCreate: {
    position: 'absolute',
    bottom: -30,
    right: 0,
    width: 80,
    height: 80,
    borderRadius: 50,
    elevation: 5,
    backgroundColor: ColorTheme.mainGreen,
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconCorner: {
    paddingRight: 14,
    alignItems: 'center'
  },
  actionsTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
    marginTop: -8,
    marginRight: -6,
    marginLeft: -6
  },
  actionsBottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
    marginBottom: -8,
    marginLeft: -6,
    marginRight: -6
  }
});
