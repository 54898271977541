import AppContext from '@contexts/AppContext';
import { AdminUserFullDTO } from '@services/apis/generated';
import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import SignupLayout from '../../components/layouts/login/SignupLayout';
import { RootStackScreenProps } from '../../navigation/UrstammNavigationHelper';

export default function SignupContainer({ navigation, route }: RootStackScreenProps<'Signup'>) {
  const appContext = useContext(AppContext);

  const createAccount = (user: AdminUserFullDTO) => {
    //customUserResourceApi.createUserIncludingExtended({adminUserFullDTO: user})
  };

  return <SignupLayout createAccount={createAccount} navigation={navigation} />;
}

const styles = StyleSheet.create({});
