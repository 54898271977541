import { Platform } from 'react-native';
import normalize from 'react-native-normalize';

export class PlatformHelper {
  private static WEB = 'web';
  private static IOS = 'ios';
  private static ANDROID = 'android';

  /**
   * Checks if current platform is web
   * @returns {boolean}
   */
  public static isWeb(): boolean {
    return Platform.OS === PlatformHelper.WEB;
  }

  /**
   * Checks if current platform is iOs
   * @returns {boolean}
   */
  public static isIos(): boolean {
    return Platform.OS === PlatformHelper.IOS;
  }

  /**
   * Checks if current platform is Android
   * @returns {boolean}
   */
  public static isAndroid(): boolean {
    return Platform.OS === PlatformHelper.ANDROID;
  }

  /**
   * Return size by device
   * @returns {number}
   */
  public static normalizeByDevice(size, based?: string): number {
    if (Platform.OS === PlatformHelper.WEB) return size;
    return normalize(size);
  }

}
