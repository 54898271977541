import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImageNewTree from '@components/images/corner/UrstammImageNewTree';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import LoggingRegistrationView from '@components/views/logging/LoggingRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { RootState } from '@redux/store';
import { customLoggingResourceApi } from '@services/apis/ApiConfiguration';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { resetLoggingList } from '../../redux/features/logging/loggingSlice';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function LoggingRegistrationContainer({
  navigation,
  route
}: RootStackScreenProps<'LoggingRegistration'>) {
  const [clearForm, setClearForm] = useState<boolean>(false);
  const dispatch = useDispatch();
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);

  const [mediaType, setMediaType] = useState<any>();

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.new_tree_record'),
      icon: (
        <UrstammImageNewTree
          width={PlatformHelper.normalizeByDevice(34)}
          height={PlatformHelper.normalizeByDevice(24)}
        />
      )
    }
  };

  const submitLoggingForm = async (form: any): Promise<void> => {
    form.logging.permitMediaType = mediaType;
    dispatch(changeLoaderStatus(true));
    form.logging.photoCreationDate = new Date();
    form.logging.id = new Date().getTime();

    customLoggingResourceApi
      .createLoggingCustom(rdxOfflineSyncList, dispatch, addOfflineObjectSync, {
        entityWithFileDTOLogging: {
          entity: form.logging,
          base64File: form.permit,
          loggingPhotoBase64File: form.base64File
        }
      })
      .then(res => {
        dispatch(changeLoaderStatus(false));

        if (res) {
          //   customPileResourceApi.getPilesByLogging({ loggingId: res.id! }).then(pileList => {
          //     if (pileList) {
          //       dispatch(setPileList(pileList));
          //     }
          //   });
          dispatch(resetLoggingList());
          UrstammNavigationHelper.navigateToLoggingList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      title={
        <UrstammTitle
          testID={'logging_title'}
          text={i18n.t('views.logging.logging_registration.new_logging')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <LoggingRegistrationView
          navigation={navigation}
          submitLoggingForm={(form: any) => submitLoggingForm(form)}
          mediaType={(type: string) => setMediaType(type)}
          clearForm={clearForm}
        />
      }
    />
  );
}
