/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Tree,
} from '../models';
import {
    TreeFromJSON,
    TreeToJSON,
} from '../models';

export interface CountTreesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    photoNumberContains?: string;
    photoNumberDoesNotContain?: string;
    photoNumberEquals?: string;
    photoNumberNotEquals?: string;
    photoNumberSpecified?: boolean;
    photoNumberIn?: Array<string>;
    photoNumberNotIn?: Array<string>;
    multipleCounterGreaterThan?: number;
    multipleCounterLessThan?: number;
    multipleCounterGreaterThanOrEqual?: number;
    multipleCounterLessThanOrEqual?: number;
    multipleCounterEquals?: number;
    multipleCounterNotEquals?: number;
    multipleCounterSpecified?: boolean;
    multipleCounterIn?: Array<number>;
    multipleCounterNotIn?: Array<number>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    speciesEquals?: CountTreesSpeciesEqualsEnum;
    speciesNotEquals?: CountTreesSpeciesNotEqualsEnum;
    speciesSpecified?: boolean;
    speciesIn?: Array<CountTreesSpeciesInEnum>;
    speciesNotIn?: Array<CountTreesSpeciesNotInEnum>;
    recordingTypeEquals?: CountTreesRecordingTypeEqualsEnum;
    recordingTypeNotEquals?: CountTreesRecordingTypeNotEqualsEnum;
    recordingTypeSpecified?: boolean;
    recordingTypeIn?: Array<CountTreesRecordingTypeInEnum>;
    recordingTypeNotIn?: Array<CountTreesRecordingTypeNotInEnum>;
    currentStateEquals?: CountTreesCurrentStateEqualsEnum;
    currentStateNotEquals?: CountTreesCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<CountTreesCurrentStateInEnum>;
    currentStateNotIn?: Array<CountTreesCurrentStateNotInEnum>;
    latitudeGreaterThan?: number;
    latitudeLessThan?: number;
    latitudeGreaterThanOrEqual?: number;
    latitudeLessThanOrEqual?: number;
    latitudeEquals?: number;
    latitudeNotEquals?: number;
    latitudeSpecified?: boolean;
    latitudeIn?: Array<number>;
    latitudeNotIn?: Array<number>;
    longitudeGreaterThan?: number;
    longitudeLessThan?: number;
    longitudeGreaterThanOrEqual?: number;
    longitudeLessThanOrEqual?: number;
    longitudeEquals?: number;
    longitudeNotEquals?: number;
    longitudeSpecified?: boolean;
    longitudeIn?: Array<number>;
    longitudeNotIn?: Array<number>;
    forestPhotoFilenameContains?: string;
    forestPhotoFilenameDoesNotContain?: string;
    forestPhotoFilenameEquals?: string;
    forestPhotoFilenameNotEquals?: string;
    forestPhotoFilenameSpecified?: boolean;
    forestPhotoFilenameIn?: Array<string>;
    forestPhotoFilenameNotIn?: Array<string>;
    forestPhotoFilepathContains?: string;
    forestPhotoFilepathDoesNotContain?: string;
    forestPhotoFilepathEquals?: string;
    forestPhotoFilepathNotEquals?: string;
    forestPhotoFilepathSpecified?: boolean;
    forestPhotoFilepathIn?: Array<string>;
    forestPhotoFilepathNotIn?: Array<string>;
    forestPhotoUrlContains?: string;
    forestPhotoUrlDoesNotContain?: string;
    forestPhotoUrlEquals?: string;
    forestPhotoUrlNotEquals?: string;
    forestPhotoUrlSpecified?: boolean;
    forestPhotoUrlIn?: Array<string>;
    forestPhotoUrlNotIn?: Array<string>;
    forestPhotoMd5Contains?: string;
    forestPhotoMd5DoesNotContain?: string;
    forestPhotoMd5Equals?: string;
    forestPhotoMd5NotEquals?: string;
    forestPhotoMd5Specified?: boolean;
    forestPhotoMd5In?: Array<string>;
    forestPhotoMd5NotIn?: Array<string>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    loggingIdGreaterThan?: number;
    loggingIdLessThan?: number;
    loggingIdGreaterThanOrEqual?: number;
    loggingIdLessThanOrEqual?: number;
    loggingIdEquals?: number;
    loggingIdNotEquals?: number;
    loggingIdSpecified?: boolean;
    loggingIdIn?: Array<number>;
    loggingIdNotIn?: Array<number>;
    plotIdGreaterThan?: number;
    plotIdLessThan?: number;
    plotIdGreaterThanOrEqual?: number;
    plotIdLessThanOrEqual?: number;
    plotIdEquals?: number;
    plotIdNotEquals?: number;
    plotIdSpecified?: boolean;
    plotIdIn?: Array<number>;
    plotIdNotIn?: Array<number>;
    forestPhotoAuthorIdGreaterThan?: number;
    forestPhotoAuthorIdLessThan?: number;
    forestPhotoAuthorIdGreaterThanOrEqual?: number;
    forestPhotoAuthorIdLessThanOrEqual?: number;
    forestPhotoAuthorIdEquals?: number;
    forestPhotoAuthorIdNotEquals?: number;
    forestPhotoAuthorIdSpecified?: boolean;
    forestPhotoAuthorIdIn?: Array<number>;
    forestPhotoAuthorIdNotIn?: Array<number>;
    treeStateIdGreaterThan?: number;
    treeStateIdLessThan?: number;
    treeStateIdGreaterThanOrEqual?: number;
    treeStateIdLessThanOrEqual?: number;
    treeStateIdEquals?: number;
    treeStateIdNotEquals?: number;
    treeStateIdSpecified?: boolean;
    treeStateIdIn?: Array<number>;
    treeStateIdNotIn?: Array<number>;
    trunkIdGreaterThan?: number;
    trunkIdLessThan?: number;
    trunkIdGreaterThanOrEqual?: number;
    trunkIdLessThanOrEqual?: number;
    trunkIdEquals?: number;
    trunkIdNotEquals?: number;
    trunkIdSpecified?: boolean;
    trunkIdIn?: Array<number>;
    trunkIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateTreeRequest {
    tree: Tree;
}

export interface DeleteTreeRequest {
    id: number;
}

export interface GetAllTreesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    photoNumberContains?: string;
    photoNumberDoesNotContain?: string;
    photoNumberEquals?: string;
    photoNumberNotEquals?: string;
    photoNumberSpecified?: boolean;
    photoNumberIn?: Array<string>;
    photoNumberNotIn?: Array<string>;
    multipleCounterGreaterThan?: number;
    multipleCounterLessThan?: number;
    multipleCounterGreaterThanOrEqual?: number;
    multipleCounterLessThanOrEqual?: number;
    multipleCounterEquals?: number;
    multipleCounterNotEquals?: number;
    multipleCounterSpecified?: boolean;
    multipleCounterIn?: Array<number>;
    multipleCounterNotIn?: Array<number>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    speciesEquals?: GetAllTreesSpeciesEqualsEnum;
    speciesNotEquals?: GetAllTreesSpeciesNotEqualsEnum;
    speciesSpecified?: boolean;
    speciesIn?: Array<GetAllTreesSpeciesInEnum>;
    speciesNotIn?: Array<GetAllTreesSpeciesNotInEnum>;
    recordingTypeEquals?: GetAllTreesRecordingTypeEqualsEnum;
    recordingTypeNotEquals?: GetAllTreesRecordingTypeNotEqualsEnum;
    recordingTypeSpecified?: boolean;
    recordingTypeIn?: Array<GetAllTreesRecordingTypeInEnum>;
    recordingTypeNotIn?: Array<GetAllTreesRecordingTypeNotInEnum>;
    currentStateEquals?: GetAllTreesCurrentStateEqualsEnum;
    currentStateNotEquals?: GetAllTreesCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<GetAllTreesCurrentStateInEnum>;
    currentStateNotIn?: Array<GetAllTreesCurrentStateNotInEnum>;
    latitudeGreaterThan?: number;
    latitudeLessThan?: number;
    latitudeGreaterThanOrEqual?: number;
    latitudeLessThanOrEqual?: number;
    latitudeEquals?: number;
    latitudeNotEquals?: number;
    latitudeSpecified?: boolean;
    latitudeIn?: Array<number>;
    latitudeNotIn?: Array<number>;
    longitudeGreaterThan?: number;
    longitudeLessThan?: number;
    longitudeGreaterThanOrEqual?: number;
    longitudeLessThanOrEqual?: number;
    longitudeEquals?: number;
    longitudeNotEquals?: number;
    longitudeSpecified?: boolean;
    longitudeIn?: Array<number>;
    longitudeNotIn?: Array<number>;
    forestPhotoFilenameContains?: string;
    forestPhotoFilenameDoesNotContain?: string;
    forestPhotoFilenameEquals?: string;
    forestPhotoFilenameNotEquals?: string;
    forestPhotoFilenameSpecified?: boolean;
    forestPhotoFilenameIn?: Array<string>;
    forestPhotoFilenameNotIn?: Array<string>;
    forestPhotoFilepathContains?: string;
    forestPhotoFilepathDoesNotContain?: string;
    forestPhotoFilepathEquals?: string;
    forestPhotoFilepathNotEquals?: string;
    forestPhotoFilepathSpecified?: boolean;
    forestPhotoFilepathIn?: Array<string>;
    forestPhotoFilepathNotIn?: Array<string>;
    forestPhotoUrlContains?: string;
    forestPhotoUrlDoesNotContain?: string;
    forestPhotoUrlEquals?: string;
    forestPhotoUrlNotEquals?: string;
    forestPhotoUrlSpecified?: boolean;
    forestPhotoUrlIn?: Array<string>;
    forestPhotoUrlNotIn?: Array<string>;
    forestPhotoMd5Contains?: string;
    forestPhotoMd5DoesNotContain?: string;
    forestPhotoMd5Equals?: string;
    forestPhotoMd5NotEquals?: string;
    forestPhotoMd5Specified?: boolean;
    forestPhotoMd5In?: Array<string>;
    forestPhotoMd5NotIn?: Array<string>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    loggingIdGreaterThan?: number;
    loggingIdLessThan?: number;
    loggingIdGreaterThanOrEqual?: number;
    loggingIdLessThanOrEqual?: number;
    loggingIdEquals?: number;
    loggingIdNotEquals?: number;
    loggingIdSpecified?: boolean;
    loggingIdIn?: Array<number>;
    loggingIdNotIn?: Array<number>;
    plotIdGreaterThan?: number;
    plotIdLessThan?: number;
    plotIdGreaterThanOrEqual?: number;
    plotIdLessThanOrEqual?: number;
    plotIdEquals?: number;
    plotIdNotEquals?: number;
    plotIdSpecified?: boolean;
    plotIdIn?: Array<number>;
    plotIdNotIn?: Array<number>;
    forestPhotoAuthorIdGreaterThan?: number;
    forestPhotoAuthorIdLessThan?: number;
    forestPhotoAuthorIdGreaterThanOrEqual?: number;
    forestPhotoAuthorIdLessThanOrEqual?: number;
    forestPhotoAuthorIdEquals?: number;
    forestPhotoAuthorIdNotEquals?: number;
    forestPhotoAuthorIdSpecified?: boolean;
    forestPhotoAuthorIdIn?: Array<number>;
    forestPhotoAuthorIdNotIn?: Array<number>;
    treeStateIdGreaterThan?: number;
    treeStateIdLessThan?: number;
    treeStateIdGreaterThanOrEqual?: number;
    treeStateIdLessThanOrEqual?: number;
    treeStateIdEquals?: number;
    treeStateIdNotEquals?: number;
    treeStateIdSpecified?: boolean;
    treeStateIdIn?: Array<number>;
    treeStateIdNotIn?: Array<number>;
    trunkIdGreaterThan?: number;
    trunkIdLessThan?: number;
    trunkIdGreaterThanOrEqual?: number;
    trunkIdLessThanOrEqual?: number;
    trunkIdEquals?: number;
    trunkIdNotEquals?: number;
    trunkIdSpecified?: boolean;
    trunkIdIn?: Array<number>;
    trunkIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetTreeRequest {
    id: number;
}

export interface PartialUpdateTreeRequest {
    id: number;
    tree: Tree;
}

export interface UpdateTreeRequest {
    id: number;
    tree: Tree;
}

/**
 * 
 */
export class TreeResourceApi extends runtime.BaseAPI {

    /**
     */
    async countTreesRaw(requestParameters: CountTreesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.photoNumberContains !== undefined) {
            queryParameters['photoNumber.contains'] = requestParameters.photoNumberContains;
        }

        if (requestParameters.photoNumberDoesNotContain !== undefined) {
            queryParameters['photoNumber.doesNotContain'] = requestParameters.photoNumberDoesNotContain;
        }

        if (requestParameters.photoNumberEquals !== undefined) {
            queryParameters['photoNumber.equals'] = requestParameters.photoNumberEquals;
        }

        if (requestParameters.photoNumberNotEquals !== undefined) {
            queryParameters['photoNumber.notEquals'] = requestParameters.photoNumberNotEquals;
        }

        if (requestParameters.photoNumberSpecified !== undefined) {
            queryParameters['photoNumber.specified'] = requestParameters.photoNumberSpecified;
        }

        if (requestParameters.photoNumberIn) {
            queryParameters['photoNumber.in'] = requestParameters.photoNumberIn;
        }

        if (requestParameters.photoNumberNotIn) {
            queryParameters['photoNumber.notIn'] = requestParameters.photoNumberNotIn;
        }

        if (requestParameters.multipleCounterGreaterThan !== undefined) {
            queryParameters['multipleCounter.greaterThan'] = requestParameters.multipleCounterGreaterThan;
        }

        if (requestParameters.multipleCounterLessThan !== undefined) {
            queryParameters['multipleCounter.lessThan'] = requestParameters.multipleCounterLessThan;
        }

        if (requestParameters.multipleCounterGreaterThanOrEqual !== undefined) {
            queryParameters['multipleCounter.greaterThanOrEqual'] = requestParameters.multipleCounterGreaterThanOrEqual;
        }

        if (requestParameters.multipleCounterLessThanOrEqual !== undefined) {
            queryParameters['multipleCounter.lessThanOrEqual'] = requestParameters.multipleCounterLessThanOrEqual;
        }

        if (requestParameters.multipleCounterEquals !== undefined) {
            queryParameters['multipleCounter.equals'] = requestParameters.multipleCounterEquals;
        }

        if (requestParameters.multipleCounterNotEquals !== undefined) {
            queryParameters['multipleCounter.notEquals'] = requestParameters.multipleCounterNotEquals;
        }

        if (requestParameters.multipleCounterSpecified !== undefined) {
            queryParameters['multipleCounter.specified'] = requestParameters.multipleCounterSpecified;
        }

        if (requestParameters.multipleCounterIn) {
            queryParameters['multipleCounter.in'] = requestParameters.multipleCounterIn;
        }

        if (requestParameters.multipleCounterNotIn) {
            queryParameters['multipleCounter.notIn'] = requestParameters.multipleCounterNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.speciesEquals !== undefined) {
            queryParameters['species.equals'] = requestParameters.speciesEquals;
        }

        if (requestParameters.speciesNotEquals !== undefined) {
            queryParameters['species.notEquals'] = requestParameters.speciesNotEquals;
        }

        if (requestParameters.speciesSpecified !== undefined) {
            queryParameters['species.specified'] = requestParameters.speciesSpecified;
        }

        if (requestParameters.speciesIn) {
            queryParameters['species.in'] = requestParameters.speciesIn;
        }

        if (requestParameters.speciesNotIn) {
            queryParameters['species.notIn'] = requestParameters.speciesNotIn;
        }

        if (requestParameters.recordingTypeEquals !== undefined) {
            queryParameters['recordingType.equals'] = requestParameters.recordingTypeEquals;
        }

        if (requestParameters.recordingTypeNotEquals !== undefined) {
            queryParameters['recordingType.notEquals'] = requestParameters.recordingTypeNotEquals;
        }

        if (requestParameters.recordingTypeSpecified !== undefined) {
            queryParameters['recordingType.specified'] = requestParameters.recordingTypeSpecified;
        }

        if (requestParameters.recordingTypeIn) {
            queryParameters['recordingType.in'] = requestParameters.recordingTypeIn;
        }

        if (requestParameters.recordingTypeNotIn) {
            queryParameters['recordingType.notIn'] = requestParameters.recordingTypeNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.latitudeGreaterThan !== undefined) {
            queryParameters['latitude.greaterThan'] = requestParameters.latitudeGreaterThan;
        }

        if (requestParameters.latitudeLessThan !== undefined) {
            queryParameters['latitude.lessThan'] = requestParameters.latitudeLessThan;
        }

        if (requestParameters.latitudeGreaterThanOrEqual !== undefined) {
            queryParameters['latitude.greaterThanOrEqual'] = requestParameters.latitudeGreaterThanOrEqual;
        }

        if (requestParameters.latitudeLessThanOrEqual !== undefined) {
            queryParameters['latitude.lessThanOrEqual'] = requestParameters.latitudeLessThanOrEqual;
        }

        if (requestParameters.latitudeEquals !== undefined) {
            queryParameters['latitude.equals'] = requestParameters.latitudeEquals;
        }

        if (requestParameters.latitudeNotEquals !== undefined) {
            queryParameters['latitude.notEquals'] = requestParameters.latitudeNotEquals;
        }

        if (requestParameters.latitudeSpecified !== undefined) {
            queryParameters['latitude.specified'] = requestParameters.latitudeSpecified;
        }

        if (requestParameters.latitudeIn) {
            queryParameters['latitude.in'] = requestParameters.latitudeIn;
        }

        if (requestParameters.latitudeNotIn) {
            queryParameters['latitude.notIn'] = requestParameters.latitudeNotIn;
        }

        if (requestParameters.longitudeGreaterThan !== undefined) {
            queryParameters['longitude.greaterThan'] = requestParameters.longitudeGreaterThan;
        }

        if (requestParameters.longitudeLessThan !== undefined) {
            queryParameters['longitude.lessThan'] = requestParameters.longitudeLessThan;
        }

        if (requestParameters.longitudeGreaterThanOrEqual !== undefined) {
            queryParameters['longitude.greaterThanOrEqual'] = requestParameters.longitudeGreaterThanOrEqual;
        }

        if (requestParameters.longitudeLessThanOrEqual !== undefined) {
            queryParameters['longitude.lessThanOrEqual'] = requestParameters.longitudeLessThanOrEqual;
        }

        if (requestParameters.longitudeEquals !== undefined) {
            queryParameters['longitude.equals'] = requestParameters.longitudeEquals;
        }

        if (requestParameters.longitudeNotEquals !== undefined) {
            queryParameters['longitude.notEquals'] = requestParameters.longitudeNotEquals;
        }

        if (requestParameters.longitudeSpecified !== undefined) {
            queryParameters['longitude.specified'] = requestParameters.longitudeSpecified;
        }

        if (requestParameters.longitudeIn) {
            queryParameters['longitude.in'] = requestParameters.longitudeIn;
        }

        if (requestParameters.longitudeNotIn) {
            queryParameters['longitude.notIn'] = requestParameters.longitudeNotIn;
        }

        if (requestParameters.forestPhotoFilenameContains !== undefined) {
            queryParameters['forestPhotoFilename.contains'] = requestParameters.forestPhotoFilenameContains;
        }

        if (requestParameters.forestPhotoFilenameDoesNotContain !== undefined) {
            queryParameters['forestPhotoFilename.doesNotContain'] = requestParameters.forestPhotoFilenameDoesNotContain;
        }

        if (requestParameters.forestPhotoFilenameEquals !== undefined) {
            queryParameters['forestPhotoFilename.equals'] = requestParameters.forestPhotoFilenameEquals;
        }

        if (requestParameters.forestPhotoFilenameNotEquals !== undefined) {
            queryParameters['forestPhotoFilename.notEquals'] = requestParameters.forestPhotoFilenameNotEquals;
        }

        if (requestParameters.forestPhotoFilenameSpecified !== undefined) {
            queryParameters['forestPhotoFilename.specified'] = requestParameters.forestPhotoFilenameSpecified;
        }

        if (requestParameters.forestPhotoFilenameIn) {
            queryParameters['forestPhotoFilename.in'] = requestParameters.forestPhotoFilenameIn;
        }

        if (requestParameters.forestPhotoFilenameNotIn) {
            queryParameters['forestPhotoFilename.notIn'] = requestParameters.forestPhotoFilenameNotIn;
        }

        if (requestParameters.forestPhotoFilepathContains !== undefined) {
            queryParameters['forestPhotoFilepath.contains'] = requestParameters.forestPhotoFilepathContains;
        }

        if (requestParameters.forestPhotoFilepathDoesNotContain !== undefined) {
            queryParameters['forestPhotoFilepath.doesNotContain'] = requestParameters.forestPhotoFilepathDoesNotContain;
        }

        if (requestParameters.forestPhotoFilepathEquals !== undefined) {
            queryParameters['forestPhotoFilepath.equals'] = requestParameters.forestPhotoFilepathEquals;
        }

        if (requestParameters.forestPhotoFilepathNotEquals !== undefined) {
            queryParameters['forestPhotoFilepath.notEquals'] = requestParameters.forestPhotoFilepathNotEquals;
        }

        if (requestParameters.forestPhotoFilepathSpecified !== undefined) {
            queryParameters['forestPhotoFilepath.specified'] = requestParameters.forestPhotoFilepathSpecified;
        }

        if (requestParameters.forestPhotoFilepathIn) {
            queryParameters['forestPhotoFilepath.in'] = requestParameters.forestPhotoFilepathIn;
        }

        if (requestParameters.forestPhotoFilepathNotIn) {
            queryParameters['forestPhotoFilepath.notIn'] = requestParameters.forestPhotoFilepathNotIn;
        }

        if (requestParameters.forestPhotoUrlContains !== undefined) {
            queryParameters['forestPhotoUrl.contains'] = requestParameters.forestPhotoUrlContains;
        }

        if (requestParameters.forestPhotoUrlDoesNotContain !== undefined) {
            queryParameters['forestPhotoUrl.doesNotContain'] = requestParameters.forestPhotoUrlDoesNotContain;
        }

        if (requestParameters.forestPhotoUrlEquals !== undefined) {
            queryParameters['forestPhotoUrl.equals'] = requestParameters.forestPhotoUrlEquals;
        }

        if (requestParameters.forestPhotoUrlNotEquals !== undefined) {
            queryParameters['forestPhotoUrl.notEquals'] = requestParameters.forestPhotoUrlNotEquals;
        }

        if (requestParameters.forestPhotoUrlSpecified !== undefined) {
            queryParameters['forestPhotoUrl.specified'] = requestParameters.forestPhotoUrlSpecified;
        }

        if (requestParameters.forestPhotoUrlIn) {
            queryParameters['forestPhotoUrl.in'] = requestParameters.forestPhotoUrlIn;
        }

        if (requestParameters.forestPhotoUrlNotIn) {
            queryParameters['forestPhotoUrl.notIn'] = requestParameters.forestPhotoUrlNotIn;
        }

        if (requestParameters.forestPhotoMd5Contains !== undefined) {
            queryParameters['forestPhotoMd5.contains'] = requestParameters.forestPhotoMd5Contains;
        }

        if (requestParameters.forestPhotoMd5DoesNotContain !== undefined) {
            queryParameters['forestPhotoMd5.doesNotContain'] = requestParameters.forestPhotoMd5DoesNotContain;
        }

        if (requestParameters.forestPhotoMd5Equals !== undefined) {
            queryParameters['forestPhotoMd5.equals'] = requestParameters.forestPhotoMd5Equals;
        }

        if (requestParameters.forestPhotoMd5NotEquals !== undefined) {
            queryParameters['forestPhotoMd5.notEquals'] = requestParameters.forestPhotoMd5NotEquals;
        }

        if (requestParameters.forestPhotoMd5Specified !== undefined) {
            queryParameters['forestPhotoMd5.specified'] = requestParameters.forestPhotoMd5Specified;
        }

        if (requestParameters.forestPhotoMd5In) {
            queryParameters['forestPhotoMd5.in'] = requestParameters.forestPhotoMd5In;
        }

        if (requestParameters.forestPhotoMd5NotIn) {
            queryParameters['forestPhotoMd5.notIn'] = requestParameters.forestPhotoMd5NotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.loggingIdGreaterThan !== undefined) {
            queryParameters['loggingId.greaterThan'] = requestParameters.loggingIdGreaterThan;
        }

        if (requestParameters.loggingIdLessThan !== undefined) {
            queryParameters['loggingId.lessThan'] = requestParameters.loggingIdLessThan;
        }

        if (requestParameters.loggingIdGreaterThanOrEqual !== undefined) {
            queryParameters['loggingId.greaterThanOrEqual'] = requestParameters.loggingIdGreaterThanOrEqual;
        }

        if (requestParameters.loggingIdLessThanOrEqual !== undefined) {
            queryParameters['loggingId.lessThanOrEqual'] = requestParameters.loggingIdLessThanOrEqual;
        }

        if (requestParameters.loggingIdEquals !== undefined) {
            queryParameters['loggingId.equals'] = requestParameters.loggingIdEquals;
        }

        if (requestParameters.loggingIdNotEquals !== undefined) {
            queryParameters['loggingId.notEquals'] = requestParameters.loggingIdNotEquals;
        }

        if (requestParameters.loggingIdSpecified !== undefined) {
            queryParameters['loggingId.specified'] = requestParameters.loggingIdSpecified;
        }

        if (requestParameters.loggingIdIn) {
            queryParameters['loggingId.in'] = requestParameters.loggingIdIn;
        }

        if (requestParameters.loggingIdNotIn) {
            queryParameters['loggingId.notIn'] = requestParameters.loggingIdNotIn;
        }

        if (requestParameters.plotIdGreaterThan !== undefined) {
            queryParameters['plotId.greaterThan'] = requestParameters.plotIdGreaterThan;
        }

        if (requestParameters.plotIdLessThan !== undefined) {
            queryParameters['plotId.lessThan'] = requestParameters.plotIdLessThan;
        }

        if (requestParameters.plotIdGreaterThanOrEqual !== undefined) {
            queryParameters['plotId.greaterThanOrEqual'] = requestParameters.plotIdGreaterThanOrEqual;
        }

        if (requestParameters.plotIdLessThanOrEqual !== undefined) {
            queryParameters['plotId.lessThanOrEqual'] = requestParameters.plotIdLessThanOrEqual;
        }

        if (requestParameters.plotIdEquals !== undefined) {
            queryParameters['plotId.equals'] = requestParameters.plotIdEquals;
        }

        if (requestParameters.plotIdNotEquals !== undefined) {
            queryParameters['plotId.notEquals'] = requestParameters.plotIdNotEquals;
        }

        if (requestParameters.plotIdSpecified !== undefined) {
            queryParameters['plotId.specified'] = requestParameters.plotIdSpecified;
        }

        if (requestParameters.plotIdIn) {
            queryParameters['plotId.in'] = requestParameters.plotIdIn;
        }

        if (requestParameters.plotIdNotIn) {
            queryParameters['plotId.notIn'] = requestParameters.plotIdNotIn;
        }

        if (requestParameters.forestPhotoAuthorIdGreaterThan !== undefined) {
            queryParameters['forestPhotoAuthorId.greaterThan'] = requestParameters.forestPhotoAuthorIdGreaterThan;
        }

        if (requestParameters.forestPhotoAuthorIdLessThan !== undefined) {
            queryParameters['forestPhotoAuthorId.lessThan'] = requestParameters.forestPhotoAuthorIdLessThan;
        }

        if (requestParameters.forestPhotoAuthorIdGreaterThanOrEqual !== undefined) {
            queryParameters['forestPhotoAuthorId.greaterThanOrEqual'] = requestParameters.forestPhotoAuthorIdGreaterThanOrEqual;
        }

        if (requestParameters.forestPhotoAuthorIdLessThanOrEqual !== undefined) {
            queryParameters['forestPhotoAuthorId.lessThanOrEqual'] = requestParameters.forestPhotoAuthorIdLessThanOrEqual;
        }

        if (requestParameters.forestPhotoAuthorIdEquals !== undefined) {
            queryParameters['forestPhotoAuthorId.equals'] = requestParameters.forestPhotoAuthorIdEquals;
        }

        if (requestParameters.forestPhotoAuthorIdNotEquals !== undefined) {
            queryParameters['forestPhotoAuthorId.notEquals'] = requestParameters.forestPhotoAuthorIdNotEquals;
        }

        if (requestParameters.forestPhotoAuthorIdSpecified !== undefined) {
            queryParameters['forestPhotoAuthorId.specified'] = requestParameters.forestPhotoAuthorIdSpecified;
        }

        if (requestParameters.forestPhotoAuthorIdIn) {
            queryParameters['forestPhotoAuthorId.in'] = requestParameters.forestPhotoAuthorIdIn;
        }

        if (requestParameters.forestPhotoAuthorIdNotIn) {
            queryParameters['forestPhotoAuthorId.notIn'] = requestParameters.forestPhotoAuthorIdNotIn;
        }

        if (requestParameters.treeStateIdGreaterThan !== undefined) {
            queryParameters['treeStateId.greaterThan'] = requestParameters.treeStateIdGreaterThan;
        }

        if (requestParameters.treeStateIdLessThan !== undefined) {
            queryParameters['treeStateId.lessThan'] = requestParameters.treeStateIdLessThan;
        }

        if (requestParameters.treeStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['treeStateId.greaterThanOrEqual'] = requestParameters.treeStateIdGreaterThanOrEqual;
        }

        if (requestParameters.treeStateIdLessThanOrEqual !== undefined) {
            queryParameters['treeStateId.lessThanOrEqual'] = requestParameters.treeStateIdLessThanOrEqual;
        }

        if (requestParameters.treeStateIdEquals !== undefined) {
            queryParameters['treeStateId.equals'] = requestParameters.treeStateIdEquals;
        }

        if (requestParameters.treeStateIdNotEquals !== undefined) {
            queryParameters['treeStateId.notEquals'] = requestParameters.treeStateIdNotEquals;
        }

        if (requestParameters.treeStateIdSpecified !== undefined) {
            queryParameters['treeStateId.specified'] = requestParameters.treeStateIdSpecified;
        }

        if (requestParameters.treeStateIdIn) {
            queryParameters['treeStateId.in'] = requestParameters.treeStateIdIn;
        }

        if (requestParameters.treeStateIdNotIn) {
            queryParameters['treeStateId.notIn'] = requestParameters.treeStateIdNotIn;
        }

        if (requestParameters.trunkIdGreaterThan !== undefined) {
            queryParameters['trunkId.greaterThan'] = requestParameters.trunkIdGreaterThan;
        }

        if (requestParameters.trunkIdLessThan !== undefined) {
            queryParameters['trunkId.lessThan'] = requestParameters.trunkIdLessThan;
        }

        if (requestParameters.trunkIdGreaterThanOrEqual !== undefined) {
            queryParameters['trunkId.greaterThanOrEqual'] = requestParameters.trunkIdGreaterThanOrEqual;
        }

        if (requestParameters.trunkIdLessThanOrEqual !== undefined) {
            queryParameters['trunkId.lessThanOrEqual'] = requestParameters.trunkIdLessThanOrEqual;
        }

        if (requestParameters.trunkIdEquals !== undefined) {
            queryParameters['trunkId.equals'] = requestParameters.trunkIdEquals;
        }

        if (requestParameters.trunkIdNotEquals !== undefined) {
            queryParameters['trunkId.notEquals'] = requestParameters.trunkIdNotEquals;
        }

        if (requestParameters.trunkIdSpecified !== undefined) {
            queryParameters['trunkId.specified'] = requestParameters.trunkIdSpecified;
        }

        if (requestParameters.trunkIdIn) {
            queryParameters['trunkId.in'] = requestParameters.trunkIdIn;
        }

        if (requestParameters.trunkIdNotIn) {
            queryParameters['trunkId.notIn'] = requestParameters.trunkIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trees/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countTrees(requestParameters: CountTreesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countTreesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createTreeRaw(requestParameters: CreateTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tree>> {
        if (requestParameters.tree === null || requestParameters.tree === undefined) {
            throw new runtime.RequiredError('tree','Required parameter requestParameters.tree was null or undefined when calling createTree.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TreeToJSON(requestParameters.tree),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeFromJSON(jsonValue));
    }

    /**
     */
    async createTree(requestParameters: CreateTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tree> {
        const response = await this.createTreeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTreeRaw(requestParameters: DeleteTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTree.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTree(requestParameters: DeleteTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTreeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllTreesRaw(requestParameters: GetAllTreesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Tree>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.photoNumberContains !== undefined) {
            queryParameters['photoNumber.contains'] = requestParameters.photoNumberContains;
        }

        if (requestParameters.photoNumberDoesNotContain !== undefined) {
            queryParameters['photoNumber.doesNotContain'] = requestParameters.photoNumberDoesNotContain;
        }

        if (requestParameters.photoNumberEquals !== undefined) {
            queryParameters['photoNumber.equals'] = requestParameters.photoNumberEquals;
        }

        if (requestParameters.photoNumberNotEquals !== undefined) {
            queryParameters['photoNumber.notEquals'] = requestParameters.photoNumberNotEquals;
        }

        if (requestParameters.photoNumberSpecified !== undefined) {
            queryParameters['photoNumber.specified'] = requestParameters.photoNumberSpecified;
        }

        if (requestParameters.photoNumberIn) {
            queryParameters['photoNumber.in'] = requestParameters.photoNumberIn;
        }

        if (requestParameters.photoNumberNotIn) {
            queryParameters['photoNumber.notIn'] = requestParameters.photoNumberNotIn;
        }

        if (requestParameters.multipleCounterGreaterThan !== undefined) {
            queryParameters['multipleCounter.greaterThan'] = requestParameters.multipleCounterGreaterThan;
        }

        if (requestParameters.multipleCounterLessThan !== undefined) {
            queryParameters['multipleCounter.lessThan'] = requestParameters.multipleCounterLessThan;
        }

        if (requestParameters.multipleCounterGreaterThanOrEqual !== undefined) {
            queryParameters['multipleCounter.greaterThanOrEqual'] = requestParameters.multipleCounterGreaterThanOrEqual;
        }

        if (requestParameters.multipleCounterLessThanOrEqual !== undefined) {
            queryParameters['multipleCounter.lessThanOrEqual'] = requestParameters.multipleCounterLessThanOrEqual;
        }

        if (requestParameters.multipleCounterEquals !== undefined) {
            queryParameters['multipleCounter.equals'] = requestParameters.multipleCounterEquals;
        }

        if (requestParameters.multipleCounterNotEquals !== undefined) {
            queryParameters['multipleCounter.notEquals'] = requestParameters.multipleCounterNotEquals;
        }

        if (requestParameters.multipleCounterSpecified !== undefined) {
            queryParameters['multipleCounter.specified'] = requestParameters.multipleCounterSpecified;
        }

        if (requestParameters.multipleCounterIn) {
            queryParameters['multipleCounter.in'] = requestParameters.multipleCounterIn;
        }

        if (requestParameters.multipleCounterNotIn) {
            queryParameters['multipleCounter.notIn'] = requestParameters.multipleCounterNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.speciesEquals !== undefined) {
            queryParameters['species.equals'] = requestParameters.speciesEquals;
        }

        if (requestParameters.speciesNotEquals !== undefined) {
            queryParameters['species.notEquals'] = requestParameters.speciesNotEquals;
        }

        if (requestParameters.speciesSpecified !== undefined) {
            queryParameters['species.specified'] = requestParameters.speciesSpecified;
        }

        if (requestParameters.speciesIn) {
            queryParameters['species.in'] = requestParameters.speciesIn;
        }

        if (requestParameters.speciesNotIn) {
            queryParameters['species.notIn'] = requestParameters.speciesNotIn;
        }

        if (requestParameters.recordingTypeEquals !== undefined) {
            queryParameters['recordingType.equals'] = requestParameters.recordingTypeEquals;
        }

        if (requestParameters.recordingTypeNotEquals !== undefined) {
            queryParameters['recordingType.notEquals'] = requestParameters.recordingTypeNotEquals;
        }

        if (requestParameters.recordingTypeSpecified !== undefined) {
            queryParameters['recordingType.specified'] = requestParameters.recordingTypeSpecified;
        }

        if (requestParameters.recordingTypeIn) {
            queryParameters['recordingType.in'] = requestParameters.recordingTypeIn;
        }

        if (requestParameters.recordingTypeNotIn) {
            queryParameters['recordingType.notIn'] = requestParameters.recordingTypeNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.latitudeGreaterThan !== undefined) {
            queryParameters['latitude.greaterThan'] = requestParameters.latitudeGreaterThan;
        }

        if (requestParameters.latitudeLessThan !== undefined) {
            queryParameters['latitude.lessThan'] = requestParameters.latitudeLessThan;
        }

        if (requestParameters.latitudeGreaterThanOrEqual !== undefined) {
            queryParameters['latitude.greaterThanOrEqual'] = requestParameters.latitudeGreaterThanOrEqual;
        }

        if (requestParameters.latitudeLessThanOrEqual !== undefined) {
            queryParameters['latitude.lessThanOrEqual'] = requestParameters.latitudeLessThanOrEqual;
        }

        if (requestParameters.latitudeEquals !== undefined) {
            queryParameters['latitude.equals'] = requestParameters.latitudeEquals;
        }

        if (requestParameters.latitudeNotEquals !== undefined) {
            queryParameters['latitude.notEquals'] = requestParameters.latitudeNotEquals;
        }

        if (requestParameters.latitudeSpecified !== undefined) {
            queryParameters['latitude.specified'] = requestParameters.latitudeSpecified;
        }

        if (requestParameters.latitudeIn) {
            queryParameters['latitude.in'] = requestParameters.latitudeIn;
        }

        if (requestParameters.latitudeNotIn) {
            queryParameters['latitude.notIn'] = requestParameters.latitudeNotIn;
        }

        if (requestParameters.longitudeGreaterThan !== undefined) {
            queryParameters['longitude.greaterThan'] = requestParameters.longitudeGreaterThan;
        }

        if (requestParameters.longitudeLessThan !== undefined) {
            queryParameters['longitude.lessThan'] = requestParameters.longitudeLessThan;
        }

        if (requestParameters.longitudeGreaterThanOrEqual !== undefined) {
            queryParameters['longitude.greaterThanOrEqual'] = requestParameters.longitudeGreaterThanOrEqual;
        }

        if (requestParameters.longitudeLessThanOrEqual !== undefined) {
            queryParameters['longitude.lessThanOrEqual'] = requestParameters.longitudeLessThanOrEqual;
        }

        if (requestParameters.longitudeEquals !== undefined) {
            queryParameters['longitude.equals'] = requestParameters.longitudeEquals;
        }

        if (requestParameters.longitudeNotEquals !== undefined) {
            queryParameters['longitude.notEquals'] = requestParameters.longitudeNotEquals;
        }

        if (requestParameters.longitudeSpecified !== undefined) {
            queryParameters['longitude.specified'] = requestParameters.longitudeSpecified;
        }

        if (requestParameters.longitudeIn) {
            queryParameters['longitude.in'] = requestParameters.longitudeIn;
        }

        if (requestParameters.longitudeNotIn) {
            queryParameters['longitude.notIn'] = requestParameters.longitudeNotIn;
        }

        if (requestParameters.forestPhotoFilenameContains !== undefined) {
            queryParameters['forestPhotoFilename.contains'] = requestParameters.forestPhotoFilenameContains;
        }

        if (requestParameters.forestPhotoFilenameDoesNotContain !== undefined) {
            queryParameters['forestPhotoFilename.doesNotContain'] = requestParameters.forestPhotoFilenameDoesNotContain;
        }

        if (requestParameters.forestPhotoFilenameEquals !== undefined) {
            queryParameters['forestPhotoFilename.equals'] = requestParameters.forestPhotoFilenameEquals;
        }

        if (requestParameters.forestPhotoFilenameNotEquals !== undefined) {
            queryParameters['forestPhotoFilename.notEquals'] = requestParameters.forestPhotoFilenameNotEquals;
        }

        if (requestParameters.forestPhotoFilenameSpecified !== undefined) {
            queryParameters['forestPhotoFilename.specified'] = requestParameters.forestPhotoFilenameSpecified;
        }

        if (requestParameters.forestPhotoFilenameIn) {
            queryParameters['forestPhotoFilename.in'] = requestParameters.forestPhotoFilenameIn;
        }

        if (requestParameters.forestPhotoFilenameNotIn) {
            queryParameters['forestPhotoFilename.notIn'] = requestParameters.forestPhotoFilenameNotIn;
        }

        if (requestParameters.forestPhotoFilepathContains !== undefined) {
            queryParameters['forestPhotoFilepath.contains'] = requestParameters.forestPhotoFilepathContains;
        }

        if (requestParameters.forestPhotoFilepathDoesNotContain !== undefined) {
            queryParameters['forestPhotoFilepath.doesNotContain'] = requestParameters.forestPhotoFilepathDoesNotContain;
        }

        if (requestParameters.forestPhotoFilepathEquals !== undefined) {
            queryParameters['forestPhotoFilepath.equals'] = requestParameters.forestPhotoFilepathEquals;
        }

        if (requestParameters.forestPhotoFilepathNotEquals !== undefined) {
            queryParameters['forestPhotoFilepath.notEquals'] = requestParameters.forestPhotoFilepathNotEquals;
        }

        if (requestParameters.forestPhotoFilepathSpecified !== undefined) {
            queryParameters['forestPhotoFilepath.specified'] = requestParameters.forestPhotoFilepathSpecified;
        }

        if (requestParameters.forestPhotoFilepathIn) {
            queryParameters['forestPhotoFilepath.in'] = requestParameters.forestPhotoFilepathIn;
        }

        if (requestParameters.forestPhotoFilepathNotIn) {
            queryParameters['forestPhotoFilepath.notIn'] = requestParameters.forestPhotoFilepathNotIn;
        }

        if (requestParameters.forestPhotoUrlContains !== undefined) {
            queryParameters['forestPhotoUrl.contains'] = requestParameters.forestPhotoUrlContains;
        }

        if (requestParameters.forestPhotoUrlDoesNotContain !== undefined) {
            queryParameters['forestPhotoUrl.doesNotContain'] = requestParameters.forestPhotoUrlDoesNotContain;
        }

        if (requestParameters.forestPhotoUrlEquals !== undefined) {
            queryParameters['forestPhotoUrl.equals'] = requestParameters.forestPhotoUrlEquals;
        }

        if (requestParameters.forestPhotoUrlNotEquals !== undefined) {
            queryParameters['forestPhotoUrl.notEquals'] = requestParameters.forestPhotoUrlNotEquals;
        }

        if (requestParameters.forestPhotoUrlSpecified !== undefined) {
            queryParameters['forestPhotoUrl.specified'] = requestParameters.forestPhotoUrlSpecified;
        }

        if (requestParameters.forestPhotoUrlIn) {
            queryParameters['forestPhotoUrl.in'] = requestParameters.forestPhotoUrlIn;
        }

        if (requestParameters.forestPhotoUrlNotIn) {
            queryParameters['forestPhotoUrl.notIn'] = requestParameters.forestPhotoUrlNotIn;
        }

        if (requestParameters.forestPhotoMd5Contains !== undefined) {
            queryParameters['forestPhotoMd5.contains'] = requestParameters.forestPhotoMd5Contains;
        }

        if (requestParameters.forestPhotoMd5DoesNotContain !== undefined) {
            queryParameters['forestPhotoMd5.doesNotContain'] = requestParameters.forestPhotoMd5DoesNotContain;
        }

        if (requestParameters.forestPhotoMd5Equals !== undefined) {
            queryParameters['forestPhotoMd5.equals'] = requestParameters.forestPhotoMd5Equals;
        }

        if (requestParameters.forestPhotoMd5NotEquals !== undefined) {
            queryParameters['forestPhotoMd5.notEquals'] = requestParameters.forestPhotoMd5NotEquals;
        }

        if (requestParameters.forestPhotoMd5Specified !== undefined) {
            queryParameters['forestPhotoMd5.specified'] = requestParameters.forestPhotoMd5Specified;
        }

        if (requestParameters.forestPhotoMd5In) {
            queryParameters['forestPhotoMd5.in'] = requestParameters.forestPhotoMd5In;
        }

        if (requestParameters.forestPhotoMd5NotIn) {
            queryParameters['forestPhotoMd5.notIn'] = requestParameters.forestPhotoMd5NotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.loggingIdGreaterThan !== undefined) {
            queryParameters['loggingId.greaterThan'] = requestParameters.loggingIdGreaterThan;
        }

        if (requestParameters.loggingIdLessThan !== undefined) {
            queryParameters['loggingId.lessThan'] = requestParameters.loggingIdLessThan;
        }

        if (requestParameters.loggingIdGreaterThanOrEqual !== undefined) {
            queryParameters['loggingId.greaterThanOrEqual'] = requestParameters.loggingIdGreaterThanOrEqual;
        }

        if (requestParameters.loggingIdLessThanOrEqual !== undefined) {
            queryParameters['loggingId.lessThanOrEqual'] = requestParameters.loggingIdLessThanOrEqual;
        }

        if (requestParameters.loggingIdEquals !== undefined) {
            queryParameters['loggingId.equals'] = requestParameters.loggingIdEquals;
        }

        if (requestParameters.loggingIdNotEquals !== undefined) {
            queryParameters['loggingId.notEquals'] = requestParameters.loggingIdNotEquals;
        }

        if (requestParameters.loggingIdSpecified !== undefined) {
            queryParameters['loggingId.specified'] = requestParameters.loggingIdSpecified;
        }

        if (requestParameters.loggingIdIn) {
            queryParameters['loggingId.in'] = requestParameters.loggingIdIn;
        }

        if (requestParameters.loggingIdNotIn) {
            queryParameters['loggingId.notIn'] = requestParameters.loggingIdNotIn;
        }

        if (requestParameters.plotIdGreaterThan !== undefined) {
            queryParameters['plotId.greaterThan'] = requestParameters.plotIdGreaterThan;
        }

        if (requestParameters.plotIdLessThan !== undefined) {
            queryParameters['plotId.lessThan'] = requestParameters.plotIdLessThan;
        }

        if (requestParameters.plotIdGreaterThanOrEqual !== undefined) {
            queryParameters['plotId.greaterThanOrEqual'] = requestParameters.plotIdGreaterThanOrEqual;
        }

        if (requestParameters.plotIdLessThanOrEqual !== undefined) {
            queryParameters['plotId.lessThanOrEqual'] = requestParameters.plotIdLessThanOrEqual;
        }

        if (requestParameters.plotIdEquals !== undefined) {
            queryParameters['plotId.equals'] = requestParameters.plotIdEquals;
        }

        if (requestParameters.plotIdNotEquals !== undefined) {
            queryParameters['plotId.notEquals'] = requestParameters.plotIdNotEquals;
        }

        if (requestParameters.plotIdSpecified !== undefined) {
            queryParameters['plotId.specified'] = requestParameters.plotIdSpecified;
        }

        if (requestParameters.plotIdIn) {
            queryParameters['plotId.in'] = requestParameters.plotIdIn;
        }

        if (requestParameters.plotIdNotIn) {
            queryParameters['plotId.notIn'] = requestParameters.plotIdNotIn;
        }

        if (requestParameters.forestPhotoAuthorIdGreaterThan !== undefined) {
            queryParameters['forestPhotoAuthorId.greaterThan'] = requestParameters.forestPhotoAuthorIdGreaterThan;
        }

        if (requestParameters.forestPhotoAuthorIdLessThan !== undefined) {
            queryParameters['forestPhotoAuthorId.lessThan'] = requestParameters.forestPhotoAuthorIdLessThan;
        }

        if (requestParameters.forestPhotoAuthorIdGreaterThanOrEqual !== undefined) {
            queryParameters['forestPhotoAuthorId.greaterThanOrEqual'] = requestParameters.forestPhotoAuthorIdGreaterThanOrEqual;
        }

        if (requestParameters.forestPhotoAuthorIdLessThanOrEqual !== undefined) {
            queryParameters['forestPhotoAuthorId.lessThanOrEqual'] = requestParameters.forestPhotoAuthorIdLessThanOrEqual;
        }

        if (requestParameters.forestPhotoAuthorIdEquals !== undefined) {
            queryParameters['forestPhotoAuthorId.equals'] = requestParameters.forestPhotoAuthorIdEquals;
        }

        if (requestParameters.forestPhotoAuthorIdNotEquals !== undefined) {
            queryParameters['forestPhotoAuthorId.notEquals'] = requestParameters.forestPhotoAuthorIdNotEquals;
        }

        if (requestParameters.forestPhotoAuthorIdSpecified !== undefined) {
            queryParameters['forestPhotoAuthorId.specified'] = requestParameters.forestPhotoAuthorIdSpecified;
        }

        if (requestParameters.forestPhotoAuthorIdIn) {
            queryParameters['forestPhotoAuthorId.in'] = requestParameters.forestPhotoAuthorIdIn;
        }

        if (requestParameters.forestPhotoAuthorIdNotIn) {
            queryParameters['forestPhotoAuthorId.notIn'] = requestParameters.forestPhotoAuthorIdNotIn;
        }

        if (requestParameters.treeStateIdGreaterThan !== undefined) {
            queryParameters['treeStateId.greaterThan'] = requestParameters.treeStateIdGreaterThan;
        }

        if (requestParameters.treeStateIdLessThan !== undefined) {
            queryParameters['treeStateId.lessThan'] = requestParameters.treeStateIdLessThan;
        }

        if (requestParameters.treeStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['treeStateId.greaterThanOrEqual'] = requestParameters.treeStateIdGreaterThanOrEqual;
        }

        if (requestParameters.treeStateIdLessThanOrEqual !== undefined) {
            queryParameters['treeStateId.lessThanOrEqual'] = requestParameters.treeStateIdLessThanOrEqual;
        }

        if (requestParameters.treeStateIdEquals !== undefined) {
            queryParameters['treeStateId.equals'] = requestParameters.treeStateIdEquals;
        }

        if (requestParameters.treeStateIdNotEquals !== undefined) {
            queryParameters['treeStateId.notEquals'] = requestParameters.treeStateIdNotEquals;
        }

        if (requestParameters.treeStateIdSpecified !== undefined) {
            queryParameters['treeStateId.specified'] = requestParameters.treeStateIdSpecified;
        }

        if (requestParameters.treeStateIdIn) {
            queryParameters['treeStateId.in'] = requestParameters.treeStateIdIn;
        }

        if (requestParameters.treeStateIdNotIn) {
            queryParameters['treeStateId.notIn'] = requestParameters.treeStateIdNotIn;
        }

        if (requestParameters.trunkIdGreaterThan !== undefined) {
            queryParameters['trunkId.greaterThan'] = requestParameters.trunkIdGreaterThan;
        }

        if (requestParameters.trunkIdLessThan !== undefined) {
            queryParameters['trunkId.lessThan'] = requestParameters.trunkIdLessThan;
        }

        if (requestParameters.trunkIdGreaterThanOrEqual !== undefined) {
            queryParameters['trunkId.greaterThanOrEqual'] = requestParameters.trunkIdGreaterThanOrEqual;
        }

        if (requestParameters.trunkIdLessThanOrEqual !== undefined) {
            queryParameters['trunkId.lessThanOrEqual'] = requestParameters.trunkIdLessThanOrEqual;
        }

        if (requestParameters.trunkIdEquals !== undefined) {
            queryParameters['trunkId.equals'] = requestParameters.trunkIdEquals;
        }

        if (requestParameters.trunkIdNotEquals !== undefined) {
            queryParameters['trunkId.notEquals'] = requestParameters.trunkIdNotEquals;
        }

        if (requestParameters.trunkIdSpecified !== undefined) {
            queryParameters['trunkId.specified'] = requestParameters.trunkIdSpecified;
        }

        if (requestParameters.trunkIdIn) {
            queryParameters['trunkId.in'] = requestParameters.trunkIdIn;
        }

        if (requestParameters.trunkIdNotIn) {
            queryParameters['trunkId.notIn'] = requestParameters.trunkIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TreeFromJSON));
    }

    /**
     */
    async getAllTrees(requestParameters: GetAllTreesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Tree>> {
        const response = await this.getAllTreesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTreeRaw(requestParameters: GetTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tree>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTree.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeFromJSON(jsonValue));
    }

    /**
     */
    async getTree(requestParameters: GetTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tree> {
        const response = await this.getTreeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateTreeRaw(requestParameters: PartialUpdateTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tree>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateTree.');
        }

        if (requestParameters.tree === null || requestParameters.tree === undefined) {
            throw new runtime.RequiredError('tree','Required parameter requestParameters.tree was null or undefined when calling partialUpdateTree.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TreeToJSON(requestParameters.tree),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateTree(requestParameters: PartialUpdateTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tree> {
        const response = await this.partialUpdateTreeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTreeRaw(requestParameters: UpdateTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tree>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTree.');
        }

        if (requestParameters.tree === null || requestParameters.tree === undefined) {
            throw new runtime.RequiredError('tree','Required parameter requestParameters.tree was null or undefined when calling updateTree.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TreeToJSON(requestParameters.tree),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeFromJSON(jsonValue));
    }

    /**
     */
    async updateTree(requestParameters: UpdateTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tree> {
        const response = await this.updateTreeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CountTreesSpeciesEqualsEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type CountTreesSpeciesEqualsEnum = typeof CountTreesSpeciesEqualsEnum[keyof typeof CountTreesSpeciesEqualsEnum];
/**
 * @export
 */
export const CountTreesSpeciesNotEqualsEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type CountTreesSpeciesNotEqualsEnum = typeof CountTreesSpeciesNotEqualsEnum[keyof typeof CountTreesSpeciesNotEqualsEnum];
/**
 * @export
 */
export const CountTreesSpeciesInEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type CountTreesSpeciesInEnum = typeof CountTreesSpeciesInEnum[keyof typeof CountTreesSpeciesInEnum];
/**
 * @export
 */
export const CountTreesSpeciesNotInEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type CountTreesSpeciesNotInEnum = typeof CountTreesSpeciesNotInEnum[keyof typeof CountTreesSpeciesNotInEnum];
/**
 * @export
 */
export const CountTreesRecordingTypeEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountTreesRecordingTypeEqualsEnum = typeof CountTreesRecordingTypeEqualsEnum[keyof typeof CountTreesRecordingTypeEqualsEnum];
/**
 * @export
 */
export const CountTreesRecordingTypeNotEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountTreesRecordingTypeNotEqualsEnum = typeof CountTreesRecordingTypeNotEqualsEnum[keyof typeof CountTreesRecordingTypeNotEqualsEnum];
/**
 * @export
 */
export const CountTreesRecordingTypeInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountTreesRecordingTypeInEnum = typeof CountTreesRecordingTypeInEnum[keyof typeof CountTreesRecordingTypeInEnum];
/**
 * @export
 */
export const CountTreesRecordingTypeNotInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountTreesRecordingTypeNotInEnum = typeof CountTreesRecordingTypeNotInEnum[keyof typeof CountTreesRecordingTypeNotInEnum];
/**
 * @export
 */
export const CountTreesCurrentStateEqualsEnum = {
    Standing: 'STANDING',
    Cut: 'CUT',
    MovedToSawmill: 'MOVED_TO_SAWMILL'
} as const;
export type CountTreesCurrentStateEqualsEnum = typeof CountTreesCurrentStateEqualsEnum[keyof typeof CountTreesCurrentStateEqualsEnum];
/**
 * @export
 */
export const CountTreesCurrentStateNotEqualsEnum = {
    Standing: 'STANDING',
    Cut: 'CUT',
    MovedToSawmill: 'MOVED_TO_SAWMILL'
} as const;
export type CountTreesCurrentStateNotEqualsEnum = typeof CountTreesCurrentStateNotEqualsEnum[keyof typeof CountTreesCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const CountTreesCurrentStateInEnum = {
    Standing: 'STANDING',
    Cut: 'CUT',
    MovedToSawmill: 'MOVED_TO_SAWMILL'
} as const;
export type CountTreesCurrentStateInEnum = typeof CountTreesCurrentStateInEnum[keyof typeof CountTreesCurrentStateInEnum];
/**
 * @export
 */
export const CountTreesCurrentStateNotInEnum = {
    Standing: 'STANDING',
    Cut: 'CUT',
    MovedToSawmill: 'MOVED_TO_SAWMILL'
} as const;
export type CountTreesCurrentStateNotInEnum = typeof CountTreesCurrentStateNotInEnum[keyof typeof CountTreesCurrentStateNotInEnum];
/**
 * @export
 */
export const GetAllTreesSpeciesEqualsEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type GetAllTreesSpeciesEqualsEnum = typeof GetAllTreesSpeciesEqualsEnum[keyof typeof GetAllTreesSpeciesEqualsEnum];
/**
 * @export
 */
export const GetAllTreesSpeciesNotEqualsEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type GetAllTreesSpeciesNotEqualsEnum = typeof GetAllTreesSpeciesNotEqualsEnum[keyof typeof GetAllTreesSpeciesNotEqualsEnum];
/**
 * @export
 */
export const GetAllTreesSpeciesInEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type GetAllTreesSpeciesInEnum = typeof GetAllTreesSpeciesInEnum[keyof typeof GetAllTreesSpeciesInEnum];
/**
 * @export
 */
export const GetAllTreesSpeciesNotInEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type GetAllTreesSpeciesNotInEnum = typeof GetAllTreesSpeciesNotInEnum[keyof typeof GetAllTreesSpeciesNotInEnum];
/**
 * @export
 */
export const GetAllTreesRecordingTypeEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllTreesRecordingTypeEqualsEnum = typeof GetAllTreesRecordingTypeEqualsEnum[keyof typeof GetAllTreesRecordingTypeEqualsEnum];
/**
 * @export
 */
export const GetAllTreesRecordingTypeNotEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllTreesRecordingTypeNotEqualsEnum = typeof GetAllTreesRecordingTypeNotEqualsEnum[keyof typeof GetAllTreesRecordingTypeNotEqualsEnum];
/**
 * @export
 */
export const GetAllTreesRecordingTypeInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllTreesRecordingTypeInEnum = typeof GetAllTreesRecordingTypeInEnum[keyof typeof GetAllTreesRecordingTypeInEnum];
/**
 * @export
 */
export const GetAllTreesRecordingTypeNotInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllTreesRecordingTypeNotInEnum = typeof GetAllTreesRecordingTypeNotInEnum[keyof typeof GetAllTreesRecordingTypeNotInEnum];
/**
 * @export
 */
export const GetAllTreesCurrentStateEqualsEnum = {
    Standing: 'STANDING',
    Cut: 'CUT',
    MovedToSawmill: 'MOVED_TO_SAWMILL'
} as const;
export type GetAllTreesCurrentStateEqualsEnum = typeof GetAllTreesCurrentStateEqualsEnum[keyof typeof GetAllTreesCurrentStateEqualsEnum];
/**
 * @export
 */
export const GetAllTreesCurrentStateNotEqualsEnum = {
    Standing: 'STANDING',
    Cut: 'CUT',
    MovedToSawmill: 'MOVED_TO_SAWMILL'
} as const;
export type GetAllTreesCurrentStateNotEqualsEnum = typeof GetAllTreesCurrentStateNotEqualsEnum[keyof typeof GetAllTreesCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const GetAllTreesCurrentStateInEnum = {
    Standing: 'STANDING',
    Cut: 'CUT',
    MovedToSawmill: 'MOVED_TO_SAWMILL'
} as const;
export type GetAllTreesCurrentStateInEnum = typeof GetAllTreesCurrentStateInEnum[keyof typeof GetAllTreesCurrentStateInEnum];
/**
 * @export
 */
export const GetAllTreesCurrentStateNotInEnum = {
    Standing: 'STANDING',
    Cut: 'CUT',
    MovedToSawmill: 'MOVED_TO_SAWMILL'
} as const;
export type GetAllTreesCurrentStateNotInEnum = typeof GetAllTreesCurrentStateNotInEnum[keyof typeof GetAllTreesCurrentStateNotInEnum];
