import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import { i18n } from '@i18n/i18n';
import { RootState } from '@redux/store';
import { Logging, Plot } from '@services/apis/generated';
import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import { mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammStyle, UrstammStyleContainer, UrstammStyleLayout } from '../../../utils/styles/UrstammStyle';

export default function PlotRegistrationView(props: {
  navigation: any;
  submitPlotForm: (form: any) => void;
  loggingSelected: Logging;
}) {
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);

  const [plotForm, setPlotForm] = useStateWithCallbackLazy<Plot>({
    name: '',
    comment: '',
    egrid: ''
  });

  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  //Validators
  const validators = useState<any>({
    name: { required: true }
    //egrid: { numeric: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: []
    //egrid: []
  });

  const validate = (value: any, key: string, updatedPlotForm: Plot) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedPlotForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedPlotForm: Plot) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated) || updatedPlotForm.name.length == 0) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** Plot Name */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="plot_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => {
              setPlotForm({ ...plotForm, name: text }, updatedPlotForm => validate(text, 'name', updatedPlotForm));
            }}
            placeholder={i18n.t('views.plot.insert_plot_name_here')}
            textInputTitle={i18n.t('views.plot.plot_name')}
            mandatory={true}
            multiline={false}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="plot_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => setPlotForm({ ...plotForm, comment: text }, () => {})}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            multiline={true}
          />
        </View>

        {/** Plot egrid */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="plot_egrid"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => {
              setPlotForm({ ...plotForm, egrid: text.toString() }, () => {});
            }}
            value={'CH' + plotForm.egrid!.substring(2)}
            placeholder={i18n.t('views.plot.insert_plot_egrid_here')}
            textInputTitle={i18n.t('views.plot.egrid_n')}
            mandatory={false}
            multiline={false}
            keyboardType={'numeric'}
            maxLength={14}
          />

          {/**Validator */}
          {errors['egrid'] && errors['egrid'].length > 0
            ? errors['egrid'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/**Save plot */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'create_plot'}
            text={i18n.t('views.plot.add_plot')}
            style={mainGreenButton}
            onSubmit={() => props.submitPlotForm(plotForm)}
            disabled={saveDisabled || rdxLoaderStatus}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});

function setErrors(arg0: (state: any) => any) {
  throw new Error('Function not implemented.');
}
