export class GenericHelper {
  /**
   * Simple delay with promise
   * @param {number} ms Milliseconds to wait
   * @returns {Promise}
   */
  public static delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
