/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyToJSON
} from './Company';
import { DeliverySheetMeasurements } from './DeliverySheet';
import type { DeliverySheetPhoto } from './DeliverySheetPhoto';
import {
    DeliverySheetFactoryMeasurementFromJSON,
    DeliverySheetPhotoFromJSON,
    DeliverySheetPhotoToJSON
} from './DeliverySheetPhoto';
import type { Logging } from './Logging';
import {
    LoggingFromJSON,
    LoggingToJSON
} from './Logging';
import type { Pile } from './Pile';
import {
    PileFromJSON,
    PileToJSON
} from './Pile';
import type { Trunk } from './Trunk';
import {
    TrunkFromJSON,
    TrunkToJSON
} from './Trunk';

/**
 * 
 * @export
 * @interface DeliverySheetDTO
 */
export interface DeliverySheetDTO {
    deliverySheetFactoryMeasurement?: DeliverySheetMeasurements;
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetDTO
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof DeliverySheetDTO
     */
    creationDate?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetDTO
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetDTO
     */
    currentState?: DeliverySheetDTOCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetDTO
     */
    currentUrstammState?: DeliverySheetDTOCurrentUrstammStateEnum;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetDTO
     */
    recordingType?: DeliverySheetDTORecordingTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DeliverySheetDTO
     */
    sentToSawmill?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetDTO
     */
    uuid?: string;
    /**
     * 
     * @type {Pile}
     * @memberof DeliverySheetDTO
     */
    pile?: Pile;
    /**
     * 
     * @type {Logging}
     * @memberof DeliverySheetDTO
     */
    logging?: Logging;
    /**
     * 
     * @type {Company}
     * @memberof DeliverySheetDTO
     */
    forestry?: Company;
    /**
     * 
     * @type {Company}
     * @memberof DeliverySheetDTO
     */
    sawmill?: Company;
    /**
     * 
     * @type {boolean}
     * @memberof DeliverySheetDTO
     */
    automaticallyCreated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetDTO
     */
    amountOfTruckJourneys?: number;
    /**
     * 
     * @type {Array<Trunk>}
     * @memberof DeliverySheetDTO
     */
    trunks?: Array<Trunk>;
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetDTO
     */
    numberOfTrunks?: number;
    /**
     * 
     * @type {Array<DeliverySheetPhoto>}
     * @memberof DeliverySheetDTO
     */
    deliverySheetPhotos?: Array<DeliverySheetPhoto>;
}


/**
 * @export
 */
export const DeliverySheetDTOCurrentStateEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetDTOCurrentStateEnum = typeof DeliverySheetDTOCurrentStateEnum[keyof typeof DeliverySheetDTOCurrentStateEnum];

/**
 * @export
 */
export const DeliverySheetDTOCurrentUrstammStateEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetDTOCurrentUrstammStateEnum = typeof DeliverySheetDTOCurrentUrstammStateEnum[keyof typeof DeliverySheetDTOCurrentUrstammStateEnum];

/**
 * @export
 */
export const DeliverySheetDTORecordingTypeEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE',
    Date: 'DATE',
} as const;
export type DeliverySheetDTORecordingTypeEnum = typeof DeliverySheetDTORecordingTypeEnum[keyof typeof DeliverySheetDTORecordingTypeEnum];


/**
 * Check if a given object implements the DeliverySheetDTO interface.
 */
export function instanceOfDeliverySheetDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeliverySheetDTOFromJSON(json: any): DeliverySheetDTO {
    return DeliverySheetDTOFromJSONTyped(json, false);
}

export function DeliverySheetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliverySheetDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'numberOfTrunks': !exists(json, 'numberOfTrunks') ? undefined : json['numberOfTrunks'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'currentUrstammState': !exists(json, 'currentUrstammState') ? undefined : json['currentUrstammState'],
        'recordingType': !exists(json, 'recordingType') ? undefined : json['recordingType'],
        'sentToSawmill': !exists(json, 'sentToSawmill') ? undefined : json['sentToSawmill'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'pile': !exists(json, 'pile') ? undefined : PileFromJSON(json['pile']),
        'logging': !exists(json, 'logging') ? undefined : LoggingFromJSON(json['logging']),
        'forestry': !exists(json, 'forestry') ? undefined : CompanyFromJSON(json['forestry']),
        'sawmill': !exists(json, 'sawmill') ? undefined : CompanyFromJSON(json['sawmill']),
        'automaticallyCreated': !exists(json, 'automaticallyCreated') ? undefined : json['automaticallyCreated'],
        'amountOfTruckJourneys': !exists(json, 'amountOfTruckJourneys') ? undefined : json['amountOfTruckJourneys'],
        'trunks': !exists(json, 'trunks') ? undefined : ((json['trunks'] as Array<any>).map(TrunkFromJSON)),
        'deliverySheetPhotos': !exists(json, 'deliverySheetPhotos') ? undefined : ((json['deliverySheetPhotos'] as Array<any>).map(DeliverySheetPhotoFromJSON)),
        'deliverySheetFactoryMeasurement': !exists(json, 'deliverySheetFactoryMeasurement') ? undefined : DeliverySheetFactoryMeasurementFromJSON(json['deliverySheetFactoryMeasurement'])
    };
}

export function DeliverySheetDTOToJSON(value?: DeliverySheetDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'sumCubage': value.sumCubage,
        'currentState': value.currentState,
        'currentUrstammState': value.currentUrstammState,
        'recordingType': value.recordingType,
        'sentToSawmill': value.sentToSawmill,
        'uuid': value.uuid,
        'pile': PileToJSON(value.pile),
        'logging': LoggingToJSON(value.logging),
        'forestry': CompanyToJSON(value.forestry),
        'sawmill': CompanyToJSON(value.sawmill),
        'automaticallyCreated': value.automaticallyCreated,
        'amountOfTruckJourneys': value.amountOfTruckJourneys,
        'trunks': value.trunks === undefined ? undefined : ((value.trunks as Array<any>).map(TrunkToJSON)),
        'deliverySheetPhotos': value.deliverySheetPhotos === undefined ? undefined : ((value.deliverySheetPhotos as Array<any>).map(DeliverySheetPhotoToJSON)),
    };
}

