import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSortList from '@components/utility-components/modal/UrstammModalSortList';
import { i18n } from '@i18n/i18n';
import { Logging, LoggingCurrentStateEnum, LoggingDTO } from '@services/apis/generated';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilter, setFilterList } from '../../../redux/features/filter/loggingFilterSlice';
import { RootState } from '../../../redux/store';
import {
  getColorByLoggingState,
  getIconByLoggingState,
  getTextByLoggingState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { UrstammStyle, UrstammStyleList } from '../../../utils/styles/UrstammStyle';

export default function LoggingListView(props: {
  navigation: any;
  loggingList: LoggingDTO[];
  moreItems: () => void;
  navigateToLoggingDetails: (loggingSelected: Logging) => void;
  showSortBy: boolean;
  refresh: () => void;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
}) {
  const loggingFilter = useSelector((state: RootState) => state.persistedReducer.loggingFilter.value);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.logging.sortBy);
  const dispatch = useDispatch();

  let keys: ListSubData[] = [
    {
      title: undefined,
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'currentState',
      keyId: '1currentState'
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.estimated_logging_volume') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'estimatedVolume',
      keyId: '1estimatedVolume'
    },
    {
      title: i18n.t('generics.total_wood') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumWoodTotal',
      keyId: '1sumWoodTotal',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    }
    //{ title: i18n.t('generics.trunks') + ':', titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'trunks', keyId: '1trunks', isEnum: true },
  ];

  useEffect(() => {
    getSlideButtonOptionsLoggingCurrentStates();
    return () => {};
  }, []);

  const getSlideButtonOptionsLoggingCurrentStates = (): SlideButtonOption[] => {
    let filterList: SlideButtonOption[] = [];
    let type: SlideButtonOption = {
      id: 'ALL',
      text: i18n.t('utility_components.filter_button.all'),
      active: true
    };
    filterList.push(type);
    Object.values(LoggingCurrentStateEnum).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByLoggingState(state),
        active: false
      };
      filterList.push(type);
    });
    dispatch(setFilterList(filterList));

    return filterList;
  };

  const setLoggingCurrentState = (selected: SlideButtonOption): void => {
    dispatch(selectFilter(selected));
  };

  const getActiveLoggingCurrentState = (): SlideButtonOption[] => {
    return loggingFilter.filter(val => val.active);
  };

  return (
    <View style={[UrstammStyle.flex1]}>
      {props.loggingList && props.loggingList.length > 0 ? (
        <View style={[{ flex: 0.15 }]}>
          <UrstammSlideButton
            testID={'button_status_filter'}
            slideButtonList={loggingFilter}
            slideButtonRotatedTitle={i18n.t('utility_components.filter_button.status')}
            buttonPressed={(selected: SlideButtonOption) => setLoggingCurrentState(selected)}
          />
        </View>
      ) : null}

      <View style={[{ flex: 0.85 }]}>
        <UrstammList
          elementSelected={(loggingSelected: Logging) => props.navigateToLoggingDetails(loggingSelected)}
          testID={'logging_list'}
          textForEmptyList={i18n.t('views.logging.loggings_not_found')}
          list={props.loggingList}
          listStyle={{
            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
            bottomContainerViewStyle: UrstammStyleList.cardSubData,
            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
          }}
          refresh={props.refresh}
          listSubData={keys}
          filterBy={getActiveLoggingCurrentState()}
          moreItems={props.moreItems}
          filterList={true}
          currentState={{
            icon: state => getIconByLoggingState(state),
            color: state => getColorByLoggingState(state),
            text: state => getTextByLoggingState(state)
          }}
          showHash={false}
        />

        {props.showSortBy ? (
          <UrstammModalSortList
            testID={'modal_sort'}
            showSortBy={props.showSortBy}
            closeShowSortBy={props.closeShowSortBy}
            applyOrder={(orderSelected: ButtonSelect[]) => props.applyOrder(orderSelected)}
            sortBySelected={rdxSortBy}
          />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
