import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import PlotListView from '@components/views/plot/PlotListView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { FullAvailablePlotResourceApi } from '@services/apis/FullAvailablePlotResourceApi';
import { Logging, Plot, PlotDTO } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { resetPlotList, setPlotList } from '../../redux/features/plot/plotSlice';
import { RootState } from '../../redux/store';
import { loggingStateIsClosed } from '../../utils/classes/UrstammUtilityCurrentState';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function PlotListContainer({ navigation, route }: RootStackScreenProps<'PlotList'>) {
  const rdxPlotList = useSelector((state: RootState) => state.persistedReducer.plot.list);
  const rdxPlotCompleteList = useSelector((state: RootState) => state.persistedReducer.plot.completeList);
  const dispatch = useDispatch();
  const [loggingSelected, setLoggingSelected] = useState<Logging>();

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: !loggingStateIsClosed(loggingSelected?.currentState!),
      text: i18n.t('generics.plot'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    if (route.params?.loggingSelected || route.params?.refreshList) {
      dispatch(resetPlotList());
      getPlotList(route.params.loggingSelected);
      setLoggingSelected(route.params.loggingSelected);
    }
  }, [route.params]);

  useEffect(() => {
    return () => {
      dispatch(resetPlotList());
    };
  }, []);

  const getPlotList = (loggingSelected: Logging) => {
    dispatch(changeLoaderStatus(true));

    FullAvailablePlotResourceApi.getPlotsByLogging(rdxPlotCompleteList, {
      loggingId: loggingSelected?.id!
    })
      .then((plotList: PlotDTO[]) => {
        dispatch(changeLoaderStatus(false));

        if (plotList) {
          dispatch(setPlotList(plotList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const navigateToPlotDetails = (plotSelected: Plot) => {
    UrstammNavigationHelper.navigateToPlotDetails(navigation, plotSelected, route.params?.loggingSelected);
  };

  const navigateToPileRegistration = () => {
    UrstammNavigationHelper.navigateToPlotRegistration(navigation, route.params?.loggingSelected);
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomRight={navigateToPileRegistration}
      title={
        <UrstammTitle
          testID={'plot_title'}
          text={i18n.t('generics.plot')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      headerDetails={[
        {
          title: i18n.t('generics.logging'),
          value: route.params?.loggingSelected.name!
        }
      ]}
      view={
        <PlotListView
          navigation={navigation}
          plotList={rdxPlotList}
          modalVisible={false}
          plotSelected={(plot: Plot) => navigateToPlotDetails(plot)}
        />
      }
    />
  );
}
