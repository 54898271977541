/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PileState,
} from '../models';
import {
    PileStateFromJSON,
    PileStateToJSON,
} from '../models';

export interface CreatePileStateRequest {
    pileState: PileState;
}

export interface DeletePileStateRequest {
    id: number;
}

export interface GetPileStateRequest {
    id: number;
}

export interface PartialUpdatePileStateRequest {
    id: number;
    pileState: PileState;
}

export interface UpdatePileStateRequest {
    id: number;
    pileState: PileState;
}

/**
 * 
 */
export class PileStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createPileStateRaw(requestParameters: CreatePileStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PileState>> {
        if (requestParameters.pileState === null || requestParameters.pileState === undefined) {
            throw new runtime.RequiredError('pileState','Required parameter requestParameters.pileState was null or undefined when calling createPileState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pile-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PileStateToJSON(requestParameters.pileState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PileStateFromJSON(jsonValue));
    }

    /**
     */
    async createPileState(requestParameters: CreatePileStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PileState> {
        const response = await this.createPileStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePileStateRaw(requestParameters: DeletePileStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePileState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pile-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePileState(requestParameters: DeletePileStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePileStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllPileStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PileState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pile-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PileStateFromJSON));
    }

    /**
     */
    async getAllPileStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PileState>> {
        const response = await this.getAllPileStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPileStateRaw(requestParameters: GetPileStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PileState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPileState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pile-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PileStateFromJSON(jsonValue));
    }

    /**
     */
    async getPileState(requestParameters: GetPileStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PileState> {
        const response = await this.getPileStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdatePileStateRaw(requestParameters: PartialUpdatePileStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PileState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePileState.');
        }

        if (requestParameters.pileState === null || requestParameters.pileState === undefined) {
            throw new runtime.RequiredError('pileState','Required parameter requestParameters.pileState was null or undefined when calling partialUpdatePileState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pile-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PileStateToJSON(requestParameters.pileState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PileStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdatePileState(requestParameters: PartialUpdatePileStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PileState> {
        const response = await this.partialUpdatePileStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePileStateRaw(requestParameters: UpdatePileStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PileState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePileState.');
        }

        if (requestParameters.pileState === null || requestParameters.pileState === undefined) {
            throw new runtime.RequiredError('pileState','Required parameter requestParameters.pileState was null or undefined when calling updatePileState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pile-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PileStateToJSON(requestParameters.pileState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PileStateFromJSON(jsonValue));
    }

    /**
     */
    async updatePileState(requestParameters: UpdatePileStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PileState> {
        const response = await this.updatePileStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
