/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PacketCertification,
} from '../models';
import {
    PacketCertificationFromJSON,
    PacketCertificationToJSON,
} from '../models';

export interface CreatePacketCertificationRequest {
    packetCertification: PacketCertification;
}

export interface DeletePacketCertificationRequest {
    id: number;
}

export interface GetPacketCertificationRequest {
    id: number;
}

export interface PartialUpdatePacketCertificationRequest {
    id: number;
    packetCertification: PacketCertification;
}

export interface UpdatePacketCertificationRequest {
    id: number;
    packetCertification: PacketCertification;
}

/**
 * 
 */
export class PacketCertificationResourceApi extends runtime.BaseAPI {

    /**
     */
    async createPacketCertificationRaw(requestParameters: CreatePacketCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketCertification>> {
        if (requestParameters.packetCertification === null || requestParameters.packetCertification === undefined) {
            throw new runtime.RequiredError('packetCertification','Required parameter requestParameters.packetCertification was null or undefined when calling createPacketCertification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packet-certifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PacketCertificationToJSON(requestParameters.packetCertification),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketCertificationFromJSON(jsonValue));
    }

    /**
     */
    async createPacketCertification(requestParameters: CreatePacketCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketCertification> {
        const response = await this.createPacketCertificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePacketCertificationRaw(requestParameters: DeletePacketCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePacketCertification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packet-certifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePacketCertification(requestParameters: DeletePacketCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePacketCertificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllPacketCertificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PacketCertification>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packet-certifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PacketCertificationFromJSON));
    }

    /**
     */
    async getAllPacketCertifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PacketCertification>> {
        const response = await this.getAllPacketCertificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPacketCertificationRaw(requestParameters: GetPacketCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketCertification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPacketCertification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packet-certifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketCertificationFromJSON(jsonValue));
    }

    /**
     */
    async getPacketCertification(requestParameters: GetPacketCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketCertification> {
        const response = await this.getPacketCertificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdatePacketCertificationRaw(requestParameters: PartialUpdatePacketCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketCertification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePacketCertification.');
        }

        if (requestParameters.packetCertification === null || requestParameters.packetCertification === undefined) {
            throw new runtime.RequiredError('packetCertification','Required parameter requestParameters.packetCertification was null or undefined when calling partialUpdatePacketCertification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packet-certifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PacketCertificationToJSON(requestParameters.packetCertification),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketCertificationFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdatePacketCertification(requestParameters: PartialUpdatePacketCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketCertification> {
        const response = await this.partialUpdatePacketCertificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePacketCertificationRaw(requestParameters: UpdatePacketCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketCertification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePacketCertification.');
        }

        if (requestParameters.packetCertification === null || requestParameters.packetCertification === undefined) {
            throw new runtime.RequiredError('packetCertification','Required parameter requestParameters.packetCertification was null or undefined when calling updatePacketCertification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packet-certifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PacketCertificationToJSON(requestParameters.packetCertification),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketCertificationFromJSON(jsonValue));
    }

    /**
     */
    async updatePacketCertification(requestParameters: UpdatePacketCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketCertification> {
        const response = await this.updatePacketCertificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
