/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductSpecies
 */
export interface ProductSpecies {
    /**
     * 
     * @type {number}
     * @memberof ProductSpecies
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSpecies
     */
    species?: ProductSpeciesSpeciesEnum;
    /**
     * 
     * @type {Product}
     * @memberof ProductSpecies
     */
    product?: Product;
}


/**
 * @export
 */
export const ProductSpeciesSpeciesEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type ProductSpeciesSpeciesEnum = typeof ProductSpeciesSpeciesEnum[keyof typeof ProductSpeciesSpeciesEnum];


/**
 * Check if a given object implements the ProductSpecies interface.
 */
export function instanceOfProductSpecies(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductSpeciesFromJSON(json: any): ProductSpecies {
    return ProductSpeciesFromJSONTyped(json, false);
}

export function ProductSpeciesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductSpecies {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'species': !exists(json, 'species') ? undefined : json['species'],
        'product': !exists(json, 'product') ? undefined : ProductFromJSON(json['product']),
    };
}

export function ProductSpeciesToJSON(value?: ProductSpecies | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'species': value.species,
        'product': ProductToJSON(value.product),
    };
}

