import AsyncStorage from '@react-native-async-storage/async-storage';
import { Logger } from './Logger';
import { UserExtendedTypeEnum } from '../services/apis/generated';
import { BaseAPIManager } from '@services/model/BaseAPIManager';

const CLASS_NAME = 'StorageHelper';

const STORE_KEY = '@UrstammStore';

const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
const LANGUAGE_KEY = 'LANGUAGE';
const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
const USER_TYPE_KEY = 'USER_TYPE';

function getStoreKey(key: string) {
  return `${STORE_KEY}:${key}`;
}

/**
 * Stores a value with key in storage
 * @param key
 * @param value
 * @returns {Promise<void>}
 */
export async function store(key: string, value: string) {
  try {
    await AsyncStorage.setItem(getStoreKey(key), value);
  } catch (error) {
    Logger.warn(CLASS_NAME, 'Error: ' + error);
  }
}

/**
 * Reads the stored value with key
 * @param key
 * @returns {Promise<any>}
 */
export async function read(key: string): Promise<any> {
  try {
    const value = await AsyncStorage.getItem(getStoreKey(key));
    if (value !== null) return value;
    Logger.log(CLASS_NAME, 'Not found');
  } catch (error) {
    Logger.warn(CLASS_NAME, 'Error: ' + error);
  }
}

/**
 * Removes item with given key
 * @param key
 * @returns {Promise<void>}
 */
export async function remove(key: string) {
  try {
    await AsyncStorage.removeItem(getStoreKey(key));
  } catch (error) {
    Logger.warn(CLASS_NAME, 'Error: ' + error);
  }
}

/**
 * Stores access token
 * @param value
 */
export function storeAccessToken(value: string) {
  store(ACCESS_TOKEN_KEY, value);
  BaseAPIManager.setToken(value);
}

/**
 * Stores access token
 * @param value
 */
export function storeUserType(value: UserExtendedTypeEnum) {
  store(USER_TYPE_KEY, value);
}

/**
 * Stores language
 * @param value
 */
export function storeLanguage(value: string) {
  store(LANGUAGE_KEY, value);
}

/**
 * Reads access token
 * @returns {Promise<string>}
 */
export function readAccessToken() {
  return read(ACCESS_TOKEN_KEY);
}

/**
 * Reads user type
 * @returns {Promise<string>}
 */
export function readUserType(): Promise<UserExtendedTypeEnum> {
  return read(USER_TYPE_KEY);
}

/**
 * Reads language
 * @returns {Promise<string>}
 */
export function readLanguage() {
  return read(LANGUAGE_KEY);
}

/**
 * Deletes access token
 * @returns {Promise<void>}
 */
export function removeAccessToken() {
  remove(ACCESS_TOKEN_KEY);
  BaseAPIManager.deleteToken();
}

/**
 * Deletes user type
 * @returns {Promise<void>}
 */
export function removeUserType() {
  remove(USER_TYPE_KEY);
}

/**
 * Stores refresh token
 * @param value
 */
export function storeRefreshToken(value: string) {
  store(REFRESH_TOKEN_KEY, value);
}

/**
 * Reads refresh token
 * @returns {Promise<string>}
 */
export function readRefreshToken() {
  return read(REFRESH_TOKEN_KEY);
}

/**
 * Deletes refresh token
 * @returns {Promise<void>}
 */
export function removeRefreshToken() {
  return remove(REFRESH_TOKEN_KEY);
}

/**
 * Deletes data for logout
 */
export function logout() {
  removeAccessToken();
  removeUserType();
}
