export const de = {
  server_errors: {
    cannot_send_shipping_note_without_processor: 'Der Lieferschein kann nicht verschickt werden ohne dass ein Weiterverarbeiter ausgewählt ist.',
    cannot_take_gps_coordinates: 'GPS-Position nicht gefunden. \nBitte erneut versuchen.',
    location_is_required: 'Für die Baumerfassung muss die Lokalisierung des Gerätes zugelassen werden',
    location_fine_is_required:
      'Bitte gehe auf Deine Geräteeinstellungen und erlaube Urstamm Deinen genauen Gerätestandort abzurufen!',
    cannot_complete_sync: 'Datenaktualisierung ist nicht möglich',
    cannot_logout_with_not_synchronized_objects: 'Logout ist nicht möglich da einige Daten noch nicht gesichert sind.',
    please_check_your_internet_connection: 'Internetverbindung überprüfen.',
    error_generic: 'Etwas ist schief gelaufen',
    ENTITY_NOT_FOUND: 'Einheit nicht gefunden',
    ERP_LEHMANN_IMPORT_PARSING_ERROR: 'Fehler beim Importieren der XML-Datei',
    ALREADY_ACTIVE_USER: 'Dieser User existiert bereits',
    ILLEGAL_STATE_FOR_UPDATE: 'Ungültiger Updatestatus',
    ALPHABET_LETTERS_EXHAUSTED_EXCEPTION: 'Buchstaben wurden ausgeschöpft??',
    SINGLE_TREE_PHOTO_NUMBER_CANNOT_BE_NULL: 'Baumnummer darf bei einer Einzelbaumerfassung nicht leer sein',
    MULTIPLE_TREE_PHOTO_NUMBER_MUST_BE_NULL: 'Baumnummer muss bei einer Schlagerfassung leer sein',
    PILE_WITH_OTHER_ASSORTMENT_CANNOT_HAVE_DELIVERY_SHEETS:
      'Polter mit anderem Sortiment kann keine Rundholzlisten enthalten',
    PILE_WOOD_TYPE_CANNOT_BE_NULL: 'Holzart darf auf dem Polter nicht leer sein',
    DELIVERY_SHEET_RECORDING_TYPE_CANNOT_BE_NULL: 'Erfassungsart darf auf der Rundholzliste nicht leer sein',
    TRUNK_PHOTO_NUMBER_CANNOT_BE_NULL_FOR_SINGLE_RECORDING:
      'Baumnummer darf bei einer Einzelbaumerfassung nicht leer sein',
    TRUNK_PHOTO_NUMBER_MUST_BE_NULL_FOR_MULTIPLE_RECORDING: 'Baumnummer muss bei einer Schlagerfassung leer sein',
    BARK_DISCOUNT_NOT_FOUND_FOR_TRUNK: 'Rindenabzug nicht gefunden',
    LENGTH_RANGE_NOT_FOUND_FOR_TRUNK: 'Längenklasse für diesen Stamm nicht gefunden',
    DIAMETER_CLASS_NOT_FOUND_FOR_TRUNK: 'Durchmesser ungültig',
    NO_COMPANY_FOR_USER: 'Dieser User gehört zu keinem Betrieb',
    CANNOT_IMPORT_IF_DELIVERY_SHEET_HAS_TRUNKS:
      'Import nicht möglich da bereits Stämme auf der Rundholzliste vorhanden sind',
    INVALID_IMPORT_SOURCE_FOR_DELIVERY_SHEET: 'Ungültige Quelle für Import der Rundholzliste',
    WRONG_USER_LETTER_FOR_PHOTO_NUMBER: 'Dieser Buchstabe ist ungültig für die Baumnummer',
    PERMIT_MEDIA_TYPE_CANNOT_BE_NULL: 'Medientyp für Bewilligung kann nicht leer sein',
    TRUNKS_TO_VALIDATE_DONT_BELONG_TO_DELIVERY_SHEET: 'Zu validierende Stämme gehören nicht zur Rundholzliste',
    CANNOT_CLOSE_LOGGING_WITH_OPEN_DELIVERY_SHEETS:
      'Dieser Schlag kann erst geschlossen werden wenn alle zugehörigen Rundholzlisten geschlossen sind',
    CANNOT_SET_PROCESSOR_CUSTOM_NAME_IF_PROCESSOR_COMPANY_IS_DEFINED:
      'Es kann kein benutzerdefinierter Name für den Weiterverarbeiter gewählt werden, wenn jener bereits erfasst wurde.',
    CANNOT_SEND_SHIPPING_NOTE_WITH_SENT_PACKET: 'Mindestens ein Paket wurde bereits geliefert.',
    CANNOT_SEND_PROCESSOR_SHIPPING_NOTE_WITH_SENT_PRODUCT: 'Mindestens ein Produkt wurde bereits geliefert.',
    CANNOT_CLOSE_PROJECT_WITHOUT_PHOTO: 'Das Projekt kann nicht geschlossen werden. Bitte ein Foto hinzufügen.',
    CANNOT_CLOSE_PROJECT_WITH_NOT_CLOSED_OR_SENT_PRODUCT: 'Das Projekt kann nicht geschlossen werden. Bitte schliesse die offenen Produkte ab.',
    CANNOT_USE_ALREADY_USED_PRODUCT: 'Mindestens ein Produkt wurde bereits einer Lieferung oder einer Produktionscharge zugewiesen.',
    CANNOT_CLOSE_PROJECT_WITHOUT_PRODUCTS: 'Das Projekt kann nicht geschlossen werden. Bitte Produkte zum Projekt hinzufügen.',
    CANNOT_SEND_SHIPPING_NOTE_WITHOUT_PACKETS: 'Dieser Lieferschein ist leer. Bitte mindestens ein Paket zum Lieferschein hinzufügen.',
    CANNOT_SEND_PROCESSOR_SHIPPING_NOTE_WITHOUT_PRODUCTS: 'Dieser Lieferschein ist leer. Bitte mindestens ein Produkt zum Lieferschein hinzufügen.',
    UNIQUE_IDENTIFIER_ALREADY_EXISTS_FOR_PACKET: "Diese UID existiert bereits.",
    UNIQUE_IDENTIFIER_ALREADY_EXISTS_FOR_PRODUCT: "Diese UID existiert bereits.",
  },
  error: 'Fehler',
  errors: {
    something_went_wrong: 'Etwas ist schief gelaufen.'
  },
  alerts: {
    are_you_sure: 'Bist Du sicher?',
    attention: 'Achtung',
    cancel: 'Abbrechen',
    delete: 'Löschen',
    no: 'Nein',
    ok: 'Ok',
    replace: 'Ersetzen',
    success: 'Erfolgreich',
    want_delete_image: 'Möchtest Du das Foto löschen?',
    want_replace_image: 'Möchtest Du das Foto ersetzen?',
    which_action_image: 'Was möchtest Du machen?',
    yes: 'Ja',
    at_least_one_trunk:
      'Eine Rundholzliste für die Einzelbaumerfassung kann der Sägerei erst zugestellt werden, wenn mindestens ein Stamm darin erfasst wurde.',
    sawmill_alerts:
      'Damit diese Rundholzliste zugestellt werden kann, muss in den Details dieser Liste eine Sägerei angewählt werden',
    sync_successfully: 'Datenaktualisierung erfolgreich!'
  },
  header: {
    welcome: 'Willkommen bei'
  },
  utility_components: {
    filter_button: {
      all: 'Alle',
      status: 'Status'
    },
    input: {
      validators: {
        email: 'E-Mail-Adresse ist ungültig',
        first_letter_character: 'Das erste Zeichen muss ein Buchstabe sein',
        max_length: 'Hier können höchstens {{char}} Zeichen eingegeben werden',
        min_length: 'Hier müssen mindestens {{char}} Zeichen eingegeben werden',
        numeric: 'Dieses Feld darf nur Zahlen beinhalten',
        numeric_no_digits: 'Dieses Feld darf nur ganze Zahlen beinhalten',
        required: 'Dieses Feld muss ausgefüllt werden',
        maxDigits: 'Wert ist nicht erlaubt',
        percentage: 'Die Zahl muss zwischen 0 und 100 sein',
        password:
          'Das Passwort muss mindestens 8 Zeichen lang sein, aus Gross- und Kleinbuchstaben bestehen, eine Zahl sowie ein Sonderzeichen enthalten.',
        maxValue: 'Dieser Wert muss tiefer sein als {{char}}',
        minValue: 'Dieser Wert muss höher sein als {{char}}'
      }
    },
    modal: {
      cancel: 'Abbrechen',
      confirm: 'Speichern',
      sortBy: {
        apply_order: 'Sortieren',
        choose_sorting_type: 'Sortieren nach:',
        clear_order: 'Sortierung zurücksetzen',
        date_asc: 'Älteste zuerst',
        date_desc: 'Aktuellste zuerst',
        name_asc: 'Name A-Z',
        name_desc: 'Name Z-A',
        id_asc: 'ID aufsteigend',
        id_desc: 'ID absteigend'
      }
    },
    corner_button: {
      menu: 'Menü',
      back: 'Zurück',
      new_tree_record: 'Neuer Baum erfassen',
      sort_by: 'Sortieren'
    },
    list: {
      closed: 'Abgeschlossen',
      creation: 'Erfassung:',
      cut: 'Cut:',
      delivered: 'In Überprüfung',
      edited_by_forestry: 'Bearbeitet von Forstbetrieb',
      edited_by_sawmill: 'Bearbeitet von der Sägerei',
      in_progress: 'Erstellt',
      in_progress_by_forestry: 'Erstellt von Forstbetrieb',
      in_progress_by_sawmill: 'Erstellt von der Sägerei',
      sent_in_shipping_note: 'Verschickt',
      selected_for_shipping_note: 'Ausgewählt',
      moved_to_sawmill: 'An Sägerei übermitteln',
      phone_number: 'Phone number:',
      standing: 'Standing',
      valid: 'Gültig',
      validated: '(Von Sägerei) überprüft',
      sent : 'Gesendet',
      received: 'Erhalten',
      accepted : 'Akzeptiert',
      rejected : 'Abgelehnt',
      done: 'Abgeschlossen',
    },
    photo_picker: {
      delete: 'Löschen',
      no_location_found: 'Auf Standortbestimmung warten'
    },
    sync_button: {
      last_sync: 'Zuletzt: {{date}}',
      never_synced: '-'
    }
  },
  generics: {
    app_version: 'Anwendungsversion',
    build_version: 'Version bauen',
    support: 'Support',
    are_you_sure_change_to_closed:
      'Möchstest Du diese Rundholzliste wirklich abschliessen?\nDanach können keine Anpassungen mehr gemacht werden.',
    are_you_sure_change_to_delivered:
      'Möchtest Du die Rundholzliste wirklich der Sägerei zustellen?\nDu kannst erst wieder Anpassungen machen, nachdem die Sägerei die Liste überprüft hat.',
    are_you_sure_change_to_validated:
      'Hast Du die Rundholzliste vollständig überprüft und möchtest diese dem Forstbetrieb zurück schicken?\nDanach können keine Anpassungen mehr gemacht werden.',
    are_you_sure_validate_all:
      'Bist Du sicher, dass Du alle Stämme zusammen validieren willst?\nBei einer Bestätigung gehen alle zuvor gemachten Anpassungen verloren.',
    appearance: 'Eigenschaften',
    batch: 'Charge',
    sender: 'Lieferant',
    receiver: 'Empfänger',
    shipment: 'Ausgang',
    shipping_note: 'Ausgang',
    processor_batch: 'Charge',
    processor_shipping_note: 'Ausgang',
    incoming_shipping_note: 'Eingang',
    company: {
      name: 'Name',
      streetName: 'Strasse',
      streetNumber: 'Hausnummer',
      city: 'Ort',
      zipCode: 'Postleitzahl',
      phoneNumber: 'Telefonnummer',
      email: 'E-Mail'
    },
    choose_media_type: 'Medientyp auswählen',
    choose_recording_type: 'Erfassungsart auswählen',
    comment: 'Kommentar',
    copy_delivery_sheet: 'Rundholzliste kopieren',
    creation: 'Erfassung:',
    cutting_time: 'Fällzeitpunkt',
    date: 'Datum',
    delivered: 'In Überprüfung',
    delivery_sheet: 'Rundholzlisten',
    details: 'Details',
    diameter: 'Durchmesser',
    document: 'Dokument',
    download_pdf: 'Herunterladen Pdf',
    edit: 'Bearbeiten',
    energy: 'Energieholz',
    estimate_volume: 'Geschätztes Volumen (m³)',
    estimated_logging_volume: 'Geschätztes Volumen (m³)',
    estimated_volume: 'Geschätztes Volumen (m³)',
    export_pdf: 'Herunterladen Pdf',
    export_xml: 'Herunterladen Xml',
    exposed: 'Sichtholz',
    filter_list: 'suchen...',
    search: 'Suchen',
    fire_wood: 'Brennholz',
    forestry: 'Forstbetrieb',
    gps_position: 'GPS Koordinaten',
    id: 'ID',
    processor_shipping_note_id: 'Lieferschein ID',
    image: 'Foto',
    import_factory_measurement: 'Werksmessung importieren',
    validate: 'Bestätigen',
    cancel: 'Stornieren',
    sync_data: 'Daten synchronisieren',
    import_xml: 'XML importieren',
    import: 'Import',
    incoming_goods: 'Eingang',
    industrial_wood: 'Industrieholz',
    industry: 'Industrieholz',
    insert_comment_here: 'Hier Kommentar eingeben',
    insert_estimated_volume_here: 'Hier geschätztes Volumen eingeben',
    latitude: 'Breitengrad',
    length: 'Länge',
    length_with_unit: 'Länge (m)',
    logging: 'Schlag',
    logout: 'Ausloggen',
    longitude: 'Längengrad',
    multiple: 'Region',
    name: 'Name',
    net_volume: 'Nettovolumen (m³)',
    new_tree_recording: 'Neuer Baum erfassen',
    offline_sync: 'Offline-Synchronisierung',
    other_assortment: 'Anderes Sortiment',
    outgoing_goods: 'Ausgang',
    packet: 'Paket',
    pile: 'Polter',
    piles: 'Polter',
    plot: 'Parzelle',
    processor: 'Weiterverarbeiter',
    production: 'Produktion',
    product: 'Produkt',
    project: 'Projekt',
    projects: 'Projekte',
    productType: 'Produkttyp',
    state: 'Status',
    creationDate: 'Erstelldatum',
    dryness: 'Trockenheit',
    planningDimension: 'Planungsdimension',
    volume: 'Volumen',
    volume_with_unit: 'Volumen (m³)',
    certifications: 'Zertifikate',
    width: 'Breite',
    width_with_unit: 'Breite (mm)',
    woodQuality: 'Holzqualität',
    thickness: 'Höhe',
    thickness_with_unit: 'Höhe (mm)',
    treeSpecies: 'Baumart',
    amountOfProducts: 'Anzahl Bretter', //used for amount of boards in a packet and therefore to calculate the volume of a packet
    amount_of_product: 'Anzahl Produkte', //used on the cards of incoming shipping notes, outgoing shipping notes, in a processor batch
    amount_of_packet: 'Anzahl Pakete',
    amountOfTruckJourneys: 'Anzahl Fahrten',
    type_of_record: 'Erfassungsart',
    quality: 'Qualität',
    recording_type: 'Erfassungsart',
    save_and_continue: 'Speichern und fortfahren',
    save: 'Speichern',
    sawmill: 'Sägerei',
    send_feedback_to_forestry: 'Rückmeldung an Forstbetrieb schicken',
    send_feedback_to_sawmill: 'Rückmeldung an Sägerei schicken',
    send_to_sawmill: 'An Sägerei zustellen',
    single: 'Einzelstamm',
    specie: 'Baumart',
    species: 'Baumart',
    sum_cubage: 'Volumen (m³)',
    surface: 'Oberfläche',
    sync: 'Daten aktualisieren',
    timber: 'Stammholz',
    total_wood: 'Volumen geschlagenes Holz (m³)',
    tree_species: 'Baumart',
    tree: 'Baum',
    trees_not_found: 'Keine Bäume gefunden',
    trees: 'Bäume',
    trunk: 'Stamm',
    trunks: 'Stämme',
    type_of_other_assortment: 'Sortimentart',
    upload_photo_file: 'Foto hochladen',
    validate_list_and_send_to_forester: 'Rundholzliste ohne Änderungen validieren und an Forstbetrieb zurückschicken',
    validate_list: 'Rundholzliste validieren',
    validated: '(Von Sägerei) überprüft',
    waiting_for_validation: 'Auf Validierungen warten',
    wood_quality: 'Holzqualität',
    wood_type: 'Holzart',
    tree_species_enum: {
      acer: 'Ahorn',
      alder: 'Erle',
      apple: 'Apfelbaum',
      arolla: 'Arve',
      ash: 'Esche',
      beech: 'Buche',
      birch: 'Birke',
      blackPine: 'Schwarzföhre',
      black_pine: 'Schwarzföhre',
      cherryTree: 'Kirsche',
      cherry_tree: 'Kirsche',
      chestnut: 'Kastanie',
      douglasFire: 'Douglasie',
      douglas_fire: 'Douglasie',
      elm: 'Ulme',
      fieldMaple: 'Feldahorn',
      field_maple: 'Feldahorn',
      fir: 'Tanne',
      hornbeam: 'Hagebuche',
      larch: 'Lärche',
      linden: 'Linde',
      mixedHardwood: 'Verschiedene Laubhölzer',
      mixed_hardwood: 'Verschiedene Laubhölzer',
      mixedSoftwood: 'Verschiedene Nadelhölzer',
      mixed_softwood: 'Verschiedene Nadelhölzer',
      norwayMaple: 'Spitzahorn',
      norway_maple: 'Spitzahorn',
      oak: 'Eiche',
      otherHardWood: 'Andere Laubhölzer',
      other_hardWood: 'Andere Laubhölzer',
      otherSoftWood: 'Andere Nadelhölzer',
      other_softWood: 'Andere Nadelhölzer',
      pear: 'Birnbaum',
      pine: 'Föhre',
      redOak: 'Roteiche',
      red_oak: 'Roteiche',
      spruce: 'Fichte',
      sycamore: 'Bergahorn',
      walnut: 'Nussbaum',
      whitePine: 'Weymouthsföhre',
      white_pine: 'Weymouthsföhre'
    }
  },
  views: {
    login: {
      check_email_for_resetting_password: 'Überprüfe Deinen Posteingang um das Passwort zurückzusetzen',
      confirm_password: 'Bestätigen',
      forgot_password: 'Passwort vergessen',
      insert_your_email_here: 'Hier E-Mail-Adresse eingeben',
      insert_your_password_here: 'Hier Passwort eingeben',
      new_password: 'Neues Passwort',
      confirm_password_here: 'Passwort bestätigen',
      login_button: 'Einloggen',
      login: 'Login',
      reset_password: 'Passwort zurücksetzen',
      send_email: 'E-Mail für Passwortzurücksetzung senden'
    },
    offlineSync: {
      offlineSyncs_not_found: 'Keine Objekte zum Hochladen vorhanden',
      offlineSyncs_title: 'Offline-Synchronisierung',
      offlineSyncs_button: 'Daten hochladen'
    },
    signup: {
      agree_app_policy_and_term: 'AGB akzeptieren',
      create_new_account: 'Neuer Account erstellen',
      insert_your_email_here: 'E-Mail-Adresse eingeben',
      insert_your_name_here: 'Vorname eingeben',
      insert_your_surname_here: 'Nachname eingeben',
      login: 'Einloggen',
      select_your_function: 'Deine Funktion auswählen',
      signup: 'Registrieren'
    },
    shipping_note:{
      batches_not_found: 'Keine Chargen gefunden',
      no_urstamm_processor: 'Nicht-Urstamm-Kunde',
      shipping_note_not_found: 'Keine Lieferscheine gefunden',
      sum_volume_packets: 'Volumen (m³)',
      send_shipping_note: 'Lieferschein verschicken',
      are_you_sure_want_send_shipping_note: 'Möchtest Du diesen Lieferschein wirklich dem Weiterverarbeiter zustellen? \n Danach können keine Anpassungen mehr gemacht werden.',

      shipping_note_registration: {
        shipping_note_name: 'Name',
        shipping_note_status: 'Status',
        choose_batch: 'Charge auswählen',
        choose_packets: 'Pakete auswählen',
        delivery_sheet_selected: 'Ausgewählt',
        insert_shipping_note_date_here: 'Hier Datum auswählen',
        insert_shipping_note_name_here: 'Hier Name auswählen',
        new_shipping_note: 'Neuer Lieferschein',   
        choose_processor: 'Weiterverarbeiter auswählen',
        choose_packet: 'Paket wählen', 
      }

    },
    processor_shipping_note:{
      batches_not_found: 'Keine Chargen gefunden',
      no_urstamm_processor: 'Nicht-Urstamm-Kunde',
      shipping_note_not_found: 'Keine Lieferscheine gefunden',
      sum_volume_products: 'Volumen (m³)',
      send_shipping_note: 'Lieferschein verschicken',
      are_you_sure_want_send_shipping_note: 'Möchtest Du diesen Lieferschein wirklich dem Weiterverarbeiter zustellen? \n Danach können keine Anpassungen mehr gemacht werden.',
      are_you_sure_want_accept_shipping_note: 'Möchtest Du wirklich die gesamte Lieferung akzeptieren? \nDanach können keine Anpassungen mehr gemacht werden.',
      are_you_sure_want_reject_shipping_note: 'Möchtest Du diese Lieferung wirklich ablehnen? \nDanach können keine Anpassungen mehr gemacht werden.',
      accept_shipping_note: 'Akzeptieren',
      reject_shipping_note: 'Ablehnen',

      processor_shipping_note_registration: {
        shipping_note_name: 'Name',
        shipping_note_status: 'Status',
        choose_batch: 'Charge auswählen',
        choose_products: 'Produkt auswählen',
        delivery_sheet_selected: 'Ausgewählt',
        insert_shipping_note_date_here: 'Hier Datum auswählen',
        insert_shipping_note_name_here: 'Hier Name auswählen',
        new_shipping_note: 'Neuer Lieferschein',   
        choose_processor: 'Weiterverarbeiter auswählen',
        choose_packet: 'Paket wählen', 
      }

    },
    batch: {
      batch_not_found: 'Keine Chargen gefunden',
      packets: 'Pakete',
      sum_volume_packets: 'Volumen (m³)',
      close_batch: 'Charge abschliessen',
      are_you_sure_want_close_batch:
        'Möchstest Du diese Charge wirklich abschliessen? \nDanach können keine Anpassungen mehr gemacht werden.',
      batch_registration: {
        batch_name: 'Name',
        batch_status: 'Status',
        choose_delivery_sheet: 'Zugehörige Rundholzlisten auswählen',
        delivery_sheet_selected: 'Ausgewählt',
        insert_batch_date_here: 'Hier Datum eingeben',
        insert_batch_name_here: 'Hier Name eingeben',
        insert_batch_unique_identifier_here: 'Hier Chargennummer eingeben',
        new_batch: 'Neue Charge',

      }
    },
    processor_batch: {
      processor_batch_not_found: 'Keine Chargen gefunden',
      products: 'Produkt',
      sum_volume_products: 'Volumen (m³)',
      close_processor_batch: 'Charge abschliessen',
      products_not_found: 'Keine Produkte gefunden',
      are_you_sure_want_close_processor_batch:
        'Möchstest Du diese Charge wirklich abschliessen? \nDanach können keine Anpassungen mehr gemacht werden.',
      processor_batch_registration: {
        processor_batch_name: 'Name',
        processor_batch_status: 'Status',
        choose_processor_shipping_note: 'Zugehörige Lieferscheine auswählen',
        choose_products: 'Zugehörige Produkte vom Lieferanten auswählen',
        processor_shipping_note_selected: 'Ausgewählt',
        insert_processor_batch_date_here: 'Hier Datum eingeben',
        insert_processor_batch_name_here: 'Hier Name eingeben',
        insert_processor_batch_unique_identifier_here: 'Hier Chargennummer eingeben',
        new_processor_batch: 'Neue Charge',
        unique_identifier: 'Chargennummer'

      }
    },
    delivery_sheet: {
      delivery_sheets_not_found: 'Keine Rundholzlisten gefunden',
      upload_delivery_sheet_image: 'Foto zu dieser Rundholzliste hochladen',
      delivery_sheet_registration: {
        attention_sawmill_cannot_be_changed_afterwards: 'Achtung: Sägerei kann später nicht mehr geändert werden',
        insert_delivery_sheet_date_here: 'Hier Datum für Rundholzliste eingeben',
        insert_delivery_sheet_name_here: 'Hier Name für Rundholzliste eingeben',
        amount_of_truck_journeys: 'Anzahl Fahrten',
        insert_amount_of_truck_journeys_here: 'Hier Anzahl Fahrten eingeben',
        new_delivery_sheet: 'Neue Rundholzliste',
        choose_logging: 'Schlag auswählen',
        choose_pile: 'Polter auswählen',
        choose_sawmill: 'Sägerei auswählen',
        sawmill_not_found: 'Sägerei nicht gefunden'
      },
      modal_pdf_text: 'Notizen einfügen (Optional)',
      amount_of_trunks: 'Anzahl Stämme',
      insert_amount_of_trunks: 'Anzahl Stämme einfügen',
      insert_volume: 'Volumen einfügen (FM | m3)',
      volume: 'Volumen',
      xml: 'XML',
      pdf: 'PDF',
      upload_xml_document: 'XML Datei hochladen',
      document: 'Dokument',
      take_photo_or_upload_pdf: 'PDF hochladen | Foto aufnehmen',
      required_information: 'Erforderliche Informationen',
      inputs_from_sawmill: 'Input Sägerei',
      tree_species: 'Baumart',
      documents: 'Dokument',
      comment: 'Kommentar',
      download_all: 'Herunterladen'
    },
    logging: {
      loggings_not_found: 'Keine Schläge gefunden',
      logging_registration: {
        insert_logging_date_here: 'Hier Datum für Schlag eingeben',
        insert_logging_name_here: 'Hier Name für Schlag eingeben',
        new_logging: 'Neuer Schlag',
        permit: 'Schlagbewilligung',
        upload_permit_file: 'Dokument hochladen',
        logging_photo: 'Schlagfoto',
      },
      logging_details: {
        are_you_sure_want_close_logging:
          'Möchtest Du diesen Schlag wirklich abschliessen? \nDanach können keine Anpassungen mehr gemacht werden.',
        close_logging: 'Schlag abschliessen',
        comment_of_the_logging: 'Kommentar zum Schlag',
        cubage_standinglist: 'Volumen auf Stehendliste',
        permit_details: 'Details Schlagbewilligung',
        plot_details: 'Details Parzelle'
      }
    },
    tree: {
      delete_tree: 'Baum löschen',
      modify_tree: 'Baum anpassen',
      multiple_tree: 'Region',
      single_tree: 'Einzelstamm',
      date: 'Datum',
      tree_registration: {
        insert_tree_date_here: 'Hier Datum von Baum eingeben',
        insert_tree_photo_number_here: 'Hier Baumnummer eingeben',
        new_tree: 'Neuer Baum',
        photo_number: 'Baumnummer',
        tree_photo: 'Foto'
      }
    },
    trunk: {
      delete_trunk: 'Stamm löschen',
      modify_trunk: 'Stamm anpassen',
      trunks_not_found: 'Keine Stämme gefunden',
      trunk_registration: {
        new_trunk: 'Neuer Stamm hinzufügen',
        choose_photo_number: 'Baumnummer wählen',
        trunk_number: 'Stammnummer',
        insert_trunk_number_here: 'Hier Stammnummer eingeben',
        insert_trunk_length_here: 'Hier Stammlänge eingeben',
        insert_trunk_diameter_here: 'Hier Stammdurchmesser eingeben',
        trunk_length: 'Länge (m)',
        trunk_diameter: 'Durchmesser (cm)'
      }
    },
    packet: {
      are_you_sure_want_close_packet:
        'Möchtest Du dieses Paket wirklich abschliessen? \nDanach können keine Anpassungen mehr gemacht werden.',
      certifications_not_found: 'Keine Zertifikate gefunden',
      packets_not_found: 'Keine Pakete gefunden',
      products_type_not_found: 'Keine Produkttypen gefunden',
      processors_not_found: 'Keine Weiterverarbeiter gefunden',
      woods_quality_not_found: 'Holzqualität nicht gefunden',
      surfaces_not_found: 'Keine Oberflächentypen gefunden',
      no_urstamm_processor: 'Nicht-Urstamm-Kunde',
      packet_registration: {
        add_new_product: 'Neuer Produktyp hinzufügen',
        amount_of_products: 'Anzahl Bretter',
        discount: 'Abzug für Rechnung',
        certification: 'Zertifizierungen',
        choose_certification: 'Zertifizierungen auswählen',
        choose_processor: 'Weiterverarbeiter auswählen',
        choose_product_type: 'Produkttyp auswählen',
        choose_surface: 'Oberflächentyp auswählen',
        choose_trunk: 'Stamm auswählen',
        choose_wood_quality: 'Holzqualität auswählen',
        enter_processor: 'Weiterverarbeiter eingeben',
        insert_amount_of_products_here: 'Hier Anzahl Bretter im Paket eingeben',
        insert_packet_date_here: 'Hier Datum eingeben',
        insert_packet_discount_here: 'Hier Abzug für Rechnung eingeben',
        insert_packet_dryness_here: 'Hier Trockenheit eingeben',
        insert_processor_name_address: 'Hier Name und Adresse vom Verarbeiter eingeben',
        insert_packet_length_here: 'Hier Produktlänge eingeben',
        insert_packet_name_here: 'Hier Paketname eingeben',
        insert_packet_planning_dimension_here: 'Hier Planungsdimension eingeben',
        insert_packet_thickness_here: 'Hier Höhe des Produkts eingeben',
        insert_packet_unique_identifier_here: 'Hier Paketnummer eingeben',
        insert_packet_width_here: 'Hier Breite des Produkts eingeben',
        insert_product_type_name_here: 'Hier Name für Produkttyp eingeben',
        manage_product_type: 'Produkttypen verwalten',
        new_packet: 'Neues Paket',
        packet_dryness: 'Trockenheit (in % +/- 2%)',
        packet_length: 'Länge Produkt (m)',
        packet_name: 'Name',
        packet_photo: 'Foto Paket',
        packet_planning_dimension: 'Planungsdimension',
        packet_thickness: 'Höhe Produkt (mm)',
        packet_width: 'Breite Produkt (mm)',
        product_type: 'Produkttyp',
        surface: 'Oberfläche',
        unique_identifier: 'UID',
        update_product_type: 'Produkttypen anpassen'
      }
    },
    plot: {
      add_plot: 'Parzelle hinzufügen',
      delete_plot: 'Parzelle löschen',
      egrid_n: 'E-GRID',
      insert_plot_egrid_here: 'Hier EGRID eingeben',
      insert_plot_name_here: 'Hier Name für Parzelle eingeben',
      modify_plot: 'Parzelle speichern',
      new_plot: 'Neue Parzelle',
      plot_name: 'Name Parzelle',
      plots_not_found: 'Keine Parzellen gefunden'
    },
    pile: {
      choose_type_of_pile: 'Sortiment auswählen',
      delete_pile: 'Polter löschen',
      go_to_delivery_sheets: 'Zu den zugehörigen Rundholzlisten',
      list_of_pile: 'Polterliste',
      piles_not_found: 'Keine Polter gefunden',
      pile_not_found: 'Keine Polter gefunden',
      upload_pile_file: 'Foto von Polter hochladen',
      pile_registration: {
        insert_pile_date_here: 'Hier Datum von Polter eingeben',
        insert_pile_name_here: 'Hier Name von Polter eingeben',
        new_pile: 'Neuer Polter',
        pile_photo: 'Foto Polter'
      }
    },
    product: {
      are_you_sure_want_close_product:
        'Möchtest Du dieses Produkt wirklich abschliessen? \nDanach können keine Anpassungen mehr gemacht werden.',
      certifications_not_found: 'Keine Zertifikate gefunden',
      products_not_found: 'Keine Produkte gefunden',
      products_type_not_found: 'Keine Produkttypen gefunden',
      processors_not_found: 'Keine Weiterverarbeiter gefunden',
      woods_quality_not_found: 'Holzqualität nicht gefunden',
      surfaces_not_found: 'Keine Oberflächentypen gefunden',
      appearances_not_found: 'Keine Eigenschaften gefunden',
      no_urstamm_processor: 'Nicht-Urstamm-Kunde',
      product_registration: {
        add_new_product: 'Neuer Produktyp hinzufügen',
        // amount_of_products: 'Anzahl Bretter', not used in product registration as we type in the total volume of a product directly
        discount: 'Abzug für Rechnung',
        certification: 'Zertifizierungen',
        choose_certification: 'Zertifizierungen auswählen',
        choose_processor: 'Weiterverarbeiter auswählen',
        choose_product_type: 'Produkttyp auswählen',
        choose_surface: 'Oberflächentyp auswählen',
        choose_appearance: 'Eigenschaften auswählen',
        choose_product: 'Produkt auswählen',
        choose_wood_quality: 'Holzqualität auswählen',
        enter_processor: 'Weiterverarbeiter eingeben',
        // insert_amount_of_products_here: 'Hier Anzahl Bretter im Produkt eingeben', not used in product registration as we type in the total volume of a product directly
        insert_product_date_here: 'Hier Datum eingeben',
        insert_product_discount_here: 'Hier Abzug für Rechnung eingeben',
        insert_processor_name_address: 'Hier Name und Adresse vom Verarbeiter eingeben',
        insert_product_name_here: 'Hier Produktname eingeben',
        insert_product_unique_identifier_here: 'Hier Produktnummer eingeben',
        insert_product_type_name_here: 'Hier Name für Produkttyp eingeben',
        insert_sum_cubage_here: 'Hier Volumen eingeben',
        manage_product_type: 'Produkttypen verwalten',
        new_product: 'Neues Produkt',
        product_name: 'Name',
        product_photo: 'Foto Produkt',
        product_type: 'Produkttyp',
        surface: 'Oberfläche',
        unique_identifier: 'UID',
        update_product_type: 'Produkttypen anpassen',
        sum_cubage: 'Volumen (m³)'
      }
    },
    project: {
      are_you_sure_want_close_project: 'Möchtest Du dieses Projekt wirklich abschliessen? \nDanach können keine Anpassungen mehr gemacht werden.',
      projects_not_found: 'Keine Projekte gefunden',
      products_not_found: 'Keine Produkte gefunden',
      close_project: 'Projekt abschliessen',
      amount_of_products: 'Anzahl Produkte',
      project_registration: {
        add_new_product: 'Neue Produkte hinzufügen',
        choose_products: 'Produkte auswählen',
        products_not_found: 'Keine Produkte gefunden',
        insert_project_date_here: 'Hier Projektdatum eingeben',
        insert_project_name_here: 'Hier Projektname eingeben',
        insert_project_unique_number_here: 'Hier UID für Projekt eingeben',
        new_project: 'Neues Projekt',
        project_name: 'Name',
        project_photo: 'Foto Projekt',
        unique_number: 'UID',
      }
    }
  },
  email: {
    support: {
      subject: 'Unterstützungsanfrage',
      body: 'Liebes Urstamm-Team,\r\n',
    },
  },
};
