/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PacketState,
} from '../models';
import {
    PacketStateFromJSON,
    PacketStateToJSON,
} from '../models';

export interface CreatePacketStateRequest {
    packetState: PacketState;
}

export interface DeletePacketStateRequest {
    id: number;
}

export interface GetPacketStateRequest {
    id: number;
}

export interface PartialUpdatePacketStateRequest {
    id: number;
    packetState: PacketState;
}

export interface UpdatePacketStateRequest {
    id: number;
    packetState: PacketState;
}

/**
 * 
 */
export class PacketStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createPacketStateRaw(requestParameters: CreatePacketStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketState>> {
        if (requestParameters.packetState === null || requestParameters.packetState === undefined) {
            throw new runtime.RequiredError('packetState','Required parameter requestParameters.packetState was null or undefined when calling createPacketState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packet-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PacketStateToJSON(requestParameters.packetState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketStateFromJSON(jsonValue));
    }

    /**
     */
    async createPacketState(requestParameters: CreatePacketStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketState> {
        const response = await this.createPacketStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePacketStateRaw(requestParameters: DeletePacketStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePacketState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packet-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePacketState(requestParameters: DeletePacketStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePacketStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllPacketStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PacketState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packet-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PacketStateFromJSON));
    }

    /**
     */
    async getAllPacketStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PacketState>> {
        const response = await this.getAllPacketStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPacketStateRaw(requestParameters: GetPacketStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPacketState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packet-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketStateFromJSON(jsonValue));
    }

    /**
     */
    async getPacketState(requestParameters: GetPacketStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketState> {
        const response = await this.getPacketStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdatePacketStateRaw(requestParameters: PartialUpdatePacketStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePacketState.');
        }

        if (requestParameters.packetState === null || requestParameters.packetState === undefined) {
            throw new runtime.RequiredError('packetState','Required parameter requestParameters.packetState was null or undefined when calling partialUpdatePacketState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packet-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PacketStateToJSON(requestParameters.packetState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdatePacketState(requestParameters: PartialUpdatePacketStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketState> {
        const response = await this.partialUpdatePacketStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePacketStateRaw(requestParameters: UpdatePacketStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePacketState.');
        }

        if (requestParameters.packetState === null || requestParameters.packetState === undefined) {
            throw new runtime.RequiredError('packetState','Required parameter requestParameters.packetState was null or undefined when calling updatePacketState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packet-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PacketStateToJSON(requestParameters.packetState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketStateFromJSON(jsonValue));
    }

    /**
     */
    async updatePacketState(requestParameters: UpdatePacketStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketState> {
        const response = await this.updatePacketStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
