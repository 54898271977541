import { DateHelper } from '@helpers/DateHelper';
import { NetworkHelper } from '@helpers/NetworkHelper';
import { OfflineSyncClassEnum, OfflineSyncTypeEnum } from '@redux/features/offlineSync/offlineSyncSlice';
import { setTrunkList } from '@redux/features/trunk/trunkSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Dispatch, AnyAction } from 'redux';
import { calculateTrunkVolume } from '../../utils/classes/UrstammUtilityCalculation';
import { sortOfflineList } from '../../utils/classes/UrstammUtilityFunctions';
import { customTrunkResourceApi } from './ApiConfiguration';
import {
  CreateTrunkCustomRequest,
  GetTrunksByDeliverySheetRequest,
  Trunk,
  UpdateTrunkCustomRequest
} from './generated';

export class FullAvailableTrunkResourceApi {
  /**
   * Get all trunk list for delivery sheet
   * @param rdxTrunkCompleteList Redux list of Trunk
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns Updated list of trunks by logging or redux list if network is not reachable
   */
  static async getTrunksByDeliverySheet(
    rdxTrunkCompleteList: Trunk[],
    requestParameters: GetTrunksByDeliverySheetRequest,
    initOverrides?: RequestInit
  ): Promise<Array<Trunk>> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk) return customTrunkResourceApi.getTrunksByDeliverySheet(requestParameters, initOverrides);
    let trunkList =  rdxTrunkCompleteList.filter(trunk => trunk.deliverySheet?.id == requestParameters.deliverySheetId);
    return sortOfflineList(trunkList, requestParameters)
  }

  /**
   * Create a Trunk
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param barkDiscountList List of trunk bark discount
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async createTrunkCustom(
    rdxOfflineSyncList,
    barkDiscountList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: CreateTrunkCustomRequest,
    initOverrides?: RequestInit
  ): Promise<Trunk> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customTrunkResourceApi.createTrunkCustom(requestParameters, initOverrides);
    requestParameters.trunk!.id = DateHelper.nowTimestamp();
    requestParameters.trunk.volume = calculateTrunkVolume(requestParameters.trunk, barkDiscountList);
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.trunk,
        type: OfflineSyncTypeEnum.INSERT,
        class: OfflineSyncClassEnum.TRUNK,
        name: requestParameters.trunk.trunkNumberFAS
      })
    );
    dispatch(setTrunkList([requestParameters.trunk]));
    return requestParameters.trunk;
  }

  /**
   * Update a Trunk
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param barkDiscountList List of trunk bark discount
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async updateTrunkCustom(
    rdxOfflineSyncList,
    barkDiscountList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: UpdateTrunkCustomRequest,
    initOverrides?: RequestInit
  ): Promise<Trunk> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customTrunkResourceApi.updateTrunkCustom(requestParameters, initOverrides);
    requestParameters.trunk.volume = calculateTrunkVolume(requestParameters.trunk, barkDiscountList);
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.trunk,
        type: OfflineSyncTypeEnum.UPDATE,
        class: OfflineSyncClassEnum.TRUNK,
        name: requestParameters.trunk.trunkNumberFAS
      })
    );
    dispatch(setTrunkList([requestParameters.trunk]));
    return requestParameters.trunk;
  }
}
