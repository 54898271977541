/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Batch,
} from '../models';
import {
    BatchFromJSON,
    BatchToJSON,
} from '../models';

export interface CountBatchesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    uniqueIdentifierContains?: string;
    uniqueIdentifierDoesNotContain?: string;
    uniqueIdentifierEquals?: string;
    uniqueIdentifierNotEquals?: string;
    uniqueIdentifierSpecified?: boolean;
    uniqueIdentifierIn?: Array<string>;
    uniqueIdentifierNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    recordingTypeEquals?: CountBatchesRecordingTypeEqualsEnum;
    recordingTypeNotEquals?: CountBatchesRecordingTypeNotEqualsEnum;
    recordingTypeSpecified?: boolean;
    recordingTypeIn?: Array<CountBatchesRecordingTypeInEnum>;
    recordingTypeNotIn?: Array<CountBatchesRecordingTypeNotInEnum>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    currentStateEquals?: CountBatchesCurrentStateEqualsEnum;
    currentStateNotEquals?: CountBatchesCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<CountBatchesCurrentStateInEnum>;
    currentStateNotIn?: Array<CountBatchesCurrentStateNotInEnum>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    sawmillIdGreaterThan?: number;
    sawmillIdLessThan?: number;
    sawmillIdGreaterThanOrEqual?: number;
    sawmillIdLessThanOrEqual?: number;
    sawmillIdEquals?: number;
    sawmillIdNotEquals?: number;
    sawmillIdSpecified?: boolean;
    sawmillIdIn?: Array<number>;
    sawmillIdNotIn?: Array<number>;
    deliverySheetIdGreaterThan?: number;
    deliverySheetIdLessThan?: number;
    deliverySheetIdGreaterThanOrEqual?: number;
    deliverySheetIdLessThanOrEqual?: number;
    deliverySheetIdEquals?: number;
    deliverySheetIdNotEquals?: number;
    deliverySheetIdSpecified?: boolean;
    deliverySheetIdIn?: Array<number>;
    deliverySheetIdNotIn?: Array<number>;
    batchStateIdGreaterThan?: number;
    batchStateIdLessThan?: number;
    batchStateIdGreaterThanOrEqual?: number;
    batchStateIdLessThanOrEqual?: number;
    batchStateIdEquals?: number;
    batchStateIdNotEquals?: number;
    batchStateIdSpecified?: boolean;
    batchStateIdIn?: Array<number>;
    batchStateIdNotIn?: Array<number>;
    packetIdGreaterThan?: number;
    packetIdLessThan?: number;
    packetIdGreaterThanOrEqual?: number;
    packetIdLessThanOrEqual?: number;
    packetIdEquals?: number;
    packetIdNotEquals?: number;
    packetIdSpecified?: boolean;
    packetIdIn?: Array<number>;
    packetIdNotIn?: Array<number>;
    shippingNoteIdGreaterThan?: number;
    shippingNoteIdLessThan?: number;
    shippingNoteIdGreaterThanOrEqual?: number;
    shippingNoteIdLessThanOrEqual?: number;
    shippingNoteIdEquals?: number;
    shippingNoteIdNotEquals?: number;
    shippingNoteIdSpecified?: boolean;
    shippingNoteIdIn?: Array<number>;
    shippingNoteIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateBatchRequest {
    batch: Batch;
}

export interface DeleteBatchRequest {
    id: number;
}

export interface GetAllBatchesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    uniqueIdentifierContains?: string;
    uniqueIdentifierDoesNotContain?: string;
    uniqueIdentifierEquals?: string;
    uniqueIdentifierNotEquals?: string;
    uniqueIdentifierSpecified?: boolean;
    uniqueIdentifierIn?: Array<string>;
    uniqueIdentifierNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    recordingTypeEquals?: GetAllBatchesRecordingTypeEqualsEnum;
    recordingTypeNotEquals?: GetAllBatchesRecordingTypeNotEqualsEnum;
    recordingTypeSpecified?: boolean;
    recordingTypeIn?: Array<GetAllBatchesRecordingTypeInEnum>;
    recordingTypeNotIn?: Array<GetAllBatchesRecordingTypeNotInEnum>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    currentStateEquals?: GetAllBatchesCurrentStateEqualsEnum;
    currentStateNotEquals?: GetAllBatchesCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<GetAllBatchesCurrentStateInEnum>;
    currentStateNotIn?: Array<GetAllBatchesCurrentStateNotInEnum>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    sawmillIdGreaterThan?: number;
    sawmillIdLessThan?: number;
    sawmillIdGreaterThanOrEqual?: number;
    sawmillIdLessThanOrEqual?: number;
    sawmillIdEquals?: number;
    sawmillIdNotEquals?: number;
    sawmillIdSpecified?: boolean;
    sawmillIdIn?: Array<number>;
    sawmillIdNotIn?: Array<number>;
    deliverySheetIdGreaterThan?: number;
    deliverySheetIdLessThan?: number;
    deliverySheetIdGreaterThanOrEqual?: number;
    deliverySheetIdLessThanOrEqual?: number;
    deliverySheetIdEquals?: number;
    deliverySheetIdNotEquals?: number;
    deliverySheetIdSpecified?: boolean;
    deliverySheetIdIn?: Array<number>;
    deliverySheetIdNotIn?: Array<number>;
    batchStateIdGreaterThan?: number;
    batchStateIdLessThan?: number;
    batchStateIdGreaterThanOrEqual?: number;
    batchStateIdLessThanOrEqual?: number;
    batchStateIdEquals?: number;
    batchStateIdNotEquals?: number;
    batchStateIdSpecified?: boolean;
    batchStateIdIn?: Array<number>;
    batchStateIdNotIn?: Array<number>;
    packetIdGreaterThan?: number;
    packetIdLessThan?: number;
    packetIdGreaterThanOrEqual?: number;
    packetIdLessThanOrEqual?: number;
    packetIdEquals?: number;
    packetIdNotEquals?: number;
    packetIdSpecified?: boolean;
    packetIdIn?: Array<number>;
    packetIdNotIn?: Array<number>;
    shippingNoteIdGreaterThan?: number;
    shippingNoteIdLessThan?: number;
    shippingNoteIdGreaterThanOrEqual?: number;
    shippingNoteIdLessThanOrEqual?: number;
    shippingNoteIdEquals?: number;
    shippingNoteIdNotEquals?: number;
    shippingNoteIdSpecified?: boolean;
    shippingNoteIdIn?: Array<number>;
    shippingNoteIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetBatchRequest {
    id: number;
}

export interface PartialUpdateBatchRequest {
    id: number;
    batch: Batch;
}

export interface UpdateBatchRequest {
    id: number;
    batch: Batch;
}

/**
 * 
 */
export class BatchResourceApi extends runtime.BaseAPI {

    /**
     */
    async countBatchesRaw(requestParameters: CountBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.uniqueIdentifierContains !== undefined) {
            queryParameters['uniqueIdentifier.contains'] = requestParameters.uniqueIdentifierContains;
        }

        if (requestParameters.uniqueIdentifierDoesNotContain !== undefined) {
            queryParameters['uniqueIdentifier.doesNotContain'] = requestParameters.uniqueIdentifierDoesNotContain;
        }

        if (requestParameters.uniqueIdentifierEquals !== undefined) {
            queryParameters['uniqueIdentifier.equals'] = requestParameters.uniqueIdentifierEquals;
        }

        if (requestParameters.uniqueIdentifierNotEquals !== undefined) {
            queryParameters['uniqueIdentifier.notEquals'] = requestParameters.uniqueIdentifierNotEquals;
        }

        if (requestParameters.uniqueIdentifierSpecified !== undefined) {
            queryParameters['uniqueIdentifier.specified'] = requestParameters.uniqueIdentifierSpecified;
        }

        if (requestParameters.uniqueIdentifierIn) {
            queryParameters['uniqueIdentifier.in'] = requestParameters.uniqueIdentifierIn;
        }

        if (requestParameters.uniqueIdentifierNotIn) {
            queryParameters['uniqueIdentifier.notIn'] = requestParameters.uniqueIdentifierNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.recordingTypeEquals !== undefined) {
            queryParameters['recordingType.equals'] = requestParameters.recordingTypeEquals;
        }

        if (requestParameters.recordingTypeNotEquals !== undefined) {
            queryParameters['recordingType.notEquals'] = requestParameters.recordingTypeNotEquals;
        }

        if (requestParameters.recordingTypeSpecified !== undefined) {
            queryParameters['recordingType.specified'] = requestParameters.recordingTypeSpecified;
        }

        if (requestParameters.recordingTypeIn) {
            queryParameters['recordingType.in'] = requestParameters.recordingTypeIn;
        }

        if (requestParameters.recordingTypeNotIn) {
            queryParameters['recordingType.notIn'] = requestParameters.recordingTypeNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.sawmillIdGreaterThan !== undefined) {
            queryParameters['sawmillId.greaterThan'] = requestParameters.sawmillIdGreaterThan;
        }

        if (requestParameters.sawmillIdLessThan !== undefined) {
            queryParameters['sawmillId.lessThan'] = requestParameters.sawmillIdLessThan;
        }

        if (requestParameters.sawmillIdGreaterThanOrEqual !== undefined) {
            queryParameters['sawmillId.greaterThanOrEqual'] = requestParameters.sawmillIdGreaterThanOrEqual;
        }

        if (requestParameters.sawmillIdLessThanOrEqual !== undefined) {
            queryParameters['sawmillId.lessThanOrEqual'] = requestParameters.sawmillIdLessThanOrEqual;
        }

        if (requestParameters.sawmillIdEquals !== undefined) {
            queryParameters['sawmillId.equals'] = requestParameters.sawmillIdEquals;
        }

        if (requestParameters.sawmillIdNotEquals !== undefined) {
            queryParameters['sawmillId.notEquals'] = requestParameters.sawmillIdNotEquals;
        }

        if (requestParameters.sawmillIdSpecified !== undefined) {
            queryParameters['sawmillId.specified'] = requestParameters.sawmillIdSpecified;
        }

        if (requestParameters.sawmillIdIn) {
            queryParameters['sawmillId.in'] = requestParameters.sawmillIdIn;
        }

        if (requestParameters.sawmillIdNotIn) {
            queryParameters['sawmillId.notIn'] = requestParameters.sawmillIdNotIn;
        }

        if (requestParameters.deliverySheetIdGreaterThan !== undefined) {
            queryParameters['deliverySheetId.greaterThan'] = requestParameters.deliverySheetIdGreaterThan;
        }

        if (requestParameters.deliverySheetIdLessThan !== undefined) {
            queryParameters['deliverySheetId.lessThan'] = requestParameters.deliverySheetIdLessThan;
        }

        if (requestParameters.deliverySheetIdGreaterThanOrEqual !== undefined) {
            queryParameters['deliverySheetId.greaterThanOrEqual'] = requestParameters.deliverySheetIdGreaterThanOrEqual;
        }

        if (requestParameters.deliverySheetIdLessThanOrEqual !== undefined) {
            queryParameters['deliverySheetId.lessThanOrEqual'] = requestParameters.deliverySheetIdLessThanOrEqual;
        }

        if (requestParameters.deliverySheetIdEquals !== undefined) {
            queryParameters['deliverySheetId.equals'] = requestParameters.deliverySheetIdEquals;
        }

        if (requestParameters.deliverySheetIdNotEquals !== undefined) {
            queryParameters['deliverySheetId.notEquals'] = requestParameters.deliverySheetIdNotEquals;
        }

        if (requestParameters.deliverySheetIdSpecified !== undefined) {
            queryParameters['deliverySheetId.specified'] = requestParameters.deliverySheetIdSpecified;
        }

        if (requestParameters.deliverySheetIdIn) {
            queryParameters['deliverySheetId.in'] = requestParameters.deliverySheetIdIn;
        }

        if (requestParameters.deliverySheetIdNotIn) {
            queryParameters['deliverySheetId.notIn'] = requestParameters.deliverySheetIdNotIn;
        }

        if (requestParameters.batchStateIdGreaterThan !== undefined) {
            queryParameters['batchStateId.greaterThan'] = requestParameters.batchStateIdGreaterThan;
        }

        if (requestParameters.batchStateIdLessThan !== undefined) {
            queryParameters['batchStateId.lessThan'] = requestParameters.batchStateIdLessThan;
        }

        if (requestParameters.batchStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['batchStateId.greaterThanOrEqual'] = requestParameters.batchStateIdGreaterThanOrEqual;
        }

        if (requestParameters.batchStateIdLessThanOrEqual !== undefined) {
            queryParameters['batchStateId.lessThanOrEqual'] = requestParameters.batchStateIdLessThanOrEqual;
        }

        if (requestParameters.batchStateIdEquals !== undefined) {
            queryParameters['batchStateId.equals'] = requestParameters.batchStateIdEquals;
        }

        if (requestParameters.batchStateIdNotEquals !== undefined) {
            queryParameters['batchStateId.notEquals'] = requestParameters.batchStateIdNotEquals;
        }

        if (requestParameters.batchStateIdSpecified !== undefined) {
            queryParameters['batchStateId.specified'] = requestParameters.batchStateIdSpecified;
        }

        if (requestParameters.batchStateIdIn) {
            queryParameters['batchStateId.in'] = requestParameters.batchStateIdIn;
        }

        if (requestParameters.batchStateIdNotIn) {
            queryParameters['batchStateId.notIn'] = requestParameters.batchStateIdNotIn;
        }

        if (requestParameters.packetIdGreaterThan !== undefined) {
            queryParameters['packetId.greaterThan'] = requestParameters.packetIdGreaterThan;
        }

        if (requestParameters.packetIdLessThan !== undefined) {
            queryParameters['packetId.lessThan'] = requestParameters.packetIdLessThan;
        }

        if (requestParameters.packetIdGreaterThanOrEqual !== undefined) {
            queryParameters['packetId.greaterThanOrEqual'] = requestParameters.packetIdGreaterThanOrEqual;
        }

        if (requestParameters.packetIdLessThanOrEqual !== undefined) {
            queryParameters['packetId.lessThanOrEqual'] = requestParameters.packetIdLessThanOrEqual;
        }

        if (requestParameters.packetIdEquals !== undefined) {
            queryParameters['packetId.equals'] = requestParameters.packetIdEquals;
        }

        if (requestParameters.packetIdNotEquals !== undefined) {
            queryParameters['packetId.notEquals'] = requestParameters.packetIdNotEquals;
        }

        if (requestParameters.packetIdSpecified !== undefined) {
            queryParameters['packetId.specified'] = requestParameters.packetIdSpecified;
        }

        if (requestParameters.packetIdIn) {
            queryParameters['packetId.in'] = requestParameters.packetIdIn;
        }

        if (requestParameters.packetIdNotIn) {
            queryParameters['packetId.notIn'] = requestParameters.packetIdNotIn;
        }

        if (requestParameters.shippingNoteIdGreaterThan !== undefined) {
            queryParameters['shippingNoteId.greaterThan'] = requestParameters.shippingNoteIdGreaterThan;
        }

        if (requestParameters.shippingNoteIdLessThan !== undefined) {
            queryParameters['shippingNoteId.lessThan'] = requestParameters.shippingNoteIdLessThan;
        }

        if (requestParameters.shippingNoteIdGreaterThanOrEqual !== undefined) {
            queryParameters['shippingNoteId.greaterThanOrEqual'] = requestParameters.shippingNoteIdGreaterThanOrEqual;
        }

        if (requestParameters.shippingNoteIdLessThanOrEqual !== undefined) {
            queryParameters['shippingNoteId.lessThanOrEqual'] = requestParameters.shippingNoteIdLessThanOrEqual;
        }

        if (requestParameters.shippingNoteIdEquals !== undefined) {
            queryParameters['shippingNoteId.equals'] = requestParameters.shippingNoteIdEquals;
        }

        if (requestParameters.shippingNoteIdNotEquals !== undefined) {
            queryParameters['shippingNoteId.notEquals'] = requestParameters.shippingNoteIdNotEquals;
        }

        if (requestParameters.shippingNoteIdSpecified !== undefined) {
            queryParameters['shippingNoteId.specified'] = requestParameters.shippingNoteIdSpecified;
        }

        if (requestParameters.shippingNoteIdIn) {
            queryParameters['shippingNoteId.in'] = requestParameters.shippingNoteIdIn;
        }

        if (requestParameters.shippingNoteIdNotIn) {
            queryParameters['shippingNoteId.notIn'] = requestParameters.shippingNoteIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/batches/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countBatches(requestParameters: CountBatchesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createBatchRaw(requestParameters: CreateBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Batch>> {
        if (requestParameters.batch === null || requestParameters.batch === undefined) {
            throw new runtime.RequiredError('batch','Required parameter requestParameters.batch was null or undefined when calling createBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/batches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BatchToJSON(requestParameters.batch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchFromJSON(jsonValue));
    }

    /**
     */
    async createBatch(requestParameters: CreateBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Batch> {
        const response = await this.createBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteBatchRaw(requestParameters: DeleteBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteBatch(requestParameters: DeleteBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllBatchesRaw(requestParameters: GetAllBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Batch>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.uniqueIdentifierContains !== undefined) {
            queryParameters['uniqueIdentifier.contains'] = requestParameters.uniqueIdentifierContains;
        }

        if (requestParameters.uniqueIdentifierDoesNotContain !== undefined) {
            queryParameters['uniqueIdentifier.doesNotContain'] = requestParameters.uniqueIdentifierDoesNotContain;
        }

        if (requestParameters.uniqueIdentifierEquals !== undefined) {
            queryParameters['uniqueIdentifier.equals'] = requestParameters.uniqueIdentifierEquals;
        }

        if (requestParameters.uniqueIdentifierNotEquals !== undefined) {
            queryParameters['uniqueIdentifier.notEquals'] = requestParameters.uniqueIdentifierNotEquals;
        }

        if (requestParameters.uniqueIdentifierSpecified !== undefined) {
            queryParameters['uniqueIdentifier.specified'] = requestParameters.uniqueIdentifierSpecified;
        }

        if (requestParameters.uniqueIdentifierIn) {
            queryParameters['uniqueIdentifier.in'] = requestParameters.uniqueIdentifierIn;
        }

        if (requestParameters.uniqueIdentifierNotIn) {
            queryParameters['uniqueIdentifier.notIn'] = requestParameters.uniqueIdentifierNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.recordingTypeEquals !== undefined) {
            queryParameters['recordingType.equals'] = requestParameters.recordingTypeEquals;
        }

        if (requestParameters.recordingTypeNotEquals !== undefined) {
            queryParameters['recordingType.notEquals'] = requestParameters.recordingTypeNotEquals;
        }

        if (requestParameters.recordingTypeSpecified !== undefined) {
            queryParameters['recordingType.specified'] = requestParameters.recordingTypeSpecified;
        }

        if (requestParameters.recordingTypeIn) {
            queryParameters['recordingType.in'] = requestParameters.recordingTypeIn;
        }

        if (requestParameters.recordingTypeNotIn) {
            queryParameters['recordingType.notIn'] = requestParameters.recordingTypeNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.sawmillIdGreaterThan !== undefined) {
            queryParameters['sawmillId.greaterThan'] = requestParameters.sawmillIdGreaterThan;
        }

        if (requestParameters.sawmillIdLessThan !== undefined) {
            queryParameters['sawmillId.lessThan'] = requestParameters.sawmillIdLessThan;
        }

        if (requestParameters.sawmillIdGreaterThanOrEqual !== undefined) {
            queryParameters['sawmillId.greaterThanOrEqual'] = requestParameters.sawmillIdGreaterThanOrEqual;
        }

        if (requestParameters.sawmillIdLessThanOrEqual !== undefined) {
            queryParameters['sawmillId.lessThanOrEqual'] = requestParameters.sawmillIdLessThanOrEqual;
        }

        if (requestParameters.sawmillIdEquals !== undefined) {
            queryParameters['sawmillId.equals'] = requestParameters.sawmillIdEquals;
        }

        if (requestParameters.sawmillIdNotEquals !== undefined) {
            queryParameters['sawmillId.notEquals'] = requestParameters.sawmillIdNotEquals;
        }

        if (requestParameters.sawmillIdSpecified !== undefined) {
            queryParameters['sawmillId.specified'] = requestParameters.sawmillIdSpecified;
        }

        if (requestParameters.sawmillIdIn) {
            queryParameters['sawmillId.in'] = requestParameters.sawmillIdIn;
        }

        if (requestParameters.sawmillIdNotIn) {
            queryParameters['sawmillId.notIn'] = requestParameters.sawmillIdNotIn;
        }

        if (requestParameters.deliverySheetIdGreaterThan !== undefined) {
            queryParameters['deliverySheetId.greaterThan'] = requestParameters.deliverySheetIdGreaterThan;
        }

        if (requestParameters.deliverySheetIdLessThan !== undefined) {
            queryParameters['deliverySheetId.lessThan'] = requestParameters.deliverySheetIdLessThan;
        }

        if (requestParameters.deliverySheetIdGreaterThanOrEqual !== undefined) {
            queryParameters['deliverySheetId.greaterThanOrEqual'] = requestParameters.deliverySheetIdGreaterThanOrEqual;
        }

        if (requestParameters.deliverySheetIdLessThanOrEqual !== undefined) {
            queryParameters['deliverySheetId.lessThanOrEqual'] = requestParameters.deliverySheetIdLessThanOrEqual;
        }

        if (requestParameters.deliverySheetIdEquals !== undefined) {
            queryParameters['deliverySheetId.equals'] = requestParameters.deliverySheetIdEquals;
        }

        if (requestParameters.deliverySheetIdNotEquals !== undefined) {
            queryParameters['deliverySheetId.notEquals'] = requestParameters.deliverySheetIdNotEquals;
        }

        if (requestParameters.deliverySheetIdSpecified !== undefined) {
            queryParameters['deliverySheetId.specified'] = requestParameters.deliverySheetIdSpecified;
        }

        if (requestParameters.deliverySheetIdIn) {
            queryParameters['deliverySheetId.in'] = requestParameters.deliverySheetIdIn;
        }

        if (requestParameters.deliverySheetIdNotIn) {
            queryParameters['deliverySheetId.notIn'] = requestParameters.deliverySheetIdNotIn;
        }

        if (requestParameters.batchStateIdGreaterThan !== undefined) {
            queryParameters['batchStateId.greaterThan'] = requestParameters.batchStateIdGreaterThan;
        }

        if (requestParameters.batchStateIdLessThan !== undefined) {
            queryParameters['batchStateId.lessThan'] = requestParameters.batchStateIdLessThan;
        }

        if (requestParameters.batchStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['batchStateId.greaterThanOrEqual'] = requestParameters.batchStateIdGreaterThanOrEqual;
        }

        if (requestParameters.batchStateIdLessThanOrEqual !== undefined) {
            queryParameters['batchStateId.lessThanOrEqual'] = requestParameters.batchStateIdLessThanOrEqual;
        }

        if (requestParameters.batchStateIdEquals !== undefined) {
            queryParameters['batchStateId.equals'] = requestParameters.batchStateIdEquals;
        }

        if (requestParameters.batchStateIdNotEquals !== undefined) {
            queryParameters['batchStateId.notEquals'] = requestParameters.batchStateIdNotEquals;
        }

        if (requestParameters.batchStateIdSpecified !== undefined) {
            queryParameters['batchStateId.specified'] = requestParameters.batchStateIdSpecified;
        }

        if (requestParameters.batchStateIdIn) {
            queryParameters['batchStateId.in'] = requestParameters.batchStateIdIn;
        }

        if (requestParameters.batchStateIdNotIn) {
            queryParameters['batchStateId.notIn'] = requestParameters.batchStateIdNotIn;
        }

        if (requestParameters.packetIdGreaterThan !== undefined) {
            queryParameters['packetId.greaterThan'] = requestParameters.packetIdGreaterThan;
        }

        if (requestParameters.packetIdLessThan !== undefined) {
            queryParameters['packetId.lessThan'] = requestParameters.packetIdLessThan;
        }

        if (requestParameters.packetIdGreaterThanOrEqual !== undefined) {
            queryParameters['packetId.greaterThanOrEqual'] = requestParameters.packetIdGreaterThanOrEqual;
        }

        if (requestParameters.packetIdLessThanOrEqual !== undefined) {
            queryParameters['packetId.lessThanOrEqual'] = requestParameters.packetIdLessThanOrEqual;
        }

        if (requestParameters.packetIdEquals !== undefined) {
            queryParameters['packetId.equals'] = requestParameters.packetIdEquals;
        }

        if (requestParameters.packetIdNotEquals !== undefined) {
            queryParameters['packetId.notEquals'] = requestParameters.packetIdNotEquals;
        }

        if (requestParameters.packetIdSpecified !== undefined) {
            queryParameters['packetId.specified'] = requestParameters.packetIdSpecified;
        }

        if (requestParameters.packetIdIn) {
            queryParameters['packetId.in'] = requestParameters.packetIdIn;
        }

        if (requestParameters.packetIdNotIn) {
            queryParameters['packetId.notIn'] = requestParameters.packetIdNotIn;
        }

        if (requestParameters.shippingNoteIdGreaterThan !== undefined) {
            queryParameters['shippingNoteId.greaterThan'] = requestParameters.shippingNoteIdGreaterThan;
        }

        if (requestParameters.shippingNoteIdLessThan !== undefined) {
            queryParameters['shippingNoteId.lessThan'] = requestParameters.shippingNoteIdLessThan;
        }

        if (requestParameters.shippingNoteIdGreaterThanOrEqual !== undefined) {
            queryParameters['shippingNoteId.greaterThanOrEqual'] = requestParameters.shippingNoteIdGreaterThanOrEqual;
        }

        if (requestParameters.shippingNoteIdLessThanOrEqual !== undefined) {
            queryParameters['shippingNoteId.lessThanOrEqual'] = requestParameters.shippingNoteIdLessThanOrEqual;
        }

        if (requestParameters.shippingNoteIdEquals !== undefined) {
            queryParameters['shippingNoteId.equals'] = requestParameters.shippingNoteIdEquals;
        }

        if (requestParameters.shippingNoteIdNotEquals !== undefined) {
            queryParameters['shippingNoteId.notEquals'] = requestParameters.shippingNoteIdNotEquals;
        }

        if (requestParameters.shippingNoteIdSpecified !== undefined) {
            queryParameters['shippingNoteId.specified'] = requestParameters.shippingNoteIdSpecified;
        }

        if (requestParameters.shippingNoteIdIn) {
            queryParameters['shippingNoteId.in'] = requestParameters.shippingNoteIdIn;
        }

        if (requestParameters.shippingNoteIdNotIn) {
            queryParameters['shippingNoteId.notIn'] = requestParameters.shippingNoteIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/batches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BatchFromJSON));
    }

    /**
     */
    async getAllBatches(requestParameters: GetAllBatchesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Batch>> {
        const response = await this.getAllBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBatchRaw(requestParameters: GetBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Batch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchFromJSON(jsonValue));
    }

    /**
     */
    async getBatch(requestParameters: GetBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Batch> {
        const response = await this.getBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateBatchRaw(requestParameters: PartialUpdateBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Batch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateBatch.');
        }

        if (requestParameters.batch === null || requestParameters.batch === undefined) {
            throw new runtime.RequiredError('batch','Required parameter requestParameters.batch was null or undefined when calling partialUpdateBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BatchToJSON(requestParameters.batch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateBatch(requestParameters: PartialUpdateBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Batch> {
        const response = await this.partialUpdateBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateBatchRaw(requestParameters: UpdateBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Batch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBatch.');
        }

        if (requestParameters.batch === null || requestParameters.batch === undefined) {
            throw new runtime.RequiredError('batch','Required parameter requestParameters.batch was null or undefined when calling updateBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BatchToJSON(requestParameters.batch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchFromJSON(jsonValue));
    }

    /**
     */
    async updateBatch(requestParameters: UpdateBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Batch> {
        const response = await this.updateBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CountBatchesRecordingTypeEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountBatchesRecordingTypeEqualsEnum = typeof CountBatchesRecordingTypeEqualsEnum[keyof typeof CountBatchesRecordingTypeEqualsEnum];
/**
 * @export
 */
export const CountBatchesRecordingTypeNotEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountBatchesRecordingTypeNotEqualsEnum = typeof CountBatchesRecordingTypeNotEqualsEnum[keyof typeof CountBatchesRecordingTypeNotEqualsEnum];
/**
 * @export
 */
export const CountBatchesRecordingTypeInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountBatchesRecordingTypeInEnum = typeof CountBatchesRecordingTypeInEnum[keyof typeof CountBatchesRecordingTypeInEnum];
/**
 * @export
 */
export const CountBatchesRecordingTypeNotInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountBatchesRecordingTypeNotInEnum = typeof CountBatchesRecordingTypeNotInEnum[keyof typeof CountBatchesRecordingTypeNotInEnum];
/**
 * @export
 */
export const CountBatchesCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountBatchesCurrentStateEqualsEnum = typeof CountBatchesCurrentStateEqualsEnum[keyof typeof CountBatchesCurrentStateEqualsEnum];
/**
 * @export
 */
export const CountBatchesCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountBatchesCurrentStateNotEqualsEnum = typeof CountBatchesCurrentStateNotEqualsEnum[keyof typeof CountBatchesCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const CountBatchesCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED',
    SelectedForShippingNote: 'SELECTED_FOR_SHIPPING_NOTE',
} as const;
export type CountBatchesCurrentStateInEnum = typeof CountBatchesCurrentStateInEnum[keyof typeof CountBatchesCurrentStateInEnum];
/**
 * @export
 */
export const CountBatchesCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountBatchesCurrentStateNotInEnum = typeof CountBatchesCurrentStateNotInEnum[keyof typeof CountBatchesCurrentStateNotInEnum];
/**
 * @export
 */
export const GetAllBatchesRecordingTypeEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllBatchesRecordingTypeEqualsEnum = typeof GetAllBatchesRecordingTypeEqualsEnum[keyof typeof GetAllBatchesRecordingTypeEqualsEnum];
/**
 * @export
 */
export const GetAllBatchesRecordingTypeNotEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllBatchesRecordingTypeNotEqualsEnum = typeof GetAllBatchesRecordingTypeNotEqualsEnum[keyof typeof GetAllBatchesRecordingTypeNotEqualsEnum];
/**
 * @export
 */
export const GetAllBatchesRecordingTypeInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllBatchesRecordingTypeInEnum = typeof GetAllBatchesRecordingTypeInEnum[keyof typeof GetAllBatchesRecordingTypeInEnum];
/**
 * @export
 */
export const GetAllBatchesRecordingTypeNotInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllBatchesRecordingTypeNotInEnum = typeof GetAllBatchesRecordingTypeNotInEnum[keyof typeof GetAllBatchesRecordingTypeNotInEnum];
/**
 * @export
 */
export const GetAllBatchesCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllBatchesCurrentStateEqualsEnum = typeof GetAllBatchesCurrentStateEqualsEnum[keyof typeof GetAllBatchesCurrentStateEqualsEnum];
/**
 * @export
 */
export const GetAllBatchesCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllBatchesCurrentStateNotEqualsEnum = typeof GetAllBatchesCurrentStateNotEqualsEnum[keyof typeof GetAllBatchesCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const GetAllBatchesCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllBatchesCurrentStateInEnum = typeof GetAllBatchesCurrentStateInEnum[keyof typeof GetAllBatchesCurrentStateInEnum];
/**
 * @export
 */
export const GetAllBatchesCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllBatchesCurrentStateNotInEnum = typeof GetAllBatchesCurrentStateNotInEnum[keyof typeof GetAllBatchesCurrentStateNotInEnum];
