import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { UrstammStyle } from '../../../utils/styles/UrstammStyle';
import UrstammIcon, { UrstammIconProps } from '../icon/UrstammIcon';

export default function UrstammSelect(props: {
  style?: StyleProp<ViewStyle>;
  selectStyle?: StyleProp<ViewStyle>;
  fontStyle?: StyleProp<TextStyle>;
  placeholder?: string;
  testID: string;
  icon: UrstammIconProps;
  list: string[];
}) {
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [dataSelected, setDataSelected] = React.useState<string>('');

  return (
    <View style={UrstammStyle.flex1}>
      <View style={[props.selectStyle, UrstammStyle.baseShadow]}>
        {props.icon.showIcon && <UrstammIcon name={props.icon.iconName} size={24} color={props.icon.iconColor!} />}
        <View style={styles.selectMainContainer}>
          <TouchableOpacity style={styles.selectCollapsible} onPress={() => setCollapsed(!collapsed)}>
            <Text style={props.fontStyle}>{dataSelected}</Text>
          </TouchableOpacity>
          <UrstammIcon name={collapsed ? 'angle-down' : 'angle-up'} size={24} color={props.icon.dropIconColor!} />
        </View>
      </View>

      <View style={[{ marginTop: 4 }]}>
        <Collapsible duration={500} easing={'easeInBounce'} collapsed={collapsed} style={styles.containerCollapsible}>
          {props.list &&
            props.list.map((selected, key) => {
              return (
                <TouchableOpacity
                  key={key}
                  style={styles.optionStyle}
                  onPress={() => {
                    setDataSelected(selected);
                    setCollapsed(!collapsed);
                  }}>
                  <Text style={props.fontStyle}>{selected}</Text>
                </TouchableOpacity>
              );
            })}
        </Collapsible>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  iconContainer: {
    paddingRight: 14
  },
  inputRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  selectMainContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  selectCollapsible: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 8,
    backgroundColor: 'transparent'
  },
  containerCollapsible: {
    backgroundColor: 'white',
    borderWidth: 1,
    padding: 12,
    height: 'auto'
  },
  optionStyle: {
    paddingVertical: 8
  }
});
