import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { Project } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface ProjectState {
    list: Project[];
    completeList: Project[];
    page: number;
    totalPages: number;
    sortBy: ButtonSelect[];
}

const initialState: ProjectState = {
    list: [],
    completeList: [],
    page: 0,
    totalPages: 0,
    sortBy: []
};

export const project = createSlice({
    name: 'projectList',
    initialState,
    reducers: {
        setProjectList: (state, action) => {
            let projectList = { ...state }.list;
            let projectCompleteList = { ...state }.completeList;

            action.payload.forEach((element: any) => {
                element = clearElementForRedux(element, true);
                pushElementOrReplaceById(projectList, element);
                pushElementOrReplaceById(projectCompleteList, element);
            });

            state.list = projectList;
            state.completeList = projectCompleteList;
        },
        setProjectTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        setProjectListSort: (state, action) => {
            const currentCompleteList = state.completeList;
            Object.assign(state, initialState);
            state.completeList = currentCompleteList || [];
            state.sortBy = action.payload;
        },
        setProjectPage: (state, action) => {
            state.page = action.payload;
        },
        resetProjectList: state => {
            const currentCompleteList = state.completeList;
            Object.assign(state, initialState);
            state.completeList = currentCompleteList || [];
        },
        clearProjectList: state => {
            Object.assign(state, initialState);
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setProjectList,
    setProjectListSort,
    resetProjectList,
    setProjectTotalPages,
    setProjectPage,
    clearProjectList
} = project.actions;

export default project.reducer;
