/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoggingState,
} from '../models';
import {
    LoggingStateFromJSON,
    LoggingStateToJSON,
} from '../models';

export interface CreateLoggingStateRequest {
    loggingState: LoggingState;
}

export interface DeleteLoggingStateRequest {
    id: number;
}

export interface GetLoggingStateRequest {
    id: number;
}

export interface PartialUpdateLoggingStateRequest {
    id: number;
    loggingState: LoggingState;
}

export interface UpdateLoggingStateRequest {
    id: number;
    loggingState: LoggingState;
}

/**
 * 
 */
export class LoggingStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createLoggingStateRaw(requestParameters: CreateLoggingStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggingState>> {
        if (requestParameters.loggingState === null || requestParameters.loggingState === undefined) {
            throw new runtime.RequiredError('loggingState','Required parameter requestParameters.loggingState was null or undefined when calling createLoggingState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/logging-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoggingStateToJSON(requestParameters.loggingState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingStateFromJSON(jsonValue));
    }

    /**
     */
    async createLoggingState(requestParameters: CreateLoggingStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggingState> {
        const response = await this.createLoggingStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteLoggingStateRaw(requestParameters: DeleteLoggingStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLoggingState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/logging-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteLoggingState(requestParameters: DeleteLoggingStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLoggingStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllLoggingStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LoggingState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/logging-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoggingStateFromJSON));
    }

    /**
     */
    async getAllLoggingStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LoggingState>> {
        const response = await this.getAllLoggingStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getLoggingStateRaw(requestParameters: GetLoggingStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggingState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLoggingState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/logging-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingStateFromJSON(jsonValue));
    }

    /**
     */
    async getLoggingState(requestParameters: GetLoggingStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggingState> {
        const response = await this.getLoggingStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateLoggingStateRaw(requestParameters: PartialUpdateLoggingStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggingState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateLoggingState.');
        }

        if (requestParameters.loggingState === null || requestParameters.loggingState === undefined) {
            throw new runtime.RequiredError('loggingState','Required parameter requestParameters.loggingState was null or undefined when calling partialUpdateLoggingState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/logging-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: LoggingStateToJSON(requestParameters.loggingState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateLoggingState(requestParameters: PartialUpdateLoggingStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggingState> {
        const response = await this.partialUpdateLoggingStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateLoggingStateRaw(requestParameters: UpdateLoggingStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggingState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLoggingState.');
        }

        if (requestParameters.loggingState === null || requestParameters.loggingState === undefined) {
            throw new runtime.RequiredError('loggingState','Required parameter requestParameters.loggingState was null or undefined when calling updateLoggingState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/logging-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LoggingStateToJSON(requestParameters.loggingState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingStateFromJSON(jsonValue));
    }

    /**
     */
    async updateLoggingState(requestParameters: UpdateLoggingStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggingState> {
        const response = await this.updateLoggingStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
