/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Packet } from './Packet';
import {
    PacketFromJSON,
    PacketFromJSONTyped,
    PacketToJSON,
} from './Packet';

/**
 * 
 * @export
 * @interface PacketCertification
 */
export interface PacketCertification {
    /**
     * 
     * @type {number}
     * @memberof PacketCertification
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PacketCertification
     */
    certification?: PacketCertificationCertificationEnum;
    /**
     * 
     * @type {Packet}
     * @memberof PacketCertification
     */
    packet?: Packet;
}


/**
 * @export
 */
export const PacketCertificationCertificationEnum = {
    Urstamm: 'URSTAMM',
    Hsh: 'HSH',
    Fsc: 'FSC',
    Pefc: 'PEFC'
} as const;
export type PacketCertificationCertificationEnum = typeof PacketCertificationCertificationEnum[keyof typeof PacketCertificationCertificationEnum];


/**
 * Check if a given object implements the PacketCertification interface.
 */
export function instanceOfPacketCertification(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PacketCertificationFromJSON(json: any): PacketCertification {
    return PacketCertificationFromJSONTyped(json, false);
}

export function PacketCertificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PacketCertification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'certification': !exists(json, 'certification') ? undefined : json['certification'],
        'packet': !exists(json, 'packet') ? undefined : PacketFromJSON(json['packet']),
    };
}

export function PacketCertificationToJSON(value?: PacketCertification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'certification': value.certification,
        'packet': PacketToJSON(value.packet),
    };
}

