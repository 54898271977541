import { UrstammButtonStyleProps } from '@components/utility-components/button/UrstammButtonBase';
import { ColorTheme } from './ColorTheme';
import { UrstammStyleButton, UrstammStyleModal } from './UrstammStyle';

export const mainGreenButton: UrstammButtonStyleProps = {
  containerViewStyle: UrstammStyleButton.baseButtonStructure,
  textStyle: UrstammStyleButton.baseButtonBWStyle,
  buttonBackground: ColorTheme.mainGreen
};

export const lightGreenButton: UrstammButtonStyleProps = {
  containerViewStyle: UrstammStyleButton.baseButtonStructure,
  textStyle: UrstammStyleButton.baseButtonBWStyle,
  buttonBackground: ColorTheme.lightGreen
};

export const warnButton: UrstammButtonStyleProps = {
  containerViewStyle: UrstammStyleButton.baseButtonStructure,
  textStyle: UrstammStyleButton.baseButtonBWWarnStyle,
  buttonBackground: 'white',
  buttonBorderStyle: UrstammStyleButton.borderWarn
};

export const warnButtonModal: UrstammButtonStyleProps = {
  containerViewStyle: UrstammStyleModal.buttonModal,
  textStyle: UrstammStyleButton.baseButtonBWWarnStyle,
  buttonBackground: 'white',
  buttonBorderStyle: UrstammStyleButton.borderWarn
};

export const mainGreenButtonModal: UrstammButtonStyleProps = {
  containerViewStyle: UrstammStyleModal.buttonModal,
  textStyle: UrstammStyleButton.baseButtonBWStyle,
  buttonBackground: ColorTheme.mainGreen
};

export const lightGreenBorderWhiteBGButton: UrstammButtonStyleProps = {
  containerViewStyle: UrstammStyleButton.baseButtonStructure,
  textStyle: UrstammStyleButton.baseButtonBWLightGreenStyle,
  buttonBorderStyle: UrstammStyleButton.borderLightGreen,
  buttonBackground: 'white'
};

export const lightGreenBorderWhiteBGButtonPD0: UrstammButtonStyleProps = {
  containerViewStyle: UrstammStyleButton.baseButtonStructure,
  textStyle: UrstammStyleButton.baseButtonBWLightGreenStyle,
  buttonBorderStyle: UrstammStyleButton.borderLightGreen,
  buttonBackground: 'white'
};

export const mediumGreyButton: UrstammButtonStyleProps = {
  containerViewStyle: UrstammStyleButton.baseButtonStructure,
  textStyle: UrstammStyleButton.baseButtonBWStyle,
  buttonBackground: ColorTheme.mediumGrey
};

export const mainGreenButtonSelectActive: UrstammButtonStyleProps = {
  buttonBorderStyle: UrstammStyleButton.borderMainGreen,
  containerViewStyle: UrstammStyleButton.baseButtonStructure,
  textStyle: UrstammStyleButton.buttonSelectBWStyle
};
