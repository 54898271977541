import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import UrstammModalSortList from '@components/utility-components/modal/UrstammModalSortList';
import { i18n } from '@i18n/i18n';
import { TrunkListItem } from '@redux/features/trunk/trunkSlice';
import {
  DeliverySheetDTO,
  DeliverySheetDTOCurrentStateEnum,
  DeliverySheetDTOCurrentUrstammStateEnum,
  Trunk,
  TrunkCurrentStateEnum
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { selectTrunkFilter, setTrunkFilterList } from '../../../redux/features/filter/trunkFilterSlice';
import { RootState } from '../../../redux/store';
import {
  getColorByDeliverySheetState,
  getColorByTrunkState,
  getTextByDeliverySheetState,
  getTextByTreeOrTrunkSpecies,
  getTextByTrunkState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  deliverySheetIsInProgress,
  deliverySheetIsValid,
  dsCurrentStatusIsInProgressOrValidOrClosed,
  editTrunkByDsAndTrunkStatus,
  getCardTitleByTrunkNumberFas,
  loggedUserIsASawmill,
  trunkChangedProperties,
  trunkInProgress,
  trunkIsValid
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButtonPD0, mediumGreyButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleLayout,
  UrstammStyleList,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';

export interface TrunkEdited {
  trunk: Trunk;
  switchStatus: boolean;
}

export default function TrunkListView(props: {
  navigation: any;
  trunkList: TrunkListItem[];
  deliverySheetSelected: DeliverySheetDTO;
  moreItems?: () => void;
  trunkSelected: (trunkSelected: Trunk) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
  validate: (validateAllAndIgnoreChanges: boolean) => void;
  validateTrunkSwitched: (trunk: TrunkListItem) => void;
  isValidated: any;
  setIsValidated: any;
}) {
  const [openCloseValidatedChangeState, setOpenCloseValidatedChangeState] = useState<boolean>(false);
  const [openCloseClosedChangeState, setOpenCloseClosedChangeState] = useState<boolean>(false);

  const [validate, setValidate] = useState<boolean>(false);

  const trunkFilter = useSelector((state: RootState) => state.persistedReducer.trunkFilter.value);
  const rdxTrunkSortBy = useSelector((state: RootState) => state.persistedReducer.trunk.sortBy);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const dispatch = useDispatch();

  let keys: ListSubData[] = [
    {
      title: i18n.t('generics.quality') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'quality',
      keyId: '1quality',
      changedKey: 'qualityChanged',
      dataContainerViewStyle: { flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.length') + ' (m):',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'length',
      keyId: '1length',
      changedKey: 'lengthChanged',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.specie') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'species',
      keyId: '1species',
      changedKey: 'speciesChanged',
      // dataContainerViewStyle: { flexBasis: '50%' },
      isEnum: true
    },
    {
      title: i18n.t('generics.net_volume') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'volume',
      keyId: '1netVolume',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.diameter') + ' (cm):',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'diameter',
      keyId: '1diameter',
      changedKey: 'diameterChanged',
      // dataContainerViewStyle: { height: 24 }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('views.tree.tree_registration.photo_number') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'treePhotoNumber',
      keyId: '1treePhotoNumber',
      // dataContainerViewStyle: { flexBasis: '50%' }
    }

    /*
        { title: i18n.t('generics.quality') + ':', titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'quality', keyId: '1quality', changedKey: 'qualityChanged', dataContainerViewStyle: { height: 24, flexBasis: '50%' } },
        { title: i18n.t('generics.length') + ' (m):', titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'length', keyId: '1length', changedKey: 'lengthChanged', dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }, dataContainerViewStyle: { height: 24, flexBasis: '50%' } },
        { title: i18n.t('generics.specie') + ':', titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'species', keyId: '1recordingType', dataContainerViewStyle: { height: 24, flexBasis: '100%' }, isEnum: true },
        { title: i18n.t('generics.diameter') + ' (cm):', titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'diameter', keyId: '1diameter', changedKey: 'diameterChanged', dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }, dataContainerViewStyle: { height: 24, flexBasis: '100%' } },
        { title: i18n.t('utility_components.list.creation'), titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'creationDate', keyId: '1creationDate', dataContainerViewStyle: { height: 24, flexBasis: '50%', marginRight: 4 } },
        { title: i18n.t('views.tree.tree_registration.photo_number') + ':', titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'treePhotoNumber', keyId: '1treePhotoNumber', dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }, dataContainerViewStyle: { height: 24, flexBasis: '50%' } },
         */
  ];

  useEffect(() => {
    getSlideButtonOptionsTrunkCurrentState();
  }, []);

  useEffect(() => {
    if (props.trunkList && props.trunkList.length > 0) {
      disableButtonSendToSawmill();
    }
  }, [props.trunkList]);

  /**
   * Function used for generating filter buttons
   */
  const getSlideButtonOptionsTrunkCurrentState = (): SlideButtonOption[] => {
    let filterList: SlideButtonOption[] = [];
    let type: SlideButtonOption = {
      id: 'ALL',
      text: i18n.t('utility_components.filter_button.all'),
      active: true
    };
    filterList.push(type);
    Object.values(TrunkCurrentStateEnum).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByTrunkState(state),
        active: false
      };
      filterList.push(type);
    });
    dispatch(setTrunkFilterList(filterList));

    return filterList;
  };

  const setTrunkCurrentState = (selected: SlideButtonOption): void => {
    dispatch(selectTrunkFilter(selected));
  };

  const getActiveTrunkCurrentState = () => {
    if (trunkFilter.length > 0) return trunkFilter.filter(val => val.active);
  };

  /**
   * @returns boolean
   */
  const disableButtonSendToSawmill = (): boolean => {
    if (
      !props.deliverySheetSelected.sawmill ||
      props.deliverySheetSelected.currentState == DeliverySheetDTOCurrentStateEnum.Valid ||
      (props.deliverySheetSelected.currentState == DeliverySheetDTOCurrentStateEnum.EditedByForestry &&
        !loggedUserIsASawmill(rdxUserExtendedMe)) ||
      (props.deliverySheetSelected.currentState == DeliverySheetDTOCurrentStateEnum.EditedBySawmill &&
        loggedUserIsASawmill(rdxUserExtendedMe))
    )
      return true;
    return false;
  };

  const disableButtonValidateSendToForestry = (): boolean => {
    if(props.isValidated) return true;
    if (
      !props.deliverySheetSelected.sawmill ||
      props.deliverySheetSelected.currentState == DeliverySheetDTOCurrentStateEnum.Valid ||
      (props.deliverySheetSelected.currentState == DeliverySheetDTOCurrentStateEnum.EditedByForestry &&
        !loggedUserIsASawmill(rdxUserExtendedMe)) ||
      (props.deliverySheetSelected.currentState == DeliverySheetDTOCurrentStateEnum.EditedBySawmill &&
        loggedUserIsASawmill(rdxUserExtendedMe))
    )
      return true;
    return false;
  };

  const disableButtonValidateSendToSawmill = (): boolean => {
    return props.deliverySheetSelected.currentUrstammState != DeliverySheetDTOCurrentUrstammStateEnum.Validated;
  };

  const disableButtonByTrunks = () => {
    return !(
      props.trunkList.length > 0 &&
      props.trunkList
        .map(_ => {
          if (_.edited.status) return true;
          return _.trunk !== _.edited.data;
        })
        .reduce((prev, next) => {
          return prev && next;
        }, true)
    );
  };

  return (
    <View>
      {props.deliverySheetSelected.sentToSawmill && !deliverySheetIsValid(props.deliverySheetSelected.currentState) ? (
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'send_feedback'}
            text={
              loggedUserIsASawmill(rdxUserExtendedMe)
                ? i18n.t('generics.validate_list_and_send_to_forester')
                : i18n.t('generics.validate_list')
            }
            style={lightGreenBorderWhiteBGButtonPD0}
            icon={{
              showIcon: false,
              iconName: 'send',
              iconColor: ColorTheme.lightGreen
            }}
            onSubmit={() => {
              setValidate(true);
              props.setIsValidated(true);
              loggedUserIsASawmill(rdxUserExtendedMe)
                ? setOpenCloseValidatedChangeState(true)
                : setOpenCloseClosedChangeState(true);
            }}
            disabled={
              loggedUserIsASawmill(rdxUserExtendedMe)
                ? disableButtonValidateSendToForestry()
                : disableButtonValidateSendToSawmill()
            }
          />
        </View>
      ) : null}
      <UrstammList
        elementSelected={(trunkSelected: Trunk) => props.trunkSelected(trunkSelected)}
        testID={'trunk_list'}
        showHash={false}
        textForEmptyList={i18n.t('views.trunk.trunks_not_found')}
        list={props.trunkList.map(_ => (_.edited.status ? _.trunk : _.edited.data))}
        titleKey={undefined}
        titleByFunction={data => getCardTitleByTrunkNumberFas(data)}
        listStyle={{
          mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
          mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
          mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
          bottomContainerViewStyle: UrstammStyleList.cardSubData,
          bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
        }}
        listSubData={keys}
        filterBy={getActiveTrunkCurrentState()}
        currentState={{
          //icon: (state) => getIconByLoggingState(state),
          color: state => getColorByDeliverySheetState(state),
          text: state => getTextByDeliverySheetState(state)
        }}
        filterList={true}
        elementSwitched={props.validateTrunkSwitched}
        getTextForData={data => getTextByTreeOrTrunkSpecies(data)}
        switchOptions={{
          enabled: true,
          textStyle: UrstammStyle.switchStyle,
          statusByFn: (trunk: Trunk) => trunkChangedProperties(trunk, rdxUserExtendedMe.type),
          colorByFn: (trunk: Trunk) => getColorByTrunkState(trunk.currentState),
          textByFn: (trunk: Trunk) => getTextByTrunkState(trunk.currentState),
          disabledByFn: (trunk: Trunk) =>
            editTrunkByDsAndTrunkStatus(trunk, rdxUserExtendedMe.type) ||
            dsCurrentStatusIsInProgressOrValidOrClosed(trunk?.deliverySheet?.currentState) ||
            deliverySheetIsValid(trunk?.deliverySheet?.currentState) ||
            trunkIsValid(trunk.currentState),
          indeterminateByFn: (trunk: Trunk) => trunkInProgress(trunk.currentState)
        }}
      />

      {props.deliverySheetSelected.sentToSawmill &&
      !deliverySheetIsValid(props.deliverySheetSelected.currentState) &&
      !deliverySheetIsInProgress(props.deliverySheetSelected.currentState) ? (
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'send_feedback'}
            text={
              loggedUserIsASawmill(rdxUserExtendedMe)
                ? i18n.t('generics.send_feedback_to_forestry')
                : i18n.t('generics.send_feedback_to_sawmill')
            }
            style={mediumGreyButton}
            icon={{
              showIcon: false,
              iconName: 'send',
              iconColor: ColorTheme.lightGreen
            }}
            onSubmit={() => {
              setValidate(false);
              loggedUserIsASawmill(rdxUserExtendedMe)
                ? setOpenCloseValidatedChangeState(true)
                : setOpenCloseClosedChangeState(true);
            }}
            disabled={disableButtonSendToSawmill() || disableButtonByTrunks()}
          />
        </View>
      ) : null}

      {props.showSortBy ? (
        <UrstammModalSortList
          testID={'modal_sort'}
          showSortBy={props.showSortBy}
          closeShowSortBy={props.closeShowSortBy}
          applyOrder={(orderSelected: ButtonSelect[]) => props.applyOrder(orderSelected)}
          sortBySelected={rdxTrunkSortBy}
        />
      ) : null}

      {openCloseValidatedChangeState && (
        <UrstammModal
          testID={'close_validate_modal'}
          text={i18n.t('generics.are_you_sure_change_to_validated')}
          textStyle={UrstammStyleModal.formBtextModalStyle}
          visible={openCloseValidatedChangeState}
          buttonText={{
            left: i18n.t('utility_components.modal.cancel'),
            right: i18n.t('utility_components.modal.confirm')
          }}
          confirm={(confirmed: boolean) => {
            confirmed ? props.validate(validate) : null, setOpenCloseValidatedChangeState(false);
          }}
        />
      )}

      {openCloseClosedChangeState && (
        <UrstammModal
          testID={'close_close_modal'}
          text={i18n.t('generics.are_you_sure_change_to_closed')}
          textStyle={UrstammStyleModal.formBtextModalStyle}
          visible={openCloseClosedChangeState}
          buttonText={{
            left: i18n.t('utility_components.modal.cancel'),
            right: i18n.t('utility_components.modal.confirm')
          }}
          confirm={(confirmed: boolean) => {
            confirmed ? props.validate(validate) : null, setOpenCloseClosedChangeState(false);
          }}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({});
