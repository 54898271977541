import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface UrstammImageDone {
  width?: number;
  height?: number;
  showText?: boolean;
}

const UrstammImageDone = (props: UrstammImageDone) => {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 18 18" fill="none">
      <Path
        d="M16.0716 8.33051V9.00409C16.0707 10.5829 15.5594 12.1191 14.6141 13.3836C13.6688 14.6482 12.34 15.5732 10.826 16.0209C9.31201 16.4685 7.69385 16.4148 6.21288 15.8676C4.73191 15.3205 3.46748 14.3093 2.60816 12.9848C1.74885 11.6603 1.3407 10.0936 1.44458 8.51817C1.54846 6.94279 2.15881 5.44318 3.18459 4.24301C4.21038 3.04285 5.59664 2.20642 7.13663 1.85848C8.67663 1.51054 10.2878 1.66973 11.73 2.3123M16.0716 3.14695L8.75014 10.4757L6.55371 8.27927"
        stroke="#AFCB1F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default UrstammImageDone;
