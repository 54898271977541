/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegistrationDTO
 */
export interface RegistrationDTO {
    /**
     * 
     * @type {number}
     * @memberof RegistrationDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    imageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationDTO
     */
    activated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    langKey?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationDTO
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    lastModifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationDTO
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {Set<string>}
     * @memberof RegistrationDTO
     */
    authorities?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    companyStreetName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    companyStreetNumber: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    companyCity: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    companyZipCode: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    companyIdiNumber: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    userType: RegistrationDTOUserTypeEnum;
}


/**
 * @export
 */
export const RegistrationDTOUserTypeEnum = {
    Forestry: 'FORESTRY',
    Sawmill: 'SAWMILL',
    Processor: 'PROCESSOR',
    Projectmanager: 'PROJECTMANAGER'
} as const;
export type RegistrationDTOUserTypeEnum = typeof RegistrationDTOUserTypeEnum[keyof typeof RegistrationDTOUserTypeEnum];


/**
 * Check if a given object implements the RegistrationDTO interface.
 */
export function instanceOfRegistrationDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "login" in value;
    isInstance = isInstance && "companyName" in value;
    isInstance = isInstance && "companyStreetName" in value;
    isInstance = isInstance && "companyStreetNumber" in value;
    isInstance = isInstance && "companyCity" in value;
    isInstance = isInstance && "companyZipCode" in value;
    isInstance = isInstance && "companyIdiNumber" in value;
    isInstance = isInstance && "userType" in value;

    return isInstance;
}

export function RegistrationDTOFromJSON(json: any): RegistrationDTO {
    return RegistrationDTOFromJSONTyped(json, false);
}

export function RegistrationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'login': json['login'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'activated': !exists(json, 'activated') ? undefined : json['activated'],
        'langKey': !exists(json, 'langKey') ? undefined : json['langKey'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (new Date(json['lastModifiedDate'])),
        'authorities': !exists(json, 'authorities') ? undefined : json['authorities'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'companyName': json['companyName'],
        'companyStreetName': json['companyStreetName'],
        'companyStreetNumber': json['companyStreetNumber'],
        'companyCity': json['companyCity'],
        'companyZipCode': json['companyZipCode'],
        'companyIdiNumber': json['companyIdiNumber'],
        'userType': json['userType'],
    };
}

export function RegistrationDTOToJSON(value?: RegistrationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'login': value.login,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'imageUrl': value.imageUrl,
        'activated': value.activated,
        'langKey': value.langKey,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate.toISOString()),
        'authorities': value.authorities === undefined ? undefined : Array.from(value.authorities as Set<any>),
        'password': value.password,
        'companyName': value.companyName,
        'companyStreetName': value.companyStreetName,
        'companyStreetNumber': value.companyStreetNumber,
        'companyCity': value.companyCity,
        'companyZipCode': value.companyZipCode,
        'companyIdiNumber': value.companyIdiNumber,
        'userType': value.userType,
    };
}

