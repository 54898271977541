/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LongFilter } from './LongFilter';
import {
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
} from './LongFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface CustomCompanyCriteria
 */
export interface CustomCompanyCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    prefixIdentifier?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    idiNumber?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    streetName?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    streetNumber?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    phoneNumber?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    email?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    city?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    zipCode?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    logoFilename?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    logoFilepath?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanyCriteria
     */
    logoUrl?: StringFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    sawmillId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    processorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    xmlExportErpId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    companyAppearanceId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    companyProductTypeId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    companyPacketWoodQualityId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    companySurfaceId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    loggingId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    userCompanyId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    userCompanyInviteId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    forestryId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanyCriteria
     */
    sawmillSupplierId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomCompanyCriteria
     */
    distinct?: boolean;
}

/**
 * Check if a given object implements the CustomCompanyCriteria interface.
 */
export function instanceOfCustomCompanyCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomCompanyCriteriaFromJSON(json: any): CustomCompanyCriteria {
    return CustomCompanyCriteriaFromJSONTyped(json, false);
}

export function CustomCompanyCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomCompanyCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : StringFilterFromJSON(json['name']),
        'prefixIdentifier': !exists(json, 'prefixIdentifier') ? undefined : StringFilterFromJSON(json['prefixIdentifier']),
        'idiNumber': !exists(json, 'idiNumber') ? undefined : StringFilterFromJSON(json['idiNumber']),
        'streetName': !exists(json, 'streetName') ? undefined : StringFilterFromJSON(json['streetName']),
        'streetNumber': !exists(json, 'streetNumber') ? undefined : StringFilterFromJSON(json['streetNumber']),
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : StringFilterFromJSON(json['phoneNumber']),
        'email': !exists(json, 'email') ? undefined : StringFilterFromJSON(json['email']),
        'city': !exists(json, 'city') ? undefined : StringFilterFromJSON(json['city']),
        'zipCode': !exists(json, 'zipCode') ? undefined : StringFilterFromJSON(json['zipCode']),
        'logoFilename': !exists(json, 'logoFilename') ? undefined : StringFilterFromJSON(json['logoFilename']),
        'logoFilepath': !exists(json, 'logoFilepath') ? undefined : StringFilterFromJSON(json['logoFilepath']),
        'logoUrl': !exists(json, 'logoUrl') ? undefined : StringFilterFromJSON(json['logoUrl']),
        'sawmillId': !exists(json, 'sawmillId') ? undefined : LongFilterFromJSON(json['sawmillId']),
        'processorId': !exists(json, 'processorId') ? undefined : LongFilterFromJSON(json['processorId']),
        'xmlExportErpId': !exists(json, 'xmlExportErpId') ? undefined : LongFilterFromJSON(json['xmlExportErpId']),
        'companyAppearanceId': !exists(json, 'companyAppearanceId') ? undefined : LongFilterFromJSON(json['companyAppearanceId']),
        'companyProductTypeId': !exists(json, 'companyProductTypeId') ? undefined : LongFilterFromJSON(json['companyProductTypeId']),
        'companyPacketWoodQualityId': !exists(json, 'companyPacketWoodQualityId') ? undefined : LongFilterFromJSON(json['companyPacketWoodQualityId']),
        'companySurfaceId': !exists(json, 'companySurfaceId') ? undefined : LongFilterFromJSON(json['companySurfaceId']),
        'loggingId': !exists(json, 'loggingId') ? undefined : LongFilterFromJSON(json['loggingId']),
        'userCompanyId': !exists(json, 'userCompanyId') ? undefined : LongFilterFromJSON(json['userCompanyId']),
        'userCompanyInviteId': !exists(json, 'userCompanyInviteId') ? undefined : LongFilterFromJSON(json['userCompanyInviteId']),
        'forestryId': !exists(json, 'forestryId') ? undefined : LongFilterFromJSON(json['forestryId']),
        'sawmillSupplierId': !exists(json, 'sawmillSupplierId') ? undefined : LongFilterFromJSON(json['sawmillSupplierId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CustomCompanyCriteriaToJSON(value?: CustomCompanyCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'name': StringFilterToJSON(value.name),
        'prefixIdentifier': StringFilterToJSON(value.prefixIdentifier),
        'idiNumber': StringFilterToJSON(value.idiNumber),
        'streetName': StringFilterToJSON(value.streetName),
        'streetNumber': StringFilterToJSON(value.streetNumber),
        'phoneNumber': StringFilterToJSON(value.phoneNumber),
        'email': StringFilterToJSON(value.email),
        'city': StringFilterToJSON(value.city),
        'zipCode': StringFilterToJSON(value.zipCode),
        'logoFilename': StringFilterToJSON(value.logoFilename),
        'logoFilepath': StringFilterToJSON(value.logoFilepath),
        'logoUrl': StringFilterToJSON(value.logoUrl),
        'sawmillId': LongFilterToJSON(value.sawmillId),
        'processorId': LongFilterToJSON(value.processorId),
        'xmlExportErpId': LongFilterToJSON(value.xmlExportErpId),
        'companyAppearanceId': LongFilterToJSON(value.companyAppearanceId),
        'companyProductTypeId': LongFilterToJSON(value.companyProductTypeId),
        'companyPacketWoodQualityId': LongFilterToJSON(value.companyPacketWoodQualityId),
        'companySurfaceId': LongFilterToJSON(value.companySurfaceId),
        'loggingId': LongFilterToJSON(value.loggingId),
        'userCompanyId': LongFilterToJSON(value.userCompanyId),
        'userCompanyInviteId': LongFilterToJSON(value.userCompanyInviteId),
        'forestryId': LongFilterToJSON(value.forestryId),
        'sawmillSupplierId': LongFilterToJSON(value.sawmillSupplierId),
        'distinct': value.distinct,
    };
}

