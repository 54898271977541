/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';

/**
 * 
 * @export
 * @interface EntityWithFileDTOProject
 */
export interface EntityWithFileDTOProject {
    /**
     * 
     * @type {Project}
     * @memberof EntityWithFileDTOProject
     */
    entity?: Project;
    /**
     * 
     * @type {string}
     * @memberof EntityWithFileDTOProject
     */
    base64File?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityWithFileDTOProject
     */
    deletePrevious?: boolean;
}

/**
 * Check if a given object implements the EntityWithFileDTOProject interface.
 */
export function instanceOfEntityWithFileDTOProject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EntityWithFileDTOProjectFromJSON(json: any): EntityWithFileDTOProject {
    return EntityWithFileDTOProjectFromJSONTyped(json, false);
}

export function EntityWithFileDTOProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityWithFileDTOProject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': !exists(json, 'entity') ? undefined : ProjectFromJSON(json['entity']),
        'base64File': !exists(json, 'base64File') ? undefined : json['base64File'],
        'deletePrevious': !exists(json, 'deletePrevious') ? undefined : json['deletePrevious'],
    };
}

export function EntityWithFileDTOProjectToJSON(value?: EntityWithFileDTOProject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': ProjectToJSON(value.entity),
        'base64File': value.base64File,
        'deletePrevious': value.deletePrevious,
    };
}

