import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import UrstammButtonSelect, { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammFilePicker, {
  PickerMediaType,
  UploadType
} from '@components/utility-components/file-picker/UrstammFilePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { AlertHelper } from '@helpers/AlertHelper';
import { i18n } from '@i18n/i18n';
import { RootState } from '@redux/store';
import {
  Logging,
  LoggingDTO,
  TreeDTO,
  TreeDTOCurrentStateEnum,
  TreeDTORecordingTypeEnum,
  TreeDTOSpeciesEnum,
  TreeSpeciesEnum
} from '@services/apis/generated';
import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByLoggingState,
  getIconByLoggingState,
  getTextByLoggingState,
  getTextByTreeOrTrunkSpecies
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilUpdateForm,
  urstammUtilUpdatePhoto,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import { getMandatorySymbol, getTreeDTOSpeciesList } from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import {
  lightGreenBorderWhiteBGButton,
  mainGreenButton,
  mainGreenButtonSelectActive
} from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList
} from '../../../utils/styles/UrstammStyle';

export interface TreeForm {
  tree: TreeDTO;
  base64File: string;
}

export default function TreeRegistrationView(props: {
  navigation: any;
  loggingSelected: Logging;
  photoNumber: string;
  submitTreeForm: (form: TreeForm) => void;
  saveAndContinue: (form: TreeForm) => void;
  clearForm: boolean;
  resetRecordingType?: boolean;
  showLoggingList: boolean;
  loggingList?: LoggingDTO[];
  moreItemsLogging?: () => void;
  loggingSelectedFormList?: (logging: Logging) => void;
}) {
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);

  const [treeRecordingType, setTreeRecordingType] = useState<ButtonSelect[]>([
    {
      text: i18n.t('views.tree.multiple_tree'),
      enum: TreeDTORecordingTypeEnum.Multiple,
      active: false
    },
    {
      text: i18n.t('views.tree.date'),
      enum: TreeDTORecordingTypeEnum.Date,
      active: false
    },
    {
      text: i18n.t('views.tree.single_tree'),
      enum: TreeDTORecordingTypeEnum.Single,
      active: false
    }
  ]);

  //Logging list
  let keys: ListSubData[] = [
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate'
    }
  ];

  const scrollRef = useRef<any>();

  //Validators
  const validators = useState<any>({
    photoNumber: { firstCharacterIsLetter: true, required: true },
    base64File: { required: true },
    species: { required: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    photoNumber: [],
    base64File: [],
    species: []
  });

  const validate = (value: string, key: string, updatedTreeForm: TreeForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm)
    );
  };

  const [treeForm, setTreeForm] = useStateWithCallbackLazy<TreeForm>({
    tree: {
      logging: undefined,
      photoNumber: '',
      comment: '',
      species: undefined,
      recordingType: undefined,
      currentState: TreeDTOCurrentStateEnum.Cut
    },
    base64File: ''
  });

  const [photoNumber, setPhotoNumber] = useState<string>('');
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  const [showLoggingListSelect, setShowLoggingListSelect] = useState<boolean>(false);

  /**
   * Reset form
   */
  useEffect(() => {
    if (props.resetRecordingType) {
      getSlideButtonOptionsTreeSpecies();
    }
    if (props.clearForm) {
      let specie = getSpecieSelected();
      setTreeForm(
        prevState => ({
          ...prevState,
          tree: {
            logging: treeForm.tree?.logging,
            photoNumber: '',
            comment: '',
            recordingType: getTreeRecordingType()?.enum,
            currentState: TreeDTOCurrentStateEnum.Cut,
            latitude: undefined,
            longitude: undefined,
            species: specie?.id ? specie?.id : undefined
          },
          base64File: ''
        }),
        updatedTreeForm => {
          saveButtonDisabled(errors, updatedTreeForm);
        }
      );

      if (props.resetRecordingType) {
        resetTreeRecordingType();
        //Clearing logging
        setTreeForm(
          prevState => ({
            ...prevState,
            tree: {
              logging: undefined
            },
            base64File: ''
          }),
          updatedTreeForm => {
            saveButtonDisabled(errors, updatedTreeForm);
          }
        );
      }
    }
  }, [props.clearForm, props.resetRecordingType]);

  useEffect(() => {
    if (props.photoNumber) {
      updateTreeForm('photoNumber', props.photoNumber);
      setPhotoNumber(props.photoNumber);
    }
  }, [props.photoNumber]);

  useEffect(() => {
    if (props.showLoggingList) {
      Object.assign(validators[0], { logging: { required: true } });
    }
  }, [props.showLoggingList]);

  useEffect(() => {
    getSlideButtonOptionsTreeSpecies();
  }, []);

  const onFabPress = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true
    });
  };

  const [treeSpeciesList, setTreeSpeciesList] = useState<SlideButtonOption[]>([]);

  const getSlideButtonOptionsTreeSpecies = (): void => {
    let filterList: SlideButtonOption[] = [];
    (getTreeDTOSpeciesList() as TreeDTOSpeciesEnum[])
      .filter(val => val != TreeDTOSpeciesEnum.MixedSoftwood && val != TreeDTOSpeciesEnum.MixedHardwood)
      .map(state => {
        let type: SlideButtonOption = {
          id: state,
          text: getTextByTreeOrTrunkSpecies(state),
          active: false
        };
        filterList.push(type);
      });
    setTreeSpeciesList(filterList);
  };

  const setTreeSpecie = (selected: SlideButtonOption): void => {
    treeSpeciesList.forEach(type => {
      type.active = type.id == selected.id ? true : false;
    });
    setTreeSpeciesList([...treeSpeciesList]);
  };

  const getSpecieSelected = () => {
    return treeSpeciesList.find(val => val.active == true);
  };
  /**
   *
   * @param key
   * @param value
   */
  const updateTreeForm = (key: string, value: any, mustValidate?: boolean): void => {
    let form = urstammUtilUpdateForm(key, value, treeForm, 'tree');
    setTreeForm(
      { tree: form['tree'], base64File: form['base64File'] },
      mustValidate ? updatedTreeForm => validate(value, key, updatedTreeForm) : () => {}
    );
  };

  const updatePhoto = (base64: string, location: any, mustValidate?: boolean): void => {
    const photoCreationDate = new Date();
    let form = urstammUtilUpdatePhoto(base64, location, photoCreationDate, treeForm, 'tree');
    setTreeForm(
      { tree: form['tree'], base64File: form['base64File'] },
      mustValidate ? updatedTreeForm => validate(base64, 'base64File', updatedTreeForm) : () => {}
    );
  };

  const activeTreeRecordingType = (treeRecordingTypeEnum: TreeDTORecordingTypeEnum): void => {
    updateTreeForm('recordingType', treeRecordingTypeEnum);

    treeRecordingType.forEach(type => {
      type.active = type.enum == treeRecordingTypeEnum ? true : false;
    });
    setTreeRecordingType([...treeRecordingType]);
  };

  const getTreeRecordingType = (): ButtonSelect => {
    let treeRecording = treeRecordingType.filter(treeType => treeType.active);
    return treeRecording[0];
  };

  const resetTreeRecordingType = (): void => {
    treeRecordingType.forEach(type => {
      type.active = false;
    });
    setTreeRecordingType([...treeRecordingType]);
  };

  const saveButtonDisabled = (errorsUpdated, updatedTreeForm: TreeForm) => {
    let disabled: boolean = false;
    if (props.showLoggingList) {
      if (
        urstammUtilValidatorInError(errorsUpdated) ||
        !updatedTreeForm.tree.logging ||
        updatedTreeForm.tree.photoNumber?.length == 0 ||
        !updatedTreeForm.tree.species ||
        updatedTreeForm.base64File.length == 0
      ) {
        disabled = true;
      }
    } else {
      if (
        urstammUtilValidatorInError(errorsUpdated) ||
        updatedTreeForm.tree.photoNumber?.length == 0 ||
        !updatedTreeForm.tree.species ||
        updatedTreeForm.base64File.length == 0
      ) {
        disabled = true;
      }
    }
    return setSaveDisabled(disabled);
  };

  const setLoggingFromList = (loggingSelected: Logging) => {
    let form = { ...treeForm.tree };
    let base64File = treeForm.base64File;

    form['logging'] = loggingSelected;
    form.species = undefined;
    getSlideButtonOptionsTreeSpecies();
    setTreeForm({ tree: form, base64File: '' }, updatedTreeForm => {
      validate(loggingSelected.name, 'logging', updatedTreeForm);
    });
    props.loggingSelectedFormList ? props.loggingSelectedFormList(loggingSelected) : null; //Updating photo number by logging selected
    setShowLoggingListSelect(false);

    resetTreeRecordingType();
  };

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn, UrstammStyle.flexStartX]}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        ref={scrollRef!}
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** Logging list */}
        {props.showLoggingList ? (
          <View style={[UrstammStyleLayout.formElementContainerPd8]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainer}>
              <Text style={UrstammStyle.textTitle}>
                {i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_logging') + getMandatorySymbol(true)}
              </Text>
            </View>

            <UrstammButtonBase
              testID={'choose_logging'}
              text={
                treeForm.tree.logging?.name
                  ? treeForm.tree.logging?.name
                  : i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_logging')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowLoggingListSelect(true)}
            />

            {/**Logging Modal select */}
            {showLoggingListSelect ? (
              <UrstammModalSelect
                testID={'modal_logging_select'}
                visible={showLoggingListSelect}
                text={{
                  textInputTitle: i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_logging'),
                  textStyle: UrstammStyle.textTitle
                }}
                closeModal={() => setShowLoggingListSelect(false)}
                listJSX={
                  <UrstammList
                    elementSelected={(loggingSelected: any) => {
                      setLoggingFromList(loggingSelected.item);
                    }}
                    showHash={false}
                    testID={'logging_list'}
                    textForEmptyList={i18n.t('views.logging.loggings_not_found')}
                    list={props.loggingList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainCardContainerRowCenter,
                      mainTitleTextStyle: UrstammStyleList.cardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    listSubData={keys}
                    filterBy={undefined}
                    moreItems={props.moreItemsLogging}
                    filterList={true}
                    currentState={{
                      icon: state => getIconByLoggingState(state),
                      color: state => getColorByLoggingState(state),
                      text: state => getTextByLoggingState(state)
                    }}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Recording type */}
        {!getTreeRecordingType() && (
          <View style={UrstammStyleLayout.formElementContainerGrw1}>
            <UrstammButtonSelect
              testID={'recording_type_button_select'}
              buttonSelectTitle={i18n.t('generics.choose_recording_type')}
              options={treeRecordingType}
              style={mainGreenButtonSelectActive}
              onSubmit={(data: ButtonSelect) => {
                activeTreeRecordingType(data.enum);
              }}
            />
          </View>
        )}

        {getTreeRecordingType() && getTreeRecordingType().enum == TreeDTORecordingTypeEnum.Single ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
            {/** Photo number */}
            <UrstammInput
              testID="tree_photo_number"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={props.photoNumber}
              //value={treeForm.tree.photoNumber ? treeForm.tree.photoNumber : ''}
              editable={false}
              onChangeText={(text: string) => {
                updateTreeForm('photoNumber', text, true);
              }}
              placeholder={i18n.t('views.tree.tree_registration.insert_tree_photo_number_here')}
              textInputTitle={i18n.t('views.tree.tree_registration.photo_number')}
              mandatory={true}
            />

            {/**Validator */}
            {errors['photoNumber'] && errors['photoNumber'].length > 0
              ? errors['photoNumber'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        ) : null}

        {getTreeRecordingType() ? (
          <>
            {/**Species */}
            <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
              <UrstammSlideButton
                testID={'button_tree_species'}
                slideButtonList={treeSpeciesList}
                slideButtonTitle={i18n.t('generics.tree_species')}
                slideButtonRotatedTitle={i18n.t('generics.species')}
                buttonPressed={(selected: SlideButtonOption) => {
                  setTreeSpecie(selected);
                  updateTreeForm('species', selected.id, true);
                }}
                mandatory={true}
              />
              {/**Validator */}
              {errors['species'] && errors['species'].length > 0
                ? errors['species'].map((error, idx) => {
                    return urstammUtilDisplayError(error, idx);
                  })
                : null}
            </View>

            {/**Image picker */}
            <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
              <UrstammFilePicker
                testID="tree_image_picker"
                inputStyle={UrstammStyleContainer.cameraContainer}
                fontStyle={UrstammStyle.formBaseLDarkGrey}
                icon={{
                  showIcon: true,
                  iconName: 'camera',
                  iconColor: ColorTheme.lightGreen
                }}
                placeholder={i18n.t('generics.upload_photo_file')}
                textInputTitle={i18n.t('views.tree.tree_registration.tree_photo')}
                uploadType={UploadType.camera}
                mandatory={true}
                deleteBase64={() => {
                  updateTreeForm('base64File', '', true);
                }}
                mediaTypeUploaded={PickerMediaType.Image}
                clearPicker={props.clearForm}
                saveBase64={(base64: string, location: any) => {
                  if (!location) {
                    AlertHelper.showSimpleAlert('Error', 'cannot_take_gps_coordinates');
                    updateTreeForm('base64File', '', true);
                    return;
                  }
                  updatePhoto(base64, location, true);
                }}
              />

              {/**Validator */}
              {errors['base64File'] && errors['base64File'].length > 0
                ? errors['base64File'].map((error, idx) => {
                    return urstammUtilDisplayError(error, idx);
                  })
                : null}
            </View>

            {/** Comment */}
            <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
              <UrstammInput
                testID="tree_comment"
                style={{
                  containerViewStyle: UrstammStyleContainer.textareaContainer,
                  textStyle: UrstammStyle.formBaseLDarkGrey
                }}
                icon={{ showIcon: false }}
                value={treeForm.tree.comment ? treeForm.tree.comment : ''}
                onChangeText={(text: string) => updateTreeForm('comment', text, false)}
                placeholder={i18n.t('generics.insert_comment_here')}
                textInputTitle={i18n.t('generics.comment')}
                mandatory={false}
                multiline={true}
              />
            </View>

            {/**Save and continue */}
            <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
              <UrstammButtonBase
                testID={'save_and_continue_tree'}
                text={i18n.t('generics.save_and_continue')}
                style={mainGreenButton}
                onSubmit={() => {
                  props.saveAndContinue(treeForm), onFabPress();
                }}
                disabled={saveDisabled || rdxLoaderStatus}
              />
            </View>

            {/**Save */}
            <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
              <UrstammButtonBase
                testID={'save_tree'}
                text={i18n.t('generics.save')}
                style={mainGreenButton}
                onSubmit={() => props.submitTreeForm(treeForm)}
                disabled={saveDisabled || rdxLoaderStatus}
              />
            </View>
          </>
        ) : null}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
