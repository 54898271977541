import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import {
  applySavingToReference,
  deepCopyAndAddSelectedParameter,
  restoreListToReference,
  selectElementFormList,
  selectSlideElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ProductRegistrationView, { ProjectForm } from '@components/views/project/ProjectRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { project, resetProjectList } from '@redux/features/project/projectSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { resetProductList, setProductList } from '@redux/features/product/productSlice';
import {
  resetCompanyProductTypeList,
  setCompanyProductTypeList
} from '@redux/features/product-type/companyProductTypeSlice';
import { resetCompanySurfaceList, setCompanySurfaceList } from '@redux/features/surface/companySurfaceSlice';
import { resetCompanyAppearanceList, setCompanyAppearanceList } from '@redux/features/appearance/companyAppearanceSlice';
import { RootState } from '@redux/store';
import {
  customCompanyProductTypeResourceApi,
  customCompanyResourceApi,
  customCompanySurfaceResourceApi,
  customCompanyAppearanceResourceApi,
  customProductResourceApi,
  listSize,
  customProjectResourceApi
} from '@services/apis/ApiConfiguration';
import {
  ProcessorBatchDTO,
  Company,
  Product,
  Project,
  ProjectCurrentStateEnum
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { getTextByTreeOrTrunkSpecies } from '../../utils/classes/UrstammUtilityCurrentState';
import {
  formatPhotoNumber,
  generateSet,
  generateSetForCertification,
  generateSetForProductSpecies,
  getCardTitleByProject,
  sleep
} from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';
import ProjectRegistrationView, { ProjectImageForm } from '@components/views/project/ProjectRegistrationView';

export default function ProjectRegistrationContainer({ route, navigation }: RootStackScreenProps<'ProjectRegistration'>) {
  //Redux

  const [sourceProductList, setSourceProductList] = useState<any>();
  const [sourceProductListReference, setSourceProductListReference] = useState<any>();

  const dispatch = useDispatch();

  const [productUniqueNumber, setProductUniqueNumber] = useState<string>('');

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('generics.project'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    generateProjectUniqueNumber();
    getSourceProduct();
  }, []);

  const getSourceProduct = () => {
    customProjectResourceApi
      .getDSProductsByProject({
        id: -1
      })
      .then(async (products: Set<Product>) => {
        dispatch(changeLoaderStatus(false));

        if (products) {
          const list = [...products.values()]
          deepCopyAndAddSelectedParameter(list, setSourceProductList, setSourceProductListReference);
        } else {
          setSourceProductList([]);
          setSourceProductListReference([]);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  }

  const goBack = async (): Promise<void> => {
    dispatch(resetProjectList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToProjectList(navigation, true);
  };

  const submitProject = (projectForm: ProjectForm, projectImageForm: ProjectImageForm | undefined) => {
    dispatch(changeLoaderStatus(true));

    // let productsSet: Set<Product> = generateSetForProducts(productsList);
    let productsSet: Set<Product> = generateSet(sourceProductList);

    let projectObj: Project = {
      currentState: ProjectCurrentStateEnum.InProgress,
      uniqueNumber: projectForm.project.uniqueNumber!,
      name: projectForm.project.name!,
      creationDate: projectForm.project.creationDate,
      comment: projectForm.project.comment,
      products: productsSet,
      longitude: isNaN(Number(projectImageForm?.longitude)) ? undefined : Number(projectImageForm?.longitude),
      latitude: isNaN(Number(projectImageForm?.latitude)) ? undefined : Number(projectImageForm?.latitude),
      photoCreationDate: projectImageForm?.photoCreationDate,
    };

    customProjectResourceApi
      .createProjectCustom({ entityWithFileDTOProject: { entity: projectObj, base64File: projectImageForm?.base64File } })
      .then(async (project: Project) => {
        dispatch(changeLoaderStatus(false));

        if (project) {
          dispatch(resetProjectList());
          Keyboard.dismiss();
          await sleep(PlatformHelper.isIos() ? 300 : 100);
          UrstammNavigationHelper.navigateToProjectDetails(navigation, project);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  /**
   * Prefix ex: UR_YYYY_0001
   * UR = Urstamm
   * YYYY = year of creation
   * 0001 = progressive
   */
  const generateProjectUniqueNumber = () => {
    const date = new Date();
    const fullYear = date.getUTCFullYear();
    //Default value
    return setProductUniqueNumber(`UR_${fullYear}_xxxx`);
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'project_title'}
          text={i18n.t('views.project.project_registration.new_project')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      // headerDetails={[{ title: ' '/* i18n.t('generics.name') */, value: ' ' }]}
      view={
        <ProjectRegistrationView
          navigation={navigation}
          uniqueNumber={productUniqueNumber}
          submitProjectForm={submitProject}
          productsPros={{
            list: sourceProductList,
            selected: selected => selectElementFormList(selected, setSourceProductList, sourceProductList),
            moreItems: () => { },
            resetSelected: () => restoreListToReference(setSourceProductList, sourceProductListReference),
            confirmSelected: () => applySavingToReference(setSourceProductListReference, sourceProductList),
          }}
        />
      }
    />
  );
}
