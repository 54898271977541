import { StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native';
import React from 'react';

export default function UrstammTitle(props: { fontStyle?: StyleProp<TextStyle>; testID: string; text: string }) {
  return (
    <View>
      <Text testID={props.testID} style={[props.fontStyle]}>
        {props.text}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({});
