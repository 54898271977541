/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrunkBarkDiscountTable
 */
export interface TrunkBarkDiscountTable {
    /**
     * 
     * @type {number}
     * @memberof TrunkBarkDiscountTable
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TrunkBarkDiscountTable
     */
    diameter?: number;
    /**
     * 
     * @type {string}
     * @memberof TrunkBarkDiscountTable
     */
    species?: TrunkBarkDiscountTableSpeciesEnum;
    /**
     * 
     * @type {number}
     * @memberof TrunkBarkDiscountTable
     */
    barkDiscountPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof TrunkBarkDiscountTable
     */
    barkDiscountMm?: number;
}


/**
 * @export
 */
export const TrunkBarkDiscountTableSpeciesEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type TrunkBarkDiscountTableSpeciesEnum = typeof TrunkBarkDiscountTableSpeciesEnum[keyof typeof TrunkBarkDiscountTableSpeciesEnum];


/**
 * Check if a given object implements the TrunkBarkDiscountTable interface.
 */
export function instanceOfTrunkBarkDiscountTable(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TrunkBarkDiscountTableFromJSON(json: any): TrunkBarkDiscountTable {
    return TrunkBarkDiscountTableFromJSONTyped(json, false);
}

export function TrunkBarkDiscountTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrunkBarkDiscountTable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'diameter': !exists(json, 'diameter') ? undefined : json['diameter'],
        'species': !exists(json, 'species') ? undefined : json['species'],
        'barkDiscountPercentage': !exists(json, 'barkDiscountPercentage') ? undefined : json['barkDiscountPercentage'],
        'barkDiscountMm': !exists(json, 'barkDiscountMm') ? undefined : json['barkDiscountMm'],
    };
}

export function TrunkBarkDiscountTableToJSON(value?: TrunkBarkDiscountTable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'diameter': value.diameter,
        'species': value.species,
        'barkDiscountPercentage': value.barkDiscountPercentage,
        'barkDiscountMm': value.barkDiscountMm,
    };
}

