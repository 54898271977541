import Sentry from '@app/sentry';
import { AlertHelper } from '@helpers/AlertHelper';
import { Logger } from '@helpers/Logger';
import { NetworkHelper } from '@helpers/NetworkHelper';
import {
  setBarkDiscountList,
  setDiameterClassList,
  setLengthRangeList
} from '@redux/features/calculation/calculationSlice';
import {
  removeDeliverySheetElement,
  setCompleteDeliverySheetList
} from '@redux/features/delivery-sheet/deliverySheetSlice';
import { changeSyncInProgress } from '@redux/features/loader/loaderSlice';
import { removeLoggingElement, resetLoggingList, setCompleteLoggingList } from '@redux/features/logging/loggingSlice';
import {
  addOfflineObjectSync,
  OfflineSyncClassEnum,
  OfflineSyncCompleted,
  OfflineSyncTypeEnum,
  removeOfflineObjectSync
} from '@redux/features/offlineSync/offlineSyncSlice';
import {
  removeOtherAssortmentElement,
  setCompleteOtherAssortmentList
} from '@redux/features/otherAssortment/otherAssortmentSlice';
import { removePileElement, setCompletePileList } from '@redux/features/pile/pileSlice';
import { removePlotElement, setCompletePlotList } from '@redux/features/plot/plotSlice';
import { setCompleteSawmillList } from '@redux/features/sawmill/sawmillSlice';
import { removeTreeElement, setCompleteTreeList } from '@redux/features/tree/treeSlice';
import { removeTrunkElement, setCompleteTrunkList } from '@redux/features/trunk/trunkSlice';
import { setExtendedMe, setMyCompany, setMyCompanyRole } from '@redux/features/user/userSlice';
import { RootState, store } from '@redux/store';
import { Severity } from '@sentry/react';
import {
  customCompanyResourceApi,
  customDeliverySheetResourceApi,
  customLoggingResourceApi,
  customOtherAssortmentResourceApi,
  customPileResourceApi,
  customPlotResourceApi,
  customTreeResourceApi,
  customTrunkBarkDiscountTableResourceApi,
  customTrunkDiameterClassTableResourceApi,
  customTrunkLengthRangeTableResourceApi,
  customTrunkResourceApi,
  customUserExtendedResourceApi
} from '@services/apis/ApiConfiguration';
import {
  DeliverySheetDTO,
  EntityWithFileDTODeliverySheetPhoto,
  EntityWithFileDTOLogging,
  EntityWithFileDTOPile,
  EntityWithFileDTOTreeDTO,
  OtherAssortment,
  PlotDTO,
  Trunk
} from '@services/apis/generated';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearElementForRedux,
  createOnlyIdCompany,
  createOnlyIdDeliverySheet,
  createOnlyIdLogging,
  createOnlyIdPile,
  createOnlyIdTree
} from '../../../utils/classes/UrstammClassMapper';
import {
  deletePilePhoto,
  deleteTreePhoto,
  readLoggingPermitPhoto,
  readLoggingPhoto,
  readPilePhoto,
  readTreePhoto
} from '../../../utils/classes/UrstammUtilityFile';
import { loggedUserIsASawmill } from '../../../utils/classes/UrstammUtilityFunctions';

/**
 * https://stackoverflow.com/a/68483032
 */
function OfflineSyncManager(props, ref) {
  //REDUX
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const rdxSyncInProgress = useSelector((state: RootState) => state.persistedReducer.loader.syncInProgress);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    // each key is connected to `ref` as a method name
    // they can execute code directly, or call a local method
    startUploadSync: (quiet = false) => {
      return startUploadSync(quiet);
    },
    startDownloadSync: (quiet = false) => {
      return startDownloadSync(quiet);
    }
  }));

  /**
   * Gets a Date or a string representation.
   * In case it is a string it is converted to Date.
   * @param date Date or string
   * @returns Given Date or new Date with given string
   */
  const dateOrByString = date => {
    if (!date) return date;
    return typeof date === 'string' ? new Date(date) : date;
  };

  /**
   * It tries to upload every offline sync object in OfflineSyncSlice.
   *
   * @param quiet If errors must be quiet (indeed showing alerts)
   * @returns A boolean Promise for upload task which returns if task has been done successfully
   */
  const startUploadSync = async (quiet = false): Promise<boolean> => {
    if (loggedUserIsASawmill(rdxUserExtendedMe)) return false;
    if (rdxSyncInProgress) return false;
    let networkOk = await NetworkHelper.isNetworkOk(quiet);
    if (!networkOk) return false;
    dispatch(changeSyncInProgress(true));
    let syncError = false;
    let completed: OfflineSyncCompleted[] = [];
    try {
      let list = [...rdxOfflineSyncList];

      while (list.length > 0) {
        let offlineSync = list[0];
        let entity = offlineSync.entity;
        let currentCompleted;
        switch (offlineSync.class) {
          case OfflineSyncClassEnum.DELIVERYSHEET:
            let deliverySheetEntity = entity as DeliverySheetDTO;
            const originalDeliverysheetId = deliverySheetEntity.id;
            let deliverySheetDto: DeliverySheetDTO = {
              id: deliverySheetEntity.id,
              name: deliverySheetEntity.name,
              comment: deliverySheetEntity.comment,
              creationDate: dateOrByString(deliverySheetEntity.creationDate),
              sumCubage: deliverySheetEntity.sumCubage,
              currentState: deliverySheetEntity.currentState,
              recordingType: deliverySheetEntity.recordingType,
              uuid: deliverySheetEntity.uuid,
              pile: createOnlyIdPile(deliverySheetEntity.pile),
              logging: createOnlyIdLogging(deliverySheetEntity.logging),
              forestry: createOnlyIdCompany(deliverySheetEntity.forestry),
              sawmill: createOnlyIdCompany(deliverySheetEntity.sawmill),
              trunks: deliverySheetEntity.trunks,
              deliverySheetPhotos: deliverySheetEntity.deliverySheetPhotos,
              automaticallyCreated: deliverySheetEntity.automaticallyCreated
            };
            if (offlineSync.type == OfflineSyncTypeEnum.INSERT) {
              deliverySheetDto.id = undefined;
              try {
                let res = await customDeliverySheetResourceApi.createDeliverySheetCustom({
                  deliverySheetDTO: deliverySheetDto
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: deliverySheetEntity.id
                };
                completed.push(currentCompleted);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            if (offlineSync.type == OfflineSyncTypeEnum.UPDATE) {
              try {
                let res = await customDeliverySheetResourceApi.updateDeliverySheetCustom({
                  id: deliverySheetDto.id!,
                  deliverySheetDTO: deliverySheetDto,
                  updateOnly: offlineSync.updateOnly
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: deliverySheetEntity.id
                };
                completed.push(currentCompleted);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            break;
          case OfflineSyncClassEnum.DELIVERYSHEETPHOTO:
            let deliverySheetPhotoEntity = entity as EntityWithFileDTODeliverySheetPhoto;
            const originalDeliverysheetphotoId = deliverySheetPhotoEntity.entity?.id;
            let deliverySheetPhotoDto: EntityWithFileDTODeliverySheetPhoto = {
              entity: {
                id: deliverySheetPhotoEntity.entity!.id,
                latitude: deliverySheetPhotoEntity.entity!.latitude,
                longitude: deliverySheetPhotoEntity.entity!.longitude,
                photoFilename: deliverySheetPhotoEntity.entity!.photoFilename,
                photoFilepath: deliverySheetPhotoEntity.entity!.photoFilepath,
                photoUrl: deliverySheetPhotoEntity.entity!.photoUrl,
                photoMd5: deliverySheetPhotoEntity.entity!.photoMd5,
                deliverySheet: createOnlyIdDeliverySheet(deliverySheetPhotoEntity.entity!.deliverySheet),
                author: deliverySheetPhotoEntity.entity!.author,
                photoCreationDate: deliverySheetPhotoEntity.entity!.photoCreationDate
              },
              base64File: deliverySheetPhotoEntity.base64File
            };
            if (offlineSync.type == OfflineSyncTypeEnum.INSERT) {
              deliverySheetPhotoDto.entity!.id = undefined;
              try {
                let res = await customDeliverySheetResourceApi.uploadDeliverySheetPhoto({
                  id: deliverySheetPhotoDto.entity!.deliverySheet!.id!,
                  entityWithFileDTODeliverySheetPhoto: deliverySheetPhotoDto
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: deliverySheetPhotoEntity.entity?.id
                };
                completed.push(currentCompleted);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            break;
          case OfflineSyncClassEnum.LOGGING:
            let loggingEntity = entity as EntityWithFileDTOLogging;
            const originalLoggingId = loggingEntity.entity?.id;
            const base64Permit = await readLoggingPermitPhoto(originalLoggingId);
            const base64Photo = await readLoggingPhoto(originalLoggingId);
            let loggingDto: EntityWithFileDTOLogging = {
              entity: {
                id: loggingEntity.entity!.id,
                name: loggingEntity.entity!.name,
                comment: loggingEntity.entity!.comment,
                creationDate: dateOrByString(loggingEntity.entity!.creationDate),
                sumCubage: loggingEntity.entity!.sumCubage,
                sumWoodOtherAssortment: loggingEntity.entity!.sumWoodOtherAssortment,
                sumWoodTotal: loggingEntity.entity!.sumWoodTotal,
                currentState: loggingEntity.entity!.currentState,
                permitMediaType: loggingEntity.entity!.permitMediaType,
                permitFilename: loggingEntity.entity!.permitFilename,
                permitFilepath: loggingEntity.entity!.permitFilepath,
                permitUrl: loggingEntity.entity!.permitUrl,
                permitMd5: loggingEntity.entity!.permitMd5,
                uuid: loggingEntity.entity!.uuid,
                loggingPhoto: loggingEntity.entity!.loggingPhoto
              },
              base64File: base64Permit,
              loggingPhotoBase64File: base64Photo
            };
            if (offlineSync.type == OfflineSyncTypeEnum.INSERT) {
              loggingDto.entity!.id = undefined;
              try {
                let res = await customLoggingResourceApi.createLoggingCustom(
                  rdxOfflineSyncList,
                  dispatch,
                  addOfflineObjectSync,
                  {
                    entityWithFileDTOLogging: loggingDto
                  }
                );
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: loggingEntity.entity?.id
                };
                completed.push(currentCompleted);
                dispatch(resetLoggingList());
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            if (offlineSync.type == OfflineSyncTypeEnum.UPDATE) {
              try {
                let res = await customLoggingResourceApi.updateLoggingCustom(
                  rdxOfflineSyncList,
                  dispatch,
                  addOfflineObjectSync,
                  {
                    id: loggingDto.entity?.id!,
                    entityWithFileDTOLogging: loggingDto
                  }
                );
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: loggingEntity.entity?.id
                };
                completed.push(currentCompleted);
                dispatch(resetLoggingList());
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            break;
          case OfflineSyncClassEnum.OTHERASSORTMENT:
            let otherAssortmentEntity = entity as OtherAssortment;
            const originalOtherAssortmentId = otherAssortmentEntity.id!;
            let otherAssortmentDto: OtherAssortment = {
              id: otherAssortmentEntity.id!,
              otherAssortmentType: otherAssortmentEntity.otherAssortmentType,
              species: otherAssortmentEntity.species,
              pile: createOnlyIdPile(otherAssortmentEntity.pile)
            };
            if (offlineSync.type == OfflineSyncTypeEnum.INSERT) {
              otherAssortmentDto.id = undefined;
              try {
                let res = await customOtherAssortmentResourceApi.createOtherAssortmentCustom({
                  otherAssortment: otherAssortmentDto
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: otherAssortmentEntity.id
                };
                completed.push(currentCompleted);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            if (offlineSync.type == OfflineSyncTypeEnum.UPDATE) {
              try {
                const originalOtherAssortmentId = otherAssortmentEntity.id!;
                let res = await customOtherAssortmentResourceApi.updateOtherAssortmentCustom({
                  id: otherAssortmentDto.id!,
                  otherAssortment: otherAssortmentDto
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: otherAssortmentEntity.id!
                };
                completed.push(currentCompleted);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            break;
          case OfflineSyncClassEnum.PILE:
            let pileEntity = entity as EntityWithFileDTOPile;
            const originalPileId = pileEntity.entity?.id;
            const base64Pile = await readPilePhoto(originalPileId);
            let pileDto: EntityWithFileDTOPile = {
              entity: {
                id: pileEntity.entity!.id,
                name: pileEntity.entity!.name,
                comment: pileEntity.entity!.comment,
                creationDate: dateOrByString(pileEntity.entity!.creationDate),
                woodType: pileEntity.entity!.woodType,
                estimatedVolume: pileEntity.entity!.estimatedVolume,
                currentState: pileEntity.entity!.currentState,
                latitude: pileEntity.entity!.latitude,
                longitude: pileEntity.entity!.longitude,
                photoFilename: pileEntity.entity!.photoFilename,
                photoFilepath: pileEntity.entity!.photoFilepath,
                photoUrl: pileEntity.entity!.photoUrl,
                photoMd5: pileEntity.entity!.photoMd5,
                uuid: pileEntity.entity!.uuid,
                logging: createOnlyIdLogging(pileEntity.entity!.logging),
                photoAuthor: pileEntity.entity!.photoAuthor,
                deliverySheets: pileEntity.entity!.deliverySheets,
                otherAssortments: pileEntity.entity!.otherAssortments,
                pileStates: pileEntity.entity!.pileStates
              },
              base64File: base64Pile
            };
            if (offlineSync.type == OfflineSyncTypeEnum.INSERT) {
              pileDto.entity!.id = undefined;
              try {
                let res = await customPileResourceApi.createPileCustom({
                  entityWithFileDTOPile: pileDto
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: pileEntity.entity?.id
                };
                completed.push(currentCompleted);
                deletePilePhoto(originalPileId);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            if (offlineSync.type == OfflineSyncTypeEnum.UPDATE) {
              try {
                const originalPileId = pileEntity.entity?.id;
                const base64Pile = await readPilePhoto(originalPileId);
                pileDto.base64File = base64Pile;
                let res = await customPileResourceApi.updatePileCustom({
                  id: pileDto.entity?.id!,
                  entityWithFileDTOPile: pileDto
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: pileEntity.entity?.id
                };
                completed.push(currentCompleted);
                deletePilePhoto(originalPileId);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            break;
          case OfflineSyncClassEnum.PLOT:
            let plotEntity = entity as PlotDTO;
            const originalPlotId = plotEntity.id;
            let plotDto: PlotDTO = {
              id: plotEntity.id,
              name: plotEntity.name,
              comment: plotEntity.comment,
              number: plotEntity.number,
              egrid: plotEntity.egrid,
              logging: createOnlyIdLogging(plotEntity.logging),
              trees: plotEntity.trees
            };
            if (offlineSync.type == OfflineSyncTypeEnum.INSERT) {
              plotDto.id = undefined;
              try {
                let res = await customPlotResourceApi.createPlotCustom({
                  plotDTO: plotDto
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: plotEntity.id
                };
                completed.push(currentCompleted);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            if (offlineSync.type == OfflineSyncTypeEnum.UPDATE) {
              try {
                let res = await customPlotResourceApi.partialUpdatePlotCustom({
                  id: plotDto.id!,
                  plotDTO: plotDto
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: plotEntity.id
                };
                completed.push(currentCompleted);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            break;
          case OfflineSyncClassEnum.TREE:
            let treeEntity = entity as EntityWithFileDTOTreeDTO;
            const originalTreeId = treeEntity.entity?.id;
            const base64Tree = await readTreePhoto(originalTreeId);
            let treeDto: EntityWithFileDTOTreeDTO = {
              entity: {
                id: treeEntity.entity!.id,
                photoNumber: treeEntity.entity!.photoNumber,
                multipleCounter: treeEntity.entity!.multipleCounter,
                comment: treeEntity.entity!.comment,
                creationDate: dateOrByString(treeEntity.entity!.creationDate),
                species: treeEntity.entity!.species,
                recordingType: treeEntity.entity!.recordingType,
                currentState: treeEntity.entity!.currentState,
                logging: createOnlyIdLogging(treeEntity.entity!.logging),
                latitude: treeEntity.entity!.latitude,
                longitude: treeEntity.entity!.longitude,
                forestPhotoFilename: treeEntity.entity!.forestPhotoFilename,
                forestPhotoFilepath: treeEntity.entity!.forestPhotoFilepath,
                forestPhotoUrl: treeEntity.entity!.forestPhotoUrl,
                forestPhotoMd5: treeEntity.entity!.forestPhotoMd5,
                photoCreationDate: treeEntity.entity!.photoCreationDate,
                uuid: treeEntity.entity!.uuid
              },
              base64File: base64Tree
            };
            if (offlineSync.type == OfflineSyncTypeEnum.INSERT) {
              treeDto.entity!.id = undefined;
              try {
                let res = await customTreeResourceApi.createTreeCustom({
                  entityWithFileDTOTreeDTO: treeDto
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: treeEntity.entity?.id
                };
                completed.push(currentCompleted);
                deleteTreePhoto(originalTreeId);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            if (offlineSync.type == OfflineSyncTypeEnum.UPDATE) {
              try {
                let res = await customTreeResourceApi.updateTreeCustom({
                  id: treeDto.entity?.id!,
                  entityWithFileDTOTreeDTO: treeDto
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: treeEntity.entity?.id
                };
                completed.push(currentCompleted);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            break;
          case OfflineSyncClassEnum.TRUNK:
            let trunkEntity = entity as Trunk;
            let trunk: Trunk = {
              id: trunkEntity.id,
              name: trunkEntity.name,
              comment: trunkEntity.comment,
              creationDate: dateOrByString(trunkEntity.creationDate),
              trunkNumberFAS: trunkEntity.trunkNumberFAS,
              quality: trunkEntity.quality,
              qualityImported: trunkEntity.qualityImported,
              length: trunkEntity.length,
              lengthImported: trunkEntity.lengthImported,
              diameter: trunkEntity.diameter,
              diameterImported: trunkEntity.diameterImported,
              species: trunkEntity.species,
              speciesImported: trunkEntity.speciesImported,
              treePhotoNumber: trunkEntity.treePhotoNumber,
              diameterClass: trunkEntity.diameterClass,
              diameterClassImported: trunkEntity.diameterClassImported,
              lengthRange: trunkEntity.lengthRange,
              lengthRangeImported: trunkEntity.lengthRangeImported,
              volume: trunkEntity.volume,
              volumeImported: trunkEntity.volumeImported,
              dataSource: trunkEntity.dataSource,
              barkDiscount: trunkEntity.barkDiscount,
              qualityChanged: trunkEntity.qualityChanged,
              lengthChanged: trunkEntity.lengthChanged,
              diameterChanged: trunkEntity.diameterChanged,
              speciesChanged: trunkEntity.speciesChanged,
              currentState: trunkEntity.currentState,
              uuid: trunkEntity.uuid,
              deliverySheet: createOnlyIdDeliverySheet(trunkEntity.deliverySheet),
              tree: createOnlyIdTree(trunkEntity.tree),
              trunkChangeHistories: trunkEntity.trunkChangeHistories,
              trunkStates: trunkEntity.trunkStates
            };
            if (offlineSync.type == OfflineSyncTypeEnum.INSERT) {
              trunk.id = undefined;
              try {
                let res = await customTrunkResourceApi.createTrunkCustom({
                  trunk: trunk
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: trunkEntity.id
                };
                completed.push(currentCompleted);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            if (offlineSync.type == OfflineSyncTypeEnum.UPDATE) {
              try {
                let res = await customTrunkResourceApi.updateTrunkCustom({
                  id: trunk.id!,
                  trunk: trunk
                });
                currentCompleted = {
                  object: offlineSync,
                  newId: res.id,
                  oldId: trunkEntity.id
                };
                completed.push(currentCompleted);
              } catch (error) {
                Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
                console.log(error);
                syncError = true;
              }
            }
            break;
        }
        // Remove the processed object from offline slice
        let result = await dispatch(removeOfflineObjectSync(currentCompleted));
        // Gets the updated object from redux
        let rdxUpdated = store.getState().persistedReducer.offlineSync.updateList;
        list = [...rdxUpdated];
        if (syncError) {
          quiet ? null : AlertHelper.showSimpleErrorAlert();
          break;
        }
      }

      for (let key in completed) {
        let completedObject = completed[key];
        dispatch(removeOfflineObjectSync(completedObject));
        if (completedObject.object.type == OfflineSyncTypeEnum.INSERT) {
          switch (completedObject.object.class) {
            case OfflineSyncClassEnum.DELIVERYSHEET:
              dispatch(removeDeliverySheetElement(completedObject.object.entity));
              break;
            case OfflineSyncClassEnum.DELIVERYSHEETPHOTO:
              // Missing
              break;
            case OfflineSyncClassEnum.LOGGING:
              dispatch(removeLoggingElement((completedObject.object.entity as EntityWithFileDTOLogging).entity));
              break;
            case OfflineSyncClassEnum.OTHERASSORTMENT:
              dispatch(removeOtherAssortmentElement(completedObject.object.entity));
              break;
            case OfflineSyncClassEnum.PILE:
              dispatch(removePileElement((completedObject.object.entity as EntityWithFileDTOPile).entity));
              break;
            case OfflineSyncClassEnum.PLOT:
              dispatch(removePlotElement(completedObject.object.entity));
              break;
            case OfflineSyncClassEnum.TREE:
              dispatch(removeTreeElement((completedObject.object.entity as EntityWithFileDTOTreeDTO).entity));
              break;
            case OfflineSyncClassEnum.TRUNK:
              dispatch(removeTrunkElement(completedObject.object.entity));
              break;
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error, { captureContext: { level: Severity.Error } });
      console.error(error);
    }

    Logger.log(OfflineSyncManager.name, 'Offline upload ended');
    dispatch(changeSyncInProgress(false));
    return !syncError;
  };

  const startDownloadSync = async (quiet = false): Promise<boolean> => {
    if (loggedUserIsASawmill(rdxUserExtendedMe)) return false;
    if (rdxSyncInProgress) return false;
    let networkOk = await NetworkHelper.isNetworkOk(quiet);
    if (!networkOk) return false;
    dispatch(changeSyncInProgress(true));
    let syncError = false;

    try {
      let me = await customUserExtendedResourceApi.getUserExtendedMe();
      if (me) {
        dispatch(setExtendedMe(me));
      }
      let company = await customCompanyResourceApi.getMyCompany();
      if (company) {
        dispatch(setMyCompany(company));
      }
      let userCompany = await customCompanyResourceApi.getMyCompanyUserCompany();
      if (userCompany) {
        dispatch(setMyCompanyRole(userCompany.role));
      }
      let companies = await customCompanyResourceApi.getAllCompaniesConnectedToMe({
        customCompanyCriteria: {}
      });
      if (companies) {
        dispatch(setCompleteSawmillList(companies));
      }
      let deliverySheetList = await customDeliverySheetResourceApi.getAllMyDeliverySheets({
        customDeliverySheetCriteria: {}
      });
      if (deliverySheetList) {
        dispatch(setCompleteDeliverySheetList(deliverySheetList));
      }
      let loggingList = await customLoggingResourceApi.getAllLoggingsForMyCompany({
        customLoggingCriteria: {}
      });
      if (loggingList) {
        dispatch(setCompleteLoggingList(loggingList));
      }
      let plotList = await customPlotResourceApi.getAllMyPlots();
      if (plotList) {
        plotList.forEach(p => {
          p.logging = clearElementForRedux(p.logging);
        });
        dispatch(setCompletePlotList(plotList));
      }
      let pileList = await customPileResourceApi.getAllMyPiles();
      if (pileList) {
        dispatch(setCompletePileList(pileList));
      }
      let oa = await customOtherAssortmentResourceApi.getAllMyOtherAssortments();
      if (oa) {
        dispatch(setCompleteOtherAssortmentList(oa));
      }
      let trees = await customTreeResourceApi.getAllMyTrees();
      if (trees) {
        dispatch(setCompleteTreeList(trees));
      }
      let trunks = await customTrunkResourceApi.getAllMyTrunks();
      if (trunks) {
        dispatch(setCompleteTrunkList(trunks));
      }
      let trunkLengthRanges = await customTrunkLengthRangeTableResourceApi.getAllTrunkLengthRangeTablesCustom();
      if (trunkLengthRanges) {
        dispatch(setLengthRangeList(trunkLengthRanges));
      }
      let trunkDiameterClasses = await customTrunkDiameterClassTableResourceApi.getAllTrunkDiameterClassTablesCustom();
      if (trunkDiameterClasses) {
        dispatch(setDiameterClassList(trunkDiameterClasses));
      }
      let trunkBarkDiscounts = await customTrunkBarkDiscountTableResourceApi.getAllTrunkBarkDiscountTablesCustom();
      if (trunkBarkDiscounts) {
        dispatch(setBarkDiscountList(trunkBarkDiscounts));
      }
    } catch (error) {
      Sentry.captureException(error, { captureContext: { level: Severity.Warning } });
      console.log(error);
      syncError = true;
    }

    dispatch(changeSyncInProgress(false));

    return !syncError;
  };

  return <></>;
}

export default forwardRef(OfflineSyncManager);
