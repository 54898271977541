/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeliverySheetErpImportation,
} from '../models';
import {
    DeliverySheetErpImportationFromJSON,
    DeliverySheetErpImportationToJSON,
} from '../models';

export interface CreateDeliverySheetErpImportationRequest {
    deliverySheetErpImportation: DeliverySheetErpImportation;
}

export interface DeleteDeliverySheetErpImportationRequest {
    id: number;
}

export interface GetDeliverySheetErpImportationRequest {
    id: number;
}

export interface PartialUpdateDeliverySheetErpImportationRequest {
    id: number;
    deliverySheetErpImportation: DeliverySheetErpImportation;
}

export interface UpdateDeliverySheetErpImportationRequest {
    id: number;
    deliverySheetErpImportation: DeliverySheetErpImportation;
}

/**
 * 
 */
export class DeliverySheetErpImportationResourceApi extends runtime.BaseAPI {

    /**
     */
    async createDeliverySheetErpImportationRaw(requestParameters: CreateDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetErpImportation>> {
        if (requestParameters.deliverySheetErpImportation === null || requestParameters.deliverySheetErpImportation === undefined) {
            throw new runtime.RequiredError('deliverySheetErpImportation','Required parameter requestParameters.deliverySheetErpImportation was null or undefined when calling createDeliverySheetErpImportation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheet-erp-importations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetErpImportationToJSON(requestParameters.deliverySheetErpImportation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetErpImportationFromJSON(jsonValue));
    }

    /**
     */
    async createDeliverySheetErpImportation(requestParameters: CreateDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetErpImportation> {
        const response = await this.createDeliverySheetErpImportationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteDeliverySheetErpImportationRaw(requestParameters: DeleteDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDeliverySheetErpImportation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheet-erp-importations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDeliverySheetErpImportation(requestParameters: DeleteDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDeliverySheetErpImportationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllDeliverySheetErpImportationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeliverySheetErpImportation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheet-erp-importations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliverySheetErpImportationFromJSON));
    }

    /**
     */
    async getAllDeliverySheetErpImportations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeliverySheetErpImportation>> {
        const response = await this.getAllDeliverySheetErpImportationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getDeliverySheetErpImportationRaw(requestParameters: GetDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetErpImportation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDeliverySheetErpImportation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheet-erp-importations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetErpImportationFromJSON(jsonValue));
    }

    /**
     */
    async getDeliverySheetErpImportation(requestParameters: GetDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetErpImportation> {
        const response = await this.getDeliverySheetErpImportationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateDeliverySheetErpImportationRaw(requestParameters: PartialUpdateDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetErpImportation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateDeliverySheetErpImportation.');
        }

        if (requestParameters.deliverySheetErpImportation === null || requestParameters.deliverySheetErpImportation === undefined) {
            throw new runtime.RequiredError('deliverySheetErpImportation','Required parameter requestParameters.deliverySheetErpImportation was null or undefined when calling partialUpdateDeliverySheetErpImportation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheet-erp-importations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetErpImportationToJSON(requestParameters.deliverySheetErpImportation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetErpImportationFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateDeliverySheetErpImportation(requestParameters: PartialUpdateDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetErpImportation> {
        const response = await this.partialUpdateDeliverySheetErpImportationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateDeliverySheetErpImportationRaw(requestParameters: UpdateDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetErpImportation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDeliverySheetErpImportation.');
        }

        if (requestParameters.deliverySheetErpImportation === null || requestParameters.deliverySheetErpImportation === undefined) {
            throw new runtime.RequiredError('deliverySheetErpImportation','Required parameter requestParameters.deliverySheetErpImportation was null or undefined when calling updateDeliverySheetErpImportation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheet-erp-importations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetErpImportationToJSON(requestParameters.deliverySheetErpImportation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetErpImportationFromJSON(jsonValue));
    }

    /**
     */
    async updateDeliverySheetErpImportation(requestParameters: UpdateDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetErpImportation> {
        const response = await this.updateDeliverySheetErpImportationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
