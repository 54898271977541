/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductCertification
 */
export interface ProductCertification {
    /**
     * 
     * @type {number}
     * @memberof ProductCertification
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCertification
     */
    certification?: ProductCertificationCertificationEnum;
    /**
     * 
     * @type {Product}
     * @memberof ProductCertification
     */
    product?: Product;
}


/**
 * @export
 */
export const ProductCertificationCertificationEnum = {
    Urstamm: 'URSTAMM',
    Hsh: 'HSH',
    Fsc: 'FSC',
    Pefc: 'PEFC'
} as const;
export type ProductCertificationCertificationEnum = typeof ProductCertificationCertificationEnum[keyof typeof ProductCertificationCertificationEnum];


/**
 * Check if a given object implements the ProductCertification interface.
 */
export function instanceOfProductCertification(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductCertificationFromJSON(json: any): ProductCertification {
    return ProductCertificationFromJSONTyped(json, false);
}

export function ProductCertificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCertification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'certification': !exists(json, 'certification') ? undefined : json['certification'],
        'product': !exists(json, 'product') ? undefined : ProductFromJSON(json['product']),
    };
}

export function ProductCertificationToJSON(value?: ProductCertification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'certification': value.certification,
        'product': ProductToJSON(value.product),
    };
}

