/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface UserCompany
 */
export interface UserCompany {
    /**
     * 
     * @type {number}
     * @memberof UserCompany
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCompany
     */
    role: UserCompanyRoleEnum;
    /**
     * 
     * @type {Company}
     * @memberof UserCompany
     */
    company?: Company;
    /**
     * 
     * @type {User}
     * @memberof UserCompany
     */
    user?: User;
}


/**
 * @export
 */
export const UserCompanyRoleEnum = {
    Administrator: 'ADMINISTRATOR',
    Forester: 'FORESTER',
    Employee: 'EMPLOYEE'
} as const;
export type UserCompanyRoleEnum = typeof UserCompanyRoleEnum[keyof typeof UserCompanyRoleEnum];


/**
 * Check if a given object implements the UserCompany interface.
 */
export function instanceOfUserCompany(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function UserCompanyFromJSON(json: any): UserCompany {
    return UserCompanyFromJSONTyped(json, false);
}

export function UserCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCompany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'role': json['role'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function UserCompanyToJSON(value?: UserCompany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'role': value.role,
        'company': CompanyToJSON(value.company),
        'user': UserToJSON(value.user),
    };
}

