import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { Tree, TreeDTO } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';
export interface TreeState {
  list: TreeDTO[];
  completeList: TreeDTO[];
  sortBy: ButtonSelect[];
}

const initialState: TreeState = {
  list: [],
  completeList: [],
  sortBy: []
};

export const tree = createSlice({
  name: 'treeList',
  initialState,
  reducers: {
    setTreeList: (state, action) => {
      let treeList = { ...state }.list;
      let treeCompleteList = { ...state }.completeList;

      treeList = action.payload;
      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(treeCompleteList, element);
      });

      state.list = treeList;
      state.completeList = treeCompleteList;
    },
    setCompleteTreeList: (state, action) => {
      // Repetition
      let treeCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(treeCompleteList, element);
      });

      state.completeList = treeCompleteList;
    },
    removeTreeElement: (state, action) => {
      let treeList = { ...state }.list;
      let treeCompleteList = { ...state }.completeList;

      let element: TreeDTO = action.payload;
      treeList = treeList.filter(e => e.id != element.id);
      treeCompleteList = treeCompleteList.filter(e => e.id != element.id);

      state.list = treeList;
      state.completeList = treeCompleteList;
    },
    setTreeListSort: (state, action) => {
      state.sortBy = action.payload;
    },
    resetTreeList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearTreeList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const { setTreeList, setCompleteTreeList, removeTreeElement, setTreeListSort, resetTreeList, clearTreeList } =
  tree.actions;

export default tree.reducer;
