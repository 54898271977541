import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import { en } from './i18n_en';
import { de } from './i18n_de';
import { environment } from '@environments/environment';

i18n.fallbacks = true;
i18n.translations = { de, en };
i18n.locale = environment.language;
if (i18n.locale != 'mock') {
  // Avoid async function for test classes
}

const changeLanguage = (languageKey: any) => {
  i18n.locale = languageKey;
};

export const switchToEnglish = () => {
  changeLanguage(LanguagesEnum.English);
};

export const switchToGerman = () => {
  changeLanguage(LanguagesEnum.German);
};

export const getCurrentLocale = () => {
  return i18n.locale;
};

export enum LanguagesEnum {
  English = 'en',
  German = 'de'
}

export { i18n };
