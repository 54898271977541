import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import TreeListView from '@components/views/tree/TreeListView';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { i18n } from '@i18n/i18n';
import { FullAvailableTreeResourceApi } from '@services/apis/FullAvailableTreeResourceApi';
import { GetTreesByLoggingDirectionEnum, Logging, TreeDTO } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { resetTreeList, setTreeList, setTreeListSort } from '../../redux/features/tree/treeSlice';
import { RootState } from '../../redux/store';
import { loggingStateIsClosed } from '../../utils/classes/UrstammUtilityCurrentState';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';
import { AlertHelper } from '@helpers/AlertHelper';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { PlatformHelper } from '@helpers/PlatformHelper';

export default function TreeListContainer({ navigation, route }: RootStackScreenProps<'TreeList'>) {
  //REDUX
  const rdxTreeList = useSelector((state: RootState) => state.persistedReducer.tree.list);
  const rdxTreeCompleteList = useSelector((state: RootState) => state.persistedReducer.tree.completeList);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.tree.sortBy);
  const dispatch = useDispatch();

  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText:
        rdxSortBy.length > 0 && rdxSortBy[0] ? rdxSortBy[0].text : i18n.t('utility_components.modal.sortBy.date_desc')
    },
    cornerBottomRight: {
      showCorner: !loggingStateIsClosed(route.params?.loggingSelected?.currentState!),
      text: i18n.t('generics.tree'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    if (route.params?.loggingSelected || route.params?.refreshList) {
      getTreeList(route.params?.loggingSelected, rdxSortBy);
    }
  }, [route.params, rdxSortBy]);

  useEffect(() => {
    return () => {
      dispatch(resetTreeList());
    };
  }, []);

  const getTreeList = (loggingSelected: Logging, order?: ButtonSelect[]): void => {
    let sort = order && order.length > 0 ? order[0].sort?.sortBy : 'creationDate';
    let direction = order && order.length > 0 ? order[0].sort?.direction : 'DESC';

    dispatch(changeLoaderStatus(true));

    if (loggingSelected && loggingSelected.id) {
      FullAvailableTreeResourceApi.getTreesByLogging(rdxTreeCompleteList, {
        loggingId: loggingSelected.id,
        sortBy: sort!,
        direction: direction! as GetTreesByLoggingDirectionEnum
      })
        .then(async (treeList: TreeDTO[]) => {
          if (treeList) {
            dispatch(changeLoaderStatus(false));
            dispatch(setTreeList(treeList));
          }
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    }
  };

  const navigateToTreeDetails = (treeSelected: TreeDTO): void => {
    UrstammNavigationHelper.navigateToTreeDetails(navigation, treeSelected, route.params?.loggingSelected);
  };

  const navigateToTreeRegistration = (): void => {
    UrstammNavigationHelper.navigateToTreeRegistration(navigation, route.params?.loggingSelected);
  };

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(resetTreeList());
    dispatch(setTreeListSort(orderSelected));
    setShowSortBy(false);
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomLeft={() => setShowSortBy(true)}
      submitBottomRight={navigateToTreeRegistration}
      title={
        <UrstammTitle
          testID={'tree_title'}
          text={i18n.t('generics.tree')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      headerDetails={[
        {
          title: i18n.t('generics.logging'),
          value: route.params?.loggingSelected.name
        }
      ]}
      view={
        <TreeListView
          navigation={navigation}
          treeList={rdxTreeList}
          modalVisible={false}
          treeSelected={(tree: TreeDTO) => navigateToTreeDetails(tree)}
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
        />
      }
    />
  );
}
