/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProjectState,
} from '../models';
import {
    ProjectStateFromJSON,
    ProjectStateToJSON,
} from '../models';

export interface CreateProjectStateRequest {
    projectState: ProjectState;
}

export interface DeleteProjectStateRequest {
    id: number;
}

export interface GetProjectStateRequest {
    id: number;
}

export interface PartialUpdateProjectStateRequest {
    id: number;
    projectState: ProjectState;
}

export interface UpdateProjectStateRequest {
    id: number;
    projectState: ProjectState;
}

/**
 * 
 */
export class ProjectStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createProjectStateRaw(requestParameters: CreateProjectStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectState>> {
        if (requestParameters.projectState === null || requestParameters.projectState === undefined) {
            throw new runtime.RequiredError('projectState','Required parameter requestParameters.projectState was null or undefined when calling createProjectState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectStateToJSON(requestParameters.projectState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectStateFromJSON(jsonValue));
    }

    /**
     */
    async createProjectState(requestParameters: CreateProjectStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectState> {
        const response = await this.createProjectStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProjectStateRaw(requestParameters: DeleteProjectStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProjectState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProjectState(requestParameters: DeleteProjectStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProjectStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProjectStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectStateFromJSON));
    }

    /**
     */
    async getAllProjectStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectState>> {
        const response = await this.getAllProjectStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectStateRaw(requestParameters: GetProjectStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProjectState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectStateFromJSON(jsonValue));
    }

    /**
     */
    async getProjectState(requestParameters: GetProjectStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectState> {
        const response = await this.getProjectStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateProjectStateRaw(requestParameters: PartialUpdateProjectStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateProjectState.');
        }

        if (requestParameters.projectState === null || requestParameters.projectState === undefined) {
            throw new runtime.RequiredError('projectState','Required parameter requestParameters.projectState was null or undefined when calling partialUpdateProjectState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectStateToJSON(requestParameters.projectState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateProjectState(requestParameters: PartialUpdateProjectStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectState> {
        const response = await this.partialUpdateProjectStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProjectStateRaw(requestParameters: UpdateProjectStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProjectState.');
        }

        if (requestParameters.projectState === null || requestParameters.projectState === undefined) {
            throw new runtime.RequiredError('projectState','Required parameter requestParameters.projectState was null or undefined when calling updateProjectState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectStateToJSON(requestParameters.projectState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectStateFromJSON(jsonValue));
    }

    /**
     */
    async updateProjectState(requestParameters: UpdateProjectStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectState> {
        const response = await this.updateProjectStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
