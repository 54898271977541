/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    type: NotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    flow: NotificationFlowEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    read?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    sent?: boolean;
    /**
     * 
     * @type {Company}
     * @memberof Notification
     */
    company?: Company;
    /**
     * 
     * @type {User}
     * @memberof Notification
     */
    companyUser?: User;
}


/**
 * @export
 */
export const NotificationTypeEnum = {
    Unknown: 'UNKNOWN'
} as const;
export type NotificationTypeEnum = typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum];

/**
 * @export
 */
export const NotificationFlowEnum = {
    Mail: 'MAIL',
    Push: 'PUSH'
} as const;
export type NotificationFlowEnum = typeof NotificationFlowEnum[keyof typeof NotificationFlowEnum];


/**
 * Check if a given object implements the Notification interface.
 */
export function instanceOfNotification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "flow" in value;

    return isInstance;
}

export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'type': json['type'],
        'flow': json['flow'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'sent': !exists(json, 'sent') ? undefined : json['sent'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'companyUser': !exists(json, 'companyUser') ? undefined : UserFromJSON(json['companyUser']),
    };
}

export function NotificationToJSON(value?: Notification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'type': value.type,
        'flow': value.flow,
        'read': value.read,
        'sent': value.sent,
        'company': CompanyToJSON(value.company),
        'companyUser': UserToJSON(value.companyUser),
    };
}

