import { environment } from '@environments/environment';
import { readAccessToken } from '@helpers/StorageHelper';
import {
  DeliverySheetDTO,
  DeliverySheetPhoto,
  ExportDeliverySheetDeliverySheetExportTypeEnum,
  ProcessorShippingNoteDTO,
  Project,
  ProjectDTO,
  ShippingNoteDTO
} from '@services/apis/generated';

export const convertBase64ToBlob = (b64: string): Blob => {
  let byteArray = base64ToByteArray(b64);
  const blob = new Blob(byteArray);
  return blob;
};

export const encodeBase64 = (data: string): string => {
  return Buffer.from(data).toString('base64');
};

export const decodeBase64 = (data: string): string => {
  return Buffer.from(data, 'base64').toString('ascii');
};

function base64ToByteArray(img: string) {
  let sliceSize = 512;
  const byteCharacters = atob(img);

  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray as never);
  }
  return byteArrays;
}

/**
 * Builds the url for permit of Logging
 * @param data
 */
export async function buildLoggingPermitUrl(data: any) {
  let mediaUrl = `api/custom/loggings/${data?.id}/download/permit`;
  let token = await readAccessToken();
  if (!data || mediaUrl == undefined || mediaUrl.trim() == '') return '././assets/images/Blank-Avatar-Image.jpg';
  if (mediaUrl?.startsWith('http:') || mediaUrl?.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  return `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&time=${timeMillis}`;
}

/**
 * Builds the url for photo of logging
 * @param data
 */
export async function buildLoggingPhotoUrl(data: any) {
  let mediaUrl = `api/custom/logging/${data?.loggingPhoto?.id}/download/photo`;
  let token = await readAccessToken();
  if (!data || mediaUrl == undefined || mediaUrl.trim() == '') return '././assets/images/Blank-Avatar-Image.jpg';
  if (mediaUrl?.startsWith('http:') || mediaUrl.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  return `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&${timeMillis}`;
}

export async function buildDeliverySheetFileUrl(data: any) {
  let mediaUrl = `api${data}`;
  let token = await readAccessToken();
  if (!data || mediaUrl == undefined || mediaUrl.trim() == '') return '././assets/images/Blank-Avatar-Image.jpg';
  if (mediaUrl?.startsWith('http:') || mediaUrl.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  return `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&${timeMillis}`;
}

/**
 * Builds the url for photo of Pile
 * @param data
 */
export async function buildPilePhotoUrl(data: any) {
  let mediaUrl = `api/custom/piles/${data?.id}/download/photo`;
  let token = await readAccessToken();
  if (!data || mediaUrl == undefined || mediaUrl.trim() == '') return '././assets/images/Blank-Avatar-Image.jpg';
  if (mediaUrl?.startsWith('http:') || mediaUrl?.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  return `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&time=${timeMillis}`;
}

/**
 * Builds the url for photo of Tree
 * @param data
 */
export async function buildTreePhotoUrl(data: any) {
  let mediaUrl = `api/custom/trees/${data?.id}/download/photo`;
  let token = await readAccessToken();
  if (!data || mediaUrl == undefined || mediaUrl.trim() == '') return '././assets/images/Blank-Avatar-Image.jpg';
  if (mediaUrl?.startsWith('http:') || mediaUrl?.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  return `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&time=${timeMillis}`;
}

export async function buildPacketPhotoUrl(data: any) {
  let mediaUrl = `api${data?.photoUrl}`;
  let token = await readAccessToken();
  if (!data || mediaUrl == undefined || mediaUrl.trim() == '') return '././assets/images/Blank-Avatar-Image.jpg';
  if (mediaUrl?.startsWith('http:') || mediaUrl?.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  return `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&time=${timeMillis}`;
}

/**
 * Builds the url for photo of DS
 * @param data
 */
export async function buildDeliverySheetPhotoUrl(data: DeliverySheetPhoto) {
  let mediaUrl = `api${data?.photoUrl}`;
  let token = await readAccessToken();
  if (!data || mediaUrl == undefined || mediaUrl.trim() == '') return '././assets/images/Blank-Avatar-Image.jpg';
  if (mediaUrl?.startsWith('http:') || mediaUrl?.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  return `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&time=${timeMillis}`;
}

/**
 * Builds the url for Delivery Sheet export
 * @param data
 * @param exportType Type of export (Xml or Pdf)
 */
export async function buildDeliverySheetExportUrl(
  data: DeliverySheetDTO,
  exportType: ExportDeliverySheetDeliverySheetExportTypeEnum,
  note?: string
) {
  let mediaUrl = `api/custom/delivery-sheets/${data?.id}/export/${exportType}`;
  let token = await readAccessToken();
  if (mediaUrl?.startsWith('http:') || mediaUrl?.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  let url = `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&time=${timeMillis}`;
  if (note && note != '') url = `${url}&notes=${note}`;
  return url;
}

export async function buildShippingNotePdfDownloadUrl(data: ShippingNoteDTO) {
  let mediaUrl = `api/custom/shipping-notes/${data?.id}/download/pdf`;
  let token = await readAccessToken();
  if (mediaUrl?.startsWith('http:') || mediaUrl?.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  return `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&time=${timeMillis}`;
}

export async function buildProcessorShippingNotePdfDownloadUrl(data: ProcessorShippingNoteDTO) {
  let mediaUrl = `api/custom/processor-shipping-notes/${data?.id}/download/pdf`;
  let token = await readAccessToken();
  if (mediaUrl?.startsWith('http:') || mediaUrl?.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  return `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&time=${timeMillis}`;
}

export async function buildProductPhotoUrl(data: any) {
  let mediaUrl = `api${data?.photoUrl}`;
  let token = await readAccessToken();
  if (!data || mediaUrl == undefined || mediaUrl.trim() == '') return '././assets/images/Blank-Avatar-Image.jpg';
  if (mediaUrl?.startsWith('http:') || mediaUrl?.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  return `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&time=${timeMillis}`;
}

export async function buildProjectPhotoUrl(data: Project | ProjectDTO) {
  let mediaUrl = `api${data?.photoUrl}`;
  let token = await readAccessToken();
  if (!data || mediaUrl == undefined || mediaUrl.trim() == '') return '././assets/images/Blank-Avatar-Image.jpg';
  if (mediaUrl?.startsWith('http:') || mediaUrl?.startsWith('https:')) return mediaUrl;
  let timeMillis = new Date().getMilliseconds();
  return `${environment.apiUrl + '/' + mediaUrl}?${environment.inlineAuthTokenKey}=${token}&time=${timeMillis}`;
}
