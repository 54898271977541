/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BooleanFilter } from './BooleanFilter';
import {
    BooleanFilterFromJSON,
    BooleanFilterFromJSONTyped,
    BooleanFilterToJSON,
} from './BooleanFilter';
import type { DeliverySheetStateEnumFilter } from './DeliverySheetStateEnumFilter';
import {
    DeliverySheetStateEnumFilterFromJSON,
    DeliverySheetStateEnumFilterFromJSONTyped,
    DeliverySheetStateEnumFilterToJSON,
} from './DeliverySheetStateEnumFilter';
import type { DeliverySheetUrstammStateEnumFilter } from './DeliverySheetUrstammStateEnumFilter';
import {
    DeliverySheetUrstammStateEnumFilterFromJSON,
    DeliverySheetUrstammStateEnumFilterFromJSONTyped,
    DeliverySheetUrstammStateEnumFilterToJSON,
} from './DeliverySheetUrstammStateEnumFilter';
import type { DoubleFilter } from './DoubleFilter';
import {
    DoubleFilterFromJSON,
    DoubleFilterFromJSONTyped,
    DoubleFilterToJSON,
} from './DoubleFilter';
import type { InstantFilter } from './InstantFilter';
import {
    InstantFilterFromJSON,
    InstantFilterFromJSONTyped,
    InstantFilterToJSON,
} from './InstantFilter';
import type { LongFilter } from './LongFilter';
import {
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
} from './LongFilter';
import type { RecordingTypeEnumFilter } from './RecordingTypeEnumFilter';
import {
    RecordingTypeEnumFilterFromJSON,
    RecordingTypeEnumFilterFromJSONTyped,
    RecordingTypeEnumFilterToJSON,
} from './RecordingTypeEnumFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface CustomDeliverySheetCriteria
 */
export interface CustomDeliverySheetCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    comment?: StringFilter;
    /**
     * 
     * @type {InstantFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    creationDate?: InstantFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    sumCubage?: DoubleFilter;
    /**
     * 
     * @type {BooleanFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    sentToSawmill?: BooleanFilter;
    /**
     * 
     * @type {DeliverySheetStateEnumFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    currentState?: DeliverySheetStateEnumFilter;
    /**
     * 
     * @type {DeliverySheetUrstammStateEnumFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    currentUrstammState?: DeliverySheetUrstammStateEnumFilter;
    /**
     * 
     * @type {RecordingTypeEnumFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    recordingType?: RecordingTypeEnumFilter;
    /**
     * 
     * @type {BooleanFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    automaticallyCreated?: BooleanFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    amountOfTruckJourneys?: DoubleFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    uuid?: StringFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    pileId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    forestryId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    sawmillId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    deliverySheetErpImportationId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    deliverySheetPhotoId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    deliverySheetStateId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    trunkId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    batchId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomDeliverySheetCriteria
     */
    packetId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomDeliverySheetCriteria
     */
    distinct?: boolean;
}

/**
 * Check if a given object implements the CustomDeliverySheetCriteria interface.
 */
export function instanceOfCustomDeliverySheetCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomDeliverySheetCriteriaFromJSON(json: any): CustomDeliverySheetCriteria {
    return CustomDeliverySheetCriteriaFromJSONTyped(json, false);
}

export function CustomDeliverySheetCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomDeliverySheetCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : StringFilterFromJSON(json['name']),
        'comment': !exists(json, 'comment') ? undefined : StringFilterFromJSON(json['comment']),
        'creationDate': !exists(json, 'creationDate') ? undefined : InstantFilterFromJSON(json['creationDate']),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : DoubleFilterFromJSON(json['sumCubage']),
        'sentToSawmill': !exists(json, 'sentToSawmill') ? undefined : BooleanFilterFromJSON(json['sentToSawmill']),
        'currentState': !exists(json, 'currentState') ? undefined : DeliverySheetStateEnumFilterFromJSON(json['currentState']),
        'currentUrstammState': !exists(json, 'currentUrstammState') ? undefined : DeliverySheetUrstammStateEnumFilterFromJSON(json['currentUrstammState']),
        'recordingType': !exists(json, 'recordingType') ? undefined : RecordingTypeEnumFilterFromJSON(json['recordingType']),
        'automaticallyCreated': !exists(json, 'automaticallyCreated') ? undefined : BooleanFilterFromJSON(json['automaticallyCreated']),
        'amountOfTruckJourneys': !exists(json, 'amountOfTruckJourneys') ? undefined : DoubleFilterFromJSON(json['amountOfTruckJourneys']),
        'uuid': !exists(json, 'uuid') ? undefined : StringFilterFromJSON(json['uuid']),
        'pileId': !exists(json, 'pileId') ? undefined : LongFilterFromJSON(json['pileId']),
        'forestryId': !exists(json, 'forestryId') ? undefined : LongFilterFromJSON(json['forestryId']),
        'sawmillId': !exists(json, 'sawmillId') ? undefined : LongFilterFromJSON(json['sawmillId']),
        'deliverySheetErpImportationId': !exists(json, 'deliverySheetErpImportationId') ? undefined : LongFilterFromJSON(json['deliverySheetErpImportationId']),
        'deliverySheetPhotoId': !exists(json, 'deliverySheetPhotoId') ? undefined : LongFilterFromJSON(json['deliverySheetPhotoId']),
        'deliverySheetStateId': !exists(json, 'deliverySheetStateId') ? undefined : LongFilterFromJSON(json['deliverySheetStateId']),
        'trunkId': !exists(json, 'trunkId') ? undefined : LongFilterFromJSON(json['trunkId']),
        'batchId': !exists(json, 'batchId') ? undefined : LongFilterFromJSON(json['batchId']),
        'packetId': !exists(json, 'packetId') ? undefined : LongFilterFromJSON(json['packetId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CustomDeliverySheetCriteriaToJSON(value?: CustomDeliverySheetCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'name': StringFilterToJSON(value.name),
        'comment': StringFilterToJSON(value.comment),
        'creationDate': InstantFilterToJSON(value.creationDate),
        'sumCubage': DoubleFilterToJSON(value.sumCubage),
        'sentToSawmill': BooleanFilterToJSON(value.sentToSawmill),
        'currentState': DeliverySheetStateEnumFilterToJSON(value.currentState),
        'currentUrstammState': DeliverySheetUrstammStateEnumFilterToJSON(value.currentUrstammState),
        'recordingType': RecordingTypeEnumFilterToJSON(value.recordingType),
        'automaticallyCreated': BooleanFilterToJSON(value.automaticallyCreated),
        'amountOfTruckJourneys': DoubleFilterToJSON(value.amountOfTruckJourneys),
        'uuid': StringFilterToJSON(value.uuid),
        'pileId': LongFilterToJSON(value.pileId),
        'forestryId': LongFilterToJSON(value.forestryId),
        'sawmillId': LongFilterToJSON(value.sawmillId),
        'deliverySheetErpImportationId': LongFilterToJSON(value.deliverySheetErpImportationId),
        'deliverySheetPhotoId': LongFilterToJSON(value.deliverySheetPhotoId),
        'deliverySheetStateId': LongFilterToJSON(value.deliverySheetStateId),
        'trunkId': LongFilterToJSON(value.trunkId),
        'batchId': LongFilterToJSON(value.batchId),
        'packetId': LongFilterToJSON(value.packetId),
        'distinct': value.distinct,
    };
}

