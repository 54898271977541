import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import {
  applySavingToReference,
  deepCopyAndAddSelectedParameter,
  restoreListToReference,
  selectElementFormList,
  selectSlideElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import PacketDetailsView from '@components/views/packet/PacketDetailsView';
import { PacketForm } from '@components/views/packet/PacketRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { resetBatchList } from '@redux/features/batch/batchSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import {
  resetCompanyPacketWoodQualityList,
  setCompanyPacketWoodQualityList
} from '@redux/features/packet/companyPacketWoodQualitySlice';
import { resetPacketList } from '@redux/features/packet/packetSlice';
import { resetCompanySurfaceList, setCompanySurfaceList } from '@redux/features/surface/companySurfaceSlice';
import { RootState } from '@redux/store';
import {
  customCompanyPacketWoodQualityResourceApi,
  customCompanySurfaceResourceApi,
  customPacketResourceApi,
  customTrunkResourceApi
} from '@services/apis/ApiConfiguration';
import {
  BatchDTO,
  Company,
  CompanyPacketWoodQuality,
  CompanySurface,
  Packet,
  PacketCertification,
  PacketCertificationCertificationEnum,
  PacketCurrentStateEnum,
  PacketDTO,
  PacketSpecies,
  PacketSpeciesSpeciesEnum,
  Trunk
} from '@services/apis/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { RouteBatchDetails } from '../../navigation/UrstammNavigationRoutes';
import { getTextByTreeOrTrunkSpecies } from '../../utils/classes/UrstammUtilityCurrentState';
import {
  generateSet,
  generateSetForCertification,
  generateSetForPacketSpecies,
  getAllCompanyProcessorConnectedToMe,
  getCompanyProductType,
  sleep
} from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function PacketDetailsContainer({ route, navigation }: RootStackScreenProps<'PacketDetails'>) {
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('generics.packet'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  const dispatch = useDispatch();

  // Redux state
  const rdxCompanyProductTypeList = useSelector((state: RootState) => state.persistedReducer.companyProductType.list);
  const rdxCompanyPacketWoodQualityList = useSelector(
    (state: RootState) => state.persistedReducer.companyPacketWoodQuality.list
  );
  const rdxCompanySurfaceList = useSelector((state: RootState) => state.persistedReducer.companySurface.list);

  const [packetSelected, setPacketSelected] = useState<PacketDTO>();
  const [batchSelected, setBatchSelected] = useState<BatchDTO>();
  const [packetSpeciesList, setPacketSpeciesList] = useStateWithCallbackLazy<any[]>([]);
  const [packetSpeciesListReference, setPacketSpeciesListReference] = useStateWithCallbackLazy<any[]>([]);
  const [trunkListByBatchDS, setTrunkListByBatchDS] = useStateWithCallbackLazy<any[]>([]);
  const [trunkListByBatchDSReference, setTrunkListByBatchDSReference] = useStateWithCallbackLazy<any[]>([]);
  const [certificationList, setCertificationList] = useStateWithCallbackLazy<any[]>([]);
  const [certificationListReference, setCertificationListReference] = useStateWithCallbackLazy<any[]>([]);
  const [processorList, setProcessorList] = useState<Company[]>([]);

  const readOnly = useMemo(() => {
    return !!route.params?.readOnly;
  }, [!!route.params?.readOnly]);

  useEffect(() => {
    getPacketCustom(route.params?.packetSelected)
  }, []);

  const getPacketCustom = (packetSelected: Packet) => {
    dispatch(changeLoaderStatus(true));

    customPacketResourceApi
      .getPacketCustom({ id: packetSelected.id! })
      .then((packet: PacketDTO) => {
        dispatch(changeLoaderStatus(false));
        if (packet) {
          let trunks = packet.trunks ? [...packet.trunks] : [];
          setPacketSelected(packet);
          setBatchSelected(readOnly ? packet.batch : route.params?.batchSelected);
          if (!readOnly) getTrunkListByDeliverySheet(trunks);
          getAllPacketCertifications(packet);
          getAllPacketSpecies(packet);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanyPacketWoodQualityResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanyPacketWoodQualityResourceApi
      .getAllCompanyPacketWoodQualitysForCompany({})
      .then((packetWoodQualityList: CompanyPacketWoodQuality[]) => {
        dispatch(changeLoaderStatus(false));

        if (packetWoodQualityList) {
          dispatch(resetCompanyPacketWoodQualityList());
          dispatch(setCompanyPacketWoodQualityList(packetWoodQualityList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanySurfaceResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanySurfaceResourceApi
      .getAllCompanySurfacesForCompany({})
      .then((surfaceList: CompanySurface[]) => {
        dispatch(changeLoaderStatus(false));

        if (surfaceList) {
          dispatch(resetCompanySurfaceList());
          dispatch(setCompanySurfaceList(surfaceList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const initializeData = (packetSelected: Packet, batchSelected: BatchDTO) => {
    getPacketCustom(packetSelected);
    setBatchSelected(route.params?.batchSelected);
    getAllCompanyProcessorConnectedToMe(dispatch, setProcessorList);
    getCompanyProductType(dispatch);
    getCompanyPacketWoodQualityResourceApi();
    getCompanySurfaceResourceApi();
  };

  // useEffect(() => {
  //   getPacketCustom(packetSelected);
  // }, [])

  useEffect(() => {
    if (route.params?.packetSelected && route.params?.batchSelected) {
      initializeData(route.params.packetSelected, route.params.batchSelected);
    }
  }, [route.params]);

  const goBack = async (): Promise<void> => {
    // Get the source
    const returnTo: string = (route.params?.returnTo || '') ?? '';
    switch (returnTo) {
      case 'back':
        Keyboard.dismiss();
        await sleep(PlatformHelper.isIos() ? 300 : 100);
        navigation.goBack();
        break;
      case RouteBatchDetails:
      default:
        dispatch(resetBatchList());
        Keyboard.dismiss();
        await sleep(PlatformHelper.isIos() ? 300 : 100);
        UrstammNavigationHelper.navigateToBatchDetails(navigation, batchSelected, true);
        break;
    }
  };

  const getTrunkListByDeliverySheet = (trunksSelected?: Trunk[]): void => {
    dispatch(changeLoaderStatus(true));
    let trunkByBatchDS: Trunk[] = [];
    let dsList = [...route.params?.batchSelected?.deliverySheets!];

    dsList.forEach((ds, idx) => {
      customTrunkResourceApi
        .getTrunksByDeliverySheet({ deliverySheetId: ds.id! })
        .then((list: Trunk[]) => {
          dispatch(changeLoaderStatus(false));
          if (list) {
            list.forEach(trunk => trunkByBatchDS.push(trunk));
            if (idx == dsList.length - 1) {
              setTrunkListByBatchDS(trunkByBatchDS, listUpdated => {
                deepCopyAndAddSelectedParameter(
                  listUpdated,
                  setTrunkListByBatchDS,
                  setTrunkListByBatchDSReference,
                  trunksSelected
                );
              });
            }
          }
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    });
  };

  const getAllPacketCertifications = (packet: PacketDTO) => {
    let filterList: any[] = [];
    Object.values(PacketCertificationCertificationEnum).map(state => {
      let type: any = {
        id: state,
        name: state,
        selected: state == PacketCertificationCertificationEnum.Urstamm,
        disabled: state == PacketCertificationCertificationEnum.Urstamm
      };
      filterList.push(type);
    });

    let packetCertification = packet.packetCertifications ? [...packet.packetCertifications] : [];
    if (packetCertification && packetCertification.length > 0) {
      packetCertification.forEach(elemSelected => {
        for (let val of filterList) {
          if (val.id == elemSelected.certification) {
            val.selected = true;
            break;
          }
        }
      });
    }
    setCertificationList(filterList, () => {});
    setCertificationListReference(filterList, () => {});
  };

  const getAllPacketSpecies = (packet: PacketDTO) => {
    let packetSpecies = packet.packetSpecies ? [...packet.packetSpecies] : [];

    let filterList: any[] = Object.values(PacketSpeciesSpeciesEnum).map(state => ({
      id: state,
      name: state,
      text: getTextByTreeOrTrunkSpecies(state),
      selected: !!packetSpecies.find(p => p.species === state),
      active: !!packetSpecies.find(p => p.species === state),
      disabled: false
    }));
    setPacketSpeciesList(filterList, () => {});
    setPacketSpeciesListReference(filterList, () => {});
  };

  const submitPacket = (data: PacketForm) => {
    dispatch(changeLoaderStatus(true));

    let certificationSet: Set<PacketCertification> = new Set(generateSetForCertification(certificationList));
    let speciesSet: Set<PacketSpecies> = generateSetForPacketSpecies(packetSpeciesList);
    let trunksSet: Set<Trunk> = new Set(generateSet(trunkListByBatchDS));

    let packetObj: Packet = {
      id: packetSelected?.id,
      batch: batchSelected as any,
      currentState: PacketCurrentStateEnum.InProgress,
      uniqueIdentifier: data.packet.uniqueIdentifier!,
      name: data.packet.name!,
      creationDate: data.packet.creationDate,
      length: data.packet.length,
      width: data.packet.width,
      thickness: data.packet.thickness,
      amountOfProducts: data.packet.amountOfProducts,
      discount: data.packet.discount,
      dryness: data.packet.dryness,
      planningDimension: data.packet.planningDimension,
      woodQuality: data.packet.woodQuality,
      comment: data.packet.comment,
      processor: data.packet.processorCustomName ? undefined : data.packet.processor,
      processorCustomName:
        data.packet.processorCustomName && data.packet.processorCustomName.length > 0
          ? data.packet.processorCustomName
          : undefined,
      packetCertifications: certificationSet,
      packetSpecies: speciesSet,
      productType: data.packet.productType,
      surface: data.packet.surface,
      trunks: trunksSet,
      photoCreationDate: data.packet.photoCreationDate
    };

    customPacketResourceApi
      .updatePacketCustom({
        id: packetSelected?.id!,
        entityWithFileDTOPacket: { entity: packetObj }
      })
      .then(async (packet: Packet) => {
        dispatch(changeLoaderStatus(false));

        if (packet) {
          dispatch(resetPacketList());
          Keyboard.dismiss();
          await sleep(PlatformHelper.isIos() ? 300 : 100);
          UrstammNavigationHelper.navigateToBatchDetails(navigation, batchSelected, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'packet_title'}
          text={i18n.t('generics.packet')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'packet_details_subtitle'}
          text={route.params?.packetSelected?.uniqueIdentifier}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={[{ title: i18n.t('generics.name'), value: route.params?.packetSelected?.name }]}
      view={
        batchSelected ? (
          <PacketDetailsView
            navigation={navigation}
            batchDTO={batchSelected}
            packetSelected={packetSelected}
            submitPacketForm={(packetForm: PacketForm) => submitPacket(packetForm)}
            companyProductTypeProps={{
              companyProductTypeList: rdxCompanyProductTypeList
            }}
            companyPacketWoodQualityProps={{
              companyPacketWoodQualityList: rdxCompanyPacketWoodQualityList
            }}
            companySurfaceProps={{
              companySurfaceList: rdxCompanySurfaceList
            }}
            trunkProps={{
              trunkList: trunkListByBatchDS,
              trunkSelected: (data: any) => selectElementFormList(data, setTrunkListByBatchDS, trunkListByBatchDS),
              resetTrunkSelected: () => restoreListToReference(setTrunkListByBatchDS, trunkListByBatchDSReference),
              confirmTrunkSelected: () => applySavingToReference(setTrunkListByBatchDSReference, trunkListByBatchDS)
            }}
            certificationProps={{
              certificationList: certificationList,
              certificationSelected: (data: any) =>
                selectElementFormList(data, setCertificationList, certificationList),
              resetCertificationSelected: () =>
                restoreListToReference(setCertificationList, certificationListReference),
              confirmCertificationSelected: () =>
                applySavingToReference(setCertificationListReference, certificationList)
            }}
            speciesProps={{
              speciesList: packetSpeciesList,
              speciesSelected: (data: any) => selectSlideElementFormList(data, setPacketSpeciesList, packetSpeciesList),
              resetSpeciesSelected: () => restoreListToReference(setPacketSpeciesList, packetSpeciesListReference),
              confirmSpeciesSelected: () => applySavingToReference(setPacketSpeciesListReference, packetSpeciesList)
            }}
            processorList={processorList}
            readOnly={readOnly}
          />
        ) : (
          <></>
        )
      }
    />
  );
}
