/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliverySheetStateEnumFilter
 */
export interface DeliverySheetStateEnumFilter {
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetStateEnumFilter
     */
    equals?: DeliverySheetStateEnumFilterEqualsEnum;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetStateEnumFilter
     */
    notEquals?: DeliverySheetStateEnumFilterNotEqualsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DeliverySheetStateEnumFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliverySheetStateEnumFilter
     */
    _in?: Array<DeliverySheetStateEnumFilterInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliverySheetStateEnumFilter
     */
    notIn?: Array<DeliverySheetStateEnumFilterNotInEnum>;
}


/**
 * @export
 */
export const DeliverySheetStateEnumFilterEqualsEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetStateEnumFilterEqualsEnum = typeof DeliverySheetStateEnumFilterEqualsEnum[keyof typeof DeliverySheetStateEnumFilterEqualsEnum];

/**
 * @export
 */
export const DeliverySheetStateEnumFilterNotEqualsEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetStateEnumFilterNotEqualsEnum = typeof DeliverySheetStateEnumFilterNotEqualsEnum[keyof typeof DeliverySheetStateEnumFilterNotEqualsEnum];

/**
 * @export
 */
export const DeliverySheetStateEnumFilterInEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetStateEnumFilterInEnum = typeof DeliverySheetStateEnumFilterInEnum[keyof typeof DeliverySheetStateEnumFilterInEnum];

/**
 * @export
 */
export const DeliverySheetStateEnumFilterNotInEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetStateEnumFilterNotInEnum = typeof DeliverySheetStateEnumFilterNotInEnum[keyof typeof DeliverySheetStateEnumFilterNotInEnum];


/**
 * Check if a given object implements the DeliverySheetStateEnumFilter interface.
 */
export function instanceOfDeliverySheetStateEnumFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeliverySheetStateEnumFilterFromJSON(json: any): DeliverySheetStateEnumFilter {
    return DeliverySheetStateEnumFilterFromJSONTyped(json, false);
}

export function DeliverySheetStateEnumFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliverySheetStateEnumFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
        'notEquals': !exists(json, 'notEquals') ? undefined : json['notEquals'],
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
    };
}

export function DeliverySheetStateEnumFilterToJSON(value?: DeliverySheetStateEnumFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals,
        'notEquals': value.notEquals,
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
    };
}

