import AsyncStorage from '@react-native-async-storage/async-storage';
import FSStorage from 'redux-persist-expo-fs-storage';
import { combineReducers, configureStore, isPlainObject } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import batchSlice from './features/batch/batchSlice';
import shippingNoteSlice from './features/shipping-note/shippingNoteSlice';
import calculationSlice from './features/calculation/calculationSlice';
import deliverySheetSlice from './features/delivery-sheet/deliverySheetSlice';
import batchFilterSlice from './features/filter/batchFilterSlice';
import shippingNoteFilterSlice from './features/filter/shippingNoteFilterSlice';
import deliverySheetFilterSlice from './features/filter/deliverySheetFilterSlice';
import loggingFilterSlice from './features/filter/loggingFilterSlice';
import packetFilterSlice from './features/filter/packetFilterSlice';
import trunkFilterSlice from './features/filter/trunkFilterSlice';
import loaderSlice from './features/loader/loaderSlice';
import loggingSlice from './features/logging/loggingSlice';
import offlineSyncSlice from './features/offlineSync/offlineSyncSlice';
import otherAssortmentSlice from './features/otherAssortment/otherAssortmentSlice';
import packetSlice from './features/packet/packetSlice';
import pileSlice from './features/pile/pileSlice';
import plotSlice from './features/plot/plotSlice';
import companyProductTypeSlice from './features/product-type/companyProductTypeSlice';
import sawmillSlice from './features/sawmill/sawmillSlice';
import companyPacketWoodQualitySlice from './features/packet/companyPacketWoodQualitySlice';
import companySurfaceSlice from './features/surface/companySurfaceSlice';
import treeSlice from './features/tree/treeSlice';
import trunkSlice from './features/trunk/trunkSlice';
import userSlice from './features/user/userSlice';
import inputSlice from './features/utility/inputSlice';
import { PlatformHelper } from '@helpers/PlatformHelper';
import incomingProcessorShippingNoteSlice from './features/processor-shipping-note/incomingProcessorShippingNoteSlice';
import incomingProcessorShippingNoteFilterSlice from './features/filter/incomingProcessorShippingNoteFilterSlice';
import processorBatchFilterSlice from './features/filter/processorBatchFilterSlice';
import projectSlice from './features/project/projectSlice';
import projectFilteSlice from './features/filter/projectFilteSlice';
import processorBatchSlice from './features/processor-batch/processorBatchSlice';
import productSlice from './features/product/productSlice';
import productFilterSlice from './features/filter/productFilterSlice';
import outgoingProcessorShippingNoteFilterSlice from './features/filter/outgoingProcessorShippingNoteFilterSlice';
import outgoingProcessorShippingNoteSlice from './features/processor-shipping-note/outgoingProcessorShippingNoteSlice';
import companyAppearanceSlice from './features/appearance/companyAppearanceSlice';

/**
 * Overrides the default isPlain function of Redux toolkit in order to avoid
 * not-serializable warnings for Dates
 * References:
 * https://redux-toolkit.js.org/api/serializabilityMiddleware#isplain
 * https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
 */
export function isPlainCustom(val: any) {
  return (
    typeof val === 'undefined' ||
    val === null ||
    typeof val === 'string' ||
    typeof val === 'boolean' ||
    typeof val === 'number' ||
    Array.isArray(val) ||
    isPlainObject(val) ||
    val instanceof Date
  );
}

/**
 * REDUX PERSISTANCE
 */

const reducers = combineReducers({
  batch: batchSlice,
  batchFilter: batchFilterSlice,
  shippingNote: shippingNoteSlice,
  shippingNoteFilter: shippingNoteFilterSlice,
  processorBatch: processorBatchSlice,
  processorBatchFilter: processorBatchFilterSlice,
  project: projectSlice,
  projectFilter: projectFilteSlice,
  incomingProcessorShippingNote: incomingProcessorShippingNoteSlice,
  incomingProcessorShippingNoteFilter: incomingProcessorShippingNoteFilterSlice,
  outgoingProcessorShippingNote: outgoingProcessorShippingNoteSlice,
  outgoingProcessorShippingNoteFilter: outgoingProcessorShippingNoteFilterSlice,
  calculation: calculationSlice,
  deliverySheet: deliverySheetSlice,
  deliverySheetFilter: deliverySheetFilterSlice,
  input: inputSlice,
  loader: loaderSlice,
  logging: loggingSlice,
  loggingFilter: loggingFilterSlice,
  offlineSync: offlineSyncSlice,
  otherAssortment: otherAssortmentSlice,
  packet: packetSlice,
  packetFilter: packetFilterSlice,
  product: productSlice,
  productFilter: productFilterSlice,
  pile: pileSlice,
  plot: plotSlice,
  companyProductType: companyProductTypeSlice,
  companyPacketWoodQuality: companyPacketWoodQualitySlice,
  companySurface: companySurfaceSlice,
  companyAppearance: companyAppearanceSlice,
  sawmill: sawmillSlice,
  tree: treeSlice,
  trunk: trunkSlice,
  trunkFilter: trunkFilterSlice,
  user: userSlice
});

const persistConfig = {
  key: 'root',
  storage: PlatformHelper.isAndroid() ? FSStorage() : AsyncStorage,
  whitelist: [
    'user',
    'calculation',
    'logging',
    'deliverySheet',
    'sawmill',
    'otherAssortment',
    'tree',
    'trunk',
    'pile',
    'plot',
    'offlineSync'
  ]
  //stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable: isPlainCustom,
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }),
  reducer: {
    persistedReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
