import Moment from 'moment';

export class NumberHelper {
  /**
   * Rounds the given number with specified precision
   * @param number Number to round
   * @param precision Precision for truncate
   * @returns The rounded number
   */
  public static roundDecimals(number: number | undefined, precision: number): number {
    if (!number || isNaN(number)) return 0;
    const divider = 10 ** precision;
    return Math.trunc(number * divider) / divider;
  }

  /**
   * Rounds a given number to closest down 5
   * @param number Number to round
   * @returns The closest 5
   */
  public static roundDownTo5(number: number | undefined): number {
    if (!number || isNaN(number)) return 0;
    return 5 * Math.trunc(number / 5);
  }

  /**
   * Rounds the given numnber with 2 decimals
   * @param number Number to round
   * @returns The rounded number
   */
  public static roundWithTwoDecimals(number: number | undefined): number {
    return this.roundDecimals(number, 2);
  }

  /**
   * Rounds the given numnber with 3 decimals
   * @param number Number to round
   * @returns The rounded number
   */
  public static roundWithThreeDecimals(number: number | undefined): number {
    return this.roundDecimals(number, 3);
  }

  /**
   * Increment the given photo number assuming it is in a form A000
   * where A is the user letter and the other numbers are the progressive.
   *
   * @param currentPhotoNumber The current photo number to increment
   * @returns The new photo number
   */
  public static incrementPhotoNumber(currentPhotoNumber: string) {
    let progressive = Number(currentPhotoNumber.slice(1));
    progressive = progressive + 1;
    let finalNumber = NumberHelper.numberOfDigits(progressive);
    switch (finalNumber) {
      case 1:
        finalNumber = '00' + progressive;
        break;
      case 2:
        finalNumber = '0' + progressive;
        break;
      case 3:
        finalNumber = '' + progressive;
        break;
    }
    return finalNumber;
  }

  /**
   * Counts the digits in a given number
   * @param number number to check
   * @returns The count of digits
   */
  private static numberOfDigits(number) {
    if (number >= 10) {
      return NumberHelper.numberOfDigits(number / 10) + 1;
    }
    return 1;
  }
}
