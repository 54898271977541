export const ColorTheme = {
  mainGreen: '#6F8E30',
  lightGreen: '#AFCB1F',
  lightGrey: '#EBEFF2',
  mediumGrey: '#999999',
  fineGrey: '#d6d6d6',
  darkGrey: '#595858',
  ivory: '#F5F7F9',
  orange: '#EDA46B',
  yellow: '#E7C61B',
  lightBlack: '#151522',
  warn: '#D70000',
  black: '#151522'
};
