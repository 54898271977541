/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DoubleFilter } from './DoubleFilter';
import {
    DoubleFilterFromJSON,
    DoubleFilterFromJSONTyped,
    DoubleFilterToJSON,
} from './DoubleFilter';
import type { InstantFilter } from './InstantFilter';
import {
    InstantFilterFromJSON,
    InstantFilterFromJSONTyped,
    InstantFilterToJSON,
} from './InstantFilter';
import type { LongFilter } from './LongFilter';
import {
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
} from './LongFilter';
import type { ProjectStateEnumFilter } from './ProjectStateEnumFilter';
import {
    ProjectStateEnumFilterFromJSON,
    ProjectStateEnumFilterFromJSONTyped,
    ProjectStateEnumFilterToJSON,
} from './ProjectStateEnumFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface CustomProjectCriteria
 */
export interface CustomProjectCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProjectCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProjectCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProjectCriteria
     */
    uniqueNumber?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProjectCriteria
     */
    comment?: StringFilter;
    /**
     * 
     * @type {InstantFilter}
     * @memberof CustomProjectCriteria
     */
    creationDate?: InstantFilter;
    /**
     * 
     * @type {ProjectStateEnumFilter}
     * @memberof CustomProjectCriteria
     */
    currentState?: ProjectStateEnumFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomProjectCriteria
     */
    latitude?: DoubleFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomProjectCriteria
     */
    longitude?: DoubleFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomProjectCriteria
     */
    sumCubage?: DoubleFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProjectCriteria
     */
    photoFilename?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProjectCriteria
     */
    photoFilepath?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProjectCriteria
     */
    photoUrl?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProjectCriteria
     */
    photoMd5?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProjectCriteria
     */
    uuid?: StringFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProjectCriteria
     */
    processorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProjectCriteria
     */
    authorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProjectCriteria
     */
    photoAuthorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProjectCriteria
     */
    projectStateId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProjectCriteria
     */
    productId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomProjectCriteria
     */
    distinct?: boolean;
}

/**
 * Check if a given object implements the CustomProjectCriteria interface.
 */
export function instanceOfCustomProjectCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomProjectCriteriaFromJSON(json: any): CustomProjectCriteria {
    return CustomProjectCriteriaFromJSONTyped(json, false);
}

export function CustomProjectCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomProjectCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : StringFilterFromJSON(json['name']),
        'uniqueNumber': !exists(json, 'uniqueNumber') ? undefined : StringFilterFromJSON(json['uniqueNumber']),
        'comment': !exists(json, 'comment') ? undefined : StringFilterFromJSON(json['comment']),
        'creationDate': !exists(json, 'creationDate') ? undefined : InstantFilterFromJSON(json['creationDate']),
        'currentState': !exists(json, 'currentState') ? undefined : ProjectStateEnumFilterFromJSON(json['currentState']),
        'latitude': !exists(json, 'latitude') ? undefined : DoubleFilterFromJSON(json['latitude']),
        'longitude': !exists(json, 'longitude') ? undefined : DoubleFilterFromJSON(json['longitude']),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : DoubleFilterFromJSON(json['sumCubage']),
        'photoFilename': !exists(json, 'photoFilename') ? undefined : StringFilterFromJSON(json['photoFilename']),
        'photoFilepath': !exists(json, 'photoFilepath') ? undefined : StringFilterFromJSON(json['photoFilepath']),
        'photoUrl': !exists(json, 'photoUrl') ? undefined : StringFilterFromJSON(json['photoUrl']),
        'photoMd5': !exists(json, 'photoMd5') ? undefined : StringFilterFromJSON(json['photoMd5']),
        'uuid': !exists(json, 'uuid') ? undefined : StringFilterFromJSON(json['uuid']),
        'processorId': !exists(json, 'processorId') ? undefined : LongFilterFromJSON(json['processorId']),
        'authorId': !exists(json, 'authorId') ? undefined : LongFilterFromJSON(json['authorId']),
        'photoAuthorId': !exists(json, 'photoAuthorId') ? undefined : LongFilterFromJSON(json['photoAuthorId']),
        'projectStateId': !exists(json, 'projectStateId') ? undefined : LongFilterFromJSON(json['projectStateId']),
        'productId': !exists(json, 'productId') ? undefined : LongFilterFromJSON(json['productId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CustomProjectCriteriaToJSON(value?: CustomProjectCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'name': StringFilterToJSON(value.name),
        'uniqueNumber': StringFilterToJSON(value.uniqueNumber),
        'comment': StringFilterToJSON(value.comment),
        'creationDate': InstantFilterToJSON(value.creationDate),
        'currentState': ProjectStateEnumFilterToJSON(value.currentState),
        'latitude': DoubleFilterToJSON(value.latitude),
        'longitude': DoubleFilterToJSON(value.longitude),
        'sumCubage': DoubleFilterToJSON(value.sumCubage),
        'photoFilename': StringFilterToJSON(value.photoFilename),
        'photoFilepath': StringFilterToJSON(value.photoFilepath),
        'photoUrl': StringFilterToJSON(value.photoUrl),
        'photoMd5': StringFilterToJSON(value.photoMd5),
        'uuid': StringFilterToJSON(value.uuid),
        'processorId': LongFilterToJSON(value.processorId),
        'authorId': LongFilterToJSON(value.authorId),
        'photoAuthorId': LongFilterToJSON(value.photoAuthorId),
        'projectStateId': LongFilterToJSON(value.projectStateId),
        'productId': LongFilterToJSON(value.productId),
        'distinct': value.distinct,
    };
}

