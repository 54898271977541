/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Logging,
} from '../models';
import {
    LoggingFromJSON,
    LoggingToJSON,
} from '../models';

export interface CountLoggingsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    sumWoodOtherAssortmentGreaterThan?: number;
    sumWoodOtherAssortmentLessThan?: number;
    sumWoodOtherAssortmentGreaterThanOrEqual?: number;
    sumWoodOtherAssortmentLessThanOrEqual?: number;
    sumWoodOtherAssortmentEquals?: number;
    sumWoodOtherAssortmentNotEquals?: number;
    sumWoodOtherAssortmentSpecified?: boolean;
    sumWoodOtherAssortmentIn?: Array<number>;
    sumWoodOtherAssortmentNotIn?: Array<number>;
    sumWoodTotalGreaterThan?: number;
    sumWoodTotalLessThan?: number;
    sumWoodTotalGreaterThanOrEqual?: number;
    sumWoodTotalLessThanOrEqual?: number;
    sumWoodTotalEquals?: number;
    sumWoodTotalNotEquals?: number;
    sumWoodTotalSpecified?: boolean;
    sumWoodTotalIn?: Array<number>;
    sumWoodTotalNotIn?: Array<number>;
    estimatedVolumeGreaterThan?: number;
    estimatedVolumeLessThan?: number;
    estimatedVolumeGreaterThanOrEqual?: number;
    estimatedVolumeLessThanOrEqual?: number;
    estimatedVolumeEquals?: number;
    estimatedVolumeNotEquals?: number;
    estimatedVolumeSpecified?: boolean;
    estimatedVolumeIn?: Array<number>;
    estimatedVolumeNotIn?: Array<number>;
    currentStateEquals?: CountLoggingsCurrentStateEqualsEnum;
    currentStateNotEquals?: CountLoggingsCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<CountLoggingsCurrentStateInEnum>;
    currentStateNotIn?: Array<CountLoggingsCurrentStateNotInEnum>;
    permitMediaTypeEquals?: CountLoggingsPermitMediaTypeEqualsEnum;
    permitMediaTypeNotEquals?: CountLoggingsPermitMediaTypeNotEqualsEnum;
    permitMediaTypeSpecified?: boolean;
    permitMediaTypeIn?: Array<CountLoggingsPermitMediaTypeInEnum>;
    permitMediaTypeNotIn?: Array<CountLoggingsPermitMediaTypeNotInEnum>;
    permitFilenameContains?: string;
    permitFilenameDoesNotContain?: string;
    permitFilenameEquals?: string;
    permitFilenameNotEquals?: string;
    permitFilenameSpecified?: boolean;
    permitFilenameIn?: Array<string>;
    permitFilenameNotIn?: Array<string>;
    permitFilepathContains?: string;
    permitFilepathDoesNotContain?: string;
    permitFilepathEquals?: string;
    permitFilepathNotEquals?: string;
    permitFilepathSpecified?: boolean;
    permitFilepathIn?: Array<string>;
    permitFilepathNotIn?: Array<string>;
    permitUrlContains?: string;
    permitUrlDoesNotContain?: string;
    permitUrlEquals?: string;
    permitUrlNotEquals?: string;
    permitUrlSpecified?: boolean;
    permitUrlIn?: Array<string>;
    permitUrlNotIn?: Array<string>;
    permitMd5Contains?: string;
    permitMd5DoesNotContain?: string;
    permitMd5Equals?: string;
    permitMd5NotEquals?: string;
    permitMd5Specified?: boolean;
    permitMd5In?: Array<string>;
    permitMd5NotIn?: Array<string>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    companyIdGreaterThan?: number;
    companyIdLessThan?: number;
    companyIdGreaterThanOrEqual?: number;
    companyIdLessThanOrEqual?: number;
    companyIdEquals?: number;
    companyIdNotEquals?: number;
    companyIdSpecified?: boolean;
    companyIdIn?: Array<number>;
    companyIdNotIn?: Array<number>;
    permitAuthorIdGreaterThan?: number;
    permitAuthorIdLessThan?: number;
    permitAuthorIdGreaterThanOrEqual?: number;
    permitAuthorIdLessThanOrEqual?: number;
    permitAuthorIdEquals?: number;
    permitAuthorIdNotEquals?: number;
    permitAuthorIdSpecified?: boolean;
    permitAuthorIdIn?: Array<number>;
    permitAuthorIdNotIn?: Array<number>;
    loggingStateIdGreaterThan?: number;
    loggingStateIdLessThan?: number;
    loggingStateIdGreaterThanOrEqual?: number;
    loggingStateIdLessThanOrEqual?: number;
    loggingStateIdEquals?: number;
    loggingStateIdNotEquals?: number;
    loggingStateIdSpecified?: boolean;
    loggingStateIdIn?: Array<number>;
    loggingStateIdNotIn?: Array<number>;
    pileIdGreaterThan?: number;
    pileIdLessThan?: number;
    pileIdGreaterThanOrEqual?: number;
    pileIdLessThanOrEqual?: number;
    pileIdEquals?: number;
    pileIdNotEquals?: number;
    pileIdSpecified?: boolean;
    pileIdIn?: Array<number>;
    pileIdNotIn?: Array<number>;
    plotIdGreaterThan?: number;
    plotIdLessThan?: number;
    plotIdGreaterThanOrEqual?: number;
    plotIdLessThanOrEqual?: number;
    plotIdEquals?: number;
    plotIdNotEquals?: number;
    plotIdSpecified?: boolean;
    plotIdIn?: Array<number>;
    plotIdNotIn?: Array<number>;
    treeIdGreaterThan?: number;
    treeIdLessThan?: number;
    treeIdGreaterThanOrEqual?: number;
    treeIdLessThanOrEqual?: number;
    treeIdEquals?: number;
    treeIdNotEquals?: number;
    treeIdSpecified?: boolean;
    treeIdIn?: Array<number>;
    treeIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateLoggingRequest {
    logging: Logging;
}

export interface DeleteLoggingRequest {
    id: number;
}

export interface GetAllLoggingsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    sumWoodOtherAssortmentGreaterThan?: number;
    sumWoodOtherAssortmentLessThan?: number;
    sumWoodOtherAssortmentGreaterThanOrEqual?: number;
    sumWoodOtherAssortmentLessThanOrEqual?: number;
    sumWoodOtherAssortmentEquals?: number;
    sumWoodOtherAssortmentNotEquals?: number;
    sumWoodOtherAssortmentSpecified?: boolean;
    sumWoodOtherAssortmentIn?: Array<number>;
    sumWoodOtherAssortmentNotIn?: Array<number>;
    sumWoodTotalGreaterThan?: number;
    sumWoodTotalLessThan?: number;
    sumWoodTotalGreaterThanOrEqual?: number;
    sumWoodTotalLessThanOrEqual?: number;
    sumWoodTotalEquals?: number;
    sumWoodTotalNotEquals?: number;
    sumWoodTotalSpecified?: boolean;
    sumWoodTotalIn?: Array<number>;
    sumWoodTotalNotIn?: Array<number>;
    estimatedVolumeGreaterThan?: number;
    estimatedVolumeLessThan?: number;
    estimatedVolumeGreaterThanOrEqual?: number;
    estimatedVolumeLessThanOrEqual?: number;
    estimatedVolumeEquals?: number;
    estimatedVolumeNotEquals?: number;
    estimatedVolumeSpecified?: boolean;
    estimatedVolumeIn?: Array<number>;
    estimatedVolumeNotIn?: Array<number>;
    currentStateEquals?: GetAllLoggingsCurrentStateEqualsEnum;
    currentStateNotEquals?: GetAllLoggingsCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<GetAllLoggingsCurrentStateInEnum>;
    currentStateNotIn?: Array<GetAllLoggingsCurrentStateNotInEnum>;
    permitMediaTypeEquals?: GetAllLoggingsPermitMediaTypeEqualsEnum;
    permitMediaTypeNotEquals?: GetAllLoggingsPermitMediaTypeNotEqualsEnum;
    permitMediaTypeSpecified?: boolean;
    permitMediaTypeIn?: Array<GetAllLoggingsPermitMediaTypeInEnum>;
    permitMediaTypeNotIn?: Array<GetAllLoggingsPermitMediaTypeNotInEnum>;
    permitFilenameContains?: string;
    permitFilenameDoesNotContain?: string;
    permitFilenameEquals?: string;
    permitFilenameNotEquals?: string;
    permitFilenameSpecified?: boolean;
    permitFilenameIn?: Array<string>;
    permitFilenameNotIn?: Array<string>;
    permitFilepathContains?: string;
    permitFilepathDoesNotContain?: string;
    permitFilepathEquals?: string;
    permitFilepathNotEquals?: string;
    permitFilepathSpecified?: boolean;
    permitFilepathIn?: Array<string>;
    permitFilepathNotIn?: Array<string>;
    permitUrlContains?: string;
    permitUrlDoesNotContain?: string;
    permitUrlEquals?: string;
    permitUrlNotEquals?: string;
    permitUrlSpecified?: boolean;
    permitUrlIn?: Array<string>;
    permitUrlNotIn?: Array<string>;
    permitMd5Contains?: string;
    permitMd5DoesNotContain?: string;
    permitMd5Equals?: string;
    permitMd5NotEquals?: string;
    permitMd5Specified?: boolean;
    permitMd5In?: Array<string>;
    permitMd5NotIn?: Array<string>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    companyIdGreaterThan?: number;
    companyIdLessThan?: number;
    companyIdGreaterThanOrEqual?: number;
    companyIdLessThanOrEqual?: number;
    companyIdEquals?: number;
    companyIdNotEquals?: number;
    companyIdSpecified?: boolean;
    companyIdIn?: Array<number>;
    companyIdNotIn?: Array<number>;
    permitAuthorIdGreaterThan?: number;
    permitAuthorIdLessThan?: number;
    permitAuthorIdGreaterThanOrEqual?: number;
    permitAuthorIdLessThanOrEqual?: number;
    permitAuthorIdEquals?: number;
    permitAuthorIdNotEquals?: number;
    permitAuthorIdSpecified?: boolean;
    permitAuthorIdIn?: Array<number>;
    permitAuthorIdNotIn?: Array<number>;
    loggingStateIdGreaterThan?: number;
    loggingStateIdLessThan?: number;
    loggingStateIdGreaterThanOrEqual?: number;
    loggingStateIdLessThanOrEqual?: number;
    loggingStateIdEquals?: number;
    loggingStateIdNotEquals?: number;
    loggingStateIdSpecified?: boolean;
    loggingStateIdIn?: Array<number>;
    loggingStateIdNotIn?: Array<number>;
    pileIdGreaterThan?: number;
    pileIdLessThan?: number;
    pileIdGreaterThanOrEqual?: number;
    pileIdLessThanOrEqual?: number;
    pileIdEquals?: number;
    pileIdNotEquals?: number;
    pileIdSpecified?: boolean;
    pileIdIn?: Array<number>;
    pileIdNotIn?: Array<number>;
    plotIdGreaterThan?: number;
    plotIdLessThan?: number;
    plotIdGreaterThanOrEqual?: number;
    plotIdLessThanOrEqual?: number;
    plotIdEquals?: number;
    plotIdNotEquals?: number;
    plotIdSpecified?: boolean;
    plotIdIn?: Array<number>;
    plotIdNotIn?: Array<number>;
    treeIdGreaterThan?: number;
    treeIdLessThan?: number;
    treeIdGreaterThanOrEqual?: number;
    treeIdLessThanOrEqual?: number;
    treeIdEquals?: number;
    treeIdNotEquals?: number;
    treeIdSpecified?: boolean;
    treeIdIn?: Array<number>;
    treeIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetLoggingRequest {
    id: number;
}

export interface PartialUpdateLoggingRequest {
    id: number;
    logging: Logging;
}

export interface UpdateLoggingRequest {
    id: number;
    logging: Logging;
}

/**
 * 
 */
export class LoggingResourceApi extends runtime.BaseAPI {

    /**
     */
    async countLoggingsRaw(requestParameters: CountLoggingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.sumWoodOtherAssortmentGreaterThan !== undefined) {
            queryParameters['sumWoodOtherAssortment.greaterThan'] = requestParameters.sumWoodOtherAssortmentGreaterThan;
        }

        if (requestParameters.sumWoodOtherAssortmentLessThan !== undefined) {
            queryParameters['sumWoodOtherAssortment.lessThan'] = requestParameters.sumWoodOtherAssortmentLessThan;
        }

        if (requestParameters.sumWoodOtherAssortmentGreaterThanOrEqual !== undefined) {
            queryParameters['sumWoodOtherAssortment.greaterThanOrEqual'] = requestParameters.sumWoodOtherAssortmentGreaterThanOrEqual;
        }

        if (requestParameters.sumWoodOtherAssortmentLessThanOrEqual !== undefined) {
            queryParameters['sumWoodOtherAssortment.lessThanOrEqual'] = requestParameters.sumWoodOtherAssortmentLessThanOrEqual;
        }

        if (requestParameters.sumWoodOtherAssortmentEquals !== undefined) {
            queryParameters['sumWoodOtherAssortment.equals'] = requestParameters.sumWoodOtherAssortmentEquals;
        }

        if (requestParameters.sumWoodOtherAssortmentNotEquals !== undefined) {
            queryParameters['sumWoodOtherAssortment.notEquals'] = requestParameters.sumWoodOtherAssortmentNotEquals;
        }

        if (requestParameters.sumWoodOtherAssortmentSpecified !== undefined) {
            queryParameters['sumWoodOtherAssortment.specified'] = requestParameters.sumWoodOtherAssortmentSpecified;
        }

        if (requestParameters.sumWoodOtherAssortmentIn) {
            queryParameters['sumWoodOtherAssortment.in'] = requestParameters.sumWoodOtherAssortmentIn;
        }

        if (requestParameters.sumWoodOtherAssortmentNotIn) {
            queryParameters['sumWoodOtherAssortment.notIn'] = requestParameters.sumWoodOtherAssortmentNotIn;
        }

        if (requestParameters.sumWoodTotalGreaterThan !== undefined) {
            queryParameters['sumWoodTotal.greaterThan'] = requestParameters.sumWoodTotalGreaterThan;
        }

        if (requestParameters.sumWoodTotalLessThan !== undefined) {
            queryParameters['sumWoodTotal.lessThan'] = requestParameters.sumWoodTotalLessThan;
        }

        if (requestParameters.sumWoodTotalGreaterThanOrEqual !== undefined) {
            queryParameters['sumWoodTotal.greaterThanOrEqual'] = requestParameters.sumWoodTotalGreaterThanOrEqual;
        }

        if (requestParameters.sumWoodTotalLessThanOrEqual !== undefined) {
            queryParameters['sumWoodTotal.lessThanOrEqual'] = requestParameters.sumWoodTotalLessThanOrEqual;
        }

        if (requestParameters.sumWoodTotalEquals !== undefined) {
            queryParameters['sumWoodTotal.equals'] = requestParameters.sumWoodTotalEquals;
        }

        if (requestParameters.sumWoodTotalNotEquals !== undefined) {
            queryParameters['sumWoodTotal.notEquals'] = requestParameters.sumWoodTotalNotEquals;
        }

        if (requestParameters.sumWoodTotalSpecified !== undefined) {
            queryParameters['sumWoodTotal.specified'] = requestParameters.sumWoodTotalSpecified;
        }

        if (requestParameters.sumWoodTotalIn) {
            queryParameters['sumWoodTotal.in'] = requestParameters.sumWoodTotalIn;
        }

        if (requestParameters.sumWoodTotalNotIn) {
            queryParameters['sumWoodTotal.notIn'] = requestParameters.sumWoodTotalNotIn;
        }

        if (requestParameters.estimatedVolumeGreaterThan !== undefined) {
            queryParameters['estimatedVolume.greaterThan'] = requestParameters.estimatedVolumeGreaterThan;
        }

        if (requestParameters.estimatedVolumeLessThan !== undefined) {
            queryParameters['estimatedVolume.lessThan'] = requestParameters.estimatedVolumeLessThan;
        }

        if (requestParameters.estimatedVolumeGreaterThanOrEqual !== undefined) {
            queryParameters['estimatedVolume.greaterThanOrEqual'] = requestParameters.estimatedVolumeGreaterThanOrEqual;
        }

        if (requestParameters.estimatedVolumeLessThanOrEqual !== undefined) {
            queryParameters['estimatedVolume.lessThanOrEqual'] = requestParameters.estimatedVolumeLessThanOrEqual;
        }

        if (requestParameters.estimatedVolumeEquals !== undefined) {
            queryParameters['estimatedVolume.equals'] = requestParameters.estimatedVolumeEquals;
        }

        if (requestParameters.estimatedVolumeNotEquals !== undefined) {
            queryParameters['estimatedVolume.notEquals'] = requestParameters.estimatedVolumeNotEquals;
        }

        if (requestParameters.estimatedVolumeSpecified !== undefined) {
            queryParameters['estimatedVolume.specified'] = requestParameters.estimatedVolumeSpecified;
        }

        if (requestParameters.estimatedVolumeIn) {
            queryParameters['estimatedVolume.in'] = requestParameters.estimatedVolumeIn;
        }

        if (requestParameters.estimatedVolumeNotIn) {
            queryParameters['estimatedVolume.notIn'] = requestParameters.estimatedVolumeNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.permitMediaTypeEquals !== undefined) {
            queryParameters['permitMediaType.equals'] = requestParameters.permitMediaTypeEquals;
        }

        if (requestParameters.permitMediaTypeNotEquals !== undefined) {
            queryParameters['permitMediaType.notEquals'] = requestParameters.permitMediaTypeNotEquals;
        }

        if (requestParameters.permitMediaTypeSpecified !== undefined) {
            queryParameters['permitMediaType.specified'] = requestParameters.permitMediaTypeSpecified;
        }

        if (requestParameters.permitMediaTypeIn) {
            queryParameters['permitMediaType.in'] = requestParameters.permitMediaTypeIn;
        }

        if (requestParameters.permitMediaTypeNotIn) {
            queryParameters['permitMediaType.notIn'] = requestParameters.permitMediaTypeNotIn;
        }

        if (requestParameters.permitFilenameContains !== undefined) {
            queryParameters['permitFilename.contains'] = requestParameters.permitFilenameContains;
        }

        if (requestParameters.permitFilenameDoesNotContain !== undefined) {
            queryParameters['permitFilename.doesNotContain'] = requestParameters.permitFilenameDoesNotContain;
        }

        if (requestParameters.permitFilenameEquals !== undefined) {
            queryParameters['permitFilename.equals'] = requestParameters.permitFilenameEquals;
        }

        if (requestParameters.permitFilenameNotEquals !== undefined) {
            queryParameters['permitFilename.notEquals'] = requestParameters.permitFilenameNotEquals;
        }

        if (requestParameters.permitFilenameSpecified !== undefined) {
            queryParameters['permitFilename.specified'] = requestParameters.permitFilenameSpecified;
        }

        if (requestParameters.permitFilenameIn) {
            queryParameters['permitFilename.in'] = requestParameters.permitFilenameIn;
        }

        if (requestParameters.permitFilenameNotIn) {
            queryParameters['permitFilename.notIn'] = requestParameters.permitFilenameNotIn;
        }

        if (requestParameters.permitFilepathContains !== undefined) {
            queryParameters['permitFilepath.contains'] = requestParameters.permitFilepathContains;
        }

        if (requestParameters.permitFilepathDoesNotContain !== undefined) {
            queryParameters['permitFilepath.doesNotContain'] = requestParameters.permitFilepathDoesNotContain;
        }

        if (requestParameters.permitFilepathEquals !== undefined) {
            queryParameters['permitFilepath.equals'] = requestParameters.permitFilepathEquals;
        }

        if (requestParameters.permitFilepathNotEquals !== undefined) {
            queryParameters['permitFilepath.notEquals'] = requestParameters.permitFilepathNotEquals;
        }

        if (requestParameters.permitFilepathSpecified !== undefined) {
            queryParameters['permitFilepath.specified'] = requestParameters.permitFilepathSpecified;
        }

        if (requestParameters.permitFilepathIn) {
            queryParameters['permitFilepath.in'] = requestParameters.permitFilepathIn;
        }

        if (requestParameters.permitFilepathNotIn) {
            queryParameters['permitFilepath.notIn'] = requestParameters.permitFilepathNotIn;
        }

        if (requestParameters.permitUrlContains !== undefined) {
            queryParameters['permitUrl.contains'] = requestParameters.permitUrlContains;
        }

        if (requestParameters.permitUrlDoesNotContain !== undefined) {
            queryParameters['permitUrl.doesNotContain'] = requestParameters.permitUrlDoesNotContain;
        }

        if (requestParameters.permitUrlEquals !== undefined) {
            queryParameters['permitUrl.equals'] = requestParameters.permitUrlEquals;
        }

        if (requestParameters.permitUrlNotEquals !== undefined) {
            queryParameters['permitUrl.notEquals'] = requestParameters.permitUrlNotEquals;
        }

        if (requestParameters.permitUrlSpecified !== undefined) {
            queryParameters['permitUrl.specified'] = requestParameters.permitUrlSpecified;
        }

        if (requestParameters.permitUrlIn) {
            queryParameters['permitUrl.in'] = requestParameters.permitUrlIn;
        }

        if (requestParameters.permitUrlNotIn) {
            queryParameters['permitUrl.notIn'] = requestParameters.permitUrlNotIn;
        }

        if (requestParameters.permitMd5Contains !== undefined) {
            queryParameters['permitMd5.contains'] = requestParameters.permitMd5Contains;
        }

        if (requestParameters.permitMd5DoesNotContain !== undefined) {
            queryParameters['permitMd5.doesNotContain'] = requestParameters.permitMd5DoesNotContain;
        }

        if (requestParameters.permitMd5Equals !== undefined) {
            queryParameters['permitMd5.equals'] = requestParameters.permitMd5Equals;
        }

        if (requestParameters.permitMd5NotEquals !== undefined) {
            queryParameters['permitMd5.notEquals'] = requestParameters.permitMd5NotEquals;
        }

        if (requestParameters.permitMd5Specified !== undefined) {
            queryParameters['permitMd5.specified'] = requestParameters.permitMd5Specified;
        }

        if (requestParameters.permitMd5In) {
            queryParameters['permitMd5.in'] = requestParameters.permitMd5In;
        }

        if (requestParameters.permitMd5NotIn) {
            queryParameters['permitMd5.notIn'] = requestParameters.permitMd5NotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.companyIdGreaterThan !== undefined) {
            queryParameters['companyId.greaterThan'] = requestParameters.companyIdGreaterThan;
        }

        if (requestParameters.companyIdLessThan !== undefined) {
            queryParameters['companyId.lessThan'] = requestParameters.companyIdLessThan;
        }

        if (requestParameters.companyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyId.greaterThanOrEqual'] = requestParameters.companyIdGreaterThanOrEqual;
        }

        if (requestParameters.companyIdLessThanOrEqual !== undefined) {
            queryParameters['companyId.lessThanOrEqual'] = requestParameters.companyIdLessThanOrEqual;
        }

        if (requestParameters.companyIdEquals !== undefined) {
            queryParameters['companyId.equals'] = requestParameters.companyIdEquals;
        }

        if (requestParameters.companyIdNotEquals !== undefined) {
            queryParameters['companyId.notEquals'] = requestParameters.companyIdNotEquals;
        }

        if (requestParameters.companyIdSpecified !== undefined) {
            queryParameters['companyId.specified'] = requestParameters.companyIdSpecified;
        }

        if (requestParameters.companyIdIn) {
            queryParameters['companyId.in'] = requestParameters.companyIdIn;
        }

        if (requestParameters.companyIdNotIn) {
            queryParameters['companyId.notIn'] = requestParameters.companyIdNotIn;
        }

        if (requestParameters.permitAuthorIdGreaterThan !== undefined) {
            queryParameters['permitAuthorId.greaterThan'] = requestParameters.permitAuthorIdGreaterThan;
        }

        if (requestParameters.permitAuthorIdLessThan !== undefined) {
            queryParameters['permitAuthorId.lessThan'] = requestParameters.permitAuthorIdLessThan;
        }

        if (requestParameters.permitAuthorIdGreaterThanOrEqual !== undefined) {
            queryParameters['permitAuthorId.greaterThanOrEqual'] = requestParameters.permitAuthorIdGreaterThanOrEqual;
        }

        if (requestParameters.permitAuthorIdLessThanOrEqual !== undefined) {
            queryParameters['permitAuthorId.lessThanOrEqual'] = requestParameters.permitAuthorIdLessThanOrEqual;
        }

        if (requestParameters.permitAuthorIdEquals !== undefined) {
            queryParameters['permitAuthorId.equals'] = requestParameters.permitAuthorIdEquals;
        }

        if (requestParameters.permitAuthorIdNotEquals !== undefined) {
            queryParameters['permitAuthorId.notEquals'] = requestParameters.permitAuthorIdNotEquals;
        }

        if (requestParameters.permitAuthorIdSpecified !== undefined) {
            queryParameters['permitAuthorId.specified'] = requestParameters.permitAuthorIdSpecified;
        }

        if (requestParameters.permitAuthorIdIn) {
            queryParameters['permitAuthorId.in'] = requestParameters.permitAuthorIdIn;
        }

        if (requestParameters.permitAuthorIdNotIn) {
            queryParameters['permitAuthorId.notIn'] = requestParameters.permitAuthorIdNotIn;
        }

        if (requestParameters.loggingStateIdGreaterThan !== undefined) {
            queryParameters['loggingStateId.greaterThan'] = requestParameters.loggingStateIdGreaterThan;
        }

        if (requestParameters.loggingStateIdLessThan !== undefined) {
            queryParameters['loggingStateId.lessThan'] = requestParameters.loggingStateIdLessThan;
        }

        if (requestParameters.loggingStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['loggingStateId.greaterThanOrEqual'] = requestParameters.loggingStateIdGreaterThanOrEqual;
        }

        if (requestParameters.loggingStateIdLessThanOrEqual !== undefined) {
            queryParameters['loggingStateId.lessThanOrEqual'] = requestParameters.loggingStateIdLessThanOrEqual;
        }

        if (requestParameters.loggingStateIdEquals !== undefined) {
            queryParameters['loggingStateId.equals'] = requestParameters.loggingStateIdEquals;
        }

        if (requestParameters.loggingStateIdNotEquals !== undefined) {
            queryParameters['loggingStateId.notEquals'] = requestParameters.loggingStateIdNotEquals;
        }

        if (requestParameters.loggingStateIdSpecified !== undefined) {
            queryParameters['loggingStateId.specified'] = requestParameters.loggingStateIdSpecified;
        }

        if (requestParameters.loggingStateIdIn) {
            queryParameters['loggingStateId.in'] = requestParameters.loggingStateIdIn;
        }

        if (requestParameters.loggingStateIdNotIn) {
            queryParameters['loggingStateId.notIn'] = requestParameters.loggingStateIdNotIn;
        }

        if (requestParameters.pileIdGreaterThan !== undefined) {
            queryParameters['pileId.greaterThan'] = requestParameters.pileIdGreaterThan;
        }

        if (requestParameters.pileIdLessThan !== undefined) {
            queryParameters['pileId.lessThan'] = requestParameters.pileIdLessThan;
        }

        if (requestParameters.pileIdGreaterThanOrEqual !== undefined) {
            queryParameters['pileId.greaterThanOrEqual'] = requestParameters.pileIdGreaterThanOrEqual;
        }

        if (requestParameters.pileIdLessThanOrEqual !== undefined) {
            queryParameters['pileId.lessThanOrEqual'] = requestParameters.pileIdLessThanOrEqual;
        }

        if (requestParameters.pileIdEquals !== undefined) {
            queryParameters['pileId.equals'] = requestParameters.pileIdEquals;
        }

        if (requestParameters.pileIdNotEquals !== undefined) {
            queryParameters['pileId.notEquals'] = requestParameters.pileIdNotEquals;
        }

        if (requestParameters.pileIdSpecified !== undefined) {
            queryParameters['pileId.specified'] = requestParameters.pileIdSpecified;
        }

        if (requestParameters.pileIdIn) {
            queryParameters['pileId.in'] = requestParameters.pileIdIn;
        }

        if (requestParameters.pileIdNotIn) {
            queryParameters['pileId.notIn'] = requestParameters.pileIdNotIn;
        }

        if (requestParameters.plotIdGreaterThan !== undefined) {
            queryParameters['plotId.greaterThan'] = requestParameters.plotIdGreaterThan;
        }

        if (requestParameters.plotIdLessThan !== undefined) {
            queryParameters['plotId.lessThan'] = requestParameters.plotIdLessThan;
        }

        if (requestParameters.plotIdGreaterThanOrEqual !== undefined) {
            queryParameters['plotId.greaterThanOrEqual'] = requestParameters.plotIdGreaterThanOrEqual;
        }

        if (requestParameters.plotIdLessThanOrEqual !== undefined) {
            queryParameters['plotId.lessThanOrEqual'] = requestParameters.plotIdLessThanOrEqual;
        }

        if (requestParameters.plotIdEquals !== undefined) {
            queryParameters['plotId.equals'] = requestParameters.plotIdEquals;
        }

        if (requestParameters.plotIdNotEquals !== undefined) {
            queryParameters['plotId.notEquals'] = requestParameters.plotIdNotEquals;
        }

        if (requestParameters.plotIdSpecified !== undefined) {
            queryParameters['plotId.specified'] = requestParameters.plotIdSpecified;
        }

        if (requestParameters.plotIdIn) {
            queryParameters['plotId.in'] = requestParameters.plotIdIn;
        }

        if (requestParameters.plotIdNotIn) {
            queryParameters['plotId.notIn'] = requestParameters.plotIdNotIn;
        }

        if (requestParameters.treeIdGreaterThan !== undefined) {
            queryParameters['treeId.greaterThan'] = requestParameters.treeIdGreaterThan;
        }

        if (requestParameters.treeIdLessThan !== undefined) {
            queryParameters['treeId.lessThan'] = requestParameters.treeIdLessThan;
        }

        if (requestParameters.treeIdGreaterThanOrEqual !== undefined) {
            queryParameters['treeId.greaterThanOrEqual'] = requestParameters.treeIdGreaterThanOrEqual;
        }

        if (requestParameters.treeIdLessThanOrEqual !== undefined) {
            queryParameters['treeId.lessThanOrEqual'] = requestParameters.treeIdLessThanOrEqual;
        }

        if (requestParameters.treeIdEquals !== undefined) {
            queryParameters['treeId.equals'] = requestParameters.treeIdEquals;
        }

        if (requestParameters.treeIdNotEquals !== undefined) {
            queryParameters['treeId.notEquals'] = requestParameters.treeIdNotEquals;
        }

        if (requestParameters.treeIdSpecified !== undefined) {
            queryParameters['treeId.specified'] = requestParameters.treeIdSpecified;
        }

        if (requestParameters.treeIdIn) {
            queryParameters['treeId.in'] = requestParameters.treeIdIn;
        }

        if (requestParameters.treeIdNotIn) {
            queryParameters['treeId.notIn'] = requestParameters.treeIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/loggings/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countLoggings(requestParameters: CountLoggingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countLoggingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createLoggingRaw(requestParameters: CreateLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Logging>> {
        if (requestParameters.logging === null || requestParameters.logging === undefined) {
            throw new runtime.RequiredError('logging','Required parameter requestParameters.logging was null or undefined when calling createLogging.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/loggings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoggingToJSON(requestParameters.logging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingFromJSON(jsonValue));
    }

    /**
     */
    async createLogging(requestParameters: CreateLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Logging> {
        const response = await this.createLoggingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteLoggingRaw(requestParameters: DeleteLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLogging.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/loggings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteLogging(requestParameters: DeleteLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLoggingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllLoggingsRaw(requestParameters: GetAllLoggingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Logging>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.sumWoodOtherAssortmentGreaterThan !== undefined) {
            queryParameters['sumWoodOtherAssortment.greaterThan'] = requestParameters.sumWoodOtherAssortmentGreaterThan;
        }

        if (requestParameters.sumWoodOtherAssortmentLessThan !== undefined) {
            queryParameters['sumWoodOtherAssortment.lessThan'] = requestParameters.sumWoodOtherAssortmentLessThan;
        }

        if (requestParameters.sumWoodOtherAssortmentGreaterThanOrEqual !== undefined) {
            queryParameters['sumWoodOtherAssortment.greaterThanOrEqual'] = requestParameters.sumWoodOtherAssortmentGreaterThanOrEqual;
        }

        if (requestParameters.sumWoodOtherAssortmentLessThanOrEqual !== undefined) {
            queryParameters['sumWoodOtherAssortment.lessThanOrEqual'] = requestParameters.sumWoodOtherAssortmentLessThanOrEqual;
        }

        if (requestParameters.sumWoodOtherAssortmentEquals !== undefined) {
            queryParameters['sumWoodOtherAssortment.equals'] = requestParameters.sumWoodOtherAssortmentEquals;
        }

        if (requestParameters.sumWoodOtherAssortmentNotEquals !== undefined) {
            queryParameters['sumWoodOtherAssortment.notEquals'] = requestParameters.sumWoodOtherAssortmentNotEquals;
        }

        if (requestParameters.sumWoodOtherAssortmentSpecified !== undefined) {
            queryParameters['sumWoodOtherAssortment.specified'] = requestParameters.sumWoodOtherAssortmentSpecified;
        }

        if (requestParameters.sumWoodOtherAssortmentIn) {
            queryParameters['sumWoodOtherAssortment.in'] = requestParameters.sumWoodOtherAssortmentIn;
        }

        if (requestParameters.sumWoodOtherAssortmentNotIn) {
            queryParameters['sumWoodOtherAssortment.notIn'] = requestParameters.sumWoodOtherAssortmentNotIn;
        }

        if (requestParameters.sumWoodTotalGreaterThan !== undefined) {
            queryParameters['sumWoodTotal.greaterThan'] = requestParameters.sumWoodTotalGreaterThan;
        }

        if (requestParameters.sumWoodTotalLessThan !== undefined) {
            queryParameters['sumWoodTotal.lessThan'] = requestParameters.sumWoodTotalLessThan;
        }

        if (requestParameters.sumWoodTotalGreaterThanOrEqual !== undefined) {
            queryParameters['sumWoodTotal.greaterThanOrEqual'] = requestParameters.sumWoodTotalGreaterThanOrEqual;
        }

        if (requestParameters.sumWoodTotalLessThanOrEqual !== undefined) {
            queryParameters['sumWoodTotal.lessThanOrEqual'] = requestParameters.sumWoodTotalLessThanOrEqual;
        }

        if (requestParameters.sumWoodTotalEquals !== undefined) {
            queryParameters['sumWoodTotal.equals'] = requestParameters.sumWoodTotalEquals;
        }

        if (requestParameters.sumWoodTotalNotEquals !== undefined) {
            queryParameters['sumWoodTotal.notEquals'] = requestParameters.sumWoodTotalNotEquals;
        }

        if (requestParameters.sumWoodTotalSpecified !== undefined) {
            queryParameters['sumWoodTotal.specified'] = requestParameters.sumWoodTotalSpecified;
        }

        if (requestParameters.sumWoodTotalIn) {
            queryParameters['sumWoodTotal.in'] = requestParameters.sumWoodTotalIn;
        }

        if (requestParameters.sumWoodTotalNotIn) {
            queryParameters['sumWoodTotal.notIn'] = requestParameters.sumWoodTotalNotIn;
        }

        if (requestParameters.estimatedVolumeGreaterThan !== undefined) {
            queryParameters['estimatedVolume.greaterThan'] = requestParameters.estimatedVolumeGreaterThan;
        }

        if (requestParameters.estimatedVolumeLessThan !== undefined) {
            queryParameters['estimatedVolume.lessThan'] = requestParameters.estimatedVolumeLessThan;
        }

        if (requestParameters.estimatedVolumeGreaterThanOrEqual !== undefined) {
            queryParameters['estimatedVolume.greaterThanOrEqual'] = requestParameters.estimatedVolumeGreaterThanOrEqual;
        }

        if (requestParameters.estimatedVolumeLessThanOrEqual !== undefined) {
            queryParameters['estimatedVolume.lessThanOrEqual'] = requestParameters.estimatedVolumeLessThanOrEqual;
        }

        if (requestParameters.estimatedVolumeEquals !== undefined) {
            queryParameters['estimatedVolume.equals'] = requestParameters.estimatedVolumeEquals;
        }

        if (requestParameters.estimatedVolumeNotEquals !== undefined) {
            queryParameters['estimatedVolume.notEquals'] = requestParameters.estimatedVolumeNotEquals;
        }

        if (requestParameters.estimatedVolumeSpecified !== undefined) {
            queryParameters['estimatedVolume.specified'] = requestParameters.estimatedVolumeSpecified;
        }

        if (requestParameters.estimatedVolumeIn) {
            queryParameters['estimatedVolume.in'] = requestParameters.estimatedVolumeIn;
        }

        if (requestParameters.estimatedVolumeNotIn) {
            queryParameters['estimatedVolume.notIn'] = requestParameters.estimatedVolumeNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.permitMediaTypeEquals !== undefined) {
            queryParameters['permitMediaType.equals'] = requestParameters.permitMediaTypeEquals;
        }

        if (requestParameters.permitMediaTypeNotEquals !== undefined) {
            queryParameters['permitMediaType.notEquals'] = requestParameters.permitMediaTypeNotEquals;
        }

        if (requestParameters.permitMediaTypeSpecified !== undefined) {
            queryParameters['permitMediaType.specified'] = requestParameters.permitMediaTypeSpecified;
        }

        if (requestParameters.permitMediaTypeIn) {
            queryParameters['permitMediaType.in'] = requestParameters.permitMediaTypeIn;
        }

        if (requestParameters.permitMediaTypeNotIn) {
            queryParameters['permitMediaType.notIn'] = requestParameters.permitMediaTypeNotIn;
        }

        if (requestParameters.permitFilenameContains !== undefined) {
            queryParameters['permitFilename.contains'] = requestParameters.permitFilenameContains;
        }

        if (requestParameters.permitFilenameDoesNotContain !== undefined) {
            queryParameters['permitFilename.doesNotContain'] = requestParameters.permitFilenameDoesNotContain;
        }

        if (requestParameters.permitFilenameEquals !== undefined) {
            queryParameters['permitFilename.equals'] = requestParameters.permitFilenameEquals;
        }

        if (requestParameters.permitFilenameNotEquals !== undefined) {
            queryParameters['permitFilename.notEquals'] = requestParameters.permitFilenameNotEquals;
        }

        if (requestParameters.permitFilenameSpecified !== undefined) {
            queryParameters['permitFilename.specified'] = requestParameters.permitFilenameSpecified;
        }

        if (requestParameters.permitFilenameIn) {
            queryParameters['permitFilename.in'] = requestParameters.permitFilenameIn;
        }

        if (requestParameters.permitFilenameNotIn) {
            queryParameters['permitFilename.notIn'] = requestParameters.permitFilenameNotIn;
        }

        if (requestParameters.permitFilepathContains !== undefined) {
            queryParameters['permitFilepath.contains'] = requestParameters.permitFilepathContains;
        }

        if (requestParameters.permitFilepathDoesNotContain !== undefined) {
            queryParameters['permitFilepath.doesNotContain'] = requestParameters.permitFilepathDoesNotContain;
        }

        if (requestParameters.permitFilepathEquals !== undefined) {
            queryParameters['permitFilepath.equals'] = requestParameters.permitFilepathEquals;
        }

        if (requestParameters.permitFilepathNotEquals !== undefined) {
            queryParameters['permitFilepath.notEquals'] = requestParameters.permitFilepathNotEquals;
        }

        if (requestParameters.permitFilepathSpecified !== undefined) {
            queryParameters['permitFilepath.specified'] = requestParameters.permitFilepathSpecified;
        }

        if (requestParameters.permitFilepathIn) {
            queryParameters['permitFilepath.in'] = requestParameters.permitFilepathIn;
        }

        if (requestParameters.permitFilepathNotIn) {
            queryParameters['permitFilepath.notIn'] = requestParameters.permitFilepathNotIn;
        }

        if (requestParameters.permitUrlContains !== undefined) {
            queryParameters['permitUrl.contains'] = requestParameters.permitUrlContains;
        }

        if (requestParameters.permitUrlDoesNotContain !== undefined) {
            queryParameters['permitUrl.doesNotContain'] = requestParameters.permitUrlDoesNotContain;
        }

        if (requestParameters.permitUrlEquals !== undefined) {
            queryParameters['permitUrl.equals'] = requestParameters.permitUrlEquals;
        }

        if (requestParameters.permitUrlNotEquals !== undefined) {
            queryParameters['permitUrl.notEquals'] = requestParameters.permitUrlNotEquals;
        }

        if (requestParameters.permitUrlSpecified !== undefined) {
            queryParameters['permitUrl.specified'] = requestParameters.permitUrlSpecified;
        }

        if (requestParameters.permitUrlIn) {
            queryParameters['permitUrl.in'] = requestParameters.permitUrlIn;
        }

        if (requestParameters.permitUrlNotIn) {
            queryParameters['permitUrl.notIn'] = requestParameters.permitUrlNotIn;
        }

        if (requestParameters.permitMd5Contains !== undefined) {
            queryParameters['permitMd5.contains'] = requestParameters.permitMd5Contains;
        }

        if (requestParameters.permitMd5DoesNotContain !== undefined) {
            queryParameters['permitMd5.doesNotContain'] = requestParameters.permitMd5DoesNotContain;
        }

        if (requestParameters.permitMd5Equals !== undefined) {
            queryParameters['permitMd5.equals'] = requestParameters.permitMd5Equals;
        }

        if (requestParameters.permitMd5NotEquals !== undefined) {
            queryParameters['permitMd5.notEquals'] = requestParameters.permitMd5NotEquals;
        }

        if (requestParameters.permitMd5Specified !== undefined) {
            queryParameters['permitMd5.specified'] = requestParameters.permitMd5Specified;
        }

        if (requestParameters.permitMd5In) {
            queryParameters['permitMd5.in'] = requestParameters.permitMd5In;
        }

        if (requestParameters.permitMd5NotIn) {
            queryParameters['permitMd5.notIn'] = requestParameters.permitMd5NotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.companyIdGreaterThan !== undefined) {
            queryParameters['companyId.greaterThan'] = requestParameters.companyIdGreaterThan;
        }

        if (requestParameters.companyIdLessThan !== undefined) {
            queryParameters['companyId.lessThan'] = requestParameters.companyIdLessThan;
        }

        if (requestParameters.companyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyId.greaterThanOrEqual'] = requestParameters.companyIdGreaterThanOrEqual;
        }

        if (requestParameters.companyIdLessThanOrEqual !== undefined) {
            queryParameters['companyId.lessThanOrEqual'] = requestParameters.companyIdLessThanOrEqual;
        }

        if (requestParameters.companyIdEquals !== undefined) {
            queryParameters['companyId.equals'] = requestParameters.companyIdEquals;
        }

        if (requestParameters.companyIdNotEquals !== undefined) {
            queryParameters['companyId.notEquals'] = requestParameters.companyIdNotEquals;
        }

        if (requestParameters.companyIdSpecified !== undefined) {
            queryParameters['companyId.specified'] = requestParameters.companyIdSpecified;
        }

        if (requestParameters.companyIdIn) {
            queryParameters['companyId.in'] = requestParameters.companyIdIn;
        }

        if (requestParameters.companyIdNotIn) {
            queryParameters['companyId.notIn'] = requestParameters.companyIdNotIn;
        }

        if (requestParameters.permitAuthorIdGreaterThan !== undefined) {
            queryParameters['permitAuthorId.greaterThan'] = requestParameters.permitAuthorIdGreaterThan;
        }

        if (requestParameters.permitAuthorIdLessThan !== undefined) {
            queryParameters['permitAuthorId.lessThan'] = requestParameters.permitAuthorIdLessThan;
        }

        if (requestParameters.permitAuthorIdGreaterThanOrEqual !== undefined) {
            queryParameters['permitAuthorId.greaterThanOrEqual'] = requestParameters.permitAuthorIdGreaterThanOrEqual;
        }

        if (requestParameters.permitAuthorIdLessThanOrEqual !== undefined) {
            queryParameters['permitAuthorId.lessThanOrEqual'] = requestParameters.permitAuthorIdLessThanOrEqual;
        }

        if (requestParameters.permitAuthorIdEquals !== undefined) {
            queryParameters['permitAuthorId.equals'] = requestParameters.permitAuthorIdEquals;
        }

        if (requestParameters.permitAuthorIdNotEquals !== undefined) {
            queryParameters['permitAuthorId.notEquals'] = requestParameters.permitAuthorIdNotEquals;
        }

        if (requestParameters.permitAuthorIdSpecified !== undefined) {
            queryParameters['permitAuthorId.specified'] = requestParameters.permitAuthorIdSpecified;
        }

        if (requestParameters.permitAuthorIdIn) {
            queryParameters['permitAuthorId.in'] = requestParameters.permitAuthorIdIn;
        }

        if (requestParameters.permitAuthorIdNotIn) {
            queryParameters['permitAuthorId.notIn'] = requestParameters.permitAuthorIdNotIn;
        }

        if (requestParameters.loggingStateIdGreaterThan !== undefined) {
            queryParameters['loggingStateId.greaterThan'] = requestParameters.loggingStateIdGreaterThan;
        }

        if (requestParameters.loggingStateIdLessThan !== undefined) {
            queryParameters['loggingStateId.lessThan'] = requestParameters.loggingStateIdLessThan;
        }

        if (requestParameters.loggingStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['loggingStateId.greaterThanOrEqual'] = requestParameters.loggingStateIdGreaterThanOrEqual;
        }

        if (requestParameters.loggingStateIdLessThanOrEqual !== undefined) {
            queryParameters['loggingStateId.lessThanOrEqual'] = requestParameters.loggingStateIdLessThanOrEqual;
        }

        if (requestParameters.loggingStateIdEquals !== undefined) {
            queryParameters['loggingStateId.equals'] = requestParameters.loggingStateIdEquals;
        }

        if (requestParameters.loggingStateIdNotEquals !== undefined) {
            queryParameters['loggingStateId.notEquals'] = requestParameters.loggingStateIdNotEquals;
        }

        if (requestParameters.loggingStateIdSpecified !== undefined) {
            queryParameters['loggingStateId.specified'] = requestParameters.loggingStateIdSpecified;
        }

        if (requestParameters.loggingStateIdIn) {
            queryParameters['loggingStateId.in'] = requestParameters.loggingStateIdIn;
        }

        if (requestParameters.loggingStateIdNotIn) {
            queryParameters['loggingStateId.notIn'] = requestParameters.loggingStateIdNotIn;
        }

        if (requestParameters.pileIdGreaterThan !== undefined) {
            queryParameters['pileId.greaterThan'] = requestParameters.pileIdGreaterThan;
        }

        if (requestParameters.pileIdLessThan !== undefined) {
            queryParameters['pileId.lessThan'] = requestParameters.pileIdLessThan;
        }

        if (requestParameters.pileIdGreaterThanOrEqual !== undefined) {
            queryParameters['pileId.greaterThanOrEqual'] = requestParameters.pileIdGreaterThanOrEqual;
        }

        if (requestParameters.pileIdLessThanOrEqual !== undefined) {
            queryParameters['pileId.lessThanOrEqual'] = requestParameters.pileIdLessThanOrEqual;
        }

        if (requestParameters.pileIdEquals !== undefined) {
            queryParameters['pileId.equals'] = requestParameters.pileIdEquals;
        }

        if (requestParameters.pileIdNotEquals !== undefined) {
            queryParameters['pileId.notEquals'] = requestParameters.pileIdNotEquals;
        }

        if (requestParameters.pileIdSpecified !== undefined) {
            queryParameters['pileId.specified'] = requestParameters.pileIdSpecified;
        }

        if (requestParameters.pileIdIn) {
            queryParameters['pileId.in'] = requestParameters.pileIdIn;
        }

        if (requestParameters.pileIdNotIn) {
            queryParameters['pileId.notIn'] = requestParameters.pileIdNotIn;
        }

        if (requestParameters.plotIdGreaterThan !== undefined) {
            queryParameters['plotId.greaterThan'] = requestParameters.plotIdGreaterThan;
        }

        if (requestParameters.plotIdLessThan !== undefined) {
            queryParameters['plotId.lessThan'] = requestParameters.plotIdLessThan;
        }

        if (requestParameters.plotIdGreaterThanOrEqual !== undefined) {
            queryParameters['plotId.greaterThanOrEqual'] = requestParameters.plotIdGreaterThanOrEqual;
        }

        if (requestParameters.plotIdLessThanOrEqual !== undefined) {
            queryParameters['plotId.lessThanOrEqual'] = requestParameters.plotIdLessThanOrEqual;
        }

        if (requestParameters.plotIdEquals !== undefined) {
            queryParameters['plotId.equals'] = requestParameters.plotIdEquals;
        }

        if (requestParameters.plotIdNotEquals !== undefined) {
            queryParameters['plotId.notEquals'] = requestParameters.plotIdNotEquals;
        }

        if (requestParameters.plotIdSpecified !== undefined) {
            queryParameters['plotId.specified'] = requestParameters.plotIdSpecified;
        }

        if (requestParameters.plotIdIn) {
            queryParameters['plotId.in'] = requestParameters.plotIdIn;
        }

        if (requestParameters.plotIdNotIn) {
            queryParameters['plotId.notIn'] = requestParameters.plotIdNotIn;
        }

        if (requestParameters.treeIdGreaterThan !== undefined) {
            queryParameters['treeId.greaterThan'] = requestParameters.treeIdGreaterThan;
        }

        if (requestParameters.treeIdLessThan !== undefined) {
            queryParameters['treeId.lessThan'] = requestParameters.treeIdLessThan;
        }

        if (requestParameters.treeIdGreaterThanOrEqual !== undefined) {
            queryParameters['treeId.greaterThanOrEqual'] = requestParameters.treeIdGreaterThanOrEqual;
        }

        if (requestParameters.treeIdLessThanOrEqual !== undefined) {
            queryParameters['treeId.lessThanOrEqual'] = requestParameters.treeIdLessThanOrEqual;
        }

        if (requestParameters.treeIdEquals !== undefined) {
            queryParameters['treeId.equals'] = requestParameters.treeIdEquals;
        }

        if (requestParameters.treeIdNotEquals !== undefined) {
            queryParameters['treeId.notEquals'] = requestParameters.treeIdNotEquals;
        }

        if (requestParameters.treeIdSpecified !== undefined) {
            queryParameters['treeId.specified'] = requestParameters.treeIdSpecified;
        }

        if (requestParameters.treeIdIn) {
            queryParameters['treeId.in'] = requestParameters.treeIdIn;
        }

        if (requestParameters.treeIdNotIn) {
            queryParameters['treeId.notIn'] = requestParameters.treeIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/loggings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoggingFromJSON));
    }

    /**
     */
    async getAllLoggings(requestParameters: GetAllLoggingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Logging>> {
        const response = await this.getAllLoggingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLoggingRaw(requestParameters: GetLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Logging>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLogging.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/loggings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingFromJSON(jsonValue));
    }

    /**
     */
    async getLogging(requestParameters: GetLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Logging> {
        const response = await this.getLoggingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateLoggingRaw(requestParameters: PartialUpdateLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Logging>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateLogging.');
        }

        if (requestParameters.logging === null || requestParameters.logging === undefined) {
            throw new runtime.RequiredError('logging','Required parameter requestParameters.logging was null or undefined when calling partialUpdateLogging.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/loggings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: LoggingToJSON(requestParameters.logging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateLogging(requestParameters: PartialUpdateLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Logging> {
        const response = await this.partialUpdateLoggingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateLoggingRaw(requestParameters: UpdateLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Logging>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLogging.');
        }

        if (requestParameters.logging === null || requestParameters.logging === undefined) {
            throw new runtime.RequiredError('logging','Required parameter requestParameters.logging was null or undefined when calling updateLogging.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/loggings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LoggingToJSON(requestParameters.logging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingFromJSON(jsonValue));
    }

    /**
     */
    async updateLogging(requestParameters: UpdateLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Logging> {
        const response = await this.updateLoggingRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CountLoggingsCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountLoggingsCurrentStateEqualsEnum = typeof CountLoggingsCurrentStateEqualsEnum[keyof typeof CountLoggingsCurrentStateEqualsEnum];
/**
 * @export
 */
export const CountLoggingsCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountLoggingsCurrentStateNotEqualsEnum = typeof CountLoggingsCurrentStateNotEqualsEnum[keyof typeof CountLoggingsCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const CountLoggingsCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountLoggingsCurrentStateInEnum = typeof CountLoggingsCurrentStateInEnum[keyof typeof CountLoggingsCurrentStateInEnum];
/**
 * @export
 */
export const CountLoggingsCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountLoggingsCurrentStateNotInEnum = typeof CountLoggingsCurrentStateNotInEnum[keyof typeof CountLoggingsCurrentStateNotInEnum];
/**
 * @export
 */
export const CountLoggingsPermitMediaTypeEqualsEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type CountLoggingsPermitMediaTypeEqualsEnum = typeof CountLoggingsPermitMediaTypeEqualsEnum[keyof typeof CountLoggingsPermitMediaTypeEqualsEnum];
/**
 * @export
 */
export const CountLoggingsPermitMediaTypeNotEqualsEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type CountLoggingsPermitMediaTypeNotEqualsEnum = typeof CountLoggingsPermitMediaTypeNotEqualsEnum[keyof typeof CountLoggingsPermitMediaTypeNotEqualsEnum];
/**
 * @export
 */
export const CountLoggingsPermitMediaTypeInEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type CountLoggingsPermitMediaTypeInEnum = typeof CountLoggingsPermitMediaTypeInEnum[keyof typeof CountLoggingsPermitMediaTypeInEnum];
/**
 * @export
 */
export const CountLoggingsPermitMediaTypeNotInEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type CountLoggingsPermitMediaTypeNotInEnum = typeof CountLoggingsPermitMediaTypeNotInEnum[keyof typeof CountLoggingsPermitMediaTypeNotInEnum];
/**
 * @export
 */
export const GetAllLoggingsCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllLoggingsCurrentStateEqualsEnum = typeof GetAllLoggingsCurrentStateEqualsEnum[keyof typeof GetAllLoggingsCurrentStateEqualsEnum];
/**
 * @export
 */
export const GetAllLoggingsCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllLoggingsCurrentStateNotEqualsEnum = typeof GetAllLoggingsCurrentStateNotEqualsEnum[keyof typeof GetAllLoggingsCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const GetAllLoggingsCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllLoggingsCurrentStateInEnum = typeof GetAllLoggingsCurrentStateInEnum[keyof typeof GetAllLoggingsCurrentStateInEnum];
/**
 * @export
 */
export const GetAllLoggingsCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllLoggingsCurrentStateNotInEnum = typeof GetAllLoggingsCurrentStateNotInEnum[keyof typeof GetAllLoggingsCurrentStateNotInEnum];
/**
 * @export
 */
export const GetAllLoggingsPermitMediaTypeEqualsEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type GetAllLoggingsPermitMediaTypeEqualsEnum = typeof GetAllLoggingsPermitMediaTypeEqualsEnum[keyof typeof GetAllLoggingsPermitMediaTypeEqualsEnum];
/**
 * @export
 */
export const GetAllLoggingsPermitMediaTypeNotEqualsEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type GetAllLoggingsPermitMediaTypeNotEqualsEnum = typeof GetAllLoggingsPermitMediaTypeNotEqualsEnum[keyof typeof GetAllLoggingsPermitMediaTypeNotEqualsEnum];
/**
 * @export
 */
export const GetAllLoggingsPermitMediaTypeInEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type GetAllLoggingsPermitMediaTypeInEnum = typeof GetAllLoggingsPermitMediaTypeInEnum[keyof typeof GetAllLoggingsPermitMediaTypeInEnum];
/**
 * @export
 */
export const GetAllLoggingsPermitMediaTypeNotInEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type GetAllLoggingsPermitMediaTypeNotInEnum = typeof GetAllLoggingsPermitMediaTypeNotInEnum[keyof typeof GetAllLoggingsPermitMediaTypeNotInEnum];
