import LoginLayout from '@components/layouts/login/LoginLayout';
import ResetPasswordView from '@components/views/login/ResetPasswordView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { customAccountResourceApi } from '@services/apis/ApiConfiguration';
import { LoginVM } from '@services/apis/generated';
import React from 'react';
import { RootStackScreenProps } from '../../navigation/UrstammNavigationHelper';

export default function ResetPasswordContainer({ navigation, route }: RootStackScreenProps<'ResetPassword'>) {
  const resetPassword = (email: string): void => {
    customAccountResourceApi
      .requestPasswordReset({ passwordResetDTO: { email: email } })
      .then(() => {})
      .catch(async error => {
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <LoginLayout
      navigation={navigation}
      view={<ResetPasswordView submit={(email: any) => resetPassword(email)} navigation={navigation} />}
    />
  );
}
