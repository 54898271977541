import { PlatformHelper } from '@helpers/PlatformHelper';
import { Client, Options } from '@sentry/types'

type SentryClient = Client & { init: (options?: Options) => void }
let Sentry: SentryClient;

const isMobile = PlatformHelper.isIos() || PlatformHelper.isAndroid();
const isDevelopment = __DEV__;

if (!isMobile || (isMobile && isDevelopment)) {
  console.debug('Setting up web Sentry' + (isDevelopment ? ' for development' : '') + (isMobile ? ' on mobile' : ''));
  Sentry = require('./sentry.web').default;
} else {
  console.debug('Setting up native Sentry');
  Sentry = require('./sentry.native').default;
}

export function testCrash(unique: boolean = false) {
  const date = new Date();
  throw new Error('Sentry test error' + (unique ? ` on ${date.toUTCString() }` : ''));
}

export default Sentry;
