/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PacketSpecies,
} from '../models';
import {
    PacketSpeciesFromJSON,
    PacketSpeciesToJSON,
} from '../models';

export interface CreatePacketSpeciesRequest {
    packetSpecies: PacketSpecies;
}

export interface DeletePacketSpeciesRequest {
    id: number;
}

export interface GetPacketSpeciesRequest {
    id: number;
}

export interface PartialUpdatePacketSpeciesRequest {
    id: number;
    packetSpecies: PacketSpecies;
}

export interface UpdatePacketSpeciesRequest {
    id: number;
    packetSpecies: PacketSpecies;
}

/**
 * 
 */
export class PacketSpeciesResourceApi extends runtime.BaseAPI {

    /**
     */
    async createPacketSpeciesRaw(requestParameters: CreatePacketSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketSpecies>> {
        if (requestParameters.packetSpecies === null || requestParameters.packetSpecies === undefined) {
            throw new runtime.RequiredError('packetSpecies','Required parameter requestParameters.packetSpecies was null or undefined when calling createPacketSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packet-species`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PacketSpeciesToJSON(requestParameters.packetSpecies),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketSpeciesFromJSON(jsonValue));
    }

    /**
     */
    async createPacketSpecies(requestParameters: CreatePacketSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketSpecies> {
        const response = await this.createPacketSpeciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePacketSpeciesRaw(requestParameters: DeletePacketSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePacketSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packet-species/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePacketSpecies(requestParameters: DeletePacketSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePacketSpeciesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllPacketSpeciesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PacketSpecies>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packet-species`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PacketSpeciesFromJSON));
    }

    /**
     */
    async getAllPacketSpecies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PacketSpecies>> {
        const response = await this.getAllPacketSpeciesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPacketSpeciesRaw(requestParameters: GetPacketSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketSpecies>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPacketSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packet-species/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketSpeciesFromJSON(jsonValue));
    }

    /**
     */
    async getPacketSpecies(requestParameters: GetPacketSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketSpecies> {
        const response = await this.getPacketSpeciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdatePacketSpeciesRaw(requestParameters: PartialUpdatePacketSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketSpecies>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePacketSpecies.');
        }

        if (requestParameters.packetSpecies === null || requestParameters.packetSpecies === undefined) {
            throw new runtime.RequiredError('packetSpecies','Required parameter requestParameters.packetSpecies was null or undefined when calling partialUpdatePacketSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packet-species/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PacketSpeciesToJSON(requestParameters.packetSpecies),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketSpeciesFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdatePacketSpecies(requestParameters: PartialUpdatePacketSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketSpecies> {
        const response = await this.partialUpdatePacketSpeciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePacketSpeciesRaw(requestParameters: UpdatePacketSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PacketSpecies>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePacketSpecies.');
        }

        if (requestParameters.packetSpecies === null || requestParameters.packetSpecies === undefined) {
            throw new runtime.RequiredError('packetSpecies','Required parameter requestParameters.packetSpecies was null or undefined when calling updatePacketSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packet-species/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PacketSpeciesToJSON(requestParameters.packetSpecies),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketSpeciesFromJSON(jsonValue));
    }

    /**
     */
    async updatePacketSpecies(requestParameters: UpdatePacketSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PacketSpecies> {
        const response = await this.updatePacketSpeciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
