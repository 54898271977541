/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Logging } from './Logging';
import {
    LoggingFromJSON,
    LoggingFromJSONTyped,
    LoggingToJSON,
} from './Logging';
import type { Tree } from './Tree';
import {
    TreeFromJSON,
    TreeFromJSONTyped,
    TreeToJSON,
} from './Tree';

/**
 * 
 * @export
 * @interface Plot
 */
export interface Plot {
    /**
     * 
     * @type {number}
     * @memberof Plot
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Plot
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Plot
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof Plot
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof Plot
     */
    egrid?: string;
    /**
     * 
     * @type {string}
     * @memberof Plot
     */
    uuid?: string;
    /**
     * 
     * @type {Logging}
     * @memberof Plot
     */
    logging?: Logging;
    /**
     * 
     * @type {Set<Tree>}
     * @memberof Plot
     */
    trees?: Set<Tree>;
}

/**
 * Check if a given object implements the Plot interface.
 */
export function instanceOfPlot(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function PlotFromJSON(json: any): Plot {
    return PlotFromJSONTyped(json, false);
}

export function PlotFromJSONTyped(json: any, ignoreDiscriminator: boolean): Plot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'egrid': !exists(json, 'egrid') ? undefined : json['egrid'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'logging': !exists(json, 'logging') ? undefined : LoggingFromJSON(json['logging']),
        'trees': !exists(json, 'trees') ? undefined : (new Set((json['trees'] as Array<any>).map(TreeFromJSON))),
    };
}

export function PlotToJSON(value?: Plot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
        'number': value.number,
        'egrid': value.egrid,
        'uuid': value.uuid,
        'logging': LoggingToJSON(value.logging),
        'trees': value.trees === undefined ? undefined : (Array.from(value.trees as Set<any>).map(TreeToJSON)),
    };
}

