import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface UrstammImageSync {
  width?: number;
  height?: number;
  showText?: boolean;
}

const UrstammImageSync = (props: UrstammImageSync) => {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 52 52" fill="none">
      <Path
        d="M51 34.3333V45.4444C51 46.9179 50.4147 48.3309 49.3728 49.3728C48.3309 50.4147 46.9179 51 45.4444 51H6.55556C5.08213 51 3.66905 50.4147 2.62718 49.3728C1.58532 48.3309 1 46.9179 1 45.4444V34.3333M39.8889 14.8889L26 1M26 1L12.1111 14.8889M26 1V34.3333"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default UrstammImageSync;
