/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ShippingNoteState,
} from '../models';
import {
    ShippingNoteStateFromJSON,
    ShippingNoteStateToJSON,
} from '../models';

export interface CreateShippingNoteStateRequest {
    shippingNoteState: ShippingNoteState;
}

export interface DeleteShippingNoteStateRequest {
    id: number;
}

export interface GetShippingNoteStateRequest {
    id: number;
}

export interface PartialUpdateShippingNoteStateRequest {
    id: number;
    shippingNoteState: ShippingNoteState;
}

export interface UpdateShippingNoteStateRequest {
    id: number;
    shippingNoteState: ShippingNoteState;
}

/**
 * 
 */
export class ShippingNoteStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createShippingNoteStateRaw(requestParameters: CreateShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNoteState>> {
        if (requestParameters.shippingNoteState === null || requestParameters.shippingNoteState === undefined) {
            throw new runtime.RequiredError('shippingNoteState','Required parameter requestParameters.shippingNoteState was null or undefined when calling createShippingNoteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/shipping-note-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShippingNoteStateToJSON(requestParameters.shippingNoteState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteStateFromJSON(jsonValue));
    }

    /**
     */
    async createShippingNoteState(requestParameters: CreateShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNoteState> {
        const response = await this.createShippingNoteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteShippingNoteStateRaw(requestParameters: DeleteShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteShippingNoteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/shipping-note-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteShippingNoteState(requestParameters: DeleteShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteShippingNoteStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllShippingNoteStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShippingNoteState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/shipping-note-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShippingNoteStateFromJSON));
    }

    /**
     */
    async getAllShippingNoteStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShippingNoteState>> {
        const response = await this.getAllShippingNoteStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getShippingNoteStateRaw(requestParameters: GetShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNoteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getShippingNoteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/shipping-note-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteStateFromJSON(jsonValue));
    }

    /**
     */
    async getShippingNoteState(requestParameters: GetShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNoteState> {
        const response = await this.getShippingNoteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateShippingNoteStateRaw(requestParameters: PartialUpdateShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNoteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateShippingNoteState.');
        }

        if (requestParameters.shippingNoteState === null || requestParameters.shippingNoteState === undefined) {
            throw new runtime.RequiredError('shippingNoteState','Required parameter requestParameters.shippingNoteState was null or undefined when calling partialUpdateShippingNoteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/shipping-note-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ShippingNoteStateToJSON(requestParameters.shippingNoteState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateShippingNoteState(requestParameters: PartialUpdateShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNoteState> {
        const response = await this.partialUpdateShippingNoteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateShippingNoteStateRaw(requestParameters: UpdateShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNoteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateShippingNoteState.');
        }

        if (requestParameters.shippingNoteState === null || requestParameters.shippingNoteState === undefined) {
            throw new runtime.RequiredError('shippingNoteState','Required parameter requestParameters.shippingNoteState was null or undefined when calling updateShippingNoteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/shipping-note-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ShippingNoteStateToJSON(requestParameters.shippingNoteState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteStateFromJSON(jsonValue));
    }

    /**
     */
    async updateShippingNoteState(requestParameters: UpdateShippingNoteStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNoteState> {
        const response = await this.updateShippingNoteStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
