import { createSlice } from '@reduxjs/toolkit';
import {
  DeliverySheetDTO,
  EntityWithFileDTODeliverySheetPhoto,
  EntityWithFileDTOLogging,
  EntityWithFileDTOPile,
  EntityWithFileDTOTreeDTO,
  OtherAssortment,
  PlotDTO,
  Trunk
} from '@services/apis/generated';

export enum OfflineSyncTypeEnum {
  INSERT,
  UPDATE
}

export enum OfflineSyncClassEnum {
  DELIVERYSHEET,
  DELIVERYSHEETPHOTO,
  LOGGING,
  OTHERASSORTMENT,
  PILE,
  PLOT,
  TREE,
  TRUNK
}

export interface OfflineSyncToUpdate {
  entity:
    | DeliverySheetDTO
    | EntityWithFileDTODeliverySheetPhoto
    | EntityWithFileDTOLogging
    | EntityWithFileDTOPile
    | PlotDTO
    | EntityWithFileDTOTreeDTO
    | Trunk
    | OtherAssortment;
  type: OfflineSyncTypeEnum;
  class: OfflineSyncClassEnum;
  name: string;
  creationDate: Date;
  updateOnly: boolean;
}

export interface OfflineSyncCompleted {
  object: OfflineSyncToUpdate;
  newId: number | undefined;
  oldId: number | undefined;
}

export interface OfflineSyncState {
  updateList: OfflineSyncToUpdate[];
  lastSync?: Date;
}

const initialState: OfflineSyncState = {
  updateList: [],
  lastSync: undefined
};

export const offlineSync = createSlice({
  name: 'offlineSyncList',
  initialState,
  reducers: {
    updateOfflineObjectLastSync: state => {
      state.lastSync = new Date();
    },
    addOfflineObjectSync: (state, action) => {
      let updateList = { ...state }.updateList;
      action.payload.creationDate = new Date();
      updateList.push(action.payload);
      state.updateList = updateList;
    },
    removeOfflineObjectSync: (state, action) => {
      let updateList = { ...state }.updateList;
      let removed = false;
      updateList = updateList.filter(obj => {
        if (action.payload.object.class != obj.class) return true;
        if (action.payload.object.type != obj.type) return true;

        let entity = obj.entity;
        let payloadEntity = action.payload.object.entity;
        let objId;
        let payloadId;

        switch (action.payload.object.class) {
          case OfflineSyncClassEnum.DELIVERYSHEET:
            objId = (entity as DeliverySheetDTO).id;
            payloadId = payloadEntity.id;
            break;
          case OfflineSyncClassEnum.DELIVERYSHEETPHOTO:
            objId = (entity as EntityWithFileDTODeliverySheetPhoto).entity?.id;
            payloadId = payloadEntity.entity.id;
            break;
          case OfflineSyncClassEnum.LOGGING:
            objId = (entity as EntityWithFileDTOLogging).entity?.id;
            payloadId = payloadEntity.entity.id;
            break;
          case OfflineSyncClassEnum.PILE:
            objId = (entity as EntityWithFileDTOPile).entity?.id;
            payloadId = payloadEntity.entity.id;
            break;
          case OfflineSyncClassEnum.PLOT:
            objId = (entity as PlotDTO).id;
            payloadId = payloadEntity.id;
            break;
          case OfflineSyncClassEnum.TREE:
            objId = (entity as EntityWithFileDTOTreeDTO).entity?.id;
            payloadId = payloadEntity.entity.id;
            break;
          case OfflineSyncClassEnum.TRUNK:
            objId = (entity as Trunk).id;
            payloadId = payloadEntity.id;
            break;
        }

        const toInclude = objId != payloadId || removed; // Remove from array only if id are the same
        if (!toInclude) removed = true;
        return toInclude;
      });

      // If this is a new insert we must update the related items using new id.
      // For example: a Logging just created must update its Id in a Pile not already created
      if (action.payload.object.type == OfflineSyncTypeEnum.INSERT) {
        updateList.forEach(offlineSync => {
          switch (action.payload.object.class) {
            case OfflineSyncClassEnum.DELIVERYSHEET:
              if (offlineSync.class == OfflineSyncClassEnum.DELIVERYSHEET) {
                if ((offlineSync.entity as DeliverySheetDTO).id == action.payload.oldId) {
                  (offlineSync.entity as DeliverySheetDTO).id = action.payload.newId;
                }
              }
              if (offlineSync.class == OfflineSyncClassEnum.DELIVERYSHEETPHOTO) {
                if (
                  (offlineSync.entity as EntityWithFileDTODeliverySheetPhoto).entity?.deliverySheet?.id ==
                  action.payload.oldId
                ) {
                  (offlineSync.entity as EntityWithFileDTODeliverySheetPhoto).entity!.deliverySheet!.id =
                    action.payload.newId;
                }
              }
              if (offlineSync.class == OfflineSyncClassEnum.TRUNK) {
                if ((offlineSync.entity as Trunk).deliverySheet?.id == action.payload.oldId) {
                  (offlineSync.entity as Trunk).deliverySheet!.id = action.payload.newId;
                }
              }
              break;
            case OfflineSyncClassEnum.LOGGING:
              if (offlineSync.class == OfflineSyncClassEnum.LOGGING) {
                if ((offlineSync.entity as EntityWithFileDTOLogging).entity?.id == action.payload.oldId) {
                  (offlineSync.entity as EntityWithFileDTOLogging).entity!.id = action.payload.newId;
                }
              }
              if (offlineSync.class == OfflineSyncClassEnum.PILE) {
                if ((offlineSync.entity as EntityWithFileDTOPile).entity?.logging?.id == action.payload.oldId) {
                  (offlineSync.entity as EntityWithFileDTOPile).entity!.logging!.id = action.payload.newId;
                }
              }
              if (offlineSync.class == OfflineSyncClassEnum.PLOT) {
                if ((offlineSync.entity as PlotDTO).logging?.id == action.payload.oldId) {
                  (offlineSync.entity as PlotDTO).logging!.id = action.payload.newId;
                }
              }
              if (offlineSync.class == OfflineSyncClassEnum.DELIVERYSHEET) {
                if ((offlineSync.entity as DeliverySheetDTO).logging?.id == action.payload.oldId) {
                  (offlineSync.entity as DeliverySheetDTO).logging!.id = action.payload.newId;
                }
              }
              if (offlineSync.class == OfflineSyncClassEnum.TREE) {
                if ((offlineSync.entity as EntityWithFileDTOTreeDTO).entity?.logging?.id == action.payload.oldId) {
                  (offlineSync.entity as EntityWithFileDTOTreeDTO).entity!.logging!.id = action.payload.newId;
                }
              }
              break;
            case OfflineSyncClassEnum.PILE:
              if (offlineSync.class == OfflineSyncClassEnum.PILE) {
                if ((offlineSync.entity as EntityWithFileDTOPile).entity?.id == action.payload.oldId) {
                  (offlineSync.entity as EntityWithFileDTOPile).entity!.id = action.payload.newId;
                }
              }
              if (offlineSync.class == OfflineSyncClassEnum.DELIVERYSHEET) {
                if ((offlineSync.entity as DeliverySheetDTO).pile?.id == action.payload.oldId) {
                  (offlineSync.entity as DeliverySheetDTO).pile!.id = action.payload.newId;
                }
              }
              if (offlineSync.class == OfflineSyncClassEnum.OTHERASSORTMENT) {
                if ((offlineSync.entity as OtherAssortment).pile?.id == action.payload.oldId) {
                  (offlineSync.entity as OtherAssortment).pile!.id = action.payload.newId;
                }
              }
              break;
            case OfflineSyncClassEnum.TREE:
              if (offlineSync.class == OfflineSyncClassEnum.TREE) {
                if ((offlineSync.entity as EntityWithFileDTOTreeDTO).entity?.id == action.payload.oldId) {
                  (offlineSync.entity as EntityWithFileDTOTreeDTO).entity!.id = action.payload.newId;
                }
              }
              break;
            case OfflineSyncClassEnum.PLOT:
              if (offlineSync.class == OfflineSyncClassEnum.PLOT) {
                if ((offlineSync.entity as PlotDTO).id == action.payload.oldId) {
                  (offlineSync.entity as PlotDTO).id = action.payload.newId;
                }
              }
              break;
            case OfflineSyncClassEnum.TRUNK:
              if (offlineSync.class == OfflineSyncClassEnum.TRUNK) {
                if ((offlineSync.entity as Trunk).id == action.payload.oldId) {
                  (offlineSync.entity as Trunk).id = action.payload.newId;
                }
              }
              break;
            case OfflineSyncClassEnum.OTHERASSORTMENT:
              if (offlineSync.class == OfflineSyncClassEnum.OTHERASSORTMENT) {
                if ((offlineSync.entity as OtherAssortment).id == action.payload.oldId) {
                  (offlineSync.entity as OtherAssortment).id = action.payload.newId;
                }
              }
              break;
            case OfflineSyncClassEnum.DELIVERYSHEETPHOTO:
              break;
          }
        });
      }

      state.updateList = updateList;
    },
    clearOfflineSyncList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateOfflineObjectLastSync, addOfflineObjectSync, removeOfflineObjectSync, clearOfflineSyncList } =
  offlineSync.actions;

export default offlineSync.reducer;
