/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShippingNoteStateUpdateDTO
 */
export interface ShippingNoteStateUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteStateUpdateDTO
     */
    updatedShippingNoteStateEnum?: ShippingNoteStateUpdateDTOUpdatedShippingNoteStateEnumEnum;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteStateUpdateDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteStateUpdateDTO
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteStateUpdateDTO
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteStateUpdateDTO
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteStateUpdateDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteStateUpdateDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteStateUpdateDTO
     */
    zipCode?: string;
}


/**
 * @export
 */
export const ShippingNoteStateUpdateDTOUpdatedShippingNoteStateEnumEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type ShippingNoteStateUpdateDTOUpdatedShippingNoteStateEnumEnum = typeof ShippingNoteStateUpdateDTOUpdatedShippingNoteStateEnumEnum[keyof typeof ShippingNoteStateUpdateDTOUpdatedShippingNoteStateEnumEnum];


/**
 * Check if a given object implements the ShippingNoteStateUpdateDTO interface.
 */
export function instanceOfShippingNoteStateUpdateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShippingNoteStateUpdateDTOFromJSON(json: any): ShippingNoteStateUpdateDTO {
    return ShippingNoteStateUpdateDTOFromJSONTyped(json, false);
}

export function ShippingNoteStateUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingNoteStateUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedShippingNoteStateEnum': !exists(json, 'updatedShippingNoteStateEnum') ? undefined : json['updatedShippingNoteStateEnum'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
    };
}

export function ShippingNoteStateUpdateDTOToJSON(value?: ShippingNoteStateUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updatedShippingNoteStateEnum': value.updatedShippingNoteStateEnum,
        'name': value.name,
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
        'city': value.city,
        'zipCode': value.zipCode,
    };
}

