import { DateHelper } from '@helpers/DateHelper';
import { NetworkHelper } from '@helpers/NetworkHelper';
import { OfflineSyncClassEnum, OfflineSyncTypeEnum } from '@redux/features/offlineSync/offlineSyncSlice';
import { setPlotList } from '@redux/features/plot/plotSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Dispatch, AnyAction } from 'redux';
import { clearElementForRedux } from '../../utils/classes/UrstammClassMapper';
import { sortOfflineList } from '../../utils/classes/UrstammUtilityFunctions';
import { customPlotResourceApi } from './ApiConfiguration';
import {
  CreatePlotCustomRequest,
  GetPlotsByLoggingRequest,
  PartialUpdatePlotCustomRequest,
  Plot,
  PlotDTO
} from './generated';

export class FullAvailablePlotResourceApi {
  /**
   * Get all plot list for logging
   * @param rdxPlotCompleteList Redux list of Plot
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns Updated list of plots by logging or redux list if network is not reachable
   */
  static async getPlotsByLogging(
    rdxPlotCompleteList: PlotDTO[],
    requestParameters: GetPlotsByLoggingRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PlotDTO>> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk) return customPlotResourceApi.getPlotsByLogging(requestParameters, initOverrides);
    let plotList = rdxPlotCompleteList.filter(plot => plot.logging?.id == requestParameters.loggingId);
    return sortOfflineList(plotList, requestParameters)
  }

  /**
   * Create a Plot
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async createPlotCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: CreatePlotCustomRequest,
    initOverrides?: RequestInit
  ) {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customPlotResourceApi.createPlotCustom(requestParameters, initOverrides);
    requestParameters.plotDTO!.id = DateHelper.nowTimestamp();
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.plotDTO,
        type: OfflineSyncTypeEnum.INSERT,
        class: OfflineSyncClassEnum.PLOT,
        name: requestParameters.plotDTO.name
      })
    );
    requestParameters.plotDTO.logging = clearElementForRedux(requestParameters.plotDTO.logging);
    dispatch(setPlotList([requestParameters.plotDTO]));
    return requestParameters.plotDTO;
  }

  /**
   * Update a Plot
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async partialUpdatePlotCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: PartialUpdatePlotCustomRequest,
    initOverrides?: RequestInit
  ): Promise<Plot> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customPlotResourceApi.partialUpdatePlotCustom(requestParameters, initOverrides);
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.plotDTO,
        type: OfflineSyncTypeEnum.UPDATE,
        class: OfflineSyncClassEnum.PLOT,
        name: requestParameters.plotDTO.name
      })
    );
    dispatch(setPlotList([requestParameters.plotDTO]));
    return {
      ...requestParameters.plotDTO,
      name: requestParameters.plotDTO.name!
    };
  }
}
