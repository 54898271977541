import { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { createSlice } from '@reduxjs/toolkit';

export interface BatchFilterState {
  value: SlideButtonOption[];
}

const initialState: BatchFilterState = {
  value: []
};

export const batchFilter = createSlice({
  name: 'batchFilter',
  initialState,
  reducers: {
    setBatchFilter: (state, action) => {
      state.value = action.payload;
    },
    setBatchFilterList: (state, action) => {
      state.value = action.payload;
    },
    selectBatchFilter: (state, action) => {
      let selected = action.payload;
      let filterList = { ...state }.value;
      if (filterList) {
        for (let elem of filterList) {
          elem.active = elem.id == selected.id;
        }
        state.value = filterList;
      }
    },
    reset: state => {
      initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setBatchFilter, setBatchFilterList, selectBatchFilter, reset } = batchFilter.actions;

export default batchFilter.reducer;
