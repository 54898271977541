/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DoubleFilter } from './DoubleFilter';
import {
    DoubleFilterFromJSON,
    DoubleFilterFromJSONTyped,
    DoubleFilterToJSON,
} from './DoubleFilter';
import type { InstantFilter } from './InstantFilter';
import {
    InstantFilterFromJSON,
    InstantFilterFromJSONTyped,
    InstantFilterToJSON,
} from './InstantFilter';
import type { LongFilter } from './LongFilter';
import {
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
} from './LongFilter';
import type { ShippingNoteStateEnumFilter } from './ShippingNoteStateEnumFilter';
import {
    ShippingNoteStateEnumFilterFromJSON,
    ShippingNoteStateEnumFilterFromJSONTyped,
    ShippingNoteStateEnumFilterToJSON,
} from './ShippingNoteStateEnumFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface CustomShippingNoteCriteria
 */
export interface CustomShippingNoteCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomShippingNoteCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomShippingNoteCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomShippingNoteCriteria
     */
    comment?: StringFilter;
    /**
     * 
     * @type {InstantFilter}
     * @memberof CustomShippingNoteCriteria
     */
    creationDate?: InstantFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomShippingNoteCriteria
     */
    sumCubage?: DoubleFilter;
    /**
     * 
     * @type {ShippingNoteStateEnumFilter}
     * @memberof CustomShippingNoteCriteria
     */
    currentState?: ShippingNoteStateEnumFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomShippingNoteCriteria
     */
    pdfFilename?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomShippingNoteCriteria
     */
    pdfFilepath?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomShippingNoteCriteria
     */
    pdfMd5?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomShippingNoteCriteria
     */
    uuid?: StringFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomShippingNoteCriteria
     */
    mockProcessorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomShippingNoteCriteria
     */
    processorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomShippingNoteCriteria
     */
    sawmillId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomShippingNoteCriteria
     */
    pdfAuthorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomShippingNoteCriteria
     */
    batchId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomShippingNoteCriteria
     */
    packetId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomShippingNoteCriteria
     */
    shippingNoteStateId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomShippingNoteCriteria
     */
    distinct?: boolean;
}

/**
 * Check if a given object implements the CustomShippingNoteCriteria interface.
 */
export function instanceOfCustomShippingNoteCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomShippingNoteCriteriaFromJSON(json: any): CustomShippingNoteCriteria {
    return CustomShippingNoteCriteriaFromJSONTyped(json, false);
}

export function CustomShippingNoteCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomShippingNoteCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : StringFilterFromJSON(json['name']),
        'comment': !exists(json, 'comment') ? undefined : StringFilterFromJSON(json['comment']),
        'creationDate': !exists(json, 'creationDate') ? undefined : InstantFilterFromJSON(json['creationDate']),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : DoubleFilterFromJSON(json['sumCubage']),
        'currentState': !exists(json, 'currentState') ? undefined : ShippingNoteStateEnumFilterFromJSON(json['currentState']),
        'pdfFilename': !exists(json, 'pdfFilename') ? undefined : StringFilterFromJSON(json['pdfFilename']),
        'pdfFilepath': !exists(json, 'pdfFilepath') ? undefined : StringFilterFromJSON(json['pdfFilepath']),
        'pdfMd5': !exists(json, 'pdfMd5') ? undefined : StringFilterFromJSON(json['pdfMd5']),
        'uuid': !exists(json, 'uuid') ? undefined : StringFilterFromJSON(json['uuid']),
        'mockProcessorId': !exists(json, 'mockProcessorId') ? undefined : LongFilterFromJSON(json['mockProcessorId']),
        'processorId': !exists(json, 'processorId') ? undefined : LongFilterFromJSON(json['processorId']),
        'sawmillId': !exists(json, 'sawmillId') ? undefined : LongFilterFromJSON(json['sawmillId']),
        'pdfAuthorId': !exists(json, 'pdfAuthorId') ? undefined : LongFilterFromJSON(json['pdfAuthorId']),
        'batchId': !exists(json, 'batchId') ? undefined : LongFilterFromJSON(json['batchId']),
        'packetId': !exists(json, 'packetId') ? undefined : LongFilterFromJSON(json['packetId']),
        'shippingNoteStateId': !exists(json, 'shippingNoteStateId') ? undefined : LongFilterFromJSON(json['shippingNoteStateId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CustomShippingNoteCriteriaToJSON(value?: CustomShippingNoteCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'name': StringFilterToJSON(value.name),
        'comment': StringFilterToJSON(value.comment),
        'creationDate': InstantFilterToJSON(value.creationDate),
        'sumCubage': DoubleFilterToJSON(value.sumCubage),
        'currentState': ShippingNoteStateEnumFilterToJSON(value.currentState),
        'pdfFilename': StringFilterToJSON(value.pdfFilename),
        'pdfFilepath': StringFilterToJSON(value.pdfFilepath),
        'pdfMd5': StringFilterToJSON(value.pdfMd5),
        'uuid': StringFilterToJSON(value.uuid),
        'mockProcessorId': LongFilterToJSON(value.mockProcessorId),
        'processorId': LongFilterToJSON(value.processorId),
        'sawmillId': LongFilterToJSON(value.sawmillId),
        'pdfAuthorId': LongFilterToJSON(value.pdfAuthorId),
        'batchId': LongFilterToJSON(value.batchId),
        'packetId': LongFilterToJSON(value.packetId),
        'shippingNoteStateId': LongFilterToJSON(value.shippingNoteStateId),
        'distinct': value.distinct,
    };
}

