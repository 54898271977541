import { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import { i18n } from '@i18n/i18n';
import { OfflineSyncToUpdate } from '@redux/features/offlineSync/offlineSyncSlice';
import { Logging, LoggingCurrentStateEnum } from '@services/apis/generated';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import {
  getColorByLoggingState,
  getIconByLoggingState,
  getTextByLoggingState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { getCardTitleByByOfflineSync } from '../../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyle, UrstammStyleList } from '../../../utils/styles/UrstammStyle';

export default function OfflineSyncListView(props: {
  navigation: any;
  offlineSyncList: OfflineSyncToUpdate[];
  moreItems: () => void;
  navigateToLoggingDetails: (offlineSyncSelected: Logging) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
}) {
  const dispatch = useDispatch();

  let keys: ListSubData[] = [
    {
      title: i18n.t('generics.name'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'name',
      keyId: '1name'
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    }
  ];

  useEffect(() => {
    getSlideButtonOptionsLoggingCurrentStates();
    return () => {};
  }, []);

  const getSlideButtonOptionsLoggingCurrentStates = (): SlideButtonOption[] => {
    let filterList: SlideButtonOption[] = [];
    let type: SlideButtonOption = {
      id: 'ALL',
      text: i18n.t('utility_components.filter_button.all'),
      active: true
    };
    filterList.push(type);
    Object.values(LoggingCurrentStateEnum).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByLoggingState(state),
        active: false
      };
      filterList.push(type);
    });

    return filterList;
  };

  return (
    <View style={[UrstammStyle.flex1]}>
      <View style={[{ flex: 0.9 }]}>
        <UrstammList
          elementSelected={undefined}
          testID={'offlineSync_list'}
          textForEmptyList={i18n.t('views.offlineSync.offlineSyncs_not_found')}
          list={props.offlineSyncList}
          listStyle={{
            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
            bottomContainerViewStyle: UrstammStyleList.cardSubData,
            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
          }}
          listSubData={keys}
          titleByFunction={data => getCardTitleByByOfflineSync(data)}
          filterBy={undefined}
          moreItems={props.moreItems}
          currentState={{
            icon: state => getIconByLoggingState(state),
            color: state => getColorByLoggingState(state),
            text: state => getTextByLoggingState(state)
          }}
          showHash={false}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
