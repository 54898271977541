import { createSlice } from '@reduxjs/toolkit';
import { Company, UserCompanyRoleEnum, UserExtended } from '@services/apis/generated';

export interface UserState {
  value: string;
  extendedMe: UserExtended;
  myCompany?: Company;
  myCompanyRole?: UserCompanyRoleEnum;
}

const initialState: UserState = {
  value: '',
  extendedMe: {} as UserExtended,
  myCompany: undefined,
  myCompanyRole: undefined
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.value = action.payload;
    },
    setExtendedMe: (state, action) => {
      state.extendedMe = action.payload;
    },
    setMyCompany: (state, action) => {
      state.myCompany = action.payload;
    },
    setMyCompanyRole: (state, action) => {
      state.myCompanyRole = action.payload;
    },
    resetToken: () => initialState
  }
});

// Action creators are generated for each case reducer function
export const { setToken, resetToken, setExtendedMe, setMyCompany, setMyCompanyRole } = userSlice.actions;

export default userSlice.reducer;
