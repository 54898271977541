import { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { createSlice } from '@reduxjs/toolkit';

export interface ProcessorBatchFilterState {
  value: SlideButtonOption[];
}

const initialState: ProcessorBatchFilterState = {
  value: []
};

export const processorBatchFilter = createSlice({
  name: 'processorBatchFilter',
  initialState,
  reducers: {
    setProcessorBatchFilter: (state, action) => {
      state.value = action.payload;
    },
    setProcessorBatchFilterList: (state, action) => {
      state.value = action.payload;
    },
    selectProcessorBatchFilter: (state, action) => {
      let selected = action.payload;
      let filterList = { ...state }.value;
      if (filterList) {
        for (let elem of filterList) {
          elem.active = elem.id == selected.id;
        }
        state.value = filterList;
      }
    },
    reset: state => {
      initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setProcessorBatchFilter, setProcessorBatchFilterList, selectProcessorBatchFilter, reset } = processorBatchFilter.actions;

export default processorBatchFilter.reducer;
