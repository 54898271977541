/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomProcessorShippingNoteCriteria,
  PageProcessorShippingNoteDTO,
  ProcessorShippingNote,
  ProcessorShippingNoteDTO,
  ProcessorShippingNoteStateUpdateDTO,
} from '../models';
import {
    CustomProcessorShippingNoteCriteriaFromJSON,
    CustomProcessorShippingNoteCriteriaToJSON,
    PageProcessorShippingNoteDTOFromJSON,
    PageProcessorShippingNoteDTOToJSON,
    ProcessorShippingNoteFromJSON,
    ProcessorShippingNoteToJSON,
    ProcessorShippingNoteDTOFromJSON,
    ProcessorShippingNoteDTOToJSON,
    ProcessorShippingNoteStateUpdateDTOFromJSON,
    ProcessorShippingNoteStateUpdateDTOToJSON,
} from '../models';

export interface CreateProcessorShippingNoteCustomRequest {
    processorShippingNote: ProcessorShippingNote;
}

export interface DownloadProcessorShippingNotePdfRequest {
    id: number;
}

export interface GetPageProcessorShippingNotesForMyCompanyRequest {
    customProcessorShippingNoteCriteria: CustomProcessorShippingNoteCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface GetProcessorShippingNoteCustomRequest {
    id: number;
}

export interface UpdateProcessorShippingNoteCustomRequest {
    id: number;
    processorShippingNote: ProcessorShippingNote;
}

export interface UpdateProcessorShippingNoteStateCustomRequest {
    id: number;
    processorShippingNoteStateUpdateDTO: ProcessorShippingNoteStateUpdateDTO;
}

/**
 * 
 */
export class CustomProcessorShippingNoteResourceApi extends runtime.BaseAPI {

    /**
     * Create a new ShippingNote connecting to Company of logged user.
     * Create a ShippingNote
     */
    async createProcessorShippingNoteCustomRaw(requestParameters: CreateProcessorShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNote>> {
        if (requestParameters.processorShippingNote === null || requestParameters.processorShippingNote === undefined) {
            throw new runtime.RequiredError('processorShippingNote','Required parameter requestParameters.processorShippingNote was null or undefined when calling createProcessorShippingNoteCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/processor-shipping-notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorShippingNoteToJSON(requestParameters.processorShippingNote),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteFromJSON(jsonValue));
    }

    /**
     * Create a new ShippingNote connecting to Company of logged user.
     * Create a ShippingNote
     */
    async createProcessorShippingNoteCustom(requestParameters: CreateProcessorShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNote> {
        const response = await this.createProcessorShippingNoteCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download pdf corresponding to ShippingNote identified by given id.
     * Download pdf of ShippingNote
     */
    async downloadProcessorShippingNotePdfRaw(requestParameters: DownloadProcessorShippingNotePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadProcessorShippingNotePdf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/processor-shipping-notes/{id}/download/pdf`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download pdf corresponding to ShippingNote identified by given id.
     * Download pdf of ShippingNote
     */
    async downloadProcessorShippingNotePdf(requestParameters: DownloadProcessorShippingNotePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadProcessorShippingNotePdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a page of ShippingNote belonging to Company of logged User.
     * Get a page of shipping-notes for my Company
     */
    async getPageProcessorShippingNotesForMyCompanyRaw(requestParameters: GetPageProcessorShippingNotesForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageProcessorShippingNoteDTO>> {
        if (requestParameters.customProcessorShippingNoteCriteria === null || requestParameters.customProcessorShippingNoteCriteria === undefined) {
            throw new runtime.RequiredError('customProcessorShippingNoteCriteria','Required parameter requestParameters.customProcessorShippingNoteCriteria was null or undefined when calling getPageProcessorShippingNotesForMyCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/processor-shipping-notes/company/my`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomProcessorShippingNoteCriteriaToJSON(requestParameters.customProcessorShippingNoteCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageProcessorShippingNoteDTOFromJSON(jsonValue));
    }

    /**
     * Get a page of ShippingNote belonging to Company of logged User.
     * Get a page of shipping-notes for my Company
     */
    async getPageProcessorShippingNotesForMyCompany(requestParameters: GetPageProcessorShippingNotesForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageProcessorShippingNoteDTO> {
        const response = await this.getPageProcessorShippingNotesForMyCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get ShippingNote identified by given id (must belong to Company of logged user).
     * Get a specific ShippingNote
     */
    async getProcessorShippingNoteCustomRaw(requestParameters: GetProcessorShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNoteDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProcessorShippingNoteCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/processor-shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteDTOFromJSON(jsonValue));
    }

    /**
     * Get ShippingNote identified by given id (must belong to Company of logged user).
     * Get a specific ShippingNote
     */
    async getProcessorShippingNoteCustom(requestParameters: GetProcessorShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNoteDTO> {
        const response = await this.getProcessorShippingNoteCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update ShippingNote identified by given id (must belong to Company of logged user).
     * Partial update a ShippingNote
     */
    async updateProcessorShippingNoteCustomRaw(requestParameters: UpdateProcessorShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProcessorShippingNoteCustom.');
        }

        if (requestParameters.processorShippingNote === null || requestParameters.processorShippingNote === undefined) {
            throw new runtime.RequiredError('processorShippingNote','Required parameter requestParameters.processorShippingNote was null or undefined when calling updateProcessorShippingNoteCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/processor-shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorShippingNoteToJSON(requestParameters.processorShippingNote),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteFromJSON(jsonValue));
    }

    /**
     * Partial update ShippingNote identified by given id (must belong to Company of logged user).
     * Partial update a ShippingNote
     */
    async updateProcessorShippingNoteCustom(requestParameters: UpdateProcessorShippingNoteCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNote> {
        const response = await this.updateProcessorShippingNoteCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ShippingNote state identified by given id (must belong to Company of logged user).
     * Update a ShippingNote state
     */
    async updateProcessorShippingNoteStateCustomRaw(requestParameters: UpdateProcessorShippingNoteStateCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorShippingNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProcessorShippingNoteStateCustom.');
        }

        if (requestParameters.processorShippingNoteStateUpdateDTO === null || requestParameters.processorShippingNoteStateUpdateDTO === undefined) {
            throw new runtime.RequiredError('processorShippingNoteStateUpdateDTO','Required parameter requestParameters.processorShippingNoteStateUpdateDTO was null or undefined when calling updateProcessorShippingNoteStateCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/processor-shipping-notes/{id}/state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorShippingNoteStateUpdateDTOToJSON(requestParameters.processorShippingNoteStateUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorShippingNoteFromJSON(jsonValue));
    }

    /**
     * Update ShippingNote state identified by given id (must belong to Company of logged user).
     * Update a ShippingNote state
     */
    async updateProcessorShippingNoteStateCustom(requestParameters: UpdateProcessorShippingNoteStateCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorShippingNote> {
        const response = await this.updateProcessorShippingNoteStateCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
