import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import {
  setIncomingProcessorShippingNoteList,
  setIncomingProcessorShippingNoteListSort,
  setIncomingProcessorShippingNotePage,
  setIncomingProcessorShippingNoteTotalPages,
  incomingProcessorShippingNote
} from '@redux/features/processor-shipping-note/incomingProcessorShippingNoteSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { RootState } from '@redux/store';

import { customProcessorShippingNoteResourceApi, listSize } from '@services/apis/ApiConfiguration';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';
import IncomingProcessorShippingNoteListView from '@components/views/processor-shipping-note/IncomingProcessorShippingNoteListView';
import { ProcessorShippingNote, PageProcessorShippingNoteDTO, CustomProcessorShippingNoteCriteriaCompanyFilterEnum } from '@services/apis/generated';
import { useFocusEffect } from '@react-navigation/native';

export default function IncomingProcessorShippingNoteListContainer({ navigation, route }: RootStackScreenProps<'IncomingProcessorShippingNoteList'>) {
  const dispatch = useDispatch();

  //REDUX STATE
  const {
    list,
    page,
    totalPages,
    sortBy
  } = useSelector((state: RootState) => state.persistedReducer.incomingProcessorShippingNote);

  // Local state
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  // Get Processor Shipping Note List
  const getProcessorShippingNoteList = useCallback((order?: ButtonSelect[]) => {
    const sortBy =
      order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['creationDate,desc'];
    dispatch(changeLoaderStatus(true));
    const criteriaFilter = CustomProcessorShippingNoteCriteriaCompanyFilterEnum.Processor;
    customProcessorShippingNoteResourceApi
      .getPageProcessorShippingNotesForMyCompany({
        sort: sortBy,
        customProcessorShippingNoteCriteria: { currentState: {}, companyFilter: criteriaFilter },
        page,
        size: listSize
      })
      .then((processorShippingNoteList: PageProcessorShippingNoteDTO) => {
        dispatch(changeLoaderStatus(false));
        if (processorShippingNoteList) {
          dispatch(setIncomingProcessorShippingNoteTotalPages(processorShippingNoteList.totalPages));
          dispatch(setIncomingProcessorShippingNoteList(processorShippingNoteList.content));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(changeLoaderStatus(false));
    getProcessorShippingNoteList(sortBy);
  }, [dispatch, route.params, sortBy]);

  useFocusEffect(
    useCallback(() => {
      getProcessorShippingNoteList(sortBy);
    }, [dispatch, route.params, page, sortBy])
  );

  // apply order
  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setIncomingProcessorShippingNoteListSort(orderSelected));
    setShowSortBy(false);
  };

  // navigate to processor shipping note details
  const navigateToProcessorShippingNoteDetails = (processorShippingNote: ProcessorShippingNote) => {
    UrstammNavigationHelper.navigateToProcessorShippingNoteDetails(navigation, processorShippingNote);
  };

  // navigate to processor shipping note registration
  const navigateToProcessorShippingNoteRegistration = () => {
    UrstammNavigationHelper.navigateToProcessorShippingNoteRegistration(navigation, list);
  };

  // get more items
  const getMoreItems = (): void => {
    if (page < totalPages - 1) {
      dispatch(setIncomingProcessorShippingNotePage(page + 1));
    }
  };

  /**
   * Settings for corner buttons
   */
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText:
        sortBy.length > 0 && sortBy[0] ? sortBy[0].text : i18n.t('utility_components.modal.sortBy.date_desc')
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('generics.incoming_goods'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerLogoTitleStart,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitBottomRight={navigateToProcessorShippingNoteRegistration}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomLeft={() => setShowSortBy(true)}
      title={
        <UrstammTitle
          testID={'shipping_note_title'}
          text={i18n.t('generics.incoming_goods')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <IncomingProcessorShippingNoteListView
          navigation={navigation}
          processorShippingNoteList={list}
          modalVisible={false}
          moreItems={getMoreItems}
          processorShippingNoteSelected={(processorShippingNote: ProcessorShippingNote) =>
            navigateToProcessorShippingNoteDetails(processorShippingNote)
          }
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
        />
      }
    />
  );
}
