import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { LoggingDTO } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface LoggingState {
  list: LoggingDTO[];
  completeList: LoggingDTO[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: LoggingState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const logging = createSlice({
  name: 'loggingList',
  initialState,
  reducers: {
    setLoggingList: (state, action) => {
      let loggingList = { ...state }.list;
      let loggingCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(loggingList, element);
        pushElementOrReplaceById(loggingCompleteList, element);
      });

      state.list = loggingList;
      state.completeList = loggingCompleteList;
    },
    setCompleteLoggingList: (state, action) => {
      // Repetition
      let loggingCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(loggingCompleteList, element);
      });

      state.completeList = loggingCompleteList;
    },
    removeLoggingElement: (state, action) => {
      let loggingList = { ...state }.list;
      let loggingCompleteList = { ...state }.completeList;

      let element: LoggingDTO = action.payload;
      loggingList = loggingList.filter(e => e.id != element.id);
      loggingCompleteList = loggingCompleteList.filter(e => e.id != element.id);

      state.list = loggingList;
      state.completeList = loggingCompleteList;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setLoggingListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setLoggingPage: (state, action) => {
      state.page = action.payload;
    },
    resetLoggingList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearLoggingList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setLoggingList,
  setCompleteLoggingList,
  removeLoggingElement,
  resetLoggingList,
  setLoggingPage,
  setTotalPages,
  setLoggingListSort,
  clearLoggingList
} = logging.actions;

export default logging.reducer;
