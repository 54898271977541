import OfflineSyncManager from '@components/utility-components/offlineSync/OfflineSyncManager';
import LoginView from '@components/views/login/LoginView';
import { environment } from '@environments/environment';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { storeAccessToken } from '@helpers/StorageHelper';
import { changeLoaderForSyncStatus, changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { updateOfflineObjectLastSync } from '@redux/features/offlineSync/offlineSyncSlice';
import {
  customCompanyResourceApi,
  customUserExtendedResourceApi,
  userJwtControllerApi
} from '@services/apis/ApiConfiguration';
import { LoginVM, UserExtendedTypeEnum } from '@services/apis/generated';
import React, { useRef } from 'react';
import { StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import LoginLayout from '../../components/layouts/login/LoginLayout';
import { RootStackScreenProps } from '../../navigation/UrstammNavigationHelper';
import { setExtendedMe, setMyCompany, setMyCompanyRole, setToken } from '../../redux/features/user/userSlice';

export default function LoginContainer({ navigation, route }: RootStackScreenProps<'Login'>) {
  const dispatch = useDispatch();
  const offlineSyncManagerRef = useRef<any>();

  const submitLogin = (data: LoginVM) => {
    dispatch(changeLoaderStatus(true));
    userJwtControllerApi
      .authorize({ loginVM: data })
      .then(async res => {
        if (res.idToken) {
          let currentRef = offlineSyncManagerRef.current;
          await storeAccessToken(res.idToken);
          await getUserExtendedMe(currentRef);
          dispatch(setToken(res.idToken));
          dispatch(changeLoaderStatus(false));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        if (errorMessage && errorMessage.detail) {
          alert(errorMessage.detail);
          return;
        }
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const startDownloadSync = async (ref: any) => {
    dispatch(changeLoaderForSyncStatus(true));
    let downloadOk = await ref!.startDownloadSync(true);
    dispatch(changeLoaderForSyncStatus(false));
    if (downloadOk) {
      dispatch(updateOfflineObjectLastSync());
      AlertHelper.showSuccessWithMessage('alerts.sync_successfully');
    } else {
      AlertHelper.showSimpleAlert('Error', 'cannot_complete_sync');
    }
  };

  const getUserExtendedMe = (ref: any): void => {
    customUserExtendedResourceApi
      .getUserExtendedMe()
      .then(async me => {
        if (me) {
          if (environment.enableOfflineSync && me.type == UserExtendedTypeEnum.Forestry) await startDownloadSync(ref);
          dispatch(setExtendedMe(me));
          dispatch(changeLoaderStatus(false));
          getMyCompany();
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getMyCompany = (): void => {
    customCompanyResourceApi
      .getMyCompany()
      .then(company => {
        if (company) {
          dispatch(setMyCompany(company));
        }
      })
      .catch(async error => {
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });

      customCompanyResourceApi
      .getMyCompanyUserCompany()
      .then(userCompany => {
        if (userCompany) {
          dispatch(setMyCompanyRole(userCompany.role));
        }
      })
      .catch(async error => {
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <>
      <OfflineSyncManager ref={offlineSyncManagerRef} />
      <LoginLayout
        navigation={navigation}
        view={<LoginView submit={(authData: LoginVM) => submitLogin(authData)} navigation={navigation} />}
      />
    </>
  );
}

const styles = StyleSheet.create({});
