import { createSlice } from '@reduxjs/toolkit';
import { PlotDTO } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';
export interface PlotState {
  list: PlotDTO[];
  completeList: PlotDTO[];
}

const initialState: PlotState = {
  list: [],
  completeList: []
};

export const plot = createSlice({
  name: 'plotList',
  initialState,
  reducers: {
    setPlotList: (state, action) => {
      let plotList = { ...state }.list;
      let plotCompleteList = { ...state }.completeList;

      plotList = action.payload;
      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(plotCompleteList, element);
      });

      state.list = plotList;
      state.completeList = plotCompleteList;
    },
    setCompletePlotList: (state, action) => {
      // Repetition
      let plotCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(plotCompleteList, element);
      });

      state.completeList = plotCompleteList;
    },
    removePlotElement: (state, action) => {
      let plotList = { ...state }.list;
      let plotCompleteList = { ...state }.completeList;

      let element: PlotDTO = action.payload;
      plotList = plotList.filter(e => e.id != element.id);
      plotCompleteList = plotCompleteList.filter(e => e.id != element.id);

      state.list = plotList;
      state.completeList = plotCompleteList;
    },
    resetPlotList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearPlotList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const { setPlotList, setCompletePlotList, removePlotElement, resetPlotList, clearPlotList } = plot.actions;

export default plot.reducer;
