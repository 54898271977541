import UrstammInput from '@components/utility-components/input/UrstammInput';
import React from 'react';
import { Modal, StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native';
import { lightGreenBorderWhiteBGButton } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammStyle, UrstammStyleContainer, UrstammStyleModal } from '../../../utils/styles/UrstammStyle';
import UrstammButtonBase from '../button/UrstammButtonBase';
import { UrstammIconProps } from '../icon/UrstammIcon';

type ButtonText = {
  center: string;
};

export default function UrstammAlertModal(props: {
  testID: string;
  visible?: boolean;
  icon?: UrstammIconProps;
  text: string;
  textStyle: StyleProp<TextStyle>;
  buttonText: ButtonText;
  confirm: (confirm: boolean) => void;
  textInput?: boolean;
  onChangeText?: (state: any) => void;
}) {
  return (
    <View style={UrstammStyleModal.centeredView}>
      <Modal animationType="slide" transparent={true} visible={props.visible}>
        <View style={UrstammStyleModal.centeredView}>
          <View style={UrstammStyleModal.modalView}>
            <View style={UrstammStyleModal.textModalContainer}>
              <Text style={props.textStyle}>{props.text}</Text>
            </View>

            {props.textInput && (
              <View style={UrstammStyleModal.textModalContainer}>
                <UrstammInput
                  testID="text_modal"
                  textInputTitle={' '}
                  style={{
                    containerViewStyle: UrstammStyleContainer.inputContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  onChangeText={(text: string) => {
                    props.onChangeText!(text);
                  }}
                  //placeholder={i18n.t('views.login.insert_your_email_here')}
                />
              </View>
            )}

            <View style={styles.buttonMainContainer}>
              <View style={{ alignSelf: 'center' }}>
                {/**Left */}
                <View>
                  <UrstammButtonBase
                    testID={'left-button'}
                    text={props.buttonText.center}
                    style={lightGreenBorderWhiteBGButton}
                    onSubmit={() => props.confirm(false)}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonMainContainer: {
    height: 80,
    minWidth: 200,
    marginTop: 20
  }
});
