import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import PlotDetailsView from '@components/views/plot/PlotDetailsView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { RootState } from '@redux/store';
import { customPlotResourceApi } from '@services/apis/ApiConfiguration';
import { FullAvailablePlotResourceApi } from '@services/apis/FullAvailablePlotResourceApi';
import { Plot, PlotDTO } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { resetPlotList } from '../../redux/features/plot/plotSlice';
import { sleep, truncate, urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function PlotDetailsContainer({ navigation, route }: RootStackScreenProps<'PlotDetails'>) {
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const [plotSelected, setPlotSelected] = useState<Plot>();
  const dispatch = useDispatch();

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    if (route.params) {
      setPlotSelected(route.params.plotSelected);
    }
    return () => {};
  }, [route.params]);

  const updatePlot = (plotForm: PlotDTO): void => {
    const loggingSelected1 = route.params?.loggingSelected;
    plotForm.logging = {
      id: loggingSelected1?.id,
      name: loggingSelected1?.name,
      currentState: loggingSelected1?.currentState
    };

    dispatch(changeLoaderStatus(true));
    FullAvailablePlotResourceApi.partialUpdatePlotCustom(rdxOfflineSyncList, dispatch, addOfflineObjectSync, {
      id: plotSelected?.id!,
      plotDTO: plotForm
    })
      .then(res => {
        dispatch(changeLoaderStatus(false));

        if (res) {
          dispatch(resetPlotList());
          UrstammNavigationHelper.navigateToPlotList(navigation, route.params?.loggingSelected, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const deletePlot = (id: number): void => {
    dispatch(changeLoaderStatus(true));

    customPlotResourceApi
      .deletePlotCustom({ id: id })
      .then(() => {
        dispatch(resetPlotList());
        dispatch(changeLoaderStatus(false));
        UrstammNavigationHelper.navigateToPlotList(navigation, route.params?.loggingSelected, true);
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      title={
        <UrstammTitle
          testID={'plot_details_title'}
          text={i18n.t('generics.plot')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'plot_details_subtitle'}
          text={truncate(route.params?.plotSelected.name!, 30)}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={[
        {
          title: i18n.t('generics.logging'),
          value: route.params?.loggingSelected.name!
        }
      ]}
      view={
        <PlotDetailsView
          navigation={navigation}
          plotSelected={plotSelected!}
          modalVisible={false}
          loggingSelected={route.params?.loggingSelected}
          updatePlot={(form: PlotDTO) => updatePlot(form)}
          deletePlot={(plotId: number) => deletePlot(plotId)}
        />
      }
    />
  );
}
