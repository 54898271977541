/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DoubleFilter } from './DoubleFilter';
import {
    DoubleFilterFromJSON,
    DoubleFilterFromJSONTyped,
    DoubleFilterToJSON,
} from './DoubleFilter';
import type { InstantFilter } from './InstantFilter';
import {
    InstantFilterFromJSON,
    InstantFilterFromJSONTyped,
    InstantFilterToJSON,
} from './InstantFilter';
import type { LongFilter } from './LongFilter';
import {
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
} from './LongFilter';
import type { ProcessorShippingNoteStateEnumFilter } from './ProcessorShippingNoteStateEnumFilter';
import {
    ProcessorShippingNoteStateEnumFilterFromJSON,
    ProcessorShippingNoteStateEnumFilterFromJSONTyped,
    ProcessorShippingNoteStateEnumFilterToJSON,
} from './ProcessorShippingNoteStateEnumFilter';
import type { StringFilter } from './StringFilter';
import {
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './StringFilter';

/**
 * 
 * @export
 * @interface CustomProcessorShippingNoteCriteria
 */
export interface CustomProcessorShippingNoteCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    comment?: StringFilter;
    /**
     * 
     * @type {InstantFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    creationDate?: InstantFilter;
    /**
     * 
     * @type {DoubleFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    sumCubage?: DoubleFilter;
    /**
     * 
     * @type {ProcessorShippingNoteStateEnumFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    currentState?: ProcessorShippingNoteStateEnumFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    pdfFilename?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    pdfFilepath?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    pdfMd5?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    uuid?: StringFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    mockProcessorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    processorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    creatorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    pdfAuthorId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    outgoingBatchId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    referenceShippingNoteId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    incomingProductId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    processorShippingNoteStateId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    outgoingProductId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    distinct?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomProcessorShippingNoteCriteria
     */
    companyFilter?: CustomProcessorShippingNoteCriteriaCompanyFilterEnum;
}


/**
 * @export
 */
export const CustomProcessorShippingNoteCriteriaCompanyFilterEnum = {
    Creator: 'CREATOR',
    Processor: 'PROCESSOR'
} as const;
export type CustomProcessorShippingNoteCriteriaCompanyFilterEnum = typeof CustomProcessorShippingNoteCriteriaCompanyFilterEnum[keyof typeof CustomProcessorShippingNoteCriteriaCompanyFilterEnum];


/**
 * Check if a given object implements the CustomProcessorShippingNoteCriteria interface.
 */
export function instanceOfCustomProcessorShippingNoteCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomProcessorShippingNoteCriteriaFromJSON(json: any): CustomProcessorShippingNoteCriteria {
    return CustomProcessorShippingNoteCriteriaFromJSONTyped(json, false);
}

export function CustomProcessorShippingNoteCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomProcessorShippingNoteCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : StringFilterFromJSON(json['name']),
        'comment': !exists(json, 'comment') ? undefined : StringFilterFromJSON(json['comment']),
        'creationDate': !exists(json, 'creationDate') ? undefined : InstantFilterFromJSON(json['creationDate']),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : DoubleFilterFromJSON(json['sumCubage']),
        'currentState': !exists(json, 'currentState') ? undefined : ProcessorShippingNoteStateEnumFilterFromJSON(json['currentState']),
        'pdfFilename': !exists(json, 'pdfFilename') ? undefined : StringFilterFromJSON(json['pdfFilename']),
        'pdfFilepath': !exists(json, 'pdfFilepath') ? undefined : StringFilterFromJSON(json['pdfFilepath']),
        'pdfMd5': !exists(json, 'pdfMd5') ? undefined : StringFilterFromJSON(json['pdfMd5']),
        'uuid': !exists(json, 'uuid') ? undefined : StringFilterFromJSON(json['uuid']),
        'mockProcessorId': !exists(json, 'mockProcessorId') ? undefined : LongFilterFromJSON(json['mockProcessorId']),
        'processorId': !exists(json, 'processorId') ? undefined : LongFilterFromJSON(json['processorId']),
        'creatorId': !exists(json, 'creatorId') ? undefined : LongFilterFromJSON(json['creatorId']),
        'pdfAuthorId': !exists(json, 'pdfAuthorId') ? undefined : LongFilterFromJSON(json['pdfAuthorId']),
        'outgoingBatchId': !exists(json, 'outgoingBatchId') ? undefined : LongFilterFromJSON(json['outgoingBatchId']),
        'referenceShippingNoteId': !exists(json, 'referenceShippingNoteId') ? undefined : LongFilterFromJSON(json['referenceShippingNoteId']),
        'incomingProductId': !exists(json, 'incomingProductId') ? undefined : LongFilterFromJSON(json['incomingProductId']),
        'processorShippingNoteStateId': !exists(json, 'processorShippingNoteStateId') ? undefined : LongFilterFromJSON(json['processorShippingNoteStateId']),
        'outgoingProductId': !exists(json, 'outgoingProductId') ? undefined : LongFilterFromJSON(json['outgoingProductId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
        'companyFilter': !exists(json, 'companyFilter') ? undefined : json['companyFilter'],
    };
}

export function CustomProcessorShippingNoteCriteriaToJSON(value?: CustomProcessorShippingNoteCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'name': StringFilterToJSON(value.name),
        'comment': StringFilterToJSON(value.comment),
        'creationDate': InstantFilterToJSON(value.creationDate),
        'sumCubage': DoubleFilterToJSON(value.sumCubage),
        'currentState': ProcessorShippingNoteStateEnumFilterToJSON(value.currentState),
        'pdfFilename': StringFilterToJSON(value.pdfFilename),
        'pdfFilepath': StringFilterToJSON(value.pdfFilepath),
        'pdfMd5': StringFilterToJSON(value.pdfMd5),
        'uuid': StringFilterToJSON(value.uuid),
        'mockProcessorId': LongFilterToJSON(value.mockProcessorId),
        'processorId': LongFilterToJSON(value.processorId),
        'creatorId': LongFilterToJSON(value.creatorId),
        'pdfAuthorId': LongFilterToJSON(value.pdfAuthorId),
        'outgoingBatchId': LongFilterToJSON(value.outgoingBatchId),
        'referenceShippingNoteId': LongFilterToJSON(value.referenceShippingNoteId),
        'incomingProductId': LongFilterToJSON(value.incomingProductId),
        'processorShippingNoteStateId': LongFilterToJSON(value.processorShippingNoteStateId),
        'outgoingProductId': LongFilterToJSON(value.outgoingProductId),
        'distinct': value.distinct,
        'companyFilter': value.companyFilter,
    };
}

