import { i18n } from '@i18n/i18n';
import { CameraView } from 'expo-camera';
import * as ImagePicker from 'expo-image-picker';
import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Modal,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle
} from 'react-native';
import Layout from '../../../config/Layout';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { UrstammStyle, UrstammStyleModal } from '../../../utils/styles/UrstammStyle';
import UrstammIcon from '../icon/UrstammIcon';
import { UploadType } from './UrstammFilePicker';

export enum CameraState {
  open = 'OPEN',
  close = 'CLOSE'
}

// Custom FlashMode Enum
enum FlashMode {
  off = 'off',
  on = 'on',
  auto = 'auto'
}

export default function UrstammCameraPicker(props: {
  style?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  fontStyle?: StyleProp<TextStyle>;
  testID: string;
  textInputTitle?: string;
  openCamera: boolean;
  closeCamera: () => void;
  saveImage: (imageCaptured: any) => void;
  isLocalizationFinished?: boolean;
  uploadType: UploadType;
}) {
  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const type = ImagePicker.CameraType.back;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [imageCaptured, setImageCaptured] = useState<any>();
  const [flash, setFlash] = useState<FlashMode>(FlashMode.auto);
  const [ratio, setRatio] = useState<number>(0);

  const cameraEl = useRef(null);

  useEffect(() => {
    setRatio(Layout.window.width / Layout.window.height);
    requestPermission();
    setModalVisible(props.openCamera);
    return () => {};
  }, [props.openCamera]);

  async function takePicture() {
    if (cameraEl.current) {
      const options = { quality: 0.65, base64: true, savePhoto: false };
      const data = await (cameraEl.current as any).takePictureAsync(options);
      setImageCaptured(data);
    }
  }

  const savePhoto = () => {
    props.saveImage(imageCaptured);
    props.closeCamera();
  };

  const enableFlash = () => {
    setFlash(prev => (prev === FlashMode.on ? FlashMode.off : FlashMode.on));
  };

  const getFlashIconColor = (): string => {
    return flash === FlashMode.on ? ColorTheme.mainGreen : ColorTheme.mediumGrey;
  };

  const checkByCameraType = () => {
    if (
      (props.uploadType === UploadType.camera && imageCaptured && props.isLocalizationFinished) ||
      (props.uploadType === UploadType.all && imageCaptured)
    ) {
      return true;
    }
    return false;
  };

  const renderCapturedContent = () => {
    if (checkByCameraType()) {
      return (
        <View style={styles.camera}>
          <Image
            source={{ uri: imageCaptured.uri }}
            style={{
              width: Layout.window.width,
              height: Layout.window.height * 0.7
            }}
          />
          <TouchableOpacity style={[styles.bottom_button, UrstammStyle.baseShadow]} onPress={savePhoto}>
            <View style={[styles.iconTakePictureContainer]}>
              <UrstammIcon name={'save'} size={24} color={ColorTheme.mediumGrey} />
            </View>
          </TouchableOpacity>
        </View>
      );
    } else if (imageCaptured && !props.isLocalizationFinished && props.uploadType === UploadType.camera) {
      return (
        <View style={{ marginTop: 16 }}>
          <ActivityIndicator size="large" color={ColorTheme.mainGreen} />
          <Text style={[UrstammStyle.formBaseRMainGreen, { padding: 36 }]}>
            {i18n.t('utility_components.photo_picker.no_location_found')}
          </Text>
        </View>
      );
    } else {
      return (
        <View style={styles.camera}>
          <CameraView style={styles.camera} type={type} ref={cameraEl} flash={flash} />
          <TouchableOpacity style={[styles.bottom_button, UrstammStyle.baseShadow]} onPress={takePicture}>
            <View style={[styles.iconTakePictureContainer]}>
              <UrstammIcon name={'photo'} size={24} color={ColorTheme.mediumGrey} />
            </View>
          </TouchableOpacity>
        </View>
      );
    }
  };

  return (
    <View>
      <View style={UrstammStyleModal.centeredView}>
        <Modal animationType="slide" transparent={true} visible={modalVisible}>
          <View style={UrstammStyleModal.centeredView}>
            <View style={UrstammStyleModal.modalView}>
              <View style={[styles.cameraTopButton]}>
                {/** Flash camera */}
                {imageCaptured ? null : (
                  <TouchableOpacity
                    style={[styles.top_button, UrstammStyle.baseShadow, { marginRight: 16 }]}
                    onPress={enableFlash}>
                    <View style={[styles.iconCloseContainer]}>
                      <UrstammIcon name={'flash'} size={24} color={getFlashIconColor()} />
                    </View>
                  </TouchableOpacity>
                )}

                {/** Close camera */}
                <TouchableOpacity
                  style={[styles.top_button, UrstammStyle.baseShadow, { alignSelf: 'flex-end' }]}
                  onPress={() => {
                    setModalVisible(false);
                    props.closeCamera();
                  }}>
                  <View style={[styles.iconCloseContainer]}>
                    <UrstammIcon name={'close'} size={24} color={ColorTheme.mediumGrey} />
                  </View>
                </TouchableOpacity>
              </View>
              {renderCapturedContent()}
            </View>
          </View>
        </Modal>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  top_button: {
    flex: 1,
    backgroundColor: ColorTheme.lightGrey,
    borderColor: ColorTheme.mainGreen,
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
    borderWidth: 1,
    zIndex: 2,
    elevation: 2,
    maxWidth: 40,
    alignSelf: 'flex-end'
  },
  bottom_button: {
    backgroundColor: ColorTheme.lightGrey,
    borderColor: ColorTheme.mainGreen,
    borderRadius: 24,
    padding: 12,
    marginTop: 8,
    borderWidth: 1,
    minWidth: 80,
    zIndex: 2,
    elevation: 2
  },
  iconCloseContainer: {
    minWidth: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  },
  iconTakePictureContainer: {
    justifyContent: 'center',
    alignSelf: 'center'
  },
  camera: {
    flex: 1,
    width: Layout.window.width
  },
  cameraTopButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    marginRight: 10
  }
});
