import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { Packet } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface PacketState {
  list: Packet[];
  completeList: Packet[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: PacketState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const packet = createSlice({
  name: 'packetList',
  initialState,
  reducers: {
    setPacketList: (state, action) => {
      let packetList = { ...state }.list;
      let packetCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(packetList, element);
        pushElementOrReplaceById(packetCompleteList, element);
      });

      state.list = packetList;
      state.completeList = packetCompleteList;
    },
    setPacketTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setPacketListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setPacketPage: (state, action) => {
      state.page = action.payload;
    },
    resetPacketList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearPacketList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setPacketList,
  setPacketListSort,
  resetPacketList,
  setPacketTotalPages,
  setPacketPage,
  clearPacketList
} = packet.actions;

export default packet.reducer;
