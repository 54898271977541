/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { CompanyAppearance } from './CompanyAppearance';
import {
    CompanyAppearanceFromJSON,
    CompanyAppearanceFromJSONTyped,
    CompanyAppearanceToJSON,
} from './CompanyAppearance';
import type { CompanyProductType } from './CompanyProductType';
import {
    CompanyProductTypeFromJSON,
    CompanyProductTypeFromJSONTyped,
    CompanyProductTypeToJSON,
} from './CompanyProductType';
import type { CompanySurface } from './CompanySurface';
import {
    CompanySurfaceFromJSON,
    CompanySurfaceFromJSONTyped,
    CompanySurfaceToJSON,
} from './CompanySurface';
import type { ProcessorBatch } from './ProcessorBatch';
import {
    ProcessorBatchFromJSON,
    ProcessorBatchFromJSONTyped,
    ProcessorBatchToJSON,
} from './ProcessorBatch';
import type { ProcessorShippingNote } from './ProcessorShippingNote';
import {
    ProcessorShippingNoteFromJSON,
    ProcessorShippingNoteFromJSONTyped,
    ProcessorShippingNoteToJSON,
} from './ProcessorShippingNote';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { ProductCertification } from './ProductCertification';
import {
    ProductCertificationFromJSON,
    ProductCertificationFromJSONTyped,
    ProductCertificationToJSON,
} from './ProductCertification';
import type { ProductSpecies } from './ProductSpecies';
import {
    ProductSpeciesFromJSON,
    ProductSpeciesFromJSONTyped,
    ProductSpeciesToJSON,
} from './ProductSpecies';
import type { ProductState } from './ProductState';
import {
    ProductStateFromJSON,
    ProductStateFromJSONTyped,
    ProductStateToJSON,
} from './ProductState';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ProductDTO
 */
export interface ProductDTO {
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    uniqueIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductDTO
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    processorCustomName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    currentState?: ProductDTOCurrentStateEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    photoFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    photoFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    photoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    photoMd5?: string;
    /**
     * 
     * @type {User}
     * @memberof ProductDTO
     */
    photoAuthor?: User;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    uuid?: string;
    /**
     * 
     * @type {ProcessorBatch}
     * @memberof ProductDTO
     */
    processorBatch?: ProcessorBatch;
    /**
     * 
     * @type {Product}
     * @memberof ProductDTO
     */
    specificIncomingProduct?: Product;
    /**
     * 
     * @type {Company}
     * @memberof ProductDTO
     */
    processor?: Company;
    /**
     * 
     * @type {CompanySurface}
     * @memberof ProductDTO
     */
    surface?: CompanySurface;
    /**
     * 
     * @type {CompanyProductType}
     * @memberof ProductDTO
     */
    productType?: CompanyProductType;
    /**
     * 
     * @type {CompanyAppearance}
     * @memberof ProductDTO
     */
    appearance?: CompanyAppearance;
    /**
     * 
     * @type {Set<ProcessorShippingNote>}
     * @memberof ProductDTO
     */
    incomingShippingNotes?: Set<ProcessorShippingNote>;
    /**
     * 
     * @type {Set<ProcessorShippingNote>}
     * @memberof ProductDTO
     */
    outgoingShippingNotes?: Set<ProcessorShippingNote>;
    /**
     * 
     * @type {Set<Product>}
     * @memberof ProductDTO
     */
    specificOutgoingProducts?: Set<Product>;
    /**
     * 
     * @type {Set<ProductCertification>}
     * @memberof ProductDTO
     */
    productCertifications?: Set<ProductCertification>;
    /**
     * 
     * @type {Set<ProductState>}
     * @memberof ProductDTO
     */
    productStates?: Set<ProductState>;
    /**
     * 
     * @type {Set<ProductSpecies>}
     * @memberof ProductDTO
     */
    productSpecies?: Set<ProductSpecies>;
    /**
     * 
     * @type {Set<ProcessorBatch>}
     * @memberof ProductDTO
     */
    usingProcessorBatches?: Set<ProcessorBatch>;
    /**
     * 
     * @type {Date}
     * @memberof ProductDTO
     */
    photoCreationDate?: Date;
}


/**
 * @export
 */
export const ProductDTOCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED',
    SentInShippingNote: 'SENT_IN_SHIPPING_NOTE'
} as const;
export type ProductDTOCurrentStateEnum = typeof ProductDTOCurrentStateEnum[keyof typeof ProductDTOCurrentStateEnum];


/**
 * Check if a given object implements the ProductDTO interface.
 */
export function instanceOfProductDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductDTOFromJSON(json: any): ProductDTO {
    return ProductDTOFromJSONTyped(json, false);
}

export function ProductDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'uniqueIdentifier': !exists(json, 'uniqueIdentifier') ? undefined : json['uniqueIdentifier'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'processorCustomName': !exists(json, 'processorCustomName') ? undefined : json['processorCustomName'],
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'photoFilename': !exists(json, 'photoFilename') ? undefined : json['photoFilename'],
        'photoFilepath': !exists(json, 'photoFilepath') ? undefined : json['photoFilepath'],
        'photoUrl': !exists(json, 'photoUrl') ? undefined : json['photoUrl'],
        'photoMd5': !exists(json, 'photoMd5') ? undefined : json['photoMd5'],
        'photoAuthor': !exists(json, 'photoAuthor') ? undefined : UserFromJSON(json['photoAuthor']),
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'processorBatch': !exists(json, 'processorBatch') ? undefined : ProcessorBatchFromJSON(json['processorBatch']),
        'specificIncomingProduct': !exists(json, 'specificIncomingProduct') ? undefined : ProductFromJSON(json['specificIncomingProduct']),
        'processor': !exists(json, 'processor') ? undefined : CompanyFromJSON(json['processor']),
        'surface': !exists(json, 'surface') ? undefined : CompanySurfaceFromJSON(json['surface']),
        'productType': !exists(json, 'productType') ? undefined : CompanyProductTypeFromJSON(json['productType']),
        'appearance': !exists(json, 'appearance') ? undefined : CompanyAppearanceFromJSON(json['appearance']),
        'incomingShippingNotes': !exists(json, 'incomingShippingNotes') ? undefined : (new Set((json['incomingShippingNotes'] as Array<any>).map(ProcessorShippingNoteFromJSON))),
        'outgoingShippingNotes': !exists(json, 'outgoingShippingNotes') ? undefined : (new Set((json['outgoingShippingNotes'] as Array<any>).map(ProcessorShippingNoteFromJSON))),
        'specificOutgoingProducts': !exists(json, 'specificOutgoingProducts') ? undefined : (new Set((json['specificOutgoingProducts'] as Array<any>).map(ProductFromJSON))),
        'productCertifications': !exists(json, 'productCertifications') ? undefined : (new Set((json['productCertifications'] as Array<any>).map(ProductCertificationFromJSON))),
        'productStates': !exists(json, 'productStates') ? undefined : (new Set((json['productStates'] as Array<any>).map(ProductStateFromJSON))),
        'productSpecies': !exists(json, 'productSpecies') ? undefined : (new Set((json['productSpecies'] as Array<any>).map(ProductSpeciesFromJSON))),
        'usingProcessorBatches': !exists(json, 'usingProcessorBatches') ? undefined : (new Set((json['usingProcessorBatches'] as Array<any>).map(ProcessorBatchFromJSON))),
    };
}

export function ProductDTOToJSON(value?: ProductDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'uniqueIdentifier': value.uniqueIdentifier,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'sumCubage': value.sumCubage,
        'processorCustomName': value.processorCustomName,
        'currentState': value.currentState,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'photoFilename': value.photoFilename,
        'photoFilepath': value.photoFilepath,
        'photoUrl': value.photoUrl,
        'photoMd5': value.photoMd5,
        'photoAuthor': UserToJSON(value.photoAuthor),
        'uuid': value.uuid,
        'processorBatch': ProcessorBatchToJSON(value.processorBatch),
        'specificIncomingProduct': ProductToJSON(value.specificIncomingProduct),
        'processor': CompanyToJSON(value.processor),
        'surface': CompanySurfaceToJSON(value.surface),
        'productType': CompanyProductTypeToJSON(value.productType),
        'appearance': CompanyAppearanceToJSON(value.appearance),
        'incomingShippingNotes': value.incomingShippingNotes === undefined ? undefined : (Array.from(value.incomingShippingNotes as Set<any>).map(ProcessorShippingNoteToJSON)),
        'outgoingShippingNotes': value.outgoingShippingNotes === undefined ? undefined : (Array.from(value.outgoingShippingNotes as Set<any>).map(ProcessorShippingNoteToJSON)),
        'specificOutgoingProducts': value.specificOutgoingProducts === undefined ? undefined : (Array.from(value.specificOutgoingProducts as Set<any>).map(ProductToJSON)),
        'productCertifications': value.productCertifications === undefined ? undefined : (Array.from(value.productCertifications as Set<any>).map(ProductCertificationToJSON)),
        'productStates': value.productStates === undefined ? undefined : (Array.from(value.productStates as Set<any>).map(ProductStateToJSON)),
        'productSpecies': value.productSpecies === undefined ? undefined : (Array.from(value.productSpecies as Set<any>).map(ProductSpeciesToJSON)),
        'usingProcessorBatches': value.usingProcessorBatches === undefined ? undefined : (Array.from(value.usingProcessorBatches as Set<any>).map(ProcessorBatchToJSON)),
    };
}

