import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSortList from '@components/utility-components/modal/UrstammModalSortList';
import { i18n } from '@i18n/i18n';
import { Pile, PileWoodTypeEnum } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
  getColorByPileState,
  getIconByPileState,
  getTextByPileState,
  getTextByWoodType
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { UrstammStyle, UrstammStyleList } from '../../../utils/styles/UrstammStyle';

export default function PileListView(props: {
  navigation: any;
  pileList: Pile[];
  moreItems?: () => void;
  modalVisible: boolean;
  pileSelected?: (pile: Pile) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
}) {
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.pile.sortBy);
  const [pileSelected, setPileSelected] = useState<Pile>({});
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  let keys: ListSubData[] = [
    {
      title: i18n.t('generics.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '2creationDate',
      dataContainerViewStyle: { height: 24 }
    },
    {
      title: i18n.t('generics.estimate_volume') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'estimatedVolume',
      keyId: '1estimatedVolume',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.wood_type') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'woodType',
      keyId: '3typeOfPile',
      isEnum: true,
      dataContainerViewStyle: { height: 24 }
    },
    {
      title: i18n.t('generics.sum_cubage') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    }
  ];

  useEffect(() => {
    if (props.modalVisible) {
      setModalVisible(props.modalVisible);
    }
    return () => {};
  }, [props.modalVisible]);

  return (
    <View style={[UrstammStyle.flex1]}>
      <View style={UrstammStyle.flex1}>
        <UrstammList
          showHash={false}
          elementSelected={(data: any) => {
            setPileSelected(data.item), props.pileSelected ? props.pileSelected(data.item) : null;
          }}
          testID={'pile_list'}
          list={props.pileList}
          textForEmptyList={i18n.t('views.pile.piles_not_found')}
          listStyle={{
            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
            mainBottomContainerViewStyle: UrstammStyleList.pileCardSubContainer,
            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
            bottomContainerViewStyle: UrstammStyleList.cardSubData,
            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
          }}
          moreItems={props.moreItems}
          listSubData={keys}
          currentState={{
            icon: state => getIconByPileState(state),
            color: state => getColorByPileState(state),
            text: state => getTextByPileState(state)
          }}
          isFieldHiddenFunction={(data, key) => {
            if (key !== 'sumCubage') return false;
            return (data as Pile).woodType == PileWoodTypeEnum.OtherAssortment;
          }}
          getTextForData={data => getTextByWoodType(data)}
        />

        {props.showSortBy && (
          <UrstammModalSortList
            testID={'modal_sort'}
            showSortBy={props.showSortBy}
            closeShowSortBy={props.closeShowSortBy}
            applyOrder={(orderSelected: ButtonSelect[]) => props.applyOrder(orderSelected)}
            sortBySelected={rdxSortBy}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
