/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PasswordResetDTO,
  RegistrationDTO,
} from '../models';
import {
    PasswordResetDTOFromJSON,
    PasswordResetDTOToJSON,
    RegistrationDTOFromJSON,
    RegistrationDTOToJSON,
} from '../models';

export interface ActivateAccountCustomRequest {
    key: string;
}

export interface RegisterAccountCustomRequest {
    registrationDTO: RegistrationDTO;
}

export interface RequestPasswordResetRequest {
    passwordResetDTO: PasswordResetDTO;
}

/**
 * 
 */
export class CustomAccountResourceApi extends runtime.BaseAPI {

    /**
     * Activate User by key
     */
    async activateAccountCustomRaw(requestParameters: ActivateAccountCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling activateAccountCustom.');
        }

        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/activate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate User by key
     */
    async activateAccountCustom(requestParameters: ActivateAccountCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateAccountCustomRaw(requestParameters, initOverrides);
    }

    /**
     * Register a new User and relative UserExtended. Create the user Company too.
     * Register a new User
     */
    async registerAccountCustomRaw(requestParameters: RegisterAccountCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.registrationDTO === null || requestParameters.registrationDTO === undefined) {
            throw new runtime.RequiredError('registrationDTO','Required parameter requestParameters.registrationDTO was null or undefined when calling registerAccountCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationDTOToJSON(requestParameters.registrationDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Register a new User and relative UserExtended. Create the user Company too.
     * Register a new User
     */
    async registerAccountCustom(requestParameters: RegisterAccountCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.registerAccountCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send an email with pssword reset link
     */
    async requestPasswordResetRaw(requestParameters: RequestPasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.passwordResetDTO === null || requestParameters.passwordResetDTO === undefined) {
            throw new runtime.RequiredError('passwordResetDTO','Required parameter requestParameters.passwordResetDTO was null or undefined when calling requestPasswordReset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/account/reset-password/init`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetDTOToJSON(requestParameters.passwordResetDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send an email with pssword reset link
     */
    async requestPasswordReset(requestParameters: RequestPasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestPasswordResetRaw(requestParameters, initOverrides);
    }

}
