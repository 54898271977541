/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Tree } from './Tree';
import {
    TreeFromJSON,
    TreeFromJSONTyped,
    TreeToJSON,
} from './Tree';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface TreeState
 */
export interface TreeState {
    /**
     * 
     * @type {number}
     * @memberof TreeState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TreeState
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof TreeState
     */
    state: TreeStateStateEnum;
    /**
     * 
     * @type {Tree}
     * @memberof TreeState
     */
    tree?: Tree;
    /**
     * 
     * @type {User}
     * @memberof TreeState
     */
    author?: User;
}


/**
 * @export
 */
export const TreeStateStateEnum = {
    Standing: 'STANDING',
    Cut: 'CUT',
    MovedToSawmill: 'MOVED_TO_SAWMILL'
} as const;
export type TreeStateStateEnum = typeof TreeStateStateEnum[keyof typeof TreeStateStateEnum];


/**
 * Check if a given object implements the TreeState interface.
 */
export function instanceOfTreeState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function TreeStateFromJSON(json: any): TreeState {
    return TreeStateFromJSONTyped(json, false);
}

export function TreeStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TreeState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'state': json['state'],
        'tree': !exists(json, 'tree') ? undefined : TreeFromJSON(json['tree']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
    };
}

export function TreeStateToJSON(value?: TreeState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'state': value.state,
        'tree': TreeToJSON(value.tree),
        'author': UserToJSON(value.author),
    };
}

