import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { NumberHelper } from '@helpers/NumberHelper';
import { i18n } from '@i18n/i18n';
import {
  Company,
  ProcessorBatchDTO,
  ProcessorBatchDTOCurrentStateEnum,
  Product,
  ProductDTO,
  UserExtended
} from '@services/apis/generated';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByProcessorBatchState,
  getColorByProductState,
  getIconByProcessorBatchState,
  getTextByProcessorBatchState,
  getTextByProcessorBatchType,
  getTextByProductState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessorOrProcessorShippingNoteId } from '../../../utils/classes/UrstammUtilityFilter';
import {
  urstammUtilDisplayError,
  urstammUtilUpdateForm,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  allProductAreClosedOrSentInShippingNote,
  getCardTitleByProcessorBatchOrProduct,
  getCounterBySelected,
  getMandatorySymbol,
  isCompanyMock,
  processorBatchIsClosed
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleButton,
  UrstammStyleCollapsible,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';
import { ProductImageForm } from '../product/ProductDetailsView';
import ProductListView from '../product/ProductListView';
import {
  CertificationProps,
  CompanyAppearanceProps,
  CompanyProductTypeProps,
  CompanySurfaceProps,
  ProductForm,
  SpeciesProps
} from '../product/ProductRegistrationView';
import { ProcessorBatchForm } from './ProcessorBatchRegistrationView';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';

export default function ProcessorBatchDetailsView(props: {
  navigation: any;
  processorBatchSelectedDTO: ProcessorBatchDTO;
  customProcessorShippingNoteList: any; // (selected: boolean)
  moreItems: () => void;
  userExtendedMe?: UserExtended;
  processorShippingNoteSelected: (data: any) => void;
  updateProcessorBatch: (processorBatchDTO: ProcessorBatchDTO) => void;
  resetProcessorShippingNoteSelected?: () => void;
  confirmProcessorShippingNoteSelected?: () => void;
  productList: Product[];
  productSelected: (productSelected: Product) => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  validate: (product: Product) => void;
  processorList: Company[];
  certificationProps?: CertificationProps;
  speciesProps?: SpeciesProps;
  companyProductTypeProps?: CompanyProductTypeProps;
  companySurfaceProps?: CompanySurfaceProps;
  companyAppearanceProps?: CompanyAppearanceProps;
  submitProductForm: (data: ProductForm, productPhoto?: ProductImageForm) => void;
  closeModalClosingProduct: boolean;
  closingProcessorBatch: (processorBatchDTO: ProcessorBatchDTO) => void;
  cloneProduct: (product: Product | ProductDTO) => void;
}) {
  const rdxProductDetails = useSelector((state: RootState) => state.persistedReducer.processorBatch);
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [processorBatchSelected, setProcessorBatchSelected] = useState<ProcessorBatchDTO>();
  const [processorShippingNoteList, setProcessorShippingNoteList] = useStateWithCallbackLazy<any>(() => {});
  const [showProcessorShippingNoteSelect, setShowProcessorShippingNoteSelect] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [openCloseProcessorBatchModal, setOpenCloseProcessorBatchModal] = useState<boolean>(false);

  const [processorBatchForm, setProcessorBatchForm] = useStateWithCallbackLazy<ProcessorBatchForm>({
    processorBatch: {
      uniqueIdentifier: '',
      name: '',
      creationDate: new Date(),
      recordingType: undefined,
      comment: ''
    }
  });

  let dsKeys: ListSubData[] = [
    // { title: undefined, titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'currentState', keyId: '1currentState', dataContainerViewStyle: { height: 24, flexBasis: '50%' }, isEnum: true },
    {
      title: undefined,
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'currentState',
      keyId: '1currentState',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('views.processor_batch.sum_volume_products') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.processor_shipping_note_id') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: '_acceptedProcessorShippingNoteId',
      keyId: '1processorShippingNote',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' },
      isEnum: true
    },
    {
      title: i18n.t('generics.sender') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: '_acceptedProcessorShippingNoteCreator',
      keyId: '1creator',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' },
      isEnum: true
    }
  ];

  useEffect(() => {
    if (props.processorBatchSelectedDTO) {
      setProcessorBatchSelected(props.processorBatchSelectedDTO);
      setProcessorBatchForm(
        {
          processorBatch: {
            name: props.processorBatchSelectedDTO.name,
            creationDate: props.processorBatchSelectedDTO.creationDate,
            comment: props.processorBatchSelectedDTO.comment
          }
        },
        updatedProcessorBatchForm => {
          saveButtonDisabled(errors, updatedProcessorBatchForm);
        }
      );
    }
  }, [props.processorBatchSelectedDTO]);

  useEffect(() => {
    if (props.customProcessorShippingNoteList && props.customProcessorShippingNoteList.length > 0)
      setProcessorShippingNoteList(props.customProcessorShippingNoteList, updatedProcessorShippingNoteList => {
        saveButtonDisabled(errors, updatedProcessorShippingNoteList);
      });
  }, [props.customProcessorShippingNoteList]);

  /**
   *
   * @param key
   * @param value
   */
  const updateProcessorBatchForm = (key: string, value: any, mustValidate?: boolean): void => {
    let form = urstammUtilUpdateForm(key, value, processorBatchForm, 'processorBatch');
    setProcessorBatchForm(
      { processorBatch: form['processorBatch'] },
      mustValidate ? updatedProcessorBatchForm => validate(value, key, updatedProcessorBatchForm) : () => {}
    );
  };

  //Validators
  const validators = useState<any>({
    name: { required: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: []
  });

  const validate = (value: string, key: string, updatedTreeForm: ProcessorBatchForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedProcessorShippingNoteList: any) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated)) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const processorBatchHasAtLeastOneSourceProduct = (processorBatchDTO: ProcessorBatchDTO): boolean => {
    let processorShippingNoteList = processorBatchDTO?.sourceProducts ? [...processorBatchDTO?.sourceProducts] : [];
    if (processorShippingNoteList.length == 0) return true;

    return false;
  };

  const getProcessorNameByProcessorShippingNote = (data: any) => {
    let value: string | null = null;
    value = '--';
    if (data?.creator && !isCompanyMock(data?.creator)) {
      value = data.creator.name;
    } else if (data?.mockProcessor) {
      value = data.mockProcessor.name;
    } else if (data?.creator && isCompanyMock(data?.creator)) {
      value = i18n.t('views.shipping_note.no_urstamm_processor');
    }
    return value;
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/**Status and creation date */}
        <View style={UrstammStyleLayout.formElementContainer}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {processorBatchSelected?.currentState ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                {processorBatchSelected?.currentState &&
                  getIconByProcessorBatchState(processorBatchSelected?.currentState as any)}
                <Text
                  style={[
                    UrstammStyleContainer.statusCreationDateTextWithIcon,
                    getColorByProcessorBatchState(processorBatchSelected.currentState)
                  ]}>
                  {getTextByProcessorBatchState(processorBatchSelected.currentState!)}
                </Text>
              </View>
            ) : null}
            {processorBatchSelected?.creationDate ? (
              <View style={UrstammStyle.flexRow}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.creationDate') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {moment(processorBatchSelected.creationDate).format('DD/MM/yyyy')}
                </Text>
              </View>
            ) : null}
          </View>
          <View style={UrstammStyleLayout.formElementContainerPd8}>
            <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
              {processorBatchSelected?.products ? (
                <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.amount_of_product') + ': '}</Text>
                  <Text style={[UrstammStyle.dataStyle]}>{props.productList.length}</Text>
                </View>
              ) : null}
              {processorBatchSelected?.recordingType ? (
                <View style={UrstammStyle.flexRow}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.type_of_record') + ': '}</Text>
                  <Text style={UrstammStyle.dataStyle}>
                    {getTextByProcessorBatchType(processorBatchSelected.recordingType)}
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
          <View style={UrstammStyleLayout.formElementContainerPd8}>
            <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
              {processorBatchSelected?.sumCubage ? (
                <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                  <Text style={UrstammStyle.titleStyle}>
                    {i18n.t('views.processor_batch.sum_volume_products') + ': '}
                  </Text>
                  <Text style={UrstammStyle.dataStyle}>
                    {NumberHelper.roundWithThreeDecimals(processorBatchSelected?.sumCubage)}
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
        </View>

        <View style={UrstammStyle.divider} />

        {props.processorBatchSelectedDTO &&
        allProductAreClosedOrSentInShippingNote(props.processorBatchSelectedDTO) &&
        props.processorBatchSelectedDTO.currentState != ProcessorBatchDTOCurrentStateEnum.Closed ? (
          <View style={[{ marginTop: 8 }]}>
            <UrstammButtonBase
              testID={'close_processorBatch'}
              text={i18n.t('views.processor_batch.close_processor_batch')}
              style={mainGreenButton}
              onSubmit={() => setOpenCloseProcessorBatchModal(true)}
              disabled={processorBatchHasAtLeastOneSourceProduct(props.processorBatchSelectedDTO)}
            />
          </View>
        ) : null}

        {/**Edit ProcessorShippingNote Collapsible */}
        <View style={{ marginTop: 8 }}>
          {props.processorBatchSelectedDTO &&
          props.processorBatchSelectedDTO.currentState != ProcessorBatchDTOCurrentStateEnum.Closed ? (
            <View
              style={[
                UrstammStyleButton.smallBaseButtonStructure,
                { backgroundColor: ColorTheme.yellow },
                UrstammStyle.baseShadow
              ]}>
              <View style={UrstammStyleCollapsible.selectMainContainer}>
                <TouchableOpacity
                  style={UrstammStyleCollapsible.selectCollapsible}
                  onPress={() => setCollapsed(!collapsed)}>
                  <Text style={UrstammStyleButton.baseButtonBWStyle}>{i18n.t('generics.edit')}</Text>

                  <UrstammIcon name={collapsed ? 'angle-down' : 'angle-up'} size={24} color={'white'} />
                </TouchableOpacity>
              </View>
            </View>
          ) : null}

          <View style={[{ marginTop: 4 }]}>
            <Collapsible
              duration={500}
              easing={'easeInBounce'}
              collapsed={collapsed}
              style={UrstammStyleCollapsible.containerCollapsible}>
              <ScrollView
                keyboardShouldPersistTaps="always"
                contentContainerStyle={[
                  {
                    flexGrow: 1,
                    flexDirection: 'column',
                    paddingBottom: 40
                  }
                ]}>
                {/** ProcessorBatch name */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
                  <UrstammInput
                    testID="processorBatch_name"
                    style={{
                      containerViewStyle: UrstammStyleContainer.inputContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    icon={{ showIcon: false }}
                    value={processorBatchForm.processorBatch.name ? processorBatchForm.processorBatch.name : ''}
                    onChangeText={(text: string) => {
                      updateProcessorBatchForm('name', text, true);
                    }}
                    placeholder={i18n.t(
                      'views.processor_batch.processor_batch_registration.insert_processor_batch_name_here'
                    )}
                    textInputTitle={i18n.t('views.processor_batch.processor_batch_registration.processor_batch_name')}
                    mandatory={true}
                    editable={!processorBatchIsClosed(props.processorBatchSelectedDTO)}
                  />

                  {/**Validator */}
                  {errors['name'] && errors['name'].length > 0
                    ? errors['name'].map((error, idx) => {
                        return urstammUtilDisplayError(error, idx);
                      })
                    : null}
                </View>

                {/** ProcessorBatch creation date (preset: today) */}
                <View style={UrstammStyleLayout.formElementContainerGrw1}>
                  <UrstammDatePicker
                    testID={'processorBatch_date'}
                    containerStyle={UrstammStyleContainer.inputContainer}
                    fontStyle={UrstammStyle.formBaseLDarkGrey}
                    textInputTitle={i18n.t('generics.date')}
                    onChange={(dateTime: string) => updateProcessorBatchForm('creationDate', dateTime)}
                    icon={{
                      showIcon: true,
                      iconName: 'calendar',
                      iconColor: ColorTheme.lightGreen
                    }}
                    defaultValue={processorBatchSelected?.creationDate}
                    placeholder={i18n.t(
                      'views.processor_batch.processor_batch_registration.insert_processor_batch_date_here'
                    )}
                    mandatory={true}
                    mode={'date'}
                    disabled={processorBatchIsClosed(props.processorBatchSelectedDTO)}
                  />
                </View>

                {/** Delivery Sheet list */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  {/**Title */}
                  <View style={UrstammStyle.textTitleContainerFlex}>
                    <Text style={UrstammStyle.textTitle}>
                      {i18n.t('views.processor_batch.processor_batch_registration.choose_products') +
                        getMandatorySymbol(true)}
                    </Text>
                  </View>

                  <UrstammButtonBase
                    testID={'choose_products'}
                    text={i18n.t('views.processor_batch.processor_batch_registration.choose_products')}
                    style={lightGreenBorderWhiteBGButton}
                    onSubmit={() => setShowProcessorShippingNoteSelect(true)}
                    counter={getCounterBySelected(props.customProcessorShippingNoteList)}
                    disabled={processorBatchIsClosed(props.processorBatchSelectedDTO)}
                  />

                  {/**Delivery Sheet multiple select */}
                  {showProcessorShippingNoteSelect ? (
                    <UrstammModalSelect
                      testID={'modal_processorBatch_multiple_select'}
                      visible={showProcessorShippingNoteSelect}
                      text={{
                        textInputTitle: i18n.t('views.processor_batch.processor_batch_registration.choose_products'),
                        textStyle: UrstammStyle.textTitle
                        //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.processor_shipping_note_selected')+ ': ' + getCounterByProcessorShippingNoteSelected()
                      }}
                      closeModal={() => {
                        setShowProcessorShippingNoteSelect(false),
                          props.resetProcessorShippingNoteSelected ? props.resetProcessorShippingNoteSelected() : null;
                      }}
                      confirmButton={{
                        enabled: true,
                        text: i18n.t('generics.save'),
                        disabled: props.customProcessorShippingNoteList?.length == 0 ? true : false,
                        submitValue: () => {
                          setShowProcessorShippingNoteSelect(false);
                          props.confirmProcessorShippingNoteSelected
                            ? props.confirmProcessorShippingNoteSelected()
                            : null;
                        }
                      }}
                      listJSX={
                        <UrstammList
                          elementSelected={(processorShippingNoteSelected: any) => {
                            props.processorShippingNoteSelected(processorShippingNoteSelected),
                              saveButtonDisabled(errors, processorBatchForm);
                          }}
                          showHash={false}
                          testID={'product_list'}
                          textForEmptyList={i18n.t('views.processor_batch.products_not_found')}
                          titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
                          list={props.customProcessorShippingNoteList}
                          listStyle={{
                            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                            bottomContainerViewStyle: UrstammStyleList.cardSubData,
                            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                            paddingBottom: 4
                          }}
                          listSubData={dsKeys}
                          moreItems={props.moreItems}
                          filterList={true}
                          multipleSelection={true}
                          filterListFunction={(text, data) =>
                            filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessorOrProcessorShippingNoteId(
                              text,
                              data,
                              props.userExtendedMe!
                            )
                          }
                          currentState={{
                            //icon: (state) => getIconByLoggingState(state),
                            color: state => getColorByProductState(state),
                            text: state => getTextByProductState(state)
                          }}
                          getTextForFullData={(data, key) => {
                            if (key === '_acceptedProcessorShippingNoteId' && data)
                              return data._acceptedProcessorShippingNote?.id;
                            else if (key === '_acceptedProcessorShippingNoteCreator' && data)
                              return getProcessorNameByProcessorShippingNote(data._acceptedProcessorShippingNote);
                            return null;
                          }}
                        />
                      }
                    />
                  ) : null}
                </View>

                {/** Comment */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  <UrstammInput
                    testID="processorBatch_comment"
                    style={{
                      containerViewStyle: UrstammStyleContainer.textareaContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    icon={{ showIcon: false }}
                    value={processorBatchForm.processorBatch.comment ? processorBatchForm.processorBatch.comment : ''}
                    onChangeText={(text: string) => updateProcessorBatchForm('comment', text, false)}
                    placeholder={i18n.t('generics.insert_comment_here')}
                    textInputTitle={i18n.t('generics.comment')}
                    mandatory={false}
                    multiline={true}
                    editable={!processorBatchIsClosed(props.processorBatchSelectedDTO)}
                  />
                </View>

                {!processorBatchIsClosed(props.processorBatchSelectedDTO) ? (
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammButtonBase
                      testID={'save_processor_shipping_note'}
                      text={i18n.t('generics.save')}
                      style={mainGreenButton}
                      onSubmit={() => props.updateProcessorBatch(processorBatchForm.processorBatch)}
                      disabled={saveDisabled}
                    />
                  </View>
                ) : null}
              </ScrollView>
            </Collapsible>
          </View>

          {processorBatchSelected ? (
            <View style={UrstammStyle.flex1}>
              <ProductListView
                navigation={props.navigation}
                processorBatchSelected={props.processorBatchSelectedDTO}
                productList={props.productList}
                productSelected={(productSelected: any) => props.productSelected(productSelected.item)}
                showSortBy={props.showSortBy}
                closeShowSortBy={props.closeShowSortBy}
                applyOrder={props.applyOrder}
                validate={(data: Product) => props.validate(data)}
                processorList={props.processorList}
                certificationProps={props.certificationProps}
                speciesProps={props.speciesProps}
                companySurfaceProps={props.companySurfaceProps}
                submitProductForm={props.submitProductForm}
                companyProductTypeProps={props.companyProductTypeProps}
                companyAppearanceProps={props.companyAppearanceProps}
                closeModalClosingProduct={props.closeModalClosingProduct}
                cloneProduct={props.cloneProduct}
              />
            </View>
          ) : null}

          {openCloseProcessorBatchModal && (
            <UrstammModal
              testID={'close_processorBatch_modal'}
              text={i18n.t('views.processor_batch.are_you_sure_want_close_processor_batch')}
              textStyle={UrstammStyleModal.formBtextModalStyle}
              visible={openCloseProcessorBatchModal}
              buttonText={{
                left: i18n.t('utility_components.modal.cancel'),
                right: i18n.t('utility_components.modal.confirm')
              }}
              confirm={(confirmed: boolean) => {
                confirmed ? props.closingProcessorBatch(props.processorBatchSelectedDTO) : null,
                  setOpenCloseProcessorBatchModal(false);
              }}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
}
