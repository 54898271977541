export const readFileByUriAsBase64 = async (uri: string): Promise<string | undefined> => {
  return Promise.resolve(undefined);
};

export const deleteFileByUri = async (uri: string): Promise<void> => {
  return Promise.resolve();
};

export const writeBase64ToFile = async (filename: string, base64: string): Promise<void> => {
  return Promise.resolve();
};

export const storeTreePhoto = async (id: any, base64: string): Promise<void> => {
  return writeBase64ToFile('', base64);
};

export const readTreePhoto = async (id: any): Promise<string | undefined> => {
  return readFileByUriAsBase64('');
};

export const deleteTreePhoto = async (id: any): Promise<void> => {
  return deleteFileByUri('');
};

export const storePilePhoto = async (id: any, base64: string): Promise<void> => {
  return writeBase64ToFile('', base64);
};

export const readPilePhoto = async (id: any): Promise<string | undefined> => {
  return readFileByUriAsBase64('');
};

export const deletePilePhoto = async (id: any): Promise<void> => {
  return deleteFileByUri('');
};
