/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProcessorShippingNoteStateEnumFilter
 */
export interface ProcessorShippingNoteStateEnumFilter {
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteStateEnumFilter
     */
    equals?: ProcessorShippingNoteStateEnumFilterEqualsEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteStateEnumFilter
     */
    notEquals?: ProcessorShippingNoteStateEnumFilterNotEqualsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessorShippingNoteStateEnumFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorShippingNoteStateEnumFilter
     */
    _in?: Array<ProcessorShippingNoteStateEnumFilterInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorShippingNoteStateEnumFilter
     */
    notIn?: Array<ProcessorShippingNoteStateEnumFilterNotInEnum>;
}


/**
 * @export
 */
export const ProcessorShippingNoteStateEnumFilterEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type ProcessorShippingNoteStateEnumFilterEqualsEnum = typeof ProcessorShippingNoteStateEnumFilterEqualsEnum[keyof typeof ProcessorShippingNoteStateEnumFilterEqualsEnum];

/**
 * @export
 */
export const ProcessorShippingNoteStateEnumFilterNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type ProcessorShippingNoteStateEnumFilterNotEqualsEnum = typeof ProcessorShippingNoteStateEnumFilterNotEqualsEnum[keyof typeof ProcessorShippingNoteStateEnumFilterNotEqualsEnum];

/**
 * @export
 */
export const ProcessorShippingNoteStateEnumFilterInEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type ProcessorShippingNoteStateEnumFilterInEnum = typeof ProcessorShippingNoteStateEnumFilterInEnum[keyof typeof ProcessorShippingNoteStateEnumFilterInEnum];

/**
 * @export
 */
export const ProcessorShippingNoteStateEnumFilterNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type ProcessorShippingNoteStateEnumFilterNotInEnum = typeof ProcessorShippingNoteStateEnumFilterNotInEnum[keyof typeof ProcessorShippingNoteStateEnumFilterNotInEnum];


/**
 * Check if a given object implements the ProcessorShippingNoteStateEnumFilter interface.
 */
export function instanceOfProcessorShippingNoteStateEnumFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProcessorShippingNoteStateEnumFilterFromJSON(json: any): ProcessorShippingNoteStateEnumFilter {
    return ProcessorShippingNoteStateEnumFilterFromJSONTyped(json, false);
}

export function ProcessorShippingNoteStateEnumFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessorShippingNoteStateEnumFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
        'notEquals': !exists(json, 'notEquals') ? undefined : json['notEquals'],
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
    };
}

export function ProcessorShippingNoteStateEnumFilterToJSON(value?: ProcessorShippingNoteStateEnumFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals,
        'notEquals': value.notEquals,
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
    };
}

