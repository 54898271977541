import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { ProcessorShippingNoteDTO } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface OutgoingProcessorShippingNoteState {
  list: ProcessorShippingNoteDTO[];
  completeList: ProcessorShippingNoteDTO[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: OutgoingProcessorShippingNoteState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const outgoingProcessorShippingNote = createSlice({
  name: 'outgoingProcessorShippingNoteList',
  initialState,
  reducers: {
    setOutgoingProcessorShippingNoteList: (state, action) => {
      let outgoingProcessorShippingNoteList = { ...state }.list;
      let outgoingProcessorShippingNoteCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(outgoingProcessorShippingNoteList, element);
        pushElementOrReplaceById(outgoingProcessorShippingNoteCompleteList, element);
      });

      state.list = outgoingProcessorShippingNoteList;
      state.completeList = outgoingProcessorShippingNoteCompleteList;
    },
    setOutgoingProcessorShippingNoteTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setOutgoingProcessorShippingNoteListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setOutgoingProcessorShippingNotePage: (state, action) => {
      state.page = action.payload;
    },
    resetOutgoingProcessorShippingNoteList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearOutgoingProcessorShippingNoteList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setOutgoingProcessorShippingNoteList,
  setOutgoingProcessorShippingNoteListSort,
  resetOutgoingProcessorShippingNoteList,
  setOutgoingProcessorShippingNoteTotalPages,
  setOutgoingProcessorShippingNotePage,
  clearOutgoingProcessorShippingNoteList
} = outgoingProcessorShippingNote.actions;

export default outgoingProcessorShippingNote.reducer;
