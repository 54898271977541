import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { LoginVM } from '@services/apis/generated';
import React, { useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { UrstammNavigationHelper } from '../../../navigation/UrstammNavigationHelper';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammFontFamily, UrstammFontsize } from '../../../utils/styles/UrstammFont';
import { UrstammStyle, UrstammStyleContainer } from '../../../utils/styles/UrstammStyle';
import UrstammButtonBase from '../../utility-components/button/UrstammButtonBase';
import UrstammInput from '../../utility-components/input/UrstammInput';
import UrstammTitle from '../../utility-components/title/UrstammTitle';

export default function LoginView(props: { submit: (data: LoginVM) => void; navigation: any }) {
  const [authenticateData, setAuthenticateData] = useState<LoginVM>({
    username: '',
    password: ''
  });

  const navigateToSignup = (): void => {
    UrstammNavigationHelper.navigateToSignup(props.navigation);
  };

  const submitForm = (): void => {
    props.submit(authenticateData);
  };

  const navigateToResetPassword = (): void => {
    UrstammNavigationHelper.navigateToResetPassword(props.navigation);
  };

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn]}>
      <ScrollView keyboardShouldPersistTaps="always" contentContainerStyle={[UrstammStyle.flexGrow1]}>
        {/** Title */}
        <View style={styles.titleTextContainer}>
          <UrstammTitle
            testID={'login_title'}
            text={i18n.t('views.login.login')}
            fontStyle={UrstammStyle.sectionTitleXBDarkGrey}
          />
        </View>
        {/** Username */}
        <View style={styles.inputContainer}>
          <UrstammInput
            testID="login_username"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{
              showIcon: true,
              iconName: 'user',
              iconColor: ColorTheme.lightGreen
            }}
            onChangeText={(text: string) =>
              setAuthenticateData((state: any) => ({
                ...state,
                username: text
              }))
            }
            placeholder={i18n.t('views.login.insert_your_email_here')}
          />
        </View>
        {/** Password */}
        <View style={styles.inputContainer}>
          <UrstammInput
            testID="login_password"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{
              showIcon: true,
              iconName: 'unlock-alt',
              iconColor: ColorTheme.lightGreen
            }}
            secureTextEntry={true}
            onChangeText={(text: string) =>
              setAuthenticateData((state: any) => ({
                ...state,
                password: text
              }))
            }
            placeholder={i18n.t('views.login.insert_your_password_here')}
          />
        </View>
      </ScrollView>

      {/** Footer */}
      <View style={[UrstammStyle.flex4, { justifyContent: 'space-between' }]}>
        {/** Log in button */}
        <View style={styles.buttonLogin}>
          <UrstammButtonBase
            testID={'login_button'}
            text={i18n.t('views.login.login_button')}
            style={mainGreenButton}
            onSubmit={submitForm}
          />
        </View>

        {/** Forgot password button */}
        <View style={[styles.forgotPasswordContainer]}>
          <TouchableOpacity onPress={navigateToResetPassword}>
            <Text style={styles.forgotPasswordText}>{i18n.t('views.login.forgot_password')}</Text>
          </TouchableOpacity>
        </View>

        {/* <View style={[]}>
                    <UrstammButtonBase
                        testID={'signup_button_login'}
                        text={i18n.t('views.signup.signup')}
                        style={mediumGreyButton}
                        onSubmit={navigateToSignup}
                    />
                </View> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  titleTextContainer: {
    //flex: 1,
    marginBottom: 16
  },
  inputContainer: {
    //flex: 1,
    paddingVertical: 8
  },
  forgotPasswordContainer: {
    //flex: 0.2,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  buttonLogin: {
    marginTop: 8
  },

  forgotPasswordText: {
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
    color: ColorTheme.mediumGrey,
    textDecorationLine: 'underline'
  }
});
