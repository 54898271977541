/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BooleanFilter
 */
export interface BooleanFilter {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanFilter
     */
    equals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanFilter
     */
    notEquals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof BooleanFilter
     */
    _in?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof BooleanFilter
     */
    notIn?: Array<boolean>;
}

/**
 * Check if a given object implements the BooleanFilter interface.
 */
export function instanceOfBooleanFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BooleanFilterFromJSON(json: any): BooleanFilter {
    return BooleanFilterFromJSONTyped(json, false);
}

export function BooleanFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): BooleanFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
        'notEquals': !exists(json, 'notEquals') ? undefined : json['notEquals'],
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
    };
}

export function BooleanFilterToJSON(value?: BooleanFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals,
        'notEquals': value.notEquals,
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
    };
}

