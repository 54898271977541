import { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { createSlice } from '@reduxjs/toolkit';

export interface TrunkFilterState {
  value: SlideButtonOption[];
}

const initialState: TrunkFilterState = {
  value: []
};

export const trunkFilter = createSlice({
  name: 'trunkFilter',
  initialState,
  reducers: {
    setTrunkFilter: (state, action) => {
      state.value = action.payload;
    },
    setTrunkFilterList: (state, action) => {
      state.value = action.payload;
    },
    selectTrunkFilter: (state, action) => {
      let selected = action.payload;
      let filterList = { ...state }.value;
      if (filterList) {
        for (let elem of filterList) {
          elem.active = elem.id == selected.id;
        }
        state.value = filterList;
      }
    },
    reset: state => {
      initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setTrunkFilter, setTrunkFilterList, selectTrunkFilter, reset } = trunkFilter.actions;

export default trunkFilter.reducer;
