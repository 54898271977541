/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliverySheet } from './DeliverySheet';
import {
    DeliverySheetFromJSON,
    DeliverySheetFromJSONTyped,
    DeliverySheetToJSON,
} from './DeliverySheet';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface DeliverySheetErpImportation
 */
export interface DeliverySheetErpImportation {
    /**
     * 
     * @type {number}
     * @memberof DeliverySheetErpImportation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetErpImportation
     */
    dataSource?: DeliverySheetErpImportationDataSourceEnum;
    /**
     * 
     * @type {Date}
     * @memberof DeliverySheetErpImportation
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetErpImportation
     */
    dataFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetErpImportation
     */
    dataFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetErpImportation
     */
    dataUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetErpImportation
     */
    dataMd5?: string;
    /**
     * 
     * @type {DeliverySheet}
     * @memberof DeliverySheetErpImportation
     */
    deliverySheet?: DeliverySheet;
    /**
     * 
     * @type {User}
     * @memberof DeliverySheetErpImportation
     */
    dataAuthor?: User;
}


/**
 * @export
 */
export const DeliverySheetErpImportationDataSourceEnum = {
    Manual: 'MANUAL',
    ErpLehmann: 'ERP_LEHMANN'
} as const;
export type DeliverySheetErpImportationDataSourceEnum = typeof DeliverySheetErpImportationDataSourceEnum[keyof typeof DeliverySheetErpImportationDataSourceEnum];


/**
 * Check if a given object implements the DeliverySheetErpImportation interface.
 */
export function instanceOfDeliverySheetErpImportation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeliverySheetErpImportationFromJSON(json: any): DeliverySheetErpImportation {
    return DeliverySheetErpImportationFromJSONTyped(json, false);
}

export function DeliverySheetErpImportationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliverySheetErpImportation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dataSource': !exists(json, 'dataSource') ? undefined : json['dataSource'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'dataFilename': !exists(json, 'dataFilename') ? undefined : json['dataFilename'],
        'dataFilepath': !exists(json, 'dataFilepath') ? undefined : json['dataFilepath'],
        'dataUrl': !exists(json, 'dataUrl') ? undefined : json['dataUrl'],
        'dataMd5': !exists(json, 'dataMd5') ? undefined : json['dataMd5'],
        'deliverySheet': !exists(json, 'deliverySheet') ? undefined : DeliverySheetFromJSON(json['deliverySheet']),
        'dataAuthor': !exists(json, 'dataAuthor') ? undefined : UserFromJSON(json['dataAuthor']),
    };
}

export function DeliverySheetErpImportationToJSON(value?: DeliverySheetErpImportation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'dataSource': value.dataSource,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'dataFilename': value.dataFilename,
        'dataFilepath': value.dataFilepath,
        'dataUrl': value.dataUrl,
        'dataMd5': value.dataMd5,
        'deliverySheet': DeliverySheetToJSON(value.deliverySheet),
        'dataAuthor': UserToJSON(value.dataAuthor),
    };
}

