/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Batch,
  BatchDTO,
  CustomBatchCriteria,
  PageBatch,
  Trunk,
} from '../models';
import {
    BatchFromJSON,
    BatchToJSON,
    BatchDTOFromJSON,
    BatchDTOToJSON,
    CustomBatchCriteriaFromJSON,
    CustomBatchCriteriaToJSON,
    PageBatchFromJSON,
    PageBatchToJSON,
    TrunkFromJSON,
    TrunkToJSON,
} from '../models';

export interface CreateBatchCustomRequest {
    batch: Batch;
}

export interface GetBatchCustomRequest {
    id: number;
}

export interface GetDSTrunksByBatchRequest {
    id: number;
}

export interface GetPageBatchesForMyCompanyRequest {
    customBatchCriteria: CustomBatchCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface UpdateBatchCustomRequest {
    id: number;
    batch: Batch;
}

/**
 * 
 */
export class CustomBatchResourceApi extends runtime.BaseAPI {

    /**
     * Create a new Batch connecting to Company of logged user.
     * Create a Batch
     */
    async createBatchCustomRaw(requestParameters: CreateBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Batch>> {
        if (requestParameters.batch === null || requestParameters.batch === undefined) {
            throw new runtime.RequiredError('batch','Required parameter requestParameters.batch was null or undefined when calling createBatchCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/batches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BatchToJSON(requestParameters.batch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchFromJSON(jsonValue));
    }

    /**
     * Create a new Batch connecting to Company of logged user.
     * Create a Batch
     */
    async createBatchCustom(requestParameters: CreateBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Batch> {
        const response = await this.createBatchCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Batch identified by given id (must belong to Company of logged user).
     * Get a specific Batch
     */
    async getBatchCustomRaw(requestParameters: GetBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatchDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBatchCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchDTOFromJSON(jsonValue));
    }

    /**
     * Get Batch identified by given id (must belong to Company of logged user).
     * Get a specific Batch
     */
    async getBatchCustom(requestParameters: GetBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatchDTO> {
        const response = await this.getBatchCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all trunks for DeliverySheets of a Batch.
     * Get all trunks for DeliverySheets of a Batch
     */
    async getDSTrunksByBatchRaw(requestParameters: GetDSTrunksByBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Set<Trunk>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDSTrunksByBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/batches/{id}/trunks`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => new Set(jsonValue.map(TrunkFromJSON)));
    }

    /**
     * Get all trunks for DeliverySheets of a Batch.
     * Get all trunks for DeliverySheets of a Batch
     */
    async getDSTrunksByBatch(requestParameters: GetDSTrunksByBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Set<Trunk>> {
        const response = await this.getDSTrunksByBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a page of Batch belonging to Company of logged User.
     * Get a page of batches for my Company
     */
    async getPageBatchesForMyCompanyRaw(requestParameters: GetPageBatchesForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageBatch>> {
        if (requestParameters.customBatchCriteria === null || requestParameters.customBatchCriteria === undefined) {
            throw new runtime.RequiredError('customBatchCriteria','Required parameter requestParameters.customBatchCriteria was null or undefined when calling getPageBatchesForMyCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/batches/company/my`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomBatchCriteriaToJSON(requestParameters.customBatchCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBatchFromJSON(jsonValue));
    }

    /**
     * Get a page of Batch belonging to Company of logged User.
     * Get a page of batches for my Company
     */
    async getPageBatchesForMyCompany(requestParameters: GetPageBatchesForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageBatch> {
        const response = await this.getPageBatchesForMyCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update Batch identified by given id (must belong to Company of logged user).
     * Partial update a Batch
     */
    async updateBatchCustomRaw(requestParameters: UpdateBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Batch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBatchCustom.');
        }

        if (requestParameters.batch === null || requestParameters.batch === undefined) {
            throw new runtime.RequiredError('batch','Required parameter requestParameters.batch was null or undefined when calling updateBatchCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BatchToJSON(requestParameters.batch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchFromJSON(jsonValue));
    }

    /**
     * Partial update Batch identified by given id (must belong to Company of logged user).
     * Partial update a Batch
     */
    async updateBatchCustom(requestParameters: UpdateBatchCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Batch> {
        const response = await this.updateBatchCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
