import { DateHelper } from '@helpers/DateHelper';
import { i18n } from '@i18n/i18n';
import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { UrstammStyle } from '../../../utils/styles/UrstammStyle';
import UrstammIcon, { UrstammIconProps } from '../icon/UrstammIcon';

export interface UrstammButtonStyleProps {
  containerViewStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  buttonBackground?: string;
  buttonBorderStyle?: StyleProp<ViewStyle>;
}

export default function UrstammButtonSync(props: {
  testID: string;
  style: UrstammButtonStyleProps;
  icon?: UrstammIconProps;
  onSubmit?: any;
  disabled?: any;
  counter?: string;
  lastSync?: Date;
}) {
  return (
    <View style={{ flexDirection: 'column' }}>
      <TouchableOpacity
        disabled={props.disabled}
        testID={props.testID}
        onPress={props.onSubmit}
        style={[
          props.style.containerViewStyle,
          props.style.buttonBorderStyle,
          styles.block,
          UrstammStyle.baseShadow,
          {
            backgroundColor: props.style.buttonBackground ? props.style.buttonBackground : ColorTheme.mainGreen
          },
          { opacity: props.disabled ? 0.5 : 1 },
          { minHeight: 20 },
          { padding: 5, margin: 10 }
        ]}>
        {props.icon?.showIcon && (
          <View style={[styles.iconContainer]}>
            <UrstammIcon name={props.icon.iconName} size={24} color={props.icon.iconColor!} />
          </View>
        )}
        <Text
          style={[
            props.style.textStyle,
            props.counter
              ? [
                  {
                    flex: 1,
                    alignItems: 'flex-end',
                    textAlign: 'center'
                  }
                ]
              : null
          ]}>
          {i18n.t('generics.sync')}
        </Text>
      </TouchableOpacity>
      <Text style={[props.style.textStyle, styles.block, { paddingLeft: 3, margin: 10, marginTop: 0 }]}>
        {props.lastSync
          ? i18n.t('utility_components.sync_button.last_sync', {
              date: DateHelper.formatDatetime(props.lastSync)
            })
          : i18n.t('utility_components.sync_button.never_synced')}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  disabled: {
    opacity: 0.6
  },
  iconContainer: {
    paddingRight: 14,
    alignItems: 'center'
  },
  block: {
    alignSelf: 'flex-start'
  }
});
