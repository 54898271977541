import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

interface UrstammImageAnnualRings {
  width?: number | string;
  height?: number | string;
  showTop?: boolean;
  showBottom?: boolean;
}

const UrstammImageAnnualRings = (props: UrstammImageAnnualRings) => {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 414 896" fill="none">
      {props.showTop && (
        <G opacity="1">
          <Path
            d="M121.434 -116.978C248.27 -54.0988 84.4341 9.73446 154.033 119.564C217.817 220.216 434.314 154.255 370.943 354.431C327.557 491.481 348.269 558.676 443.026 634.092"
            stroke="#999999"
          />
          <Path
            d="M130.397 -121.411C257.234 -58.5324 93.3975 5.30087 162.997 115.13C226.781 215.782 443.277 149.821 379.907 349.997C336.52 487.047 357.232 554.243 451.99 629.658"
            stroke="#999999"
          />
          <Path
            d="M139.36 -125.845C266.197 -62.9662 102.361 0.867029 171.96 110.696C235.744 211.349 452.241 145.388 388.87 345.564C345.483 482.613 366.196 549.809 460.953 625.225"
            stroke="#999999"
          />
          <Path
            d="M148.324 -130.279C275.161 -67.3998 111.325 -3.56656 180.924 106.263C244.708 206.915 461.204 140.954 397.834 341.13C354.447 478.18 375.159 545.375 469.917 620.791"
            stroke="#999999"
          />
          <Path
            d="M157.288 -134.712C284.124 -71.8334 120.288 -8.00016 189.887 101.829C253.671 202.481 470.168 136.52 406.797 336.696C363.411 473.746 384.123 540.942 478.88 616.357"
            stroke="#999999"
          />
          <Path
            d="M166.251 -139.146C293.088 -76.267 129.251 -12.4338 198.851 97.3955C262.635 198.048 479.131 132.087 415.761 332.263C372.374 469.312 393.086 536.508 487.844 611.924"
            stroke="#999999"
          />
          <Path
            d="M175.214 -143.579C302.051 -80.7006 138.215 -16.8673 207.814 92.9619C271.598 193.614 488.095 127.653 424.724 327.829C381.337 464.879 402.05 532.074 496.807 607.49"
            stroke="#999999"
          />
          <Path
            d="M184.178 -148.013C311.014 -85.1344 147.178 -21.3012 216.777 88.5281C280.561 189.18 497.058 123.219 433.687 323.395C390.301 460.445 411.013 527.641 505.77 603.056"
            stroke="#999999"
          />
          <Path
            d="M193.141 -152.447C319.978 -89.568 156.142 -25.7348 225.741 84.0945C289.525 184.747 506.021 118.786 442.651 318.962C399.264 456.011 419.976 523.207 514.734 598.623"
            stroke="#999999"
          />
          <Path
            d="M202.104 -156.88C328.941 -94.0016 165.105 -30.1684 234.704 79.6609C298.488 180.313 514.985 114.352 451.614 314.528C408.228 451.578 428.94 518.773 523.697 594.189"
            stroke="#999999"
          />
          <Path
            d="M211.068 -161.314C337.905 -98.4352 174.068 -34.602 243.668 75.2273C307.451 175.88 523.948 109.919 460.577 310.095C417.191 447.144 437.903 514.34 532.66 589.756"
            stroke="#999999"
          />
          <Path
            d="M220.031 -165.748C346.868 -102.869 183.032 -39.0356 252.631 70.7937C316.415 171.446 532.912 105.485 469.541 305.661C426.154 442.711 446.866 509.906 541.624 585.322"
            stroke="#999999"
          />
          <Path
            d="M228.995 -170.181C355.831 -107.303 191.995 -43.4694 261.594 66.3599C325.378 167.012 541.875 101.051 478.504 301.227C435.118 438.277 455.83 505.472 550.587 580.888"
            stroke="#999999"
          />
          <Path
            d="M237.958 -174.615C364.795 -111.736 200.959 -47.903 270.558 61.9263C334.342 162.579 550.838 96.6176 487.468 296.794C444.081 433.843 464.793 501.039 559.551 576.455"
            stroke="#999999"
          />
          <Path
            d="M246.921 -179.049C373.758 -116.17 209.922 -52.3366 279.521 57.4927C343.305 158.145 559.802 92.184 496.431 292.36C453.044 429.41 473.757 496.605 568.514 572.021"
            stroke="#999999"
          />
          <Path
            d="M255.885 -183.482C382.721 -120.603 218.885 -56.7702 288.485 53.0591C352.268 153.711 568.765 87.7504 505.394 287.926C462.008 424.976 482.72 492.172 577.477 567.587"
            stroke="#999999"
          />
          <Path
            d="M264.849 -187.916C391.685 -125.037 227.849 -61.2038 297.448 48.6255C361.232 149.278 577.729 83.3168 514.358 283.493C470.972 420.542 491.684 487.738 586.441 563.154"
            stroke="#999999"
          />
          <Path
            d="M273.812 -192.349C400.649 -129.471 236.813 -65.6374 306.412 44.1919C370.196 144.844 586.692 78.8833 523.322 279.059C479.935 416.109 500.647 483.304 595.405 558.72"
            stroke="#999999"
          />
          <Path
            d="M282.775 -196.783C409.612 -133.904 245.776 -70.0712 315.375 39.758C379.159 140.41 595.656 74.4494 532.285 274.625C488.898 411.675 509.611 478.871 604.368 554.286"
            stroke="#999999"
          />
          <Path
            d="M291.739 -201.217C418.575 -138.338 254.739 -74.5048 324.339 35.3245C388.122 135.977 604.619 70.0158 541.248 270.192C497.862 407.241 518.574 474.437 613.331 549.853"
            stroke="#999999"
          />
        </G>
      )}
      {props.showBottom && (
        <G opacity="1">
          <Path
            d="M-129.16 702.207C12.403 703.28 -107.015 832.34 3.60974 900.668C104.991 963.287 270.835 809.282 301.395 1017.01C322.318 1159.24 370.333 1210.61 488.531 1236.98"
            stroke="#999999"
          />
          <Path
            d="M-123.039 694.3C18.5246 695.373 -100.893 824.433 9.73132 892.761C111.112 955.38 276.957 801.375 307.517 1009.11C328.44 1151.33 376.454 1202.7 494.652 1229.08"
            stroke="#999999"
          />
          <Path
            d="M-116.917 686.393C24.6462 687.466 -94.7716 816.525 15.8529 884.853C117.234 947.472 283.078 793.468 313.638 1001.2C334.561 1143.42 382.576 1194.79 500.774 1221.17"
            stroke="#999999"
          />
          <Path
            d="M-110.795 678.485C30.7678 679.558 -88.65 808.618 21.9745 876.946C123.356 939.565 289.2 785.56 319.76 993.292C340.683 1135.51 388.698 1186.88 506.895 1213.26"
            stroke="#999999"
          />
          <Path
            d="M-104.674 670.578C36.8893 671.651 -82.5284 800.71 28.0961 869.039C129.477 931.658 295.321 777.653 325.882 985.384C346.804 1127.61 394.819 1178.98 513.017 1205.35"
            stroke="#999999"
          />
          <Path
            d="M-98.5522 662.671C43.0109 663.744 -76.4068 792.803 34.2177 861.131C135.599 923.75 301.443 769.746 332.003 977.477C352.926 1119.7 400.941 1171.07 519.139 1197.45"
            stroke="#999999"
          />
          <Path
            d="M-92.4307 654.763C49.1325 655.836 -70.2853 784.896 40.3392 853.224C141.72 915.843 307.564 761.838 338.125 969.57C359.048 1111.79 407.062 1163.16 525.26 1189.54"
            stroke="#999999"
          />
          <Path
            d="M-86.3091 646.856C55.2541 647.929 -64.1637 776.988 46.4608 845.317C147.842 907.936 313.686 753.931 344.246 961.662C365.169 1103.88 413.184 1155.25 531.382 1181.63"
            stroke="#999999"
          />
          <Path
            d="M-80.1875 638.948C61.3757 640.021 -58.0421 769.081 52.5824 837.409C153.963 900.028 319.808 746.024 350.368 953.755C371.291 1095.98 419.306 1147.35 537.503 1173.72"
            stroke="#999999"
          />
          <Path
            d="M-74.0659 631.041C67.4973 632.114 -51.9205 761.174 58.704 829.502C160.085 892.121 325.929 738.116 356.489 945.848C377.412 1088.07 425.427 1139.44 543.625 1165.82"
            stroke="#999999"
          />
          <Path
            d="M-67.9443 623.134C73.6188 624.207 -45.7989 753.266 64.8256 821.595C166.207 884.213 332.051 730.209 362.611 937.94C383.534 1080.16 431.549 1131.53 549.747 1157.91"
            stroke="#999999"
          />
          <Path
            d="M-61.8228 615.227C79.7404 616.299 -39.6773 745.359 70.9471 813.687C172.328 876.306 338.172 722.302 368.733 930.033C389.655 1072.26 437.67 1123.63 555.868 1150"
            stroke="#999999"
          />
          <Path
            d="M-55.7012 607.319C85.862 608.392 -33.5558 737.452 77.0687 805.78C178.45 868.399 344.294 714.394 374.854 922.126C395.777 1064.35 443.792 1115.72 561.99 1142.1"
            stroke="#999999"
          />
          <Path
            d="M-49.5796 599.412C91.9836 600.485 -27.4342 729.544 83.1903 797.873C184.571 860.492 350.416 706.487 380.976 914.218C401.899 1056.44 449.913 1107.81 568.111 1134.19"
            stroke="#999999"
          />
          <Path
            d="M-43.458 591.504C98.1052 592.577 -21.3126 721.637 89.3119 789.965C190.693 852.584 356.537 698.58 387.097 906.311C408.02 1048.53 456.035 1099.9 574.233 1126.28"
            stroke="#999999"
          />
          <Path
            d="M-37.3364 583.597C104.227 584.67 -15.191 713.73 95.4335 782.058C196.815 844.677 362.659 690.672 393.219 898.404C414.142 1040.63 462.157 1092 580.354 1118.37"
            stroke="#999999"
          />
          <Path
            d="M-31.2148 575.69C110.348 576.763 -9.06943 705.822 101.555 774.151C202.936 836.77 368.78 682.765 399.34 890.496C420.263 1032.72 468.278 1084.09 586.476 1110.47"
            stroke="#999999"
          />
          <Path
            d="M-25.0933 567.782C116.47 568.855 -2.94785 697.915 107.677 766.243C209.058 828.862 374.902 674.858 405.462 882.589C426.385 1024.81 474.4 1076.18 592.598 1102.56"
            stroke="#999999"
          />
          <Path
            d="M-18.9717 559.875C122.591 560.948 3.17373 690.008 113.798 758.336C215.179 820.955 381.023 666.95 411.584 874.682C432.507 1016.9 480.521 1068.27 598.719 1094.65"
            stroke="#999999"
          />
          <Path
            d="M-12.8501 551.968C128.713 553.041 9.29531 682.1 119.92 750.429C221.301 813.047 387.145 659.043 417.705 866.774C438.628 1009 486.643 1060.37 604.841 1086.74"
            stroke="#999999"
          />
        </G>
      )}
    </Svg>
  );
};
export default UrstammImageAnnualRings;
