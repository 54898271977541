/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExternalCompany,
} from '../models';
import {
    ExternalCompanyFromJSON,
    ExternalCompanyToJSON,
} from '../models';

export interface CreateExternalCompanyRequest {
    externalCompany: ExternalCompany;
}

export interface DeleteExternalCompanyRequest {
    id: number;
}

export interface GetExternalCompanyRequest {
    id: number;
}

export interface PartialUpdateExternalCompanyRequest {
    id: number;
    externalCompany: ExternalCompany;
}

export interface UpdateExternalCompanyRequest {
    id: number;
    externalCompany: ExternalCompany;
}

/**
 * 
 */
export class ExternalCompanyResourceApi extends runtime.BaseAPI {

    /**
     */
    async createExternalCompanyRaw(requestParameters: CreateExternalCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExternalCompany>> {
        if (requestParameters.externalCompany === null || requestParameters.externalCompany === undefined) {
            throw new runtime.RequiredError('externalCompany','Required parameter requestParameters.externalCompany was null or undefined when calling createExternalCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/external-companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalCompanyToJSON(requestParameters.externalCompany),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalCompanyFromJSON(jsonValue));
    }

    /**
     */
    async createExternalCompany(requestParameters: CreateExternalCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExternalCompany> {
        const response = await this.createExternalCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteExternalCompanyRaw(requestParameters: DeleteExternalCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteExternalCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external-companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteExternalCompany(requestParameters: DeleteExternalCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteExternalCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllExternalCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExternalCompany>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external-companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExternalCompanyFromJSON));
    }

    /**
     */
    async getAllExternalCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExternalCompany>> {
        const response = await this.getAllExternalCompaniesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getExternalCompanyRaw(requestParameters: GetExternalCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExternalCompany>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getExternalCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external-companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalCompanyFromJSON(jsonValue));
    }

    /**
     */
    async getExternalCompany(requestParameters: GetExternalCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExternalCompany> {
        const response = await this.getExternalCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateExternalCompanyRaw(requestParameters: PartialUpdateExternalCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExternalCompany>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateExternalCompany.');
        }

        if (requestParameters.externalCompany === null || requestParameters.externalCompany === undefined) {
            throw new runtime.RequiredError('externalCompany','Required parameter requestParameters.externalCompany was null or undefined when calling partialUpdateExternalCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/external-companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalCompanyToJSON(requestParameters.externalCompany),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalCompanyFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateExternalCompany(requestParameters: PartialUpdateExternalCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExternalCompany> {
        const response = await this.partialUpdateExternalCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateExternalCompanyRaw(requestParameters: UpdateExternalCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExternalCompany>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateExternalCompany.');
        }

        if (requestParameters.externalCompany === null || requestParameters.externalCompany === undefined) {
            throw new runtime.RequiredError('externalCompany','Required parameter requestParameters.externalCompany was null or undefined when calling updateExternalCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/external-companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalCompanyToJSON(requestParameters.externalCompany),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalCompanyFromJSON(jsonValue));
    }

    /**
     */
    async updateExternalCompany(requestParameters: UpdateExternalCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExternalCompany> {
        const response = await this.updateExternalCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
