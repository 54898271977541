/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ShippingNote,
} from '../models';
import {
    ShippingNoteFromJSON,
    ShippingNoteToJSON,
} from '../models';

export interface CountShippingNotesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    currentStateEquals?: CountShippingNotesCurrentStateEqualsEnum;
    currentStateNotEquals?: CountShippingNotesCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<CountShippingNotesCurrentStateInEnum>;
    currentStateNotIn?: Array<CountShippingNotesCurrentStateNotInEnum>;
    pdfFilenameContains?: string;
    pdfFilenameDoesNotContain?: string;
    pdfFilenameEquals?: string;
    pdfFilenameNotEquals?: string;
    pdfFilenameSpecified?: boolean;
    pdfFilenameIn?: Array<string>;
    pdfFilenameNotIn?: Array<string>;
    pdfFilepathContains?: string;
    pdfFilepathDoesNotContain?: string;
    pdfFilepathEquals?: string;
    pdfFilepathNotEquals?: string;
    pdfFilepathSpecified?: boolean;
    pdfFilepathIn?: Array<string>;
    pdfFilepathNotIn?: Array<string>;
    pdfMd5Contains?: string;
    pdfMd5DoesNotContain?: string;
    pdfMd5Equals?: string;
    pdfMd5NotEquals?: string;
    pdfMd5Specified?: boolean;
    pdfMd5In?: Array<string>;
    pdfMd5NotIn?: Array<string>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    mockProcessorIdGreaterThan?: number;
    mockProcessorIdLessThan?: number;
    mockProcessorIdGreaterThanOrEqual?: number;
    mockProcessorIdLessThanOrEqual?: number;
    mockProcessorIdEquals?: number;
    mockProcessorIdNotEquals?: number;
    mockProcessorIdSpecified?: boolean;
    mockProcessorIdIn?: Array<number>;
    mockProcessorIdNotIn?: Array<number>;
    processorIdGreaterThan?: number;
    processorIdLessThan?: number;
    processorIdGreaterThanOrEqual?: number;
    processorIdLessThanOrEqual?: number;
    processorIdEquals?: number;
    processorIdNotEquals?: number;
    processorIdSpecified?: boolean;
    processorIdIn?: Array<number>;
    processorIdNotIn?: Array<number>;
    sawmillIdGreaterThan?: number;
    sawmillIdLessThan?: number;
    sawmillIdGreaterThanOrEqual?: number;
    sawmillIdLessThanOrEqual?: number;
    sawmillIdEquals?: number;
    sawmillIdNotEquals?: number;
    sawmillIdSpecified?: boolean;
    sawmillIdIn?: Array<number>;
    sawmillIdNotIn?: Array<number>;
    pdfAuthorIdGreaterThan?: number;
    pdfAuthorIdLessThan?: number;
    pdfAuthorIdGreaterThanOrEqual?: number;
    pdfAuthorIdLessThanOrEqual?: number;
    pdfAuthorIdEquals?: number;
    pdfAuthorIdNotEquals?: number;
    pdfAuthorIdSpecified?: boolean;
    pdfAuthorIdIn?: Array<number>;
    pdfAuthorIdNotIn?: Array<number>;
    batchIdGreaterThan?: number;
    batchIdLessThan?: number;
    batchIdGreaterThanOrEqual?: number;
    batchIdLessThanOrEqual?: number;
    batchIdEquals?: number;
    batchIdNotEquals?: number;
    batchIdSpecified?: boolean;
    batchIdIn?: Array<number>;
    batchIdNotIn?: Array<number>;
    packetIdGreaterThan?: number;
    packetIdLessThan?: number;
    packetIdGreaterThanOrEqual?: number;
    packetIdLessThanOrEqual?: number;
    packetIdEquals?: number;
    packetIdNotEquals?: number;
    packetIdSpecified?: boolean;
    packetIdIn?: Array<number>;
    packetIdNotIn?: Array<number>;
    shippingNoteStateIdGreaterThan?: number;
    shippingNoteStateIdLessThan?: number;
    shippingNoteStateIdGreaterThanOrEqual?: number;
    shippingNoteStateIdLessThanOrEqual?: number;
    shippingNoteStateIdEquals?: number;
    shippingNoteStateIdNotEquals?: number;
    shippingNoteStateIdSpecified?: boolean;
    shippingNoteStateIdIn?: Array<number>;
    shippingNoteStateIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateShippingNoteRequest {
    shippingNote: ShippingNote;
}

export interface DeleteShippingNoteRequest {
    id: number;
}

export interface GetAllShippingNotesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    currentStateEquals?: GetAllShippingNotesCurrentStateEqualsEnum;
    currentStateNotEquals?: GetAllShippingNotesCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<GetAllShippingNotesCurrentStateInEnum>;
    currentStateNotIn?: Array<GetAllShippingNotesCurrentStateNotInEnum>;
    pdfFilenameContains?: string;
    pdfFilenameDoesNotContain?: string;
    pdfFilenameEquals?: string;
    pdfFilenameNotEquals?: string;
    pdfFilenameSpecified?: boolean;
    pdfFilenameIn?: Array<string>;
    pdfFilenameNotIn?: Array<string>;
    pdfFilepathContains?: string;
    pdfFilepathDoesNotContain?: string;
    pdfFilepathEquals?: string;
    pdfFilepathNotEquals?: string;
    pdfFilepathSpecified?: boolean;
    pdfFilepathIn?: Array<string>;
    pdfFilepathNotIn?: Array<string>;
    pdfMd5Contains?: string;
    pdfMd5DoesNotContain?: string;
    pdfMd5Equals?: string;
    pdfMd5NotEquals?: string;
    pdfMd5Specified?: boolean;
    pdfMd5In?: Array<string>;
    pdfMd5NotIn?: Array<string>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    mockProcessorIdGreaterThan?: number;
    mockProcessorIdLessThan?: number;
    mockProcessorIdGreaterThanOrEqual?: number;
    mockProcessorIdLessThanOrEqual?: number;
    mockProcessorIdEquals?: number;
    mockProcessorIdNotEquals?: number;
    mockProcessorIdSpecified?: boolean;
    mockProcessorIdIn?: Array<number>;
    mockProcessorIdNotIn?: Array<number>;
    processorIdGreaterThan?: number;
    processorIdLessThan?: number;
    processorIdGreaterThanOrEqual?: number;
    processorIdLessThanOrEqual?: number;
    processorIdEquals?: number;
    processorIdNotEquals?: number;
    processorIdSpecified?: boolean;
    processorIdIn?: Array<number>;
    processorIdNotIn?: Array<number>;
    sawmillIdGreaterThan?: number;
    sawmillIdLessThan?: number;
    sawmillIdGreaterThanOrEqual?: number;
    sawmillIdLessThanOrEqual?: number;
    sawmillIdEquals?: number;
    sawmillIdNotEquals?: number;
    sawmillIdSpecified?: boolean;
    sawmillIdIn?: Array<number>;
    sawmillIdNotIn?: Array<number>;
    pdfAuthorIdGreaterThan?: number;
    pdfAuthorIdLessThan?: number;
    pdfAuthorIdGreaterThanOrEqual?: number;
    pdfAuthorIdLessThanOrEqual?: number;
    pdfAuthorIdEquals?: number;
    pdfAuthorIdNotEquals?: number;
    pdfAuthorIdSpecified?: boolean;
    pdfAuthorIdIn?: Array<number>;
    pdfAuthorIdNotIn?: Array<number>;
    batchIdGreaterThan?: number;
    batchIdLessThan?: number;
    batchIdGreaterThanOrEqual?: number;
    batchIdLessThanOrEqual?: number;
    batchIdEquals?: number;
    batchIdNotEquals?: number;
    batchIdSpecified?: boolean;
    batchIdIn?: Array<number>;
    batchIdNotIn?: Array<number>;
    packetIdGreaterThan?: number;
    packetIdLessThan?: number;
    packetIdGreaterThanOrEqual?: number;
    packetIdLessThanOrEqual?: number;
    packetIdEquals?: number;
    packetIdNotEquals?: number;
    packetIdSpecified?: boolean;
    packetIdIn?: Array<number>;
    packetIdNotIn?: Array<number>;
    shippingNoteStateIdGreaterThan?: number;
    shippingNoteStateIdLessThan?: number;
    shippingNoteStateIdGreaterThanOrEqual?: number;
    shippingNoteStateIdLessThanOrEqual?: number;
    shippingNoteStateIdEquals?: number;
    shippingNoteStateIdNotEquals?: number;
    shippingNoteStateIdSpecified?: boolean;
    shippingNoteStateIdIn?: Array<number>;
    shippingNoteStateIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetShippingNoteRequest {
    id: number;
}

export interface PartialUpdateShippingNoteRequest {
    id: number;
    shippingNote: ShippingNote;
}

export interface UpdateShippingNoteRequest {
    id: number;
    shippingNote: ShippingNote;
}

/**
 * 
 */
export class ShippingNoteResourceApi extends runtime.BaseAPI {

    /**
     */
    async countShippingNotesRaw(requestParameters: CountShippingNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.pdfFilenameContains !== undefined) {
            queryParameters['pdfFilename.contains'] = requestParameters.pdfFilenameContains;
        }

        if (requestParameters.pdfFilenameDoesNotContain !== undefined) {
            queryParameters['pdfFilename.doesNotContain'] = requestParameters.pdfFilenameDoesNotContain;
        }

        if (requestParameters.pdfFilenameEquals !== undefined) {
            queryParameters['pdfFilename.equals'] = requestParameters.pdfFilenameEquals;
        }

        if (requestParameters.pdfFilenameNotEquals !== undefined) {
            queryParameters['pdfFilename.notEquals'] = requestParameters.pdfFilenameNotEquals;
        }

        if (requestParameters.pdfFilenameSpecified !== undefined) {
            queryParameters['pdfFilename.specified'] = requestParameters.pdfFilenameSpecified;
        }

        if (requestParameters.pdfFilenameIn) {
            queryParameters['pdfFilename.in'] = requestParameters.pdfFilenameIn;
        }

        if (requestParameters.pdfFilenameNotIn) {
            queryParameters['pdfFilename.notIn'] = requestParameters.pdfFilenameNotIn;
        }

        if (requestParameters.pdfFilepathContains !== undefined) {
            queryParameters['pdfFilepath.contains'] = requestParameters.pdfFilepathContains;
        }

        if (requestParameters.pdfFilepathDoesNotContain !== undefined) {
            queryParameters['pdfFilepath.doesNotContain'] = requestParameters.pdfFilepathDoesNotContain;
        }

        if (requestParameters.pdfFilepathEquals !== undefined) {
            queryParameters['pdfFilepath.equals'] = requestParameters.pdfFilepathEquals;
        }

        if (requestParameters.pdfFilepathNotEquals !== undefined) {
            queryParameters['pdfFilepath.notEquals'] = requestParameters.pdfFilepathNotEquals;
        }

        if (requestParameters.pdfFilepathSpecified !== undefined) {
            queryParameters['pdfFilepath.specified'] = requestParameters.pdfFilepathSpecified;
        }

        if (requestParameters.pdfFilepathIn) {
            queryParameters['pdfFilepath.in'] = requestParameters.pdfFilepathIn;
        }

        if (requestParameters.pdfFilepathNotIn) {
            queryParameters['pdfFilepath.notIn'] = requestParameters.pdfFilepathNotIn;
        }

        if (requestParameters.pdfMd5Contains !== undefined) {
            queryParameters['pdfMd5.contains'] = requestParameters.pdfMd5Contains;
        }

        if (requestParameters.pdfMd5DoesNotContain !== undefined) {
            queryParameters['pdfMd5.doesNotContain'] = requestParameters.pdfMd5DoesNotContain;
        }

        if (requestParameters.pdfMd5Equals !== undefined) {
            queryParameters['pdfMd5.equals'] = requestParameters.pdfMd5Equals;
        }

        if (requestParameters.pdfMd5NotEquals !== undefined) {
            queryParameters['pdfMd5.notEquals'] = requestParameters.pdfMd5NotEquals;
        }

        if (requestParameters.pdfMd5Specified !== undefined) {
            queryParameters['pdfMd5.specified'] = requestParameters.pdfMd5Specified;
        }

        if (requestParameters.pdfMd5In) {
            queryParameters['pdfMd5.in'] = requestParameters.pdfMd5In;
        }

        if (requestParameters.pdfMd5NotIn) {
            queryParameters['pdfMd5.notIn'] = requestParameters.pdfMd5NotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.mockProcessorIdGreaterThan !== undefined) {
            queryParameters['mockProcessorId.greaterThan'] = requestParameters.mockProcessorIdGreaterThan;
        }

        if (requestParameters.mockProcessorIdLessThan !== undefined) {
            queryParameters['mockProcessorId.lessThan'] = requestParameters.mockProcessorIdLessThan;
        }

        if (requestParameters.mockProcessorIdGreaterThanOrEqual !== undefined) {
            queryParameters['mockProcessorId.greaterThanOrEqual'] = requestParameters.mockProcessorIdGreaterThanOrEqual;
        }

        if (requestParameters.mockProcessorIdLessThanOrEqual !== undefined) {
            queryParameters['mockProcessorId.lessThanOrEqual'] = requestParameters.mockProcessorIdLessThanOrEqual;
        }

        if (requestParameters.mockProcessorIdEquals !== undefined) {
            queryParameters['mockProcessorId.equals'] = requestParameters.mockProcessorIdEquals;
        }

        if (requestParameters.mockProcessorIdNotEquals !== undefined) {
            queryParameters['mockProcessorId.notEquals'] = requestParameters.mockProcessorIdNotEquals;
        }

        if (requestParameters.mockProcessorIdSpecified !== undefined) {
            queryParameters['mockProcessorId.specified'] = requestParameters.mockProcessorIdSpecified;
        }

        if (requestParameters.mockProcessorIdIn) {
            queryParameters['mockProcessorId.in'] = requestParameters.mockProcessorIdIn;
        }

        if (requestParameters.mockProcessorIdNotIn) {
            queryParameters['mockProcessorId.notIn'] = requestParameters.mockProcessorIdNotIn;
        }

        if (requestParameters.processorIdGreaterThan !== undefined) {
            queryParameters['processorId.greaterThan'] = requestParameters.processorIdGreaterThan;
        }

        if (requestParameters.processorIdLessThan !== undefined) {
            queryParameters['processorId.lessThan'] = requestParameters.processorIdLessThan;
        }

        if (requestParameters.processorIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorId.greaterThanOrEqual'] = requestParameters.processorIdGreaterThanOrEqual;
        }

        if (requestParameters.processorIdLessThanOrEqual !== undefined) {
            queryParameters['processorId.lessThanOrEqual'] = requestParameters.processorIdLessThanOrEqual;
        }

        if (requestParameters.processorIdEquals !== undefined) {
            queryParameters['processorId.equals'] = requestParameters.processorIdEquals;
        }

        if (requestParameters.processorIdNotEquals !== undefined) {
            queryParameters['processorId.notEquals'] = requestParameters.processorIdNotEquals;
        }

        if (requestParameters.processorIdSpecified !== undefined) {
            queryParameters['processorId.specified'] = requestParameters.processorIdSpecified;
        }

        if (requestParameters.processorIdIn) {
            queryParameters['processorId.in'] = requestParameters.processorIdIn;
        }

        if (requestParameters.processorIdNotIn) {
            queryParameters['processorId.notIn'] = requestParameters.processorIdNotIn;
        }

        if (requestParameters.sawmillIdGreaterThan !== undefined) {
            queryParameters['sawmillId.greaterThan'] = requestParameters.sawmillIdGreaterThan;
        }

        if (requestParameters.sawmillIdLessThan !== undefined) {
            queryParameters['sawmillId.lessThan'] = requestParameters.sawmillIdLessThan;
        }

        if (requestParameters.sawmillIdGreaterThanOrEqual !== undefined) {
            queryParameters['sawmillId.greaterThanOrEqual'] = requestParameters.sawmillIdGreaterThanOrEqual;
        }

        if (requestParameters.sawmillIdLessThanOrEqual !== undefined) {
            queryParameters['sawmillId.lessThanOrEqual'] = requestParameters.sawmillIdLessThanOrEqual;
        }

        if (requestParameters.sawmillIdEquals !== undefined) {
            queryParameters['sawmillId.equals'] = requestParameters.sawmillIdEquals;
        }

        if (requestParameters.sawmillIdNotEquals !== undefined) {
            queryParameters['sawmillId.notEquals'] = requestParameters.sawmillIdNotEquals;
        }

        if (requestParameters.sawmillIdSpecified !== undefined) {
            queryParameters['sawmillId.specified'] = requestParameters.sawmillIdSpecified;
        }

        if (requestParameters.sawmillIdIn) {
            queryParameters['sawmillId.in'] = requestParameters.sawmillIdIn;
        }

        if (requestParameters.sawmillIdNotIn) {
            queryParameters['sawmillId.notIn'] = requestParameters.sawmillIdNotIn;
        }

        if (requestParameters.pdfAuthorIdGreaterThan !== undefined) {
            queryParameters['pdfAuthorId.greaterThan'] = requestParameters.pdfAuthorIdGreaterThan;
        }

        if (requestParameters.pdfAuthorIdLessThan !== undefined) {
            queryParameters['pdfAuthorId.lessThan'] = requestParameters.pdfAuthorIdLessThan;
        }

        if (requestParameters.pdfAuthorIdGreaterThanOrEqual !== undefined) {
            queryParameters['pdfAuthorId.greaterThanOrEqual'] = requestParameters.pdfAuthorIdGreaterThanOrEqual;
        }

        if (requestParameters.pdfAuthorIdLessThanOrEqual !== undefined) {
            queryParameters['pdfAuthorId.lessThanOrEqual'] = requestParameters.pdfAuthorIdLessThanOrEqual;
        }

        if (requestParameters.pdfAuthorIdEquals !== undefined) {
            queryParameters['pdfAuthorId.equals'] = requestParameters.pdfAuthorIdEquals;
        }

        if (requestParameters.pdfAuthorIdNotEquals !== undefined) {
            queryParameters['pdfAuthorId.notEquals'] = requestParameters.pdfAuthorIdNotEquals;
        }

        if (requestParameters.pdfAuthorIdSpecified !== undefined) {
            queryParameters['pdfAuthorId.specified'] = requestParameters.pdfAuthorIdSpecified;
        }

        if (requestParameters.pdfAuthorIdIn) {
            queryParameters['pdfAuthorId.in'] = requestParameters.pdfAuthorIdIn;
        }

        if (requestParameters.pdfAuthorIdNotIn) {
            queryParameters['pdfAuthorId.notIn'] = requestParameters.pdfAuthorIdNotIn;
        }

        if (requestParameters.batchIdGreaterThan !== undefined) {
            queryParameters['batchId.greaterThan'] = requestParameters.batchIdGreaterThan;
        }

        if (requestParameters.batchIdLessThan !== undefined) {
            queryParameters['batchId.lessThan'] = requestParameters.batchIdLessThan;
        }

        if (requestParameters.batchIdGreaterThanOrEqual !== undefined) {
            queryParameters['batchId.greaterThanOrEqual'] = requestParameters.batchIdGreaterThanOrEqual;
        }

        if (requestParameters.batchIdLessThanOrEqual !== undefined) {
            queryParameters['batchId.lessThanOrEqual'] = requestParameters.batchIdLessThanOrEqual;
        }

        if (requestParameters.batchIdEquals !== undefined) {
            queryParameters['batchId.equals'] = requestParameters.batchIdEquals;
        }

        if (requestParameters.batchIdNotEquals !== undefined) {
            queryParameters['batchId.notEquals'] = requestParameters.batchIdNotEquals;
        }

        if (requestParameters.batchIdSpecified !== undefined) {
            queryParameters['batchId.specified'] = requestParameters.batchIdSpecified;
        }

        if (requestParameters.batchIdIn) {
            queryParameters['batchId.in'] = requestParameters.batchIdIn;
        }

        if (requestParameters.batchIdNotIn) {
            queryParameters['batchId.notIn'] = requestParameters.batchIdNotIn;
        }

        if (requestParameters.packetIdGreaterThan !== undefined) {
            queryParameters['packetId.greaterThan'] = requestParameters.packetIdGreaterThan;
        }

        if (requestParameters.packetIdLessThan !== undefined) {
            queryParameters['packetId.lessThan'] = requestParameters.packetIdLessThan;
        }

        if (requestParameters.packetIdGreaterThanOrEqual !== undefined) {
            queryParameters['packetId.greaterThanOrEqual'] = requestParameters.packetIdGreaterThanOrEqual;
        }

        if (requestParameters.packetIdLessThanOrEqual !== undefined) {
            queryParameters['packetId.lessThanOrEqual'] = requestParameters.packetIdLessThanOrEqual;
        }

        if (requestParameters.packetIdEquals !== undefined) {
            queryParameters['packetId.equals'] = requestParameters.packetIdEquals;
        }

        if (requestParameters.packetIdNotEquals !== undefined) {
            queryParameters['packetId.notEquals'] = requestParameters.packetIdNotEquals;
        }

        if (requestParameters.packetIdSpecified !== undefined) {
            queryParameters['packetId.specified'] = requestParameters.packetIdSpecified;
        }

        if (requestParameters.packetIdIn) {
            queryParameters['packetId.in'] = requestParameters.packetIdIn;
        }

        if (requestParameters.packetIdNotIn) {
            queryParameters['packetId.notIn'] = requestParameters.packetIdNotIn;
        }

        if (requestParameters.shippingNoteStateIdGreaterThan !== undefined) {
            queryParameters['shippingNoteStateId.greaterThan'] = requestParameters.shippingNoteStateIdGreaterThan;
        }

        if (requestParameters.shippingNoteStateIdLessThan !== undefined) {
            queryParameters['shippingNoteStateId.lessThan'] = requestParameters.shippingNoteStateIdLessThan;
        }

        if (requestParameters.shippingNoteStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['shippingNoteStateId.greaterThanOrEqual'] = requestParameters.shippingNoteStateIdGreaterThanOrEqual;
        }

        if (requestParameters.shippingNoteStateIdLessThanOrEqual !== undefined) {
            queryParameters['shippingNoteStateId.lessThanOrEqual'] = requestParameters.shippingNoteStateIdLessThanOrEqual;
        }

        if (requestParameters.shippingNoteStateIdEquals !== undefined) {
            queryParameters['shippingNoteStateId.equals'] = requestParameters.shippingNoteStateIdEquals;
        }

        if (requestParameters.shippingNoteStateIdNotEquals !== undefined) {
            queryParameters['shippingNoteStateId.notEquals'] = requestParameters.shippingNoteStateIdNotEquals;
        }

        if (requestParameters.shippingNoteStateIdSpecified !== undefined) {
            queryParameters['shippingNoteStateId.specified'] = requestParameters.shippingNoteStateIdSpecified;
        }

        if (requestParameters.shippingNoteStateIdIn) {
            queryParameters['shippingNoteStateId.in'] = requestParameters.shippingNoteStateIdIn;
        }

        if (requestParameters.shippingNoteStateIdNotIn) {
            queryParameters['shippingNoteStateId.notIn'] = requestParameters.shippingNoteStateIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/shipping-notes/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countShippingNotes(requestParameters: CountShippingNotesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countShippingNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createShippingNoteRaw(requestParameters: CreateShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNote>> {
        if (requestParameters.shippingNote === null || requestParameters.shippingNote === undefined) {
            throw new runtime.RequiredError('shippingNote','Required parameter requestParameters.shippingNote was null or undefined when calling createShippingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/shipping-notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShippingNoteToJSON(requestParameters.shippingNote),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteFromJSON(jsonValue));
    }

    /**
     */
    async createShippingNote(requestParameters: CreateShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNote> {
        const response = await this.createShippingNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteShippingNoteRaw(requestParameters: DeleteShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteShippingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteShippingNote(requestParameters: DeleteShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteShippingNoteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllShippingNotesRaw(requestParameters: GetAllShippingNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShippingNote>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.pdfFilenameContains !== undefined) {
            queryParameters['pdfFilename.contains'] = requestParameters.pdfFilenameContains;
        }

        if (requestParameters.pdfFilenameDoesNotContain !== undefined) {
            queryParameters['pdfFilename.doesNotContain'] = requestParameters.pdfFilenameDoesNotContain;
        }

        if (requestParameters.pdfFilenameEquals !== undefined) {
            queryParameters['pdfFilename.equals'] = requestParameters.pdfFilenameEquals;
        }

        if (requestParameters.pdfFilenameNotEquals !== undefined) {
            queryParameters['pdfFilename.notEquals'] = requestParameters.pdfFilenameNotEquals;
        }

        if (requestParameters.pdfFilenameSpecified !== undefined) {
            queryParameters['pdfFilename.specified'] = requestParameters.pdfFilenameSpecified;
        }

        if (requestParameters.pdfFilenameIn) {
            queryParameters['pdfFilename.in'] = requestParameters.pdfFilenameIn;
        }

        if (requestParameters.pdfFilenameNotIn) {
            queryParameters['pdfFilename.notIn'] = requestParameters.pdfFilenameNotIn;
        }

        if (requestParameters.pdfFilepathContains !== undefined) {
            queryParameters['pdfFilepath.contains'] = requestParameters.pdfFilepathContains;
        }

        if (requestParameters.pdfFilepathDoesNotContain !== undefined) {
            queryParameters['pdfFilepath.doesNotContain'] = requestParameters.pdfFilepathDoesNotContain;
        }

        if (requestParameters.pdfFilepathEquals !== undefined) {
            queryParameters['pdfFilepath.equals'] = requestParameters.pdfFilepathEquals;
        }

        if (requestParameters.pdfFilepathNotEquals !== undefined) {
            queryParameters['pdfFilepath.notEquals'] = requestParameters.pdfFilepathNotEquals;
        }

        if (requestParameters.pdfFilepathSpecified !== undefined) {
            queryParameters['pdfFilepath.specified'] = requestParameters.pdfFilepathSpecified;
        }

        if (requestParameters.pdfFilepathIn) {
            queryParameters['pdfFilepath.in'] = requestParameters.pdfFilepathIn;
        }

        if (requestParameters.pdfFilepathNotIn) {
            queryParameters['pdfFilepath.notIn'] = requestParameters.pdfFilepathNotIn;
        }

        if (requestParameters.pdfMd5Contains !== undefined) {
            queryParameters['pdfMd5.contains'] = requestParameters.pdfMd5Contains;
        }

        if (requestParameters.pdfMd5DoesNotContain !== undefined) {
            queryParameters['pdfMd5.doesNotContain'] = requestParameters.pdfMd5DoesNotContain;
        }

        if (requestParameters.pdfMd5Equals !== undefined) {
            queryParameters['pdfMd5.equals'] = requestParameters.pdfMd5Equals;
        }

        if (requestParameters.pdfMd5NotEquals !== undefined) {
            queryParameters['pdfMd5.notEquals'] = requestParameters.pdfMd5NotEquals;
        }

        if (requestParameters.pdfMd5Specified !== undefined) {
            queryParameters['pdfMd5.specified'] = requestParameters.pdfMd5Specified;
        }

        if (requestParameters.pdfMd5In) {
            queryParameters['pdfMd5.in'] = requestParameters.pdfMd5In;
        }

        if (requestParameters.pdfMd5NotIn) {
            queryParameters['pdfMd5.notIn'] = requestParameters.pdfMd5NotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.mockProcessorIdGreaterThan !== undefined) {
            queryParameters['mockProcessorId.greaterThan'] = requestParameters.mockProcessorIdGreaterThan;
        }

        if (requestParameters.mockProcessorIdLessThan !== undefined) {
            queryParameters['mockProcessorId.lessThan'] = requestParameters.mockProcessorIdLessThan;
        }

        if (requestParameters.mockProcessorIdGreaterThanOrEqual !== undefined) {
            queryParameters['mockProcessorId.greaterThanOrEqual'] = requestParameters.mockProcessorIdGreaterThanOrEqual;
        }

        if (requestParameters.mockProcessorIdLessThanOrEqual !== undefined) {
            queryParameters['mockProcessorId.lessThanOrEqual'] = requestParameters.mockProcessorIdLessThanOrEqual;
        }

        if (requestParameters.mockProcessorIdEquals !== undefined) {
            queryParameters['mockProcessorId.equals'] = requestParameters.mockProcessorIdEquals;
        }

        if (requestParameters.mockProcessorIdNotEquals !== undefined) {
            queryParameters['mockProcessorId.notEquals'] = requestParameters.mockProcessorIdNotEquals;
        }

        if (requestParameters.mockProcessorIdSpecified !== undefined) {
            queryParameters['mockProcessorId.specified'] = requestParameters.mockProcessorIdSpecified;
        }

        if (requestParameters.mockProcessorIdIn) {
            queryParameters['mockProcessorId.in'] = requestParameters.mockProcessorIdIn;
        }

        if (requestParameters.mockProcessorIdNotIn) {
            queryParameters['mockProcessorId.notIn'] = requestParameters.mockProcessorIdNotIn;
        }

        if (requestParameters.processorIdGreaterThan !== undefined) {
            queryParameters['processorId.greaterThan'] = requestParameters.processorIdGreaterThan;
        }

        if (requestParameters.processorIdLessThan !== undefined) {
            queryParameters['processorId.lessThan'] = requestParameters.processorIdLessThan;
        }

        if (requestParameters.processorIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorId.greaterThanOrEqual'] = requestParameters.processorIdGreaterThanOrEqual;
        }

        if (requestParameters.processorIdLessThanOrEqual !== undefined) {
            queryParameters['processorId.lessThanOrEqual'] = requestParameters.processorIdLessThanOrEqual;
        }

        if (requestParameters.processorIdEquals !== undefined) {
            queryParameters['processorId.equals'] = requestParameters.processorIdEquals;
        }

        if (requestParameters.processorIdNotEquals !== undefined) {
            queryParameters['processorId.notEquals'] = requestParameters.processorIdNotEquals;
        }

        if (requestParameters.processorIdSpecified !== undefined) {
            queryParameters['processorId.specified'] = requestParameters.processorIdSpecified;
        }

        if (requestParameters.processorIdIn) {
            queryParameters['processorId.in'] = requestParameters.processorIdIn;
        }

        if (requestParameters.processorIdNotIn) {
            queryParameters['processorId.notIn'] = requestParameters.processorIdNotIn;
        }

        if (requestParameters.sawmillIdGreaterThan !== undefined) {
            queryParameters['sawmillId.greaterThan'] = requestParameters.sawmillIdGreaterThan;
        }

        if (requestParameters.sawmillIdLessThan !== undefined) {
            queryParameters['sawmillId.lessThan'] = requestParameters.sawmillIdLessThan;
        }

        if (requestParameters.sawmillIdGreaterThanOrEqual !== undefined) {
            queryParameters['sawmillId.greaterThanOrEqual'] = requestParameters.sawmillIdGreaterThanOrEqual;
        }

        if (requestParameters.sawmillIdLessThanOrEqual !== undefined) {
            queryParameters['sawmillId.lessThanOrEqual'] = requestParameters.sawmillIdLessThanOrEqual;
        }

        if (requestParameters.sawmillIdEquals !== undefined) {
            queryParameters['sawmillId.equals'] = requestParameters.sawmillIdEquals;
        }

        if (requestParameters.sawmillIdNotEquals !== undefined) {
            queryParameters['sawmillId.notEquals'] = requestParameters.sawmillIdNotEquals;
        }

        if (requestParameters.sawmillIdSpecified !== undefined) {
            queryParameters['sawmillId.specified'] = requestParameters.sawmillIdSpecified;
        }

        if (requestParameters.sawmillIdIn) {
            queryParameters['sawmillId.in'] = requestParameters.sawmillIdIn;
        }

        if (requestParameters.sawmillIdNotIn) {
            queryParameters['sawmillId.notIn'] = requestParameters.sawmillIdNotIn;
        }

        if (requestParameters.pdfAuthorIdGreaterThan !== undefined) {
            queryParameters['pdfAuthorId.greaterThan'] = requestParameters.pdfAuthorIdGreaterThan;
        }

        if (requestParameters.pdfAuthorIdLessThan !== undefined) {
            queryParameters['pdfAuthorId.lessThan'] = requestParameters.pdfAuthorIdLessThan;
        }

        if (requestParameters.pdfAuthorIdGreaterThanOrEqual !== undefined) {
            queryParameters['pdfAuthorId.greaterThanOrEqual'] = requestParameters.pdfAuthorIdGreaterThanOrEqual;
        }

        if (requestParameters.pdfAuthorIdLessThanOrEqual !== undefined) {
            queryParameters['pdfAuthorId.lessThanOrEqual'] = requestParameters.pdfAuthorIdLessThanOrEqual;
        }

        if (requestParameters.pdfAuthorIdEquals !== undefined) {
            queryParameters['pdfAuthorId.equals'] = requestParameters.pdfAuthorIdEquals;
        }

        if (requestParameters.pdfAuthorIdNotEquals !== undefined) {
            queryParameters['pdfAuthorId.notEquals'] = requestParameters.pdfAuthorIdNotEquals;
        }

        if (requestParameters.pdfAuthorIdSpecified !== undefined) {
            queryParameters['pdfAuthorId.specified'] = requestParameters.pdfAuthorIdSpecified;
        }

        if (requestParameters.pdfAuthorIdIn) {
            queryParameters['pdfAuthorId.in'] = requestParameters.pdfAuthorIdIn;
        }

        if (requestParameters.pdfAuthorIdNotIn) {
            queryParameters['pdfAuthorId.notIn'] = requestParameters.pdfAuthorIdNotIn;
        }

        if (requestParameters.batchIdGreaterThan !== undefined) {
            queryParameters['batchId.greaterThan'] = requestParameters.batchIdGreaterThan;
        }

        if (requestParameters.batchIdLessThan !== undefined) {
            queryParameters['batchId.lessThan'] = requestParameters.batchIdLessThan;
        }

        if (requestParameters.batchIdGreaterThanOrEqual !== undefined) {
            queryParameters['batchId.greaterThanOrEqual'] = requestParameters.batchIdGreaterThanOrEqual;
        }

        if (requestParameters.batchIdLessThanOrEqual !== undefined) {
            queryParameters['batchId.lessThanOrEqual'] = requestParameters.batchIdLessThanOrEqual;
        }

        if (requestParameters.batchIdEquals !== undefined) {
            queryParameters['batchId.equals'] = requestParameters.batchIdEquals;
        }

        if (requestParameters.batchIdNotEquals !== undefined) {
            queryParameters['batchId.notEquals'] = requestParameters.batchIdNotEquals;
        }

        if (requestParameters.batchIdSpecified !== undefined) {
            queryParameters['batchId.specified'] = requestParameters.batchIdSpecified;
        }

        if (requestParameters.batchIdIn) {
            queryParameters['batchId.in'] = requestParameters.batchIdIn;
        }

        if (requestParameters.batchIdNotIn) {
            queryParameters['batchId.notIn'] = requestParameters.batchIdNotIn;
        }

        if (requestParameters.packetIdGreaterThan !== undefined) {
            queryParameters['packetId.greaterThan'] = requestParameters.packetIdGreaterThan;
        }

        if (requestParameters.packetIdLessThan !== undefined) {
            queryParameters['packetId.lessThan'] = requestParameters.packetIdLessThan;
        }

        if (requestParameters.packetIdGreaterThanOrEqual !== undefined) {
            queryParameters['packetId.greaterThanOrEqual'] = requestParameters.packetIdGreaterThanOrEqual;
        }

        if (requestParameters.packetIdLessThanOrEqual !== undefined) {
            queryParameters['packetId.lessThanOrEqual'] = requestParameters.packetIdLessThanOrEqual;
        }

        if (requestParameters.packetIdEquals !== undefined) {
            queryParameters['packetId.equals'] = requestParameters.packetIdEquals;
        }

        if (requestParameters.packetIdNotEquals !== undefined) {
            queryParameters['packetId.notEquals'] = requestParameters.packetIdNotEquals;
        }

        if (requestParameters.packetIdSpecified !== undefined) {
            queryParameters['packetId.specified'] = requestParameters.packetIdSpecified;
        }

        if (requestParameters.packetIdIn) {
            queryParameters['packetId.in'] = requestParameters.packetIdIn;
        }

        if (requestParameters.packetIdNotIn) {
            queryParameters['packetId.notIn'] = requestParameters.packetIdNotIn;
        }

        if (requestParameters.shippingNoteStateIdGreaterThan !== undefined) {
            queryParameters['shippingNoteStateId.greaterThan'] = requestParameters.shippingNoteStateIdGreaterThan;
        }

        if (requestParameters.shippingNoteStateIdLessThan !== undefined) {
            queryParameters['shippingNoteStateId.lessThan'] = requestParameters.shippingNoteStateIdLessThan;
        }

        if (requestParameters.shippingNoteStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['shippingNoteStateId.greaterThanOrEqual'] = requestParameters.shippingNoteStateIdGreaterThanOrEqual;
        }

        if (requestParameters.shippingNoteStateIdLessThanOrEqual !== undefined) {
            queryParameters['shippingNoteStateId.lessThanOrEqual'] = requestParameters.shippingNoteStateIdLessThanOrEqual;
        }

        if (requestParameters.shippingNoteStateIdEquals !== undefined) {
            queryParameters['shippingNoteStateId.equals'] = requestParameters.shippingNoteStateIdEquals;
        }

        if (requestParameters.shippingNoteStateIdNotEquals !== undefined) {
            queryParameters['shippingNoteStateId.notEquals'] = requestParameters.shippingNoteStateIdNotEquals;
        }

        if (requestParameters.shippingNoteStateIdSpecified !== undefined) {
            queryParameters['shippingNoteStateId.specified'] = requestParameters.shippingNoteStateIdSpecified;
        }

        if (requestParameters.shippingNoteStateIdIn) {
            queryParameters['shippingNoteStateId.in'] = requestParameters.shippingNoteStateIdIn;
        }

        if (requestParameters.shippingNoteStateIdNotIn) {
            queryParameters['shippingNoteStateId.notIn'] = requestParameters.shippingNoteStateIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/shipping-notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShippingNoteFromJSON));
    }

    /**
     */
    async getAllShippingNotes(requestParameters: GetAllShippingNotesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShippingNote>> {
        const response = await this.getAllShippingNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getShippingNoteRaw(requestParameters: GetShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getShippingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteFromJSON(jsonValue));
    }

    /**
     */
    async getShippingNote(requestParameters: GetShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNote> {
        const response = await this.getShippingNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateShippingNoteRaw(requestParameters: PartialUpdateShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateShippingNote.');
        }

        if (requestParameters.shippingNote === null || requestParameters.shippingNote === undefined) {
            throw new runtime.RequiredError('shippingNote','Required parameter requestParameters.shippingNote was null or undefined when calling partialUpdateShippingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ShippingNoteToJSON(requestParameters.shippingNote),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateShippingNote(requestParameters: PartialUpdateShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNote> {
        const response = await this.partialUpdateShippingNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateShippingNoteRaw(requestParameters: UpdateShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShippingNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateShippingNote.');
        }

        if (requestParameters.shippingNote === null || requestParameters.shippingNote === undefined) {
            throw new runtime.RequiredError('shippingNote','Required parameter requestParameters.shippingNote was null or undefined when calling updateShippingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/shipping-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ShippingNoteToJSON(requestParameters.shippingNote),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShippingNoteFromJSON(jsonValue));
    }

    /**
     */
    async updateShippingNote(requestParameters: UpdateShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShippingNote> {
        const response = await this.updateShippingNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CountShippingNotesCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type CountShippingNotesCurrentStateEqualsEnum = typeof CountShippingNotesCurrentStateEqualsEnum[keyof typeof CountShippingNotesCurrentStateEqualsEnum];
/**
 * @export
 */
export const CountShippingNotesCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type CountShippingNotesCurrentStateNotEqualsEnum = typeof CountShippingNotesCurrentStateNotEqualsEnum[keyof typeof CountShippingNotesCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const CountShippingNotesCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type CountShippingNotesCurrentStateInEnum = typeof CountShippingNotesCurrentStateInEnum[keyof typeof CountShippingNotesCurrentStateInEnum];
/**
 * @export
 */
export const CountShippingNotesCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type CountShippingNotesCurrentStateNotInEnum = typeof CountShippingNotesCurrentStateNotInEnum[keyof typeof CountShippingNotesCurrentStateNotInEnum];
/**
 * @export
 */
export const GetAllShippingNotesCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type GetAllShippingNotesCurrentStateEqualsEnum = typeof GetAllShippingNotesCurrentStateEqualsEnum[keyof typeof GetAllShippingNotesCurrentStateEqualsEnum];
/**
 * @export
 */
export const GetAllShippingNotesCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type GetAllShippingNotesCurrentStateNotEqualsEnum = typeof GetAllShippingNotesCurrentStateNotEqualsEnum[keyof typeof GetAllShippingNotesCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const GetAllShippingNotesCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type GetAllShippingNotesCurrentStateInEnum = typeof GetAllShippingNotesCurrentStateInEnum[keyof typeof GetAllShippingNotesCurrentStateInEnum];
/**
 * @export
 */
export const GetAllShippingNotesCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type GetAllShippingNotesCurrentStateNotInEnum = typeof GetAllShippingNotesCurrentStateNotInEnum[keyof typeof GetAllShippingNotesCurrentStateNotInEnum];
