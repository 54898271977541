/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Trunk } from './Trunk';
import {
    TrunkFromJSON,
    TrunkFromJSONTyped,
    TrunkToJSON,
} from './Trunk';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface TrunkChangeHistory
 */
export interface TrunkChangeHistory {
    /**
     * 
     * @type {number}
     * @memberof TrunkChangeHistory
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TrunkChangeHistory
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof TrunkChangeHistory
     */
    quality?: TrunkChangeHistoryQualityEnum;
    /**
     * 
     * @type {number}
     * @memberof TrunkChangeHistory
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof TrunkChangeHistory
     */
    diameter?: number;
    /**
     * 
     * @type {string}
     * @memberof TrunkChangeHistory
     */
    species?: TrunkChangeHistorySpeciesEnum;
    /**
     * 
     * @type {Trunk}
     * @memberof TrunkChangeHistory
     */
    trunk?: Trunk;
    /**
     * 
     * @type {User}
     * @memberof TrunkChangeHistory
     */
    author?: User;
}


/**
 * @export
 */
export const TrunkChangeHistoryQualityEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D'
} as const;
export type TrunkChangeHistoryQualityEnum = typeof TrunkChangeHistoryQualityEnum[keyof typeof TrunkChangeHistoryQualityEnum];

/**
 * @export
 */
export const TrunkChangeHistorySpeciesEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type TrunkChangeHistorySpeciesEnum = typeof TrunkChangeHistorySpeciesEnum[keyof typeof TrunkChangeHistorySpeciesEnum];


/**
 * Check if a given object implements the TrunkChangeHistory interface.
 */
export function instanceOfTrunkChangeHistory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TrunkChangeHistoryFromJSON(json: any): TrunkChangeHistory {
    return TrunkChangeHistoryFromJSONTyped(json, false);
}

export function TrunkChangeHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrunkChangeHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'quality': !exists(json, 'quality') ? undefined : json['quality'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'diameter': !exists(json, 'diameter') ? undefined : json['diameter'],
        'species': !exists(json, 'species') ? undefined : json['species'],
        'trunk': !exists(json, 'trunk') ? undefined : TrunkFromJSON(json['trunk']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
    };
}

export function TrunkChangeHistoryToJSON(value?: TrunkChangeHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'quality': value.quality,
        'length': value.length,
        'diameter': value.diameter,
        'species': value.species,
        'trunk': TrunkToJSON(value.trunk),
        'author': UserToJSON(value.author),
    };
}

