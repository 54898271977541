import { i18n } from '@i18n/i18n';
import {
  ShippingNote,
  Batch,
  DeliverySheetDTO,
  Packet,
  UserExtended,
  UserExtendedTypeEnum,
  ProcessorBatch,
  ProcessorShippingNote,
  Product,
  ProcessorShippingNoteDTO,
  Project
} from '@services/apis/generated';
import { isCompanyMock } from './UrstammUtilityFunctions';

/**
 * It checks by name or by company if Delivery Sheet must be included in filtered list.
 * Check is on name and based on logged user it checks forestry name or sawmill name.
 *
 * @param searchText Search text input by user
 * @param data DeliverySheet to check
 * @param loggedUser Currently logged user
 * @returns If current delivery sheet must be included in filter
 */
export const filterDSByNameOrCompany = (
  searchText: string,
  data: DeliverySheetDTO,
  loggedUser: UserExtended
): boolean => {
  if (!data || !data.name) return false;
  let lowerCaseSearchText = searchText.toLowerCase();
  let isForestry = loggedUser.type == UserExtendedTypeEnum.Forestry;
  return (
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    (isForestry && data.sawmill?.name?.toLocaleLowerCase().includes(lowerCaseSearchText)) ||
    (!isForestry && data.forestry?.name?.toLocaleLowerCase().includes(lowerCaseSearchText)) ||
    false
  );
};

/**
 * It checks by name or by company if ProcessorShippingNote must be included in filtered list.
 * Check is on name and based on logged user it checks processor name or creator name.
 *
 * @param searchText Search text input by user
 * @param data ProcessorShippingNote to check
 * @param loggedUser Currently logged user
 * @returns If current delivery sheet must be included in filter
 */
 export const filterProcessorShippingNoteByNameOrCompany = (
  searchText: string,
  data: ProcessorShippingNoteDTO,
  loggedUser: UserExtended
): boolean => {
  if (!data || !data.name) return false;
  let lowerCaseSearchText = searchText.toLowerCase();
  let isForestry = loggedUser.type == UserExtendedTypeEnum.Forestry;
  return (
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    (isForestry && data.creator?.name?.toLocaleLowerCase().includes(lowerCaseSearchText)) ||
    (!isForestry && data.processor?.name?.toLocaleLowerCase().includes(lowerCaseSearchText)) ||
    false
  );
};

/**
 * It checks by name or by unique identifier if Batch must be included in filtered list.
 *
 * @param searchText Search text input by user
 * @param data Batch to check
 * @param loggedUser Currently logged user
 * @returns If current batch must be included in filter
 */
export const filterBatchByNameOrUniqueIdentifier = (
  searchText: string,
  data: Batch,
  loggedUser: UserExtended
): boolean => {
  if (!data || !data.name) return false;
  let lowerCaseSearchText = searchText.toLowerCase();
  return (
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.uniqueIdentifier?.toLowerCase().includes(lowerCaseSearchText) ||
    false
  );
};

/**
 * It checks by name if ShippingNote must be included in filtered list.
 *
 * @param searchText Search text input by user
 * @param data ShippingNote to check
 * @param loggedUser Currently logged user
 * @returns If current shipment must be included in filter
 */
export const filterShippingNote = (searchText: string, data: ShippingNote, loggedUser: UserExtended): boolean => {
  if (!data || !data.name) return false;
  let lowerCaseSearchText = searchText.toLowerCase();
  let isSawmill = loggedUser.type == UserExtendedTypeEnum.Sawmill;
  return (
    data?.id?.toString(10).includes(lowerCaseSearchText) || 
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.batch?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.batch?.uniqueIdentifier?.toLowerCase().includes(lowerCaseSearchText) ||
    (isSawmill && data.processor?.name?.toLocaleLowerCase().includes(lowerCaseSearchText)) ||
    (isSawmill &&
      isCompanyMock(data.processor) &&
      i18n.t('views.shipping_note.no_urstamm_processor').toLowerCase().includes(lowerCaseSearchText)) ||
    (isSawmill &&
      data.mockProcessor &&
      i18n.t('views.shipping_note.no_urstamm_processor').toLowerCase().includes(lowerCaseSearchText)) ||
    (isSawmill && data.mockProcessor?.name?.toLocaleLowerCase().includes(lowerCaseSearchText)) ||
    (!isSawmill && data.sawmill?.name?.toLocaleLowerCase().includes(lowerCaseSearchText)) ||
    false
  );
};

/**
 * It checks by name or by unique identifier if Packet must be included in filtered list.
 *
 * @param searchText Search text input by user
 * @param data Packet to check
 * @param loggedUser Currently logged user
 * @returns If current packet must be included in filter
 */
export const filterPacketByNameOrUniqueIdentifier = (
  searchText: string,
  data: Packet,
  loggedUser: UserExtended
): boolean => {
  if (!data || !data.name) return false;
  let lowerCaseSearchText = searchText.toLowerCase();
  return (
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.uniqueIdentifier?.toLowerCase().includes(lowerCaseSearchText) ||
    false
  );
};


/**
 * It checks by name, unique identifier, processor, product type if Packet must be included in filtered list.
 *
 * @param searchText Search text input by user
 * @param data Packet to check
 * @param loggedUser Currently logged user
 * @returns If current packet must be included in filter
 */
export const filterPacketByNameOrUniqueIdentifierOrProductTypeOrProcessor = (
  searchText: string,
  data: Packet,
  loggedUser: UserExtended
): boolean => {
  if (!data || !data.name) return false;
  let lowerCaseSearchText = searchText.toLowerCase();
  return (
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.uniqueIdentifier?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.productType?.name?.toLocaleLowerCase().includes(lowerCaseSearchText) ||
    data?.processor?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.processorCustomName?.toLowerCase().includes(lowerCaseSearchText) ||
    false
  );
};

/**
 * It checks by name or by company if ProcessorShippingNote must be included in filtered list.
 * Check is on name and based on logged user it checks processor name or creator name.
 *
 * @param searchText Search text input by user
 * @param data ProcessorShippingNote to check
 * @param loggedUser Currently logged user
 * @returns If current delivery sheet must be included in filter
 */
export const filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessorOrProcessorShippingNoteId = (
  searchText: string,
  data: Product & Partial<{ _acceptedProcessorShippingNote: Partial<ProcessorShippingNoteDTO> }>,
  loggedUser: UserExtended
): boolean => {
  if (!data || !data.name) return false;
  let lowerCaseSearchText = searchText.toLowerCase();
  return (
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.uniqueIdentifier?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.productType?.name?.toLocaleLowerCase().includes(lowerCaseSearchText) ||
    data?.processor?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.processorCustomName?.toLowerCase().includes(lowerCaseSearchText) ||
    data?._acceptedProcessorShippingNote?.id?.toString().toLowerCase().includes(lowerCaseSearchText) ||
    false
  );
};

export const filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessor = (
  searchText: string,
  data: Product,
  loggedUser: UserExtended
): boolean => {
  if (!data || !data.name) return false;
  let lowerCaseSearchText = searchText.toLowerCase();
  return (
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.uniqueIdentifier?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.productType?.name?.toLocaleLowerCase().includes(lowerCaseSearchText) ||
    data?.processor?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.processorCustomName?.toLowerCase().includes(lowerCaseSearchText) ||
    false
  );
};


/**
 * It checks by name or by unique identifier if ProcessorBatch must be included in filtered list.
 *
 * @param searchText Search text input by user
 * @param data ProcessorBatch to check
 * @param loggedUser Currently logged user
 * @returns If current batch must be included in filter
 */
 export const filterProcessorBatchByNameOrUniqueIdentifier = (
  searchText: string,
  data: ProcessorBatch,
  loggedUser: UserExtended
): boolean => {
  if (!data || !data.name) return false;
  let lowerCaseSearchText = searchText.toLowerCase();
  return (
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.uniqueIdentifier?.toLowerCase().includes(lowerCaseSearchText) ||
    false
  );
};

/**
 * It checks by name if ProcessorShippingNote must be included in filtered list.
 *
 * @param searchText Search text input by user
 * @param data ProcessorShippingNote to check
 * @param loggedUser Currently logged user
 * @returns If current shipment must be included in filter
 */
export const filterProcessorShippingNote = (searchText: string, data: ProcessorShippingNote, loggedUser: UserExtended): boolean => {
  if (!data || !data.name) return false;
  let lowerCaseSearchText = searchText.toLowerCase();
  let isSawmill = loggedUser.type == UserExtendedTypeEnum.Sawmill;
  let id = data.referenceShippingNote ? data.referenceShippingNote.id : data.id;
  return (
    id?.toString().includes(lowerCaseSearchText) ||
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.outgoingBatch?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.outgoingBatch?.uniqueIdentifier?.toLowerCase().includes(lowerCaseSearchText) ||
    (isSawmill && data.processor?.name?.toLocaleLowerCase().includes(lowerCaseSearchText)) ||
    (isSawmill &&
      isCompanyMock(data.processor) &&
      i18n.t('views.processor_shipping_note.no_urstamm_processor').toLowerCase().includes(lowerCaseSearchText)) ||
    (isSawmill &&
      data.mockProcessor &&
      i18n.t('views.processor_shipping_note.no_urstamm_processor').toLowerCase().includes(lowerCaseSearchText)) ||
    (isSawmill && data.mockProcessor?.name?.toLocaleLowerCase().includes(lowerCaseSearchText)) ||
    (!isSawmill && data.creator?.name?.toLocaleLowerCase().includes(lowerCaseSearchText)) ||
    false
  );
};

/**
 * It checks by name or by unique identifier if Product must be included in filtered list.
 *
 * @param searchText Search text input by user
 * @param data Product to check
 * @param loggedUser Currently logged user
 * @returns If current product must be included in filter
 */
export const filterProductByNameOrUniqueIdentifier = (
  searchText: string,
  data: Product,
  loggedUser: UserExtended
): boolean => {
  if (!data || !data.name) return false;
  let lowerCaseSearchText = searchText.toLowerCase();
  return (
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.uniqueIdentifier?.toLowerCase().includes(lowerCaseSearchText) ||
    false
  );
};


/**
 * It checks by name or by unique number if Project must be included in filtered list.
 *
 * @param searchText Search text input by user
 * @param data Project to check
 * @param loggedUser Currently logged user
 * @returns If current project must be included in filter
 */
export const filterProjectByNameOrUniqueNumber = (
  searchText: string,
  data: Project,
  loggedUser: UserExtended
): boolean => {
  let lowerCaseSearchText = searchText.toLowerCase();
  return (
    data?.name?.toLowerCase().includes(lowerCaseSearchText) ||
    data?.uniqueNumber?.toLowerCase().includes(lowerCaseSearchText) ||
    false
  );
};
