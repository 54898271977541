/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BatchState,
} from '../models';
import {
    BatchStateFromJSON,
    BatchStateToJSON,
} from '../models';

export interface CreateBatchStateRequest {
    batchState: BatchState;
}

export interface DeleteBatchStateRequest {
    id: number;
}

export interface GetBatchStateRequest {
    id: number;
}

export interface PartialUpdateBatchStateRequest {
    id: number;
    batchState: BatchState;
}

export interface UpdateBatchStateRequest {
    id: number;
    batchState: BatchState;
}

/**
 * 
 */
export class BatchStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createBatchStateRaw(requestParameters: CreateBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatchState>> {
        if (requestParameters.batchState === null || requestParameters.batchState === undefined) {
            throw new runtime.RequiredError('batchState','Required parameter requestParameters.batchState was null or undefined when calling createBatchState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/batch-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BatchStateToJSON(requestParameters.batchState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchStateFromJSON(jsonValue));
    }

    /**
     */
    async createBatchState(requestParameters: CreateBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatchState> {
        const response = await this.createBatchStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteBatchStateRaw(requestParameters: DeleteBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBatchState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/batch-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteBatchState(requestParameters: DeleteBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBatchStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllBatchStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BatchState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/batch-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BatchStateFromJSON));
    }

    /**
     */
    async getAllBatchStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BatchState>> {
        const response = await this.getAllBatchStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getBatchStateRaw(requestParameters: GetBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatchState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBatchState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/batch-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchStateFromJSON(jsonValue));
    }

    /**
     */
    async getBatchState(requestParameters: GetBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatchState> {
        const response = await this.getBatchStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateBatchStateRaw(requestParameters: PartialUpdateBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatchState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateBatchState.');
        }

        if (requestParameters.batchState === null || requestParameters.batchState === undefined) {
            throw new runtime.RequiredError('batchState','Required parameter requestParameters.batchState was null or undefined when calling partialUpdateBatchState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/batch-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BatchStateToJSON(requestParameters.batchState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateBatchState(requestParameters: PartialUpdateBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatchState> {
        const response = await this.partialUpdateBatchStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateBatchStateRaw(requestParameters: UpdateBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatchState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBatchState.');
        }

        if (requestParameters.batchState === null || requestParameters.batchState === undefined) {
            throw new runtime.RequiredError('batchState','Required parameter requestParameters.batchState was null or undefined when calling updateBatchState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/batch-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BatchStateToJSON(requestParameters.batchState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchStateFromJSON(jsonValue));
    }

    /**
     */
    async updateBatchState(requestParameters: UpdateBatchStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatchState> {
        const response = await this.updateBatchStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
