import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { Trunk } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById, pushTrunkOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export type TrunkListItem = {
  id?: number;
  trunk: Trunk;
  edited: { status: boolean; data: Trunk };
};

export interface TrunkState {
  list: TrunkListItem[];
  completeList: TrunkListItem[];
  sortBy: ButtonSelect[];
}

const initialState: TrunkState = {
  list: [],
  completeList: [],
  sortBy: []
};

export const trunk = createSlice({
  name: 'trunkList',
  initialState,
  reducers: {
    setTrunkList: (state, action) => {
      let trunkList = { ...state }.list;
      let trunkCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        let objectToCheck = element.trunk
          ? element
          : {
              id: element.id,
              trunk: clearElementForRedux(element),
              edited: { status: false, data: clearElementForRedux(element) }
            };
        clearElementForRedux(objectToCheck.trunk);
        pushElementOrReplaceById(trunkList, objectToCheck);
        pushElementOrReplaceById(trunkCompleteList, objectToCheck);
      });

      state.list = trunkList;
      state.completeList = trunkCompleteList;
    },
    setCompleteTrunkList: (state, action) => {
      // Repetition
      let trunkCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        let objectToCheck = element.trunk
          ? element
          : {
              id: element.id,
              trunk: clearElementForRedux(element),
              edited: { status: false, data: clearElementForRedux(element) }
            };
        clearElementForRedux(objectToCheck.trunk);
        pushElementOrReplaceById(trunkCompleteList, objectToCheck);
      });

      state.completeList = trunkCompleteList;
    },
    removeTrunkElement: (state, action) => {
      let trunkList = { ...state }.list;
      let trunkCompleteList = { ...state }.completeList;

      let element = action.payload;
      let objectToCheck = element.trunk
        ? element
        : {
            id: element.id,
            trunk: element,
            edited: { status: false, data: element }
          };
      trunkList = trunkList.filter(e => e.id != objectToCheck.id);
      trunkCompleteList = trunkCompleteList.filter(e => e.id != objectToCheck.id);

      state.list = trunkList;
      state.completeList = trunkCompleteList;
    },
    setTrunkListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    resetTrunkList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearTrunkList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setTrunkList,
  setCompleteTrunkList,
  removeTrunkElement,
  setTrunkListSort,
  resetTrunkList,
  clearTrunkList
} = trunk.actions;

export default trunk.reducer;
