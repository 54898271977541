/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Notification,
} from '../models';
import {
    NotificationFromJSON,
    NotificationToJSON,
} from '../models';

export interface CreateNotificationRequest {
    notification: Notification;
}

export interface DeleteNotificationRequest {
    id: number;
}

export interface GetNotificationRequest {
    id: number;
}

export interface PartialUpdateNotificationRequest {
    id: number;
    notification: Notification;
}

export interface UpdateNotificationRequest {
    id: number;
    notification: Notification;
}

/**
 * 
 */
export class NotificationResourceApi extends runtime.BaseAPI {

    /**
     */
    async createNotificationRaw(requestParameters: CreateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notification>> {
        if (requestParameters.notification === null || requestParameters.notification === undefined) {
            throw new runtime.RequiredError('notification','Required parameter requestParameters.notification was null or undefined when calling createNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationToJSON(requestParameters.notification),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationFromJSON(jsonValue));
    }

    /**
     */
    async createNotification(requestParameters: CreateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notification> {
        const response = await this.createNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteNotificationRaw(requestParameters: DeleteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNotification(requestParameters: DeleteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNotificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllNotificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Notification>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationFromJSON));
    }

    /**
     */
    async getAllNotifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Notification>> {
        const response = await this.getAllNotificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getNotificationRaw(requestParameters: GetNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationFromJSON(jsonValue));
    }

    /**
     */
    async getNotification(requestParameters: GetNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notification> {
        const response = await this.getNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateNotificationRaw(requestParameters: PartialUpdateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateNotification.');
        }

        if (requestParameters.notification === null || requestParameters.notification === undefined) {
            throw new runtime.RequiredError('notification','Required parameter requestParameters.notification was null or undefined when calling partialUpdateNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationToJSON(requestParameters.notification),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateNotification(requestParameters: PartialUpdateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notification> {
        const response = await this.partialUpdateNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateNotificationRaw(requestParameters: UpdateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNotification.');
        }

        if (requestParameters.notification === null || requestParameters.notification === undefined) {
            throw new runtime.RequiredError('notification','Required parameter requestParameters.notification was null or undefined when calling updateNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationToJSON(requestParameters.notification),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationFromJSON(jsonValue));
    }

    /**
     */
    async updateNotification(requestParameters: UpdateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notification> {
        const response = await this.updateNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
