/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  XmlExportErp,
} from '../models';
import {
    XmlExportErpFromJSON,
    XmlExportErpToJSON,
} from '../models';

export interface CreateXmlExportErpRequest {
    xmlExportErp: XmlExportErp;
}

export interface DeleteXmlExportErpRequest {
    id: number;
}

export interface GetXmlExportErpRequest {
    id: number;
}

export interface PartialUpdateXmlExportErpRequest {
    id: number;
    xmlExportErp: XmlExportErp;
}

export interface UpdateXmlExportErpRequest {
    id: number;
    xmlExportErp: XmlExportErp;
}

/**
 * 
 */
export class XmlExportErpResourceApi extends runtime.BaseAPI {

    /**
     */
    async createXmlExportErpRaw(requestParameters: CreateXmlExportErpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<XmlExportErp>> {
        if (requestParameters.xmlExportErp === null || requestParameters.xmlExportErp === undefined) {
            throw new runtime.RequiredError('xmlExportErp','Required parameter requestParameters.xmlExportErp was null or undefined when calling createXmlExportErp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/xml-export-erps`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: XmlExportErpToJSON(requestParameters.xmlExportErp),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => XmlExportErpFromJSON(jsonValue));
    }

    /**
     */
    async createXmlExportErp(requestParameters: CreateXmlExportErpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<XmlExportErp> {
        const response = await this.createXmlExportErpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteXmlExportErpRaw(requestParameters: DeleteXmlExportErpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteXmlExportErp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/xml-export-erps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteXmlExportErp(requestParameters: DeleteXmlExportErpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteXmlExportErpRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllXmlExportErpsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<XmlExportErp>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/xml-export-erps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(XmlExportErpFromJSON));
    }

    /**
     */
    async getAllXmlExportErps(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<XmlExportErp>> {
        const response = await this.getAllXmlExportErpsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getXmlExportErpRaw(requestParameters: GetXmlExportErpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<XmlExportErp>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getXmlExportErp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/xml-export-erps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => XmlExportErpFromJSON(jsonValue));
    }

    /**
     */
    async getXmlExportErp(requestParameters: GetXmlExportErpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<XmlExportErp> {
        const response = await this.getXmlExportErpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateXmlExportErpRaw(requestParameters: PartialUpdateXmlExportErpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<XmlExportErp>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateXmlExportErp.');
        }

        if (requestParameters.xmlExportErp === null || requestParameters.xmlExportErp === undefined) {
            throw new runtime.RequiredError('xmlExportErp','Required parameter requestParameters.xmlExportErp was null or undefined when calling partialUpdateXmlExportErp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/xml-export-erps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: XmlExportErpToJSON(requestParameters.xmlExportErp),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => XmlExportErpFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateXmlExportErp(requestParameters: PartialUpdateXmlExportErpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<XmlExportErp> {
        const response = await this.partialUpdateXmlExportErpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateXmlExportErpRaw(requestParameters: UpdateXmlExportErpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<XmlExportErp>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateXmlExportErp.');
        }

        if (requestParameters.xmlExportErp === null || requestParameters.xmlExportErp === undefined) {
            throw new runtime.RequiredError('xmlExportErp','Required parameter requestParameters.xmlExportErp was null or undefined when calling updateXmlExportErp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/xml-export-erps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: XmlExportErpToJSON(requestParameters.xmlExportErp),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => XmlExportErpFromJSON(jsonValue));
    }

    /**
     */
    async updateXmlExportErp(requestParameters: UpdateXmlExportErpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<XmlExportErp> {
        const response = await this.updateXmlExportErpRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
