/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Batch } from './Batch';
import {
    BatchFromJSON,
    BatchFromJSONTyped,
    BatchToJSON,
} from './Batch';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { ProcessorBatchState } from './ProcessorBatchState';
import {
    ProcessorBatchStateFromJSON,
    ProcessorBatchStateFromJSONTyped,
    ProcessorBatchStateToJSON,
} from './ProcessorBatchState';
import type { ProcessorShippingNote } from './ProcessorShippingNote';
import {
    ProcessorShippingNoteFromJSON,
    ProcessorShippingNoteFromJSONTyped,
    ProcessorShippingNoteToJSON,
} from './ProcessorShippingNote';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * Processor
 * @export
 * @interface ProcessorBatch
 */
export interface ProcessorBatch {
    /**
     * 
     * @type {number}
     * @memberof ProcessorBatch
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatch
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatch
     */
    uniqueIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatch
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProcessorBatch
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatch
     */
    recordingType: ProcessorBatchRecordingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ProcessorBatch
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatch
     */
    currentState: ProcessorBatchCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatch
     */
    uuid?: string;
    /**
     * 
     * @type {Company}
     * @memberof ProcessorBatch
     */
    processor?: Company;
    /**
     * 
     * @type {Batch}
     * @memberof ProcessorBatch
     */
    referenceBatch?: Batch;
    /**
     * A product can be a source product in multiple processor batch, and a processor batch can have multiple products
     * @type {Set<Product>}
     * @memberof ProcessorBatch
     */
    sourceProducts?: Set<Product>;
    /**
     * 
     * @type {Set<ProcessorBatchState>}
     * @memberof ProcessorBatch
     */
    processorBatchStates?: Set<ProcessorBatchState>;
    /**
     * 
     * @type {Set<ProcessorShippingNote>}
     * @memberof ProcessorBatch
     */
    ownerShippingNotes?: Set<ProcessorShippingNote>;
    /**
     * 
     * @type {Set<Product>}
     * @memberof ProcessorBatch
     */
    products?: Set<Product>;
}


/**
 * @export
 */
export const ProcessorBatchRecordingTypeEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE',
    Date: 'DATE',
} as const;
export type ProcessorBatchRecordingTypeEnum = typeof ProcessorBatchRecordingTypeEnum[keyof typeof ProcessorBatchRecordingTypeEnum];

/**
 * @export
 */
export const ProcessorBatchCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProcessorBatchCurrentStateEnum = typeof ProcessorBatchCurrentStateEnum[keyof typeof ProcessorBatchCurrentStateEnum];


/**
 * Check if a given object implements the ProcessorBatch interface.
 */
export function instanceOfProcessorBatch(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "uniqueIdentifier" in value;
    isInstance = isInstance && "recordingType" in value;
    isInstance = isInstance && "currentState" in value;

    return isInstance;
}

export function ProcessorBatchFromJSON(json: any): ProcessorBatch {
    return ProcessorBatchFromJSONTyped(json, false);
}

export function ProcessorBatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessorBatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'uniqueIdentifier': json['uniqueIdentifier'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'recordingType': json['recordingType'],
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'currentState': json['currentState'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'processor': !exists(json, 'processor') ? undefined : CompanyFromJSON(json['processor']),
        'referenceBatch': !exists(json, 'referenceBatch') ? undefined : BatchFromJSON(json['referenceBatch']),
        'sourceProducts': !exists(json, 'sourceProducts') ? undefined : (new Set((json['sourceProducts'] as Array<any>).map(ProductFromJSON))),
        'processorBatchStates': !exists(json, 'processorBatchStates') ? undefined : (new Set((json['processorBatchStates'] as Array<any>).map(ProcessorBatchStateFromJSON))),
        'ownerShippingNotes': !exists(json, 'ownerShippingNotes') ? undefined : (new Set((json['ownerShippingNotes'] as Array<any>).map(ProcessorShippingNoteFromJSON))),
        'products': !exists(json, 'products') ? undefined : (new Set((json['products'] as Array<any>).map(ProductFromJSON))),
    };
}

export function ProcessorBatchToJSON(value?: ProcessorBatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'uniqueIdentifier': value.uniqueIdentifier,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'recordingType': value.recordingType,
        'sumCubage': value.sumCubage,
        'currentState': value.currentState,
        'uuid': value.uuid,
        'processor': CompanyToJSON(value.processor),
        'referenceBatch': BatchToJSON(value.referenceBatch),
        'sourceProducts': value.sourceProducts === undefined ? undefined : (Array.from(value.sourceProducts as Set<any>).map(ProductToJSON)),
        'processorBatchStates': value.processorBatchStates === undefined ? undefined : (Array.from(value.processorBatchStates as Set<any>).map(ProcessorBatchStateToJSON)),
        'ownerShippingNotes': value.ownerShippingNotes === undefined ? undefined : (Array.from(value.ownerShippingNotes as Set<any>).map(ProcessorShippingNoteToJSON)),
        'products': value.products === undefined ? undefined : (Array.from(value.products as Set<any>).map(ProductToJSON)),
    };
}

