import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { ProcessorBatch } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface ProcessorBatchState {
  list: ProcessorBatch[];
  completeList: ProcessorBatch[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: ProcessorBatchState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const processorBatch = createSlice({
  name: 'processorBatchList',
  initialState,
  reducers: {
    setProcessorBatchList: (state, action) => {
      let processorBatchList = { ...state }.list;
      let processorBatchCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(processorBatchList, element);
        pushElementOrReplaceById(processorBatchCompleteList, element);
      });

      state.list = processorBatchList;
      state.completeList = processorBatchCompleteList;
    },
    setProcessorBatchTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setProcessorBatchListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setProcessorBatchPage: (state, action) => {
      state.page = action.payload;
    },
    resetProcessorBatchList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearProcessorBatchList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const { setProcessorBatchList, setProcessorBatchListSort, resetProcessorBatchList, setProcessorBatchTotalPages, setProcessorBatchPage, clearProcessorBatchList } =
  processorBatch.actions;

export default processorBatch.reducer;
