/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Pile } from './Pile';
import {
    PileFromJSON,
    PileFromJSONTyped,
    PileToJSON,
} from './Pile';

/**
 * 
 * @export
 * @interface OtherAssortment
 */
export interface OtherAssortment {
    /**
     * 
     * @type {number}
     * @memberof OtherAssortment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OtherAssortment
     */
    otherAssortmentType?: OtherAssortmentOtherAssortmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OtherAssortment
     */
    species?: OtherAssortmentSpeciesEnum;
    /**
     * 
     * @type {Pile}
     * @memberof OtherAssortment
     */
    pile?: Pile;
}


/**
 * @export
 */
export const OtherAssortmentOtherAssortmentTypeEnum = {
    EnergyWood: 'ENERGY_WOOD',
    FireWood: 'FIRE_WOOD',
    IndustrialWood: 'INDUSTRIAL_WOOD'
} as const;
export type OtherAssortmentOtherAssortmentTypeEnum = typeof OtherAssortmentOtherAssortmentTypeEnum[keyof typeof OtherAssortmentOtherAssortmentTypeEnum];

/**
 * @export
 */
export const OtherAssortmentSpeciesEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type OtherAssortmentSpeciesEnum = typeof OtherAssortmentSpeciesEnum[keyof typeof OtherAssortmentSpeciesEnum];


/**
 * Check if a given object implements the OtherAssortment interface.
 */
export function instanceOfOtherAssortment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OtherAssortmentFromJSON(json: any): OtherAssortment {
    return OtherAssortmentFromJSONTyped(json, false);
}

export function OtherAssortmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtherAssortment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'otherAssortmentType': !exists(json, 'otherAssortmentType') ? undefined : json['otherAssortmentType'],
        'species': !exists(json, 'species') ? undefined : json['species'],
        'pile': !exists(json, 'pile') ? undefined : PileFromJSON(json['pile']),
    };
}

export function OtherAssortmentToJSON(value?: OtherAssortment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'otherAssortmentType': value.otherAssortmentType,
        'species': value.species,
        'pile': PileToJSON(value.pile),
    };
}

