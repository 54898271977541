import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { i18n } from '@i18n/i18n';
import { RootState } from '@redux/store';
import {
  DeliverySheetDTO,
  TreeDTO,
  Trunk,
  TrunkCurrentStateEnum,
  TrunkQualityEnum,
  TrunkSpeciesEnum
} from '@services/apis/generated';
import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByLoggingState,
  getIconByLoggingState,
  getTextByLoggingState,
  getTextByTreeOrTrunkSpecies,
  getTextByTreeType,
  getTextByTrunkState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  deliverySheetIsSingleRecordingType,
  getCardTitleByRecordingType,
  getMandatorySymbol,
  getTreeDTOSpeciesList
} from '../../../utils/classes/UrstammUtilityFunctions';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList
} from '../../../utils/styles/UrstammStyle';

export default function TrunkRegistrationView(props: {
  navigation: any;
  submitTrunkForm: (form: Trunk) => void;
  saveAndContinue: (form: Trunk) => void;
  deliverySheetSelected: DeliverySheetDTO;
  treeList: TreeDTO[];
  resetForm: boolean;
  trunkReference?: Trunk;
}) {
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);

  const [filterList, setFilterList] = useState<SlideButtonOption[]>([]);
  const [trunkForm, setTrunkForm] = useStateWithCallbackLazy<Trunk>({
    name: '',
    currentState: TrunkCurrentStateEnum.InProgressByForestry
  });
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [trunkQualityList, setTrunkQualityList] = useState<SlideButtonOption[]>([]);

  const scrollRef = useRef<any>();

  const [showTreePhotoNumberListSelect, setShowTreePhotoNumberListSelect] = useState<boolean>(false);

  //Validators
  const validators = useState<any>({
    trunkNumberFAS: { required: true, numeric: true },
    treePhotoNumber: { required: deliverySheetIsSingleRecordingType(props.deliverySheetSelected?.recordingType!) },
    quality: { required: true },
    length: { required: true, numeric: true, max2Digits: true },
    diameter: { required: true, numeric_no_digits: true, maxValue: 109, minValue: 10 },
    species: { required: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    trunkNumberFAS: [],
    treePhotoNumber: [],
    quality: [],
    length: [],
    diameter: [],
    species: []
  });

  /**
   * Reset form
   */
  useEffect(() => {
    if (props.resetForm) {
      setTrunkForm(
        {
          name: '',
          currentState: TrunkCurrentStateEnum.InProgressByForestry
        },
        updatedTreeForm => {
          saveButtonDisabled(errors, updatedTreeForm);
        }
      );

      trunkTreeSpeciesList.forEach(type => {
        type.active = false;
      });
      setTrunkTreeSpeciesList([...trunkTreeSpeciesList]);
      resetTrunkQuality();
      resetTrunkTreeSpeciesList();
    }
  }, [props.resetForm]);

  useEffect(() => {
    getSlideButtonOptionsTrunkQualities();
    getSlideButtonOptionsWithTrunkTreeSpecies();
    saveButtonDisabled(errors, trunkForm);
  }, []);

  useEffect(() => {
    if (props.trunkReference && props.trunkReference.trunkNumberFAS) {
      setTrunkForm(
        {
          name: props.trunkReference.name,
          trunkNumberFAS: props.trunkReference.trunkNumberFAS,
          species: props.trunkReference.species,
          treePhotoNumber: props.trunkReference.treePhotoNumber,
          length: props.trunkReference.length,
          //diameter: props.trunkReference.diameter,
          currentState: TrunkCurrentStateEnum.InProgressByForestry,
          quality: props.trunkReference.quality
        },
        updatedTreeForm => {
          saveButtonDisabled(errors, updatedTreeForm);
        }
      );

      if (props.trunkReference.quality) setTrunkQuality(props.trunkReference.quality);
      if (props.trunkReference.species) setTrunkTreeSpecie(props.trunkReference.species);
    }
  }, [props.trunkReference]);

  const validate = (value: string | number, key: string, updatedTrunkForm: Trunk) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTrunkForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedTrunkForm: Trunk) => {
    let disabled: boolean = false;
    if (
      urstammUtilValidatorInError(errorsUpdated) ||
      updatedTrunkForm.trunkNumberFAS?.toString().trim().length == 0 ||
      updatedTrunkForm.trunkNumberFAS?.toString() == null ||
      updatedTrunkForm.trunkNumberFAS?.toString() == '' ||
      (!updatedTrunkForm.treePhotoNumber &&
        deliverySheetIsSingleRecordingType(props.deliverySheetSelected?.recordingType!)) ||
      !updatedTrunkForm.species ||
      !updatedTrunkForm.quality ||
      !updatedTrunkForm.length ||
      !updatedTrunkForm.diameter
    ) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const getSlideButtonOptionsTrunkQualities = (): void => {
    let filterList: SlideButtonOption[] = [];
    Object.values(TrunkQualityEnum).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: state,
        active: false
      };
      filterList.push(type);
    });
    setTrunkQualityList(filterList);
  };

  const [trunkTreeSpeciesList, setTrunkTreeSpeciesList] = useState<SlideButtonOption[]>([]);

  const getSlideButtonOptionsWithTrunkTreeSpecies = (): void => {
    let filterList: SlideButtonOption[] = [];
    (getTreeDTOSpeciesList() as TrunkSpeciesEnum[])
      .filter(
        val =>
          val != TrunkSpeciesEnum.MixedSoftwood &&
          val != TrunkSpeciesEnum.MixedHardwood &&
          val != TrunkSpeciesEnum.OtherHardWood
      )
      .map(state => {
        let type: SlideButtonOption = {
          id: state,
          text: getTextByTreeOrTrunkSpecies(state),
          active: false
        };
        filterList.push(type);
      });
    setTrunkTreeSpeciesList(filterList);
  };

  const setTrunkTreeSpecie = (selected: any): void => {
    trunkTreeSpeciesList.forEach(type => {
      type.active = type.id == selected ? true : false;
    });
    setTrunkTreeSpeciesList([...trunkTreeSpeciesList]);
  };

  const resetTrunkTreeSpeciesList = (): void => {
    trunkTreeSpeciesList.forEach(type => {
      type.active = false;
    });
    setTrunkTreeSpeciesList([...trunkTreeSpeciesList]);
  };

  const setTrunkQuality = (selected: any): void => {
    trunkQualityList.forEach(type => {
      type.active = type.id == selected ? true : false;
    });
    setTrunkQualityList([...trunkQualityList]);
  };

  const resetTrunkQuality = () => {
    trunkQualityList.forEach(type => {
      type.active = false;
    });
    setTrunkQualityList([...trunkQualityList]);
  };

  const setTreePhotoNumberFromList = (tree: TreeDTO) => {
    setTrunkForm({ ...trunkForm, treePhotoNumber: tree.photoNumber?.toString() }, updatedTrunkForm =>
      validate(tree.photoNumber?.toString() || '', 'treePhotoNumber', updatedTrunkForm)
    );
    setShowTreePhotoNumberListSelect(false);
  };

  let treeKeys: ListSubData[] = [
    {
      title: i18n.t('generics.species') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'species',
      keyId: '1species',
      isEnum: true
    },
    {
      title: i18n.t('generics.recording_type') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'recordingType',
      keyId: '2recordingType',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' },
      isEnum: true
    }
  ];

  const onFabPress = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true
    });
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        ref={scrollRef!}
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** Trunk number */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
          <UrstammInput
            testID="trunk_number"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={trunkForm.trunkNumberFAS ? trunkForm.trunkNumberFAS.toString() : ''}
            onChangeText={(text: number) => {
              setTrunkForm(
                {
                  ...trunkForm,
                  trunkNumberFAS: text,
                  name: text.toString()
                },
                updatedTrunkForm => validate(text, 'trunkNumberFAS', updatedTrunkForm)
              );
            }}
            placeholder={i18n.t('views.trunk.trunk_registration.insert_trunk_number_here')}
            textInputTitle={i18n.t('views.trunk.trunk_registration.trunk_number')}
            keyboardType={'numeric'}
            maxLength={5}
            mandatory={true}
          />

          {/**Validator */}
          {errors['trunkNumberFAS'] && errors['trunkNumberFAS'].length > 0
            ? errors['trunkNumberFAS'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Tree list */}
        {deliverySheetIsSingleRecordingType(props.deliverySheetSelected?.recordingType!) ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>
                {i18n.t('views.trunk.trunk_registration.choose_photo_number') + getMandatorySymbol(true)}
              </Text>
            </View>

            <UrstammButtonBase
              testID={'choose_logging'}
              text={
                trunkForm.treePhotoNumber
                  ? trunkForm.treePhotoNumber
                  : i18n.t('views.trunk.trunk_registration.choose_photo_number')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowTreePhotoNumberListSelect(true)}
            />

            {/**Logging Modal select */}
            {showTreePhotoNumberListSelect ? (
              <UrstammModalSelect
                testID={'modal_tree_select'}
                visible={showTreePhotoNumberListSelect}
                text={{
                  textInputTitle: i18n.t('views.trunk.trunk_registration.choose_photo_number'),
                  textStyle: UrstammStyle.textTitle
                }}
                closeModal={() => setShowTreePhotoNumberListSelect(false)}
                listJSX={
                  <UrstammList
                    elementSelected={(tree: any) => {
                      setTreePhotoNumberFromList(tree.item);
                    }}
                    showHash={false}
                    testID={'photo_number_list'}
                    textForEmptyList={i18n.t('generics.trees_not_found')}
                    titleByFunction={data => getCardTitleByRecordingType(data)}
                    list={props.treeList?.filter((tree) => tree.recordingType != 'DATE')} // filter for new date recording
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    listSubData={treeKeys}
                    filterBy={undefined}
                    filterList={true}
                    getTextForData={(data, key) =>
                      key == 'recordingType'
                        ? getTextByTreeType(data)
                        : key == 'species'
                        ? getTextByTreeOrTrunkSpecies(data)
                        : null
                    }
                    currentState={{
                      icon: state => getIconByLoggingState(state),
                      color: state => getColorByLoggingState(state),
                      text: state => getTextByLoggingState(state)
                    }}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/**Trunk quality */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammSlideButton
            testID={'button_tree_species'}
            slideButtonList={trunkQualityList}
            slideButtonTitle={i18n.t('generics.quality')}
            slideButtonRotatedTitle={i18n.t('generics.quality')}
            mandatory={true}
            buttonPressed={(selected: SlideButtonOption) => {
              setTrunkQuality(selected.id);
              setTrunkForm({ ...trunkForm, quality: selected.id }, updatedTrunkForm =>
                validate(selected.id, 'quality', updatedTrunkForm)
              );
            }}
          />
        </View>

        {/** Trunk length */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
          <UrstammInput
            testID="trunk_length"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={trunkForm.length ? trunkForm.length.toString() : ''}
            onChangeText={(text: number) => {
              setTrunkForm({ ...trunkForm, length: text }, updatedTrunkForm =>
                validate(text, 'length', updatedTrunkForm)
              );
            }}
            placeholder={i18n.t('views.trunk.trunk_registration.insert_trunk_length_here')}
            textInputTitle={i18n.t('views.trunk.trunk_registration.trunk_length')}
            keyboardType={'numeric'}
            maxLength={5}
            mandatory={true}
          />

          {/**Validator */}
          {errors['length'] && errors['length'].length > 0
            ? errors['length'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Trunk diameter */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
          <UrstammInput
            testID="trunk_diameter"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={trunkForm.diameter ? trunkForm.diameter.toString() : ''}
            onChangeText={(text: number) => {
              setTrunkForm({ ...trunkForm, diameter: text }, updatedTrunkForm =>
                validate(text, 'diameter', updatedTrunkForm)
              );
            }}
            placeholder={i18n.t('views.trunk.trunk_registration.insert_trunk_diameter_here')}
            textInputTitle={i18n.t('views.trunk.trunk_registration.trunk_diameter')}
            keyboardType={'numeric'}
            mandatory={true}
          />

          {/**Validator */}
          {errors['diameter'] && errors['diameter'].length > 0
            ? errors['diameter'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/**Species */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammSlideButton
            testID={'button_tree_species'}
            slideButtonList={trunkTreeSpeciesList}
            slideButtonTitle={i18n.t('generics.tree_species')}
            slideButtonRotatedTitle={i18n.t('generics.species')}
            mandatory={true}
            buttonPressed={(selected: SlideButtonOption) => {
              setTrunkTreeSpecie(selected.id);
              setTrunkForm({ ...trunkForm, species: selected.id }, updatedTrunkForm =>
                validate(selected.id, 'species', updatedTrunkForm)
              );
            }}
          />
        </View>

        {/**Save and continue */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_and_continue_trunk'}
            text={i18n.t('generics.save_and_continue')}
            style={mainGreenButton}
            onSubmit={() => {
              props.saveAndContinue(trunkForm), onFabPress();
            }}
            disabled={saveDisabled || rdxLoaderStatus}
          />
        </View>

        {/**Save */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_trunk'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() => props.submitTrunkForm(trunkForm)}
            disabled={saveDisabled || rdxLoaderStatus}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
