/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliverySheetUrstammStateEnumFilter
 */
export interface DeliverySheetUrstammStateEnumFilter {
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetUrstammStateEnumFilter
     */
    equals?: DeliverySheetUrstammStateEnumFilterEqualsEnum;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheetUrstammStateEnumFilter
     */
    notEquals?: DeliverySheetUrstammStateEnumFilterNotEqualsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DeliverySheetUrstammStateEnumFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliverySheetUrstammStateEnumFilter
     */
    _in?: Array<DeliverySheetUrstammStateEnumFilterInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliverySheetUrstammStateEnumFilter
     */
    notIn?: Array<DeliverySheetUrstammStateEnumFilterNotInEnum>;
}


/**
 * @export
 */
export const DeliverySheetUrstammStateEnumFilterEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetUrstammStateEnumFilterEqualsEnum = typeof DeliverySheetUrstammStateEnumFilterEqualsEnum[keyof typeof DeliverySheetUrstammStateEnumFilterEqualsEnum];

/**
 * @export
 */
export const DeliverySheetUrstammStateEnumFilterNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetUrstammStateEnumFilterNotEqualsEnum = typeof DeliverySheetUrstammStateEnumFilterNotEqualsEnum[keyof typeof DeliverySheetUrstammStateEnumFilterNotEqualsEnum];

/**
 * @export
 */
export const DeliverySheetUrstammStateEnumFilterInEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetUrstammStateEnumFilterInEnum = typeof DeliverySheetUrstammStateEnumFilterInEnum[keyof typeof DeliverySheetUrstammStateEnumFilterInEnum];

/**
 * @export
 */
export const DeliverySheetUrstammStateEnumFilterNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetUrstammStateEnumFilterNotInEnum = typeof DeliverySheetUrstammStateEnumFilterNotInEnum[keyof typeof DeliverySheetUrstammStateEnumFilterNotInEnum];


/**
 * Check if a given object implements the DeliverySheetUrstammStateEnumFilter interface.
 */
export function instanceOfDeliverySheetUrstammStateEnumFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeliverySheetUrstammStateEnumFilterFromJSON(json: any): DeliverySheetUrstammStateEnumFilter {
    return DeliverySheetUrstammStateEnumFilterFromJSONTyped(json, false);
}

export function DeliverySheetUrstammStateEnumFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliverySheetUrstammStateEnumFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
        'notEquals': !exists(json, 'notEquals') ? undefined : json['notEquals'],
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
    };
}

export function DeliverySheetUrstammStateEnumFilterToJSON(value?: DeliverySheetUrstammStateEnumFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals,
        'notEquals': value.notEquals,
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
    };
}

