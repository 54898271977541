/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Trunk,
} from '../models';
import {
    TrunkFromJSON,
    TrunkToJSON,
} from '../models';

export interface CreateTrunkCustomRequest {
    trunk: Trunk;
}

export interface GetTrunkCustomRequest {
    id: number;
}

export interface GetTrunksByDeliverySheetRequest {
    deliverySheetId: number;
}

export interface UpdateTrunkCustomRequest {
    id: number;
    trunk: Trunk;
}

/**
 * 
 */
export class CustomTrunkResourceApi extends runtime.BaseAPI {

    /**
     * Create a new Trunk connecting to given Pile.
     * Create a Trunk
     */
    async createTrunkCustomRaw(requestParameters: CreateTrunkCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Trunk>> {
        if (requestParameters.trunk === null || requestParameters.trunk === undefined) {
            throw new runtime.RequiredError('trunk','Required parameter requestParameters.trunk was null or undefined when calling createTrunkCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/trunks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkToJSON(requestParameters.trunk),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkFromJSON(jsonValue));
    }

    /**
     * Create a new Trunk connecting to given Pile.
     * Create a Trunk
     */
    async createTrunkCustom(requestParameters: CreateTrunkCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Trunk> {
        const response = await this.createTrunkCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all Trunks for Company.
     * Get a list of all Trunks for Company
     */
    async getAllMyTrunksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Trunk>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/trunks/my/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrunkFromJSON));
    }

    /**
     * Get a list of all Trunks for Company.
     * Get a list of all Trunks for Company
     */
    async getAllMyTrunks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Trunk>> {
        const response = await this.getAllMyTrunksRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Trunk identified by given id (must belong to Company of logged user).
     * Get a specific Trunk
     */
    async getTrunkCustomRaw(requestParameters: GetTrunkCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Trunk>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTrunkCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/trunks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkFromJSON(jsonValue));
    }

    /**
     * Get Trunk identified by given id (must belong to Company of logged user).
     * Get a specific Trunk
     */
    async getTrunkCustom(requestParameters: GetTrunkCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Trunk> {
        const response = await this.getTrunkCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of Trunks for DeliverySheet with given id (must belong to Company of logged user).
     * Get a list of Trunks for DeliverySheet
     */
    async getTrunksByDeliverySheetRaw(requestParameters: GetTrunksByDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Trunk>>> {
        if (requestParameters.deliverySheetId === null || requestParameters.deliverySheetId === undefined) {
            throw new runtime.RequiredError('deliverySheetId','Required parameter requestParameters.deliverySheetId was null or undefined when calling getTrunksByDeliverySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/trunks/deliverySheet/{deliverySheetId}`.replace(`{${"deliverySheetId"}}`, encodeURIComponent(String(requestParameters.deliverySheetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrunkFromJSON));
    }

    /**
     * Get a list of Trunks for DeliverySheet with given id (must belong to Company of logged user).
     * Get a list of Trunks for DeliverySheet
     */
    async getTrunksByDeliverySheet(requestParameters: GetTrunksByDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Trunk>> {
        const response = await this.getTrunksByDeliverySheetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update Trunk identified by given id (must belong to Pile of logged user).
     * Partial update a Trunk
     */
    async updateTrunkCustomRaw(requestParameters: UpdateTrunkCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Trunk>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTrunkCustom.');
        }

        if (requestParameters.trunk === null || requestParameters.trunk === undefined) {
            throw new runtime.RequiredError('trunk','Required parameter requestParameters.trunk was null or undefined when calling updateTrunkCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/trunks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkToJSON(requestParameters.trunk),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkFromJSON(jsonValue));
    }

    /**
     * Partial update Trunk identified by given id (must belong to Pile of logged user).
     * Partial update a Trunk
     */
    async updateTrunkCustom(requestParameters: UpdateTrunkCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Trunk> {
        const response = await this.updateTrunkCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
