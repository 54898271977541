/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Plot,
} from '../models';
import {
    PlotFromJSON,
    PlotToJSON,
} from '../models';

export interface CreatePlotRequest {
    plot: Plot;
}

export interface DeletePlotRequest {
    id: number;
}

export interface GetPlotRequest {
    id: number;
}

export interface PartialUpdatePlotRequest {
    id: number;
    plot: Plot;
}

export interface UpdatePlotRequest {
    id: number;
    plot: Plot;
}

/**
 * 
 */
export class PlotResourceApi extends runtime.BaseAPI {

    /**
     */
    async createPlotRaw(requestParameters: CreatePlotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Plot>> {
        if (requestParameters.plot === null || requestParameters.plot === undefined) {
            throw new runtime.RequiredError('plot','Required parameter requestParameters.plot was null or undefined when calling createPlot.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/plots`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlotToJSON(requestParameters.plot),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlotFromJSON(jsonValue));
    }

    /**
     */
    async createPlot(requestParameters: CreatePlotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Plot> {
        const response = await this.createPlotRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePlotRaw(requestParameters: DeletePlotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePlot.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/plots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePlot(requestParameters: DeletePlotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePlotRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllPlotsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Plot>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/plots`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlotFromJSON));
    }

    /**
     */
    async getAllPlots(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Plot>> {
        const response = await this.getAllPlotsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPlotRaw(requestParameters: GetPlotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Plot>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPlot.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/plots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlotFromJSON(jsonValue));
    }

    /**
     */
    async getPlot(requestParameters: GetPlotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Plot> {
        const response = await this.getPlotRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdatePlotRaw(requestParameters: PartialUpdatePlotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Plot>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePlot.');
        }

        if (requestParameters.plot === null || requestParameters.plot === undefined) {
            throw new runtime.RequiredError('plot','Required parameter requestParameters.plot was null or undefined when calling partialUpdatePlot.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/plots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PlotToJSON(requestParameters.plot),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlotFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdatePlot(requestParameters: PartialUpdatePlotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Plot> {
        const response = await this.partialUpdatePlotRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePlotRaw(requestParameters: UpdatePlotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Plot>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePlot.');
        }

        if (requestParameters.plot === null || requestParameters.plot === undefined) {
            throw new runtime.RequiredError('plot','Required parameter requestParameters.plot was null or undefined when calling updatePlot.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/plots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PlotToJSON(requestParameters.plot),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlotFromJSON(jsonValue));
    }

    /**
     */
    async updatePlot(requestParameters: UpdatePlotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Plot> {
        const response = await this.updatePlotRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
