/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Trunk } from './Trunk';
import {
    TrunkFromJSON,
    TrunkFromJSONTyped,
    TrunkToJSON,
} from './Trunk';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface TrunkState
 */
export interface TrunkState {
    /**
     * 
     * @type {number}
     * @memberof TrunkState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TrunkState
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof TrunkState
     */
    state: TrunkStateStateEnum;
    /**
     * 
     * @type {Trunk}
     * @memberof TrunkState
     */
    trunk?: Trunk;
    /**
     * 
     * @type {User}
     * @memberof TrunkState
     */
    author?: User;
}


/**
 * @export
 */
export const TrunkStateStateEnum = {
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID'
} as const;
export type TrunkStateStateEnum = typeof TrunkStateStateEnum[keyof typeof TrunkStateStateEnum];


/**
 * Check if a given object implements the TrunkState interface.
 */
export function instanceOfTrunkState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function TrunkStateFromJSON(json: any): TrunkState {
    return TrunkStateFromJSONTyped(json, false);
}

export function TrunkStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrunkState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'state': json['state'],
        'trunk': !exists(json, 'trunk') ? undefined : TrunkFromJSON(json['trunk']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
    };
}

export function TrunkStateToJSON(value?: TrunkState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'state': value.state,
        'trunk': TrunkToJSON(value.trunk),
        'author': UserToJSON(value.author),
    };
}

