/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProcessorBatch,
} from '../models';
import {
    ProcessorBatchFromJSON,
    ProcessorBatchToJSON,
} from '../models';

export interface CountProcessorBatchesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    uniqueIdentifierContains?: string;
    uniqueIdentifierDoesNotContain?: string;
    uniqueIdentifierEquals?: string;
    uniqueIdentifierNotEquals?: string;
    uniqueIdentifierSpecified?: boolean;
    uniqueIdentifierIn?: Array<string>;
    uniqueIdentifierNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    recordingTypeEquals?: CountProcessorBatchesRecordingTypeEqualsEnum;
    recordingTypeNotEquals?: CountProcessorBatchesRecordingTypeNotEqualsEnum;
    recordingTypeSpecified?: boolean;
    recordingTypeIn?: Array<CountProcessorBatchesRecordingTypeInEnum>;
    recordingTypeNotIn?: Array<CountProcessorBatchesRecordingTypeNotInEnum>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    currentStateEquals?: CountProcessorBatchesCurrentStateEqualsEnum;
    currentStateNotEquals?: CountProcessorBatchesCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<CountProcessorBatchesCurrentStateInEnum>;
    currentStateNotIn?: Array<CountProcessorBatchesCurrentStateNotInEnum>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    processorIdGreaterThan?: number;
    processorIdLessThan?: number;
    processorIdGreaterThanOrEqual?: number;
    processorIdLessThanOrEqual?: number;
    processorIdEquals?: number;
    processorIdNotEquals?: number;
    processorIdSpecified?: boolean;
    processorIdIn?: Array<number>;
    processorIdNotIn?: Array<number>;
    referenceBatchIdGreaterThan?: number;
    referenceBatchIdLessThan?: number;
    referenceBatchIdGreaterThanOrEqual?: number;
    referenceBatchIdLessThanOrEqual?: number;
    referenceBatchIdEquals?: number;
    referenceBatchIdNotEquals?: number;
    referenceBatchIdSpecified?: boolean;
    referenceBatchIdIn?: Array<number>;
    referenceBatchIdNotIn?: Array<number>;
    sourceProductIdGreaterThan?: number;
    sourceProductIdLessThan?: number;
    sourceProductIdGreaterThanOrEqual?: number;
    sourceProductIdLessThanOrEqual?: number;
    sourceProductIdEquals?: number;
    sourceProductIdNotEquals?: number;
    sourceProductIdSpecified?: boolean;
    sourceProductIdIn?: Array<number>;
    sourceProductIdNotIn?: Array<number>;
    processorBatchStateIdGreaterThan?: number;
    processorBatchStateIdLessThan?: number;
    processorBatchStateIdGreaterThanOrEqual?: number;
    processorBatchStateIdLessThanOrEqual?: number;
    processorBatchStateIdEquals?: number;
    processorBatchStateIdNotEquals?: number;
    processorBatchStateIdSpecified?: boolean;
    processorBatchStateIdIn?: Array<number>;
    processorBatchStateIdNotIn?: Array<number>;
    ownerShippingNoteIdGreaterThan?: number;
    ownerShippingNoteIdLessThan?: number;
    ownerShippingNoteIdGreaterThanOrEqual?: number;
    ownerShippingNoteIdLessThanOrEqual?: number;
    ownerShippingNoteIdEquals?: number;
    ownerShippingNoteIdNotEquals?: number;
    ownerShippingNoteIdSpecified?: boolean;
    ownerShippingNoteIdIn?: Array<number>;
    ownerShippingNoteIdNotIn?: Array<number>;
    productIdGreaterThan?: number;
    productIdLessThan?: number;
    productIdGreaterThanOrEqual?: number;
    productIdLessThanOrEqual?: number;
    productIdEquals?: number;
    productIdNotEquals?: number;
    productIdSpecified?: boolean;
    productIdIn?: Array<number>;
    productIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateProcessorBatchRequest {
    processorBatch: ProcessorBatch;
}

export interface DeleteProcessorBatchRequest {
    id: number;
}

export interface GetAllProcessorBatchesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    uniqueIdentifierContains?: string;
    uniqueIdentifierDoesNotContain?: string;
    uniqueIdentifierEquals?: string;
    uniqueIdentifierNotEquals?: string;
    uniqueIdentifierSpecified?: boolean;
    uniqueIdentifierIn?: Array<string>;
    uniqueIdentifierNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    recordingTypeEquals?: GetAllProcessorBatchesRecordingTypeEqualsEnum;
    recordingTypeNotEquals?: GetAllProcessorBatchesRecordingTypeNotEqualsEnum;
    recordingTypeSpecified?: boolean;
    recordingTypeIn?: Array<GetAllProcessorBatchesRecordingTypeInEnum>;
    recordingTypeNotIn?: Array<GetAllProcessorBatchesRecordingTypeNotInEnum>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    currentStateEquals?: GetAllProcessorBatchesCurrentStateEqualsEnum;
    currentStateNotEquals?: GetAllProcessorBatchesCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<GetAllProcessorBatchesCurrentStateInEnum>;
    currentStateNotIn?: Array<GetAllProcessorBatchesCurrentStateNotInEnum>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    processorIdGreaterThan?: number;
    processorIdLessThan?: number;
    processorIdGreaterThanOrEqual?: number;
    processorIdLessThanOrEqual?: number;
    processorIdEquals?: number;
    processorIdNotEquals?: number;
    processorIdSpecified?: boolean;
    processorIdIn?: Array<number>;
    processorIdNotIn?: Array<number>;
    referenceBatchIdGreaterThan?: number;
    referenceBatchIdLessThan?: number;
    referenceBatchIdGreaterThanOrEqual?: number;
    referenceBatchIdLessThanOrEqual?: number;
    referenceBatchIdEquals?: number;
    referenceBatchIdNotEquals?: number;
    referenceBatchIdSpecified?: boolean;
    referenceBatchIdIn?: Array<number>;
    referenceBatchIdNotIn?: Array<number>;
    sourceProductIdGreaterThan?: number;
    sourceProductIdLessThan?: number;
    sourceProductIdGreaterThanOrEqual?: number;
    sourceProductIdLessThanOrEqual?: number;
    sourceProductIdEquals?: number;
    sourceProductIdNotEquals?: number;
    sourceProductIdSpecified?: boolean;
    sourceProductIdIn?: Array<number>;
    sourceProductIdNotIn?: Array<number>;
    processorBatchStateIdGreaterThan?: number;
    processorBatchStateIdLessThan?: number;
    processorBatchStateIdGreaterThanOrEqual?: number;
    processorBatchStateIdLessThanOrEqual?: number;
    processorBatchStateIdEquals?: number;
    processorBatchStateIdNotEquals?: number;
    processorBatchStateIdSpecified?: boolean;
    processorBatchStateIdIn?: Array<number>;
    processorBatchStateIdNotIn?: Array<number>;
    ownerShippingNoteIdGreaterThan?: number;
    ownerShippingNoteIdLessThan?: number;
    ownerShippingNoteIdGreaterThanOrEqual?: number;
    ownerShippingNoteIdLessThanOrEqual?: number;
    ownerShippingNoteIdEquals?: number;
    ownerShippingNoteIdNotEquals?: number;
    ownerShippingNoteIdSpecified?: boolean;
    ownerShippingNoteIdIn?: Array<number>;
    ownerShippingNoteIdNotIn?: Array<number>;
    productIdGreaterThan?: number;
    productIdLessThan?: number;
    productIdGreaterThanOrEqual?: number;
    productIdLessThanOrEqual?: number;
    productIdEquals?: number;
    productIdNotEquals?: number;
    productIdSpecified?: boolean;
    productIdIn?: Array<number>;
    productIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetProcessorBatchRequest {
    id: number;
}

export interface PartialUpdateProcessorBatchRequest {
    id: number;
    processorBatch: ProcessorBatch;
}

export interface UpdateProcessorBatchRequest {
    id: number;
    processorBatch: ProcessorBatch;
}

/**
 * 
 */
export class ProcessorBatchResourceApi extends runtime.BaseAPI {

    /**
     */
    async countProcessorBatchesRaw(requestParameters: CountProcessorBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.uniqueIdentifierContains !== undefined) {
            queryParameters['uniqueIdentifier.contains'] = requestParameters.uniqueIdentifierContains;
        }

        if (requestParameters.uniqueIdentifierDoesNotContain !== undefined) {
            queryParameters['uniqueIdentifier.doesNotContain'] = requestParameters.uniqueIdentifierDoesNotContain;
        }

        if (requestParameters.uniqueIdentifierEquals !== undefined) {
            queryParameters['uniqueIdentifier.equals'] = requestParameters.uniqueIdentifierEquals;
        }

        if (requestParameters.uniqueIdentifierNotEquals !== undefined) {
            queryParameters['uniqueIdentifier.notEquals'] = requestParameters.uniqueIdentifierNotEquals;
        }

        if (requestParameters.uniqueIdentifierSpecified !== undefined) {
            queryParameters['uniqueIdentifier.specified'] = requestParameters.uniqueIdentifierSpecified;
        }

        if (requestParameters.uniqueIdentifierIn) {
            queryParameters['uniqueIdentifier.in'] = requestParameters.uniqueIdentifierIn;
        }

        if (requestParameters.uniqueIdentifierNotIn) {
            queryParameters['uniqueIdentifier.notIn'] = requestParameters.uniqueIdentifierNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.recordingTypeEquals !== undefined) {
            queryParameters['recordingType.equals'] = requestParameters.recordingTypeEquals;
        }

        if (requestParameters.recordingTypeNotEquals !== undefined) {
            queryParameters['recordingType.notEquals'] = requestParameters.recordingTypeNotEquals;
        }

        if (requestParameters.recordingTypeSpecified !== undefined) {
            queryParameters['recordingType.specified'] = requestParameters.recordingTypeSpecified;
        }

        if (requestParameters.recordingTypeIn) {
            queryParameters['recordingType.in'] = requestParameters.recordingTypeIn;
        }

        if (requestParameters.recordingTypeNotIn) {
            queryParameters['recordingType.notIn'] = requestParameters.recordingTypeNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.processorIdGreaterThan !== undefined) {
            queryParameters['processorId.greaterThan'] = requestParameters.processorIdGreaterThan;
        }

        if (requestParameters.processorIdLessThan !== undefined) {
            queryParameters['processorId.lessThan'] = requestParameters.processorIdLessThan;
        }

        if (requestParameters.processorIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorId.greaterThanOrEqual'] = requestParameters.processorIdGreaterThanOrEqual;
        }

        if (requestParameters.processorIdLessThanOrEqual !== undefined) {
            queryParameters['processorId.lessThanOrEqual'] = requestParameters.processorIdLessThanOrEqual;
        }

        if (requestParameters.processorIdEquals !== undefined) {
            queryParameters['processorId.equals'] = requestParameters.processorIdEquals;
        }

        if (requestParameters.processorIdNotEquals !== undefined) {
            queryParameters['processorId.notEquals'] = requestParameters.processorIdNotEquals;
        }

        if (requestParameters.processorIdSpecified !== undefined) {
            queryParameters['processorId.specified'] = requestParameters.processorIdSpecified;
        }

        if (requestParameters.processorIdIn) {
            queryParameters['processorId.in'] = requestParameters.processorIdIn;
        }

        if (requestParameters.processorIdNotIn) {
            queryParameters['processorId.notIn'] = requestParameters.processorIdNotIn;
        }

        if (requestParameters.referenceBatchIdGreaterThan !== undefined) {
            queryParameters['referenceBatchId.greaterThan'] = requestParameters.referenceBatchIdGreaterThan;
        }

        if (requestParameters.referenceBatchIdLessThan !== undefined) {
            queryParameters['referenceBatchId.lessThan'] = requestParameters.referenceBatchIdLessThan;
        }

        if (requestParameters.referenceBatchIdGreaterThanOrEqual !== undefined) {
            queryParameters['referenceBatchId.greaterThanOrEqual'] = requestParameters.referenceBatchIdGreaterThanOrEqual;
        }

        if (requestParameters.referenceBatchIdLessThanOrEqual !== undefined) {
            queryParameters['referenceBatchId.lessThanOrEqual'] = requestParameters.referenceBatchIdLessThanOrEqual;
        }

        if (requestParameters.referenceBatchIdEquals !== undefined) {
            queryParameters['referenceBatchId.equals'] = requestParameters.referenceBatchIdEquals;
        }

        if (requestParameters.referenceBatchIdNotEquals !== undefined) {
            queryParameters['referenceBatchId.notEquals'] = requestParameters.referenceBatchIdNotEquals;
        }

        if (requestParameters.referenceBatchIdSpecified !== undefined) {
            queryParameters['referenceBatchId.specified'] = requestParameters.referenceBatchIdSpecified;
        }

        if (requestParameters.referenceBatchIdIn) {
            queryParameters['referenceBatchId.in'] = requestParameters.referenceBatchIdIn;
        }

        if (requestParameters.referenceBatchIdNotIn) {
            queryParameters['referenceBatchId.notIn'] = requestParameters.referenceBatchIdNotIn;
        }

        if (requestParameters.sourceProductIdGreaterThan !== undefined) {
            queryParameters['sourceProductId.greaterThan'] = requestParameters.sourceProductIdGreaterThan;
        }

        if (requestParameters.sourceProductIdLessThan !== undefined) {
            queryParameters['sourceProductId.lessThan'] = requestParameters.sourceProductIdLessThan;
        }

        if (requestParameters.sourceProductIdGreaterThanOrEqual !== undefined) {
            queryParameters['sourceProductId.greaterThanOrEqual'] = requestParameters.sourceProductIdGreaterThanOrEqual;
        }

        if (requestParameters.sourceProductIdLessThanOrEqual !== undefined) {
            queryParameters['sourceProductId.lessThanOrEqual'] = requestParameters.sourceProductIdLessThanOrEqual;
        }

        if (requestParameters.sourceProductIdEquals !== undefined) {
            queryParameters['sourceProductId.equals'] = requestParameters.sourceProductIdEquals;
        }

        if (requestParameters.sourceProductIdNotEquals !== undefined) {
            queryParameters['sourceProductId.notEquals'] = requestParameters.sourceProductIdNotEquals;
        }

        if (requestParameters.sourceProductIdSpecified !== undefined) {
            queryParameters['sourceProductId.specified'] = requestParameters.sourceProductIdSpecified;
        }

        if (requestParameters.sourceProductIdIn) {
            queryParameters['sourceProductId.in'] = requestParameters.sourceProductIdIn;
        }

        if (requestParameters.sourceProductIdNotIn) {
            queryParameters['sourceProductId.notIn'] = requestParameters.sourceProductIdNotIn;
        }

        if (requestParameters.processorBatchStateIdGreaterThan !== undefined) {
            queryParameters['processorBatchStateId.greaterThan'] = requestParameters.processorBatchStateIdGreaterThan;
        }

        if (requestParameters.processorBatchStateIdLessThan !== undefined) {
            queryParameters['processorBatchStateId.lessThan'] = requestParameters.processorBatchStateIdLessThan;
        }

        if (requestParameters.processorBatchStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorBatchStateId.greaterThanOrEqual'] = requestParameters.processorBatchStateIdGreaterThanOrEqual;
        }

        if (requestParameters.processorBatchStateIdLessThanOrEqual !== undefined) {
            queryParameters['processorBatchStateId.lessThanOrEqual'] = requestParameters.processorBatchStateIdLessThanOrEqual;
        }

        if (requestParameters.processorBatchStateIdEquals !== undefined) {
            queryParameters['processorBatchStateId.equals'] = requestParameters.processorBatchStateIdEquals;
        }

        if (requestParameters.processorBatchStateIdNotEquals !== undefined) {
            queryParameters['processorBatchStateId.notEquals'] = requestParameters.processorBatchStateIdNotEquals;
        }

        if (requestParameters.processorBatchStateIdSpecified !== undefined) {
            queryParameters['processorBatchStateId.specified'] = requestParameters.processorBatchStateIdSpecified;
        }

        if (requestParameters.processorBatchStateIdIn) {
            queryParameters['processorBatchStateId.in'] = requestParameters.processorBatchStateIdIn;
        }

        if (requestParameters.processorBatchStateIdNotIn) {
            queryParameters['processorBatchStateId.notIn'] = requestParameters.processorBatchStateIdNotIn;
        }

        if (requestParameters.ownerShippingNoteIdGreaterThan !== undefined) {
            queryParameters['ownerShippingNoteId.greaterThan'] = requestParameters.ownerShippingNoteIdGreaterThan;
        }

        if (requestParameters.ownerShippingNoteIdLessThan !== undefined) {
            queryParameters['ownerShippingNoteId.lessThan'] = requestParameters.ownerShippingNoteIdLessThan;
        }

        if (requestParameters.ownerShippingNoteIdGreaterThanOrEqual !== undefined) {
            queryParameters['ownerShippingNoteId.greaterThanOrEqual'] = requestParameters.ownerShippingNoteIdGreaterThanOrEqual;
        }

        if (requestParameters.ownerShippingNoteIdLessThanOrEqual !== undefined) {
            queryParameters['ownerShippingNoteId.lessThanOrEqual'] = requestParameters.ownerShippingNoteIdLessThanOrEqual;
        }

        if (requestParameters.ownerShippingNoteIdEquals !== undefined) {
            queryParameters['ownerShippingNoteId.equals'] = requestParameters.ownerShippingNoteIdEquals;
        }

        if (requestParameters.ownerShippingNoteIdNotEquals !== undefined) {
            queryParameters['ownerShippingNoteId.notEquals'] = requestParameters.ownerShippingNoteIdNotEquals;
        }

        if (requestParameters.ownerShippingNoteIdSpecified !== undefined) {
            queryParameters['ownerShippingNoteId.specified'] = requestParameters.ownerShippingNoteIdSpecified;
        }

        if (requestParameters.ownerShippingNoteIdIn) {
            queryParameters['ownerShippingNoteId.in'] = requestParameters.ownerShippingNoteIdIn;
        }

        if (requestParameters.ownerShippingNoteIdNotIn) {
            queryParameters['ownerShippingNoteId.notIn'] = requestParameters.ownerShippingNoteIdNotIn;
        }

        if (requestParameters.productIdGreaterThan !== undefined) {
            queryParameters['productId.greaterThan'] = requestParameters.productIdGreaterThan;
        }

        if (requestParameters.productIdLessThan !== undefined) {
            queryParameters['productId.lessThan'] = requestParameters.productIdLessThan;
        }

        if (requestParameters.productIdGreaterThanOrEqual !== undefined) {
            queryParameters['productId.greaterThanOrEqual'] = requestParameters.productIdGreaterThanOrEqual;
        }

        if (requestParameters.productIdLessThanOrEqual !== undefined) {
            queryParameters['productId.lessThanOrEqual'] = requestParameters.productIdLessThanOrEqual;
        }

        if (requestParameters.productIdEquals !== undefined) {
            queryParameters['productId.equals'] = requestParameters.productIdEquals;
        }

        if (requestParameters.productIdNotEquals !== undefined) {
            queryParameters['productId.notEquals'] = requestParameters.productIdNotEquals;
        }

        if (requestParameters.productIdSpecified !== undefined) {
            queryParameters['productId.specified'] = requestParameters.productIdSpecified;
        }

        if (requestParameters.productIdIn) {
            queryParameters['productId.in'] = requestParameters.productIdIn;
        }

        if (requestParameters.productIdNotIn) {
            queryParameters['productId.notIn'] = requestParameters.productIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-batches/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countProcessorBatches(requestParameters: CountProcessorBatchesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countProcessorBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProcessorBatchRaw(requestParameters: CreateProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorBatch>> {
        if (requestParameters.processorBatch === null || requestParameters.processorBatch === undefined) {
            throw new runtime.RequiredError('processorBatch','Required parameter requestParameters.processorBatch was null or undefined when calling createProcessorBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-batches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorBatchToJSON(requestParameters.processorBatch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorBatchFromJSON(jsonValue));
    }

    /**
     */
    async createProcessorBatch(requestParameters: CreateProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorBatch> {
        const response = await this.createProcessorBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProcessorBatchRaw(requestParameters: DeleteProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProcessorBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProcessorBatch(requestParameters: DeleteProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProcessorBatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProcessorBatchesRaw(requestParameters: GetAllProcessorBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProcessorBatch>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.uniqueIdentifierContains !== undefined) {
            queryParameters['uniqueIdentifier.contains'] = requestParameters.uniqueIdentifierContains;
        }

        if (requestParameters.uniqueIdentifierDoesNotContain !== undefined) {
            queryParameters['uniqueIdentifier.doesNotContain'] = requestParameters.uniqueIdentifierDoesNotContain;
        }

        if (requestParameters.uniqueIdentifierEquals !== undefined) {
            queryParameters['uniqueIdentifier.equals'] = requestParameters.uniqueIdentifierEquals;
        }

        if (requestParameters.uniqueIdentifierNotEquals !== undefined) {
            queryParameters['uniqueIdentifier.notEquals'] = requestParameters.uniqueIdentifierNotEquals;
        }

        if (requestParameters.uniqueIdentifierSpecified !== undefined) {
            queryParameters['uniqueIdentifier.specified'] = requestParameters.uniqueIdentifierSpecified;
        }

        if (requestParameters.uniqueIdentifierIn) {
            queryParameters['uniqueIdentifier.in'] = requestParameters.uniqueIdentifierIn;
        }

        if (requestParameters.uniqueIdentifierNotIn) {
            queryParameters['uniqueIdentifier.notIn'] = requestParameters.uniqueIdentifierNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.recordingTypeEquals !== undefined) {
            queryParameters['recordingType.equals'] = requestParameters.recordingTypeEquals;
        }

        if (requestParameters.recordingTypeNotEquals !== undefined) {
            queryParameters['recordingType.notEquals'] = requestParameters.recordingTypeNotEquals;
        }

        if (requestParameters.recordingTypeSpecified !== undefined) {
            queryParameters['recordingType.specified'] = requestParameters.recordingTypeSpecified;
        }

        if (requestParameters.recordingTypeIn) {
            queryParameters['recordingType.in'] = requestParameters.recordingTypeIn;
        }

        if (requestParameters.recordingTypeNotIn) {
            queryParameters['recordingType.notIn'] = requestParameters.recordingTypeNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.processorIdGreaterThan !== undefined) {
            queryParameters['processorId.greaterThan'] = requestParameters.processorIdGreaterThan;
        }

        if (requestParameters.processorIdLessThan !== undefined) {
            queryParameters['processorId.lessThan'] = requestParameters.processorIdLessThan;
        }

        if (requestParameters.processorIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorId.greaterThanOrEqual'] = requestParameters.processorIdGreaterThanOrEqual;
        }

        if (requestParameters.processorIdLessThanOrEqual !== undefined) {
            queryParameters['processorId.lessThanOrEqual'] = requestParameters.processorIdLessThanOrEqual;
        }

        if (requestParameters.processorIdEquals !== undefined) {
            queryParameters['processorId.equals'] = requestParameters.processorIdEquals;
        }

        if (requestParameters.processorIdNotEquals !== undefined) {
            queryParameters['processorId.notEquals'] = requestParameters.processorIdNotEquals;
        }

        if (requestParameters.processorIdSpecified !== undefined) {
            queryParameters['processorId.specified'] = requestParameters.processorIdSpecified;
        }

        if (requestParameters.processorIdIn) {
            queryParameters['processorId.in'] = requestParameters.processorIdIn;
        }

        if (requestParameters.processorIdNotIn) {
            queryParameters['processorId.notIn'] = requestParameters.processorIdNotIn;
        }

        if (requestParameters.referenceBatchIdGreaterThan !== undefined) {
            queryParameters['referenceBatchId.greaterThan'] = requestParameters.referenceBatchIdGreaterThan;
        }

        if (requestParameters.referenceBatchIdLessThan !== undefined) {
            queryParameters['referenceBatchId.lessThan'] = requestParameters.referenceBatchIdLessThan;
        }

        if (requestParameters.referenceBatchIdGreaterThanOrEqual !== undefined) {
            queryParameters['referenceBatchId.greaterThanOrEqual'] = requestParameters.referenceBatchIdGreaterThanOrEqual;
        }

        if (requestParameters.referenceBatchIdLessThanOrEqual !== undefined) {
            queryParameters['referenceBatchId.lessThanOrEqual'] = requestParameters.referenceBatchIdLessThanOrEqual;
        }

        if (requestParameters.referenceBatchIdEquals !== undefined) {
            queryParameters['referenceBatchId.equals'] = requestParameters.referenceBatchIdEquals;
        }

        if (requestParameters.referenceBatchIdNotEquals !== undefined) {
            queryParameters['referenceBatchId.notEquals'] = requestParameters.referenceBatchIdNotEquals;
        }

        if (requestParameters.referenceBatchIdSpecified !== undefined) {
            queryParameters['referenceBatchId.specified'] = requestParameters.referenceBatchIdSpecified;
        }

        if (requestParameters.referenceBatchIdIn) {
            queryParameters['referenceBatchId.in'] = requestParameters.referenceBatchIdIn;
        }

        if (requestParameters.referenceBatchIdNotIn) {
            queryParameters['referenceBatchId.notIn'] = requestParameters.referenceBatchIdNotIn;
        }

        if (requestParameters.sourceProductIdGreaterThan !== undefined) {
            queryParameters['sourceProductId.greaterThan'] = requestParameters.sourceProductIdGreaterThan;
        }

        if (requestParameters.sourceProductIdLessThan !== undefined) {
            queryParameters['sourceProductId.lessThan'] = requestParameters.sourceProductIdLessThan;
        }

        if (requestParameters.sourceProductIdGreaterThanOrEqual !== undefined) {
            queryParameters['sourceProductId.greaterThanOrEqual'] = requestParameters.sourceProductIdGreaterThanOrEqual;
        }

        if (requestParameters.sourceProductIdLessThanOrEqual !== undefined) {
            queryParameters['sourceProductId.lessThanOrEqual'] = requestParameters.sourceProductIdLessThanOrEqual;
        }

        if (requestParameters.sourceProductIdEquals !== undefined) {
            queryParameters['sourceProductId.equals'] = requestParameters.sourceProductIdEquals;
        }

        if (requestParameters.sourceProductIdNotEquals !== undefined) {
            queryParameters['sourceProductId.notEquals'] = requestParameters.sourceProductIdNotEquals;
        }

        if (requestParameters.sourceProductIdSpecified !== undefined) {
            queryParameters['sourceProductId.specified'] = requestParameters.sourceProductIdSpecified;
        }

        if (requestParameters.sourceProductIdIn) {
            queryParameters['sourceProductId.in'] = requestParameters.sourceProductIdIn;
        }

        if (requestParameters.sourceProductIdNotIn) {
            queryParameters['sourceProductId.notIn'] = requestParameters.sourceProductIdNotIn;
        }

        if (requestParameters.processorBatchStateIdGreaterThan !== undefined) {
            queryParameters['processorBatchStateId.greaterThan'] = requestParameters.processorBatchStateIdGreaterThan;
        }

        if (requestParameters.processorBatchStateIdLessThan !== undefined) {
            queryParameters['processorBatchStateId.lessThan'] = requestParameters.processorBatchStateIdLessThan;
        }

        if (requestParameters.processorBatchStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorBatchStateId.greaterThanOrEqual'] = requestParameters.processorBatchStateIdGreaterThanOrEqual;
        }

        if (requestParameters.processorBatchStateIdLessThanOrEqual !== undefined) {
            queryParameters['processorBatchStateId.lessThanOrEqual'] = requestParameters.processorBatchStateIdLessThanOrEqual;
        }

        if (requestParameters.processorBatchStateIdEquals !== undefined) {
            queryParameters['processorBatchStateId.equals'] = requestParameters.processorBatchStateIdEquals;
        }

        if (requestParameters.processorBatchStateIdNotEquals !== undefined) {
            queryParameters['processorBatchStateId.notEquals'] = requestParameters.processorBatchStateIdNotEquals;
        }

        if (requestParameters.processorBatchStateIdSpecified !== undefined) {
            queryParameters['processorBatchStateId.specified'] = requestParameters.processorBatchStateIdSpecified;
        }

        if (requestParameters.processorBatchStateIdIn) {
            queryParameters['processorBatchStateId.in'] = requestParameters.processorBatchStateIdIn;
        }

        if (requestParameters.processorBatchStateIdNotIn) {
            queryParameters['processorBatchStateId.notIn'] = requestParameters.processorBatchStateIdNotIn;
        }

        if (requestParameters.ownerShippingNoteIdGreaterThan !== undefined) {
            queryParameters['ownerShippingNoteId.greaterThan'] = requestParameters.ownerShippingNoteIdGreaterThan;
        }

        if (requestParameters.ownerShippingNoteIdLessThan !== undefined) {
            queryParameters['ownerShippingNoteId.lessThan'] = requestParameters.ownerShippingNoteIdLessThan;
        }

        if (requestParameters.ownerShippingNoteIdGreaterThanOrEqual !== undefined) {
            queryParameters['ownerShippingNoteId.greaterThanOrEqual'] = requestParameters.ownerShippingNoteIdGreaterThanOrEqual;
        }

        if (requestParameters.ownerShippingNoteIdLessThanOrEqual !== undefined) {
            queryParameters['ownerShippingNoteId.lessThanOrEqual'] = requestParameters.ownerShippingNoteIdLessThanOrEqual;
        }

        if (requestParameters.ownerShippingNoteIdEquals !== undefined) {
            queryParameters['ownerShippingNoteId.equals'] = requestParameters.ownerShippingNoteIdEquals;
        }

        if (requestParameters.ownerShippingNoteIdNotEquals !== undefined) {
            queryParameters['ownerShippingNoteId.notEquals'] = requestParameters.ownerShippingNoteIdNotEquals;
        }

        if (requestParameters.ownerShippingNoteIdSpecified !== undefined) {
            queryParameters['ownerShippingNoteId.specified'] = requestParameters.ownerShippingNoteIdSpecified;
        }

        if (requestParameters.ownerShippingNoteIdIn) {
            queryParameters['ownerShippingNoteId.in'] = requestParameters.ownerShippingNoteIdIn;
        }

        if (requestParameters.ownerShippingNoteIdNotIn) {
            queryParameters['ownerShippingNoteId.notIn'] = requestParameters.ownerShippingNoteIdNotIn;
        }

        if (requestParameters.productIdGreaterThan !== undefined) {
            queryParameters['productId.greaterThan'] = requestParameters.productIdGreaterThan;
        }

        if (requestParameters.productIdLessThan !== undefined) {
            queryParameters['productId.lessThan'] = requestParameters.productIdLessThan;
        }

        if (requestParameters.productIdGreaterThanOrEqual !== undefined) {
            queryParameters['productId.greaterThanOrEqual'] = requestParameters.productIdGreaterThanOrEqual;
        }

        if (requestParameters.productIdLessThanOrEqual !== undefined) {
            queryParameters['productId.lessThanOrEqual'] = requestParameters.productIdLessThanOrEqual;
        }

        if (requestParameters.productIdEquals !== undefined) {
            queryParameters['productId.equals'] = requestParameters.productIdEquals;
        }

        if (requestParameters.productIdNotEquals !== undefined) {
            queryParameters['productId.notEquals'] = requestParameters.productIdNotEquals;
        }

        if (requestParameters.productIdSpecified !== undefined) {
            queryParameters['productId.specified'] = requestParameters.productIdSpecified;
        }

        if (requestParameters.productIdIn) {
            queryParameters['productId.in'] = requestParameters.productIdIn;
        }

        if (requestParameters.productIdNotIn) {
            queryParameters['productId.notIn'] = requestParameters.productIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-batches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProcessorBatchFromJSON));
    }

    /**
     */
    async getAllProcessorBatches(requestParameters: GetAllProcessorBatchesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProcessorBatch>> {
        const response = await this.getAllProcessorBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProcessorBatchRaw(requestParameters: GetProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorBatch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProcessorBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/processor-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorBatchFromJSON(jsonValue));
    }

    /**
     */
    async getProcessorBatch(requestParameters: GetProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorBatch> {
        const response = await this.getProcessorBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateProcessorBatchRaw(requestParameters: PartialUpdateProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorBatch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateProcessorBatch.');
        }

        if (requestParameters.processorBatch === null || requestParameters.processorBatch === undefined) {
            throw new runtime.RequiredError('processorBatch','Required parameter requestParameters.processorBatch was null or undefined when calling partialUpdateProcessorBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorBatchToJSON(requestParameters.processorBatch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorBatchFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateProcessorBatch(requestParameters: PartialUpdateProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorBatch> {
        const response = await this.partialUpdateProcessorBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProcessorBatchRaw(requestParameters: UpdateProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorBatch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProcessorBatch.');
        }

        if (requestParameters.processorBatch === null || requestParameters.processorBatch === undefined) {
            throw new runtime.RequiredError('processorBatch','Required parameter requestParameters.processorBatch was null or undefined when calling updateProcessorBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/processor-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessorBatchToJSON(requestParameters.processorBatch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorBatchFromJSON(jsonValue));
    }

    /**
     */
    async updateProcessorBatch(requestParameters: UpdateProcessorBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorBatch> {
        const response = await this.updateProcessorBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CountProcessorBatchesRecordingTypeEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountProcessorBatchesRecordingTypeEqualsEnum = typeof CountProcessorBatchesRecordingTypeEqualsEnum[keyof typeof CountProcessorBatchesRecordingTypeEqualsEnum];
/**
 * @export
 */
export const CountProcessorBatchesRecordingTypeNotEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountProcessorBatchesRecordingTypeNotEqualsEnum = typeof CountProcessorBatchesRecordingTypeNotEqualsEnum[keyof typeof CountProcessorBatchesRecordingTypeNotEqualsEnum];
/**
 * @export
 */
export const CountProcessorBatchesRecordingTypeInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountProcessorBatchesRecordingTypeInEnum = typeof CountProcessorBatchesRecordingTypeInEnum[keyof typeof CountProcessorBatchesRecordingTypeInEnum];
/**
 * @export
 */
export const CountProcessorBatchesRecordingTypeNotInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type CountProcessorBatchesRecordingTypeNotInEnum = typeof CountProcessorBatchesRecordingTypeNotInEnum[keyof typeof CountProcessorBatchesRecordingTypeNotInEnum];
/**
 * @export
 */
export const CountProcessorBatchesCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountProcessorBatchesCurrentStateEqualsEnum = typeof CountProcessorBatchesCurrentStateEqualsEnum[keyof typeof CountProcessorBatchesCurrentStateEqualsEnum];
/**
 * @export
 */
export const CountProcessorBatchesCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountProcessorBatchesCurrentStateNotEqualsEnum = typeof CountProcessorBatchesCurrentStateNotEqualsEnum[keyof typeof CountProcessorBatchesCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const CountProcessorBatchesCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountProcessorBatchesCurrentStateInEnum = typeof CountProcessorBatchesCurrentStateInEnum[keyof typeof CountProcessorBatchesCurrentStateInEnum];
/**
 * @export
 */
export const CountProcessorBatchesCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountProcessorBatchesCurrentStateNotInEnum = typeof CountProcessorBatchesCurrentStateNotInEnum[keyof typeof CountProcessorBatchesCurrentStateNotInEnum];
/**
 * @export
 */
export const GetAllProcessorBatchesRecordingTypeEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllProcessorBatchesRecordingTypeEqualsEnum = typeof GetAllProcessorBatchesRecordingTypeEqualsEnum[keyof typeof GetAllProcessorBatchesRecordingTypeEqualsEnum];
/**
 * @export
 */
export const GetAllProcessorBatchesRecordingTypeNotEqualsEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllProcessorBatchesRecordingTypeNotEqualsEnum = typeof GetAllProcessorBatchesRecordingTypeNotEqualsEnum[keyof typeof GetAllProcessorBatchesRecordingTypeNotEqualsEnum];
/**
 * @export
 */
export const GetAllProcessorBatchesRecordingTypeInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllProcessorBatchesRecordingTypeInEnum = typeof GetAllProcessorBatchesRecordingTypeInEnum[keyof typeof GetAllProcessorBatchesRecordingTypeInEnum];
/**
 * @export
 */
export const GetAllProcessorBatchesRecordingTypeNotInEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE'
} as const;
export type GetAllProcessorBatchesRecordingTypeNotInEnum = typeof GetAllProcessorBatchesRecordingTypeNotInEnum[keyof typeof GetAllProcessorBatchesRecordingTypeNotInEnum];
/**
 * @export
 */
export const GetAllProcessorBatchesCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllProcessorBatchesCurrentStateEqualsEnum = typeof GetAllProcessorBatchesCurrentStateEqualsEnum[keyof typeof GetAllProcessorBatchesCurrentStateEqualsEnum];
/**
 * @export
 */
export const GetAllProcessorBatchesCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllProcessorBatchesCurrentStateNotEqualsEnum = typeof GetAllProcessorBatchesCurrentStateNotEqualsEnum[keyof typeof GetAllProcessorBatchesCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const GetAllProcessorBatchesCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllProcessorBatchesCurrentStateInEnum = typeof GetAllProcessorBatchesCurrentStateInEnum[keyof typeof GetAllProcessorBatchesCurrentStateInEnum];
/**
 * @export
 */
export const GetAllProcessorBatchesCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllProcessorBatchesCurrentStateNotInEnum = typeof GetAllProcessorBatchesCurrentStateNotInEnum[keyof typeof GetAllProcessorBatchesCurrentStateNotInEnum];
