/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProcessorBatch } from './ProcessorBatch';
import {
    ProcessorBatchFromJSON,
    ProcessorBatchFromJSONTyped,
    ProcessorBatchToJSON,
} from './ProcessorBatch';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ProcessorBatchState
 */
export interface ProcessorBatchState {
    /**
     * 
     * @type {number}
     * @memberof ProcessorBatchState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof ProcessorBatchState
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatchState
     */
    state: ProcessorBatchStateStateEnum;
    /**
     * 
     * @type {ProcessorBatch}
     * @memberof ProcessorBatchState
     */
    processorBatch?: ProcessorBatch;
    /**
     * 
     * @type {User}
     * @memberof ProcessorBatchState
     */
    author?: User;
}


/**
 * @export
 */
export const ProcessorBatchStateStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProcessorBatchStateStateEnum = typeof ProcessorBatchStateStateEnum[keyof typeof ProcessorBatchStateStateEnum];


/**
 * Check if a given object implements the ProcessorBatchState interface.
 */
export function instanceOfProcessorBatchState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function ProcessorBatchStateFromJSON(json: any): ProcessorBatchState {
    return ProcessorBatchStateFromJSONTyped(json, false);
}

export function ProcessorBatchStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessorBatchState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'state': json['state'],
        'processorBatch': !exists(json, 'processorBatch') ? undefined : ProcessorBatchFromJSON(json['processorBatch']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
    };
}

export function ProcessorBatchStateToJSON(value?: ProcessorBatchState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'state': value.state,
        'processorBatch': ProcessorBatchToJSON(value.processorBatch),
        'author': UserToJSON(value.author),
    };
}

