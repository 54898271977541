import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import { i18n } from '@i18n/i18n';
import { Logging, TreeDTO, TreeDTORecordingTypeEnum, TreeDTOSpeciesEnum } from '@services/apis/generated';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Image, Modal, ScrollView, StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Layout from '../../../config/Layout';
import { getTextByTreeOrTrunkSpecies, getTextByTreeType } from '../../../utils/classes/UrstammUtilityCurrentState';
import { readTreePhoto } from '../../../utils/classes/UrstammUtilityFile';
import { getTreeDTOSpeciesList, urstammUtilOpenMaps } from '../../../utils/classes/UrstammUtilityFunctions';
import { buildTreePhotoUrl } from '../../../utils/classes/UrstammUtilityImage';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';

export default function TreeDetailsView(props: {
  navigation: any;
  treeSelected: TreeDTO;
  modalVisible: boolean;
  loggingSelected: Logging;
  updateTree: (treeForm: any) => void;
}) {
  const [treeSelected, setTreeSelected] = useState<TreeDTO>();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [photoPreview, setPhotoPreview] = useState<string>('');
  const [treeSpeciesList, setTreeSpeciesList] = useState<SlideButtonOption[]>([]);
  const [showPreview, setShowPreview] = useState<any>(false);

  const [treeForm, setTreeForm] = useStateWithCallbackLazy<any>({
    comment: '',
    species: undefined
  });

  useEffect(() => {
    if (props.treeSelected && props.treeSelected.id) {
      setTreeSelected(props.treeSelected);

      setTreeForm(
        {
          comment: props.treeSelected.comment,
          species: props.treeSelected.species ? props.treeSelected.species : undefined
        },
        updatedForm => {
          if (props.treeSelected.species)
            setTreeSpecie({
              id: props.treeSelected.species,
              text: getTextByTreeOrTrunkSpecies(props.treeSelected.species!),
              active: false
            });
        }
      );
      if (props.treeSelected.forestPhotoFilepath) {
        buildTreePhotoUrl(props.treeSelected).then(res => {
          setPhotoPreview(res);
        });
      } else {
        readTreePhoto(props.treeSelected.id).then(photo => {
          setPhotoPreview('data:image/png;base64,' + photo);
        });
      }
      activeTreeRecordingType(
        props.treeSelected?.recordingType == TreeDTORecordingTypeEnum.Single
          ? TreeDTORecordingTypeEnum.Single
          : TreeDTORecordingTypeEnum.Multiple
      );
    }
  }, [props.treeSelected]);

  useEffect(() => {
    getSlideButtonOptionsTreeSpecies();
  }, []);

  const getSlideButtonOptionsTreeSpecies = (): void => {
    let filterList: SlideButtonOption[] = [];
    (getTreeDTOSpeciesList() as TreeDTOSpeciesEnum[])
      .filter(val => val != TreeDTOSpeciesEnum.MixedSoftwood && val != TreeDTOSpeciesEnum.MixedHardwood)
      .map(state => {
        let type: SlideButtonOption = {
          id: state,
          text: getTextByTreeOrTrunkSpecies(state),
          active: false
        };
        filterList.push(type);
      });
    setTreeSpeciesList(filterList);
  };

  const setTreeSpecie = (selected: SlideButtonOption): void => {
    treeSpeciesList.forEach(type => {
      type.active = type.id == selected.id ? true : false;
    });
    setTreeSpeciesList([...treeSpeciesList]);
  };

  const [treeRecordingType, setTreeRecordingType] = useState<ButtonSelect[]>([
    {
      text: i18n.t('views.tree.single_tree'),
      enum: TreeDTORecordingTypeEnum.Single,
      active: false
    },
    {
      text: i18n.t('views.tree.multiple_tree'),
      enum: TreeDTORecordingTypeEnum.Multiple,
      active: false
    }
  ]);

  const activeTreeRecordingType = (treeRecordingTypeEnum: TreeDTORecordingTypeEnum) => {
    treeRecordingType.forEach(type => {
      type.active = type.enum == treeRecordingTypeEnum ? true : false;
    });

    setTreeRecordingType([...treeRecordingType]);
  };

  return (
    <View style={[UrstammStyle.flex1]}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', paddingBottom: 144 }]}>
        {/**Specie and creation date */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <View style={[UrstammStyleContainer.row1SpaceBetween]}>
            <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.wrap]}>
              <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.species') + ': '}</Text>
              <Text style={UrstammStyle.dataStyle}>
                {treeSelected?.species ? getTextByTreeOrTrunkSpecies(treeSelected.species) : '--'}
              </Text>
            </View>

            {treeSelected?.creationDate ? (
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.wrap]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.cutting_time') + ' '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {moment(treeSelected.creationDate).format('DD/MM/yyyy HH:mm')}
                </Text>
              </View>
            ) : null}
          </View>
          {/* <View style={[UrstammStyleContainer.row1SpaceBetween]}>
            <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexStartX]}>
              <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.species') + ': '}</Text>
              <Text style={UrstammStyle.dataStyle}>
                {treeSelected?.species ? getTextByTreeOrTrunkSpecies(treeSelected.species) : '--'}
              </Text>
            </View>

            {treeSelected?.creationDate ? (
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexEndX]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.cutting_time') + ' '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {moment(treeSelected.creationDate).format('DD/MM/yyyy HH:mm')}
                </Text>
              </View>
            ) : null}
          </View> */}
        </View>

        {/**Recording type */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <View style={[UrstammStyle.flex1, UrstammStyleContainer.row1SpaceBetween]}>
            {treeSelected?.recordingType ? (
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexStartX]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.recording_type') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {treeSelected.recordingType ? getTextByTreeType(treeSelected.recordingType) : '--'}
                </Text>
              </View>
            ) : null}

            {photoPreview ? (
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexEndX]}>
                {photoPreview ? (
                  <TouchableOpacity style={styles.imagePreview} onPress={() => setShowPreview(true)}>
                    <Image source={{ uri: photoPreview }} style={{ width: 52, height: 52 }} />
                  </TouchableOpacity>
                ) : null}
              </View>
            ) : null}
          </View>
        </View>

        {/**Localization */}
        {treeSelected?.latitude && treeSelected?.longitude ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexStartX, UrstammStyle.flexCenterY]}>
              <TouchableOpacity onPress={() => urstammUtilOpenMaps(treeSelected)}>
                <UrstammIcon name={'map-marked-alt'} size={20} color={ColorTheme.mainGreen} type={'FontAwesome5'} />
              </TouchableOpacity>

              <View style={[UrstammStyle.flexColumn, { paddingLeft: 16 }]}>
                {/**Latitude */}
                <View style={[UrstammStyle.flexRow]}>
                  <Text style={[UrstammStyle.titleStyle]}>{i18n.t('generics.latitude') + ': '}</Text>
                  <Text style={[UrstammStyle.dataStyle]}>{treeSelected.latitude}</Text>
                </View>

                {/**Longitude */}
                <View style={[UrstammStyle.flexRow]}>
                  <Text style={[UrstammStyle.titleStyle]}>{i18n.t('generics.longitude') + ': '}</Text>
                  <Text style={[UrstammStyle.dataStyle]}>{treeSelected.longitude}</Text>
                </View>
              </View>
            </View>
          </View>
        ) : null}

        <View style={UrstammStyle.divider} />

        {/**Species */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammSlideButton
            testID={'button_tree_species'}
            slideButtonList={treeSpeciesList}
            slideButtonTitle={i18n.t('generics.tree_species')}
            slideButtonRotatedTitle={i18n.t('generics.species')}
            buttonPressed={(selected: SlideButtonOption) => {
              setTreeSpecie(selected);
              setTreeForm(
                prev => ({ ...prev, species: selected.id }),
                () => {}
              );
            }}
          />
        </View>

        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/** Comment */}
          <UrstammInput
            testID="tree_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) =>
              setTreeForm(
                prev => ({ ...prev, comment: text }),
                () => {}
              )
            }
            value={treeForm?.comment ? treeForm.comment : ''}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/**Save */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_tree'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() => props.updateTree(treeForm)}
            disabled={false}
          />
        </View>

        {/* Preview modal */}
        {showPreview && (
          <View style={UrstammStyleModal.centeredView}>
            <Modal animationType="slide" transparent={true} visible={showPreview}>
              <View style={UrstammStyleModal.centeredView}>
                <View style={UrstammStyleModal.modalView}>
                  <View style={[styles.cameraTopButton]}>
                    {/**Close Preview */}
                    <TouchableOpacity
                      style={[styles.top_button, UrstammStyle.baseShadow, { alignSelf: 'flex-end' }]}
                      onPress={() => setShowPreview(false)}>
                      <View style={[styles.iconCloseContainer]}>
                        <UrstammIcon name={'close'} size={24} color={ColorTheme.mediumGrey} />
                      </View>
                    </TouchableOpacity>
                  </View>

                  {photoPreview.length > 0 && (
                    <Image
                      source={{ uri: photoPreview }}
                      style={{
                        width: Layout.window.width * 0.9,
                        height: Layout.window.height * 0.7
                      }}
                    />
                  )}
                </View>
              </View>
            </Modal>
          </View>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  imagePreview: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  cameraTopButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-end'
  },
  top_button: {
    backgroundColor: ColorTheme.lightGrey,
    borderColor: ColorTheme.mainGreen,
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
    borderWidth: 1,
    zIndex: 2,
    elevation: 2,
    maxWidth: 40,
    alignSelf: 'flex-end'
  },
  iconCloseContainer: {
    minWidth: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  }
});
