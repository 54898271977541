/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Packet } from './Packet';
import {
    PacketFromJSON,
    PacketFromJSONTyped,
    PacketToJSON,
} from './Packet';

/**
 * 
 * @export
 * @interface EntityWithFileDTOPacket
 */
export interface EntityWithFileDTOPacket {
    /**
     * 
     * @type {Packet}
     * @memberof EntityWithFileDTOPacket
     */
    entity?: Packet;
    /**
     * 
     * @type {string}
     * @memberof EntityWithFileDTOPacket
     */
    base64File?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityWithFileDTOPacket
     */
    deletePrevious?: boolean;
}

/**
 * Check if a given object implements the EntityWithFileDTOPacket interface.
 */
export function instanceOfEntityWithFileDTOPacket(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EntityWithFileDTOPacketFromJSON(json: any): EntityWithFileDTOPacket {
    return EntityWithFileDTOPacketFromJSONTyped(json, false);
}

export function EntityWithFileDTOPacketFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityWithFileDTOPacket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': !exists(json, 'entity') ? undefined : PacketFromJSON(json['entity']),
        'base64File': !exists(json, 'base64File') ? undefined : json['base64File'],
        'deletePrevious': !exists(json, 'deletePrevious') ? undefined : json['deletePrevious'],
    };
}

export function EntityWithFileDTOPacketToJSON(value?: EntityWithFileDTOPacket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': PacketToJSON(value.entity),
        'base64File': value.base64File,
        'deletePrevious': value.deletePrevious,
    };
}

