/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProductState,
} from '../models';
import {
    ProductStateFromJSON,
    ProductStateToJSON,
} from '../models';

export interface CreateProductStateRequest {
    productState: ProductState;
}

export interface DeleteProductStateRequest {
    id: number;
}

export interface GetProductStateRequest {
    id: number;
}

export interface PartialUpdateProductStateRequest {
    id: number;
    productState: ProductState;
}

export interface UpdateProductStateRequest {
    id: number;
    productState: ProductState;
}

/**
 * 
 */
export class ProductStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createProductStateRaw(requestParameters: CreateProductStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductState>> {
        if (requestParameters.productState === null || requestParameters.productState === undefined) {
            throw new runtime.RequiredError('productState','Required parameter requestParameters.productState was null or undefined when calling createProductState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/product-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductStateToJSON(requestParameters.productState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductStateFromJSON(jsonValue));
    }

    /**
     */
    async createProductState(requestParameters: CreateProductStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductState> {
        const response = await this.createProductStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProductStateRaw(requestParameters: DeleteProductStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProductState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/product-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProductState(requestParameters: DeleteProductStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProductStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProductStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/product-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductStateFromJSON));
    }

    /**
     */
    async getAllProductStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductState>> {
        const response = await this.getAllProductStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductStateRaw(requestParameters: GetProductStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProductState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/product-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductStateFromJSON(jsonValue));
    }

    /**
     */
    async getProductState(requestParameters: GetProductStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductState> {
        const response = await this.getProductStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateProductStateRaw(requestParameters: PartialUpdateProductStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateProductState.');
        }

        if (requestParameters.productState === null || requestParameters.productState === undefined) {
            throw new runtime.RequiredError('productState','Required parameter requestParameters.productState was null or undefined when calling partialUpdateProductState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/product-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProductStateToJSON(requestParameters.productState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateProductState(requestParameters: PartialUpdateProductStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductState> {
        const response = await this.partialUpdateProductStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProductStateRaw(requestParameters: UpdateProductStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProductState.');
        }

        if (requestParameters.productState === null || requestParameters.productState === undefined) {
            throw new runtime.RequiredError('productState','Required parameter requestParameters.productState was null or undefined when calling updateProductState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/product-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductStateToJSON(requestParameters.productState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductStateFromJSON(jsonValue));
    }

    /**
     */
    async updateProductState(requestParameters: UpdateProductStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductState> {
        const response = await this.updateProductStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
