import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { CompanySurface } from '@services/apis/generated';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface CompanySurfaceState {
  list: CompanySurface[];
  completeList: CompanySurface[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: CompanySurfaceState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const companySurface = createSlice({
  name: 'companySurfaceList',
  initialState,
  reducers: {
    setCompanySurfaceList: (state, action) => {
      let companySurface = { ...state }.list;
      let companySurfaceCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        pushElementOrReplaceById(companySurface, element);
        pushElementOrReplaceById(companySurfaceCompleteList, element);
      });

      state.list = companySurface;
      state.completeList = companySurfaceCompleteList;
    },
    setCompanySurfaceTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setCompanySurfaceListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setCompanySurfacePage: (state, action) => {
      state.page = action.payload;
    },
    resetCompanySurfaceList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearCompanySurfaceList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setCompanySurfaceList,
  setCompanySurfaceListSort,
  resetCompanySurfaceList,
  setCompanySurfaceTotalPages,
  setCompanySurfacePage,
  clearCompanySurfaceList
} = companySurface.actions;

export default companySurface.reducer;
