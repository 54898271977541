/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeliverySheetState,
} from '../models';
import {
    DeliverySheetStateFromJSON,
    DeliverySheetStateToJSON,
} from '../models';

export interface CreateDeliverySheetStateRequest {
    deliverySheetState: DeliverySheetState;
}

export interface DeleteDeliverySheetStateRequest {
    id: number;
}

export interface GetDeliverySheetStateRequest {
    id: number;
}

export interface PartialUpdateDeliverySheetStateRequest {
    id: number;
    deliverySheetState: DeliverySheetState;
}

export interface UpdateDeliverySheetStateRequest {
    id: number;
    deliverySheetState: DeliverySheetState;
}

/**
 * 
 */
export class DeliverySheetStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createDeliverySheetStateRaw(requestParameters: CreateDeliverySheetStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetState>> {
        if (requestParameters.deliverySheetState === null || requestParameters.deliverySheetState === undefined) {
            throw new runtime.RequiredError('deliverySheetState','Required parameter requestParameters.deliverySheetState was null or undefined when calling createDeliverySheetState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheet-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetStateToJSON(requestParameters.deliverySheetState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetStateFromJSON(jsonValue));
    }

    /**
     */
    async createDeliverySheetState(requestParameters: CreateDeliverySheetStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetState> {
        const response = await this.createDeliverySheetStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteDeliverySheetStateRaw(requestParameters: DeleteDeliverySheetStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDeliverySheetState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheet-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDeliverySheetState(requestParameters: DeleteDeliverySheetStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDeliverySheetStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllDeliverySheetStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeliverySheetState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheet-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliverySheetStateFromJSON));
    }

    /**
     */
    async getAllDeliverySheetStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeliverySheetState>> {
        const response = await this.getAllDeliverySheetStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getDeliverySheetStateRaw(requestParameters: GetDeliverySheetStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDeliverySheetState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/delivery-sheet-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetStateFromJSON(jsonValue));
    }

    /**
     */
    async getDeliverySheetState(requestParameters: GetDeliverySheetStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetState> {
        const response = await this.getDeliverySheetStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateDeliverySheetStateRaw(requestParameters: PartialUpdateDeliverySheetStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateDeliverySheetState.');
        }

        if (requestParameters.deliverySheetState === null || requestParameters.deliverySheetState === undefined) {
            throw new runtime.RequiredError('deliverySheetState','Required parameter requestParameters.deliverySheetState was null or undefined when calling partialUpdateDeliverySheetState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheet-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetStateToJSON(requestParameters.deliverySheetState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateDeliverySheetState(requestParameters: PartialUpdateDeliverySheetStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetState> {
        const response = await this.partialUpdateDeliverySheetStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateDeliverySheetStateRaw(requestParameters: UpdateDeliverySheetStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDeliverySheetState.');
        }

        if (requestParameters.deliverySheetState === null || requestParameters.deliverySheetState === undefined) {
            throw new runtime.RequiredError('deliverySheetState','Required parameter requestParameters.deliverySheetState was null or undefined when calling updateDeliverySheetState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delivery-sheet-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetStateToJSON(requestParameters.deliverySheetState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetStateFromJSON(jsonValue));
    }

    /**
     */
    async updateDeliverySheetState(requestParameters: UpdateDeliverySheetStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetState> {
        const response = await this.updateDeliverySheetStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
