/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Pile,
} from '../models';
import {
    PileFromJSON,
    PileToJSON,
} from '../models';

export interface CreatePileRequest {
    pile: Pile;
}

export interface DeletePileRequest {
    id: number;
}

export interface GetPileRequest {
    id: number;
}

export interface PartialUpdatePileRequest {
    id: number;
    pile: Pile;
}

export interface UpdatePileRequest {
    id: number;
    pile: Pile;
}

/**
 * 
 */
export class PileResourceApi extends runtime.BaseAPI {

    /**
     */
    async createPileRaw(requestParameters: CreatePileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pile>> {
        if (requestParameters.pile === null || requestParameters.pile === undefined) {
            throw new runtime.RequiredError('pile','Required parameter requestParameters.pile was null or undefined when calling createPile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/piles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PileToJSON(requestParameters.pile),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PileFromJSON(jsonValue));
    }

    /**
     */
    async createPile(requestParameters: CreatePileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pile> {
        const response = await this.createPileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePileRaw(requestParameters: DeletePileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/piles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePile(requestParameters: DeletePileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePileRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllPilesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Pile>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/piles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PileFromJSON));
    }

    /**
     */
    async getAllPiles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Pile>> {
        const response = await this.getAllPilesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPileRaw(requestParameters: GetPileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/piles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PileFromJSON(jsonValue));
    }

    /**
     */
    async getPile(requestParameters: GetPileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pile> {
        const response = await this.getPileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdatePileRaw(requestParameters: PartialUpdatePileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePile.');
        }

        if (requestParameters.pile === null || requestParameters.pile === undefined) {
            throw new runtime.RequiredError('pile','Required parameter requestParameters.pile was null or undefined when calling partialUpdatePile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/piles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PileToJSON(requestParameters.pile),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PileFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdatePile(requestParameters: PartialUpdatePileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pile> {
        const response = await this.partialUpdatePileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePileRaw(requestParameters: UpdatePileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePile.');
        }

        if (requestParameters.pile === null || requestParameters.pile === undefined) {
            throw new runtime.RequiredError('pile','Required parameter requestParameters.pile was null or undefined when calling updatePile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/piles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PileToJSON(requestParameters.pile),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PileFromJSON(jsonValue));
    }

    /**
     */
    async updatePile(requestParameters: UpdatePileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pile> {
        const response = await this.updatePileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
