import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import LoggingDetailsView from '@components/views/logging/LoggingDetailsView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { NetworkHelper } from '@helpers/NetworkHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { RootState } from '@redux/store';
import { customLoggingResourceApi } from '@services/apis/ApiConfiguration';
import { Logging, LoggingCurrentStateEnum } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { resetLoggingList } from '../../redux/features/logging/loggingSlice';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function LoggingDetailsContainer({ navigation, route }: RootStackScreenProps<'LoggingDetails'>) {
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const [loggingSelected, setLoggingSelected] = useState<Logging>();
  const [mediaType, setMediaType] = useState<any>();

  const dispatch = useDispatch();

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('generics.logging'),
      icon: undefined
    }
  };

  useEffect(() => {
    if (route.params?.logging) {
      setLoggingSelected(route.params?.logging.item);
    }
  }, [route.params?.logging]);

  const navigateToLoggingRegistration = (): void => {
    UrstammNavigationHelper.navigateToLoggingRegistration(navigation);
  };

  const navigateToPlots = (): void => {
    UrstammNavigationHelper.navigateToPlotList(navigation, loggingSelected!);
  };

  const navigateToPiles = (): void => {
    UrstammNavigationHelper.navigateToPileList(navigation, loggingSelected!);
  };

  const navigateToDeliverySheets = (): void => {
    UrstammNavigationHelper.navigateToDeliverySheetList(navigation, loggingSelected, true);
  };

  const navigateToTrees = (): void => {
    UrstammNavigationHelper.navigateToTreeList(navigation, loggingSelected!, true);
  };

  const closeLogging = async (confirmed: boolean): Promise<void> => {
    if (confirmed) {
      if (loggingSelected) {
        let networkOk = await NetworkHelper.isNetworkOk(false);
        if (!networkOk) return;
        dispatch(changeLoaderStatus(true));

        let logging: Logging = {
          id: loggingSelected.id,
          name: loggingSelected.name,
          currentState: LoggingCurrentStateEnum.Closed
        };

        customLoggingResourceApi
          .updateLoggingCustom({
            id: loggingSelected?.id!,
            entityWithFileDTOLogging: { entity: logging }
          })
          .then(res => {
            dispatch(changeLoaderStatus(false));

            if (res) {
              dispatch(resetLoggingList());
              UrstammNavigationHelper.navigateToLoggingList(navigation, true);
            }
          })
          .catch(async error => {
            dispatch(changeLoaderStatus(false));
            let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
            let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
            AlertHelper.showSimpleAlert('Error', errorJson);
          });
      }
    }
  };

  const updateLogging = async (form: any) => {
    // let networkOk = await NetworkHelper.isNetworkOk(false);
    // if (!networkOk) return;

    dispatch(changeLoaderStatus(true));

    let permit = form.permit == undefined || form.permit.length == 0 ? undefined : form.permit;
    form.logging.name = form.logging.name && form.logging.name.length > 0 ? form.logging.name : loggingSelected?.name;
    form.logging.id = loggingSelected?.id;
    form.logging.currentState = loggingSelected?.currentState;
    form.logging.permitMediaType = mediaType;
    let loggingPhotoBase64File = form.base64File.length == 0 ? undefined : form.base64File;

    customLoggingResourceApi
      .updateLoggingCustom(rdxOfflineSyncList, dispatch, addOfflineObjectSync, {
        id: loggingSelected?.id!,
        entityWithFileDTOLogging: {
          entity: form.logging!,
          base64File: permit,
          loggingPhotoBase64File: loggingPhotoBase64File
        }
      })
      .then(res => {
        dispatch(changeLoaderStatus(false));

        if (res) {
          dispatch(resetLoggingList());
          UrstammNavigationHelper.navigateToLoggingList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomRight={navigateToLoggingRegistration}
      title={
        <UrstammTitle
          testID={'logging_title'}
          text={i18n.t('generics.logging')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      headerDetails={[{ title: i18n.t('generics.name'), value: loggingSelected?.name! }]}
      view={
        <LoggingDetailsView
          navigation={navigation}
          loggingSelected={loggingSelected!}
          updateLogging={(form: any) => updateLogging(form)}
          navigateToPlots={navigateToPlots}
          navigateToPiles={navigateToPiles}
          navigateToDeliverySheets={navigateToDeliverySheets}
          navigateToTrees={navigateToTrees}
          closeLogging={(confirmed: boolean) => closeLogging(confirmed)}
          mediaType={(type: string) => setMediaType(type)}
        />
      }
    />
  );
}
