/**
 * Auth
 */
export const RouteSignup = 'Signup';
export const RouteLogin = 'Login';
export const RouteResetPassword = 'ResetPassword';
export const RouteChangePassword = 'ChangePassword';
/**
 * Logging
 */
export const RouteLoggingList = 'LoggingList';
export const RouteLoggingDetails = 'LoggingDetails';
export const RouteLoggingRegistration = 'LoggingRegistration';
/**
 * Pile
 */
export const RoutePileList = 'PileList';
export const RoutePileDetails = 'PileDetails';
export const RoutePileRegistration = 'PileRegistration';
/**
 * Tree
 */
export const RouteTreeList = 'TreeList';
export const RouteTreeDetails = 'TreeDetails';
export const RoutTreeRegistration = 'TreeRegistration';
/**
 * Plot
 */
export const RoutePlotList = 'PlotList';
export const RoutePlotDetails = 'PlotDetails';
export const RoutePlotRegistration = 'PlotRegistration';
/**
 * Delivery Sheet
 */
export const RouteDeliverySheetList = 'DeliverySheetList';
export const RouteDeliverySheetDetails = 'DeliverySheetDetails';
export const RouteDeliverySheetRegistration = 'DeliverySheetRegistration';
/**
 * Trunk
 */
export const RouteTrunkList = 'TrunkList';
export const RouteTrunkDetails = 'TrunkDetails';
export const RouteTrunkRegistration = 'TrunkRegistration';
/**
 * Batch
 */
export const RouteBatchList = 'BatchList';
export const RouteBatchDetails = 'BatchDetails';
export const RouteBatchRegistration = 'BatchRegistration';

/**
 * Shipping Notes
 */
 export const RouteShippingNoteList = 'ShippingNoteList';
 export const RouteShippingNoteDetails = 'ShippingNoteDetails';
 export const RouteShippingNoteRegistration = 'ShippingNoteRegistration';
/**
 * Packet
 */
export const RoutePacketList = 'PacketList';
export const RoutePacketDetails = 'PacketDetails';
export const RoutePacketRegistration = 'PacketRegistration';

/**
 * Processor Shipping Notes
 */
export const RouteIncomingProcessorShippingNoteList = 'IncomingProcessorShippingNoteList';
export const RouteOutgoingProcessorShippingNoteList = 'OutgoingProcessorShippingNoteList';
export const RouteProcessorShippingNoteDetails = 'ProcessorShippingNoteDetails';
export const RouteProcessorShippingNoteRegistration = 'ProcessorShippingNoteRegistration';

/**
 * ProcessorBatch
 */
 export const RouteProcessorBatchList = 'ProcessorBatchList';
 export const RouteProcessorBatchDetails = 'ProcessorBatchDetails';
 export const RouteProcessorBatchRegistration = 'ProcessorBatchRegistration';

 /**
 * Product
 */
export const RouteProductList = 'ProductList';
export const RouteProductDetails = 'ProductDetails';
export const RouteProductRegistration = 'ProductRegistration';

/**
 * Project
 * */
export const RouteProjectList = 'ProjectList';
export const RouteProjectDetails = 'ProjectDetails';
export const RouteProjectRegistration = 'ProjectRegistration';

/**
 * Support
 */
export const RouteSupport = 'Support';
