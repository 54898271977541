import { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { createSlice } from '@reduxjs/toolkit';

export interface PacketFilterState {
  value: SlideButtonOption[];
}

const initialState: PacketFilterState = {
  value: []
};

export const packetFilter = createSlice({
  name: 'packetFilter',
  initialState,
  reducers: {
    setPacketFilter: (state, action) => {
      state.value = action.payload;
    },
    setPacketFilterList: (state, action) => {
      state.value = action.payload;
    },
    selectPacketFilter: (state, action) => {
      let selected = action.payload;
      let filterList = { ...state }.value;
      if (filterList) {
        for (let elem of filterList) {
          elem.active = elem.id == selected.id;
        }
        state.value = filterList;
      }
    },
    reset: state => {
      initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setPacketFilter, setPacketFilterList, selectPacketFilter, reset } = packetFilter.actions;

export default packetFilter.reducer;
