/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Plot,
  PlotDTO,
} from '../models';
import {
    PlotFromJSON,
    PlotToJSON,
    PlotDTOFromJSON,
    PlotDTOToJSON,
} from '../models';

export interface CreatePlotCustomRequest {
    plotDTO: PlotDTO;
}

export interface DeletePlotCustomRequest {
    id: number;
}

export interface GetPlotsByLoggingRequest {
    loggingId: number;
}

export interface PartialUpdatePlotCustomRequest {
    id: number;
    plotDTO: PlotDTO;
}

/**
 * 
 */
export class CustomPlotResourceApi extends runtime.BaseAPI {

    /**
     */
    async createPlotCustomRaw(requestParameters: CreatePlotCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Plot>> {
        if (requestParameters.plotDTO === null || requestParameters.plotDTO === undefined) {
            throw new runtime.RequiredError('plotDTO','Required parameter requestParameters.plotDTO was null or undefined when calling createPlotCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/plots`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlotDTOToJSON(requestParameters.plotDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlotFromJSON(jsonValue));
    }

    /**
     */
    async createPlotCustom(requestParameters: CreatePlotCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Plot> {
        const response = await this.createPlotCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePlotCustomRaw(requestParameters: DeletePlotCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePlotCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/plots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePlotCustom(requestParameters: DeletePlotCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePlotCustomRaw(requestParameters, initOverrides);
    }

    /**
     * Get a list of all Plots for my company.
     * Get a list of all Plots for my Company
     */
    async getAllMyPlotsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PlotDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/plots/my/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlotDTOFromJSON));
    }

    /**
     * Get a list of all Plots for my company.
     * Get a list of all Plots for my Company
     */
    async getAllMyPlots(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PlotDTO>> {
        const response = await this.getAllMyPlotsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a list of Plots for Logging with given id (must belong to Company of logged user).
     * Get a list of Plots for Logging
     */
    async getPlotsByLoggingRaw(requestParameters: GetPlotsByLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PlotDTO>>> {
        if (requestParameters.loggingId === null || requestParameters.loggingId === undefined) {
            throw new runtime.RequiredError('loggingId','Required parameter requestParameters.loggingId was null or undefined when calling getPlotsByLogging.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/plots/logging/{loggingId}`.replace(`{${"loggingId"}}`, encodeURIComponent(String(requestParameters.loggingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlotDTOFromJSON));
    }

    /**
     * Get a list of Plots for Logging with given id (must belong to Company of logged user).
     * Get a list of Plots for Logging
     */
    async getPlotsByLogging(requestParameters: GetPlotsByLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PlotDTO>> {
        const response = await this.getPlotsByLoggingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdatePlotCustomRaw(requestParameters: PartialUpdatePlotCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Plot>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePlotCustom.');
        }

        if (requestParameters.plotDTO === null || requestParameters.plotDTO === undefined) {
            throw new runtime.RequiredError('plotDTO','Required parameter requestParameters.plotDTO was null or undefined when calling partialUpdatePlotCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/plots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PlotDTOToJSON(requestParameters.plotDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlotFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdatePlotCustom(requestParameters: PartialUpdatePlotCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Plot> {
        const response = await this.partialUpdatePlotCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
