import React from 'react';
import { TouchableHighlight, TouchableOpacity } from 'react-native';
import Svg, { Path } from 'react-native-svg';

interface UrstammImageMenu {
  width?: number;
  height?: number;
  showText?: boolean;
}

const UrstammImageMenu = (props: UrstammImageMenu) => {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 55 23" fill="none">
      <Path
        d="M3.5 19.7H52.1M3.5 3.5H52.1"
        stroke="white"
        strokeWidth="5.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default UrstammImageMenu;
