/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProcessorShippingNoteStateUpdateDTO
 */
export interface ProcessorShippingNoteStateUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteStateUpdateDTO
     */
    updatedProcessorShippingNoteStateEnum?: ProcessorShippingNoteStateUpdateDTOUpdatedProcessorShippingNoteStateEnumEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteStateUpdateDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteStateUpdateDTO
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteStateUpdateDTO
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteStateUpdateDTO
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteStateUpdateDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteStateUpdateDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorShippingNoteStateUpdateDTO
     */
    zipCode?: string;
}


/**
 * @export
 */
export const ProcessorShippingNoteStateUpdateDTOUpdatedProcessorShippingNoteStateEnumEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type ProcessorShippingNoteStateUpdateDTOUpdatedProcessorShippingNoteStateEnumEnum = typeof ProcessorShippingNoteStateUpdateDTOUpdatedProcessorShippingNoteStateEnumEnum[keyof typeof ProcessorShippingNoteStateUpdateDTOUpdatedProcessorShippingNoteStateEnumEnum];


/**
 * Check if a given object implements the ProcessorShippingNoteStateUpdateDTO interface.
 */
export function instanceOfProcessorShippingNoteStateUpdateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProcessorShippingNoteStateUpdateDTOFromJSON(json: any): ProcessorShippingNoteStateUpdateDTO {
    return ProcessorShippingNoteStateUpdateDTOFromJSONTyped(json, false);
}

export function ProcessorShippingNoteStateUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessorShippingNoteStateUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedProcessorShippingNoteStateEnum': !exists(json, 'updatedProcessorShippingNoteStateEnum') ? undefined : json['updatedProcessorShippingNoteStateEnum'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
    };
}

export function ProcessorShippingNoteStateUpdateDTOToJSON(value?: ProcessorShippingNoteStateUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updatedProcessorShippingNoteStateEnum': value.updatedProcessorShippingNoteStateEnum,
        'name': value.name,
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
        'city': value.city,
        'zipCode': value.zipCode,
    };
}

