import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { UrstammNavigationHelper } from '../../../navigation/UrstammNavigationHelper';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammFontFamily, UrstammFontsize } from '../../../utils/styles/UrstammFont';
import { UrstammStyle, UrstammStyleContainer } from '../../../utils/styles/UrstammStyle';
import UrstammButtonBase from '../../utility-components/button/UrstammButtonBase';
import UrstammInput from '../../utility-components/input/UrstammInput';
import UrstammTitle from '../../utility-components/title/UrstammTitle';

export interface PasswordForm {
  password: string;
  confirmPassword: string;
}

export default function ChangePasswordView(props: { submit: (password: PasswordForm) => void; navigation: any }) {
  const [changePasswordForm, setChangePasswordForm] = useStateWithCallbackLazy<PasswordForm>({
    password: '',
    confirmPassword: ''
  });
  const [openCloseValidateModal, setOpenCloseValidateModal] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  const backToLogin = (): void => {
    UrstammNavigationHelper.navigateToLogin(props.navigation);
  };

  //Validators
  const validators = useState<any>({
    password: { required: true, password: true },
    confirmPassword: { required: true, minlength: '8' }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    password: [],
    confirmPassword: []
  });

  const validate = (value: string, key: string, updatePassword: object) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatePassword)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatePassword: any) => {
    let disabled: boolean = false;
    if (
      urstammUtilValidatorInError(errorsUpdated) ||
      changePasswordForm.password.length == 0 ||
      changePasswordForm.confirmPassword.length == 0 ||
      updatePassword.password != updatePassword.confirmPassword
    ) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn]}>
      <ScrollView keyboardShouldPersistTaps="always" contentContainerStyle={[UrstammStyle.flexGrow1]}>
        {/** Title */}
        <View style={styles.titleTextContainer}>
          <UrstammTitle
            testID={'reset_password_title'}
            text={i18n.t('views.login.reset_password')}
            fontStyle={UrstammStyle.sectionTitleXBDarkGrey}
          />
        </View>
        {/** Password */}
        <View style={styles.inputContainer}>
          <UrstammInput
            testID="password"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{
              showIcon: true,
              iconName: 'unlock-alt',
              iconColor: ColorTheme.lightGreen
            }}
            secureTextEntry={true}
            onChangeText={(text: string) =>
              setChangePasswordForm(
                (state: any) => ({ ...state, password: text }),
                updatedPassword => validate(text, 'password', updatedPassword)
              )
            }
            placeholder={i18n.t('views.login.new_password')}
          />
          {/**Validator */}
          {errors['password'] && errors['password'].length > 0
            ? errors['password'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Confirm Password */}
        <View style={styles.inputContainer}>
          <UrstammInput
            testID="confirm_password"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{
              showIcon: true,
              iconName: 'unlock-alt',
              iconColor: ColorTheme.lightGreen
            }}
            secureTextEntry={true}
            onChangeText={(text: string) =>
              setChangePasswordForm(
                (state: any) => ({ ...state, confirmPassword: text }),
                updatedConfirmPassword => validate(text, 'confirmPassword', updatedConfirmPassword)
              )
            }
            placeholder={i18n.t('views.login.confirm_password_here')}
          />
          {/**Validator */}
          {errors['confirmPassword'] && errors['confirmPassword'].length > 0
            ? errors['confirmPassword'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Footer */}
        <View style={[UrstammStyle.flex4, { justifyContent: 'center' }]}>
          {/** change password button */}
          <View style={styles.buttonLogin}>
            <UrstammButtonBase
              testID={'change_password_button'}
              text={i18n.t('views.login.confirm_password')}
              style={mainGreenButton}
              onSubmit={() => props.submit(changePasswordForm)}
              disabled={saveDisabled}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  titleTextContainer: {
    //flex: 1,
    marginBottom: 16
  },
  inputContainer: {
    //flex: 1,
    paddingVertical: 8
  },
  forgotPasswordContainer: {
    //flex: 0.2,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  buttonLogin: {
    marginTop: 8
  },

  forgotPasswordText: {
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
    color: ColorTheme.mediumGrey,
    textDecorationLine: 'underline'
  }
});
