/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CompanySurface,
} from '../models';
import {
    CompanySurfaceFromJSON,
    CompanySurfaceToJSON,
} from '../models';

export interface CreateCompanySurfaceForMyCompanyRequest {
    companySurface: CompanySurface;
}

export interface GetAllCompanySurfacesForCompanyRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    deletionDateGreaterThan?: Date;
    deletionDateLessThan?: Date;
    deletionDateGreaterThanOrEqual?: Date;
    deletionDateLessThanOrEqual?: Date;
    deletionDateEquals?: Date;
    deletionDateNotEquals?: Date;
    deletionDateSpecified?: boolean;
    deletionDateIn?: Array<Date>;
    deletionDateNotIn?: Array<Date>;
    companyIdGreaterThan?: number;
    companyIdLessThan?: number;
    companyIdGreaterThanOrEqual?: number;
    companyIdLessThanOrEqual?: number;
    companyIdEquals?: number;
    companyIdNotEquals?: number;
    companyIdSpecified?: boolean;
    companyIdIn?: Array<number>;
    companyIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface PartialUpdateCompanySurfaceRequest {
    id: number;
    companySurface: CompanySurface;
}

/**
 * 
 */
export class CustomCompanySurfaceResourceApi extends runtime.BaseAPI {

    /**
     */
    async createCompanySurfaceForMyCompanyRaw(requestParameters: CreateCompanySurfaceForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySurface>> {
        if (requestParameters.companySurface === null || requestParameters.companySurface === undefined) {
            throw new runtime.RequiredError('companySurface','Required parameter requestParameters.companySurface was null or undefined when calling createCompanySurfaceForMyCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-surfaces`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySurfaceToJSON(requestParameters.companySurface),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurfaceFromJSON(jsonValue));
    }

    /**
     */
    async createCompanySurfaceForMyCompany(requestParameters: CreateCompanySurfaceForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySurface> {
        const response = await this.createCompanySurfaceForMyCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllCompanySurfacesForCompanyRaw(requestParameters: GetAllCompanySurfacesForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanySurface>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.deletionDateGreaterThan !== undefined) {
            queryParameters['deletionDate.greaterThan'] = (requestParameters.deletionDateGreaterThan as any).toISOString();
        }

        if (requestParameters.deletionDateLessThan !== undefined) {
            queryParameters['deletionDate.lessThan'] = (requestParameters.deletionDateLessThan as any).toISOString();
        }

        if (requestParameters.deletionDateGreaterThanOrEqual !== undefined) {
            queryParameters['deletionDate.greaterThanOrEqual'] = (requestParameters.deletionDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.deletionDateLessThanOrEqual !== undefined) {
            queryParameters['deletionDate.lessThanOrEqual'] = (requestParameters.deletionDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.deletionDateEquals !== undefined) {
            queryParameters['deletionDate.equals'] = (requestParameters.deletionDateEquals as any).toISOString();
        }

        if (requestParameters.deletionDateNotEquals !== undefined) {
            queryParameters['deletionDate.notEquals'] = (requestParameters.deletionDateNotEquals as any).toISOString();
        }

        if (requestParameters.deletionDateSpecified !== undefined) {
            queryParameters['deletionDate.specified'] = requestParameters.deletionDateSpecified;
        }

        if (requestParameters.deletionDateIn) {
            queryParameters['deletionDate.in'] = requestParameters.deletionDateIn;
        }

        if (requestParameters.deletionDateNotIn) {
            queryParameters['deletionDate.notIn'] = requestParameters.deletionDateNotIn;
        }

        if (requestParameters.companyIdGreaterThan !== undefined) {
            queryParameters['companyId.greaterThan'] = requestParameters.companyIdGreaterThan;
        }

        if (requestParameters.companyIdLessThan !== undefined) {
            queryParameters['companyId.lessThan'] = requestParameters.companyIdLessThan;
        }

        if (requestParameters.companyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyId.greaterThanOrEqual'] = requestParameters.companyIdGreaterThanOrEqual;
        }

        if (requestParameters.companyIdLessThanOrEqual !== undefined) {
            queryParameters['companyId.lessThanOrEqual'] = requestParameters.companyIdLessThanOrEqual;
        }

        if (requestParameters.companyIdEquals !== undefined) {
            queryParameters['companyId.equals'] = requestParameters.companyIdEquals;
        }

        if (requestParameters.companyIdNotEquals !== undefined) {
            queryParameters['companyId.notEquals'] = requestParameters.companyIdNotEquals;
        }

        if (requestParameters.companyIdSpecified !== undefined) {
            queryParameters['companyId.specified'] = requestParameters.companyIdSpecified;
        }

        if (requestParameters.companyIdIn) {
            queryParameters['companyId.in'] = requestParameters.companyIdIn;
        }

        if (requestParameters.companyIdNotIn) {
            queryParameters['companyId.notIn'] = requestParameters.companyIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/company-surfaces`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanySurfaceFromJSON));
    }

    /**
     */
    async getAllCompanySurfacesForCompany(requestParameters: GetAllCompanySurfacesForCompanyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanySurface>> {
        const response = await this.getAllCompanySurfacesForCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanySurfaceRaw(requestParameters: PartialUpdateCompanySurfaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySurface>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanySurface.');
        }

        if (requestParameters.companySurface === null || requestParameters.companySurface === undefined) {
            throw new runtime.RequiredError('companySurface','Required parameter requestParameters.companySurface was null or undefined when calling partialUpdateCompanySurface.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-surfaces/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySurfaceToJSON(requestParameters.companySurface),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurfaceFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanySurface(requestParameters: PartialUpdateCompanySurfaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySurface> {
        const response = await this.partialUpdateCompanySurfaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
