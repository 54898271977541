/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TrunkLengthRangeTable,
} from '../models';
import {
    TrunkLengthRangeTableFromJSON,
    TrunkLengthRangeTableToJSON,
} from '../models';

export interface CreateTrunkLengthRangeTableRequest {
    trunkLengthRangeTable: TrunkLengthRangeTable;
}

export interface DeleteTrunkLengthRangeTableRequest {
    id: number;
}

export interface GetTrunkLengthRangeTableRequest {
    id: number;
}

export interface PartialUpdateTrunkLengthRangeTableRequest {
    id: number;
    trunkLengthRangeTable: TrunkLengthRangeTable;
}

export interface UpdateTrunkLengthRangeTableRequest {
    id: number;
    trunkLengthRangeTable: TrunkLengthRangeTable;
}

/**
 * 
 */
export class TrunkLengthRangeTableResourceApi extends runtime.BaseAPI {

    /**
     */
    async createTrunkLengthRangeTableRaw(requestParameters: CreateTrunkLengthRangeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkLengthRangeTable>> {
        if (requestParameters.trunkLengthRangeTable === null || requestParameters.trunkLengthRangeTable === undefined) {
            throw new runtime.RequiredError('trunkLengthRangeTable','Required parameter requestParameters.trunkLengthRangeTable was null or undefined when calling createTrunkLengthRangeTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-length-range-tables`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkLengthRangeTableToJSON(requestParameters.trunkLengthRangeTable),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkLengthRangeTableFromJSON(jsonValue));
    }

    /**
     */
    async createTrunkLengthRangeTable(requestParameters: CreateTrunkLengthRangeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkLengthRangeTable> {
        const response = await this.createTrunkLengthRangeTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTrunkLengthRangeTableRaw(requestParameters: DeleteTrunkLengthRangeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTrunkLengthRangeTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-length-range-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTrunkLengthRangeTable(requestParameters: DeleteTrunkLengthRangeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTrunkLengthRangeTableRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllTrunkLengthRangeTablesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TrunkLengthRangeTable>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-length-range-tables`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrunkLengthRangeTableFromJSON));
    }

    /**
     */
    async getAllTrunkLengthRangeTables(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TrunkLengthRangeTable>> {
        const response = await this.getAllTrunkLengthRangeTablesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTrunkLengthRangeTableRaw(requestParameters: GetTrunkLengthRangeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkLengthRangeTable>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTrunkLengthRangeTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-length-range-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkLengthRangeTableFromJSON(jsonValue));
    }

    /**
     */
    async getTrunkLengthRangeTable(requestParameters: GetTrunkLengthRangeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkLengthRangeTable> {
        const response = await this.getTrunkLengthRangeTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateTrunkLengthRangeTableRaw(requestParameters: PartialUpdateTrunkLengthRangeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkLengthRangeTable>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateTrunkLengthRangeTable.');
        }

        if (requestParameters.trunkLengthRangeTable === null || requestParameters.trunkLengthRangeTable === undefined) {
            throw new runtime.RequiredError('trunkLengthRangeTable','Required parameter requestParameters.trunkLengthRangeTable was null or undefined when calling partialUpdateTrunkLengthRangeTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-length-range-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkLengthRangeTableToJSON(requestParameters.trunkLengthRangeTable),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkLengthRangeTableFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateTrunkLengthRangeTable(requestParameters: PartialUpdateTrunkLengthRangeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkLengthRangeTable> {
        const response = await this.partialUpdateTrunkLengthRangeTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTrunkLengthRangeTableRaw(requestParameters: UpdateTrunkLengthRangeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkLengthRangeTable>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTrunkLengthRangeTable.');
        }

        if (requestParameters.trunkLengthRangeTable === null || requestParameters.trunkLengthRangeTable === undefined) {
            throw new runtime.RequiredError('trunkLengthRangeTable','Required parameter requestParameters.trunkLengthRangeTable was null or undefined when calling updateTrunkLengthRangeTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-length-range-tables/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkLengthRangeTableToJSON(requestParameters.trunkLengthRangeTable),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkLengthRangeTableFromJSON(jsonValue));
    }

    /**
     */
    async updateTrunkLengthRangeTable(requestParameters: UpdateTrunkLengthRangeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkLengthRangeTable> {
        const response = await this.updateTrunkLengthRangeTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
