import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { Pile } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';
export interface PileState {
  list: Pile[];
  completeList: Pile[];
  sortBy: ButtonSelect[];
}

const initialState: PileState = {
  list: [],
  completeList: [],
  sortBy: []
};

export const pile = createSlice({
  name: 'pileList',
  initialState,
  reducers: {
    setPileList: (state, action) => {
      let pileList = { ...state }.list;
      let pileCompleteList = { ...state }.completeList;

      pileList = action.payload;
      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(pileCompleteList, element);
      });

      state.list = pileList;
      state.completeList = pileCompleteList;
    },
    setCompletePileList: (state, action) => {
      // Repetition
      let pileCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(pileCompleteList, element);
      });

      state.completeList = pileCompleteList;
    },
    removePileElement: (state, action) => {
      let pileList = { ...state }.list;
      let pileCompleteList = { ...state }.completeList;

      let element: Pile = action.payload;
      pileList = pileList.filter(e => e.id != element.id);
      pileCompleteList = pileCompleteList.filter(e => e.id != element.id);

      state.list = pileList;
      state.completeList = pileCompleteList;
    },
    setPileListSort: (state, action) => {
      state.sortBy = action.payload;
    },
    resetPileList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearPileList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const { setPileList, setCompletePileList, removePileElement, setPileListSort, resetPileList, clearPileList } =
  pile.actions;

export default pile.reducer;
