/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { DeliverySheet } from './DeliverySheet';
import {
    DeliverySheetFromJSON,
    DeliverySheetFromJSONTyped,
    DeliverySheetToJSON,
} from './DeliverySheet';
import { LoggingPhoto } from './Logging';
import type { Pile } from './Pile';
import {
    PileFromJSON,
    PileFromJSONTyped,
    PileToJSON,
} from './Pile';
import type { Plot } from './Plot';
import {
    PlotFromJSON,
    PlotFromJSONTyped,
    PlotToJSON,
} from './Plot';
import type { Tree } from './Tree';
import {
    TreeFromJSON,
    TreeFromJSONTyped,
    TreeToJSON,
} from './Tree';
import type { Trunk } from './Trunk';
import {
    TrunkFromJSON,
    TrunkFromJSONTyped,
    TrunkToJSON,
} from './Trunk';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface LoggingDTO
 */
export interface LoggingDTO {
    /**
     * 
     * @type {number}
     * @memberof LoggingDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoggingDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggingDTO
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof LoggingDTO
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof LoggingDTO
     */
    sumCubage?: number;
    /**
     * 
     * @type {number}
     * @memberof LoggingDTO
     */
    sumWoodOtherAssortment?: number;
    /**
     * 
     * @type {number}
     * @memberof LoggingDTO
     */
    sumWoodTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof LoggingDTO
     */
    estimatedVolume?: number;
    /**
     * 
     * @type {string}
     * @memberof LoggingDTO
     */
    currentState?: LoggingDTOCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof LoggingDTO
     */
    permitMediaType?: LoggingDTOPermitMediaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LoggingDTO
     */
    permitFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggingDTO
     */
    permitFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggingDTO
     */
    permitUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggingDTO
     */
    permitMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggingDTO
     */
    uuid?: string;
    /**
     * 
     * @type {Company}
     * @memberof LoggingDTO
     */
    company?: Company;
    /**
     * 
     * @type {User}
     * @memberof LoggingDTO
     */
    permitAuthor?: User;
    /**
     * 
     * @type {Set<Pile>}
     * @memberof LoggingDTO
     */
    piles?: Set<Pile>;
    /**
     * 
     * @type {Set<Plot>}
     * @memberof LoggingDTO
     */
    plots?: Set<Plot>;
    /**
     * 
     * @type {Set<Tree>}
     * @memberof LoggingDTO
     */
    trees?: Set<Tree>;
    /**
     * 
     * @type {Set<Trunk>}
     * @memberof LoggingDTO
     */
    trunks?: Set<Trunk>;
    /**
     * 
     * @type {Set<DeliverySheet>}
     * @memberof LoggingDTO
     */
    deliverySheets?: Set<DeliverySheet>;
    /**
     * 
     * @type {Set<LoggingPhoto>}
     * @memberof LoggingDTO
     */
    loggingPhoto?: LoggingPhoto;
}


/**
 * @export
 */
export const LoggingDTOCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type LoggingDTOCurrentStateEnum = typeof LoggingDTOCurrentStateEnum[keyof typeof LoggingDTOCurrentStateEnum];

/**
 * @export
 */
export const LoggingDTOPermitMediaTypeEnum = {
    Pdf: 'PDF',
    Image: 'IMAGE'
} as const;
export type LoggingDTOPermitMediaTypeEnum = typeof LoggingDTOPermitMediaTypeEnum[keyof typeof LoggingDTOPermitMediaTypeEnum];


/**
 * Check if a given object implements the LoggingDTO interface.
 */
export function instanceOfLoggingDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LoggingDTOFromJSON(json: any): LoggingDTO {
    return LoggingDTOFromJSONTyped(json, false);
}

export function LoggingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggingDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'sumWoodOtherAssortment': !exists(json, 'sumWoodOtherAssortment') ? undefined : json['sumWoodOtherAssortment'],
        'sumWoodTotal': !exists(json, 'sumWoodTotal') ? undefined : json['sumWoodTotal'],
        'estimatedVolume': !exists(json, 'estimatedVolume') ? undefined : json['estimatedVolume'],
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'permitMediaType': !exists(json, 'permitMediaType') ? undefined : json['permitMediaType'],
        'permitFilename': !exists(json, 'permitFilename') ? undefined : json['permitFilename'],
        'permitFilepath': !exists(json, 'permitFilepath') ? undefined : json['permitFilepath'],
        'permitUrl': !exists(json, 'permitUrl') ? undefined : json['permitUrl'],
        'permitMd5': !exists(json, 'permitMd5') ? undefined : json['permitMd5'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'permitAuthor': !exists(json, 'permitAuthor') ? undefined : UserFromJSON(json['permitAuthor']),
        'piles': !exists(json, 'piles') ? undefined : (new Set((json['piles'] as Array<any>).map(PileFromJSON))),
        'plots': !exists(json, 'plots') ? undefined : (new Set((json['plots'] as Array<any>).map(PlotFromJSON))),
        'trees': !exists(json, 'trees') ? undefined : (new Set((json['trees'] as Array<any>).map(TreeFromJSON))),
        'trunks': !exists(json, 'trunks') ? undefined : (new Set((json['trunks'] as Array<any>).map(TrunkFromJSON))),
        'deliverySheets': !exists(json, 'deliverySheets') ? undefined : (new Set((json['deliverySheets'] as Array<any>).map(DeliverySheetFromJSON))),
    };
}

export function LoggingDTOToJSON(value?: LoggingDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'sumCubage': value.sumCubage,
        'sumWoodOtherAssortment': value.sumWoodOtherAssortment,
        'sumWoodTotal': value.sumWoodTotal,
        'estimatedVolume': value.estimatedVolume,
        'currentState': value.currentState,
        'permitMediaType': value.permitMediaType,
        'permitFilename': value.permitFilename,
        'permitFilepath': value.permitFilepath,
        'permitUrl': value.permitUrl,
        'permitMd5': value.permitMd5,
        'uuid': value.uuid,
        'company': CompanyToJSON(value.company),
        'permitAuthor': UserToJSON(value.permitAuthor),
        'piles': value.piles === undefined ? undefined : (Array.from(value.piles as Set<any>).map(PileToJSON)),
        'plots': value.plots === undefined ? undefined : (Array.from(value.plots as Set<any>).map(PlotToJSON)),
        'trees': value.trees === undefined ? undefined : (Array.from(value.trees as Set<any>).map(TreeToJSON)),
        'trunks': value.trunks === undefined ? undefined : (Array.from(value.trunks as Set<any>).map(TrunkToJSON)),
        'deliverySheets': value.deliverySheets === undefined ? undefined : (Array.from(value.deliverySheets as Set<any>).map(DeliverySheetToJSON)),
    };
}

