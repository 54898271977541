/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TreeState,
} from '../models';
import {
    TreeStateFromJSON,
    TreeStateToJSON,
} from '../models';

export interface CreateTreeStateRequest {
    treeState: TreeState;
}

export interface DeleteTreeStateRequest {
    id: number;
}

export interface GetTreeStateRequest {
    id: number;
}

export interface PartialUpdateTreeStateRequest {
    id: number;
    treeState: TreeState;
}

export interface UpdateTreeStateRequest {
    id: number;
    treeState: TreeState;
}

/**
 * 
 */
export class TreeStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createTreeStateRaw(requestParameters: CreateTreeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TreeState>> {
        if (requestParameters.treeState === null || requestParameters.treeState === undefined) {
            throw new runtime.RequiredError('treeState','Required parameter requestParameters.treeState was null or undefined when calling createTreeState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tree-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TreeStateToJSON(requestParameters.treeState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeStateFromJSON(jsonValue));
    }

    /**
     */
    async createTreeState(requestParameters: CreateTreeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TreeState> {
        const response = await this.createTreeStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTreeStateRaw(requestParameters: DeleteTreeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTreeState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tree-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTreeState(requestParameters: DeleteTreeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTreeStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllTreeStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TreeState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tree-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TreeStateFromJSON));
    }

    /**
     */
    async getAllTreeStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TreeState>> {
        const response = await this.getAllTreeStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTreeStateRaw(requestParameters: GetTreeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TreeState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTreeState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tree-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeStateFromJSON(jsonValue));
    }

    /**
     */
    async getTreeState(requestParameters: GetTreeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TreeState> {
        const response = await this.getTreeStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateTreeStateRaw(requestParameters: PartialUpdateTreeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TreeState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateTreeState.');
        }

        if (requestParameters.treeState === null || requestParameters.treeState === undefined) {
            throw new runtime.RequiredError('treeState','Required parameter requestParameters.treeState was null or undefined when calling partialUpdateTreeState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tree-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TreeStateToJSON(requestParameters.treeState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateTreeState(requestParameters: PartialUpdateTreeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TreeState> {
        const response = await this.partialUpdateTreeStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTreeStateRaw(requestParameters: UpdateTreeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TreeState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTreeState.');
        }

        if (requestParameters.treeState === null || requestParameters.treeState === undefined) {
            throw new runtime.RequiredError('treeState','Required parameter requestParameters.treeState was null or undefined when calling updateTreeState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tree-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TreeStateToJSON(requestParameters.treeState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeStateFromJSON(jsonValue));
    }

    /**
     */
    async updateTreeState(requestParameters: UpdateTreeStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TreeState> {
        const response = await this.updateTreeStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
