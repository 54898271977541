/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { UserCompanyInviteState } from './UserCompanyInviteState';
import {
    UserCompanyInviteStateFromJSON,
    UserCompanyInviteStateFromJSONTyped,
    UserCompanyInviteStateToJSON,
} from './UserCompanyInviteState';

/**
 * 
 * @export
 * @interface UserCompanyInvite
 */
export interface UserCompanyInvite {
    /**
     * 
     * @type {number}
     * @memberof UserCompanyInvite
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserCompanyInvite
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserCompanyInvite
     */
    currentState: UserCompanyInviteCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UserCompanyInvite
     */
    role: UserCompanyInviteRoleEnum;
    /**
     * 
     * @type {Company}
     * @memberof UserCompanyInvite
     */
    company?: Company;
    /**
     * 
     * @type {User}
     * @memberof UserCompanyInvite
     */
    user?: User;
    /**
     * 
     * @type {Set<UserCompanyInviteState>}
     * @memberof UserCompanyInvite
     */
    userCompanyInviteStates?: Set<UserCompanyInviteState>;
}


/**
 * @export
 */
export const UserCompanyInviteCurrentStateEnum = {
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type UserCompanyInviteCurrentStateEnum = typeof UserCompanyInviteCurrentStateEnum[keyof typeof UserCompanyInviteCurrentStateEnum];

/**
 * @export
 */
export const UserCompanyInviteRoleEnum = {
    Administrator: 'ADMINISTRATOR',
    Forester: 'FORESTER',
    Employee: 'EMPLOYEE'
} as const;
export type UserCompanyInviteRoleEnum = typeof UserCompanyInviteRoleEnum[keyof typeof UserCompanyInviteRoleEnum];


/**
 * Check if a given object implements the UserCompanyInvite interface.
 */
export function instanceOfUserCompanyInvite(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentState" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function UserCompanyInviteFromJSON(json: any): UserCompanyInvite {
    return UserCompanyInviteFromJSONTyped(json, false);
}

export function UserCompanyInviteFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCompanyInvite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'currentState': json['currentState'],
        'role': json['role'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userCompanyInviteStates': !exists(json, 'userCompanyInviteStates') ? undefined : (new Set((json['userCompanyInviteStates'] as Array<any>).map(UserCompanyInviteStateFromJSON))),
    };
}

export function UserCompanyInviteToJSON(value?: UserCompanyInvite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'currentState': value.currentState,
        'role': value.role,
        'company': CompanyToJSON(value.company),
        'user': UserToJSON(value.user),
        'userCompanyInviteStates': value.userCompanyInviteStates === undefined ? undefined : (Array.from(value.userCompanyInviteStates as Set<any>).map(UserCompanyInviteStateToJSON)),
    };
}

