import { createSlice } from '@reduxjs/toolkit';

export interface InputState {
  isFocused: boolean;
}

const initialState: InputState = {
  isFocused: false
};

export const inputSlice = createSlice({
  name: 'input',
  initialState,
  reducers: {
    setInputFocus: (state, action) => {
      state.isFocused = action.payload;
    },
    resetInputFocus: () => initialState
  }
});

// Action creators are generated for each case reducer function
export const { setInputFocus, resetInputFocus } = inputSlice.actions;

export default inputSlice.reducer;
