import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { createSlice } from '@reduxjs/toolkit';
import { Product } from '@services/apis/generated';
import { clearElementForRedux } from '../../../utils/classes/UrstammClassMapper';
import { pushElementOrReplaceById } from '../../../utils/classes/UrstammUtilityFunctions';

export interface ProductState {
  list: Product[];
  completeList: Product[];
  page: number;
  totalPages: number;
  sortBy: ButtonSelect[];
}

const initialState: ProductState = {
  list: [],
  completeList: [],
  page: 0,
  totalPages: 0,
  sortBy: []
};

export const product = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    setProductList: (state, action) => {
      let productList = { ...state }.list;
      let productCompleteList = { ...state }.completeList;

      action.payload.forEach((element: any) => {
        element = clearElementForRedux(element);
        pushElementOrReplaceById(productList, element);
        pushElementOrReplaceById(productCompleteList, element);
      });

      state.list = productList;
      state.completeList = productCompleteList;
    },
    setProductTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setProductListSort: (state, action) => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
      state.sortBy = action.payload;
    },
    setProductPage: (state, action) => {
      state.page = action.payload;
    },
    resetProductList: state => {
      const currentCompleteList = state.completeList;
      Object.assign(state, initialState);
      state.completeList = currentCompleteList || [];
    },
    clearProductList: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setProductList,
  setProductListSort,
  resetProductList,
  setProductTotalPages,
  setProductPage,
  clearProductList
} = product.actions;

export default product.reducer;
