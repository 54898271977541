/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Batch } from './Batch';
import {
    BatchFromJSON,
    BatchFromJSONTyped,
    BatchToJSON,
} from './Batch';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { ExternalCompany } from './ExternalCompany';
import {
    ExternalCompanyFromJSON,
    ExternalCompanyFromJSONTyped,
    ExternalCompanyToJSON,
} from './ExternalCompany';
import type { Packet } from './Packet';
import {
    PacketFromJSON,
    PacketFromJSONTyped,
    PacketToJSON,
} from './Packet';
import type { ShippingNoteState } from './ShippingNoteState';
import {
    ShippingNoteStateFromJSON,
    ShippingNoteStateFromJSONTyped,
    ShippingNoteStateToJSON,
} from './ShippingNoteState';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ShippingNote
 */
export interface ShippingNote {
    /**
     * 
     * @type {number}
     * @memberof ShippingNote
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingNote
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNote
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ShippingNote
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ShippingNote
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingNote
     */
    currentState: ShippingNoteCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ShippingNote
     */
    pdfFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNote
     */
    pdfFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNote
     */
    pdfMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNote
     */
    uuid?: string;
    /**
     * 
     * @type {ExternalCompany}
     * @memberof ShippingNote
     */
    mockProcessor?: ExternalCompany;
    /**
     * 
     * @type {Company}
     * @memberof ShippingNote
     */
    processor?: Company;
    /**
     * 
     * @type {Company}
     * @memberof ShippingNote
     */
    sawmill?: Company;
    /**
     * 
     * @type {User}
     * @memberof ShippingNote
     */
    pdfAuthor?: User;
    /**
     * 
     * @type {Batch}
     * @memberof ShippingNote
     */
    batch?: Batch;
    /**
     * 
     * @type {Set<Packet>}
     * @memberof ShippingNote
     */
    packets?: Set<Packet>;
    /**
     * 
     * @type {Set<ShippingNoteState>}
     * @memberof ShippingNote
     */
    shippingNoteStates?: Set<ShippingNoteState>;
}


/**
 * @export
 */
export const ShippingNoteCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type ShippingNoteCurrentStateEnum = typeof ShippingNoteCurrentStateEnum[keyof typeof ShippingNoteCurrentStateEnum];


/**
 * Check if a given object implements the ShippingNote interface.
 */
export function instanceOfShippingNote(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "currentState" in value;

    return isInstance;
}

export function ShippingNoteFromJSON(json: any): ShippingNote {
    return ShippingNoteFromJSONTyped(json, false);
}

export function ShippingNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'currentState': json['currentState'],
        'pdfFilename': !exists(json, 'pdfFilename') ? undefined : json['pdfFilename'],
        'pdfFilepath': !exists(json, 'pdfFilepath') ? undefined : json['pdfFilepath'],
        'pdfMd5': !exists(json, 'pdfMd5') ? undefined : json['pdfMd5'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'mockProcessor': !exists(json, 'mockProcessor') ? undefined : ExternalCompanyFromJSON(json['mockProcessor']),
        'processor': !exists(json, 'processor') ? undefined : CompanyFromJSON(json['processor']),
        'sawmill': !exists(json, 'sawmill') ? undefined : CompanyFromJSON(json['sawmill']),
        'pdfAuthor': !exists(json, 'pdfAuthor') ? undefined : UserFromJSON(json['pdfAuthor']),
        'batch': !exists(json, 'batch') ? undefined : BatchFromJSON(json['batch']),
        'packets': !exists(json, 'packets') ? undefined : (new Set((json['packets'] as Array<any>).map(PacketFromJSON))),
        'shippingNoteStates': !exists(json, 'shippingNoteStates') ? undefined : (new Set((json['shippingNoteStates'] as Array<any>).map(ShippingNoteStateFromJSON))),
    };
}

export function ShippingNoteToJSON(value?: ShippingNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'sumCubage': value.sumCubage,
        'currentState': value.currentState,
        'pdfFilename': value.pdfFilename,
        'pdfFilepath': value.pdfFilepath,
        'pdfMd5': value.pdfMd5,
        'uuid': value.uuid,
        'mockProcessor': ExternalCompanyToJSON(value.mockProcessor),
        'processor': CompanyToJSON(value.processor),
        'sawmill': CompanyToJSON(value.sawmill),
        'pdfAuthor': UserToJSON(value.pdfAuthor),
        'batch': BatchToJSON(value.batch),
        'packets': value.packets === undefined ? undefined : (Array.from(value.packets as Set<any>).map(PacketToJSON)),
        'shippingNoteStates': value.shippingNoteStates === undefined ? undefined : (Array.from(value.shippingNoteStates as Set<any>).map(ShippingNoteStateToJSON)),
    };
}

