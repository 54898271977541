import { createSlice } from '@reduxjs/toolkit';

export interface LoaderState {
  enabled: boolean;
  enabledForSync: boolean;
  syncInProgress: boolean;
}

const initialState: LoaderState = {
  enabled: false,
  enabledForSync: false,
  syncInProgress: false
};

export const loader = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    changeLoaderStatus: (state, action) => {
      state.enabled = action.payload;
    },
    changeLoaderForSyncStatus: (state, action) => {
      state.enabledForSync = action.payload;
    },
    changeSyncInProgress: (state, action) => {
      state.syncInProgress = action.payload;
    },
    clearLoaderStatus: state => {
      Object.assign(state, initialState);
    }
  }
});

// Action creators are generated for each case reducer function
export const { changeLoaderStatus, changeLoaderForSyncStatus, changeSyncInProgress, clearLoaderStatus } =
  loader.actions;

export default loader.reducer;
