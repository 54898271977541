/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectStateEnumFilter
 */
export interface ProjectStateEnumFilter {
    /**
     * 
     * @type {string}
     * @memberof ProjectStateEnumFilter
     */
    equals?: ProjectStateEnumFilterEqualsEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectStateEnumFilter
     */
    notEquals?: ProjectStateEnumFilterNotEqualsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectStateEnumFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectStateEnumFilter
     */
    _in?: Array<ProjectStateEnumFilterInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectStateEnumFilter
     */
    notIn?: Array<ProjectStateEnumFilterNotInEnum>;
}


/**
 * @export
 */
export const ProjectStateEnumFilterEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProjectStateEnumFilterEqualsEnum = typeof ProjectStateEnumFilterEqualsEnum[keyof typeof ProjectStateEnumFilterEqualsEnum];

/**
 * @export
 */
export const ProjectStateEnumFilterNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProjectStateEnumFilterNotEqualsEnum = typeof ProjectStateEnumFilterNotEqualsEnum[keyof typeof ProjectStateEnumFilterNotEqualsEnum];

/**
 * @export
 */
export const ProjectStateEnumFilterInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProjectStateEnumFilterInEnum = typeof ProjectStateEnumFilterInEnum[keyof typeof ProjectStateEnumFilterInEnum];

/**
 * @export
 */
export const ProjectStateEnumFilterNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProjectStateEnumFilterNotInEnum = typeof ProjectStateEnumFilterNotInEnum[keyof typeof ProjectStateEnumFilterNotInEnum];


/**
 * Check if a given object implements the ProjectStateEnumFilter interface.
 */
export function instanceOfProjectStateEnumFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectStateEnumFilterFromJSON(json: any): ProjectStateEnumFilter {
    return ProjectStateEnumFilterFromJSONTyped(json, false);
}

export function ProjectStateEnumFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectStateEnumFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
        'notEquals': !exists(json, 'notEquals') ? undefined : json['notEquals'],
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
    };
}

export function ProjectStateEnumFilterToJSON(value?: ProjectStateEnumFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals,
        'notEquals': value.notEquals,
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
    };
}

