import UrstammImageAnnualRings from '@components/images/UrstammImageAnnualRings';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { LoginVM } from '@services/apis/generated';
import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { UrstammStyle, UrstammStyleHeader, UrstammStyleLayout } from '../../../utils/styles/UrstammStyle';
import UrstammImageLogo from '../../images/UrstammImageLogo';
import normalize from 'react-native-normalize';

export default function LoginLayout(props: { submit?: any; navigation: any; view: ReactElement }) {
  const submitLogin = (authData: LoginVM) => {
    props.submit(authData);
  };

  return (
    <View style={UrstammStyleLayout.mainContainer}>
      {/**Background annual rings */}
      <View style={[UrstammStyleHeader.topLinesContainer, UrstammStyle.zIndex3]}>
        {!PlatformHelper.isWeb() ? (
          <UrstammImageAnnualRings width={'100%'} height={'100%'} showTop={true} showBottom={true} />
        ) : null}
      </View>

      {/**Top layout */}
      <View style={[UrstammStyle.zIndex5, UrstammStyleLayout.topContainerWelcome]}>
        <View>
          <UrstammTitle
            testID={'login_title'}
            text={i18n.t('header.welcome')}
            fontStyle={UrstammStyleHeader.headerTextStyleLightGreen}
          />
          <UrstammImageLogo
            width={PlatformHelper.normalizeByDevice(280)}
            height={PlatformHelper.normalizeByDevice(90)}
            showText={true}
          />
        </View>
      </View>

      {/**Bottom layout */}
      <View style={[UrstammStyleLayout.bottomContainerLogin, UrstammStyle.zIndex4]}>
        <View style={[UrstammStyleLayout.bottomSubContainer40, UrstammStyle.zIndex5]}>
          {props.view ? props.view : null}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
