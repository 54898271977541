import UrstammImageAnnualRings from '@components/images/UrstammImageAnnualRings';
import UrstammImageCreateAccount from '@components/images/UrstammImageCreateAccount';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { AdminUserFullDTO } from '@services/apis/generated';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { UrstammStyle, UrstammStyleHeader, UrstammStyleLayout } from '../../../utils/styles/UrstammStyle';
import SignupView from '../../views/login/SignupView';

export default function SignupLayout(props: { createAccount: (user: AdminUserFullDTO) => void; navigation: any }) {
  const createAccount = (user: AdminUserFullDTO) => {
    props.createAccount(user);
  };

  return (
    <View style={UrstammStyleLayout.mainContainer}>
      {/**Background annual rings */}
      <View style={[UrstammStyleHeader.topLinesContainer, UrstammStyle.zIndex3]}>
        {!PlatformHelper.isWeb() ? (
          <UrstammImageAnnualRings width={'100%'} height={'100%'} showTop={true} showBottom={true} />
        ) : null}
      </View>

      {/**Top layout */}
      <View style={[UrstammStyleLayout.topContainerSignup, UrstammStyle.zIndex2]}>
        <UrstammTitle
          testID={'create_new_account_title'}
          text={i18n.t('views.signup.create_new_account')}
          fontStyle={UrstammStyleHeader.headerTitleXBDarkGrey}
        />

        {/**Section logo */}
        <View style={[UrstammStyleHeader.sectionImageContainer, UrstammStyle.zIndex5]}>
          <UrstammImageCreateAccount width={65} height={65} />
        </View>
      </View>

      {/**Bottom layout */}
      <View style={[UrstammStyleLayout.baseBottomContainer, UrstammStyle.zIndex4]}>
        <View style={UrstammStyleLayout.bottomSubContainer40}>
          <SignupView createAccount={(user: AdminUserFullDTO) => createAccount(user)} navigation={props.navigation} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
