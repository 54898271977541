import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import { i18n } from '@i18n/i18n';
import { Plot, PlotDTO } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { UrstammStyle, UrstammStyleList } from '../../../utils/styles/UrstammStyle';

export default function PlotListView(props: {
  navigation: any;
  plotList: PlotDTO[];
  modalVisible: boolean;
  plotSelected?: (plot: Plot) => void;
}) {
  const [plotSelected, setPlotSelected] = useState<Plot>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  let keys: ListSubData[] = [
    {
      title: i18n.t('views.plot.egrid_n'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'egrid',
      keyId: '1egrid',
      truncateMaxLength: 8
    }
  ];

  useEffect(() => {
    if (props.modalVisible) {
      setModalVisible(props.modalVisible);
    }
    return () => {};
  }, [props.modalVisible]);

  return (
    <View style={[UrstammStyle.flex1]}>
      <View style={UrstammStyle.flex1}>
        <UrstammList
          showHash={false}
          elementSelected={(data: any) => {
            setPlotSelected(data.item), props.plotSelected ? props.plotSelected(data.item) : null;
          }}
          testID={'plot_list'}
          list={props.plotList}
          textForEmptyList={i18n.t('views.plot.plots_not_found')}
          listStyle={{
            mainTopContainerViewStyle: UrstammStyleList.mainCardContainerRowCenter,
            mainTitleTextStyle: UrstammStyleList.cardMainTitleTextStyle,
            bottomContainerViewStyle: UrstammStyleList.plotCardSubContainer,
            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
          }}
          listSubData={keys}
          switchOptions={{ enabled: false }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
