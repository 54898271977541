import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import { i18n } from '@i18n/i18n';
import { Logging, Plot } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { loggingStateIsClosed } from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import { mainGreenButton, warnButton } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammStyle, UrstammStyleContainer, UrstammStyleLayout } from '../../../utils/styles/UrstammStyle';

export default function PlotDetailsView(props: {
  navigation: any;
  plotSelected: Plot;
  modalVisible: boolean;
  loggingSelected: Logging;
  updatePlot: (plotForm: Plot) => void;
  deletePlot: (plotId: number) => void;
}) {
  const [plotSelected, setPlotSelected] = useState<Plot>();

  const [plotForm, setPlotForm] = useStateWithCallbackLazy<Plot>({
    id: 0,
    name: '',
    comment: '',
    egrid: ''
  });
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  //Validators
  const validators = useState<any>({
    name: { required: true }
    //egrid: { numeric: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: []
    //egrid: []
  });

  const validate = (value: any, key: string, updatedPlotForm: Plot) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedPlotForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedPlotForm: Plot) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated) || updatedPlotForm.name.length == 0) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  useEffect(() => {
    if (props.plotSelected) {
      setPlotSelected(props.plotSelected);
      setPlotForm(
        (state: any) => ({
          ...state,
          id: props.plotSelected?.id,
          name: props.plotSelected?.name,
          comment: props.plotSelected?.comment,
          egrid: props.plotSelected?.egrid
        }),
        upadatedPlotForm => {
          saveButtonDisabled(errors, upadatedPlotForm);
        }
      );
    }
    return () => {};
  }, [props.plotSelected]);

  return (
    <View style={[UrstammStyle.flex1]}>
      <ScrollView keyboardShouldPersistTaps="always" contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column' }]}>
        {/** Plot Name */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="plot_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => {
              setPlotForm({ ...plotForm, name: text }, updatedPlotForm => validate(text, 'name', updatedPlotForm));
            }}
            placeholder={i18n.t('views.plot.insert_plot_name_here')}
            textInputTitle={i18n.t('views.plot.plot_name')}
            value={plotForm.name ? plotForm.name : ''}
            editable={!loggingStateIsClosed(props.loggingSelected.currentState)}
            mandatory={true}
            multiline={false}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="plot_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => setPlotForm({ ...plotForm, comment: text }, () => {})}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            value={plotForm.comment ? plotForm.comment : ''}
            editable={!loggingStateIsClosed(props.loggingSelected.currentState)}
            multiline={true}
          />
        </View>

        {/** Plot egrid */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="plot_egrid"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => {
              setPlotForm({ ...plotForm, egrid: text.toString() }, () => {});
            }}
            placeholder={i18n.t('views.plot.insert_plot_egrid_here')}
            textInputTitle={i18n.t('views.plot.egrid_n')}
            mandatory={true}
            value={plotForm.egrid ? 'CH' + plotForm.egrid!.substring(2) : 'CH'}
            editable={!loggingStateIsClosed(props.loggingSelected.currentState)}
            multiline={false}
            keyboardType={'numeric'}
            maxLength={14}
          />

          {/**Validator */}
          {errors['egrid'] && errors['egrid'].length > 0
            ? errors['egrid'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/**Update/Delete plot */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'modify_plot'}
            text={i18n.t('views.plot.modify_plot')}
            style={mainGreenButton}
            onSubmit={() => props.updatePlot(plotForm)}
            disabled={loggingStateIsClosed(props.loggingSelected.currentState) || saveDisabled}
          />

          <UrstammButtonBase
            testID={'delete_plot'}
            text={i18n.t('views.plot.delete_plot')}
            style={warnButton}
            onSubmit={() => props.deletePlot(plotSelected?.id!)}
            disabled={loggingStateIsClosed(props.loggingSelected.currentState)}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
