/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Logging } from './Logging';
import {
    LoggingFromJSON,
    LoggingFromJSONTyped,
    LoggingToJSON,
} from './Logging';
import type { Plot } from './Plot';
import {
    PlotFromJSON,
    PlotFromJSONTyped,
    PlotToJSON,
} from './Plot';
import type { TreeState } from './TreeState';
import {
    TreeStateFromJSON,
    TreeStateFromJSONTyped,
    TreeStateToJSON,
} from './TreeState';
import type { Trunk } from './Trunk';
import {
    TrunkFromJSON,
    TrunkFromJSONTyped,
    TrunkToJSON,
} from './Trunk';

/**
 * 
 * @export
 * @interface TreeDTO
 */
export interface TreeDTO {
    /**
     * 
     * @type {string}
     * @memberof TreeDTO
     */
    uuid: string;
    /**
     * 
     * @type {number}
     * @memberof TreeDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TreeDTO
     */
    photoNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof TreeDTO
     */
    multipleCounter?: number;
    /**
     * 
     * @type {string}
     * @memberof TreeDTO
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof TreeDTO
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof TreeDTO
     */
    species?: TreeDTOSpeciesEnum;
    /**
     * 
     * @type {string}
     * @memberof TreeDTO
     */
    recordingType?: TreeDTORecordingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TreeDTO
     */
    currentState?: TreeDTOCurrentStateEnum;
    /**
     * 
     * @type {number}
     * @memberof TreeDTO
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof TreeDTO
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof TreeDTO
     */
    forestPhotoFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof TreeDTO
     */
    forestPhotoFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof TreeDTO
     */
    forestPhotoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof TreeDTO
     */
    forestPhotoMd5?: string;
    /**
     * 
     * @type {Logging}
     * @memberof TreeDTO
     */
    logging?: Logging;
    /**
     * 
     * @type {Plot}
     * @memberof TreeDTO
     */
    plot?: Plot;
    /**
     * 
     * @type {Set<Trunk>}
     * @memberof TreeDTO
     */
    trunks?: Set<Trunk>;
    /**
     * 
     * @type {Set<TreeState>}
     * @memberof TreeDTO
     */
    treeStates?: Set<TreeState>;
    /**
     * 
     * @type {Date}
     * @memberof TreeDTO
     */
    photoCreationDate?: Date;
}


/**
 * @export
 */
export const TreeDTOSpeciesEnum = {
    Spruce: 'SPRUCE',
    Fir: 'FIR',
    Beech: 'BEECH',
    Larch: 'LARCH',
    Pine: 'PINE',
    Arolla: 'AROLLA',
    DouglasFire: 'DOUGLAS_FIRE',
    OtherSoftWood: 'OTHER_SOFT_WOOD',
    OtherHardWood: 'OTHER_HARD_WOOD',
    Oak: 'OAK',
    Acer: 'ACER',
    Chestnut: 'CHESTNUT',
    Alder: 'ALDER',
    CherryTree: 'CHERRY_TREE',
    Ash: 'ASH',
    Apple: 'APPLE',
    Pear: 'PEAR',
    Birch: 'BIRCH',
    Linden: 'LINDEN',
    Walnut: 'WALNUT',
    Elm: 'ELM',
    Hornbeam: 'HORNBEAM',
    WhitePine: 'WHITE_PINE',
    BlackPine: 'BLACK_PINE',
    RedOak: 'RED_OAK',
    NorwayMaple: 'NORWAY_MAPLE',
    FieldMaple: 'FIELD_MAPLE',
    Sycamore: 'SYCAMORE',
    MixedSoftwood: 'MIXED_SOFTWOOD',
    MixedHardwood: 'MIXED_HARDWOOD'
} as const;
export type TreeDTOSpeciesEnum = typeof TreeDTOSpeciesEnum[keyof typeof TreeDTOSpeciesEnum];

/**
 * @export
 */
export const TreeDTORecordingTypeEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE',
    Date: 'DATE',
} as const;
export type TreeDTORecordingTypeEnum = typeof TreeDTORecordingTypeEnum[keyof typeof TreeDTORecordingTypeEnum];

/**
 * @export
 */
export const TreeDTOCurrentStateEnum = {
    Standing: 'STANDING',
    Cut: 'CUT',
    MovedToSawmill: 'MOVED_TO_SAWMILL'
} as const;
export type TreeDTOCurrentStateEnum = typeof TreeDTOCurrentStateEnum[keyof typeof TreeDTOCurrentStateEnum];


/**
 * Check if a given object implements the TreeDTO interface.
 */
export function instanceOfTreeDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TreeDTOFromJSON(json: any): TreeDTO {
    return TreeDTOFromJSONTyped(json, false);
}

export function TreeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TreeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'photoNumber': !exists(json, 'photoNumber') ? undefined : json['photoNumber'],
        'multipleCounter': !exists(json, 'multipleCounter') ? undefined : json['multipleCounter'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'species': !exists(json, 'species') ? undefined : json['species'],
        'recordingType': !exists(json, 'recordingType') ? undefined : json['recordingType'],
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'forestPhotoFilename': !exists(json, 'forestPhotoFilename') ? undefined : json['forestPhotoFilename'],
        'forestPhotoFilepath': !exists(json, 'forestPhotoFilepath') ? undefined : json['forestPhotoFilepath'],
        'forestPhotoUrl': !exists(json, 'forestPhotoUrl') ? undefined : json['forestPhotoUrl'],
        'forestPhotoMd5': !exists(json, 'forestPhotoMd5') ? undefined : json['forestPhotoMd5'],
        'logging': !exists(json, 'logging') ? undefined : LoggingFromJSON(json['logging']),
        'plot': !exists(json, 'plot') ? undefined : PlotFromJSON(json['plot']),
        'trunks': !exists(json, 'trunks') ? undefined : (new Set((json['trunks'] as Array<any>).map(TrunkFromJSON))),
        'treeStates': !exists(json, 'treeStates') ? undefined : (new Set((json['treeStates'] as Array<any>).map(TreeStateFromJSON))),
    };
}

export function TreeDTOToJSON(value?: TreeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'photoNumber': value.photoNumber,
        'multipleCounter': value.multipleCounter,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'species': value.species,
        'recordingType': value.recordingType,
        'currentState': value.currentState,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'forestPhotoFilename': value.forestPhotoFilename,
        'forestPhotoFilepath': value.forestPhotoFilepath,
        'forestPhotoUrl': value.forestPhotoUrl,
        'forestPhotoMd5': value.forestPhotoMd5,
        'logging': LoggingToJSON(value.logging),
        'plot': PlotToJSON(value.plot),
        'trunks': value.trunks === undefined ? undefined : (Array.from(value.trunks as Set<any>).map(TrunkToJSON)),
        'treeStates': value.treeStates === undefined ? undefined : (Array.from(value.treeStates as Set<any>).map(TreeStateToJSON)),
        'photoCreationDate': value.photoCreationDate === undefined ? undefined : value.photoCreationDate
    };
}

