import { PlatformHelper } from '@helpers/PlatformHelper';
import { PixelRatio } from 'react-native';
import normalize from 'react-native-normalize';

export const UrstammFontFamily = {
  regularFont: 'barlow-regular',
  lightFont: 'barlow-light',
  boldFont: 'barlow-bold'
};

// export const UrstammFontsize = {
//   sbaseFontSize: !PlatformHelper.isWeb() ? normalize(18 * 0.7, 'height') : 18 * 0.7,
//   baseFontSize: !PlatformHelper.isWeb() ? normalize(18, 'height') : 18,
//   mbaseFontSize: !PlatformHelper.isWeb() ? normalize(18 * 1.15, 'height') : 18 * 1.15,
//   xbaseFontSize: !PlatformHelper.isWeb() ? normalize(18 * 1, 'height') : 18 * 1,
//   xxbaseFontSize: !PlatformHelper.isWeb() ? normalize(18 * 1.5, 'height') : 18 * 1.5,
//   xxxbaseFontSize: !PlatformHelper.isWeb() ? normalize(18 * 2, 'height') : 18 * 2
// };

// responsive font size integration
const fontScale = PixelRatio.getFontScale();
const getFontSize = size => size / fontScale;

export const UrstammFontsize = {
  ssbaseFontSize: !PlatformHelper.isWeb() ? getFontSize(16 * 0.75) : 16 * 0.75,
  sbaseFontSize: !PlatformHelper.isWeb() ? getFontSize(18 * 0.75) : 18 * 0.75,
  baseFontSize: !PlatformHelper.isWeb() ? getFontSize(18) : 18,
  mbaseFontSize: !PlatformHelper.isWeb() ? getFontSize(18 * 1.15) : 18 * 1.15,
  xbaseFontSize: !PlatformHelper.isWeb() ? getFontSize(18 * 1) : 18 * 1,
  xxbaseFontSize: !PlatformHelper.isWeb() ? getFontSize(18 * 1.5) : 18 * 1.5,
  xxxbaseFontSize: !PlatformHelper.isWeb() ? getFontSize(18 * 2) : 18 * 2
};

export const UrstammFontWeight = {
  title: 'bold',
  subtitle: '300',
  regular: '400'
};
