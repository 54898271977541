/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProjectUniqueNumber,
} from '../models';
import {
    ProjectUniqueNumberFromJSON,
    ProjectUniqueNumberToJSON,
} from '../models';

export interface CountProjectUniqueNumbersRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    yearGreaterThan?: number;
    yearLessThan?: number;
    yearGreaterThanOrEqual?: number;
    yearLessThanOrEqual?: number;
    yearEquals?: number;
    yearNotEquals?: number;
    yearSpecified?: boolean;
    yearIn?: Array<number>;
    yearNotIn?: Array<number>;
    numberGreaterThan?: number;
    numberLessThan?: number;
    numberGreaterThanOrEqual?: number;
    numberLessThanOrEqual?: number;
    numberEquals?: number;
    numberNotEquals?: number;
    numberSpecified?: boolean;
    numberIn?: Array<number>;
    numberNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateProjectUniqueNumberRequest {
    projectUniqueNumber: ProjectUniqueNumber;
}

export interface DeleteProjectUniqueNumberRequest {
    id: number;
}

export interface GetAllProjectUniqueNumbersRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    yearGreaterThan?: number;
    yearLessThan?: number;
    yearGreaterThanOrEqual?: number;
    yearLessThanOrEqual?: number;
    yearEquals?: number;
    yearNotEquals?: number;
    yearSpecified?: boolean;
    yearIn?: Array<number>;
    yearNotIn?: Array<number>;
    numberGreaterThan?: number;
    numberLessThan?: number;
    numberGreaterThanOrEqual?: number;
    numberLessThanOrEqual?: number;
    numberEquals?: number;
    numberNotEquals?: number;
    numberSpecified?: boolean;
    numberIn?: Array<number>;
    numberNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetProjectUniqueNumberRequest {
    id: number;
}

export interface PartialUpdateProjectUniqueNumberRequest {
    id: number;
    projectUniqueNumber: ProjectUniqueNumber;
}

export interface UpdateProjectUniqueNumberRequest {
    id: number;
    projectUniqueNumber: ProjectUniqueNumber;
}

/**
 * 
 */
export class ProjectUniqueNumberResourceApi extends runtime.BaseAPI {

    /**
     */
    async countProjectUniqueNumbersRaw(requestParameters: CountProjectUniqueNumbersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.yearGreaterThan !== undefined) {
            queryParameters['year.greaterThan'] = requestParameters.yearGreaterThan;
        }

        if (requestParameters.yearLessThan !== undefined) {
            queryParameters['year.lessThan'] = requestParameters.yearLessThan;
        }

        if (requestParameters.yearGreaterThanOrEqual !== undefined) {
            queryParameters['year.greaterThanOrEqual'] = requestParameters.yearGreaterThanOrEqual;
        }

        if (requestParameters.yearLessThanOrEqual !== undefined) {
            queryParameters['year.lessThanOrEqual'] = requestParameters.yearLessThanOrEqual;
        }

        if (requestParameters.yearEquals !== undefined) {
            queryParameters['year.equals'] = requestParameters.yearEquals;
        }

        if (requestParameters.yearNotEquals !== undefined) {
            queryParameters['year.notEquals'] = requestParameters.yearNotEquals;
        }

        if (requestParameters.yearSpecified !== undefined) {
            queryParameters['year.specified'] = requestParameters.yearSpecified;
        }

        if (requestParameters.yearIn) {
            queryParameters['year.in'] = requestParameters.yearIn;
        }

        if (requestParameters.yearNotIn) {
            queryParameters['year.notIn'] = requestParameters.yearNotIn;
        }

        if (requestParameters.numberGreaterThan !== undefined) {
            queryParameters['number.greaterThan'] = requestParameters.numberGreaterThan;
        }

        if (requestParameters.numberLessThan !== undefined) {
            queryParameters['number.lessThan'] = requestParameters.numberLessThan;
        }

        if (requestParameters.numberGreaterThanOrEqual !== undefined) {
            queryParameters['number.greaterThanOrEqual'] = requestParameters.numberGreaterThanOrEqual;
        }

        if (requestParameters.numberLessThanOrEqual !== undefined) {
            queryParameters['number.lessThanOrEqual'] = requestParameters.numberLessThanOrEqual;
        }

        if (requestParameters.numberEquals !== undefined) {
            queryParameters['number.equals'] = requestParameters.numberEquals;
        }

        if (requestParameters.numberNotEquals !== undefined) {
            queryParameters['number.notEquals'] = requestParameters.numberNotEquals;
        }

        if (requestParameters.numberSpecified !== undefined) {
            queryParameters['number.specified'] = requestParameters.numberSpecified;
        }

        if (requestParameters.numberIn) {
            queryParameters['number.in'] = requestParameters.numberIn;
        }

        if (requestParameters.numberNotIn) {
            queryParameters['number.notIn'] = requestParameters.numberNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project-unique-numbers/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countProjectUniqueNumbers(requestParameters: CountProjectUniqueNumbersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countProjectUniqueNumbersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProjectUniqueNumberRaw(requestParameters: CreateProjectUniqueNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectUniqueNumber>> {
        if (requestParameters.projectUniqueNumber === null || requestParameters.projectUniqueNumber === undefined) {
            throw new runtime.RequiredError('projectUniqueNumber','Required parameter requestParameters.projectUniqueNumber was null or undefined when calling createProjectUniqueNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project-unique-numbers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUniqueNumberToJSON(requestParameters.projectUniqueNumber),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectUniqueNumberFromJSON(jsonValue));
    }

    /**
     */
    async createProjectUniqueNumber(requestParameters: CreateProjectUniqueNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectUniqueNumber> {
        const response = await this.createProjectUniqueNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProjectUniqueNumberRaw(requestParameters: DeleteProjectUniqueNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProjectUniqueNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project-unique-numbers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProjectUniqueNumber(requestParameters: DeleteProjectUniqueNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProjectUniqueNumberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProjectUniqueNumbersRaw(requestParameters: GetAllProjectUniqueNumbersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectUniqueNumber>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.yearGreaterThan !== undefined) {
            queryParameters['year.greaterThan'] = requestParameters.yearGreaterThan;
        }

        if (requestParameters.yearLessThan !== undefined) {
            queryParameters['year.lessThan'] = requestParameters.yearLessThan;
        }

        if (requestParameters.yearGreaterThanOrEqual !== undefined) {
            queryParameters['year.greaterThanOrEqual'] = requestParameters.yearGreaterThanOrEqual;
        }

        if (requestParameters.yearLessThanOrEqual !== undefined) {
            queryParameters['year.lessThanOrEqual'] = requestParameters.yearLessThanOrEqual;
        }

        if (requestParameters.yearEquals !== undefined) {
            queryParameters['year.equals'] = requestParameters.yearEquals;
        }

        if (requestParameters.yearNotEquals !== undefined) {
            queryParameters['year.notEquals'] = requestParameters.yearNotEquals;
        }

        if (requestParameters.yearSpecified !== undefined) {
            queryParameters['year.specified'] = requestParameters.yearSpecified;
        }

        if (requestParameters.yearIn) {
            queryParameters['year.in'] = requestParameters.yearIn;
        }

        if (requestParameters.yearNotIn) {
            queryParameters['year.notIn'] = requestParameters.yearNotIn;
        }

        if (requestParameters.numberGreaterThan !== undefined) {
            queryParameters['number.greaterThan'] = requestParameters.numberGreaterThan;
        }

        if (requestParameters.numberLessThan !== undefined) {
            queryParameters['number.lessThan'] = requestParameters.numberLessThan;
        }

        if (requestParameters.numberGreaterThanOrEqual !== undefined) {
            queryParameters['number.greaterThanOrEqual'] = requestParameters.numberGreaterThanOrEqual;
        }

        if (requestParameters.numberLessThanOrEqual !== undefined) {
            queryParameters['number.lessThanOrEqual'] = requestParameters.numberLessThanOrEqual;
        }

        if (requestParameters.numberEquals !== undefined) {
            queryParameters['number.equals'] = requestParameters.numberEquals;
        }

        if (requestParameters.numberNotEquals !== undefined) {
            queryParameters['number.notEquals'] = requestParameters.numberNotEquals;
        }

        if (requestParameters.numberSpecified !== undefined) {
            queryParameters['number.specified'] = requestParameters.numberSpecified;
        }

        if (requestParameters.numberIn) {
            queryParameters['number.in'] = requestParameters.numberIn;
        }

        if (requestParameters.numberNotIn) {
            queryParameters['number.notIn'] = requestParameters.numberNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project-unique-numbers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectUniqueNumberFromJSON));
    }

    /**
     */
    async getAllProjectUniqueNumbers(requestParameters: GetAllProjectUniqueNumbersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectUniqueNumber>> {
        const response = await this.getAllProjectUniqueNumbersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectUniqueNumberRaw(requestParameters: GetProjectUniqueNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectUniqueNumber>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProjectUniqueNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project-unique-numbers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectUniqueNumberFromJSON(jsonValue));
    }

    /**
     */
    async getProjectUniqueNumber(requestParameters: GetProjectUniqueNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectUniqueNumber> {
        const response = await this.getProjectUniqueNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateProjectUniqueNumberRaw(requestParameters: PartialUpdateProjectUniqueNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectUniqueNumber>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateProjectUniqueNumber.');
        }

        if (requestParameters.projectUniqueNumber === null || requestParameters.projectUniqueNumber === undefined) {
            throw new runtime.RequiredError('projectUniqueNumber','Required parameter requestParameters.projectUniqueNumber was null or undefined when calling partialUpdateProjectUniqueNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project-unique-numbers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUniqueNumberToJSON(requestParameters.projectUniqueNumber),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectUniqueNumberFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateProjectUniqueNumber(requestParameters: PartialUpdateProjectUniqueNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectUniqueNumber> {
        const response = await this.partialUpdateProjectUniqueNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProjectUniqueNumberRaw(requestParameters: UpdateProjectUniqueNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectUniqueNumber>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProjectUniqueNumber.');
        }

        if (requestParameters.projectUniqueNumber === null || requestParameters.projectUniqueNumber === undefined) {
            throw new runtime.RequiredError('projectUniqueNumber','Required parameter requestParameters.projectUniqueNumber was null or undefined when calling updateProjectUniqueNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project-unique-numbers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUniqueNumberToJSON(requestParameters.projectUniqueNumber),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectUniqueNumberFromJSON(jsonValue));
    }

    /**
     */
    async updateProjectUniqueNumber(requestParameters: UpdateProjectUniqueNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectUniqueNumber> {
        const response = await this.updateProjectUniqueNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
