/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Packet,
} from '../models';
import {
    PacketFromJSON,
    PacketToJSON,
} from '../models';

export interface CreatePacketRequest {
    packet: Packet;
}

export interface DeletePacketRequest {
    id: number;
}

export interface GetAllPacketsRequest {
    eagerload?: boolean;
}

export interface GetPacketRequest {
    id: number;
}

export interface PartialUpdatePacketRequest {
    id: number;
    packet: Packet;
}

export interface UpdatePacketRequest {
    id: number;
    packet: Packet;
}

/**
 * 
 */
export class PacketResourceApi extends runtime.BaseAPI {

    /**
     */
    async createPacketRaw(requestParameters: CreatePacketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Packet>> {
        if (requestParameters.packet === null || requestParameters.packet === undefined) {
            throw new runtime.RequiredError('packet','Required parameter requestParameters.packet was null or undefined when calling createPacket.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PacketToJSON(requestParameters.packet),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketFromJSON(jsonValue));
    }

    /**
     */
    async createPacket(requestParameters: CreatePacketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Packet> {
        const response = await this.createPacketRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePacketRaw(requestParameters: DeletePacketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePacket.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePacket(requestParameters: DeletePacketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePacketRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllPacketsRaw(requestParameters: GetAllPacketsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Packet>>> {
        const queryParameters: any = {};

        if (requestParameters.eagerload !== undefined) {
            queryParameters['eagerload'] = requestParameters.eagerload;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PacketFromJSON));
    }

    /**
     */
    async getAllPackets(requestParameters: GetAllPacketsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Packet>> {
        const response = await this.getAllPacketsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPacketRaw(requestParameters: GetPacketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Packet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPacket.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/packets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketFromJSON(jsonValue));
    }

    /**
     */
    async getPacket(requestParameters: GetPacketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Packet> {
        const response = await this.getPacketRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdatePacketRaw(requestParameters: PartialUpdatePacketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Packet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePacket.');
        }

        if (requestParameters.packet === null || requestParameters.packet === undefined) {
            throw new runtime.RequiredError('packet','Required parameter requestParameters.packet was null or undefined when calling partialUpdatePacket.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PacketToJSON(requestParameters.packet),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdatePacket(requestParameters: PartialUpdatePacketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Packet> {
        const response = await this.partialUpdatePacketRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePacketRaw(requestParameters: UpdatePacketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Packet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePacket.');
        }

        if (requestParameters.packet === null || requestParameters.packet === undefined) {
            throw new runtime.RequiredError('packet','Required parameter requestParameters.packet was null or undefined when calling updatePacket.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/packets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PacketToJSON(requestParameters.packet),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PacketFromJSON(jsonValue));
    }

    /**
     */
    async updatePacket(requestParameters: UpdatePacketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Packet> {
        const response = await this.updatePacketRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
