import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImageNewTree from '@components/images/corner/UrstammImageNewTree';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import TrunkDetailsView from '@components/views/trunk/TrunkDetailsView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { setLoggingList } from '@redux/features/logging/loggingSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { RootState } from '@redux/store';
import { customDeliverySheetResourceApi } from '@services/apis/ApiConfiguration';
import { FullAvailableDeliverySheetResourceApi } from '@services/apis/FullAvailableDeliverySheetResourceApi';
import { FullAvailableLoggingResourceApi } from '@services/apis/FullAvailableLoggingResourceApi';
import { FullAvailableTreeResourceApi } from '@services/apis/FullAvailableTreeResourceApi';
import { FullAvailableTrunkResourceApi } from '@services/apis/FullAvailableTrunkResourceApi';
import {
  DeliverySheetDTO,
  DeliverySheetDTOCurrentStateEnum,
  DeliverySheetDTOCurrentUrstammStateEnum,
  DeliverySheetRecordingTypeEnum,
  LoggingDTO,
  TreeDTO,
  TreeDTORecordingTypeEnum,
  Trunk
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { resetTrunkList, setTrunkList } from '../../redux/features/trunk/trunkSlice';
import { clearElementForRedux } from '../../utils/classes/UrstammClassMapper';
import { loggedUserIsASawmill, sleep, urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function TreeDetailsContainer({ navigation, route }: RootStackScreenProps<'TreeDetails'>) {
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const rdxDSCompleteList = useSelector((state: RootState) => state.persistedReducer.deliverySheet.completeList);
  const rdxLoggingCompleteList = useSelector((state: RootState) => state.persistedReducer.logging.completeList);
  const rdxTreeCompleteList = useSelector((state: RootState) => state.persistedReducer.tree.completeList);
  const rdxTrunkList = useSelector((state: RootState) => state.persistedReducer.trunk.list);
  const rdxBarkDiscountList = useSelector((state: RootState) => state.persistedReducer.calculation.barkDiscountList);
  const dispatch = useDispatch();
  const [treeListByDS, setTreeListByDS] = useState<TreeDTO[]>([]);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.new_tree_record'),
      icon: (
        <UrstammImageNewTree
          width={PlatformHelper.normalizeByDevice(34)}
          height={PlatformHelper.normalizeByDevice(24)}
        />
      )
    }
  };

  useEffect(() => {
    if (
      route.params?.deliverySheetSelected?.recordingType == DeliverySheetRecordingTypeEnum.Single &&
      !loggedUserIsASawmill(rdxUserExtendedMe)
    ) {
      getTreeListByDS();
    }
  }, [route.params]);

  const updateTrunkForm = (form: Trunk): void => {
    dispatch(changeLoaderStatus(true));
    let trunkSelectedCopy: any = { ...route.params?.trunkSelected };
    form.id = trunkSelectedCopy?.id;
    form.currentState = trunkSelectedCopy?.currentState;
    form.creationDate = new Date();
    form.deliverySheet = trunkSelectedCopy?.deliverySheet;

    if (
      form &&
      route.params?.deliverySheetSelected?.currentUrstammState == DeliverySheetDTOCurrentUrstammStateEnum.InProgress
    ) {
      FullAvailableTrunkResourceApi.updateTrunkCustom(
        rdxOfflineSyncList,
        rdxBarkDiscountList,
        dispatch,
        addOfflineObjectSync,
        {
          id: route.params?.trunkSelected?.id,
          trunk: form
        }
      )
        .then(res => {
          dispatch(changeLoaderStatus(false));
          if (res) {
            dispatch(resetTrunkList());

            //Updating the delivery sheet selected
            FullAvailableDeliverySheetResourceApi.getDeliverySheetCustom(rdxDSCompleteList, {
              id: route.params?.deliverySheetSelected.id
            })
              .then((ds: DeliverySheetDTO) => {
                if (ds) {
                  UrstammNavigationHelper.navigateToDeliverySheetDetails(navigation, ds, true);
                }
              })
              .catch(async error => {
                dispatch(changeLoaderStatus(false));
                let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                AlertHelper.showSimpleAlert('Error', errorJson);
              });

            FullAvailableLoggingResourceApi.getLoggingCustom(rdxLoggingCompleteList, {
              id: route.params?.deliverySheetSelected?.logging?.id
            })
              .then((logging: LoggingDTO) => {
                if (logging) {
                  logging = clearElementForRedux(logging);
                  dispatch(setLoggingList([logging]));
                }
              })
              .catch(async error => {
                let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                AlertHelper.showSimpleAlert('Error', errorJson);
              });
          }
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    } else {
      let list = [...rdxTrunkList];
      const indexComplete = list.findIndex(object => object.trunk.id === form.id);
      const item = list[indexComplete];
      if (item.trunk.quality != form.quality) form.qualityChanged = true;
      if (item.trunk.length != form.length) form.lengthChanged = true;
      if (item.trunk.diameter != form.diameter) form.diameterChanged = true;
      if (item.trunk.species != form.species) form.speciesChanged = true;
      const itemEdited = {
        id: item.id,
        trunk: item.trunk,
        edited: {
          status: item.edited.status,
          data: form
        }
      };
      list[indexComplete] = itemEdited;
      dispatch(changeLoaderStatus(false));
      dispatch(setTrunkList(list));
      UrstammNavigationHelper.navigateToDeliverySheetDetails(navigation, route.params?.deliverySheetSelected, true);
    }
  };

  const getTreeListByDS = () => {
    dispatch(changeLoaderStatus(true));
    let loggingId = route.params?.deliverySheetSelected.logging.id;

    FullAvailableTreeResourceApi.getTreesByLogging(rdxTreeCompleteList, {
      loggingId: loggingId
    })
      .then((list: TreeDTO[]) => {
        dispatch(changeLoaderStatus(false));

        if (list) {
          let singleTreeList = list.filter(val => val.recordingType != TreeDTORecordingTypeEnum.Multiple);
          setTreeListByDS(singleTreeList);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      title={
        <UrstammTitle
          testID={'trunk_details_title'}
          text={i18n.t('generics.trunk')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'trunk_details_subtitle'}
          text={route.params?.trunkSelected?.name}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={
        route.params?.deliverySheetSelected
          ? [
              {
                title: i18n.t('generics.delivery_sheet'),
                value: route.params?.deliverySheetSelected.name!
              }
            ]
          : undefined
      }
      view={
        <TrunkDetailsView
          navigation={navigation}
          updateTrunk={(trunk: Trunk) => updateTrunkForm(trunk)}
          trunkSelected={route.params?.trunkSelected}
          treeList={treeListByDS}
          deliverySheetSelected={route.params?.deliverySheetSelected}
        />
      }
    />
  );
}
