/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Logging } from './Logging';
import {
    LoggingFromJSON,
    LoggingFromJSONTyped,
    LoggingToJSON,
} from './Logging';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface LoggingState
 */
export interface LoggingState {
    /**
     * 
     * @type {number}
     * @memberof LoggingState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof LoggingState
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof LoggingState
     */
    state: LoggingStateStateEnum;
    /**
     * 
     * @type {Logging}
     * @memberof LoggingState
     */
    logging?: Logging;
    /**
     * 
     * @type {User}
     * @memberof LoggingState
     */
    author?: User;
}


/**
 * @export
 */
export const LoggingStateStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type LoggingStateStateEnum = typeof LoggingStateStateEnum[keyof typeof LoggingStateStateEnum];


/**
 * Check if a given object implements the LoggingState interface.
 */
export function instanceOfLoggingState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function LoggingStateFromJSON(json: any): LoggingState {
    return LoggingStateFromJSONTyped(json, false);
}

export function LoggingStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggingState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'state': json['state'],
        'logging': !exists(json, 'logging') ? undefined : LoggingFromJSON(json['logging']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
    };
}

export function LoggingStateToJSON(value?: LoggingState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'state': value.state,
        'logging': LoggingToJSON(value.logging),
        'author': UserToJSON(value.author),
    };
}

