import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammAlertModal from '@components/utility-components/modal/UrstammAlertModal';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import UrstammModalSortList from '@components/utility-components/modal/UrstammModalSortList';
import { i18n } from '@i18n/i18n';
import {
  DeliverySheetDTO,
  DeliverySheetDTOCurrentUrstammStateEnum,
  DeliverySheetRecordingTypeEnum,
  UserExtendedTypeEnum
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { selectDsFilter, setDsFilterList } from '../../../redux/features/filter/deliverySheetFilterSlice';
import { RootState } from '../../../redux/store';
import {
  getColorByDeliverySheetUrstammState,
  getDSColorBySentToSawmillValue,
  getDSDisabledByTrunkListAndSawmill,
  getDSStatusBySentToSawmillValue,
  getDSStatusBySentToSawmillValueForSwitch,
  getTextByDeliverySheetUrstammState,
  getTextByDsRecordingType
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterDSByNameOrCompany } from '../../../utils/classes/UrstammUtilityFilter';
import { UrstammStyle, UrstammStyleList, UrstammStyleModal } from '../../../utils/styles/UrstammStyle';

export default function DeliverySheetListView(props: {
  navigation: any;
  deliverySheetList: DeliverySheetDTO[];
  moreItems: () => void;
  navigateToDeliverySheetDetails: (deliverySheetSelected: DeliverySheetDTO) => void;
  showSortBy: boolean;
  refresh: () => void;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
  validateDSSwitched: (data: any) => void;
}) {
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const deliverySheetFilter = useSelector((state: RootState) => state.persistedReducer.deliverySheetFilter.value);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.deliverySheet.sortBy);
  const dispatch = useDispatch();

  const [openCloseValidateModal, setOpenCloseValidateModal] = useState<boolean>(false);
  const [dsToValidate, setDsToValidate] = useState();
  const [openCloseAlertModal, setOpenCloseAlertModal] = useState<boolean>(false);
  const [openCloseModalAtLeastOneTrunk, setOpenCloseModalAtLeastOneTrunk] = useState<boolean>(false);

  let keys: ListSubData[] = [
    // { title: undefined, titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'currentUrstammState', keyId: '1currentUrstammState', dataContainerViewStyle: { height: 24, flexBasis: '50%' }, isEnum: true },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate'
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.logging') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'logging',
      secondKey: 'name',
      keyId: '1logging',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.type_of_record') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'recordingType',
      keyId: '1recordingType',
      // dataContainerViewStyle: { height: 24 },
      isEnum: true
    },
    {
      title: i18n.t('generics.trunks') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'numberOfTrunks',
      keyId: '1trunks'
      // dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.sum_cubage') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.forestry') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'forestry',
      secondKey: 'name',
      keyId: '1forestry',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.pile') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'pile',
      secondKey: 'name',
      keyId: '1pile'
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.sawmill') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sawmill',
      secondKey: 'name',
      keyId: '1sawmill',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    }
  ];

  useEffect(() => {
    getSlideButtonOptionsDSCurrentStates();
    return () => {};
  }, []);

  /**
   * Function used for generating filter buttons
   */
  const getSlideButtonOptionsDSCurrentStates = (): SlideButtonOption[] => {
    let filterList: SlideButtonOption[] = [];
    let type: SlideButtonOption = {
      id: 'ALL',
      text: i18n.t('utility_components.filter_button.all'),
      key: 'currentUrstammState',
      active: true
    };
    filterList.push(type);
    Object.values(DeliverySheetDTOCurrentUrstammStateEnum).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByDeliverySheetUrstammState(state),
        key: 'currentUrstammState',
        active: false
      };
      filterList.push(type);
    });
    dispatch(setDsFilterList(filterList));

    return filterList;
  };

  const setDSCurrentState = (selected: SlideButtonOption): void => {
    dispatch(selectDsFilter(selected));
  };

  const getActiveDSCurrentState = (): SlideButtonOption[] => {
    return deliverySheetFilter.filter(val => val.active);
  };

  function confirmSwitch() {
    props.validateDSSwitched(dsToValidate);
  }

  return (
    <View style={[UrstammStyle.flex1]}>
      {props.deliverySheetList && props.deliverySheetList.length > 0 ? (
        <View style={[{ flex: 0.15 }]}>
          <UrstammSlideButton
            testID={'button_status_filter'}
            slideButtonList={deliverySheetFilter}
            slideButtonRotatedTitle={i18n.t('utility_components.filter_button.status')}
            buttonPressed={(selected: SlideButtonOption) => setDSCurrentState(selected)}
          />
        </View>
      ) : null}

      <View style={[{ flex: 0.85 }]}>
        <UrstammList
          elementSelected={(deliverySheetSelected: any) =>
            props.navigateToDeliverySheetDetails(deliverySheetSelected.item)
          }
          testID={'delivery_sheet_list'}
          textForEmptyList={i18n.t('views.delivery_sheet.delivery_sheets_not_found')}
          list={props.deliverySheetList}
          listStyle={{
            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
            bottomContainerViewStyle: UrstammStyleList.cardSubData,
            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
          }}
          refresh={props.refresh}
          listSubData={keys}
          filterBy={getActiveDSCurrentState()}
          moreItems={props.moreItems}
          filterList={true}
          filterListFunction={(text, data) => filterDSByNameOrCompany(text, data, rdxUserExtendedMe)}
          currentState={{
            //icon: (state) => getIconByLoggingState(state),`
            color: state => getColorByDeliverySheetUrstammState(state),
            text: state => getTextByDeliverySheetUrstammState(state)
          }}
          elementSwitched={data => {
            if (!data.sentToSawmill && rdxUserExtendedMe.type == UserExtendedTypeEnum.Forestry) {
              let trunkList = data?.trunk.trunks ? [...data.trunk.trunks] : [];
              if (data.trunk?.recordingType == DeliverySheetRecordingTypeEnum.Single && data.trunk.numberOfTrunks == 0)
                return setOpenCloseModalAtLeastOneTrunk(true);

              setOpenCloseValidateModal(true);
              return setDsToValidate(data);
            }
          }}
          switchPressed={(data: DeliverySheetDTO, pressed: boolean) => {
            //TODO: TRUNK ERROR SINGLE TYPE
            if (
              !data.sentToSawmill &&
              rdxUserExtendedMe.type == UserExtendedTypeEnum.Forestry &&
              data.currentUrstammState == DeliverySheetDTOCurrentUrstammStateEnum.InProgress
            ) {
              setOpenCloseAlertModal(pressed);
            }
          }}
          switchOptions={{
            enabled: true,
            textStyle: UrstammStyle.switchStyle,
            statusByFn: (ds: DeliverySheetDTO) => getDSStatusBySentToSawmillValue(ds, rdxUserExtendedMe.type),
            colorByFn: (ds: DeliverySheetDTO) => getDSColorBySentToSawmillValue(ds),
            textByFn: (ds: DeliverySheetDTO) => getTextByDeliverySheetUrstammState(ds.currentUrstammState),
            disabledByFn: (ds: DeliverySheetDTO) => getDSDisabledByTrunkListAndSawmill(ds),
            switchConfirm: (ds: DeliverySheetDTO) => getDSStatusBySentToSawmillValueForSwitch(ds)
          }}
          titleKey={'name'}
          getTextForData={(data, key) =>
            key == 'currentUrstammState' ? getTextByDeliverySheetUrstammState(data) : getTextByDsRecordingType(data)
          }
          touchSwitch={() => setOpenCloseValidateModal(true)}
          showHash={false}
        />

        {props.showSortBy ? (
          <UrstammModalSortList
            testID={'modal_sort'}
            showSortBy={props.showSortBy}
            closeShowSortBy={props.closeShowSortBy}
            applyOrder={(orderSelected: ButtonSelect[]) => props.applyOrder(orderSelected)}
            sortBySelected={rdxSortBy}
          />
        ) : null}
        {openCloseValidateModal && (
          <UrstammModal
            testID={'close_validate_modal'}
            text={i18n.t('generics.are_you_sure_change_to_delivered')}
            textStyle={UrstammStyleModal.formBtextModalStyle}
            visible={openCloseValidateModal}
            buttonText={{
              left: i18n.t('utility_components.modal.cancel'),
              right: i18n.t('utility_components.modal.confirm')
            }}
            confirm={(confirmed: boolean) => {
              confirmed ? confirmSwitch() : null, setOpenCloseValidateModal(false);
            }}
          />
        )}
        {openCloseAlertModal && (
          <UrstammAlertModal
            testID={'pdf_modal'}
            text={i18n.t('alerts.sawmill_alerts')}
            textStyle={UrstammStyleModal.formBtextModalStyle}
            visible={openCloseAlertModal}
            buttonText={{
              center: i18n.t('utility_components.modal.confirm')
            }}
            confirm={(confirmed: boolean) => setOpenCloseAlertModal(false)}
          />
        )}

        {openCloseModalAtLeastOneTrunk && (
          <UrstammAlertModal
            testID={'pdf_modal'}
            text={i18n.t('alerts.at_least_one_trunk')}
            textStyle={UrstammStyleModal.formBtextModalStyle}
            visible={openCloseModalAtLeastOneTrunk}
            buttonText={{
              center: i18n.t('utility_components.modal.confirm')
            }}
            confirm={(confirmed: boolean) => setOpenCloseModalAtLeastOneTrunk(false)}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
