/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PushToken,
} from '../models';
import {
    PushTokenFromJSON,
    PushTokenToJSON,
} from '../models';

export interface CreateMyPushTokenRequest {
    pushToken: PushToken;
}

/**
 * 
 */
export class CustomPushTokenResourceApi extends runtime.BaseAPI {

    /**
     * Create a new PushToken for logged user.
     * Create a new PushToken for me
     */
    async createMyPushTokenRaw(requestParameters: CreateMyPushTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PushToken>> {
        if (requestParameters.pushToken === null || requestParameters.pushToken === undefined) {
            throw new runtime.RequiredError('pushToken','Required parameter requestParameters.pushToken was null or undefined when calling createMyPushToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/push-tokens`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushTokenToJSON(requestParameters.pushToken),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PushTokenFromJSON(jsonValue));
    }

    /**
     * Create a new PushToken for logged user.
     * Create a new PushToken for me
     */
    async createMyPushToken(requestParameters: CreateMyPushTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PushToken> {
        const response = await this.createMyPushTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
