/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserCompanyInvite,
} from '../models';
import {
    UserCompanyInviteFromJSON,
    UserCompanyInviteToJSON,
} from '../models';

export interface CreateUserCompanyInviteRequest {
    userCompanyInvite: UserCompanyInvite;
}

export interface DeleteUserCompanyInviteRequest {
    id: number;
}

export interface GetUserCompanyInviteRequest {
    id: number;
}

export interface PartialUpdateUserCompanyInviteRequest {
    id: number;
    userCompanyInvite: UserCompanyInvite;
}

export interface UpdateUserCompanyInviteRequest {
    id: number;
    userCompanyInvite: UserCompanyInvite;
}

/**
 * 
 */
export class UserCompanyInviteResourceApi extends runtime.BaseAPI {

    /**
     */
    async createUserCompanyInviteRaw(requestParameters: CreateUserCompanyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompanyInvite>> {
        if (requestParameters.userCompanyInvite === null || requestParameters.userCompanyInvite === undefined) {
            throw new runtime.RequiredError('userCompanyInvite','Required parameter requestParameters.userCompanyInvite was null or undefined when calling createUserCompanyInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-company-invites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCompanyInviteToJSON(requestParameters.userCompanyInvite),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyInviteFromJSON(jsonValue));
    }

    /**
     */
    async createUserCompanyInvite(requestParameters: CreateUserCompanyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompanyInvite> {
        const response = await this.createUserCompanyInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteUserCompanyInviteRaw(requestParameters: DeleteUserCompanyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserCompanyInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-company-invites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserCompanyInvite(requestParameters: DeleteUserCompanyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserCompanyInviteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllUserCompanyInvitesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserCompanyInvite>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-company-invites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserCompanyInviteFromJSON));
    }

    /**
     */
    async getAllUserCompanyInvites(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserCompanyInvite>> {
        const response = await this.getAllUserCompanyInvitesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserCompanyInviteRaw(requestParameters: GetUserCompanyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompanyInvite>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserCompanyInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-company-invites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyInviteFromJSON(jsonValue));
    }

    /**
     */
    async getUserCompanyInvite(requestParameters: GetUserCompanyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompanyInvite> {
        const response = await this.getUserCompanyInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateUserCompanyInviteRaw(requestParameters: PartialUpdateUserCompanyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompanyInvite>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateUserCompanyInvite.');
        }

        if (requestParameters.userCompanyInvite === null || requestParameters.userCompanyInvite === undefined) {
            throw new runtime.RequiredError('userCompanyInvite','Required parameter requestParameters.userCompanyInvite was null or undefined when calling partialUpdateUserCompanyInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-company-invites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserCompanyInviteToJSON(requestParameters.userCompanyInvite),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyInviteFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateUserCompanyInvite(requestParameters: PartialUpdateUserCompanyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompanyInvite> {
        const response = await this.partialUpdateUserCompanyInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserCompanyInviteRaw(requestParameters: UpdateUserCompanyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompanyInvite>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserCompanyInvite.');
        }

        if (requestParameters.userCompanyInvite === null || requestParameters.userCompanyInvite === undefined) {
            throw new runtime.RequiredError('userCompanyInvite','Required parameter requestParameters.userCompanyInvite was null or undefined when calling updateUserCompanyInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-company-invites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserCompanyInviteToJSON(requestParameters.userCompanyInvite),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyInviteFromJSON(jsonValue));
    }

    /**
     */
    async updateUserCompanyInvite(requestParameters: UpdateUserCompanyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompanyInvite> {
        const response = await this.updateUserCompanyInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
