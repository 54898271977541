import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { getMandatorySymbol } from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { UrstammStyle } from '../../../utils/styles/UrstammStyle';
import { UrstammButtonStyleProps } from './UrstammButtonBase';

export interface SortValue {
  direction: string;
  sortBy: string;
}
export interface ButtonSelect {
  text: string;
  enum?: any;
  active: boolean;
  sort?: SortValue;
}

export default function UrstammButtonSelect(props: {
  icon?: string;
  style: UrstammButtonStyleProps;
  options: ButtonSelect[];
  testID: string;
  buttonSelectTitle?: string;
  mandatory?: boolean;
  onSubmit?: (data: any) => void;
  disabled?: boolean;
}) {
  return (
    <View style={[{ flexDirection: 'column' }]}>
      {/**Title */}
      {props.buttonSelectTitle && props.buttonSelectTitle.length > 0 && (
        <View style={UrstammStyle.textTitleContainerFlex}>
          <Text style={UrstammStyle.textTitle}>{props.buttonSelectTitle + getMandatorySymbol(props.mandatory!)}</Text>
        </View>
      )}
      {props.options &&
        props.options.length > 0 &&
        props.options.map((data: ButtonSelect, idx: number) => {
          return (
            <TouchableOpacity
              key={idx}
              testID={props.testID}
              disabled={props.disabled}
              style={[
                props.style.containerViewStyle,
                props.style.buttonBorderStyle,
                UrstammStyle.baseShadow,
                {
                  backgroundColor: data.active ? ColorTheme.lightGreen : 'white'
                }
              ]}
              onPress={() => (props.onSubmit ? props.onSubmit(data) : null)}>
              <Text
                style={[
                  props.style.textStyle,
                  {
                    color: data.active ? 'white' : ColorTheme.mainGreen
                  }
                ]}>
                {data.text}
              </Text>
            </TouchableOpacity>
          );
        })}
    </View>
  );
}

const styles = StyleSheet.create({});
