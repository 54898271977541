/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProductCertification,
} from '../models';
import {
    ProductCertificationFromJSON,
    ProductCertificationToJSON,
} from '../models';

export interface CreateProductCertificationRequest {
    productCertification: ProductCertification;
}

export interface DeleteProductCertificationRequest {
    id: number;
}

export interface GetProductCertificationRequest {
    id: number;
}

export interface PartialUpdateProductCertificationRequest {
    id: number;
    productCertification: ProductCertification;
}

export interface UpdateProductCertificationRequest {
    id: number;
    productCertification: ProductCertification;
}

/**
 * 
 */
export class ProductCertificationResourceApi extends runtime.BaseAPI {

    /**
     */
    async createProductCertificationRaw(requestParameters: CreateProductCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductCertification>> {
        if (requestParameters.productCertification === null || requestParameters.productCertification === undefined) {
            throw new runtime.RequiredError('productCertification','Required parameter requestParameters.productCertification was null or undefined when calling createProductCertification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/product-certifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductCertificationToJSON(requestParameters.productCertification),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductCertificationFromJSON(jsonValue));
    }

    /**
     */
    async createProductCertification(requestParameters: CreateProductCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductCertification> {
        const response = await this.createProductCertificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProductCertificationRaw(requestParameters: DeleteProductCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProductCertification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/product-certifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProductCertification(requestParameters: DeleteProductCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProductCertificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProductCertificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductCertification>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/product-certifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductCertificationFromJSON));
    }

    /**
     */
    async getAllProductCertifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductCertification>> {
        const response = await this.getAllProductCertificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductCertificationRaw(requestParameters: GetProductCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductCertification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProductCertification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/product-certifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductCertificationFromJSON(jsonValue));
    }

    /**
     */
    async getProductCertification(requestParameters: GetProductCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductCertification> {
        const response = await this.getProductCertificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateProductCertificationRaw(requestParameters: PartialUpdateProductCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductCertification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateProductCertification.');
        }

        if (requestParameters.productCertification === null || requestParameters.productCertification === undefined) {
            throw new runtime.RequiredError('productCertification','Required parameter requestParameters.productCertification was null or undefined when calling partialUpdateProductCertification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/product-certifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProductCertificationToJSON(requestParameters.productCertification),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductCertificationFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateProductCertification(requestParameters: PartialUpdateProductCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductCertification> {
        const response = await this.partialUpdateProductCertificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProductCertificationRaw(requestParameters: UpdateProductCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductCertification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProductCertification.');
        }

        if (requestParameters.productCertification === null || requestParameters.productCertification === undefined) {
            throw new runtime.RequiredError('productCertification','Required parameter requestParameters.productCertification was null or undefined when calling updateProductCertification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/product-certifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductCertificationToJSON(requestParameters.productCertification),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductCertificationFromJSON(jsonValue));
    }

    /**
     */
    async updateProductCertification(requestParameters: UpdateProductCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductCertification> {
        const response = await this.updateProductCertificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
