/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Project,
} from '../models';
import {
    ProjectFromJSON,
    ProjectToJSON,
} from '../models';

export interface CountProjectsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    uniqueNumberContains?: string;
    uniqueNumberDoesNotContain?: string;
    uniqueNumberEquals?: string;
    uniqueNumberNotEquals?: string;
    uniqueNumberSpecified?: boolean;
    uniqueNumberIn?: Array<string>;
    uniqueNumberNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    currentStateEquals?: CountProjectsCurrentStateEqualsEnum;
    currentStateNotEquals?: CountProjectsCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<CountProjectsCurrentStateInEnum>;
    currentStateNotIn?: Array<CountProjectsCurrentStateNotInEnum>;
    latitudeGreaterThan?: number;
    latitudeLessThan?: number;
    latitudeGreaterThanOrEqual?: number;
    latitudeLessThanOrEqual?: number;
    latitudeEquals?: number;
    latitudeNotEquals?: number;
    latitudeSpecified?: boolean;
    latitudeIn?: Array<number>;
    latitudeNotIn?: Array<number>;
    longitudeGreaterThan?: number;
    longitudeLessThan?: number;
    longitudeGreaterThanOrEqual?: number;
    longitudeLessThanOrEqual?: number;
    longitudeEquals?: number;
    longitudeNotEquals?: number;
    longitudeSpecified?: boolean;
    longitudeIn?: Array<number>;
    longitudeNotIn?: Array<number>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    photoFilenameContains?: string;
    photoFilenameDoesNotContain?: string;
    photoFilenameEquals?: string;
    photoFilenameNotEquals?: string;
    photoFilenameSpecified?: boolean;
    photoFilenameIn?: Array<string>;
    photoFilenameNotIn?: Array<string>;
    photoFilepathContains?: string;
    photoFilepathDoesNotContain?: string;
    photoFilepathEquals?: string;
    photoFilepathNotEquals?: string;
    photoFilepathSpecified?: boolean;
    photoFilepathIn?: Array<string>;
    photoFilepathNotIn?: Array<string>;
    photoUrlContains?: string;
    photoUrlDoesNotContain?: string;
    photoUrlEquals?: string;
    photoUrlNotEquals?: string;
    photoUrlSpecified?: boolean;
    photoUrlIn?: Array<string>;
    photoUrlNotIn?: Array<string>;
    photoMd5Contains?: string;
    photoMd5DoesNotContain?: string;
    photoMd5Equals?: string;
    photoMd5NotEquals?: string;
    photoMd5Specified?: boolean;
    photoMd5In?: Array<string>;
    photoMd5NotIn?: Array<string>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    processorIdGreaterThan?: number;
    processorIdLessThan?: number;
    processorIdGreaterThanOrEqual?: number;
    processorIdLessThanOrEqual?: number;
    processorIdEquals?: number;
    processorIdNotEquals?: number;
    processorIdSpecified?: boolean;
    processorIdIn?: Array<number>;
    processorIdNotIn?: Array<number>;
    authorIdGreaterThan?: number;
    authorIdLessThan?: number;
    authorIdGreaterThanOrEqual?: number;
    authorIdLessThanOrEqual?: number;
    authorIdEquals?: number;
    authorIdNotEquals?: number;
    authorIdSpecified?: boolean;
    authorIdIn?: Array<number>;
    authorIdNotIn?: Array<number>;
    photoAuthorIdGreaterThan?: number;
    photoAuthorIdLessThan?: number;
    photoAuthorIdGreaterThanOrEqual?: number;
    photoAuthorIdLessThanOrEqual?: number;
    photoAuthorIdEquals?: number;
    photoAuthorIdNotEquals?: number;
    photoAuthorIdSpecified?: boolean;
    photoAuthorIdIn?: Array<number>;
    photoAuthorIdNotIn?: Array<number>;
    projectStateIdGreaterThan?: number;
    projectStateIdLessThan?: number;
    projectStateIdGreaterThanOrEqual?: number;
    projectStateIdLessThanOrEqual?: number;
    projectStateIdEquals?: number;
    projectStateIdNotEquals?: number;
    projectStateIdSpecified?: boolean;
    projectStateIdIn?: Array<number>;
    projectStateIdNotIn?: Array<number>;
    productIdGreaterThan?: number;
    productIdLessThan?: number;
    productIdGreaterThanOrEqual?: number;
    productIdLessThanOrEqual?: number;
    productIdEquals?: number;
    productIdNotEquals?: number;
    productIdSpecified?: boolean;
    productIdIn?: Array<number>;
    productIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateProjectRequest {
    project: Project;
}

export interface DeleteProjectRequest {
    id: number;
}

export interface GetAllProjectsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    uniqueNumberContains?: string;
    uniqueNumberDoesNotContain?: string;
    uniqueNumberEquals?: string;
    uniqueNumberNotEquals?: string;
    uniqueNumberSpecified?: boolean;
    uniqueNumberIn?: Array<string>;
    uniqueNumberNotIn?: Array<string>;
    commentContains?: string;
    commentDoesNotContain?: string;
    commentEquals?: string;
    commentNotEquals?: string;
    commentSpecified?: boolean;
    commentIn?: Array<string>;
    commentNotIn?: Array<string>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    currentStateEquals?: GetAllProjectsCurrentStateEqualsEnum;
    currentStateNotEquals?: GetAllProjectsCurrentStateNotEqualsEnum;
    currentStateSpecified?: boolean;
    currentStateIn?: Array<GetAllProjectsCurrentStateInEnum>;
    currentStateNotIn?: Array<GetAllProjectsCurrentStateNotInEnum>;
    latitudeGreaterThan?: number;
    latitudeLessThan?: number;
    latitudeGreaterThanOrEqual?: number;
    latitudeLessThanOrEqual?: number;
    latitudeEquals?: number;
    latitudeNotEquals?: number;
    latitudeSpecified?: boolean;
    latitudeIn?: Array<number>;
    latitudeNotIn?: Array<number>;
    longitudeGreaterThan?: number;
    longitudeLessThan?: number;
    longitudeGreaterThanOrEqual?: number;
    longitudeLessThanOrEqual?: number;
    longitudeEquals?: number;
    longitudeNotEquals?: number;
    longitudeSpecified?: boolean;
    longitudeIn?: Array<number>;
    longitudeNotIn?: Array<number>;
    sumCubageGreaterThan?: number;
    sumCubageLessThan?: number;
    sumCubageGreaterThanOrEqual?: number;
    sumCubageLessThanOrEqual?: number;
    sumCubageEquals?: number;
    sumCubageNotEquals?: number;
    sumCubageSpecified?: boolean;
    sumCubageIn?: Array<number>;
    sumCubageNotIn?: Array<number>;
    photoFilenameContains?: string;
    photoFilenameDoesNotContain?: string;
    photoFilenameEquals?: string;
    photoFilenameNotEquals?: string;
    photoFilenameSpecified?: boolean;
    photoFilenameIn?: Array<string>;
    photoFilenameNotIn?: Array<string>;
    photoFilepathContains?: string;
    photoFilepathDoesNotContain?: string;
    photoFilepathEquals?: string;
    photoFilepathNotEquals?: string;
    photoFilepathSpecified?: boolean;
    photoFilepathIn?: Array<string>;
    photoFilepathNotIn?: Array<string>;
    photoUrlContains?: string;
    photoUrlDoesNotContain?: string;
    photoUrlEquals?: string;
    photoUrlNotEquals?: string;
    photoUrlSpecified?: boolean;
    photoUrlIn?: Array<string>;
    photoUrlNotIn?: Array<string>;
    photoMd5Contains?: string;
    photoMd5DoesNotContain?: string;
    photoMd5Equals?: string;
    photoMd5NotEquals?: string;
    photoMd5Specified?: boolean;
    photoMd5In?: Array<string>;
    photoMd5NotIn?: Array<string>;
    uuidContains?: string;
    uuidDoesNotContain?: string;
    uuidEquals?: string;
    uuidNotEquals?: string;
    uuidSpecified?: boolean;
    uuidIn?: Array<string>;
    uuidNotIn?: Array<string>;
    processorIdGreaterThan?: number;
    processorIdLessThan?: number;
    processorIdGreaterThanOrEqual?: number;
    processorIdLessThanOrEqual?: number;
    processorIdEquals?: number;
    processorIdNotEquals?: number;
    processorIdSpecified?: boolean;
    processorIdIn?: Array<number>;
    processorIdNotIn?: Array<number>;
    authorIdGreaterThan?: number;
    authorIdLessThan?: number;
    authorIdGreaterThanOrEqual?: number;
    authorIdLessThanOrEqual?: number;
    authorIdEquals?: number;
    authorIdNotEquals?: number;
    authorIdSpecified?: boolean;
    authorIdIn?: Array<number>;
    authorIdNotIn?: Array<number>;
    photoAuthorIdGreaterThan?: number;
    photoAuthorIdLessThan?: number;
    photoAuthorIdGreaterThanOrEqual?: number;
    photoAuthorIdLessThanOrEqual?: number;
    photoAuthorIdEquals?: number;
    photoAuthorIdNotEquals?: number;
    photoAuthorIdSpecified?: boolean;
    photoAuthorIdIn?: Array<number>;
    photoAuthorIdNotIn?: Array<number>;
    projectStateIdGreaterThan?: number;
    projectStateIdLessThan?: number;
    projectStateIdGreaterThanOrEqual?: number;
    projectStateIdLessThanOrEqual?: number;
    projectStateIdEquals?: number;
    projectStateIdNotEquals?: number;
    projectStateIdSpecified?: boolean;
    projectStateIdIn?: Array<number>;
    projectStateIdNotIn?: Array<number>;
    productIdGreaterThan?: number;
    productIdLessThan?: number;
    productIdGreaterThanOrEqual?: number;
    productIdLessThanOrEqual?: number;
    productIdEquals?: number;
    productIdNotEquals?: number;
    productIdSpecified?: boolean;
    productIdIn?: Array<number>;
    productIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetProjectRequest {
    id: number;
}

export interface PartialUpdateProjectRequest {
    id: number;
    project: Project;
}

export interface UpdateProjectRequest {
    id: number;
    project: Project;
}

/**
 * 
 */
export class ProjectResourceApi extends runtime.BaseAPI {

    /**
     */
    async countProjectsRaw(requestParameters: CountProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.uniqueNumberContains !== undefined) {
            queryParameters['uniqueNumber.contains'] = requestParameters.uniqueNumberContains;
        }

        if (requestParameters.uniqueNumberDoesNotContain !== undefined) {
            queryParameters['uniqueNumber.doesNotContain'] = requestParameters.uniqueNumberDoesNotContain;
        }

        if (requestParameters.uniqueNumberEquals !== undefined) {
            queryParameters['uniqueNumber.equals'] = requestParameters.uniqueNumberEquals;
        }

        if (requestParameters.uniqueNumberNotEquals !== undefined) {
            queryParameters['uniqueNumber.notEquals'] = requestParameters.uniqueNumberNotEquals;
        }

        if (requestParameters.uniqueNumberSpecified !== undefined) {
            queryParameters['uniqueNumber.specified'] = requestParameters.uniqueNumberSpecified;
        }

        if (requestParameters.uniqueNumberIn) {
            queryParameters['uniqueNumber.in'] = requestParameters.uniqueNumberIn;
        }

        if (requestParameters.uniqueNumberNotIn) {
            queryParameters['uniqueNumber.notIn'] = requestParameters.uniqueNumberNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.latitudeGreaterThan !== undefined) {
            queryParameters['latitude.greaterThan'] = requestParameters.latitudeGreaterThan;
        }

        if (requestParameters.latitudeLessThan !== undefined) {
            queryParameters['latitude.lessThan'] = requestParameters.latitudeLessThan;
        }

        if (requestParameters.latitudeGreaterThanOrEqual !== undefined) {
            queryParameters['latitude.greaterThanOrEqual'] = requestParameters.latitudeGreaterThanOrEqual;
        }

        if (requestParameters.latitudeLessThanOrEqual !== undefined) {
            queryParameters['latitude.lessThanOrEqual'] = requestParameters.latitudeLessThanOrEqual;
        }

        if (requestParameters.latitudeEquals !== undefined) {
            queryParameters['latitude.equals'] = requestParameters.latitudeEquals;
        }

        if (requestParameters.latitudeNotEquals !== undefined) {
            queryParameters['latitude.notEquals'] = requestParameters.latitudeNotEquals;
        }

        if (requestParameters.latitudeSpecified !== undefined) {
            queryParameters['latitude.specified'] = requestParameters.latitudeSpecified;
        }

        if (requestParameters.latitudeIn) {
            queryParameters['latitude.in'] = requestParameters.latitudeIn;
        }

        if (requestParameters.latitudeNotIn) {
            queryParameters['latitude.notIn'] = requestParameters.latitudeNotIn;
        }

        if (requestParameters.longitudeGreaterThan !== undefined) {
            queryParameters['longitude.greaterThan'] = requestParameters.longitudeGreaterThan;
        }

        if (requestParameters.longitudeLessThan !== undefined) {
            queryParameters['longitude.lessThan'] = requestParameters.longitudeLessThan;
        }

        if (requestParameters.longitudeGreaterThanOrEqual !== undefined) {
            queryParameters['longitude.greaterThanOrEqual'] = requestParameters.longitudeGreaterThanOrEqual;
        }

        if (requestParameters.longitudeLessThanOrEqual !== undefined) {
            queryParameters['longitude.lessThanOrEqual'] = requestParameters.longitudeLessThanOrEqual;
        }

        if (requestParameters.longitudeEquals !== undefined) {
            queryParameters['longitude.equals'] = requestParameters.longitudeEquals;
        }

        if (requestParameters.longitudeNotEquals !== undefined) {
            queryParameters['longitude.notEquals'] = requestParameters.longitudeNotEquals;
        }

        if (requestParameters.longitudeSpecified !== undefined) {
            queryParameters['longitude.specified'] = requestParameters.longitudeSpecified;
        }

        if (requestParameters.longitudeIn) {
            queryParameters['longitude.in'] = requestParameters.longitudeIn;
        }

        if (requestParameters.longitudeNotIn) {
            queryParameters['longitude.notIn'] = requestParameters.longitudeNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.photoFilenameContains !== undefined) {
            queryParameters['photoFilename.contains'] = requestParameters.photoFilenameContains;
        }

        if (requestParameters.photoFilenameDoesNotContain !== undefined) {
            queryParameters['photoFilename.doesNotContain'] = requestParameters.photoFilenameDoesNotContain;
        }

        if (requestParameters.photoFilenameEquals !== undefined) {
            queryParameters['photoFilename.equals'] = requestParameters.photoFilenameEquals;
        }

        if (requestParameters.photoFilenameNotEquals !== undefined) {
            queryParameters['photoFilename.notEquals'] = requestParameters.photoFilenameNotEquals;
        }

        if (requestParameters.photoFilenameSpecified !== undefined) {
            queryParameters['photoFilename.specified'] = requestParameters.photoFilenameSpecified;
        }

        if (requestParameters.photoFilenameIn) {
            queryParameters['photoFilename.in'] = requestParameters.photoFilenameIn;
        }

        if (requestParameters.photoFilenameNotIn) {
            queryParameters['photoFilename.notIn'] = requestParameters.photoFilenameNotIn;
        }

        if (requestParameters.photoFilepathContains !== undefined) {
            queryParameters['photoFilepath.contains'] = requestParameters.photoFilepathContains;
        }

        if (requestParameters.photoFilepathDoesNotContain !== undefined) {
            queryParameters['photoFilepath.doesNotContain'] = requestParameters.photoFilepathDoesNotContain;
        }

        if (requestParameters.photoFilepathEquals !== undefined) {
            queryParameters['photoFilepath.equals'] = requestParameters.photoFilepathEquals;
        }

        if (requestParameters.photoFilepathNotEquals !== undefined) {
            queryParameters['photoFilepath.notEquals'] = requestParameters.photoFilepathNotEquals;
        }

        if (requestParameters.photoFilepathSpecified !== undefined) {
            queryParameters['photoFilepath.specified'] = requestParameters.photoFilepathSpecified;
        }

        if (requestParameters.photoFilepathIn) {
            queryParameters['photoFilepath.in'] = requestParameters.photoFilepathIn;
        }

        if (requestParameters.photoFilepathNotIn) {
            queryParameters['photoFilepath.notIn'] = requestParameters.photoFilepathNotIn;
        }

        if (requestParameters.photoUrlContains !== undefined) {
            queryParameters['photoUrl.contains'] = requestParameters.photoUrlContains;
        }

        if (requestParameters.photoUrlDoesNotContain !== undefined) {
            queryParameters['photoUrl.doesNotContain'] = requestParameters.photoUrlDoesNotContain;
        }

        if (requestParameters.photoUrlEquals !== undefined) {
            queryParameters['photoUrl.equals'] = requestParameters.photoUrlEquals;
        }

        if (requestParameters.photoUrlNotEquals !== undefined) {
            queryParameters['photoUrl.notEquals'] = requestParameters.photoUrlNotEquals;
        }

        if (requestParameters.photoUrlSpecified !== undefined) {
            queryParameters['photoUrl.specified'] = requestParameters.photoUrlSpecified;
        }

        if (requestParameters.photoUrlIn) {
            queryParameters['photoUrl.in'] = requestParameters.photoUrlIn;
        }

        if (requestParameters.photoUrlNotIn) {
            queryParameters['photoUrl.notIn'] = requestParameters.photoUrlNotIn;
        }

        if (requestParameters.photoMd5Contains !== undefined) {
            queryParameters['photoMd5.contains'] = requestParameters.photoMd5Contains;
        }

        if (requestParameters.photoMd5DoesNotContain !== undefined) {
            queryParameters['photoMd5.doesNotContain'] = requestParameters.photoMd5DoesNotContain;
        }

        if (requestParameters.photoMd5Equals !== undefined) {
            queryParameters['photoMd5.equals'] = requestParameters.photoMd5Equals;
        }

        if (requestParameters.photoMd5NotEquals !== undefined) {
            queryParameters['photoMd5.notEquals'] = requestParameters.photoMd5NotEquals;
        }

        if (requestParameters.photoMd5Specified !== undefined) {
            queryParameters['photoMd5.specified'] = requestParameters.photoMd5Specified;
        }

        if (requestParameters.photoMd5In) {
            queryParameters['photoMd5.in'] = requestParameters.photoMd5In;
        }

        if (requestParameters.photoMd5NotIn) {
            queryParameters['photoMd5.notIn'] = requestParameters.photoMd5NotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.processorIdGreaterThan !== undefined) {
            queryParameters['processorId.greaterThan'] = requestParameters.processorIdGreaterThan;
        }

        if (requestParameters.processorIdLessThan !== undefined) {
            queryParameters['processorId.lessThan'] = requestParameters.processorIdLessThan;
        }

        if (requestParameters.processorIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorId.greaterThanOrEqual'] = requestParameters.processorIdGreaterThanOrEqual;
        }

        if (requestParameters.processorIdLessThanOrEqual !== undefined) {
            queryParameters['processorId.lessThanOrEqual'] = requestParameters.processorIdLessThanOrEqual;
        }

        if (requestParameters.processorIdEquals !== undefined) {
            queryParameters['processorId.equals'] = requestParameters.processorIdEquals;
        }

        if (requestParameters.processorIdNotEquals !== undefined) {
            queryParameters['processorId.notEquals'] = requestParameters.processorIdNotEquals;
        }

        if (requestParameters.processorIdSpecified !== undefined) {
            queryParameters['processorId.specified'] = requestParameters.processorIdSpecified;
        }

        if (requestParameters.processorIdIn) {
            queryParameters['processorId.in'] = requestParameters.processorIdIn;
        }

        if (requestParameters.processorIdNotIn) {
            queryParameters['processorId.notIn'] = requestParameters.processorIdNotIn;
        }

        if (requestParameters.authorIdGreaterThan !== undefined) {
            queryParameters['authorId.greaterThan'] = requestParameters.authorIdGreaterThan;
        }

        if (requestParameters.authorIdLessThan !== undefined) {
            queryParameters['authorId.lessThan'] = requestParameters.authorIdLessThan;
        }

        if (requestParameters.authorIdGreaterThanOrEqual !== undefined) {
            queryParameters['authorId.greaterThanOrEqual'] = requestParameters.authorIdGreaterThanOrEqual;
        }

        if (requestParameters.authorIdLessThanOrEqual !== undefined) {
            queryParameters['authorId.lessThanOrEqual'] = requestParameters.authorIdLessThanOrEqual;
        }

        if (requestParameters.authorIdEquals !== undefined) {
            queryParameters['authorId.equals'] = requestParameters.authorIdEquals;
        }

        if (requestParameters.authorIdNotEquals !== undefined) {
            queryParameters['authorId.notEquals'] = requestParameters.authorIdNotEquals;
        }

        if (requestParameters.authorIdSpecified !== undefined) {
            queryParameters['authorId.specified'] = requestParameters.authorIdSpecified;
        }

        if (requestParameters.authorIdIn) {
            queryParameters['authorId.in'] = requestParameters.authorIdIn;
        }

        if (requestParameters.authorIdNotIn) {
            queryParameters['authorId.notIn'] = requestParameters.authorIdNotIn;
        }

        if (requestParameters.photoAuthorIdGreaterThan !== undefined) {
            queryParameters['photoAuthorId.greaterThan'] = requestParameters.photoAuthorIdGreaterThan;
        }

        if (requestParameters.photoAuthorIdLessThan !== undefined) {
            queryParameters['photoAuthorId.lessThan'] = requestParameters.photoAuthorIdLessThan;
        }

        if (requestParameters.photoAuthorIdGreaterThanOrEqual !== undefined) {
            queryParameters['photoAuthorId.greaterThanOrEqual'] = requestParameters.photoAuthorIdGreaterThanOrEqual;
        }

        if (requestParameters.photoAuthorIdLessThanOrEqual !== undefined) {
            queryParameters['photoAuthorId.lessThanOrEqual'] = requestParameters.photoAuthorIdLessThanOrEqual;
        }

        if (requestParameters.photoAuthorIdEquals !== undefined) {
            queryParameters['photoAuthorId.equals'] = requestParameters.photoAuthorIdEquals;
        }

        if (requestParameters.photoAuthorIdNotEquals !== undefined) {
            queryParameters['photoAuthorId.notEquals'] = requestParameters.photoAuthorIdNotEquals;
        }

        if (requestParameters.photoAuthorIdSpecified !== undefined) {
            queryParameters['photoAuthorId.specified'] = requestParameters.photoAuthorIdSpecified;
        }

        if (requestParameters.photoAuthorIdIn) {
            queryParameters['photoAuthorId.in'] = requestParameters.photoAuthorIdIn;
        }

        if (requestParameters.photoAuthorIdNotIn) {
            queryParameters['photoAuthorId.notIn'] = requestParameters.photoAuthorIdNotIn;
        }

        if (requestParameters.projectStateIdGreaterThan !== undefined) {
            queryParameters['projectStateId.greaterThan'] = requestParameters.projectStateIdGreaterThan;
        }

        if (requestParameters.projectStateIdLessThan !== undefined) {
            queryParameters['projectStateId.lessThan'] = requestParameters.projectStateIdLessThan;
        }

        if (requestParameters.projectStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['projectStateId.greaterThanOrEqual'] = requestParameters.projectStateIdGreaterThanOrEqual;
        }

        if (requestParameters.projectStateIdLessThanOrEqual !== undefined) {
            queryParameters['projectStateId.lessThanOrEqual'] = requestParameters.projectStateIdLessThanOrEqual;
        }

        if (requestParameters.projectStateIdEquals !== undefined) {
            queryParameters['projectStateId.equals'] = requestParameters.projectStateIdEquals;
        }

        if (requestParameters.projectStateIdNotEquals !== undefined) {
            queryParameters['projectStateId.notEquals'] = requestParameters.projectStateIdNotEquals;
        }

        if (requestParameters.projectStateIdSpecified !== undefined) {
            queryParameters['projectStateId.specified'] = requestParameters.projectStateIdSpecified;
        }

        if (requestParameters.projectStateIdIn) {
            queryParameters['projectStateId.in'] = requestParameters.projectStateIdIn;
        }

        if (requestParameters.projectStateIdNotIn) {
            queryParameters['projectStateId.notIn'] = requestParameters.projectStateIdNotIn;
        }

        if (requestParameters.productIdGreaterThan !== undefined) {
            queryParameters['productId.greaterThan'] = requestParameters.productIdGreaterThan;
        }

        if (requestParameters.productIdLessThan !== undefined) {
            queryParameters['productId.lessThan'] = requestParameters.productIdLessThan;
        }

        if (requestParameters.productIdGreaterThanOrEqual !== undefined) {
            queryParameters['productId.greaterThanOrEqual'] = requestParameters.productIdGreaterThanOrEqual;
        }

        if (requestParameters.productIdLessThanOrEqual !== undefined) {
            queryParameters['productId.lessThanOrEqual'] = requestParameters.productIdLessThanOrEqual;
        }

        if (requestParameters.productIdEquals !== undefined) {
            queryParameters['productId.equals'] = requestParameters.productIdEquals;
        }

        if (requestParameters.productIdNotEquals !== undefined) {
            queryParameters['productId.notEquals'] = requestParameters.productIdNotEquals;
        }

        if (requestParameters.productIdSpecified !== undefined) {
            queryParameters['productId.specified'] = requestParameters.productIdSpecified;
        }

        if (requestParameters.productIdIn) {
            queryParameters['productId.in'] = requestParameters.productIdIn;
        }

        if (requestParameters.productIdNotIn) {
            queryParameters['productId.notIn'] = requestParameters.productIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countProjects(requestParameters: CountProjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProjectRaw(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.project === null || requestParameters.project === undefined) {
            throw new runtime.RequiredError('project','Required parameter requestParameters.project was null or undefined when calling createProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters.project),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     */
    async createProject(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.createProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProjectRaw(requestParameters: DeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProject(requestParameters: DeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProjectRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProjectsRaw(requestParameters: GetAllProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Project>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.uniqueNumberContains !== undefined) {
            queryParameters['uniqueNumber.contains'] = requestParameters.uniqueNumberContains;
        }

        if (requestParameters.uniqueNumberDoesNotContain !== undefined) {
            queryParameters['uniqueNumber.doesNotContain'] = requestParameters.uniqueNumberDoesNotContain;
        }

        if (requestParameters.uniqueNumberEquals !== undefined) {
            queryParameters['uniqueNumber.equals'] = requestParameters.uniqueNumberEquals;
        }

        if (requestParameters.uniqueNumberNotEquals !== undefined) {
            queryParameters['uniqueNumber.notEquals'] = requestParameters.uniqueNumberNotEquals;
        }

        if (requestParameters.uniqueNumberSpecified !== undefined) {
            queryParameters['uniqueNumber.specified'] = requestParameters.uniqueNumberSpecified;
        }

        if (requestParameters.uniqueNumberIn) {
            queryParameters['uniqueNumber.in'] = requestParameters.uniqueNumberIn;
        }

        if (requestParameters.uniqueNumberNotIn) {
            queryParameters['uniqueNumber.notIn'] = requestParameters.uniqueNumberNotIn;
        }

        if (requestParameters.commentContains !== undefined) {
            queryParameters['comment.contains'] = requestParameters.commentContains;
        }

        if (requestParameters.commentDoesNotContain !== undefined) {
            queryParameters['comment.doesNotContain'] = requestParameters.commentDoesNotContain;
        }

        if (requestParameters.commentEquals !== undefined) {
            queryParameters['comment.equals'] = requestParameters.commentEquals;
        }

        if (requestParameters.commentNotEquals !== undefined) {
            queryParameters['comment.notEquals'] = requestParameters.commentNotEquals;
        }

        if (requestParameters.commentSpecified !== undefined) {
            queryParameters['comment.specified'] = requestParameters.commentSpecified;
        }

        if (requestParameters.commentIn) {
            queryParameters['comment.in'] = requestParameters.commentIn;
        }

        if (requestParameters.commentNotIn) {
            queryParameters['comment.notIn'] = requestParameters.commentNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.currentStateEquals !== undefined) {
            queryParameters['currentState.equals'] = requestParameters.currentStateEquals;
        }

        if (requestParameters.currentStateNotEquals !== undefined) {
            queryParameters['currentState.notEquals'] = requestParameters.currentStateNotEquals;
        }

        if (requestParameters.currentStateSpecified !== undefined) {
            queryParameters['currentState.specified'] = requestParameters.currentStateSpecified;
        }

        if (requestParameters.currentStateIn) {
            queryParameters['currentState.in'] = requestParameters.currentStateIn;
        }

        if (requestParameters.currentStateNotIn) {
            queryParameters['currentState.notIn'] = requestParameters.currentStateNotIn;
        }

        if (requestParameters.latitudeGreaterThan !== undefined) {
            queryParameters['latitude.greaterThan'] = requestParameters.latitudeGreaterThan;
        }

        if (requestParameters.latitudeLessThan !== undefined) {
            queryParameters['latitude.lessThan'] = requestParameters.latitudeLessThan;
        }

        if (requestParameters.latitudeGreaterThanOrEqual !== undefined) {
            queryParameters['latitude.greaterThanOrEqual'] = requestParameters.latitudeGreaterThanOrEqual;
        }

        if (requestParameters.latitudeLessThanOrEqual !== undefined) {
            queryParameters['latitude.lessThanOrEqual'] = requestParameters.latitudeLessThanOrEqual;
        }

        if (requestParameters.latitudeEquals !== undefined) {
            queryParameters['latitude.equals'] = requestParameters.latitudeEquals;
        }

        if (requestParameters.latitudeNotEquals !== undefined) {
            queryParameters['latitude.notEquals'] = requestParameters.latitudeNotEquals;
        }

        if (requestParameters.latitudeSpecified !== undefined) {
            queryParameters['latitude.specified'] = requestParameters.latitudeSpecified;
        }

        if (requestParameters.latitudeIn) {
            queryParameters['latitude.in'] = requestParameters.latitudeIn;
        }

        if (requestParameters.latitudeNotIn) {
            queryParameters['latitude.notIn'] = requestParameters.latitudeNotIn;
        }

        if (requestParameters.longitudeGreaterThan !== undefined) {
            queryParameters['longitude.greaterThan'] = requestParameters.longitudeGreaterThan;
        }

        if (requestParameters.longitudeLessThan !== undefined) {
            queryParameters['longitude.lessThan'] = requestParameters.longitudeLessThan;
        }

        if (requestParameters.longitudeGreaterThanOrEqual !== undefined) {
            queryParameters['longitude.greaterThanOrEqual'] = requestParameters.longitudeGreaterThanOrEqual;
        }

        if (requestParameters.longitudeLessThanOrEqual !== undefined) {
            queryParameters['longitude.lessThanOrEqual'] = requestParameters.longitudeLessThanOrEqual;
        }

        if (requestParameters.longitudeEquals !== undefined) {
            queryParameters['longitude.equals'] = requestParameters.longitudeEquals;
        }

        if (requestParameters.longitudeNotEquals !== undefined) {
            queryParameters['longitude.notEquals'] = requestParameters.longitudeNotEquals;
        }

        if (requestParameters.longitudeSpecified !== undefined) {
            queryParameters['longitude.specified'] = requestParameters.longitudeSpecified;
        }

        if (requestParameters.longitudeIn) {
            queryParameters['longitude.in'] = requestParameters.longitudeIn;
        }

        if (requestParameters.longitudeNotIn) {
            queryParameters['longitude.notIn'] = requestParameters.longitudeNotIn;
        }

        if (requestParameters.sumCubageGreaterThan !== undefined) {
            queryParameters['sumCubage.greaterThan'] = requestParameters.sumCubageGreaterThan;
        }

        if (requestParameters.sumCubageLessThan !== undefined) {
            queryParameters['sumCubage.lessThan'] = requestParameters.sumCubageLessThan;
        }

        if (requestParameters.sumCubageGreaterThanOrEqual !== undefined) {
            queryParameters['sumCubage.greaterThanOrEqual'] = requestParameters.sumCubageGreaterThanOrEqual;
        }

        if (requestParameters.sumCubageLessThanOrEqual !== undefined) {
            queryParameters['sumCubage.lessThanOrEqual'] = requestParameters.sumCubageLessThanOrEqual;
        }

        if (requestParameters.sumCubageEquals !== undefined) {
            queryParameters['sumCubage.equals'] = requestParameters.sumCubageEquals;
        }

        if (requestParameters.sumCubageNotEquals !== undefined) {
            queryParameters['sumCubage.notEquals'] = requestParameters.sumCubageNotEquals;
        }

        if (requestParameters.sumCubageSpecified !== undefined) {
            queryParameters['sumCubage.specified'] = requestParameters.sumCubageSpecified;
        }

        if (requestParameters.sumCubageIn) {
            queryParameters['sumCubage.in'] = requestParameters.sumCubageIn;
        }

        if (requestParameters.sumCubageNotIn) {
            queryParameters['sumCubage.notIn'] = requestParameters.sumCubageNotIn;
        }

        if (requestParameters.photoFilenameContains !== undefined) {
            queryParameters['photoFilename.contains'] = requestParameters.photoFilenameContains;
        }

        if (requestParameters.photoFilenameDoesNotContain !== undefined) {
            queryParameters['photoFilename.doesNotContain'] = requestParameters.photoFilenameDoesNotContain;
        }

        if (requestParameters.photoFilenameEquals !== undefined) {
            queryParameters['photoFilename.equals'] = requestParameters.photoFilenameEquals;
        }

        if (requestParameters.photoFilenameNotEquals !== undefined) {
            queryParameters['photoFilename.notEquals'] = requestParameters.photoFilenameNotEquals;
        }

        if (requestParameters.photoFilenameSpecified !== undefined) {
            queryParameters['photoFilename.specified'] = requestParameters.photoFilenameSpecified;
        }

        if (requestParameters.photoFilenameIn) {
            queryParameters['photoFilename.in'] = requestParameters.photoFilenameIn;
        }

        if (requestParameters.photoFilenameNotIn) {
            queryParameters['photoFilename.notIn'] = requestParameters.photoFilenameNotIn;
        }

        if (requestParameters.photoFilepathContains !== undefined) {
            queryParameters['photoFilepath.contains'] = requestParameters.photoFilepathContains;
        }

        if (requestParameters.photoFilepathDoesNotContain !== undefined) {
            queryParameters['photoFilepath.doesNotContain'] = requestParameters.photoFilepathDoesNotContain;
        }

        if (requestParameters.photoFilepathEquals !== undefined) {
            queryParameters['photoFilepath.equals'] = requestParameters.photoFilepathEquals;
        }

        if (requestParameters.photoFilepathNotEquals !== undefined) {
            queryParameters['photoFilepath.notEquals'] = requestParameters.photoFilepathNotEquals;
        }

        if (requestParameters.photoFilepathSpecified !== undefined) {
            queryParameters['photoFilepath.specified'] = requestParameters.photoFilepathSpecified;
        }

        if (requestParameters.photoFilepathIn) {
            queryParameters['photoFilepath.in'] = requestParameters.photoFilepathIn;
        }

        if (requestParameters.photoFilepathNotIn) {
            queryParameters['photoFilepath.notIn'] = requestParameters.photoFilepathNotIn;
        }

        if (requestParameters.photoUrlContains !== undefined) {
            queryParameters['photoUrl.contains'] = requestParameters.photoUrlContains;
        }

        if (requestParameters.photoUrlDoesNotContain !== undefined) {
            queryParameters['photoUrl.doesNotContain'] = requestParameters.photoUrlDoesNotContain;
        }

        if (requestParameters.photoUrlEquals !== undefined) {
            queryParameters['photoUrl.equals'] = requestParameters.photoUrlEquals;
        }

        if (requestParameters.photoUrlNotEquals !== undefined) {
            queryParameters['photoUrl.notEquals'] = requestParameters.photoUrlNotEquals;
        }

        if (requestParameters.photoUrlSpecified !== undefined) {
            queryParameters['photoUrl.specified'] = requestParameters.photoUrlSpecified;
        }

        if (requestParameters.photoUrlIn) {
            queryParameters['photoUrl.in'] = requestParameters.photoUrlIn;
        }

        if (requestParameters.photoUrlNotIn) {
            queryParameters['photoUrl.notIn'] = requestParameters.photoUrlNotIn;
        }

        if (requestParameters.photoMd5Contains !== undefined) {
            queryParameters['photoMd5.contains'] = requestParameters.photoMd5Contains;
        }

        if (requestParameters.photoMd5DoesNotContain !== undefined) {
            queryParameters['photoMd5.doesNotContain'] = requestParameters.photoMd5DoesNotContain;
        }

        if (requestParameters.photoMd5Equals !== undefined) {
            queryParameters['photoMd5.equals'] = requestParameters.photoMd5Equals;
        }

        if (requestParameters.photoMd5NotEquals !== undefined) {
            queryParameters['photoMd5.notEquals'] = requestParameters.photoMd5NotEquals;
        }

        if (requestParameters.photoMd5Specified !== undefined) {
            queryParameters['photoMd5.specified'] = requestParameters.photoMd5Specified;
        }

        if (requestParameters.photoMd5In) {
            queryParameters['photoMd5.in'] = requestParameters.photoMd5In;
        }

        if (requestParameters.photoMd5NotIn) {
            queryParameters['photoMd5.notIn'] = requestParameters.photoMd5NotIn;
        }

        if (requestParameters.uuidContains !== undefined) {
            queryParameters['uuid.contains'] = requestParameters.uuidContains;
        }

        if (requestParameters.uuidDoesNotContain !== undefined) {
            queryParameters['uuid.doesNotContain'] = requestParameters.uuidDoesNotContain;
        }

        if (requestParameters.uuidEquals !== undefined) {
            queryParameters['uuid.equals'] = requestParameters.uuidEquals;
        }

        if (requestParameters.uuidNotEquals !== undefined) {
            queryParameters['uuid.notEquals'] = requestParameters.uuidNotEquals;
        }

        if (requestParameters.uuidSpecified !== undefined) {
            queryParameters['uuid.specified'] = requestParameters.uuidSpecified;
        }

        if (requestParameters.uuidIn) {
            queryParameters['uuid.in'] = requestParameters.uuidIn;
        }

        if (requestParameters.uuidNotIn) {
            queryParameters['uuid.notIn'] = requestParameters.uuidNotIn;
        }

        if (requestParameters.processorIdGreaterThan !== undefined) {
            queryParameters['processorId.greaterThan'] = requestParameters.processorIdGreaterThan;
        }

        if (requestParameters.processorIdLessThan !== undefined) {
            queryParameters['processorId.lessThan'] = requestParameters.processorIdLessThan;
        }

        if (requestParameters.processorIdGreaterThanOrEqual !== undefined) {
            queryParameters['processorId.greaterThanOrEqual'] = requestParameters.processorIdGreaterThanOrEqual;
        }

        if (requestParameters.processorIdLessThanOrEqual !== undefined) {
            queryParameters['processorId.lessThanOrEqual'] = requestParameters.processorIdLessThanOrEqual;
        }

        if (requestParameters.processorIdEquals !== undefined) {
            queryParameters['processorId.equals'] = requestParameters.processorIdEquals;
        }

        if (requestParameters.processorIdNotEquals !== undefined) {
            queryParameters['processorId.notEquals'] = requestParameters.processorIdNotEquals;
        }

        if (requestParameters.processorIdSpecified !== undefined) {
            queryParameters['processorId.specified'] = requestParameters.processorIdSpecified;
        }

        if (requestParameters.processorIdIn) {
            queryParameters['processorId.in'] = requestParameters.processorIdIn;
        }

        if (requestParameters.processorIdNotIn) {
            queryParameters['processorId.notIn'] = requestParameters.processorIdNotIn;
        }

        if (requestParameters.authorIdGreaterThan !== undefined) {
            queryParameters['authorId.greaterThan'] = requestParameters.authorIdGreaterThan;
        }

        if (requestParameters.authorIdLessThan !== undefined) {
            queryParameters['authorId.lessThan'] = requestParameters.authorIdLessThan;
        }

        if (requestParameters.authorIdGreaterThanOrEqual !== undefined) {
            queryParameters['authorId.greaterThanOrEqual'] = requestParameters.authorIdGreaterThanOrEqual;
        }

        if (requestParameters.authorIdLessThanOrEqual !== undefined) {
            queryParameters['authorId.lessThanOrEqual'] = requestParameters.authorIdLessThanOrEqual;
        }

        if (requestParameters.authorIdEquals !== undefined) {
            queryParameters['authorId.equals'] = requestParameters.authorIdEquals;
        }

        if (requestParameters.authorIdNotEquals !== undefined) {
            queryParameters['authorId.notEquals'] = requestParameters.authorIdNotEquals;
        }

        if (requestParameters.authorIdSpecified !== undefined) {
            queryParameters['authorId.specified'] = requestParameters.authorIdSpecified;
        }

        if (requestParameters.authorIdIn) {
            queryParameters['authorId.in'] = requestParameters.authorIdIn;
        }

        if (requestParameters.authorIdNotIn) {
            queryParameters['authorId.notIn'] = requestParameters.authorIdNotIn;
        }

        if (requestParameters.photoAuthorIdGreaterThan !== undefined) {
            queryParameters['photoAuthorId.greaterThan'] = requestParameters.photoAuthorIdGreaterThan;
        }

        if (requestParameters.photoAuthorIdLessThan !== undefined) {
            queryParameters['photoAuthorId.lessThan'] = requestParameters.photoAuthorIdLessThan;
        }

        if (requestParameters.photoAuthorIdGreaterThanOrEqual !== undefined) {
            queryParameters['photoAuthorId.greaterThanOrEqual'] = requestParameters.photoAuthorIdGreaterThanOrEqual;
        }

        if (requestParameters.photoAuthorIdLessThanOrEqual !== undefined) {
            queryParameters['photoAuthorId.lessThanOrEqual'] = requestParameters.photoAuthorIdLessThanOrEqual;
        }

        if (requestParameters.photoAuthorIdEquals !== undefined) {
            queryParameters['photoAuthorId.equals'] = requestParameters.photoAuthorIdEquals;
        }

        if (requestParameters.photoAuthorIdNotEquals !== undefined) {
            queryParameters['photoAuthorId.notEquals'] = requestParameters.photoAuthorIdNotEquals;
        }

        if (requestParameters.photoAuthorIdSpecified !== undefined) {
            queryParameters['photoAuthorId.specified'] = requestParameters.photoAuthorIdSpecified;
        }

        if (requestParameters.photoAuthorIdIn) {
            queryParameters['photoAuthorId.in'] = requestParameters.photoAuthorIdIn;
        }

        if (requestParameters.photoAuthorIdNotIn) {
            queryParameters['photoAuthorId.notIn'] = requestParameters.photoAuthorIdNotIn;
        }

        if (requestParameters.projectStateIdGreaterThan !== undefined) {
            queryParameters['projectStateId.greaterThan'] = requestParameters.projectStateIdGreaterThan;
        }

        if (requestParameters.projectStateIdLessThan !== undefined) {
            queryParameters['projectStateId.lessThan'] = requestParameters.projectStateIdLessThan;
        }

        if (requestParameters.projectStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['projectStateId.greaterThanOrEqual'] = requestParameters.projectStateIdGreaterThanOrEqual;
        }

        if (requestParameters.projectStateIdLessThanOrEqual !== undefined) {
            queryParameters['projectStateId.lessThanOrEqual'] = requestParameters.projectStateIdLessThanOrEqual;
        }

        if (requestParameters.projectStateIdEquals !== undefined) {
            queryParameters['projectStateId.equals'] = requestParameters.projectStateIdEquals;
        }

        if (requestParameters.projectStateIdNotEquals !== undefined) {
            queryParameters['projectStateId.notEquals'] = requestParameters.projectStateIdNotEquals;
        }

        if (requestParameters.projectStateIdSpecified !== undefined) {
            queryParameters['projectStateId.specified'] = requestParameters.projectStateIdSpecified;
        }

        if (requestParameters.projectStateIdIn) {
            queryParameters['projectStateId.in'] = requestParameters.projectStateIdIn;
        }

        if (requestParameters.projectStateIdNotIn) {
            queryParameters['projectStateId.notIn'] = requestParameters.projectStateIdNotIn;
        }

        if (requestParameters.productIdGreaterThan !== undefined) {
            queryParameters['productId.greaterThan'] = requestParameters.productIdGreaterThan;
        }

        if (requestParameters.productIdLessThan !== undefined) {
            queryParameters['productId.lessThan'] = requestParameters.productIdLessThan;
        }

        if (requestParameters.productIdGreaterThanOrEqual !== undefined) {
            queryParameters['productId.greaterThanOrEqual'] = requestParameters.productIdGreaterThanOrEqual;
        }

        if (requestParameters.productIdLessThanOrEqual !== undefined) {
            queryParameters['productId.lessThanOrEqual'] = requestParameters.productIdLessThanOrEqual;
        }

        if (requestParameters.productIdEquals !== undefined) {
            queryParameters['productId.equals'] = requestParameters.productIdEquals;
        }

        if (requestParameters.productIdNotEquals !== undefined) {
            queryParameters['productId.notEquals'] = requestParameters.productIdNotEquals;
        }

        if (requestParameters.productIdSpecified !== undefined) {
            queryParameters['productId.specified'] = requestParameters.productIdSpecified;
        }

        if (requestParameters.productIdIn) {
            queryParameters['productId.in'] = requestParameters.productIdIn;
        }

        if (requestParameters.productIdNotIn) {
            queryParameters['productId.notIn'] = requestParameters.productIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectFromJSON));
    }

    /**
     */
    async getAllProjects(requestParameters: GetAllProjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Project>> {
        const response = await this.getAllProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectRaw(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     */
    async getProject(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.getProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateProjectRaw(requestParameters: PartialUpdateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateProject.');
        }

        if (requestParameters.project === null || requestParameters.project === undefined) {
            throw new runtime.RequiredError('project','Required parameter requestParameters.project was null or undefined when calling partialUpdateProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters.project),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateProject(requestParameters: PartialUpdateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.partialUpdateProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProjectRaw(requestParameters: UpdateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProject.');
        }

        if (requestParameters.project === null || requestParameters.project === undefined) {
            throw new runtime.RequiredError('project','Required parameter requestParameters.project was null or undefined when calling updateProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters.project),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     */
    async updateProject(requestParameters: UpdateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.updateProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CountProjectsCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountProjectsCurrentStateEqualsEnum = typeof CountProjectsCurrentStateEqualsEnum[keyof typeof CountProjectsCurrentStateEqualsEnum];
/**
 * @export
 */
export const CountProjectsCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountProjectsCurrentStateNotEqualsEnum = typeof CountProjectsCurrentStateNotEqualsEnum[keyof typeof CountProjectsCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const CountProjectsCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountProjectsCurrentStateInEnum = typeof CountProjectsCurrentStateInEnum[keyof typeof CountProjectsCurrentStateInEnum];
/**
 * @export
 */
export const CountProjectsCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type CountProjectsCurrentStateNotInEnum = typeof CountProjectsCurrentStateNotInEnum[keyof typeof CountProjectsCurrentStateNotInEnum];
/**
 * @export
 */
export const GetAllProjectsCurrentStateEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllProjectsCurrentStateEqualsEnum = typeof GetAllProjectsCurrentStateEqualsEnum[keyof typeof GetAllProjectsCurrentStateEqualsEnum];
/**
 * @export
 */
export const GetAllProjectsCurrentStateNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllProjectsCurrentStateNotEqualsEnum = typeof GetAllProjectsCurrentStateNotEqualsEnum[keyof typeof GetAllProjectsCurrentStateNotEqualsEnum];
/**
 * @export
 */
export const GetAllProjectsCurrentStateInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllProjectsCurrentStateInEnum = typeof GetAllProjectsCurrentStateInEnum[keyof typeof GetAllProjectsCurrentStateInEnum];
/**
 * @export
 */
export const GetAllProjectsCurrentStateNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type GetAllProjectsCurrentStateNotInEnum = typeof GetAllProjectsCurrentStateNotInEnum[keyof typeof GetAllProjectsCurrentStateNotInEnum];
