import { NetworkHelper } from '@helpers/NetworkHelper';
import { customCompanyResourceApi } from './ApiConfiguration';
import { Company, GetCompaniesConnectedToMeRequest, PageCompany } from './generated';

export class FullAvailableCompanyResourceApi {
  /**
   * Get all company list for logged user
   * @param rdxSawmillCompleteList Redux list of Company
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns Updated list of company or redux list if network is not reachable
   */
  static async getCompaniesConnectedToMe(
    rdxSawmillCompleteList: Company[],
    requestParameters: GetCompaniesConnectedToMeRequest,
    initOverrides?: RequestInit
  ): Promise<PageCompany> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk) return customCompanyResourceApi.getCompaniesConnectedToMe(requestParameters, initOverrides);
    let pageLogging: PageCompany = {
      totalElements: rdxSawmillCompleteList.length,
      content: rdxSawmillCompleteList,
      totalPages: 1
    };
    return pageLogging;
  }
}
