/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TrunkState,
} from '../models';
import {
    TrunkStateFromJSON,
    TrunkStateToJSON,
} from '../models';

export interface CreateTrunkStateRequest {
    trunkState: TrunkState;
}

export interface DeleteTrunkStateRequest {
    id: number;
}

export interface GetTrunkStateRequest {
    id: number;
}

export interface PartialUpdateTrunkStateRequest {
    id: number;
    trunkState: TrunkState;
}

export interface UpdateTrunkStateRequest {
    id: number;
    trunkState: TrunkState;
}

/**
 * 
 */
export class TrunkStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createTrunkStateRaw(requestParameters: CreateTrunkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkState>> {
        if (requestParameters.trunkState === null || requestParameters.trunkState === undefined) {
            throw new runtime.RequiredError('trunkState','Required parameter requestParameters.trunkState was null or undefined when calling createTrunkState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkStateToJSON(requestParameters.trunkState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkStateFromJSON(jsonValue));
    }

    /**
     */
    async createTrunkState(requestParameters: CreateTrunkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkState> {
        const response = await this.createTrunkStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTrunkStateRaw(requestParameters: DeleteTrunkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTrunkState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTrunkState(requestParameters: DeleteTrunkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTrunkStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllTrunkStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TrunkState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrunkStateFromJSON));
    }

    /**
     */
    async getAllTrunkStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TrunkState>> {
        const response = await this.getAllTrunkStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTrunkStateRaw(requestParameters: GetTrunkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTrunkState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trunk-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkStateFromJSON(jsonValue));
    }

    /**
     */
    async getTrunkState(requestParameters: GetTrunkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkState> {
        const response = await this.getTrunkStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateTrunkStateRaw(requestParameters: PartialUpdateTrunkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateTrunkState.');
        }

        if (requestParameters.trunkState === null || requestParameters.trunkState === undefined) {
            throw new runtime.RequiredError('trunkState','Required parameter requestParameters.trunkState was null or undefined when calling partialUpdateTrunkState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkStateToJSON(requestParameters.trunkState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateTrunkState(requestParameters: PartialUpdateTrunkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkState> {
        const response = await this.partialUpdateTrunkStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTrunkStateRaw(requestParameters: UpdateTrunkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrunkState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTrunkState.');
        }

        if (requestParameters.trunkState === null || requestParameters.trunkState === undefined) {
            throw new runtime.RequiredError('trunkState','Required parameter requestParameters.trunkState was null or undefined when calling updateTrunkState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trunk-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TrunkStateToJSON(requestParameters.trunkState),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrunkStateFromJSON(jsonValue));
    }

    /**
     */
    async updateTrunkState(requestParameters: UpdateTrunkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrunkState> {
        const response = await this.updateTrunkStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
