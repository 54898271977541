/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProductSpecies,
} from '../models';
import {
    ProductSpeciesFromJSON,
    ProductSpeciesToJSON,
} from '../models';

export interface CreateProductSpeciesRequest {
    productSpecies: ProductSpecies;
}

export interface DeleteProductSpeciesRequest {
    id: number;
}

export interface GetProductSpeciesRequest {
    id: number;
}

export interface PartialUpdateProductSpeciesRequest {
    id: number;
    productSpecies: ProductSpecies;
}

export interface UpdateProductSpeciesRequest {
    id: number;
    productSpecies: ProductSpecies;
}

/**
 * 
 */
export class ProductSpeciesResourceApi extends runtime.BaseAPI {

    /**
     */
    async createProductSpeciesRaw(requestParameters: CreateProductSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductSpecies>> {
        if (requestParameters.productSpecies === null || requestParameters.productSpecies === undefined) {
            throw new runtime.RequiredError('productSpecies','Required parameter requestParameters.productSpecies was null or undefined when calling createProductSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/product-species`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductSpeciesToJSON(requestParameters.productSpecies),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductSpeciesFromJSON(jsonValue));
    }

    /**
     */
    async createProductSpecies(requestParameters: CreateProductSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductSpecies> {
        const response = await this.createProductSpeciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProductSpeciesRaw(requestParameters: DeleteProductSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProductSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/product-species/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProductSpecies(requestParameters: DeleteProductSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProductSpeciesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProductSpeciesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductSpecies>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/product-species`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductSpeciesFromJSON));
    }

    /**
     */
    async getAllProductSpecies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductSpecies>> {
        const response = await this.getAllProductSpeciesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductSpeciesRaw(requestParameters: GetProductSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductSpecies>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProductSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/product-species/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductSpeciesFromJSON(jsonValue));
    }

    /**
     */
    async getProductSpecies(requestParameters: GetProductSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductSpecies> {
        const response = await this.getProductSpeciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async partialUpdateProductSpeciesRaw(requestParameters: PartialUpdateProductSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductSpecies>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateProductSpecies.');
        }

        if (requestParameters.productSpecies === null || requestParameters.productSpecies === undefined) {
            throw new runtime.RequiredError('productSpecies','Required parameter requestParameters.productSpecies was null or undefined when calling partialUpdateProductSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/product-species/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProductSpeciesToJSON(requestParameters.productSpecies),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductSpeciesFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateProductSpecies(requestParameters: PartialUpdateProductSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductSpecies> {
        const response = await this.partialUpdateProductSpeciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProductSpeciesRaw(requestParameters: UpdateProductSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductSpecies>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProductSpecies.');
        }

        if (requestParameters.productSpecies === null || requestParameters.productSpecies === undefined) {
            throw new runtime.RequiredError('productSpecies','Required parameter requestParameters.productSpecies was null or undefined when calling updateProductSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/product-species/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductSpeciesToJSON(requestParameters.productSpecies),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductSpeciesFromJSON(jsonValue));
    }

    /**
     */
    async updateProductSpecies(requestParameters: UpdateProductSpeciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductSpecies> {
        const response = await this.updateProductSpeciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
