import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { i18n } from '@i18n/i18n';
import { Batch, Company, ProcessorBatch, Product, UserExtended } from '@services/apis/generated';

import { ProcessorShippingNoteDTO } from '@services/apis/generated/models/ProcessorShippingNoteDTO';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByBatchState,
  getColorByProductState,
  getIconByBatchState,
  getTextByBatchState,
  getTextByDeliverySheetUrstammState,
  getTextByProductState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessor } from '../../../utils/classes/UrstammUtilityFilter';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  getCardTitleByProcessorBatchOrProduct,
  getCounterBySelected,
  getMandatorySymbol
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList
} from '../../../utils/styles/UrstammStyle';

//Future developments
export interface ProcessorShippingNoteForm {
  processorShippingNote: ProcessorShippingNoteDTO;
}

interface ProcessorBatchProps {
  processorBatchList: any[];
  processorBatchSelected: (data: any) => void;
  resetProcessorBatchSelected?: () => void;
  processorBatchSelectedID: (processorBatch: ProcessorBatch) => void;
  moreItems: () => void;
}
interface ProcessorProps {
  getAll: Function;
  processorList: any[];
  processorSelectedID: (processor: Company) => void;
}

interface ProductProps {
  productList: any[];
  productSelected: (data: any) => void;
  resetProductSelected?: () => void;
  confirmProductSelected?: () => void;
  moreItems: () => void;
}

export default function ProcessorShippingNoteRegistrationView(props: {
  navigation: any;
  submitProcessorShippingNoteForm: (data: ProcessorShippingNoteDTO) => void;
  resetProcessorShippingNoteForm: boolean;
  userExtendedMe?: UserExtended;
  processorBatchProps: ProcessorBatchProps;
  processorProps: ProcessorProps;
  productProps: ProductProps;
}) {
  const [processorShippingNoteForm, setProcessorShippingNoteForm] = useStateWithCallbackLazy<ProcessorShippingNoteForm>({
    processorShippingNote: {
      name: '',
      creationDate: new Date(),
      comment: '',
      outgoingBatch: undefined,
      processor: undefined,
      incomingProducts: undefined
    }
  });

  const [processorBatchList, setBatchList] = useStateWithCallbackLazy<any>(() => {});
  const [processorList, setProcessorList] = useStateWithCallbackLazy<any>(() => {});
  const [productList, setProductList] = useStateWithCallbackLazy<any>(() => {});
  const [showBatchSelect, setShowBatchSelect] = useState<boolean>(false);
  const [showProcessorModalSelect, setShowProcessorModalSelect] = useState<boolean>(false);
  const [showProductSelect, setShowProductSelect] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  let keys: ListSubData[] = [
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      // dataContainerViewStyle: { height: 24, flexBasis: '100%' }
    },
    {
      title: i18n.t('generics.productType') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productType',
      secondKey: 'name',
      keyId: '1productType',
      // dataContainerViewStyle: { height: 24, flexBasis: '100%' },
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('views.processor_batch.sum_volume_products') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.processor') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      secondKey: 'name',
      keyId: '1processor',
      dataViewStyle: { justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center' },
      truncateMaxLength: 250,
    }
  ];

  let processorBatchKeys: ListSubData[] = [
    {
      title: undefined,
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'currentState',
      keyId: '1currentState',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    }
  ];

  useEffect(() => {
    if (props.resetProcessorShippingNoteForm) {
      clearForm();
    }
  }, [props.resetProcessorShippingNoteForm]);

  useEffect(() => {
    if (props.processorBatchProps.processorBatchList)
      setBatchList(props.processorBatchProps.processorBatchList, updatedBatchList => {
        saveButtonDisabled(errors, processorShippingNoteForm, updatedBatchList);
      });
  }, [props.processorBatchProps.processorBatchList]);

  useEffect(() => {
    props.processorProps.getAll();
    if (props.processorProps.processorList)
      setProcessorList(props.processorProps.processorList, updatedProcessorList => {
        saveButtonDisabled(errors, processorShippingNoteForm, updatedProcessorList);
      });
  }, []);

  useEffect(() => {
    if (props.productProps.productList && processorShippingNoteForm.processorShippingNote.outgoingBatch) {
      let pl = JSON.parse(JSON.stringify(props.productProps.productList));
      pl.filter((product: Product) => product.processorBatch == processorShippingNoteForm.processorShippingNote.outgoingBatch!.id);
      setProductList(pl, updatedProductList => {
        saveButtonDisabled(errors, processorShippingNoteForm, updatedProductList);
      });
    }
  }, [props.productProps.productList]);

  /**
   * Resetting processorBatch form for new recording
   */
  const clearForm = () => {
    setProcessorShippingNoteForm(
      prevState => ({
        ...prevState,
        processorShippingNote: {
          name: '',
          comment: ''
        }
      }),
      updatedProcessorShippingNoteForm => {
        saveButtonDisabled(errors, updatedProcessorShippingNoteForm);
      }
    );
  };

  /**
   *
   * @param key
   * @param value
   */
  const updateProcessorShippingNoteForm = (key: string, value: any, mustValidate?: boolean): void => {
    let processorShippingNote = { ...processorShippingNoteForm.processorShippingNote };

    const formKey = key as keyof any; //TODO: add interface
    processorShippingNote[formKey] = value;

    return setProcessorShippingNoteForm(
      { processorShippingNote: processorShippingNote },
      mustValidate ? updatedProcessorShippingNoteForm => validate(value, key, updatedProcessorShippingNoteForm) : () => {}
    );
  };

  //Validators
  const validators = useState<any>({
    name: { required: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: []
  });

  const validate = (value: string, key: string, updatedProcessorShippingNoteForm: ProcessorShippingNoteForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedProcessorShippingNoteForm, processorBatchList)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedProcessorShippingNoteForm?: ProcessorShippingNoteForm, processorBatchList?: any) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated) || !updatedProcessorShippingNoteForm?.processorShippingNote.name) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn, UrstammStyle.flexStartX]}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** ProcessorShippingNote name */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
          <UrstammInput
            testID="shipping_note_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={processorShippingNoteForm.processorShippingNote.name ? processorShippingNoteForm.processorShippingNote.name : ''}
            onChangeText={(text: string) => {
              updateProcessorShippingNoteForm('name', text, true);
            }}
            placeholder={i18n.t('views.processor_shipping_note.processor_shipping_note_registration.insert_shipping_note_name_here')}
            textInputTitle={i18n.t('views.processor_shipping_note.processor_shipping_note_registration.shipping_note_name')}
            mandatory={true}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** ProcessorShippingNote creation date (preset: today) */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammDatePicker
            testID={'shipping_note_date'}
            containerStyle={UrstammStyleContainer.inputContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            textInputTitle={i18n.t('generics.date')}
            onChange={(dateTime: string) => updateProcessorShippingNoteForm('creationDate', dateTime)}
            icon={{
              showIcon: true,
              iconName: 'calendar',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.processor_shipping_note.processor_shipping_note_registration.insert_shipping_note_date_here')}
            mandatory={true}
            mode={'date'}
          />
        </View>

        {/** ProcessorShippingNote list */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>
              {i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_batch')}
            </Text>
          </View>

          <UrstammButtonBase
            testID={'choose_batch'}
            text={
              processorShippingNoteForm.processorShippingNote.outgoingBatch
                ? processorShippingNoteForm.processorShippingNote.outgoingBatch.name
                : i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_batch')
            }
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowBatchSelect(true)}
          />

          {/**ProcessorShippingNote Modal single select */}
          {showBatchSelect ? (
            <UrstammModalSelect
              testID={'modal_processorBatch_multiple_select'}
              visible={showBatchSelect}
              text={{
                textInputTitle: i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_batch'),
                textStyle: UrstammStyle.textTitle
              }}
              closeModal={() => {
                setShowBatchSelect(false);
              }}
              listJSX={
                <UrstammList
                  titleKey={'name'}
                  showHash={false}
                  elementSelected={(processorBatch: any) => {
                    updateProcessorShippingNoteForm('outgoingBatch', processorBatch.item, false);
                    setShowBatchSelect(false);
                    props.processorBatchProps.processorBatchSelectedID(processorBatch.item);
                  }}
                  testID={'processorBatch_list'}
                  titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
                  textForEmptyList={i18n.t('views.processor_batch.processor_batch_not_found')}
                  listSubData={processorBatchKeys}
                  list={props.processorBatchProps?.processorBatchList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                    mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                    mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  currentState={{
                    icon: state => getIconByBatchState(state),
                    color: state => getColorByBatchState(state),
                    text: state => getTextByBatchState(state)!
                  }}
                  moreItems={props.processorBatchProps?.moreItems}
                  filterList={true}
                  multipleSelection={false}
                />
              }
            />
          ) : null}
        </View>

        {/** Processor list */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>{i18n.t('generics.processor')}</Text>
          </View>

          <UrstammButtonBase
            testID={'choose_processor'}
            text={
              processorShippingNoteForm.processorShippingNote.processor
                ? processorShippingNoteForm.processorShippingNote.processor.name
                : i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_processor')
            }
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowProcessorModalSelect(true)}
          />

          {/**Product processor  */}
          {showProcessorModalSelect ? (
            <UrstammModalSelect
              testID={'modal_product_company_processor'}
              visible={showProcessorModalSelect}
              text={{
                textInputTitle: i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_processor'),
                textStyle: UrstammStyle.textTitle
              }}
              closeModal={() => {
                setShowProcessorModalSelect(false);
              }}
              confirmButton={{
                enabled: false,
                text: i18n.t('views.product.no_urstamm_processor'),
                submitValue: () => {}
              }}
              listJSX={
                <UrstammList
                  titleKey={'name'}
                  showHash={false}
                  elementSelected={(processor: any) => {
                    updateProcessorShippingNoteForm('processor', processor.item, false);
                    setShowProcessorModalSelect(false);
                    props.processorProps.processorSelectedID(processor.item);
                  }}
                  testID={'processor_list'}
                  textForEmptyList={i18n.t('views.product.processors_not_found')}
                  list={props.processorProps?.processorList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                    mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                    mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  filterList={true}
                  multipleSelection={false}
                />
              }
            />
          ) : null}
        </View>

        {/** Product list */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>
              {i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_products') + getMandatorySymbol(false)}
            </Text>
          </View>

          <UrstammButtonBase
            testID={'choose_product'}
            text={i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_products')}
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowProductSelect(true)}
            disabled={!processorShippingNoteForm.processorShippingNote.processor}
            counter={getCounterBySelected(productList)}
          />

          {/**Product Modal multiple select */}
          {showProductSelect ? (
            <UrstammModalSelect
              testID={'modal_processorBatch_multiple_select'}
              visible={showProductSelect}
              text={{
                textInputTitle: i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_products'),
                textStyle: UrstammStyle.textTitle
              }}
              closeModal={() => {
                setShowProductSelect(false),
                  props.productProps.resetProductSelected ? props.productProps.resetProductSelected() : null;
              }}
              confirmButton={{
                enabled: true,
                text: i18n.t('generics.save'),
                submitValue: () => {
                  setShowProductSelect(false),
                    props.productProps.confirmProductSelected ? props.productProps.confirmProductSelected() : null;
                }
              }}
              listJSX={
                <UrstammList
                  elementSelected={(productSelected: any) => {
                    props.productProps.productSelected(productSelected);
                    saveButtonDisabled(errors, processorShippingNoteForm, productList);
                  }}
                  showHash={false}
                  testID={'product_list'}
                  textForEmptyList={i18n.t('views.product.products_not_found')}
                  titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
                  list={productList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                    mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                    mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  listSubData={keys}
                  moreItems={props.productProps.moreItems}
                  filterList={true}
                  multipleSelection={true}
                  filterListFunction={(text, data) =>
                    filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessor(text, data, props.userExtendedMe!)
                  }
                  currentState={{
                    //icon: (state) => getIconByLoggingState(state),
                    color: state => getColorByProductState(state),
                    text: state => getTextByProductState(state)
                  }}
                  titleKey={'name'}
                  getTextForData={data => getTextByProductState(data)}
                />
              }
            />
          ) : null}
        </View>

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="processorShippingNote_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={processorShippingNoteForm.processorShippingNote.comment ? processorShippingNoteForm.processorShippingNote.comment : ''}
            onChangeText={(text: string) => updateProcessorShippingNoteForm('comment', text, false)}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/**Save */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_processorShippingNote'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() => props.submitProcessorShippingNoteForm(processorShippingNoteForm.processorShippingNote)}
            disabled={saveDisabled}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
