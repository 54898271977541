/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminUserDTO,
  KeyAndPasswordVM,
  ManagedUserVM,
  PasswordChangeDTO,
} from '../models';
import {
    AdminUserDTOFromJSON,
    AdminUserDTOToJSON,
    KeyAndPasswordVMFromJSON,
    KeyAndPasswordVMToJSON,
    ManagedUserVMFromJSON,
    ManagedUserVMToJSON,
    PasswordChangeDTOFromJSON,
    PasswordChangeDTOToJSON,
} from '../models';

export interface ActivateAccountRequest {
    key: string;
}

export interface ChangePasswordRequest {
    passwordChangeDTO: PasswordChangeDTO;
}

export interface FinishPasswordResetRequest {
    keyAndPasswordVM: KeyAndPasswordVM;
}

export interface RegisterAccountRequest {
    managedUserVM: ManagedUserVM;
}

export interface RequestPasswordReset1Request {
    body: string;
}

export interface SaveAccountRequest {
    adminUserDTO: AdminUserDTO;
}

/**
 * 
 */
export class AccountResourceApi extends runtime.BaseAPI {

    /**
     */
    async activateAccountRaw(requestParameters: ActivateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling activateAccount.');
        }

        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/activate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activateAccount(requestParameters: ActivateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateAccountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.passwordChangeDTO === null || requestParameters.passwordChangeDTO === undefined) {
            throw new runtime.RequiredError('passwordChangeDTO','Required parameter requestParameters.passwordChangeDTO was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/account/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordChangeDTOToJSON(requestParameters.passwordChangeDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changePassword(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async finishPasswordResetRaw(requestParameters: FinishPasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.keyAndPasswordVM === null || requestParameters.keyAndPasswordVM === undefined) {
            throw new runtime.RequiredError('keyAndPasswordVM','Required parameter requestParameters.keyAndPasswordVM was null or undefined when calling finishPasswordReset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/account/reset-password/finish`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KeyAndPasswordVMToJSON(requestParameters.keyAndPasswordVM),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async finishPasswordReset(requestParameters: FinishPasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.finishPasswordResetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserDTOFromJSON(jsonValue));
    }

    /**
     */
    async getAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserDTO> {
        const response = await this.getAccountRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async isAuthenticatedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/authenticate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async isAuthenticated(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.isAuthenticatedRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async registerAccountRaw(requestParameters: RegisterAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.managedUserVM === null || requestParameters.managedUserVM === undefined) {
            throw new runtime.RequiredError('managedUserVM','Required parameter requestParameters.managedUserVM was null or undefined when calling registerAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManagedUserVMToJSON(requestParameters.managedUserVM),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async registerAccount(requestParameters: RegisterAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerAccountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async requestPasswordReset1Raw(requestParameters: RequestPasswordReset1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling requestPasswordReset1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/account/reset-password/init`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestPasswordReset1(requestParameters: RequestPasswordReset1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestPasswordReset1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async saveAccountRaw(requestParameters: SaveAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.adminUserDTO === null || requestParameters.adminUserDTO === undefined) {
            throw new runtime.RequiredError('adminUserDTO','Required parameter requestParameters.adminUserDTO was null or undefined when calling saveAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUserDTOToJSON(requestParameters.adminUserDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveAccount(requestParameters: SaveAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveAccountRaw(requestParameters, initOverrides);
    }

}
