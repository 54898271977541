import { ResponseError } from '@services/apis/generated';
import { i18n } from '../i18n/i18n';

export class ErrorHelper {
  /**
   * Gets a translated error message for server code
   * @param {any} error_message
   * @returns {any}
   */
  static getTranslatedServerErrorMessage(error_message: string, interpolation?: [{ key: any; value: any }]) {
    if (interpolation) {
      let dictionary: any = {
        defaultValue: i18n.t('server_errors.error_generic')
      };
      interpolation.forEach(element => {
        dictionary[element.key] = element.value;
      });
      return i18n.t('server_errors.' + error_message, dictionary);
    }
    return i18n.t('server_errors.' + error_message, {
      defaultValue: i18n.t('server_errors.error_generic')
    });
  }

  /**
   * Gets the detail message from error
   * @param error
   */
  static async getDetailServerMessageFromJson(error: any) {
    let errorJson = this.getServerMessageFromJson(error);
    return this.getDetailFromServerMessage(errorJson);
  }

  static async getServerMessageFromJson(error: any) {
    if (!error) return null;
    return error.json ? await error.json() : null;
  }

  static async getServerMessageFromJsonOrResponse(error: any | ResponseError) {
    if (!error) return null;
    if (error.name === 'ResponseError' && error.response)
      error = error.response;
    return error.json ? await error.json() : null;
  }

  static getDetailFromServerMessage(error: any) {
    return error ? error.detail : 'error_generic';
  }

  static getStatusFromServerMessage(error: any) {
    return error ? error.status : 'error_generic';
  }
}
