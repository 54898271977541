/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomProjectCriteria,
  EntityWithFileDTOProject,
  PageProject,
  Product,
  Project,
  ProjectDTO,
} from '../models';
import {
    CustomProjectCriteriaFromJSON,
    CustomProjectCriteriaToJSON,
    EntityWithFileDTOProjectFromJSON,
    EntityWithFileDTOProjectToJSON,
    PageProjectFromJSON,
    PageProjectToJSON,
    ProductFromJSON,
    ProductToJSON,
    ProjectFromJSON,
    ProjectToJSON,
    ProjectDTOFromJSON,
    ProjectDTOToJSON,
} from '../models';

export interface CreateProjectCustomRequest {
    entityWithFileDTOProject: EntityWithFileDTOProject;
}

export interface DownloadProjectPhotoRequest {
    id: number;
}

export interface GetDSProductsByProjectRequest {
    id: number;
}

export interface GetPageProjectsForMyCompanyRequest {
    customProjectCriteria: CustomProjectCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface GetProjectCustomRequest {
    id: number;
}

export interface UpdateProjectCustomRequest {
    id: number;
    entityWithFileDTOProject: EntityWithFileDTOProject;
}

/**
 * 
 */
export class CustomProjectResourceApi extends runtime.BaseAPI {

    /**
     * Create a new Project connecting to Company of logged user.
     * Create a Project
     */
    async createProjectCustomRaw(requestParameters: CreateProjectCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.entityWithFileDTOProject === null || requestParameters.entityWithFileDTOProject === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOProject','Required parameter requestParameters.entityWithFileDTOProject was null or undefined when calling createProjectCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOProjectToJSON(requestParameters.entityWithFileDTOProject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Create a new Project connecting to Company of logged user.
     * Create a Project
     */
    async createProjectCustom(requestParameters: CreateProjectCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.createProjectCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download photo corresponding to Project identified by given id.
     * Download photo of Project
     */
    async downloadProjectPhotoRaw(requestParameters: DownloadProjectPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadProjectPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/projects/{id}/download/photo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Download photo corresponding to Project identified by given id.
     * Download photo of Project
     */
    async downloadProjectPhoto(requestParameters: DownloadProjectPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.downloadProjectPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all products for Project.
     * Get all products for Project
     */
    async getDSProductsByProjectRaw(requestParameters: GetDSProductsByProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Set<Product>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDSProductsByProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/projects/{id}/available-products`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => new Set(jsonValue.map(ProductFromJSON)));
    }

    /**
     * Get all products for Project.
     * Get all products for Project
     */
    async getDSProductsByProject(requestParameters: GetDSProductsByProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Set<Product>> {
        const response = await this.getDSProductsByProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a page of Project belonging to Company of logged User.
     * Get a page of projects for my Company
     */
    async getPageProjectsForMyCompanyRaw(requestParameters: GetPageProjectsForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageProject>> {
        if (requestParameters.customProjectCriteria === null || requestParameters.customProjectCriteria === undefined) {
            throw new runtime.RequiredError('customProjectCriteria','Required parameter requestParameters.customProjectCriteria was null or undefined when calling getPageProjectsForMyCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/projects/company/my`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomProjectCriteriaToJSON(requestParameters.customProjectCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageProjectFromJSON(jsonValue));
    }

    /**
     * Get a page of Project belonging to Company of logged User.
     * Get a page of projects for my Company
     */
    async getPageProjectsForMyCompany(requestParameters: GetPageProjectsForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageProject> {
        const response = await this.getPageProjectsForMyCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project identified by given id (must belong to Company of logged user).
     * Get a specific Project
     */
    async getProjectCustomRaw(requestParameters: GetProjectCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProjectCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/project/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectDTOFromJSON(jsonValue));
    }

    /**
     * Get Project identified by given id (must belong to Company of logged user).
     * Get a specific Project
     */
    async getProjectCustom(requestParameters: GetProjectCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectDTO> {
        const response = await this.getProjectCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update project identified by given id (must belong to Company of logged user).
     * Partial update a project
     */
    async updateProjectCustomRaw(requestParameters: UpdateProjectCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProjectCustom.');
        }

        if (requestParameters.entityWithFileDTOProject === null || requestParameters.entityWithFileDTOProject === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOProject','Required parameter requestParameters.entityWithFileDTOProject was null or undefined when calling updateProjectCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOProjectToJSON(requestParameters.entityWithFileDTOProject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Partial update project identified by given id (must belong to Company of logged user).
     * Partial update a project
     */
    async updateProjectCustom(requestParameters: UpdateProjectCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.updateProjectCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
