import UrstammDrawerNavigation from '@components/utility-components/drawer-navigation/UrstammDrawerNavigation';
import UrstammWarningIcon from '@components/utility-components/icon/UrstammWarningIcon';
import { environment } from '@environments/environment';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { customCompanyResourceApi } from '@services/apis/ApiConfiguration';
import { UserExtendedTypeEnum } from '@services/apis/generated';
import { BaseAPIManager } from '@services/model/BaseAPIManager';
import * as React from 'react';
import { useEffect } from 'react';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../config/Layout';
import BatchDetailsContainer from '../containers/batch/BatchDetailsContainer';
import BatchListContainer from '../containers/batch/BatchListContainer';
import BatchRegistrationContainer from '../containers/batch/BatchRegistrationContainer';

import ShippingNoteDetailsContainer from '../containers/shipping-note/ShippingNoteDetailsContainer';
import ShippingNoteListContainer from '../containers/shipping-note/ShippingNoteListContainer';
import ShippingNoteRegistrationContainer from '../containers/shipping-note/ShippingNoteRegistrationContainer';

import DeliverySheetDetailsContainer from '../containers/delivery-sheet/DeliverySheetDetailsContainer';
import DeliverySheetListContainer from '../containers/delivery-sheet/DeliverySheetListContainer';
import DeliverySheetRegistrationContainer from '../containers/delivery-sheet/DeliverySheetRegistrationContainer';
import LoggingDetailsContainer from '../containers/logging/LoggingDetailsContainer';
import LoggingListContainer from '../containers/logging/LoggingListContainer';
import LoggingRegistrationContainer from '../containers/logging/LoggingRegistrationContainer';
import ChangePasswordContainer from '../containers/login/ChangePasswordContainer';
import LoginContainer from '../containers/login/LoginContainer';
import ResetPasswordContainer from '../containers/login/ResetPasswordContainer';
import SignupContainer from '../containers/login/SignupContainer';
import OfflineSyncListContainer from '../containers/offlineSync/OfflineSyncListContainer';
import PacketDetailsContainer from '../containers/packet/PacketDetailsContainer';
import PacketRegistrationContainer from '../containers/packet/PacketRegistrationContainer';
import PileDetailsContainer from '../containers/pile/PileDetailsContainer';
import PileListContainer from '../containers/pile/PileListContainer';
import PileRegistrationContainer from '../containers/pile/PileRegistrationContainer';
import PlotDetailsContainer from '../containers/plot/PlotDetailsContainer';
import PlotListContainer from '../containers/plot/PlotListContainer';
import PlotRegistrationContainer from '../containers/plot/PlotRegistrationContainer';
import ProcessorBatchDetailsContainer from '../containers/processor-batch/ProcessorBatchDetailsContainer';
import ProcessorBatchListContainer from '../containers/processor-batch/ProcessorBatchListContainer';
import ProcessorBatchRegistrationContainer from '../containers/processor-batch/ProcessorBatchRegistrationContainer';
import IncomingProcessorShippingNoteListContainer from '../containers/processor-shipping-note/IncomingProcessorShippingNoteListContainer';
import OutgoingProcessorShippingNoteListContainer from '../containers/processor-shipping-note/OutgoingProcessorShippingNoteListContainer';
import ProcessorShippingNoteDetailsContainer from '../containers/processor-shipping-note/ProcessorShippingNoteDetailsContainer';
import ProcessorShippingNoteRegistrationContainer from '../containers/processor-shipping-note/ProcessorShippingNoteRegistrationContainer';
import ProductDetailsContainer from '../containers/product/ProductDetailsContainer';
import ProductRegistrationContainer from '../containers/product/ProductRegistrationContainer';
import ProjectDetailsContainer from '../containers/project/ProjectDetailsContainer';
import ProjectListContainer from '../containers/project/ProjectListContainer';
import ProjectRegistrationContainer from '../containers/project/ProjectRegistrationContainer';
import TreeDetailsContainer from '../containers/tree/TreeDetailsContainer';
import TreeListContainer from '../containers/tree/TreeListContainer';
import TreeRegistrationContainer from '../containers/tree/TreeRegistrationContainer';
import TrunkDetailsContainer from '../containers/trunk/TrunkDetailsContainer';
import TrunkListContainer from '../containers/trunk/TrunkListContainer';
import TrunkRegistrationContainer from '../containers/trunk/TrunkRegistrationContainer';
import { setMyCompany, setToken } from '../redux/features/user/userSlice';
import { RootState } from '../redux/store';
import { loggedUserIsASawmill } from '../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../utils/styles/ColorTheme';
import { UrstammFontFamily, UrstammFontsize } from '../utils/styles/UrstammFont';
import linking from './UrstammLinking';
import {
  RoutTreeRegistration,
  RouteBatchDetails,
  RouteBatchList,
  RouteBatchRegistration,
  RouteChangePassword,
  RouteDeliverySheetDetails,
  RouteDeliverySheetList,
  RouteDeliverySheetRegistration,
  RouteIncomingProcessorShippingNoteList,
  RouteLoggingDetails,
  RouteLoggingList,
  RouteLoggingRegistration,
  RouteLogin,
  RouteOutgoingProcessorShippingNoteList,
  RoutePacketDetails,
  RoutePacketRegistration,
  RoutePileDetails,
  RoutePileList,
  RoutePileRegistration,
  RoutePlotDetails,
  RoutePlotList,
  RoutePlotRegistration,
  RouteProcessorBatchDetails,
  RouteProcessorBatchList,
  RouteProcessorBatchRegistration,
  RouteProcessorShippingNoteDetails,
  RouteProcessorShippingNoteRegistration,
  RouteProductDetails,
  RouteProductRegistration,
  RouteProjectDetails,
  RouteProjectList,
  RouteProjectRegistration,
  RouteResetPassword,
  RouteShippingNoteDetails,
  RouteShippingNoteList,
  RouteShippingNoteRegistration,
  RouteSignup,
  RouteTreeDetails,
  RouteTreeList,
  RouteTrunkDetails,
  RouteTrunkList,
  RouteTrunkRegistration
} from './UrstammNavigationRoutes';

export enum StackEnum {
  logging_stack = 'LOGGING_STACK',
  delivery_sheet_stack = 'DELIVERY_SHEET_STACK',
  batch_sheet_stack = 'BATCH_STACK',
  shipment_stack = 'SHIPMENT_STACK',
  processor_shipment_stack = 'PROCESSOR_SHIPMENT_STACK',
  processor_batch_sheet_stack = 'PROCESSOR_BATCH_STACK',
  projectmanager_projects_stack = 'PROJECTMANAGER_PROJECT_STACK'
}

export default function UrstammNavigation() {
  const user = useSelector((state: RootState) => state.persistedReducer.user.value);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const rdxMyCompany = useSelector((state: RootState) => state.persistedReducer.user.myCompany);
  const dispatch = useDispatch();

  const Stack = createNativeStackNavigator();
  const Drawer = createDrawerNavigator();

  useEffect(() => {
    if (BaseAPIManager.token) {
      dispatch(setToken(BaseAPIManager.token));
      if (!rdxMyCompany && !loggedUserIsASawmill(rdxUserExtendedMe) && !loggedUserIsASawmill(rdxUserExtendedMe))
        getMyCompany();
    }

    return () => {};
  }, []);

  const getMyCompany = (): void => {
    customCompanyResourceApi
      .getMyCompany()
      .then(company => {
        if (company) {
          dispatch(setMyCompany(company));
        }
      })
      .catch(async error => {
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const LoggingStack = () => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}>
        <Stack.Screen
          name={RouteLoggingList}
          component={LoggingListContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RouteLoggingDetails}
          component={LoggingDetailsContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RouteLoggingRegistration}
          component={LoggingRegistrationContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />

        <Stack.Screen
          name={RoutePlotList}
          component={PlotListContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RoutePlotRegistration}
          component={PlotRegistrationContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RoutePlotDetails}
          component={PlotDetailsContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />

        <Stack.Screen
          name={RoutePileList}
          component={PileListContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RoutePileDetails}
          component={PileDetailsContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RoutePileRegistration}
          component={PileRegistrationContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />

        <Stack.Screen
          name={RouteTreeList}
          component={TreeListContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RouteTreeDetails}
          component={TreeDetailsContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RoutTreeRegistration}
          component={TreeRegistrationContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />

        <Stack.Screen
          name={RouteDeliverySheetList}
          component={DeliverySheetListContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RouteDeliverySheetDetails}
          component={DeliverySheetDetailsContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RouteDeliverySheetRegistration}
          component={DeliverySheetRegistrationContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />

        <Stack.Screen
          name={RouteTrunkList}
          component={TrunkListContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RouteTrunkDetails}
          component={TrunkDetailsContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
        <Stack.Screen
          name={RouteTrunkRegistration}
          component={TrunkRegistrationContainer}
          initialParams={{ stack: StackEnum.logging_stack }}
        />
      </Stack.Navigator>
    );
  };

  const DeliverySheetForestryStack = () => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}>
        <Stack.Screen
          name={RouteDeliverySheetList}
          component={DeliverySheetListContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />
        <Stack.Screen
          name={RouteDeliverySheetDetails}
          component={DeliverySheetDetailsContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />
        <Stack.Screen
          name={RouteDeliverySheetRegistration}
          component={DeliverySheetRegistrationContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />

        <Stack.Screen
          name={RouteTrunkList}
          component={TrunkListContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />
        <Stack.Screen
          name={RouteTrunkDetails}
          component={TrunkDetailsContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />
        <Stack.Screen
          name={RouteTrunkRegistration}
          component={TrunkRegistrationContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />

        <Stack.Screen
          name={RouteBatchList}
          component={BatchListContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />
        <Stack.Screen
          name={RouteBatchDetails}
          component={BatchDetailsContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />
        <Stack.Screen
          name={RouteBatchRegistration}
          component={BatchRegistrationContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />

        <Stack.Screen
          name={RouteShippingNoteList}
          component={ShippingNoteListContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />
        <Stack.Screen
          name={RouteShippingNoteDetails}
          component={ShippingNoteDetailsContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />
        <Stack.Screen
          name={RouteShippingNoteRegistration}
          component={ShippingNoteRegistrationContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />

        <Stack.Screen
          name={RoutePacketRegistration}
          component={PacketRegistrationContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />
        <Stack.Screen
          name={RoutePacketDetails}
          component={PacketDetailsContainer}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
        />
      </Stack.Navigator>
    );
  };

  const ShipmentStack = () => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}>
        <Stack.Screen
          name={RouteShippingNoteList}
          component={ShippingNoteListContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />
        <Stack.Screen
          name={RouteShippingNoteDetails}
          component={ShippingNoteDetailsContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />
        <Stack.Screen
          name={RouteShippingNoteRegistration}
          component={ShippingNoteRegistrationContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />

        <Stack.Screen
          name={RouteDeliverySheetList}
          component={DeliverySheetListContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />
        <Stack.Screen
          name={RouteDeliverySheetDetails}
          component={DeliverySheetDetailsContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />
        <Stack.Screen
          name={RouteDeliverySheetRegistration}
          component={DeliverySheetRegistrationContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />

        <Stack.Screen
          name={RouteTrunkList}
          component={TrunkListContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />
        <Stack.Screen
          name={RouteTrunkDetails}
          component={TrunkDetailsContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />
        <Stack.Screen
          name={RouteTrunkRegistration}
          component={TrunkRegistrationContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />

        <Stack.Screen
          name={RouteBatchList}
          component={BatchListContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />
        <Stack.Screen
          name={RouteBatchDetails}
          component={BatchDetailsContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />
        <Stack.Screen
          name={RouteBatchRegistration}
          component={BatchRegistrationContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />

        <Stack.Screen
          name={RoutePacketRegistration}
          component={PacketRegistrationContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />
        <Stack.Screen
          name={RoutePacketDetails}
          component={PacketDetailsContainer}
          initialParams={{ stack: StackEnum.shipment_stack }}
        />
      </Stack.Navigator>
    );
  };

  const BatchStack = () => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}>
        <Stack.Screen
          name={RouteBatchList}
          component={BatchListContainer}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
        />
        <Stack.Screen
          name={RouteBatchDetails}
          component={BatchDetailsContainer}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
        />
        <Stack.Screen
          name={RouteBatchRegistration}
          component={BatchRegistrationContainer}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
        />

        <Stack.Screen
          name={RouteDeliverySheetList}
          component={DeliverySheetListContainer}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
        />
        <Stack.Screen
          name={RouteDeliverySheetDetails}
          component={DeliverySheetDetailsContainer}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
        />
        <Stack.Screen
          name={RouteDeliverySheetRegistration}
          component={DeliverySheetRegistrationContainer}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
        />

        <Stack.Screen
          name={RouteTrunkList}
          component={TrunkListContainer}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
        />
        <Stack.Screen
          name={RouteTrunkDetails}
          component={TrunkDetailsContainer}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
        />
        <Stack.Screen
          name={RouteTrunkRegistration}
          component={TrunkRegistrationContainer}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
        />

        <Stack.Screen
          name={RoutePacketRegistration}
          component={PacketRegistrationContainer}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
        />
        <Stack.Screen
          name={RoutePacketDetails}
          component={PacketDetailsContainer}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
        />
      </Stack.Navigator>
    );
  };

  const ProcessorShippingNoteStack = (outgoing = false) => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}>
        <Stack.Screen
          name={outgoing ? RouteOutgoingProcessorShippingNoteList : RouteIncomingProcessorShippingNoteList}
          component={outgoing ? OutgoingProcessorShippingNoteListContainer : IncomingProcessorShippingNoteListContainer}
          initialParams={{ stack: StackEnum.processor_shipment_stack }}
        />
        <Stack.Screen
          name={RouteProcessorShippingNoteDetails}
          component={ProcessorShippingNoteDetailsContainer}
          initialParams={{ stack: StackEnum.processor_shipment_stack, outgoing: outgoing }}
        />
        <Stack.Screen
          name={RouteProcessorShippingNoteRegistration}
          component={ProcessorShippingNoteRegistrationContainer}
          initialParams={{ stack: StackEnum.processor_shipment_stack }}
        />

        <Stack.Screen
          name={RouteBatchList}
          component={ProcessorBatchListContainer}
          initialParams={{ stack: StackEnum.processor_shipment_stack }}
        />
        <Stack.Screen
          name={RouteProcessorBatchDetails}
          component={ProcessorBatchDetailsContainer}
          initialParams={{ stack: StackEnum.processor_shipment_stack }}
        />
        <Stack.Screen
          name={RouteProcessorBatchRegistration}
          component={ProcessorBatchRegistrationContainer}
          initialParams={{ stack: StackEnum.processor_shipment_stack }}
        />

        <Stack.Screen
          name={RouteProductRegistration}
          component={ProductRegistrationContainer}
          initialParams={{ stack: StackEnum.processor_shipment_stack }}
        />
        <Stack.Screen
          name={RouteProductDetails}
          component={ProductDetailsContainer}
          initialParams={{ stack: StackEnum.processor_shipment_stack }}
        />
      </Stack.Navigator>
    );
  };

  const ProjectProcessorShippingNoteIncomingStack = () => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}>
        <Stack.Screen
          name={RouteIncomingProcessorShippingNoteList}
          component={IncomingProcessorShippingNoteListContainer}
          initialParams={{ stack: StackEnum.projectmanager_projects_stack }}
        />
        <Stack.Screen
          name={RouteProcessorShippingNoteDetails}
          component={ProcessorShippingNoteDetailsContainer}
          initialParams={{ stack: StackEnum.projectmanager_projects_stack, outgoing: false }}
        />

        {/* <Stack.Screen
          name={RouteBatchList}
          component={ProcessorBatchListContainer}
          initialParams={{ stack: StackEnum.projectmanager_projects_stack }}
        /> */}
        {/* <Stack.Screen
          name={RouteProcessorBatchDetails}
          component={ProcessorBatchDetailsContainer}
          initialParams={{ stack: StackEnum.projectmanager_projects_stack }}
        /> */}

        <Stack.Screen
          name={RouteProductDetails}
          component={ProductDetailsContainer}
          initialParams={{ stack: StackEnum.projectmanager_projects_stack }}
        />
      </Stack.Navigator>
    );
  };

  const ProjectManagerProjectStack = () => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}>
        <Stack.Screen
          name={RouteProjectList}
          component={ProjectListContainer}
          initialParams={{ stack: StackEnum.projectmanager_projects_stack }}
        />

        <Stack.Screen
          name={RouteProjectRegistration}
          component={ProjectRegistrationContainer}
          initialParams={{ stack: StackEnum.projectmanager_projects_stack }}
        />
        <Stack.Screen
          name={RouteProjectDetails}
          component={ProjectDetailsContainer}
          initialParams={{ stack: StackEnum.projectmanager_projects_stack }}
        />

        <Stack.Screen
          name={RouteProductDetails}
          component={ProductDetailsContainer}
          initialParams={{ stack: StackEnum.projectmanager_projects_stack }}
        />
      </Stack.Navigator>
    );
  };

  const ProcessorShippingNoteIncomingStack = () => {
    return ProcessorShippingNoteStack(false);
  };

  const ProcessorShippingNoteOutgoingStack = () => {
    return ProcessorShippingNoteStack(true);
  };

  const ProcessorBatchStack = () => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}>
        <Stack.Screen
          name={RouteProcessorBatchList}
          component={ProcessorBatchListContainer}
          initialParams={{ stack: StackEnum.processor_batch_sheet_stack }}
        />
        <Stack.Screen
          name={RouteProcessorBatchDetails}
          component={ProcessorBatchDetailsContainer}
          initialParams={{ stack: StackEnum.processor_batch_sheet_stack }}
        />
        <Stack.Screen
          name={RouteProcessorBatchRegistration}
          component={ProcessorBatchRegistrationContainer}
          initialParams={{ stack: StackEnum.processor_batch_sheet_stack }}
        />

        <Stack.Screen
          name={RouteIncomingProcessorShippingNoteList}
          component={IncomingProcessorShippingNoteListContainer}
          initialParams={{ stack: StackEnum.processor_batch_sheet_stack }}
        />
        <Stack.Screen
          name={RouteProcessorShippingNoteDetails}
          component={ProcessorShippingNoteDetailsContainer}
          initialParams={{ stack: StackEnum.processor_batch_sheet_stack }}
        />
        <Stack.Screen
          name={RouteProcessorShippingNoteRegistration}
          component={ProcessorShippingNoteRegistrationContainer}
          initialParams={{ stack: StackEnum.processor_batch_sheet_stack }}
        />

        <Stack.Screen
          name={RouteProductDetails}
          component={ProductDetailsContainer}
          initialParams={{ stack: StackEnum.processor_batch_sheet_stack }}
        />
        <Stack.Screen
          name={RouteProductRegistration}
          component={ProductRegistrationContainer}
          initialParams={{ stack: StackEnum.processor_batch_sheet_stack }}
        />
      </Stack.Navigator>
    );
  };

  const AuthStack = () => {
    return (
      <Stack.Navigator
        initialRouteName={RouteLogin}
        screenOptions={{
          headerShown: false
        }}>
        <Stack.Screen name={RouteLogin} component={LoginContainer} />
        <Stack.Screen name={RouteSignup} component={SignupContainer} />
        <Stack.Screen name={RouteResetPassword} component={ResetPasswordContainer} />
        <Stack.Screen name={RouteChangePassword} component={ChangePasswordContainer} />
      </Stack.Navigator>
    );
  };

  const DrawerNavigatorForestry = () => {
    return (
      <Drawer.Navigator
        initialRouteName={RouteLoggingList}
        screenOptions={{
          headerShown: false,
          drawerStyle: {
            width: Layout.window.width * 0.8
          }
        }}
        drawerContent={props => <UrstammDrawerNavigation {...props} />}>
        <Drawer.Screen
          name={i18n.t('generics.logging')}
          component={LoggingStack}
          initialParams={{ stack: StackEnum.logging_stack }}
          options={{
            headerTitleStyle: { fontFamily: 'Gugi-Regular' },
            //drawerLabelStyle: {color: 'white'},
            //drawerItemStyle: {backgroundColor: ColorTheme.lightGrey},
            drawerActiveTintColor: ColorTheme.ivory,
            drawerActiveBackgroundColor: ColorTheme.mainGreen,
            drawerInactiveTintColor: ColorTheme.lightGreen,
            drawerInactiveBackgroundColor: 'white',
            drawerItemStyle: { marginTop: 100 },
            drawerLabelStyle: [
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]
          }}
        />
        <Drawer.Screen
          name={i18n.t('generics.new_tree_recording')}
          component={TreeRegistrationContainer}
          initialParams={{ newTreeRecordingFromDrawer: true }}
          options={{
            headerTitleStyle: { fontFamily: 'Gugi-Regular' },
            //drawerLabelStyle: {color: 'white'},
            //drawerItemStyle: {backgroundColor: ColorTheme.lightGrey},
            drawerActiveTintColor: ColorTheme.ivory,
            drawerActiveBackgroundColor: ColorTheme.mainGreen,
            drawerInactiveTintColor: ColorTheme.lightGreen,
            drawerInactiveBackgroundColor: 'white',
            drawerLabelStyle: [
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]
          }}
        />
        <Drawer.Screen
          name={i18n.t('generics.delivery_sheet')}
          component={DeliverySheetForestryStack}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
          options={{
            headerTitleStyle: { fontFamily: 'Gugi-Regular' },
            //drawerLabelStyle: {color: 'white'},
            //drawerItemStyle: {backgroundColor: ColorTheme.lightGrey},
            drawerActiveTintColor: ColorTheme.ivory,
            drawerActiveBackgroundColor: ColorTheme.mainGreen,
            drawerInactiveTintColor: ColorTheme.lightGreen,
            drawerInactiveBackgroundColor: 'white',
            drawerLabelStyle: [
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]
          }}
        />
        {environment.enableOfflineSync ? (
          <Drawer.Screen
            name={i18n.t('generics.offline_sync')}
            component={OfflineSyncListContainer}
            initialParams={{ newTreeRecordingFromDrawer: true }}
            options={{
              headerTitleStyle: { fontFamily: 'Gugi-Regular' },
              //drawerLabelStyle: {color: 'white'},
              //drawerItemStyle: {backgroundColor: ColorTheme.lightGrey},
              drawerActiveTintColor: ColorTheme.ivory,
              drawerActiveBackgroundColor: ColorTheme.mainGreen,
              drawerInactiveTintColor: ColorTheme.lightGreen,
              drawerInactiveBackgroundColor: 'white',
              drawerLabelStyle: [
                {
                  fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                  fontFamily: UrstammFontFamily.lightFont
                }
              ],
              drawerLabel: props => (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}>
                  <Text
                    style={{
                      color: props.color,
                      fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                      fontFamily: UrstammFontFamily.lightFont
                    }}>
                    {i18n.t('generics.offline_sync')}
                  </Text>
                  <UrstammWarningIcon />
                </View>
              )
            }}
          />
        ) : null}
      </Drawer.Navigator>
    );
  };

  const DrawerNavigatorSawmill = () => {
    return (
      <Drawer.Navigator
        initialRouteName={RouteBatchList}
        screenOptions={{
          headerShown: false,
          drawerStyle: {
            width: Layout.window.width * 0.8
          }
        }}
        drawerContent={props => <UrstammDrawerNavigation {...props} />}>
        <Drawer.Screen
          name={i18n.t('generics.delivery_sheet')}
          component={DeliverySheetForestryStack}
          initialParams={{ stack: StackEnum.delivery_sheet_stack }}
          options={{
            headerTitleStyle: { fontFamily: 'Gugi-Regular' },
            //drawerLabelStyle: {color: 'white'},
            //drawerItemStyle: {backgroundColor: ColorTheme.lightGrey},
            drawerActiveTintColor: ColorTheme.ivory,
            drawerActiveBackgroundColor: ColorTheme.mainGreen,
            drawerInactiveTintColor: ColorTheme.lightGreen,
            drawerInactiveBackgroundColor: 'white',
            drawerItemStyle: { marginTop: 100 },
            drawerLabelStyle: [
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]
          }}
        />
        <Drawer.Screen
          name={i18n.t('generics.batch')}
          component={BatchStack}
          initialParams={{ stack: StackEnum.batch_sheet_stack }}
          options={{
            headerTitleStyle: { fontFamily: 'Gugi-Regular' },
            //drawerLabelStyle: {color: 'white'},
            //drawerItemStyle: {backgroundColor: ColorTheme.lightGrey},
            drawerActiveTintColor: ColorTheme.ivory,
            drawerActiveBackgroundColor: ColorTheme.mainGreen,
            drawerInactiveTintColor: ColorTheme.lightGreen,
            drawerInactiveBackgroundColor: 'white',
            drawerLabelStyle: [
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]
          }}
        />
        <Drawer.Screen
          name={i18n.t('generics.shipment')}
          component={ShipmentStack}
          initialParams={{ stack: StackEnum.shipment_stack }}
          options={{
            headerTitleStyle: { fontFamily: 'Gugi-Regular' },
            drawerActiveTintColor: ColorTheme.ivory,
            drawerActiveBackgroundColor: ColorTheme.mainGreen,
            drawerInactiveTintColor: ColorTheme.lightGreen,
            drawerInactiveBackgroundColor: 'white',
            drawerLabelStyle: [
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]
          }}
        />
      </Drawer.Navigator>
    );
  };

  const DrawerNavigatorProcessor = () => {
    return (
      <Drawer.Navigator
        initialRouteName={RouteIncomingProcessorShippingNoteList}
        screenOptions={{
          headerShown: false,
          drawerStyle: {
            width: Layout.window.width * 0.8
          }
        }}
        drawerContent={props => <UrstammDrawerNavigation {...props} />}>
        <Drawer.Screen
          name={i18n.t('generics.incoming_goods')}
          component={ProcessorShippingNoteIncomingStack}
          initialParams={{ stack: StackEnum.processor_shipment_stack }}
          options={{
            headerTitleStyle: { fontFamily: 'Gugi-Regular' },
            drawerActiveTintColor: ColorTheme.ivory,
            drawerActiveBackgroundColor: ColorTheme.mainGreen,
            drawerInactiveTintColor: ColorTheme.lightGreen,
            drawerInactiveBackgroundColor: 'white',
            drawerItemStyle: { marginTop: 100 },
            drawerLabelStyle: [
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]
          }}
        />

        <Drawer.Screen
          name={i18n.t('generics.production')}
          component={ProcessorBatchStack}
          initialParams={{ stack: StackEnum.processor_batch_sheet_stack }}
          options={{
            headerTitleStyle: { fontFamily: 'Gugi-Regular' },

            drawerActiveTintColor: ColorTheme.ivory,
            drawerActiveBackgroundColor: ColorTheme.mainGreen,
            drawerInactiveTintColor: ColorTheme.lightGreen,
            drawerInactiveBackgroundColor: 'white',
            drawerLabelStyle: [
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]
          }}
        />
        <Drawer.Screen
          name={i18n.t('generics.outgoing_goods')}
          component={ProcessorShippingNoteOutgoingStack}
          initialParams={{ stack: StackEnum.processor_shipment_stack }}
          options={{
            headerTitleStyle: { fontFamily: 'Gugi-Regular' },
            drawerActiveTintColor: ColorTheme.ivory,
            drawerActiveBackgroundColor: ColorTheme.mainGreen,
            drawerInactiveTintColor: ColorTheme.lightGreen,
            drawerInactiveBackgroundColor: 'white',
            drawerLabelStyle: [
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]
          }}
        />
      </Drawer.Navigator>
    );
  };

  const DrawerNavigatorProjectManager = () => {
    return (
      <Drawer.Navigator
        initialRouteName={RouteProjectList}
        screenOptions={{
          headerShown: false,
          drawerStyle: {
            width: Layout.window.width * 0.8
          }
        }}
        drawerContent={props => <UrstammDrawerNavigation {...props} />}>
        <Drawer.Screen
          name={i18n.t('generics.incoming_goods')}
          component={ProjectProcessorShippingNoteIncomingStack}
          initialParams={{ stack: StackEnum.processor_shipment_stack }}
          options={{
            headerTitleStyle: { fontFamily: 'Gugi-Regular' },
            drawerActiveTintColor: ColorTheme.ivory,
            drawerActiveBackgroundColor: ColorTheme.mainGreen,
            drawerInactiveTintColor: ColorTheme.lightGreen,
            drawerInactiveBackgroundColor: 'white',
            drawerItemStyle: { marginTop: 100 },
            drawerLabelStyle: [
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]
          }}
        />
        <Drawer.Screen
          name={i18n.t('generics.projects')}
          component={ProjectManagerProjectStack}
          initialParams={{ stack: StackEnum.projectmanager_projects_stack }}
          options={{
            headerTitleStyle: { fontFamily: 'Gugi-Regular' },

            drawerActiveTintColor: ColorTheme.ivory,
            drawerActiveBackgroundColor: ColorTheme.mainGreen,
            drawerInactiveTintColor: ColorTheme.lightGreen,
            drawerInactiveBackgroundColor: 'white',
            drawerLabelStyle: [
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]
          }}
        />
      </Drawer.Navigator>
    );
  };

  const RootNavigation = () => {
    return (
      <NavigationContainer linking={linking}>
        {user && rdxUserExtendedMe.type == UserExtendedTypeEnum.Forestry ? (
          <DrawerNavigatorForestry />
        ) : user && rdxUserExtendedMe.type == UserExtendedTypeEnum.Processor ? (
          <DrawerNavigatorProcessor />
        ) : user && rdxUserExtendedMe.type == UserExtendedTypeEnum.Sawmill ? (
          <DrawerNavigatorSawmill />
        ) : user && rdxUserExtendedMe.type == UserExtendedTypeEnum.Projectmanager ? (
          <DrawerNavigatorProjectManager />
        ) : (
          <AuthStack />
        )}
      </NavigationContainer>
    );
  };

  return <RootNavigation />;
}
