/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProcessorBatchStateEnumFilter
 */
export interface ProcessorBatchStateEnumFilter {
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatchStateEnumFilter
     */
    equals?: ProcessorBatchStateEnumFilterEqualsEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessorBatchStateEnumFilter
     */
    notEquals?: ProcessorBatchStateEnumFilterNotEqualsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessorBatchStateEnumFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorBatchStateEnumFilter
     */
    _in?: Array<ProcessorBatchStateEnumFilterInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorBatchStateEnumFilter
     */
    notIn?: Array<ProcessorBatchStateEnumFilterNotInEnum>;
}


/**
 * @export
 */
export const ProcessorBatchStateEnumFilterEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProcessorBatchStateEnumFilterEqualsEnum = typeof ProcessorBatchStateEnumFilterEqualsEnum[keyof typeof ProcessorBatchStateEnumFilterEqualsEnum];

/**
 * @export
 */
export const ProcessorBatchStateEnumFilterNotEqualsEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProcessorBatchStateEnumFilterNotEqualsEnum = typeof ProcessorBatchStateEnumFilterNotEqualsEnum[keyof typeof ProcessorBatchStateEnumFilterNotEqualsEnum];

/**
 * @export
 */
export const ProcessorBatchStateEnumFilterInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProcessorBatchStateEnumFilterInEnum = typeof ProcessorBatchStateEnumFilterInEnum[keyof typeof ProcessorBatchStateEnumFilterInEnum];

/**
 * @export
 */
export const ProcessorBatchStateEnumFilterNotInEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type ProcessorBatchStateEnumFilterNotInEnum = typeof ProcessorBatchStateEnumFilterNotInEnum[keyof typeof ProcessorBatchStateEnumFilterNotInEnum];


/**
 * Check if a given object implements the ProcessorBatchStateEnumFilter interface.
 */
export function instanceOfProcessorBatchStateEnumFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProcessorBatchStateEnumFilterFromJSON(json: any): ProcessorBatchStateEnumFilter {
    return ProcessorBatchStateEnumFilterFromJSONTyped(json, false);
}

export function ProcessorBatchStateEnumFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessorBatchStateEnumFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
        'notEquals': !exists(json, 'notEquals') ? undefined : json['notEquals'],
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
    };
}

export function ProcessorBatchStateEnumFilterToJSON(value?: ProcessorBatchStateEnumFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals,
        'notEquals': value.notEquals,
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
    };
}

