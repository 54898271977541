import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface UrstammImageNewTree {
  width?: number;
  height?: number;
  showText?: boolean;
}

const UrstammImageNewTree = (props: UrstammImageNewTree) => {
  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 42 48" fill="none">
      <Path
        d="M14.8516 12.6295C14.7494 12.4764 14.611 12.3508 14.4487 12.2639C14.2864 12.177 14.1051 12.1316 13.921 12.1316C13.7369 12.1316 13.5557 12.177 13.3933 12.2639C13.231 12.3508 13.0926 12.4764 12.9905 12.6295L6.27997 22.6953C6.16789 22.8638 6.1036 23.0594 6.09393 23.2615C6.08426 23.4636 6.12959 23.6645 6.22508 23.8428C6.32056 24.0212 6.46264 24.1703 6.63617 24.2743C6.8097 24.3783 7.00819 24.4333 7.2105 24.4334H7.42971L2.90681 31.6696C2.801 31.8389 2.74243 32.0334 2.73719 32.233C2.73195 32.4325 2.78023 32.6299 2.87701 32.8045C2.97379 32.9791 3.11555 33.1246 3.28756 33.226C3.45958 33.3273 3.65559 33.3807 3.85523 33.3807H4.28247L0.618516 40.7087C0.533072 40.8792 0.492652 41.0687 0.501097 41.2592C0.509543 41.4498 0.566573 41.635 0.666767 41.7973C0.766962 41.9595 0.906993 42.0935 1.07355 42.1864C1.24011 42.2793 1.42766 42.3281 1.61839 42.3281H11.6842V47.9202H16.1579V42.3281H26.2237C26.4144 42.3281 26.602 42.2793 26.7685 42.1864C26.9351 42.0935 27.0751 41.9595 27.1753 41.7973C27.2755 41.635 27.3325 41.4498 27.341 41.2592C27.3494 41.0687 27.309 40.8792 27.2235 40.7087L23.5618 33.3807H23.9891C24.1887 33.3807 24.3847 33.3273 24.5567 33.226C24.7288 33.1246 24.8705 32.9791 24.9673 32.8045C25.0641 32.6299 25.1123 32.4325 25.1071 32.233C25.1019 32.0334 25.0433 31.8389 24.9375 31.6696L20.4124 24.4334H20.6316C20.8339 24.4333 21.0324 24.3783 21.2059 24.2743C21.3794 24.1703 21.5215 24.0212 21.617 23.8428C21.7125 23.6645 21.7578 23.4636 21.7481 23.2615C21.7385 23.0594 21.6742 22.8638 21.5621 22.6953L14.8516 12.6295Z"
        fill="white"
      />
      <Path
        d="M29.5 18V6M23.5 12H35.5"
        stroke="white"
        strokeWidth="1.65517"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default UrstammImageNewTree;
