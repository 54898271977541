import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammSelect from '@components/utility-components/select/UrstammSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import { i18n } from '@i18n/i18n';
import { AdminUserFullDTO } from '@services/apis/generated';
import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { UrstammNavigationHelper } from '../../../navigation/UrstammNavigationHelper';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { mainGreenButton, mediumGreyButton } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammStyle, UrstammStyleContainer, UrstammStyleSelect } from '../../../utils/styles/UrstammStyle';

export default function SignupView(props: { navigation: any; createAccount: (user: AdminUserFullDTO) => void }) {
  const [accountData, setAccountData] = useState<AdminUserFullDTO>({
    firstName: '',
    lastName: '',
    //function: '',
    login: '',
    email: ''
  });

  const submit = (): void => {
    props.createAccount(accountData);
  };

  const navigateToLogin = (): void => {
    UrstammNavigationHelper.navigateToLogin(props.navigation);
  };

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn]}>
      <ScrollView keyboardShouldPersistTaps="always" contentContainerStyle={UrstammStyle.flexGrow1}>
        {/** Title */}
        <View style={styles.titleTextContainer}>
          <UrstammTitle
            testID={'signup_title'}
            text={i18n.t('views.signup.signup')}
            fontStyle={UrstammStyle.sectionTitleXBDarkGrey}
          />
        </View>

        {/** Name */}
        <View style={styles.inputContainer}>
          <UrstammInput
            testID="signup_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{
              showIcon: true,
              iconName: 'user',
              iconColor: ColorTheme.lightGreen
            }}
            onChangeText={(text: string) =>
              setAccountData((state: any) => ({
                ...state,
                firstName: text
              }))
            }
            placeholder={i18n.t('views.signup.insert_your_name_here')}
          />
        </View>

        {/** Surname */}
        <View style={styles.inputContainer}>
          <UrstammInput
            testID="signup_surname"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{
              showIcon: true,
              iconName: 'user',
              iconColor: ColorTheme.lightGreen
            }}
            onChangeText={(text: string) =>
              setAccountData((state: any) => ({
                ...state,
                lastName: text
              }))
            }
            placeholder={i18n.t('views.signup.insert_your_surname_here')}
          />
        </View>

        {/** Function */}
        <View style={[styles.selectContainer]}>
          <UrstammSelect
            selectStyle={UrstammStyleSelect.selectContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            testID="signup_function"
            icon={{
              showIcon: true,
              iconName: 'list-ul',
              iconColor: ColorTheme.lightGreen,
              dropIconColor: ColorTheme.darkGrey
            }}
            list={['test1', 'test2']}
            placeholder={i18n.t('views.signup.select_your_function')}
          />
        </View>

        {/** Email */}
        <View style={styles.inputContainer}>
          <UrstammInput
            testID="signup_email"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{
              showIcon: true,
              iconName: 'at',
              iconColor: ColorTheme.lightGreen
            }}
            onChangeText={(text: string) => {
              setAccountData((state: any) => ({
                ...state,
                email: text
              })),
                setAccountData((state: any) => ({
                  ...state,
                  login: text
                }));
            }}
            placeholder={i18n.t('views.signup.insert_your_email_here')}
          />
        </View>
      </ScrollView>

      <View style={[UrstammStyle.flexGrow1, { flexBasis: 156 }]}>
        {/** Sign up button */}
        <View style={[UrstammStyle.flex1]}>
          <UrstammButtonBase
            testID={'signup_button'}
            text={i18n.t('views.signup.signup')}
            style={mainGreenButton}
            onSubmit={submit}
          />
        </View>
        {/** Navigate to log in */}
        <View style={[UrstammStyle.flex1, { paddingTop: 16 }]}>
          <UrstammButtonBase
            testID={'login_button'}
            text={i18n.t('views.login.login_button')}
            style={mediumGreyButton}
            onSubmit={navigateToLogin}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  titleTextContainer: {
    flex: 1
  },
  inputContainer: {
    flex: 1,
    paddingVertical: 8
  },
  selectContainer: {
    flex: 1,
    paddingVertical: 8,
    zIndex: 1
  }
});
