/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BatchState } from './BatchState';
import {
    BatchStateFromJSON,
    BatchStateFromJSONTyped,
    BatchStateToJSON,
} from './BatchState';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { DeliverySheet } from './DeliverySheet';
import {
    DeliverySheetFromJSON,
    DeliverySheetFromJSONTyped,
    DeliverySheetToJSON,
} from './DeliverySheet';
import type { Packet } from './Packet';
import {
    PacketFromJSON,
    PacketFromJSONTyped,
    PacketToJSON,
} from './Packet';
import type { ShippingNote } from './ShippingNote';
import {
    ShippingNoteFromJSON,
    ShippingNoteFromJSONTyped,
    ShippingNoteToJSON,
} from './ShippingNote';

/**
 * 
 * @export
 * @interface Batch
 */
export interface Batch {
    /**
     * 
     * @type {number}
     * @memberof Batch
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    uniqueIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof Batch
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    recordingType: BatchRecordingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Batch
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    currentState: BatchCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    uuid?: string;
    /**
     * 
     * @type {Company}
     * @memberof Batch
     */
    sawmill?: Company;
    /**
     * 
     * @type {Set<DeliverySheet>}
     * @memberof Batch
     */
    deliverySheets?: Set<DeliverySheet>;
    /**
     * 
     * @type {Set<BatchState>}
     * @memberof Batch
     */
    batchStates?: Set<BatchState>;
    /**
     * 
     * @type {Set<Packet>}
     * @memberof Batch
     */
    packets?: Set<Packet>;
    /**
     * 
     * @type {Set<ShippingNote>}
     * @memberof Batch
     */
    shippingNotes?: Set<ShippingNote>;
}


/**
 * @export
 */
export const BatchRecordingTypeEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE',
    Date: 'DATE',
} as const;
export type BatchRecordingTypeEnum = typeof BatchRecordingTypeEnum[keyof typeof BatchRecordingTypeEnum];

/**
 * @export
 */
export const BatchCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type BatchCurrentStateEnum = typeof BatchCurrentStateEnum[keyof typeof BatchCurrentStateEnum];


/**
 * Check if a given object implements the Batch interface.
 */
export function instanceOfBatch(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "uniqueIdentifier" in value;
    isInstance = isInstance && "recordingType" in value;
    isInstance = isInstance && "currentState" in value;

    return isInstance;
}

export function BatchFromJSON(json: any): Batch {
    return BatchFromJSONTyped(json, false);
}

export function BatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): Batch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'uniqueIdentifier': json['uniqueIdentifier'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'recordingType': json['recordingType'],
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'currentState': json['currentState'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'sawmill': !exists(json, 'sawmill') ? undefined : CompanyFromJSON(json['sawmill']),
        'deliverySheets': !exists(json, 'deliverySheets') ? undefined : (new Set((json['deliverySheets'] as Array<any>).map(DeliverySheetFromJSON))),
        'batchStates': !exists(json, 'batchStates') ? undefined : (new Set((json['batchStates'] as Array<any>).map(BatchStateFromJSON))),
        'packets': !exists(json, 'packets') ? undefined : (new Set((json['packets'] as Array<any>).map(PacketFromJSON))),
        'shippingNotes': !exists(json, 'shippingNotes') ? undefined : (new Set((json['shippingNotes'] as Array<any>).map(ShippingNoteFromJSON))),
    };
}

export function BatchToJSON(value?: Batch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'uniqueIdentifier': value.uniqueIdentifier,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'recordingType': value.recordingType,
        'sumCubage': value.sumCubage,
        'currentState': value.currentState,
        'uuid': value.uuid,
        'sawmill': CompanyToJSON(value.sawmill),
        'deliverySheets': value.deliverySheets === undefined ? undefined : (Array.from(value.deliverySheets as Set<any>).map(DeliverySheetToJSON)),
        'batchStates': value.batchStates === undefined ? undefined : (Array.from(value.batchStates as Set<any>).map(BatchStateToJSON)),
        'packets': value.packets === undefined ? undefined : (Array.from(value.packets as Set<any>).map(PacketToJSON)),
        'shippingNotes': value.shippingNotes === undefined ? undefined : (Array.from(value.shippingNotes as Set<any>).map(ShippingNoteToJSON)),
    };
}

