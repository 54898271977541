/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    size?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    sort?: Array<string>;
}

/**
 * Check if a given object implements the Pageable interface.
 */
export function instanceOfPageable(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageableFromJSON(json: any): Pageable {
    return PageableFromJSONTyped(json, false);
}

export function PageableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pageable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
    };
}

export function PageableToJSON(value?: Pageable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'size': value.size,
        'sort': value.sort,
    };
}

